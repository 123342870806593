import { serialize } from "../../utils";
import moment from "moment";
import AuthManager from "../../lib/AuthManager";

class GenericModel {
  constructor(object, API) {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        this[prop] = object[prop];
      }
    }
    this.API = API;
  }

  static insertAuthHeader = async (headerObj) => {
    if (!headerObj) {
      headerObj = {};
    }
    const id_app = localStorage.getItem("ID_APP");
    const id_client = localStorage.getItem("CLIENT_ID");
    try {
      const userToken = await AuthManager.getUserToken();
      headerObj["Authorization"] = "Bearer " + userToken;
      //console.log(userToken);
      if (id_app) headerObj["id_app"] = id_app;
      if (id_client) headerObj["id_client"] = id_client;
      headerObj["admin"] = 1;
      /*console.log("real barrer hi");
      console.log(userToken);*/
      return headerObj;
    } catch (err) {
      throw Error(err);
    }
  };

  static get = async function (id) {
    let authHeader = null;
    try {
      authHeader = await GenericModel.insertAuthHeader();
    } catch (err) {
      throw Error(err);
    }

    const response = await fetch(this.API + "/" + id, {
      headers: authHeader,
    });
    const body = await response.json();

    if (response.status !== 200) throw new Error(body.message);

    return new GenericModel(body, this.API);
  };

  static query = async function (query) {
    let queryString = "";
    if (query) {
      queryString = serialize(query);
    }
    let authHeader = null;
    try {
      let that = this;
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(this.API + "?" + queryString, {
        headers: authHeader,
      });
      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

      let ans = {};

      function mountObjArray(elementsArray) {
        let result = [];

        for (let i = 0; i < elementsArray.length; i++) {
          result.push(new GenericModel(elementsArray[i], that.API));
        }

        return result;
      }

      if (body && body.elements) {
        for (let prop in body) {
          if (body.hasOwnProperty(prop) && prop !== "elements") {
            ans[prop] = body[prop];
          }
        }
        ans.elements = mountObjArray(body.elements);
      } else if (body instanceof Array) {
        ans = mountObjArray(body);
      } else {
        ans = new GenericModel(body, that.API);
      }

      return ans;
    } catch (err) {
      throw Error(err);
    }
  };

  static filters = async function (query) {
    let queryString = serialize(query);

    let authHeader = null;

    try {
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(this.API + "/filters?" + queryString, {
        headers: authHeader,
      });

      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

      return body;
    } catch (err) {
      throw Error(err);
    }
  };

  saveUserWeb = async function () {
    let that = this;

    let authHeader = {};

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API + "/web-kenner", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  };

  save = async function () {
    let that = this;

    let authHeader = {};
    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API, {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      console.log(err);
      throw Error(err);
    }
  };

  remove = function () {
    return new Promise(async (resolve, reject) => {
      if(this.deleted_date !== null){
        this.deleted_date = null; // unlock user
      }else {
        this.deleted_date = moment().format("YYYYMMDDHHmmss"); // block user
      }

      let authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      fetch(this.API, {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      })
        .then(async function () {
          return resolve();
        })
        .catch(function (err) {
          return reject(err);
        });
    });
  };

  removeNew = function (id_content) {
    return new Promise(async (resolve, reject) => {
      

      let authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      fetch(this.API + "/remove/" + id_content, {
        method: "POST",
        headers: authHeader,
      })
        .then(async function () {
          return resolve();
        })
        .catch(function (err) {
          return reject(err);
        });
    });
  };
}

export default GenericModel;
