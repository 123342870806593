import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

export default function ContentEngagementCardOld(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;

    let content_type = 'video';
    if (this.object.training && this.object.training.training_video) {
        content_type = 'video';
    } else if (this.object.training && this.object.training.training_file) {
        content_type = 'pdf';
    } else if (this.object.form != null) {
        content_type = 'form';
    } else if (this.object.training && this.object.training.training_images && this.object.training.training_images.length > 0) {
        content_type = 'image';
    } else if (this.object.training && this.object.training.is_challenge_photo) {
        content_type = 'challenge';
    }
    this.object.title = (content_type === 'video' || content_type === 'pdf' || content_type === 'image' || content_type === 'challenge') && this.object.training ? this.object.training.training_title :
        content_type === 'form' && this.object.form ? this.object.form.form_title : '';

    this.object.id = this.object.id_content ? this.object.id_content : 'SEM ID';

    this.content_type = content_type === 'video' ? 'VIDEO' :
        content_type === 'pdf' ? 'ARQUIVO' :
            content_type === 'form' ? 'FORMULÁRIO' :
                content_type === 'image' ? 'IMAGEM' :
                    content_type === 'challenge' ? 'DES. FOTO' : '';

    this.object.content_type_name = this.content_type;

    if (this.content_type === 'FORMULÁRIO') {
        this.object.is_form = true;
    } else {
        this.object.is_form = false;
    }

    // Functions
    this.updateContent = props.updateObject;
    this.deleteContent = props.deleteObject;
    this.callDescription = props.callDescription;

    this.onChangeUpdate = (field, force) => event => {
        let value = (event && event.target) ? event.target.value : null;
        this.updateContent(this.object, field, value, force);
    };

    this.onClickCallDescription = () => {
        this.callDescription(this.object);
    };

    return (
        <div style={{
            'height': '6vw',
            'backgroundColor': props.chosenId === this.object.id_content ? '#4498A7' : '#f2f2f2',
            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
        }} onClick={this.onClickCallDescription}>
            <img
                style={{ width: '10vw', height: '5vw', objectFit: 'cover', marginRight: '20px', marginLeft: '20px' }}
                src={(content_type === 'video' || content_type === 'pdf' || content_type === 'image' || content_type === 'challenge') && this.object.training ? this.object.training.training_cover_image :
                    content_type === 'form' ? this.object.form.form_image : null}
                id={'image-modal'}
            />
            <div style={{ flex: 3 }}>
                <span
                    style={{
                        float: 'left',
                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em'
                    }}>
                    {this.object.title}
                </span>
            </div>
            <div style={{ flex: 1 }}>
                <span
                    style={{
                        float: 'left',
                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em'
                    }}>
                    {(this.content_type !== "FORMULÁRIO") ?
                        "Visualizações: " + this.object.view_number :
                        "Respostas: " + this.object.answer_number}
                </span>
            </div>
            <div style={{ flex: 1 }}>
                <span
                    style={{
                        float: 'left',
                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em'
                    }}>
                    ID: {this.object.id}
                </span>
            </div>
            <div style={{ flex: 1 }}>
                <span
                    style={{
                        float: 'left',
                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em'
                    }}>
                    Tipo: {this.content_type}
                </span>
            </div>
        </div>
    );
}