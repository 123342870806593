import GenericModel from './generic_model/GenericModel'
import axios from 'axios';

const API = '/tickets_answers';

class TicketAnswer extends GenericModel {
    API = API;
    static API = API;

    static async sendAttachment(attachment, message, userId, ticketId){
        try {
            const authHeader = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
              });

            const formData = new FormData();

            if(message){
                formData.append('answerMessage', message);
            }

            if(!attachment){
                throw Error("Erro ao enviar anexo");
            }

            if(!userId){
                throw Error("Erro ao enviar mensagem");
            }

            if(!ticketId){
                throw Error("Erro ao enviar mensagem");
            }

            formData.append('file', attachment);
            formData.append('userId', userId);
            formData.append('ticketId', ticketId);

           const response =  await axios.request({
                url: API + '/attachment',
                method: "POST",
                headers: {
                "Content-Type": "multipart/form-data",
                ...authHeader
                },
                data: formData
            });

            return response.data;
        }catch(err){
            throw new Error(err.response.data);
        }
    }

    static async deleteTicketAnswer(idUser, ticketAnswer){
        try {
            if(!idUser){
                throw Error("Erro ao enviar mensagem");
            }

            if(!ticketAnswer){
                throw Error("Erro ao enviar mensagem");
            }

            const authHeader = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            const response = await axios.delete(API + "/", {
                headers: authHeader,
                data: {
                    idUser: idUser,
                    ticketAnswers: ticketAnswer
                }
            });

            return response.data;
        }catch(err){
            throw new Error(err.response.data);
        }
    }
}

export default TicketAnswer;