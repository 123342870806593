import React from 'react';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { red, green, blue } from '@material-ui/core/colors';
import DeleteButton from '../buttons/DeleteButton';
import { DateTimePicker } from '@material-ui/pickers/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SaveButton from "../buttons/SaveButton";
import { toast } from 'react-toastify';

export default function SubjectDescription(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;



    // Functions
    this.updateSubject = props.updateObject;
    this.deleteSubject = props.deleteObject;
    this.nullDescription = props.nullDescription;
    this.saveSubject = props.saveObject;


    this.onChangeUpdate = (field, force) => event => {
        let value = (event && event.target) ? event.target.value : null;
        this.updateSubject(this.object, field, value, force);
    };

    this.onClickDeleteSubject = () => {
        if (window.confirm('Deseja mesmo deletar esse item?')) {
            this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the subject
            this.deleteSubject(this.object);
        }
    };

    this.onClickSaveSubject = () => {
        if(this.object.topic_name === null|| this.object.topic_name === undefined ){
            toast.info('Para salvar adicione um nome')
            return;
           }else if(this.object.topic_name?.trim().length===0){
             toast.info('Para salvar adicione um nome')
             return;
           }

        this.saveSubject(this.object);
    };

    return (
        <div style={{ 'height': 'auto' }}>
            <Grid container>
                <Grid xs={1}>

                </Grid>
                <Grid xs={10} container direction="row" spacing={1} style={{ marginTop: "10px" }}>
                    <Grid xs={3} item>
                        <TextField label="ID" value={this.object.id_topic} disabled />
                    </Grid>
                    <Grid xs={9} item>
                        <TextField label="Nome" value={this.object.topic_name ? this.object.topic_name : ''} style={{ 'width': '100%' }}
                            onChange={this.onChangeUpdate('topic_name', false)}
                            onBlur={this.onChangeUpdate('topic_name', true)} />
                    </Grid>
                    <Grid xs={6} item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker label="Data de Criação" value={this.object.created_date} style={{ 'width': '100%' }} format="dd/MM/yyyy HH:mm" disabled />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid xs={6} item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker label="Data da ultima alteração" value={this.object.updated_date} style={{ 'width': '100%' }} format="dd/MM/yyyy HH:mm" label-style={{ 'overflowX': 'hidden' }} disabled />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <Grid xs={11}>
                    <SaveButton style={{ float: 'right' }} onClickSave={this.onClickSaveSubject} ref={(ref) => { this.saveButton = ref }} />
                    <DeleteButton style={{ float: 'right', background: '#CC0B0B' }} onClickDelete={this.onClickDeleteSubject}></DeleteButton>
                </Grid>
            </Grid>
        </div>
    );

}