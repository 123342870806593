import GenericModel from './generic_model/GenericModel'
import axios from 'axios';

const API = '/tickets';

class Ticket extends GenericModel {
    API = API;
    static API = API;


    static getAnswers = async function (id_ticket) {

        let queryString = "";
        if (id_ticket) {
            queryString = id_ticket;
        }


        let authHeader = null;
        try {
            let that = this;
            authHeader = await GenericModel.insertAuthHeader();
            const response = await fetch(this.API + '/answers/' + queryString, {
                headers: authHeader,
            });
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let ans = {};

            function mountObjArray(elementsArray) {
                let result = [];

                for (let i = 0; i < elementsArray.length; i++) {
                    result.push(new GenericModel(elementsArray[i], that.API));
                }

                return result;
            }

            if (body && body.elements) {
                for (let prop in body) {
                    if (body.hasOwnProperty(prop) && prop !== "elements") {
                        ans[prop] = body[prop];
                    }
                }
                ans.elements = mountObjArray(body.elements);
            } else if (body instanceof Array) {
                ans = mountObjArray(body);
            } else {
                ans = new GenericModel(body, that.API);
            }

            return ans;
        } catch (err) {
            console.log(err);
            throw Error(err);
        }
    };
    static getStatusName = async function (ticket_status) {

        let queryString = "";
        if (ticket_status) {
            queryString = ticket_status;
        }


        let authHeader = null;
        try {
            let that = this;
            authHeader = await GenericModel.insertAuthHeader();
            const response = await fetch(this.API + '/status_name/' + queryString, {
                headers: authHeader,
            });
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let ans = {};

            function mountObjArray(elementsArray) {
                let result = [];

                for (let i = 0; i < elementsArray.length; i++) {
                    result.push(new GenericModel(elementsArray[i], that.API));
                }

                return result;
            }

            if (body && body.elements) {
                for (let prop in body) {
                    if (body.hasOwnProperty(prop) && prop !== "elements") {
                        ans[prop] = body[prop];
                    }
                }
                ans.elements = mountObjArray(body.elements);
            } else if (body instanceof Array) {
                ans = mountObjArray(body);
            } else {
                ans = new GenericModel(body, that.API);
            }

            return ans;
        } catch (err) {
            console.log(err);
            throw Error(err);
        }
    };

    static saveTicketAnswerFeedBack = async (id_ticket) => {
        const authHeader = await GenericModel.insertAuthHeader({
          Accept: "application/json",
          "Content-Type": "application/json",
        });
    
        try {
          let response = await fetch(this.API + "/answer_feedBack", {
            method: "POST",
            headers: authHeader,
            body: JSON.stringify({
              id_ticket: id_ticket,
            }),
          });
          if (response.status >= 400) {
            throw Error(response.statusText);
          }
        } catch (err) {
          console.warn(err);
        }
      };

      static getUserInCharge = async function (id_department_type) {

        let queryString = "";
        if (id_department_type) {
            queryString = id_department_type;
        }


        let authHeader = null;
        try {
            let that = this;
            authHeader = await GenericModel.insertAuthHeader();
            const response = await fetch(this.API + '/get_user_in_charge/' + queryString, {
                headers: authHeader,
            });
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body;
        } catch (err) {
            throw Error(err);
        }
    };

    static async removeTicket(ticketId, idUser){
        try {
            const authHeader = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });
            
            const response = await axios.delete(API + `/${ticketId}`, {
                headers: authHeader,
                data: {
                    userId: idUser
                }
            });

            return response.data;
        }catch(err){
            console.log(err);
        }
    }
}

export default Ticket;