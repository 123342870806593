import GenericModel from './generic_model/GenericModel'
import axios from "axios";
import moment from "moment";
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { serialize } from '../utils'
const API = '/store';

class Store extends GenericModel {
    API = API;
    static API = API;

    static statistics = async function (query) {

        let queryString = serialize(query);

        let authHeader = null;
        try {
            authHeader = await GenericModel.insertAuthHeader();

            const response = await fetch(API + '/statistics?' + queryString, {
                headers: authHeader
            });

            const body = await response.json();

            if (response.status !== 200) throw new Error(body.message);

            return body;
        } catch (err) {
            throw Error(err);
        }
    };
    static sendCoverImage = async (obj, progressFunction) => {

        try {
            let formData = new FormData();
            formData.append('file', obj, (new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + obj.name.split('.').pop());

            let headerObj = await GenericModel.insertAuthHeader();

            let response = await axios.request({
                url: API + '/send-store-cover-image',
                method: "post",
                data: formData,
                headers: headerObj,
                onUploadProgress: (p) => {
                    if (progressFunction) {
                        progressFunction(Math.round((p.loaded * 100) / p.total));
                    }
                }
            });

            return response.data;
        } catch (err) {
            throw Error(err);
        }
    };

    static sendAttachFile = async (obj, progressFunction) => {

        try {
            let formData = new FormData();
            formData.append('file', obj, obj.name.split('.')[0] + (new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + obj.name.split('.').pop());

            let headerObj = await GenericModel.insertAuthHeader();

            let response = await axios.request({
                url: API + '/send-store-attach-file',
                method: "post",
                data: formData,
                headers: headerObj,
                onUploadProgress: (p) => {
                    if (progressFunction) {
                        progressFunction(Math.round((p.loaded * 100) / p.total));
                    }
                }
            });

            return response.data;
        } catch (err) {
            throw Error(err);
        }
    };

    static sendAttachFileVideo = async (obj, progressFunction) => {

        return new Promise((resolve, reject) => {
            var storageRef = firebase.storage().ref();

            let bucketImg = storageRef.child((new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + obj.name.split('.').pop());

            let uploadTask = bucketImg.put(obj);

            uploadTask.on('state_changed', function (snapshot) {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                progressFunction(progress);

                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                reject(error);
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    resolve({ url: downloadURL });
                });
            });
        });
    };


    static saveStoreDocument = async (store, fileUrl, fileName) => {

        try {
            let storeDoc = {
                id_store: store.id_store,
                store_document_file: fileUrl,
                store_document_name: fileName,
            };

            let headerObj = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            let response = await fetch(this.API + '/save-store-attach-file', {
                method: "POST",
                headers: headerObj,
                body: JSON.stringify(storeDoc),
            });

            return response.data;
        } catch (err) {
            throw Error(err);
        }
    };

    static removeAttachFile = async (attachment) => {

        try {
            attachment.deleted_date = moment().format("YYYYMMDDHHmmss");
            let headerObj = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            let response = await fetch(this.API + '/save-store-attach-file', {
                method: "POST",
                headers: headerObj,
                body: JSON.stringify(attachment),
            });

            return response.data;
        } catch (err) {
            throw Error(err);
        }
    };
}

export default Store;