export default class Utils {
    static correctPhoneNumber(phone_number) {
        let clean_state_number = phone_number.replace(/(\(|\)|\ |\-|,|\.)/g, '');

        clean_state_number = clean_state_number.replace('+55','');

        if (clean_state_number.length < 10) {
            throw Error('Invalid brazilian phone number!');
        }

        let ddd = clean_state_number.substring(0, 2);
        let number = clean_state_number.substring(2);

        if(ddd.startsWith("+")) {
            throw Error('Invalid brazilian phone number!');
        }

        if (ddd.startsWith("0")) {
            ddd = clean_state_number.substring(1, 3);
            number = clean_state_number.substring(3);
        }

        if (number.length > 9 || number.length < 8) {
            throw Error('Invalid brazilian phone number!');
        } else if (number.length === 8) {
            number = "9" + number;
        }

        return '+55' + ddd + number;
    }
}



