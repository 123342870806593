import React, { PureComponent } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import moment from 'moment';


// const data = [
//   {
//     date: '2019-01-02', views: 4000, pv: 2400, amt: 2400,
//   },
//   {
//     date: '2019-01-03', views: 3000, pv: 1398, amt: 2210,
//   },
//   {
//     date: '2019-01-04', views: 0, pv: 9800, amt: 2290,
//   },
//   {
//     date: '2019-01-08', views: 2780, pv: 3908, amt: 2000,
//   },
//   {
//     date: '2019-01-11', views: 1890, pv: 4800, amt: 2181,
//   },
//   {
//     date: '2019-01-13', views: 2390, pv: 3800, amt: 2500,
//   },
//   {
//     date: '2019-01-14', views: 3490, pv: 4300, amt: 2100,
//   },
//   {
//     date: '2019-01-15', views: 4000, pv: 2400, amt: 2400,
//   },
//   {
//     date: '2019-01-18', views: 3000, pv: 1398, amt: 2210,
//   },
//   {
//     date: '2019-01-19', views: 2000, pv: 9800, amt: 2290,
//   },
//   {
//     date: '2019-01-22', views: 2780, pv: 3908, amt: 2000,
//   },
//   {
//     date: '2019-01-23', views: 1890, pv: 4800, amt: 2181,
//   },
//   {
//     date: '2019-01-24', views: 2390, pv: 3800, amt: 2500,
//   },
// ];

function formatXAxis(tickItem) {
  //return moment(tickItem).format(this.state.xTickFormat)
  return moment(tickItem).format('DD/M')
}

export default class DashboardBarChart extends PureComponent {

  state = {
    data: [],
    };
    
    constructor(props) {
        super(props);
        this.state.data = props.data;
    }
  
 
  
  render() {
    return (
      <BarChart
        width={600}
        height={400}
        data={this.state.data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={formatXAxis} />
        <YAxis type="number" domain={[0, 'dataMax + 1']} allowDecimals={false} />
        <Tooltip labelFormatter={date => moment(date).format('DD/M/YYYY')}/>
        <Legend />
        <Bar name="Novos acessos" dataKey="views" fill="#8884d8" />
      </BarChart>
    );
  }
}
