import React from 'react';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import DeleteButton from '../buttons/DeleteButton'
import { DateTimePicker } from '@material-ui/pickers/'
import SaveButton from "../buttons/SaveButton";
import cloneDeep from "lodash/cloneDeep";
import FaqQuestionCard from "../cards/FaqQuestionCard";
import AddIcon from "@material-ui/icons/Add";
import { toast } from 'react-toastify';
export default function FaqTopicDescription(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;

    // Functions
    this.updateFaqTopic = props.updateObject;
    this.deleteFaqTopic = props.deleteObject;
    this.saveFaqTopic = props.saveObject;
    this.nullDescription = props.nullDescription;

    this.onClickSaveFaqTopic = () => {
        if(this.object.faq_topic === null|| this.object.faq_topic === undefined ){
            toast.info('Para salvar adicione um tópico')
            return;
           }else if(this.object.faq_topic?.trim().length===0){
             toast.info('Para salvar adicione um tópico')
             return;
           }
        this.saveFaqTopic(this.object);
    };

    this.onClickDeleteFaqTopic = () => {
        if (window.confirm('Deseja mesmo deletar esse item?')) {
            this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the faqTopic
            this.deleteFaqTopic(this.object);
        }
    };

    this.onChangeUpdate = (field, force) => event => {
        let value = (event && event.target) ? event.target.value : null;
        this.updateFaqTopic(this.object, field, value, force);
    };

    this.onQuestionChange = (field, idxQuestion) => event => {
        let value = (event && event.target) ? event.target.value : null;
        let faqQuestionsList = cloneDeep(this.object.faq_questions);
        let faqQuestionObj = faqQuestionsList[idxQuestion];

        if (faqQuestionObj) {
            if (!isNaN(value)) {
                value = Number(value);
            }
            faqQuestionObj[field] = value;
            this.updateFaqTopic(this.object, 'faq_questions', faqQuestionsList);
        }
    };

    this.addQuestion = () => {
        let faqQuestionsList = cloneDeep(this.object.faq_questions);
        if (!faqQuestionsList) {
            faqQuestionsList = [];
        }
        faqQuestionsList.push({});
        this.updateFaqTopic(this.object, 'faq_questions', faqQuestionsList);
    };

    return (
        <div style={{ 'height': 'auto' }}>
            <Grid container spacing={2} style={{ paddingTop: '10px' }}>
                <Grid xs={1}>

                </Grid>
                <Grid xs={10} container direction="row" spacing={1}>
                    <Grid xs={2} item>
                        <TextField label="ID" value={this.object.id_faq_topic} disabled />
                    </Grid>
                    <Grid xs={5} item>
                        <TextField label="Tópico" value={this.object.faq_topic ? this.object.faq_topic : ''} style={{ 'width': '100%' }}
                            onChange={this.onChangeUpdate('faq_topic', false)}
                            onBlur={this.onChangeUpdate('faq_topic', true)} />
                    </Grid>
                    <Grid xs={5} item>
                        <TextField label="E-mail" value={this.object.faq_topic_email ? this.object.faq_topic_email : ''} style={{ 'width': '100%' }}
                            onChange={this.onChangeUpdate('faq_topic_email', false)}
                            onBlur={this.onChangeUpdate('faq_topic_email', true)} />
                    </Grid>
                </Grid>

                <Grid style={{ flexDirection: 'column', width: '100%', paddingLeft: '20px', paddingRight: '20px', marginTop: '30px' }}>
                    {this.object.faq_questions ? this.object.faq_questions.map((question, idx) => {
                        let deleteQuestion = () => {
                            let faqQuestionsList = cloneDeep(this.object.faq_questions);
                            faqQuestionsList.splice(idx, 1);
                            this.updateFaqTopic(this.object, 'faq_questions', faqQuestionsList);
                        };

                        return (
                            <FaqQuestionCard
                                faqQuestion={question}
                                onQuestionChange={this.onQuestionChange}
                                deleteQuestion={deleteQuestion}
                                idx={idx}
                            />
                        )
                    }) : null}
                    <Button
                        onClick={this.addQuestion}
                        variant="contained"
                        style={{ width: '100%' }}>
                        Adicionar Pergunta
						<AddIcon />
                    </Button>
                </Grid>

                <Grid xs={11} style={{ marginTop: '20px' }}>
                    <SaveButton style={{ float: 'right' }} onClickSave={this.onClickSaveFaqTopic}
                        ref={(ref) => { this.saveButton = ref }} />
                    <DeleteButton style={{ float: 'right', background: '#CC0B0B' }} onClickDelete={this.onClickDeleteFaqTopic}></DeleteButton>
                </Grid>
            </Grid>
        </div>
    );
}