import { Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserCircle,
    faBan
} from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import AttachFileButton from '../buttons/AttachFileButton';
import { DateTimePicker } from '@material-ui/pickers/';
import { CustomDropzone } from "../CustomDropzone";
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import SearchTextFieldWithFilter from "../text_fields/SearchTextFieldWithFilter";
import NumberFormat from 'react-number-format';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';
import SRFilteredSelect from '../selectbox/SRFilteredSelect'
import SaveButton from "../buttons/SaveButton";
import Store from "../../../models/Store";
import User from "../../../models/User";
import "../../../../node_modules/rodal/lib/rodal.css";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Rodal from "rodal";

export default function StoreUserListDescription(props) {

    this.editionTimeout = null;
    // Object to Render
    this.object = props.object;
    this.objectUserList = props.objectUserList;
    this.addButton_placeholder = props.addButton_placeholder;
    //FUNCTIONS
    this.saveStore = props.saveObject;
    this.updateStore = props.updateObject;
    this.deleteStore = props.deleteObject;
    this.nullDescription = props.nullDescription;
    this.refreshStore = props.refreshObject;
    this.searchUserList = props.searchUserList;
    this.updateUserList = props.updateUserList;
    this.searchTimeout = null;
    this.filterText = '';
    this.openStoreUserDescriptionModal = props.openStoreUserDescriptionModal;


    this.onClickDeleteStore = () => {
        if (window.confirm('Deseja mesmo deletar esse item?')) {
            this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the store
            this.deleteStore(this.object);
        }
    };

    
    function NumberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                isNumericString
                allowNegative={false}
                decimalScale={0}
            />
        );
    };

    this.addUser = async () => {
        let newUser = new User();
        newUser.user_ein_code = this.object.store_ein_code;
        newUser.id_store = this.object.id_store;
        let userObj = {};
        try {
            userObj = await newUser.save();
        } catch (err) {
            console.log(err);
            throw Error(err);
        }
        this.updateUserList(userObj);
        this.objectUserList.push(userObj);
        return userObj;
    };

    this.onClickopenStoreUserDescriptionModal = () => {
        this.addUser().then((storeNewUser) => {
                this.openStoreUserDescriptionModal(storeNewUser);
        });
    };

    this.searchText_onSearch = (event) => {
        this.searchUserList(event);
    };

    return (
        <div style={{ 'height': 'auto' }} key={this.keyReload}>
            <Grid xs={12} container direction="row" spacing={2}>
                <Grid xs={12}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "10px 30px 10px 20px",
                        }}
                    >
                        <div style={{ paddingRight: "20px" }}>
                            <Button variant="contained" onClick={this.onClickopenStoreUserDescriptionModal}>
                                <AddCircleIcon style={{ marginRight: "2px" }} />{" "}
                            </Button>
                        </div>
                        <div style={{ paddingRight: "10px" }}>
                            <Button variant="contained" onClick={this.refreshStore}>
                                <i
                                    className="fa fa-fw fa-refresh"
                                    style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                                />
                            </Button>
                        </div>
                        <div style={{ flex: "1" }}>
                            <SearchTextFieldWithFilter
                                placeholder={"Pesquise um usuário"}
                                onSearch={this.searchText_onSearch}
                            ></SearchTextFieldWithFilter>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div style={{ flex: "1" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    position: "relative",
                                    display: "flex",
                                }}
                            >
                                <Grid container>
                                    <Grid xs={12} >
                                        <PerfectScrollbar
                                            style={{
                                                width: "100%",
                                                height: "60vh",
                                                overflow: "hidden",
                                                position: "absolute",
                                            }}
                                            options={{ suppressScrollX: "true" }}
                                        >
                                            {this.objectUserList
                                                .map((object, index) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                marginBottom: "5px",
                                                                marginLeft: "20px",
                                                                marginRight: "20px",
                                                            }}
                                                        >
                                                            <div itemID={object.id_user} onClick={() => this.openStoreUserDescriptionModal(object)}
                                                                style={{
                                                                    backgroundColor: "#bdbdbd",
                                                                    borderRadius: 30,
                                                                    padding: "10px"
                                                                }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: 'row',
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "center",
                                                                    marginLeft: "5px",

                                                                }}>
                                                                    <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
                                                                        <FontAwesomeIcon
                                                                            icon={object.deleted_date ? faBan : faUserCircle}
                                                                            style={{ fontSize: "1.75em", verticalAlign: "middle", marginRight: "5px" }}
                                                                            color={'#000'}
                                                                        />
                                                                    </div>
                                                                    <div style={{ display: "flex", flex: 5, justifyContent: "center", alignItems: 'center' }}>
                                                                        <span>{object.user_name ? object.user_name : object.user_phone}</span>
                                                                    </div>
                                                                    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                                        <span>{object.user_phone}</span>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </PerfectScrollbar>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>


            </Grid>

        </div>

    );
}