import React, { useEffect, useState } from "react";

function ModalChecklistForm({
  object,
  callDescription,
  deleteObject,
  updateObject,
  relations,
  authors,
  subjects,
  descriptionState,
  chosenId,
}) {
  // const [ticketsDepartments, setTicketsDepartments] = useState(
  //   props.ticketsDepartments
  // );

  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(object ? object : []);
  // const [formObject, setFormObject] = useState(props.form);
  const [formTitle, setFormTitle] = useState("Formulario");
  // const [formDefaultRoles, setFormDefaultRoles] = useState([]);
  // const [formRoles, setFormRoles] = useState(props.form.roles);
  // const [formQuestions, setFormQuestions] = useState(
  //   props.form.form.forms_questions ? props.form.form.forms_questions : []
  // );
  // const [formQuestion, setFormQuestion] = useState(props.form.form.forms_questions ? props.form.form.forms_questions[0]?.form_question : "");
  // const [formChoices, setFormChoices] = useState([]);
  // const [chosenFormDepartment, setChosenFormDepartment] = useState({});
  // const [openCofirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  //*-----------------------------------------------------------------------------

  // useEffect(() => {
  //   fetchData();
  // }, [form]);
  //*-----------------------------------------------------------------------------

  // const fetchData = async () => {
  //   try {
  //     setLoading(true);

  //     // let forms = await Content.getTicketForms();
  //     setForm(object.form?.form_type == 6 ? object : []);
  //     let title = form?.form?.form_title;
  //     setFormTitle(title === undefined ? "nada" : title);
  //     // console.log(form, "OS form RECEBIDOS nessa tela");
  //     console.log(form?.form?.form_title, "OS form RECEBIDOS nessa tela");
  //     console.log(
  //       title === "NOME" ? object : "O Objecto Recebido nao ta certo"
  //     );
  //     console.log(object.length, "object.length");
  //     // console.log(formTitle, "object.formTitle");
  //     console.log(title, "object.title");
  //   } catch (e) {
  //     console.error(e);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  //*-----------------------------------------------------------------------------
  // const fetchData = () => {
  // let formDefaultRoles = [];
  // if (formRoles && formRoles.length > 0) {
  //   formDefaultRoles = formRoles.map((role) => {
  //     let formDefaultRole = {
  //       value: role.id_role,
  //       label: role.role_name,
  //     };
  //     return formDefaultRole;
  //   });
  // }
  // let chosenFormDepartmentDefault = {
  //   value: Array.isArray(props.form.form.ticket_department)
  //     ? props.form.form.ticket_department[0]?.id_ticket_department
  //     : props.form.form.ticket_department?.id_ticket_department,
  //   label: Array.isArray(props.form.form.ticket_department)
  //     ? props.form.form.ticket_department[0]?.ticket_department_name
  //     : props.form.form.ticket_department?.ticket_department_name,
  // };
  // if (formQuestions && formQuestions.length > 0) {
  //   setFormChoices(formQuestions[0].forms_choices);
  // }
  // setFormDefaultRoles(formDefaultRoles);
  // setChosenFormDepartment(chosenFormDepartmentDefault);
  // };

  // const handleClose = () => {
  //   props.onClose();
  // };
  // const handleSave = () => {
  //   //console.log(formTitle, "Titulo");
  //   //console.log(formRoles, "ROLES");
  //   //console.log(formQuestions, "PERGUNTAS");
  //   //console.log(formQuestion, "PERGUNTA");
  //   //console.log(formChoices, "CHOICES");
  //   let formObj = formObject;
  //   formObj.form.form_title = formTitle;
  //   formObj.roles = formRoles;
  //   formObj.form.forms_questions = formQuestions;
  //   formObj.form.forms_questions[0].forms_choices = formChoices;

  //   props.saveObject(formObj);
  // };
  // const handlePublish = () => {
  //   //console.log(formTitle, "Titulo");
  //   //console.log(formRoles, "ROLES");
  //   //console.log(formQuestions, "PERGUNTAS");
  //   //console.log(formQuestion, "PERGUNTA");
  //   //console.log(formChoices, "CHOICES");
  //   let formObj = formObject;
  //   formObj.form.form_title = formTitle;
  //   formObj.roles = formRoles;
  //   formObj.is_published = 1;
  //   formObj.form.forms_questions = formQuestions;
  //   formObj.form.forms_questions[0].forms_choices = formChoices;

  //   //console.log(formObj);

  //   props.saveObject(formObj);
  // };
  // const onChangeTitle = (title) => {
  //   setFormTitle(title);
  // };
  // const onChangeQuestion = (question) => {
  //   let formQuestionsNew = formQuestions;
  //   if (!formQuestionsNew || formQuestionsNew.length === 0) {
  //     formQuestionsNew.push({ form_question: question, form_question_type: 4 });
  //   } else {
  //     let formQuestion = formQuestionsNew[0];
  //     formQuestion.form_question = question;
  //     formQuestionsNew[0] = formQuestion;
  //   }
  //   setFormQuestions(formQuestionsNew);
  //   setFormQuestion(question);
  // };

  // const onChangeRoles = (value) => {
  //   let roles;
  //   if (value != null) {
  //     //null cant use map function
  //     roles = value.map((obj) => {
  //       return {
  //         //revert the label to put the id_role and role_name in the Content Object
  //         id_role: obj.value,
  //         role_name: obj.label,
  //       };
  //     });
  //   } else {
  //     roles = [];
  //   }
  //   setFormRoles(roles);
  //   setFormDefaultRoles(value);
  // };

  // const addProduct = () => {
  //   let formsChoicesList = cloneDeep(formChoices);
  //   if (!formsChoicesList) {
  //     formsChoicesList = [];
  //   }
  //   let form_choice_order = formsChoicesList.length + 1;
  //   formsChoicesList.push({
  //     forms_choices_order: form_choice_order,
  //     form_choice: "",
  //   });
  //   setFormChoices(formsChoicesList);
  // };

  // const onClickDeleteProduct = (position) => () => {
  //   let formsChoicesList = cloneDeep(formChoices);
  //   formsChoicesList.splice(position, 1);
  //   setFormChoices(formsChoicesList);
  // };

  // const onQuestionChoiceChange = (idx, form_choice) => {

  //   let formsChoicesList = cloneDeep(formChoices);

  //   let choice = formsChoicesList[idx];

  //   if (choice) {
  //     choice.form_choice = form_choice;
  //   }
  //   formsChoicesList[idx] = choice;
  //   setFormChoices(formsChoicesList);
  // };

  // const handleDepartmentChange = (department) => {
  //   setChosenFormDepartment(department);
  // };

  // const handleDelete = () => {
  //   props.deleteForm(formObject);
  // };

  // const handleCloseDeleteDialog = () => {
  //   setOpenConfirmDeleteDialog(false);
  // };

  // const handleOpenModalDelete = () => {
  //   setOpenConfirmDeleteDialog(true);
  // };

  return (
    <>
      {/* <p>fazendo</p> */}

      {/* <Dialog
     open={false}
     scroll={"paper"}
     fullWidth={true}
     maxWidth={"sm"}
     // onClose={handleClose}
     aria-describedby="scroll-dialog-description"
     >
  <DialogContent style={{ display: "flex", flexDirection: "column" }}>
 
   </DialogContent>
   </Dialog> */}
    </>
  );
}

export default ModalChecklistForm;

export const Carregando = () => <p>Carregando</p>;

//transform style components
export const BasicCard = () => (
  <div
    style={{
      backgroundColor: "#eee",
      width: "100%",
      borderRadius: 4,
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // marginTop:  "5px" ,
      margin: "5px",
      marginLeft: "10px",
    }}

    // onClick={() => openChosenForm(form)}
  ></div>
);
