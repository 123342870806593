import React from 'react';
import { Grid, Paper } from '@material-ui/core';

export default function BrandCard(props) {

	this.editionTimeout = null;

	// Object to Render
	this.object = props.object;

	// Functions
	this.updateBrand = props.updateObject;
	this.deleteBrand = props.deleteObject;
	this.callDescription = props.callDescription;

	this.onChangeUpdate = (field, force) => event => {
		let value = (event && event.target) ? event.target.value : null;
		this.updateBrand(this.object, field, value, force);
	};

	this.onClickCallDescription = () => {
		this.callDescription(this.object);
	};

	return (
		<Paper style={{'height':'100px'}} onClick={this.onClickCallDescription}>
			<Grid container>
				<Grid xs={2}>
					<div style={{height:'80px', background:'#f2f2f2', top:'10px', margin:'auto', position: 'relative', width:'75px'}} />
				</Grid>
				<Grid xs={8} style={{margin:'auto'}}>
					<tbody style={{float:'left'}}>
						<tr>
							<Grid xs={12} style={{width:'100%'}}>
								<span style={{float: 'left', padding: '0', fontSize: '18px',
                fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                letterSpacing: '0.00938em'}}>{this.object.brand_name ? this.object.brand_name : ''}</span>
							</Grid>
						</tr>
					</tbody>
				</Grid>
			</Grid>
		</Paper>
	);
}