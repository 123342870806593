import React from 'react';
import { Grid, Paper, TextField, Button, Checkbox } from '@material-ui/core';
import DeleteButton from '../buttons/DeleteButton';
import { DateTimePicker } from '@material-ui/pickers/';
import SaveButton from '../buttons/SaveButton';
import Select from 'react-select';
import cloneDeep from 'lodash/cloneDeep';
import FaqQuestionCard from '../cards/FaqQuestionCard';
import AddIcon from '@material-ui/icons/Add';
import AdminManualContentTrailScreen from '../../screens/AdminManualContentTrailScreen';
import { toast } from 'react-toastify';
export default function ManualDescription(props) {
  this.editionTimeout = null;

  // Object to Render
  this.object = props.object;

  // Functions
  this.updateManual = props.updateObject;
  this.deleteManual = props.deleteObject;
  this.saveManual = props.saveObject;
  this.nullDescription = props.nullDescription;
  this.publishObject = props.publishObject;

  this.roles = props.relations[0];

  this.object.roleOptions = this.roles.map((role) => {
    let roleOption = {
      value: role.id_role,
      label: role.role_name,
    };
    return roleOption;
  });

  if (this.object.roles !== undefined) {
    this.object.defaultRoleValues = this.object.roles.map((role) => {
      return {
        value: role.id_role ? role.id_role : null,
        label: role.role_name ? role.role_name : null,
      };
    });
  } else {
    this.object.defaultRoleValues = [];
  }

  this.onClicksaveManual = () => {
    if(this.object.manual_title === null|| this.object.manual_title === undefined ){
      toast.info('Para salvar adicione um título')
      return;
     }else if(this.object.manual_title?.trim().length===0){
       toast.info('Para salvar adicione um título')
       return;
     }
    this.saveManual(this.object);
  };

  this.onClickdeleteManual = () => {
    if (window.confirm('Deseja mesmo deletar esse item?')) {
      this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the faqTopic
      this.deleteManual(this.object);
    }
  };

  this.showTrailContent = () => {
    return (
      <Grid xs={12} item style={{ width: '100%' }} contain spacing={2}>
        <AdminManualContentTrailScreen manual={this.object} />
      </Grid>
    );
  };

  this.onChangeUpdate = (field, force) => (event) => {
    
    let value = event && event.target ? event.target.value : null;
    if (field === 'starting_manual') {
      value = event.target?.checked ? 1 : 0;
    }
    this.updateManual(this.object, field, value, force);
  };

  this.onChangeUpdateRoles = (value) => {
    let roles;
    if (value != null) {
      //null cant use map function
      roles = value.map((obj) => {
        return {
          //revert the label to put the id_role and role_name in the Content Object
          id_role: obj.value,
          role_name: obj.label,
        };
      });
    } else {
      roles = [];
    }
    this.updateManual(this.object, 'roles', roles, true);
  };

  this.onClickPublishManual = () => {
    if(this.object.manual_title === null|| this.object.manual_title === undefined ){
      toast.info('Para publicar adicione um titulo')
      return;
     }else if(this.object.manual_title?.trim().length===0){
       toast.info('Para publicar adicione um titulo')
       return;
     }
    this.object.is_published = 1;
    this.publishObject(this.object);
  };

  return (
    <div style={{ height: 'auto' }}>
      <Grid xs={12} container spacing={2} style={{ marginTop: '10px' }}>
        <Grid
          xs={12}
          container
          direction='row'
          style={{ marginLeft: '12px' }}
          spacing={1}
        >
          <Grid xs={1} item>
            <TextField label='ID' value={this.object.id_manual} disabled />
          </Grid>
          <Grid xs={5} item>
            <TextField
              label='Título'
              value={this.object.manual_title ? this.object.manual_title : ''}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('manual_title', false)}
              onBlur={this.onChangeUpdate('manual_title', true)}
            />
          </Grid>
          <Grid xs={2} item>
            <TextField
              label='Ordem'
              value={this.object.order_manual !== null ? this.object.order_manual : ''}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('order_manual', false)}
              onBlur={this.onChangeUpdate('order_manual', true)}
            />
          </Grid>
          <Grid xs={4} item spacing={2} style={{ marginTop: '10px' }}>
            <Grid xs={11}>
              <Select
                placeholder={'Selecione os Papéis'}
                options={this.object.roleOptions}
                value={this.object.defaultRoleValues}
                onChange={this.onChangeUpdateRoles}
                isMulti
              />
            </Grid>
          </Grid>
          {/* aqui */}
          {localStorage.getItem('ID_APP') === '3' && (
            <Grid item xs={2}>
              <Grid container>
                <Grid item xs={12}>
                  <span
                    style={{
                      fontSize: '12px',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      lineHeight: 1,
                      letterSpacing: '0.00938em',
                      float: 'left',
                      color: 'rgba(0, 0, 0, 0.54)',
                      paddingBottom: '0px',
                    }}
                  >
                    P/ inativos:
                  </span>
                </Grid>
                <Grid xs={12} item>
                  <Grid style={{ float: 'left' }}>
                    <Checkbox
                      onChange={this.onChangeUpdate('starting_manual', true)}
                      checked={this.object.starting_manual === 1}
                    />{' '}
                    P/ inativos
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          xs={12}
          container
          direction='row'
          spacing={2}
          style={{ marginTop: '10px' }}
        >
          {this.object ? this.showTrailContent() : null}
        </Grid>
        <Grid xs={12} style={{ marginTop: '20px' }}>
          <SaveButton
            style={{ float: 'right' }}
            text={'Publicar'}
            onClickSave={this.onClickPublishManual}
            ref={(ref) => {
              this.saveButton = ref;
            }}
          />
          <SaveButton
            style={{ float: 'right' }}
            onClickSave={this.onClicksaveManual}
            ref={(ref) => {
              this.saveButton = ref;
            }}
          />
          <DeleteButton
            style={{ float: 'right', background: '#CC0B0B' }}
            onClickDelete={this.onClickdeleteManual}
          ></DeleteButton>
        </Grid>
      </Grid>
    </div>
  );
}
