import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Manual from '../../models/Manual';
import Role from '../../models/Role';

// UI Cards
import ManualCard from '../components/cards/ManualCard';
import ManualDescription from '../components/description_cards/ManualDescription';
import Spinner from '../components/buttons/Spinner';

class AdminManualcreen extends Component {
  editionTimeout = null;

  state = {
    manuals: [],
    relations: [],
    loading:true
  };

  // TEXTS
  searchText_placeholder = 'Tópico ou Pergunta';
  addButton_placeholder = 'Manual';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    Promise.all([Manual.query(), Role.query()])
      .then((relations) => {
        this.setState({
          manuals: relations[0],
          relations: [relations[1]],
         loading: false ,
        });
      })
      .catch((err) => {
        this.setState({loading: false});
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
  }

  addManual = async () => {
    let newManual = new Manual();
    let manualObj = {};
    try {
      manualObj = await newManual.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let manualList = this.state.manuals;
    manualList.push(manualObj);
    this.setState({ manuals: manualList });
    return manualObj;
  };

  updateManual = (manualObj, field, value, force) => {
    this.setState({ loading: true });
    let that = this;
    let manualList = this.state.manuals;
    let position = manualList.findIndex((obj) => {
      return obj.id_manual === manualObj.id_manual;
    });
    let manualToUpdate = manualList.find((obj) => {
      return obj.id_manual === manualObj.id_manual;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          manualToUpdate[prop] = value[prop];
        }
      }
    } else {
      manualToUpdate[field] = value;
    }

    // Timeout: Wait 500 milliseconds before sending API POST call to Server
    if (this.editionTimeout) clearTimeout(this.editionTimeout);

    this.setState({ manuals: manualList });
    this.setState({ loading: false });
    return manualList[position];
  };

  deleteManual = async (manualObj) => {
    let manualList = this.state.manuals;

    // Finds Edited Manual on manuals list and change Manual_name value
    for (let i = 0; i < manualList.length; i++) {
      if (manualObj.id_manual === manualList[i].id_manual) {
        await manualList[i].remove();
        manualList.splice(i, 1);
        break;
      }
    }

    this.setState({ manuals: manualList });
  };

  saveManual = async (manualObj) => {
    let manualList = this.state.manuals;
    let position = manualList.findIndex((obj) => {
      return obj.id_manual === manualObj.id_manual;
    });
    let manualToUpdate = manualList.find((obj) => {
      return obj.id_manual === manualObj.id_manual;
    });

    if (manualToUpdate != null) {
      let manualObj = manualToUpdate;

      try {
        manualObj = await manualToUpdate.save();
        // Manual upload to server worked
        manualList[position] = manualObj;
        this.setState({ manuals: manualList });
       toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
       toast.error('Erro ao salvar conteúdo!');
      }
    }
  };

  refreshManuals = async (parameters) => {
    this.setState({loading: true});
    await this.reloadManuals(parameters);
  };


  reloadManuals = async (parameters) => {
    try {
      const relations = await Promise.all([
        Manual.query(parameters), 
        Role.query()
      ]);

      this.setState({
        manuals: relations[0],
        relations: [relations[1]],
       loading: false ,
      });
    } catch(err){
      this.setState({loading: false});
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
  }

  render() {
    return (
      <> 
      {/*this.state.loading
      ? <Spinner
       margin='13px' 
      texto={'carregando...'}
      border={'#9BB3D4'}
      top={'#1D2D44'}/>
      :*/
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.manuals}
        objectCard={ManualCard}
        objectDescription={ManualDescription}
        addObject={this.addManual}
        updateObject={this.updateManual}
        saveObject={this.saveManual}
        deleteObject={this.deleteManual}
        relations={this.state.relations}
        objectIdName={'id_manual'}
        onFilterReload={true}
        refreshObjects={this.refreshManuals}
        contentType={{
          content_type: 'manual'
        }}
        objectModel={Manual}
      ></GenericAdminScreen>
  }</>
    );
  }
}

export default AdminManualcreen;
