import React from 'react';
import { Grid, Paper } from '@material-ui/core';

export default function ManualContentCard(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;

    // Functions
    this.updateManual = props.updateObject;
    this.deleteFaqTopic = props.deleteObject;
    this.callDescription = props.callDescription;

    this.onChangeUpdate = (field, force) => event => {
        let value = (event && event.target) ? event.target.value : null;
        this.updateManual(this.object, field, value, force);
    };

    this.onClickCallDescription = () => {
        this.callDescription(this.object);
    };

    return (
        <Paper
            style={{
                'height': '100px',
                'backgroundColor': (props.chosenId === this.object.id_manual_content ? '#4498A7' : '#f2f2f2')
            }}
            onClick={this.onClickCallDescription}
        >
            <Grid container>
                <Grid xs={12}>
                    <div style={{
                        display: "flex",
                        height: '100px',
                        flexDirection: 'row',
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <div style={{ display: "flex", flex: 1, justifyContent: "flex-start", marginLeft: "50px" }}>
                            <span style={{
                                fontSize: '18px',
                                fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                                letterSpacing: '0.00938em'
                            }}>{this.object.manual_content_title ? this.object.manual_content_title : ''}</span>
                        </div>
                        <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", marginRight: '50px' }}>
                            <span style={{
                                fontSize: '18px',
                                fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                                letterSpacing: '0.00938em'
                            }}>{'Ordem:'}</span>
                            <span style={{
                                fontSize: '18px',
                                fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                                letterSpacing: '0.00938em'
                            }}>{this.object.order_content ? this.object.order_content : '0'}</span>
                        </div>

                        <div style={{ flex: 1 }}>
                            <span
                                style={{
                                    float: 'flex',
                                    padding: '0',
                                    fontSize: '18px',
                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                    fontWeight: '400',
                                    lineHeight: '1',
                                    letterSpacing: '0.00938em'
                                }}>
                                {(this.object.is_published == 1 ? "Publicado" : "Salvo como rascunho")}
                            </span>
                        </div>


                    </div>

                </Grid>
            </Grid>
        </Paper>
    );
}