// include styles for modal
import '../../../../node_modules/rodal/lib/rodal.css';

// Scrollbar Style
import "../../../../node_modules/react-perfect-scrollbar/dist/css/styles.css";

import React, { Component } from "react";
import ReactDOM from "react-dom";
import Rodal from "rodal";
import { toast, ToastContainer } from 'react-toastify';
import { Button, Grid, Paper, TextField, MenuItem } from "@material-ui/core";
import SearchTextFieldWithFilter from "../text_fields/SearchTextFieldWithFilter";
import PerfectScrollbar from "react-perfect-scrollbar";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { DateTimePicker } from "@material-ui/pickers/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Modal from "react-responsive-modal";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ListIcon from "@material-ui/icons/List";
import Utils from "../../../lib/utils";

// Import the user 
import User from "../../../models/User";

const PAGE_INTERVAL = 20;

// const moment = require('moment');

class StoreAdminScreen extends Component {
  state = {
    alteredContent: false,
    objectList: [],
    chosenObject: {},
    chosenObjectId: {},
    isCalled: false,
    relations: [],
    users: [],
    authors: [],
    subjects: [],
    open: false,
    showList: true,
    showFilter: false,
    fieldsToFilter: [],
    curPage: 1,
    maxPage: 1,
    maxEl: 0,
    completed: 0,
    is_saving: false,
    is_loading: false,
    is_editing_item: false,
    descriptionState: {},
    leftOffset: 0,
    modalWidth: 0,
    attachModalVisible: false,
    documentsFilterList: [],
    textFilterDoc: '',
    userListModalVisible: false,
    usersFilterList: [],
    textFilterUserList: '',
    userDescriptionModalVisible: false,
  };

  constructor(props) {
    super(props);

    this.objectModel = props.objectModel;

    this.onFilterReload = props.onFilterReload;

    // Parameters
    this.addButton_placeholder = props.addButton_placeholder;
    this.searchText_placeholder = props.searchText_placeholder;
    this.hideAddButton = props.hideAddButton;
    this.key = props.key;

    // UI Classes
    this.objectCard = props.objectCard
      ? props.objectCard
      : this.defaultObjectCard;
    this.objectDescription = props.objectDescription
      ? props.objectDescription
      : this.defaultObjectDescription;
    this.objectDescriptionAttachment = props.objectDescriptionAttachment;
    this.objectDescriptionUserList = props.objectDescriptionUserList;
    this.objectStoreUserDescription = props.objectStoreUserDescription;

    // Functions
    this.addObject = props.addObject
      ? async () => {
          this.setState({ isCalled: false });
          let obj = await props.addObject();
          this.setChosenObject(obj);
          this.setState({
            isCalled: true,
            is_editing_item: true,
          });
        }
      : () => {};

    this.saveObject = props.saveObject
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props
            .saveObject(obj)
            .then(() => {
              this.setState({ is_saving: false });
            })
            .catch((e) => {
              this.setState({ is_saving: false });
              console.warn(e);
            });
        }
      : () => {};

    this.publishObject = props.saveObject
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props
            .saveObject(obj)
            .then(() => {
              this.setState({ is_saving: false });
              this.onModalClose();
            })
            .catch((e) => {
              this.setState({ is_saving: false });
              console.warn(e);
            });
        }
      : () => {};

    this.archiveObject = props.archiveObject
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props
            .archiveObject(obj)
            .then(() => {
              this.setState({ is_saving: false });
            })
            .catch((e) => {
              this.setState({ is_saving: false });
              console.warn(e);
            });
        }
      : () => {};

    this.sendMessage = props.sendMessage
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props
            .sendMessage(obj)
            .then(() => {
              this.setState({ is_saving: false });
              this.onModalClose();
            })
            .catch((e) => {
              this.setState({ is_saving: false });
              console.warn(e);
            });
        }
      : () => {};

    this.updateObject = props.updateObject
      ? (invoiceObj, field, value, force) => {
          if (props.saveObject) {
            this.setState({ alteredContent: true });
          }
          let obj = props.updateObject(invoiceObj, field, value, force);
          this.setChosenObject(obj);
        }
      : () => {};

    this.deleteObject = props.deleteObject
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props.deleteObject(obj);
          this.setState({
            is_saving: false,
            is_editing_item: props.dontCloseRodal ? true : false,
          });
        }
      : () => {};

    this.refreshObjects = props.refreshObjects //&& this.state.fieldsToFilter.length > 0
      ? () => {
          this.setState({ is_loading: true });

          let fieldsToFilter = this.state.fieldsToFilter;
          let filterObjAPI = {};

          if (fieldsToFilter) {
            for (let i = 0; i < fieldsToFilter.length; i++) {
              let filterObj = fieldsToFilter[i];
              if (filterObj.filter_type === 'DATE') {
                if (filterObj.from_date)
                  filterObjAPI[filterObj.parameter + '_from'] =
                    filterObj.from_date;
                if (filterObj.to_date)
                  filterObjAPI[filterObj.parameter + '_to'] = filterObj.to_date;
              } else if (filterObj.filter_type === 'NUMERIC') {
                filterObjAPI[filterObj.parameter + '_operator'] =
                  filterObj.operator ? filterObj.operator : undefined;
                filterObjAPI[filterObj.parameter + '_number'] =
                  filterObj.number != null ? filterObj.number : undefined;
              } else if (
                filterObj.selectedValues &&
                filterObj.selectedValues.length > 0
              ) {
                filterObjAPI[
                  filterObj.parameter
                ] = `('${filterObj.selectedValues.join("','")}')`;
              }
            }

            filterObjAPI['offset'] = (this.state.curPage - 1) * PAGE_INTERVAL;
            filterObjAPI['interval'] = PAGE_INTERVAL;
            if (this.state.textFilter) {
              filterObjAPI['textFilter'] = this.state.textFilter;
            }
            if (filterObjAPI) {
              props
                .refreshObjects(filterObjAPI)
                .then(() => {
                  let maxEl = props.pagination
                    ? props.numberElements
                    : this.state.objectList.filter(this.filterList).length;
                  let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

                  this.setState({
                    maxEl: maxEl,
                    maxPage: maxPage,
                    is_loading: false,
                  });
                })
                .catch((err) => {
                  toast.error(
                    'Erro ao carregar elementos. Verifique internet e tente novamente.',
                  );
                  this.setState({ is_loading: false });
                });
            }
          }
        }
      : () => {};
    this.ListSize = this.state.objectList.length * 100;
    this.updateSelectList = props.updateSelectList;
    this.onDropAction = props.onDropAction;

    this.objectIdName = props.objectIdName;

    this.setStateFromProps(props);
  }

  componentDidMount() {
    window.addEventListener('resize', this.calculateLeftPosition);
    this.calculateLeftPosition();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateLeftPosition);
  }

  // Ensures that updated state will be re-rendered!
  componentWillReceiveProps(nextProps) {
    this.setStateFromProps(nextProps);
  }

  // Set state from props
  setStateFromProps(props) {
    let objectList = props.objectList;

    if (!props.objectList || !(props.objectList instanceof Array)) {
      objectList = [];
    }

    let maxEl = props.pagination
      ? props.numberElements
      : this.state.objectList.filter(this.filterList).length;

    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

    // List of Objects
    this.setState({
      objectList: objectList,
      maxEl: maxEl,
      maxPage: maxPage,
      relations: props.relations ? props.relations : [],
      authors: props.authors ? props.authors : [],
      subjects: props.subjects ? props.subjects : [],
      app: props.app ? props.app : [],
    });

    let chosenObject =
      props.objectList && props.objectList.find
        ? props.objectList.find((el) => {
            return el[this.objectIdName] === this.state.chosenObjectId;
          })
        : null;

    if (chosenObject) {
      this.setChosenObject(chosenObject);
    }
    if (this.state.attachModalVisible) {
      this.calculateLeftPosition();
    }
  }

  // UI Default Classes
  defaultObjectCard = function (props) {
    return (
      <Paper style={{ margin: '20px' }}>{JSON.stringify(props.object)}</Paper>
    );
  };

  defaultObjectDescription = function (props) {
    return (
      <Paper style={{ margin: '20px', height: '300px', width: '100%' }}>
        {JSON.stringify(props.object)}
      </Paper>
    );
  };

  // Event Functions
  onPopUpClose = () => {
    this.state.open = false;
    this.key++;
  };

  searchText_onClickFilter = () => {
    let that = this;

    if (this.objectModel) {
      this.objectModel.filters().then((filters) => {
        if (this.state.fieldsToFilter && this.state.fieldsToFilter.length > 0) {
          let fieldsToFilter = this.state.fieldsToFilter;
          for (let i = 0; i < fieldsToFilter.length; i++) {
            let filterObj = fieldsToFilter[i];
            let newFilterObj = filters.find((el) => {
              return el.parameter === filterObj.parameter;
            });
            newFilterObj.selectedValues = filterObj.selectedValues;
            newFilterObj.selectedOptions = filterObj.selectedOptions;
            newFilterObj.from_date = filterObj.from_date;
            newFilterObj.to_date = filterObj.to_date;
          }
        }

        let maxEl = this.props.pagination
          ? this.props.numberElements
          : this.state.objectList.filter(this.filterList).length;
        let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

        that.setState({
          fieldsToFilter: filters,
          showFilter: true,
          maxEl: maxEl,
          maxPage: maxPage,
        });
      });
    }
  };

  searchTimeout = null;

  searchText_onSearch = (event) => {
    this.setState({ textFilter: event });

    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      if (this.props.pagination) this.refreshObjects();
    }, 1000);
  };

  onModalClose = () => {
    if(this.state.chosenObject.store_name === null|| this.state.chosenObject.store_name === undefined ){
      toast.info('Para fechar adicione um nome')
      return;
     }else if(this.state.chosenObject.store_name?.trim().length===0){
       toast.info('Para fechar adicione um nome')
       return;
     }
    
    if (
      !this.state.alteredContent ||
      (this.state.alteredContent &&
        window.confirm(
          'O item atual ainda não foi salvo. ' +
            'Deseja fechar a janela? (As alterações serão perdidas!)',
        ))
    ) {
      this.setState({
        alteredContent: false,
        isCalled: false,
        is_editing_item: false,
        descriptionState: {},
        filteredList: {},
      });
    }
  };

  onModalAttachFileClose = () => {
    this.setState({ attachModalVisible: false, textFilterDoc: '' });
  };

  onModalUserListClose = () => {
    this.setState({ userListModalVisible: false, textFilterUserList: '' });
    
  };

  onModalStoreUserDescriptionClose = () => {
    this.setState({ userDescriptionModalVisible: false, /* textFilterUserDescription: "" */ });
    this.saveObject(this.state.chosenObject);
    this.refreshObjects();
}

  objectCard_CallDescription = (object) => {
    if (
      !this.state.alteredContent ||
      (this.state.alteredContent &&
        window.confirm(
          'O item atual ainda não foi salvo. ' +
            'Tem certeza que deseja abrir outro item antes de salvar o atual? ' +
            '(As alterações serão perdidas)',
        ))
    ) {
      object.start_commit_time = new Date();
      this.setState({
        alteredContent: false,
        isCalled: false,
        is_editing_item: true,
        descriptionState: {},
        filteredList: {},
      });

      if (object !== {}) {
        this.setChosenObject(object);
        let that = this;
        setTimeout(() => {
          that.setState({ isCalled: true });
        }, 1);
      }
    }
  };

  nullDescription = () => {
    this.setChosenObject({});
    this.setState({ isCalled: false });
  };

  updateUserList = (user) => {
    let userList = this.state.usersFilterList;
    userList.push(user);
    this.setState({ usersFilterList : userList});
};

updateObjectUser = (userObj, field, value, force) => {
            
    this.setState({ alteredContent: true });
    let userList = this.state.usersFilterList;
    let position = userList.findIndex((obj) => {
        return obj.id_user === userObj.id_user;
    });
    let userToUpdate = userList.find((obj) => {
        return obj.id_user === userObj.id_user;
    });

    if (!field) {
      for (let prop in value) {
          if (value.hasOwnProperty(prop)) {
              userToUpdate[prop] = value[prop];
          }
      }
  } else {
      // Format phone number
      if (field === "user_phone" && value && force) {
          try {
              value = Utils.correctPhoneNumber(value);
          } catch (err) {
              console.error(err);
          }
      }
      userToUpdate[field] = value;
  }
  userList[position] = userToUpdate;
  this.setState({ usersFilterList: userList });
  //this.setChosenObject(userObj);
  return userList[position];
  
};

saveObjectUser = async (userObj) => {
  this.setState({ is_saving: true, alteredContent: false });
  let userList = this.state.usersFilterList;
  let position = userList.findIndex((obj) => {
      return obj.id_user === userObj.id_user;
  });
  let userToUpdate = userList.find((obj) => {
      return obj.id_user === userObj.id_user;
  });
  userToUpdate = new User(userToUpdate)
  if (userToUpdate != null) {
    userToUpdate.is_approved = 1;
    userObj.is_approved = 1;
    let checkUserPhoneToUpdate = await User.searchPhoneNumber(userToUpdate.user_phone);
    if (!checkUserPhoneToUpdate || checkUserPhoneToUpdate === null || checkUserPhoneToUpdate.length === 0) {
      if (localStorage.getItem("ID_APP") == 4) {
        userToUpdate
            .saveUserWeb()
            .then((resultUser) => {
                // User upload to server worked
                userList[position] = resultUser;
                this.setState({ usersFilterList: userList });
                this.setState({ is_saving: false });
                toast.success("Usuário salvo com sucesso!");
            })
            .catch((err) => {
                this.setState({ is_saving: false });
                console.error(err);
                toast.error("Erro ao salvar usuário!");
            });
    } else {
        userList[position] = new User(userList[position])
        userToUpdate
            .save()
            .then((resultUser) => {
                // User upload to server worked
                userList[position] = userObj;
                this.setState({ usersFilterList: userList });
                this.setState({ is_saving: false });
                toast.success("Usuário salvo com sucesso!");
            })
            .catch((err) => {
                this.setState({ is_saving: false });
                console.error(err);
                toast.error("Erro ao salvar usuário!");
            });
    }
    } else  if (checkUserPhoneToUpdate.length > 0 && userToUpdate.id_user === checkUserPhoneToUpdate[0].id_user) {
      if (localStorage.getItem("ID_APP") == 4) {
        userToUpdate
            .saveUserWeb()
            .then((resultUser) => {
                // User upload to server worked
                userList[position] = resultUser;
                this.setState({ usersFilterList: userList });
                this.setState({ is_saving: false });
                toast.success("Usuário salvo com sucesso!");
            })
            .catch((err) => {
                this.setState({ is_saving: false });
                console.error(err);
                toast.error("Erro ao salvar usuário!");
            });
    } else {
        userList[position] = new User(userList[position])
            .save()
            .then((resultUser) => {
                // User upload to server worked
                userList[position] = userObj;
                this.setState({ usersFilterList: userList });
                this.setState({ is_saving: false });
                toast.success("Usuário salvo com sucesso!");
            })
            .catch((err) => {
                this.setState({ is_saving: false });
                console.error(err);
                toast.error("Erro ao salvar usuário!");
            });
    }
    } else {
      toast.error('Número já cadastrado!');
      this.setState({ is_saving: false });
        return;
    }
      
  }
};

deleteObjectUser = async (userObj) => {
  this.setState({ is_saving: true, alteredContent: false });
  let userList = this.state.usersFilterList;
  let checkUserPhoneToUpdate = await User.searchPhoneNumber(userObj.user_phone);
  let removeFromList = false;

  if (checkUserPhoneToUpdate && checkUserPhoneToUpdate.length > 0 && userObj.id_user !== checkUserPhoneToUpdate[0].id_user) {
    userObj.user_phone = null;
    userObj.user_name = null;
    userObj.user_email = null;
    userObj.user_cpf = null;
    removeFromList = true;
  }
  
  if(userObj.deleted_date) {
    userObj.deleted_date = null;
    this.saveObjectUser(userObj);
    return;
  } else {
    let userToRemove = new User(userObj)
    userToRemove.remove();
  }
  

  if (removeFromList) {
for (let i = 0; i < userList.length; i++) {
      if (userObj.id_user === userList[i].id_user) {
          /*userList[i] = new User(userList[i])
          userList[i].remove();*/
          userList.splice(i, 1);
          break;
        }
    }
  }

  // Finds Edited brand on brands list and change Brand_name value
  
    this.setState({
                is_saving: false,
                userDescriptionModalVisible: false,
            });

    this.setState({ usersFilterList: userList });
    this.saveObject(this.state.chosenObject);
    this.refreshObjects();
    
};

  updateDescriptionState = (descriptionState) => {
    let oldState = this.state.descriptionState;
    if (!oldState) {
      oldState = {};
    }

    for (let prop in descriptionState) {
      if (descriptionState.hasOwnProperty(prop)) {
        oldState[prop] = descriptionState[prop];
      }
    }
    descriptionState = oldState;

    this.setState({ descriptionState });
  };

  progressFunction = (completed) => {
    // If completed is an Object with props (else just saves a number)
    if (isNaN(completed)) {
      let completedState = this.state.completed;
      if (completedState === 0) {
        completedState = {};
      }
      for (let prop in completed) {
        if (completed.hasOwnProperty(prop)) {
          completedState[prop] = completed[prop];
        }
      }
      completed = completedState;
    }

    this.setState({ completed });
  };

  Description = (chosenObject) => {
    return new this.objectDescription({
      object: chosenObject,
      saveObject: this.saveObject,
      publishObject: this.publishObject,
      sendMessage: this.sendMessage,
      updateObject: this.updateObject,
      deleteObject: this.deleteObject,
      archiveObject: this.archiveObject,
      openAttachModal: this.openAttachModal,
      openUserListModal: this.openUserListModal,
      nullDescription: this.nullDescription,
      relations: this.state.relations,
      users: chosenObject.usersList ? chosenObject.usersList : [],
      authors: this.state.authors,
      subjects: this.state.subjects,
      showList: this.state.showList,
      progressFunction: this.progressFunction,
      completed: this.state.completed,
      updateDescriptionState: this.updateDescriptionState,
      descriptionState: this.state.descriptionState,
      app: this.state.app,
    });
  };

  DescriptionAttachment = (chosenObject) => {
    return new this.objectDescriptionAttachment({
      object: chosenObject,
      objectAttachList: this.state.documentsFilterList.filter(
        this.filterDocList,
      ),
      saveObject: this.saveObject,
      updateObject: this.updateObject,
      deleteObject: this.deleteObject,
      nullDescription: this.nullDescription,
      showList: this.state.showList,
      progressFunction: this.progressFunction,
      completed: this.state.completed,
      updateDescriptionState: this.updateDescriptionState,
      descriptionState: this.state.descriptionState,
      app: this.state.app,
      relations: this.state.relations,
      refreshObject: this.refreshObjects,
      searchDoc: this.searchDoc,
    });
  };

  /* Validar com o Augusto */
  StoreUserDescription = (chosenObject) => {
    return new this.objectStoreUserDescription({
        object: chosenObject,
        saveObject: this.saveObjectUser,
        updateObject: this.updateObjectUser,
        deleteObject: this.deleteObjectUser,
        nullDescription: this.nullDescription,
        showList: this.state.showList,
        progressFunction: this.progressFunction,
        completed: this.state.completed,
        updateDescriptionState: this.updateDescriptionState,
        descriptionState: this.state.descriptionState,
        app: this.state.app,
        relations: this.state.relations,
        refreshObject: this.refreshObjects,
        /* searchStoreUserDescription: this.searchStoreUserDescription */
    })
}

DescriptionUserList = (chosenObject) => {
    return new this.objectDescriptionUserList({
        object: chosenObject,
        objectUserList: this.state.usersFilterList.filter(this.filterUserList),
        saveObject: this.saveObject,
        updateObject: this.updateObject,
        deleteObject: this.deleteObject,
        nullDescription: this.nullDescription,
        showList: this.state.showList,
        progressFunction: this.progressFunction,
        completed: this.state.completed,
        updateDescriptionState: this.updateDescriptionState,
        descriptionState: this.state.descriptionState,
        openStoreUserDescriptionModal: this.openStoreUserDescriptionModal,
        app: this.state.app,
        relations: this.state.relations,
        refreshObject: this.refreshObjects,
        searchUserList: this.searchUser,
        updateUserList: this.updateUserList,
    });
};

  searchDoc = (filterText) => {
    this.setState({ textFilterDoc: filterText });
  };

  filterDocList = (doc, store) => {
    let storeObj = store;
    let docObj = doc;
    let filterDocList = store.store_documents ? store.store_documents : [];

    if (this.state.textFilterDoc && this.state.textFilterDoc !== '') {
      if (
        docObj.store_document_name
          .toUpperCase()
          .indexOf(this.state.textFilterDoc.toUpperCase()) < 0
      )
        return false;
    }

    return true;
  };

  searchUser = (filterText) => {
    this.setState({ textFilterUserList: filterText });
  };

  filterUserList = (user, store) => {
    let storeObj = store;
    let userObj = user;
    let filterUserList = store.usersList ? store.usersList : [];

    if (this.state.textFilterUserList && this.state.textFilterUserList !== '') {
      if (
        JSON.stringify(Object.values(userObj))
          .toUpperCase()
          .indexOf(this.state.textFilterUserList.toUpperCase()) < 0
      )
        return false;
    }

    return true;
  };

  openAttachModal = () => {
    this.calculateLeftPosition();
    this.setState({ attachModalVisible: true });
  };

  openUserListModal = () => {
    this.calculateLeftPosition();
    this.setState({ userListModalVisible: true });
  };

  openStoreUserDescriptionModal = (storeNewUser) => {
    this.calculateLeftPosition();
    this.setState({ userDescriptionModalVisible: true, chosenObjectUser : storeNewUser});
}

  setChosenObject = (chosenObject) => {
    this.calculateLeftPosition();

    this.setState({
      chosenObject: chosenObject,
      documentsFilterList: chosenObject.store_documents
        ? chosenObject.store_documents
        : [],
      usersFilterList: chosenObject.usersList,
    });

    if (chosenObject[this.objectIdName]) {
      this.setState({ chosenObjectId: chosenObject[this.objectIdName] });
    } else {
      this.setState({ chosenObjectId: null });
    }
  };

  filterList = (object) => {
    if (this.props.pagination) {
      return true;
    }
    if (this.state.fieldsToFilter && this.state.fieldsToFilter.length > 0) {
      for (let i = 0; i < this.state.fieldsToFilter.length; i++) {
        let filterObj = this.state.fieldsToFilter[i];
        // Check if there are filtered values
        if (
          filterObj.filter_type !== 'IGNOREFRONT' &&
          (filterObj.filter_type === 'DATE' ||
            (filterObj.selectedValues && filterObj.selectedValues.length > 0))
        ) {
          let objectValue = object[filterObj.parameter];
          let objectValueArray = [];

          // If parameter has dots, dive into object's hierarchy
          if (filterObj.parameter.indexOf('.') > 0) {
            let parameterList = filterObj.parameter.split('.');
            objectValue = object[parameterList[0]];
            if (objectValue instanceof Array) {
              for (let k = 0; k < objectValue.length; k++) {
                for (let j = 1; j < parameterList.length; j++) {
                  if (!objectValue) break;
                  if (objectValue[k])
                    objectValueArray.push(objectValue[k][parameterList[j]]);
                }
              }
            } else {
              for (let j = 1; j < parameterList.length; j++) {
                if (!objectValue) break;
                objectValueArray.push(objectValue[parameterList[j]]);

                if (j === parameterList.length - 1) {
                  break;
                }
              }
            }
          }
          if (objectValueArray.length > 0) {
            objectValue = objectValueArray;
          }
          if (filterObj.filter_type === 'DATE') {
            if (
              filterObj.from_date &&
              new Date(objectValue) < filterObj.from_date
            )
              return false;
            if (filterObj.to_date && new Date(objectValue) > filterObj.to_date)
              return false;
          } else {
            let isInFilter;
            // Searches object value in array of filtered values
            if (objectValue instanceof Array) {
              isInFilter = objectValue.filter((value) => {
                return filterObj.selectedValues.find((filterValue) => {
                  if (
                    !filterValue ||
                    filterValue === 'null' ||
                    filterValue === null ||
                    filterValue === 0 ||
                    filterValue === '0' ||
                    filterValue === 'undefined' ||
                    filterValue === undefined
                  ) {
                    return !objectValue;
                  }
                  return filterValue === value;
                });
              });
            } else {
              isInFilter = filterObj.selectedValues.find((filterValue) => {
                if (
                  !filterValue ||
                  filterValue === 'null' ||
                  filterValue === null ||
                  filterValue === 0 ||
                  filterValue === '0' ||
                  filterValue === 'undefined' ||
                  filterValue === undefined
                ) {
                  return !objectValue;
                }
                return filterValue === objectValue;
              });
            }
            if (isInFilter instanceof Array) {
              if (isInFilter.length == filterObj.selectedValues.length) {
                for (let i = 0; i < isInFilter.length; i++) {
                  if (
                    !filterObj.selectedValues.some(
                      (value) => value == isInFilter[i],
                    )
                  ) {
                    return false;
                  }
                }
              } else {
                return false;
              }
            }
            // Check if value is in filter array.
            // Sometimes, filter array has 0 number, so we need to check explicitly for that
            else if (!isInFilter && isInFilter !== 0) return false;
          }
        }
      }
    }

    if (this.state.textFilter && this.state.textFilter !== '') {
      if (
        JSON.stringify(Object.values(object))
          .toUpperCase()
          .indexOf(this.state.textFilter.toUpperCase()) < 0
      )
        return false;
    }

    return true;
  };

  nextPage = () => {
    let maxEl = this.props.pagination
      ? this.props.numberElements
      : this.state.objectList.filter(this.filterList).length;
    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

    let curPage = this.state.curPage;
    if (curPage < maxPage) {
      curPage++;
      if (this.props.pagination) {
        this.state.curPage = curPage;
        this.refreshObjects();
      } else {
        this.setState({ curPage });
      }
    }
  };

  previousPage = () => {
    let curPage = this.state.curPage;
    curPage--;
    if (curPage >= 1) {
      if (this.props.pagination) {
        this.state.curPage = curPage;
        this.refreshObjects();
      } else {
        this.setState({ curPage });
      }
    }
  };

  calculateLeftPosition = () => {
    setTimeout(() => {
      let leftOffset = 0;
      let modalWidth = 0;

      if (
        ReactDOM &&
        ReactDOM.findDOMNode(this.refs['GenericScreenContainer'])
      ) {
        leftOffset = ReactDOM.findDOMNode(
          this.refs['GenericScreenContainer'],
        ).getBoundingClientRect().left;
        modalWidth = window.innerWidth - leftOffset - 100;
      }

      this.setState({ leftOffset, modalWidth });
    }, 300);
  };

  // Final UI
  render() {
    let that = this;

    let maxEl = this.props.pagination
      ? this.props.numberElements
      : this.state.objectList.filter(this.filterList).length;
    let minElPage = (this.state.curPage - 1) * PAGE_INTERVAL + 1;
    let maxElPage =
      this.state.curPage * PAGE_INTERVAL > maxEl
        ? maxEl
        : this.state.curPage * PAGE_INTERVAL;

    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
        ref={'GenericScreenContainer'}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px 30px 10px 20px',
          }}
        >
          {this.addButton_placeholder ? (
            <div style={{ paddingRight: '10px' }}>
              <Button variant='contained' onClick={this.addObject}>
                <AddCircleIcon style={{ marginRight: '8px' }} />{' '}
                {this.addButton_placeholder}
              </Button>
            </div>
          ) : null}
          <div style={{ paddingRight: '10px' }}>
            <Button variant='contained' onClick={this.refreshObjects}>
              <i
                className='fa fa-fw fa-refresh'
                style={{ fontSize: '1.75em', verticalAlign: 'middle' }}
              />
            </Button>
          </div>
          <div style={{ flex: '1' }}>
            <SearchTextFieldWithFilter
              placeholder={this.searchText_placeholder}
              onSearch={this.searchText_onSearch}
              onClickFilter={this.searchText_onClickFilter}
            ></SearchTextFieldWithFilter>
          </div>
        </div>
        <div style={{ flex: '1' }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                position: 'relative',
                display: this.state.showList ? 'flex' : 'none',
              }}
            >
              <Grid container>
                <Grid xs={12} style={{ height: '82vh' }}>
                  <PerfectScrollbar
                    style={{
                      width: '100%',
                      height: '82vh',
                      overflow: 'hidden',
                      position: 'absolute',
                    }}
                    options={{ suppressScrollX: 'true' }}
                  >
                    {this.state.objectList
                      .filter(this.filterList)
                      .map((object, index) => {
                        return (
                          <div
                            style={{
                              marginBottom: '5px',
                              marginLeft: '20px',
                              marginRight: '20px',
                            }}
                          >
                            {
                              new this.objectCard({
                                object: object,
                                updateObject: this.updateObject,
                                deleteObject: this.deleteObject,
                                callDescription:
                                  this.objectCard_CallDescription,
                                relations: this.state.relations,
                                authors: this.state.authors,
                                subjects: this.state.subjects,
                                descriptionState: this.state.descriptionState
                                  ? this.state.descriptionState
                                  : null,
                                chosenId: this.state.chosenObjectId,
                              })
                            }
                          </div>
                        );
                      })
                      .slice(
                        this.props.pagination
                          ? 0
                          : (this.state.curPage - 1) * PAGE_INTERVAL,
                        this.props.pagination
                          ? undefined
                          : this.state.curPage * PAGE_INTERVAL,
                      )}
                  </PerfectScrollbar>
                </Grid>
                <Grid xs={12} style={{ marginBottom: '10px' }}>
                  <Button
                    variant='contained'
                    style={{ margin: '0px 10px', fontWeight: 'bolder' }}
                    onClick={this.previousPage}
                  >
                    &lt;
                  </Button>
                  Exibindo {minElPage}-{maxElPage} de {maxEl}
                  <Button
                    variant='contained'
                    style={{ margin: '0px 10px', fontWeight: 'bolder' }}
                    onClick={this.nextPage}
                  >
                    &gt;
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Rodal
          visible={this.state.is_editing_item}
          onClose={this.onModalClose}
          customStyles={{
            width: this.state.modalWidth,
            height: '80%',
            left: this.state.leftOffset,
          }}
        >
          <PerfectScrollbar
            style={{
              height: '80vh',
              overflow: 'hidden',
              position: 'absolute',
              width: '100%',
            }}
            options={{ suppressScrollX: 'true' }}
            ref={(ref) => {
              this._scrollBarRef = ref;
            }}
          >
            <div style={{ margin: '20px' }}>
              {this.state.chosenObject !== {} && this.state.isCalled === true
                ? this.Description(this.state.chosenObject)
                : ''}
            </div>
          </PerfectScrollbar>
        </Rodal>
        <Rodal
          visible={this.state.attachModalVisible}
          onClose={this.onModalAttachFileClose}
          customStyles={{
            width: this.state.modalWidth - 350,
            height: '75%',
            left: this.state.leftOffset,
            backgroundColor: '#eeeeee',
          }}
        >
          <PerfectScrollbar
            style={{
              height: '75vh',
              overflow: 'hidden',
              position: 'absolute',
              width: '100%',
            }}
            options={{ suppressScrollX: 'true', suppressScrollY: 'true' }}
            ref={(ref) => {
              this._scrollBarRef = ref;
            }}
          >
            <div style={{ margin: '20px' }}>
              {this.state.chosenObject !== {} &&
              this.state.attachModalVisible === true
                ? this.DescriptionAttachment(this.state.chosenObject)
                : ''}
            </div>
          </PerfectScrollbar>
        </Rodal>
        <Rodal
          visible={this.state.userListModalVisible}
          onClose={this.onModalUserListClose}
          customStyles={{
            width: this.state.modalWidth - 350,
            height: '75%',
            left: this.state.leftOffset,
            backgroundColor: '#eeeeee',
          }}
        >
          <PerfectScrollbar
            style={{
              height: '75vh',
              overflow: 'hidden',
              position: 'absolute',
              width: '100%',
            }}
            options={{ suppressScrollX: 'true', suppressScrollY: 'true' }}
            ref={(ref) => {
              this._scrollBarRef = ref;
            }}
          >
            <div style={{ margin: '20px' }}>
              {this.state.chosenObject !== {} &&
              this.state.userListModalVisible === true
                ? this.DescriptionUserList(this.state.chosenObject)
                : ''}
            </div>
          </PerfectScrollbar>
        </Rodal>
        <Rodal
                    visible={this.state.userDescriptionModalVisible}
                    onClose={this.onModalStoreUserDescriptionClose}
                    customStyles={{
                        width: this.state.modalWidth - 350,
                        height: "75%",
                        left: this.state.leftOffset,
                        backgroundColor: "#eeeeee"
                    }}
                >
                    <PerfectScrollbar
                        style={{
                            height: "75vh",
                            overflow: "hidden",
                            position: "absolute",
                            width: "100%",
                        }}
                        options={{ suppressScrollX: "true", suppressScrollY: "true" }}
                        ref={(ref) => {
                            this._scrollBarRef = ref;
                        }}
                    >
                        <div style={{ margin: "20px" }}>
                            {this.state.chosenObject !== {} && this.state.chosenObjectUser && this.state.userDescriptionModalVisible === true
                                ? this.StoreUserDescription(this.state.chosenObjectUser)
                                : ""}
                        </div>
                    </PerfectScrollbar>
                </Rodal>
                <Rodal
                    visible={this.state.attachModalVisible}
                    onClose={this.onModalAttachFileClose}
                    customStyles={{
                        width: this.state.modalWidth - 350,
                        height: "75%",
                        left: this.state.leftOffset,
                        backgroundColor: "#eeeeee"
                    }}
                >
                    <PerfectScrollbar
                        style={{
                            height: "75vh",
                            overflow: "hidden",
                            position: "absolute",
                            width: "100%",
                        }}
                        options={{ suppressScrollX: "true", suppressScrollY: "true" }}
                        ref={(ref) => {
                            this._scrollBarRef = ref;
                        }}
                    >
                        <div style={{ margin: "20px" }}>
                            {this.state.chosenObject !== {} && this.state.attachModalVisible === true
                                ? this.DescriptionAttachment(this.state.chosenObject)
                                : ""}
                        </div>
                    </PerfectScrollbar>
                </Rodal>
                <SlidingPane
                    isOpen={this.state.showFilter}
                    title="Filtros"
                    from="right"
                    width="40vw"
                    onRequestClose={() => this.setState({ showFilter: false })}
                >
                    <PerfectScrollbar
                        style={{
                            width: "100%",
                            height: "87vh",
                            overflow: "hidden",
                            position: "absolute",
                        }}
                        options={{ suppressScrollX: "true" }}
                        ref={(ref) => {
                            this._scrollBarRef = ref;
                        }}
                    >
                        {this.state.fieldsToFilter
                            ? this.state.fieldsToFilter.map((el) => {
                                el.operator = el.operator
                                    ? el.operator
                                    : el.possibleValues && el.possibleValues[0]
                                        ? el.possibleValues[0].value
                                        : null;

                                const handleChange = (selectedOptions) => {
                                    let selectedValues = selectedOptions.map((obj) => {
                                        return obj.value;
                                    });

                                    let fieldsToFilter = this.state.fieldsToFilter;
                                    let fieldObj = fieldsToFilter.find((filterObj) => {
                                        return filterObj.parameter === el.parameter;
                                    });

                                    fieldObj.selectedValues = selectedValues;
                                    fieldObj.selectedOptions = selectedOptions;

                                    if (this.onFilterReload) this.refreshObjects();

                                    let maxEl = this.props.pagination
                                        ? this.props.numberElements
                                        : this.state.objectList.filter(this.filterList).length;
                                    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

                                    that.setState({
                                        maxEl: maxEl,
                                        maxPage: maxPage,
                                        fieldsToFilter: fieldsToFilter,
                                    });
                                };

                                const handleDateChange = (date_field) => (event) => {
                                    event = event ? new Date(event) : undefined;

                                    let fieldsToFilter = this.state.fieldsToFilter;
                                    let fieldObj = fieldsToFilter.find((filterObj) => {
                                        return filterObj.parameter === el.parameter;
                                    });

                                    fieldObj[date_field] = event;

                                    if (this.onFilterReload) this.refreshObjects();

                                    let maxEl = this.props.pagination
                                        ? this.props.numberElements
                                        : this.state.objectList.filter(this.filterList).length;
                                    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

                                    that.setState({
                                        maxEl: maxEl,
                                        maxPage: maxPage,
                                    });

                                    //that.setState(fieldsToFilter);
                                };

                                const handleNumericChange = (numeric_field) => (event) => {
                                    let fieldsToFilter = this.state.fieldsToFilter;
                                    let fieldObj = fieldsToFilter.find((filterObj) => {
                                        return filterObj.parameter === el.parameter;
                                    });

                                    fieldObj[numeric_field] = event.target.value;

                                    if (this.onFilterReload) this.refreshObjects();

                                    let maxEl = this.props.pagination
                                        ? this.props.numberElements
                                        : this.state.objectList.filter(this.filterList).length;
                                    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

                                    that.setState({
                                        maxEl: maxEl,
                                        maxPage: maxPage,
                                    });

                                    that.setState(fieldsToFilter);
                                };

                                return (
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <p>{el.title}</p>
                                        </Grid>

                                        {el.filter_type === "DATE" ? (
                                            <Grid item xs={6}>
                                                <Grid container>
                                                    <Grid xs={6}>
                                                        <MuiPickersUtilsProvider
                                                            utils={DateFnsUtils}
                                                            style={{
                                                                border: "0px",
                                                                color: "black",
                                                                textDecoration: "none",
                                                            }}
                                                        >
                                                            <DateTimePicker
                                                                clearable
                                                                label="De"
                                                                value={el.from_date}
                                                                style={{
                                                                    width: "100%",
                                                                    textDecoration: "none",
                                                                }}
                                                                format="dd/MM/yyyy"
                                                                onChange={handleDateChange("from_date")}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <MuiPickersUtilsProvider
                                                            utils={DateFnsUtils}
                                                            style={{
                                                                border: "0px",
                                                                color: "black",
                                                                textDecoration: "none",
                                                            }}
                                                        >
                                                            <DateTimePicker
                                                                clearable
                                                                label="Até"
                                                                value={el.to_date}
                                                                style={{
                                                                    width: "100%",
                                                                    textDecoration: "none",
                                                                }}
                                                                format="dd/MM/yyyy"
                                                                onChange={handleDateChange("to_date")}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : el.filter_type === "NUMERIC" ? (
                                            <Grid item xs={6}>
                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            id="comparison_operator"
                                                            select
                                                            value={el.operator}
                                                            defaultValue={el.operator}
                                                            onChange={handleNumericChange("operator")}
                                                        >
                                                            {el.possibleValues.map((option) => (
                                                                <MenuItem
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ paddingLeft: "30px" }}>
                                                        <TextField
                                                            id="comparison_number"
                                                            type="number"
                                                            value={el.number}
                                                            defaultValue={el.number ? el.number : undefined}
                                                            onChange={handleNumericChange("number")}
                                                            inputProps={{ min: "0", max: "100", step: "1" }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid item xs={6}>
                                                <ReactMultiSelectCheckboxes
                                                    style={{ dropdownButton: { width: "100%" } }}
                                                    options={el.possibleValues}
                                                    defaultValue={el.selectedOptions}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            })
                            : null}
                    </PerfectScrollbar>
                </SlidingPane>
                <Modal
                    open={this.state.is_saving}
                    showCloseIcon={false}
                    focusTrapped={false}
                    center={true}
                    onClose={() => { }}
                >
                    <h2>Salvando alterações...</h2>
                </Modal>
                <Modal
                    open={this.state.is_loading}
                    showCloseIcon={false}
                    focusTrapped={false}
                    center={true}
                    onClose={() => { }}
                >
                    <h2>Carregando...</h2>
                </Modal>
                
            </div>
        );
    }
}

export default StoreAdminScreen;
