import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

export default function SaveButton(props) {

    const classes = useStyles();
    return (
        <Button
            onClick={props.onClickSave}
            variant="contained"
            color="primary"
            className={classes.button}
            style={props.style}
            disabled={props.disabled}
        >
            {props.text ? props.text : "Salvar"}
            {props.text ? props.text === "Enviar" ? (<SendIcon className={classes.rightIcon} />)
                : (<SaveIcon className={classes.rightIcon} />)
                : (<SaveIcon className={classes.rightIcon} />)}
        </Button>
    )
}