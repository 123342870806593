import React, { useState } from "react";
import GenericAdminScreen from "../components/generic_screens/GenericAdminScreen";
import ContentFormAdminScreen from "../components/generic_screens/ContentFormAdminScreen";
import { toast } from "react-toastify";
// DB Models
import Content from "../../models/Content";
import Role from "../../models/Role";

import ContentCard from "../components/cards/ContentCard";
import ContentTrailCard from "../components/cards/ContentFormCard";
import ContentDescriptionFormResearch from "../components/description_cards/ContentDescriptionFormResearch";
import Spinner from "../components/buttons/Spinner";
import { useEffect } from "react";

const AdminContentInboxScreen = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [contents, setContents] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      let contents = await Content.getInbox();
      setContents(contents);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <p></p>
    </>
  );
};

export default AdminContentInboxScreen;
