import React from 'react';
import {
  Grid, TextareaAutosize
} from '@material-ui/core';
import DeleteButton from "../buttons/DeleteButton";

export default function FormQuestionCard(props) {

  let faqQuestion = props.faqQuestion;
  let idxQuestion = props.idx;

  let onQuestionChange = props.onQuestionChange ? props.onQuestionChange : () => {};

  let onClickDeleteQuestion = () => {
    if (props.deleteQuestion) {
      props.deleteQuestion();
    }
  };

  return (<Grid tabIndex={0} style={{
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    marginBottom: '5px',
    backgroundColor: '#f2f2f2'
  }}>
    <Grid style={{display: 'flex', flexDirection: 'row', flex: 1}}>
      <Grid style={{flex: 1}}>
        <span style={{
          fontSize: '16px',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: 400,
          lineHeight: 1,
          letterSpacing: '0.00938em',
          float: 'left',
          color: 'rgba(0, 0, 0, 0.54)',
          paddingBottom: '0px'
        }}>
          # {idxQuestion + 1}.
        </span>
      </Grid>
      <Grid style={{flex: 5}}>
        <TextareaAutosize
          aria-label="Pergunta"
          placeholder="Pergunta"
          rows={2}
          value={faqQuestion.faq_question}
          onChange={onQuestionChange('faq_question', idxQuestion)}
          style={{
            'width': '95%',
            padding: '10px 10px'
          }}
        />
      </Grid>
    </Grid>
    <Grid style={{display: 'flex', flexDirection: 'row'}}>
      <Grid style={{flex: 1}} />
      <Grid style={{flex: 5}}>
        <TextareaAutosize
          aria-label="Resposta"
          placeholder="Resposta"
          rows={2}
          value={faqQuestion.faq_answer}
          onChange={onQuestionChange('faq_answer', idxQuestion)}
          style={{
            'width': '95%',
            padding: '10px 10px'
          }}
        />
      </Grid>
    </Grid>
    <Grid style={{display: 'flex', flexDirection: 'row'}}>
      <DeleteButton
        style={{float: 'right', background: '#CC0B0B', width: '100%'}}
        onClickDelete={onClickDeleteQuestion}
        text={"Remover Pergunta"}/>
    </Grid>
  </Grid>)
}