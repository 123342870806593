import React, { Component } from 'react';
import ContentTaskAdminScreen from '../components/generic_screens/ContentTaskAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Content from "../../models/Content";
import Role from "../../models/Role";
import TopicsOfInterest from "../../models/TopicOfInterest";

import ContentCard from "../components/cards/ContentEngagementCard";
import ContentDescription from "../components/description_cards/ContentTaskDescription";
import DescriptionTasks from "../components/description_cards/TasksDescription";
import Spinner from '../components/buttons/Spinner';


class AdminContentTaskScreen extends Component {
  editionTimeout = null;

  state = {
    contents: [],
    content: [],
    relations: [],
    filters: [],
    subjects: [],
    brands: [],
    loading:true,
    is_selecting_type: false,
  };

  // TEXTS
  searchText_placeholder = 'Título da Tarefa';
  addButton_placeholder = 'Tarefa';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    Content.query()
      .then((contents) => {
        contents = contents.filter((content) => {
           if(localStorage.getItem('ID_APP') === '31') {
            return content.content_type === 4;
           } else {
            return content.is_challenge && !content.id_message;
           }
          
        });
        this.setState({ contents: contents });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
    Role.query().then((roles) => {
      let relations = this.state.relations;
      relations[0] = roles;
      this.setState({ relations: relations });
    });
    Content.query().then((content) => {
      let contents = this.state.content;
      contents[0] = content;
      this.setState({ content: contents });
    });
    TopicsOfInterest.query().then((topics) => {
      let subjects = this.state.subjects;
      subjects[0] = topics;
      this.setState({ subjects: subjects });
    });
    this.reloadContent();
  }

  async reloadContent(parameters) {
    try {
      let contents = await Content.query(parameters);
      contents = contents.filter((content) => {
        if(localStorage.getItem('ID_APP') === '31') {
          return content.content_type === 4;
         } else {
          return content.is_challenge && !content.id_message && content.form?.form_type !== 6 && content.form?.form_type !== 3;
         }
      });
      contents = contents.sort((obj1, obj2) => {
        let obj1_order_content = obj1.order_content;
        let obj2_order_content = obj2.order_content;

        if (!obj1_order_content) {
          obj1_order_content = 99999999999999;
        }

        if (!obj2_order_content) {
          obj2_order_content = 99999999999999;
        }

        if (obj1.is_fixed) {
          if (obj2.is_fixed) {
            if (obj1_order_content < obj2_order_content) {
              return -1;
            } else if (obj1_order_content > obj2_order_content) {
              return 1;
            }
          } else {
            return -1;
          }
        } else {
          if (obj2.is_fixed) {
            return 1;
          } else {
            if (obj1_order_content < obj2_order_content) {
              return -1;
            } else if (obj1_order_content > obj2_order_content) {
              return 1;
            }
          }
        }

        if (obj1.id_content < obj2.id_content) {
          return -1;
        } else {
          return 1;
        }
      });

      this.setState({ loading: false });
      this.setState({ contents: contents });
    } catch (err) {
     toast.error('Erro ao carregar conteúdos');
    }
  }

  addContent = async (contentType) => {
    let newContent = new Content();
    newContent.content = {
      id_app: localStorage.getItem('ID_APP'),
      is_challenge: 1,
      content_type: contentType,
    };
    let contentObj = {};
    try {
      if (contentType === 6) {
        contentObj = await newContent.saveMainTrail();
      } else {
        contentObj = await newContent.save();
      }
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let contentList = this.state.contents;
    contentList.push(contentObj);
    this.setState({ contents: contentList });
    return contentObj;
  };

  updateContent = (contentObj, field, value, force) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_content === contentObj.id_content;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          contentToUpdate[prop] = value[prop];
        }
      }
    } else if (field.indexOf('.') > 0) {
      let hierarchy = field.split('.');
      let obj = contentToUpdate;
      for (let i = 0; i < hierarchy.length; i++) {
        if (i === hierarchy.length - 1) {
          obj[hierarchy[i]] = value;
          break;
        }
        obj = obj[hierarchy[i]];
      }
    } else {
      contentToUpdate[field] = value;
    }

    this.setState({ contents: contentList });
    return contentList[position];
  };

  deleteContent = async (contentObj) => {
    let contentList = this.state.contents;

    // Finds Edited content on contents list and change Content_name value
    for (let i = 0; i < contentList.length; i++) {
      if (contentObj.id_content === contentList[i].id_content) {
        if (contentObj.content_type === 6) {
          Content.getContentTrail(contentObj.id_content).then(
            async (contentTrail) => {
              if (contentTrail && contentTrail.length) {
                for (let j = 0; j < contentTrail.length; j++) {
                  contentTrail[j].remove();
                }
              }
            },
          );
        }
        contentList[i].remove();
        contentList.splice(i, 1);
        break;
      }
    }

    this.setState({ contents: contentList });
  };

  saveContent = async (contentObj) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_content === contentObj.id_content;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    if (contentToUpdate != null) {
      let contentObj = contentToUpdate;

      let content_quiz = contentList.find((obj) => {
        if (obj.id_content !== contentToUpdate.id_content) {
          return obj.id_content_quiz === contentToUpdate.id_content_quiz;
        }
      });

      try {
        if (contentToUpdate.content_type === 3 || contentToUpdate.content_type === 8) {
          if (
            contentToUpdate.form.forms_questions &&
            contentToUpdate.form.forms_questions.length
          ) {
            let form_questions = contentToUpdate.form.forms_questions;
            for (let k = 0; k < form_questions.length; k++) {
              if (form_questions[k].form_question_type === 1) {
                if (!form_questions[k].forms_choices) {
                  throw new Error('form_choices');
                } else if (
                  !form_questions[k].forms_choices.length ||
                  form_questions[k].forms_choices.length === 0
                ) {
                  throw new Error('form_choices');
                } else if (!form_questions[k].forms_choices[0].form_choice) {
                  throw new Error('form_choices');
                }
              }
            }
          } else {
            throw new Error('form_questions');
          }
        }
        if (contentToUpdate.content_type === 8) {
          if (!contentToUpdate.id_content_quiz) {
            throw new Error('null_content_quiz');
          } else if (content_quiz) {
            throw new Error('repeated_content_quiz');
          }
        }
        contentObj = await contentToUpdate.save();
        if (contentObj.content_type === 6) {
          Content.getContentTrail(contentObj.id_content).then(
            async (contentTrail) => {
              if (contentTrail && contentTrail.length) {
                for (let j = 0; j < contentTrail.length; j++) {
                  contentTrail[j].is_challenge = contentObj.is_challenge;
                  contentTrail[j].is_fixed = contentObj.is_fixed;
                  contentTrail[j].is_marketing = contentObj.is_marketing;
                  contentTrail[j].roles = contentObj.roles;
                  contentTrail[j].starting_content =
                    contentObj.starting_content;
                  contentTrail[j].is_archived = contentObj.is_archived;
                  contentTrail[j].is_published = contentObj.is_published;
                  await contentTrail[j].save();
                }
              }
            },
          );
        }
        // Content upload to server worked
        contentList[position] = contentObj;
        this.setState({ contents: contentList });
        toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
        switch (err.message) {
          case 'form_questions':
           toast.error(
              'Erro ao salvar conteúdo! Elabore uma pergunta para seu formulário.',
            );
            break;
          case 'form_choices':
           toast.error(
              'Erro ao salvar conteúdo! Pergunta do tipo múltipla deve ter pelo menos uma alternativa.',
            );
            break;
          case 'null_content_quiz':
              toast(
                'Erro ao salvar conteúdo! Selecione um conteúdo para o Quiz.',
              );
              break;
          case 'repeated_content_quiz':
              toast(
                'Erro ao salvar conteúdo! Esse conteúdo já pertence a outro Quiz.',
              );
              break;
          default:
           toast.error('Erro ao salvar conteúdo!');
            break;
        }
      }
    }
  };

  refreshContents = async (parameters) => {
   await this.reloadContent(parameters);
};

openModalTarefas = () => {
    this.setState({ is_selecting_type: true });
};

closeModalTarefas = () => {
    this.setState({ is_selecting_type: false });
};


render() {
    return (
        // dani
        <> 
        {this.state.contents.length===0 
        &&this.state.loading
        ? <Spinner
         margin='13px' 
        texto={'carregando...'}
        border={'#9BB3D4'}
        top={'#1D2D44'}/>
        :
        <ContentTaskAdminScreen
            specify={this.specify}
            searchText_placeholder={this.searchText_placeholder}
            addButton_placeholder={this.addButton_placeholder}
            objectList={this.state.contents}
            objectCard={ContentCard}
            objectDescription={ContentDescription}
            objectDescriptionTasks={DescriptionTasks}
            subjects={this.state.subjects}
            contents={this.state.contents}
            content={this.state.content}
            addObject={this.addContent}
            updateObject={this.updateContent}
            deleteObject={this.deleteContent}
            saveObject={this.saveContent}
            refreshObjects={this.refreshContents}
            brands={this.state.brands}
            relations={this.state.relations}
            objectIdName={"id_content"}
            objectModel={Content}
            onFilterReload={true}
            modalTarefasVisibile={this.state.is_selecting_type}
            openModalTarefas={this.openModalTarefas}
            closeModalTarefas={this.closeModalTarefas}
        ></ContentTaskAdminScreen>
        }
        </>
    );
  }
}

export default AdminContentTaskScreen;
