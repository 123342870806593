// include styles for modal
import "../../../../node_modules/rodal/lib/rodal.css";

// Scrollbar Style
import "../../../../node_modules/react-perfect-scrollbar/dist/css/styles.css";

import React, { Component } from "react";
import ReactDOM from "react-dom";
import Rodal from "rodal";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, Paper, TextField, MenuItem } from "@material-ui/core";
import SearchTextFieldWithFilter from "../text_fields/SearchTextFieldWithFilter";
import PerfectScrollbar from "react-perfect-scrollbar";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { DateTimePicker } from "@material-ui/pickers/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Modal from "react-responsive-modal";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import ListIcon from "@material-ui/icons/List";
import { toast } from "react-toastify";
const PAGE_INTERVAL = 20;

// const moment = require('moment');

class RoleAdminScreen extends Component {
  state = {
    alteredContent: false,
    objectList: [],
    objectListTypes: [],
    chosenObject: {},
    chosenObjectId: {},
    isCalled: false,
    relations: [],
    authors: [],
    subjects: [],
    contents: [],
    open: false,
    showList: true,
    showFilter: false,
    fieldsToFilter: [],
    curPage: 1,
    maxPage: 1,
    maxEl: 0,
    completed: 0,
    is_saving: false,
    is_loading: false,
    is_editing_item: false,
    descriptionState: {},
    leftOffset: 0,
    modalWidth: 0,
    role_name: "",
    add_role: false,
  };

  constructor(props) {
    super(props);
    this.props = props;

    this.objectModel = props.objectModel;

    this.onFilterReload = props.onFilterReload;

    // Parameters
    this.addButton_placeholder = props.addButton_placeholder;
    this.bannerButton_placeholder = props.bannerButton_placeholder;
    this.searchText_placeholder = props.searchText_placeholder;
    this.hideAddButton = props.hideAddButton;
    this.key = props.key;

    // UI Classes
    this.objectCard = props.objectCard
      ? props.objectCard
      : this.defaultObjectCard;
    this.objectDescription = props.objectDescription
      ? props.objectDescription
      : this.defaultObjectDescription;

    // Functions
    this.addObject = props.addObject
      ? async (role_name) => {
          this.setState({ isCalled: false });
          let obj = await props.addObject(role_name);
          this.setChosenObject(obj);
          this.setState({
            isCalled: true,
            is_editing_item: true,
            role_name: role_name,
            add_role: false,
            role_name_type: "",
          });
        }
      : () => {};

    this.addObjectType = props.addObjectType
      ? async (role_name, id_role_ref) => {
          this.setState({ isCalled: false });
          let obj = await props.addObjectType(
            role_name,
            id_role_ref,
            this.state.objectListTypes
          );
          let roleTypes = this.state.objectListTypes;
          roleTypes.push(obj);
          this.setState({
            role_name_type: "",
            isCalled: true,
            objectListTypes: roleTypes,
          });
        }
      : () => {};

    this.addBanner = props.addBanner
      ? async () => {
          this.setState({ isCalled: false });
          let obj = await props.addBanner();
          this.setChosenObject(obj);
          this.setState({
            isCalled: true,
            is_editing_item: true,
          });
        }
      : () => {};

    this.saveObject = props.saveObject
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props
            .saveObject(obj)
            .then(() => {
              this.setState({ is_saving: false });
            })
            .catch((e) => {
              this.setState({ is_saving: false });
              console.warn(e);
            });
        }
      : () => {};

    this.publishObject = props.saveObject
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props
            .saveObject(obj)
            .then(() => {
              this.setState({ is_saving: false });
              this.onModalClose();
            })
            .catch((e) => {
              this.setState({ is_saving: false });
              console.warn(e);
            });
        }
      : () => {};

    this.archiveObject = props.archiveObject
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props
            .archiveObject(obj)
            .then(() => {
              this.setState({ is_saving: false });
            })
            .catch((e) => {
              this.setState({ is_saving: false });
              console.warn(e);
            });
        }
      : () => {};

    this.sendMessage = props.sendMessage
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props
            .sendMessage(obj)
            .then(() => {
              this.setState({ is_saving: false });
              this.onModalClose();
            })
            .catch((e) => {
              this.setState({ is_saving: false });
              console.warn(e);
            });
        }
      : () => {};

    this.updateObject = props.updateObject
      ? (invoiceObj, field, value, force) => {
          if (props.saveObject) {
            this.setState({ alteredContent: true });
          }
          let obj = props.updateObject(invoiceObj, field, value, force);
          this.setChosenObject(obj);
        }
      : () => {};

    this.deleteObject = props.deleteObject
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props.deleteObject(obj);
          this.setState({
            is_saving: false,
            is_editing_item: props.dontCloseRodal ? true : false,
            deleted_role: false
          });
        }
      : () => {};

    this.deleteObjectType = props.deleteObjectType
      ? (obj) => {
          this.setState({ is_saving: true, alteredContent: false });
          props.deleteObjectType(obj, this.state.objectListTypes);
          let roleList = this.state.objectListTypes;

          // Finds Edited brand on brands list and change Brand_name value
          for (let i = 0; i < roleList.length; i++) {
            if (obj.id_role === roleList[i].id_role) {
              roleList.splice(i, 1);
              break;
            }
          }
          this.setState({
            is_saving: false,
            objectListTypes: roleList,
          });
        }
      : () => {};

    this.refreshObjects = props.refreshObjects //&& this.state.fieldsToFilter.length > 0
      ? () => {
          this.setState({ is_loading: true });

          let fieldsToFilter = this.state.fieldsToFilter;
          let filterObjAPI = {};

          if (fieldsToFilter) {
            for (let i = 0; i < fieldsToFilter.length; i++) {
              let filterObj = fieldsToFilter[i];
              if (filterObj.filter_type === "DATE") {
                if (filterObj.from_date)
                  filterObjAPI[filterObj.parameter + "_from"] =
                    filterObj.from_date;
                if (filterObj.to_date)
                  filterObjAPI[filterObj.parameter + "_to"] = filterObj.to_date;
              } else if (filterObj.filter_type === "NUMERIC") {
                filterObjAPI[filterObj.parameter + "_operator"] =
                  filterObj.operator ? filterObj.operator : undefined;
                filterObjAPI[filterObj.parameter + "_number"] =
                  filterObj.number != null ? filterObj.number : undefined;
              } else if (
                filterObj.selectedValues &&
                filterObj.selectedValues.length > 0
              ) {
                filterObjAPI[
                  filterObj.parameter
                ] = `('${filterObj.selectedValues.join("','")}')`;
              }
            }

            filterObjAPI["offset"] = (this.state.curPage - 1) * PAGE_INTERVAL;
            filterObjAPI["interval"] = PAGE_INTERVAL;
            if (this.state.textFilter) {
              filterObjAPI["textFilter"] = this.state.textFilter;
            }
            if (filterObjAPI) {
              props
                .refreshObjects(filterObjAPI)
                .then(() => {
                  let maxEl = props.pagination
                    ? props.numberElements
                    : this.state.objectList.filter(this.filterList).length;
                  let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

                  this.setState({
                    maxEl: maxEl,
                    maxPage: maxPage,
                    is_loading: false,
                  });
                })
                .catch((err) => {
                  toast.error(
                    "Erro ao carregar elementos. Verifique internet e tente novamente."
                  );
                  this.setState({ is_loading: false });
                });
            }
          }
        }
      : () => {};
    this.ListSize = this.state.objectList.length * 100;
    this.updateSelectList = props.updateSelectList;
    this.onDropAction = props.onDropAction;

    this.objectIdName = props.objectIdName;

    this.setStateFromProps(props);
  }

  componentDidMount() {
    window.addEventListener("resize", this.calculateLeftPosition);
    this.calculateLeftPosition();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateLeftPosition);
  }

  // Ensures that updated state will be re-rendered!
  componentWillReceiveProps(nextProps) {
    this.setStateFromProps(nextProps);
  }

  // Set state from props
  setStateFromProps(props) {
    let objectList = props.objectList;

    if (!props.objectList || !(props.objectList instanceof Array)) {
      objectList = [];
    }

    let maxEl = props.pagination
      ? props.numberElements
      : this.state.objectList.filter(this.filterList).length;

    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

    // List of Objects
    this.setState({
      objectList: objectList,
      maxEl: maxEl,
      maxPage: maxPage,
      relations: props.relations ? props.relations : [],
      authors: props.authors ? props.authors : [],
      subjects: props.subjects ? props.subjects : [],
      contents: props.contents ? props.contents : [],

      app: props.app ? props.app : [],
    });

    let chosenObject =
      props.objectList && props.objectList.find
        ? props.objectList.find((el) => {
            return el[this.objectIdName] === this.state.chosenObjectId;
          })
        : null;

    if (chosenObject) {
      this.setChosenObject(chosenObject);
    }
  }

  // UI Default Classes
  defaultObjectCard = function (props) {
    return (
      <Paper style={{ margin: "20px" }}>{JSON.stringify(props.object)}</Paper>
    );
  };

  defaultObjectDescription = function (props) {
    return (
      <Paper style={{ margin: "20px", height: "300px", width: "100%" }}>
        {JSON.stringify(props.object)}
      </Paper>
    );
  };

  // Event Functions
  onPopUpClose = () => {
    this.state.open = false;
    this.key++;
  };

  searchText_onClickFilter = () => {
    let that = this;

    if (this.objectModel) {
      this.objectModel.filters().then((filters) => {
        if (this.state.fieldsToFilter && this.state.fieldsToFilter.length > 0) {
          let fieldsToFilter = this.state.fieldsToFilter;
          for (let i = 0; i < fieldsToFilter.length; i++) {
            let filterObj = fieldsToFilter[i];
            let newFilterObj = filters.find((el) => {
              return el.parameter === filterObj.parameter;
            });
            newFilterObj.selectedValues = filterObj.selectedValues;
            newFilterObj.selectedOptions = filterObj.selectedOptions;
            newFilterObj.from_date = filterObj.from_date;
            newFilterObj.to_date = filterObj.to_date;
          }
        }

        let maxEl = this.props.pagination
          ? this.props.numberElements
          : this.state.objectList.filter(this.filterList).length;
        let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

        that.setState({
          fieldsToFilter: filters,
          showFilter: true,
          maxEl: maxEl,
          maxPage: maxPage,
          curPage: 1,
        });
      });
    }
  };

  searchTimeout = null;

  searchText_onSearch = (event) => {
    this.setState({ textFilter: event });
    this.setState({ curPage: 1 });

    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      if (this.props.pagination) this.refreshObjects();
    }, 1000);
  };

  handleRoleNameChange = (event) => {
    this.setState({ role_name: event });
    
  };
  handleRoleNameChange2 = (event) => {
    if(this.state.chosenObject) {
      this.updateObject(this.state.chosenObject, 'role_name', event, false)
                          
    }
    
  };

  handleRoleNameTypeChange = (event) => {
    this.setState({ role_name_type: event });
  };

  onModalClose = () => {
    // console.log(this.state.chosenObject, "PRINT DO OBJETO ");
    // console.log(this.state.chosenObject)
    /*let basic =
      this.state.chosenObject.faq_topic !== undefined
        ? this.state.chosenObject.faq_topic
        : this.state.chosenObject.manual_title;
    basic = basic ? basic : this.state.chosenObject.brand_name;
    basic = basic ? basic : this.state.chosenObject.role_name;
    basic = basic ? basic : this.state.chosenObject.user_name;
    basic = basic ? basic : this.state.chosenObject.topic_name;
    basic = basic ? basic : this.state.chosenObject.training_title;
    basic = basic ? basic : this.state.chosenObject.form_title;
    basic = basic ? basic : this.state.chosenObject.title;
    basic = basic ? basic : this.state.chosenObject.message_title;
    basic = basic ? basic : this.state.chosenObject.class_title;
    let jump = this.state.chosenObject?.user?.id_user ? true : false;

    if (!jump) {
      if (!basic) {
        toast.info("Para fechar adicione um titulo, tópico ou nome");
        return;
      } else if (basic?.trim().length === 0) {
        toast.info("Para fechar adicione um titulo, tópico ou nome");
        return;
      }
    }
    // console.log(basic);

    if (
      !this.state.alteredContent ||
      (this.state.alteredContent &&
        window.confirm(
          "O item atual ainda não foi salvo. " +
            "Deseja fechar a janela? (As alterações serão perdidas!)"
        ))
    ) {
      this.setState({
        alteredContent: false,
        isCalled: false,
        is_editing_item: false,
        descriptionState: {},
        filteredList: {},
      });
    }*/
    this.setState({
      alteredContent: false,
      isCalled: false,
      is_editing_item: false,
      descriptionState: {},
      filteredList: {},
      add_role: false,
    });
  };

  objectCard_CallDescription = (object) => {
    if (
      !this.state.alteredContent ||
      (this.state.alteredContent &&
        window.confirm(
          "O item atual ainda não foi salvo. " +
            "Tem certeza que deseja abrir outro item antes de salvar o atual? " +
            "(As alterações serão perdidas)"
        ))
    ) {
      object.start_commit_time = new Date();
      this.setState({
        alteredContent: false,
        isCalled: false,
        is_editing_item: true,
        descriptionState: {},
        filteredList: {},
      });

      if (object !== {}) {
        this.setChosenObject(object);
        let that = this;
        setTimeout(() => {
          that.setState({ isCalled: true });
        }, 1);
      }
    }
  };

  nullDescription = () => {
    this.setChosenObject({});
    this.setState({ isCalled: false });
  };

  updateDescriptionState = (descriptionState) => {
    let oldState = this.state.descriptionState;
    if (!oldState) {
      oldState = {};
    }

    for (let prop in descriptionState) {
      if (descriptionState.hasOwnProperty(prop)) {
        oldState[prop] = descriptionState[prop];
      }
    }
    descriptionState = oldState;

    this.setState({ descriptionState });
  };

  progressFunction = (completed) => {
    // If completed is an Object with props (else just saves a number)
    if (isNaN(completed)) {
      let completedState = this.state.completed;
      if (completedState === 0) {
        completedState = {};
      }
      for (let prop in completed) {
        if (completed.hasOwnProperty(prop)) {
          completedState[prop] = completed[prop];
        }
      }
      completed = completedState;
    }

    this.setState({ completed });
  };

  Description = (chosenObject) => {
    return new this.objectDescription({
      object: chosenObject,
      saveObject: this.saveObject,
      publishObject: this.publishObject,
      sendMessage: this.sendMessage,
      updateObject: this.updateObject,
      deleteObject: this.deleteObject,
      archiveObject: this.archiveObject,
      nullDescription: this.nullDescription,
      relations: this.state.relations,
      authors: this.state.authors,
      subjects: this.state.subjects,
      contents: this.state.contents,
      showList: this.state.showList,
      progressFunction: this.progressFunction,
      completed: this.state.completed,
      updateDescriptionState: this.updateDescriptionState,
      descriptionState: this.state.descriptionState,
      app: this.state.app,
    });
  };

  setChosenObject = (chosenObject) => {
    this.calculateLeftPosition();
    let objectListTypes = this.props.objectTypeList;

    if (objectListTypes && objectListTypes.length > 0) {
      objectListTypes = objectListTypes.filter((roletype) => {
        return roletype.id_role_ref === chosenObject.id_role && !roletype.deleted_date;
      });
    }


    this.setState({ objectListTypes: objectListTypes });

    this.setState({ chosenObject: chosenObject });
    if (chosenObject[this.objectIdName]) {
      this.setState({ chosenObjectId: chosenObject[this.objectIdName] });
    } else {
      this.setState({ chosenObjectId: null });
    }
  };

  filterList = (object) => {
    if (this.props.pagination) {
      return true;
    }
    if (this.state.fieldsToFilter && this.state.fieldsToFilter.length > 0) {
      for (let i = 0; i < this.state.fieldsToFilter.length; i++) {
        let filterObj = this.state.fieldsToFilter[i];
        // Check if there are filtered values
        if (
          filterObj.filter_type !== "IGNOREFRONT" &&
          (filterObj.filter_type === "DATE" ||
            (filterObj.selectedValues && filterObj.selectedValues.length > 0))
        ) {
          let objectValue = object[filterObj.parameter];
          let objectValueArray = [];

          // If parameter has dots, dive into object's hierarchy
          if (filterObj.parameter.indexOf(".") > 0) {
            let parameterList = filterObj.parameter.split(".");
            objectValue = object[parameterList[0]];
            if (objectValue instanceof Array) {
              for (let k = 0; k < objectValue.length; k++) {
                for (let j = 1; j < parameterList.length; j++) {
                  if (!objectValue) break;
                  if (objectValue[k])
                    objectValueArray.push(objectValue[k][parameterList[j]]);
                }
              }
            } else {
              for (let j = 1; j < parameterList.length; j++) {
                if (!objectValue) break;
                objectValueArray.push(objectValue[parameterList[j]]);

                if (j === parameterList.length - 1) {
                  break;
                }
              }
            }
          }
          if (objectValueArray.length > 0) {
            objectValue = objectValueArray;
          }
          if (filterObj.filter_type === "DATE") {
            if (
              filterObj.from_date &&
              new Date(objectValue) < filterObj.from_date
            )
              return false;
            if (filterObj.to_date && new Date(objectValue) > filterObj.to_date)
              return false;
          } else {
            let isInFilter;
            // Searches object value in array of filtered values
            if (objectValue instanceof Array) {
              isInFilter = objectValue.filter((value) => {
                return filterObj.selectedValues.find((filterValue) => {
                  if (
                    !filterValue ||
                    filterValue === "null" ||
                    filterValue === null ||
                    filterValue === 0 ||
                    filterValue === "0" ||
                    filterValue === "undefined" ||
                    filterValue === undefined
                  ) {
                    return !objectValue;
                  }
                  return filterValue === value;
                });
              });
            } else {
              isInFilter = filterObj.selectedValues.find((filterValue) => {
                if (
                  !filterValue ||
                  filterValue === "null" ||
                  filterValue === null ||
                  filterValue === 0 ||
                  filterValue === "0" ||
                  filterValue === "undefined" ||
                  filterValue === undefined
                ) {
                  return !objectValue;
                }
                return filterValue === objectValue;
              });
            }
            if (isInFilter instanceof Array) {
              if (isInFilter.length == filterObj.selectedValues.length) {
                for (let i = 0; i < isInFilter.length; i++) {
                  if (
                    !filterObj.selectedValues.some(
                      (value) => value == isInFilter[i]
                    )
                  ) {
                    return false;
                  }
                }
              } else {
                return false;
              }
            }
            // Check if value is in filter array.
            // Sometimes, filter array has 0 number, so we need to check explicitly for that
            else if (!isInFilter && isInFilter !== 0) return false;
          }
        }
      }
    }

    if (this.state.textFilter && this.state.textFilter !== "") {
      if (
        JSON.stringify(Object.values(object))
          .toUpperCase()
          .indexOf(this.state.textFilter.toUpperCase()) < 0
      )
        return false;
    }
    return true;
  };

  nextPage = () => {
    let maxEl = this.props.pagination
      ? this.props.numberElements
      : this.state.objectList.filter(this.filterList).length;
    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

    let curPage = this.state.curPage;
    if (curPage < maxPage) {
      curPage++;
      if (this.props.pagination) {
        this.state.curPage = curPage;
        this.refreshObjects();
      } else {
        this.setState({ curPage });
      }
    }
  };

  previousPage = () => {
    let curPage = this.state.curPage;
    curPage--;
    if (curPage >= 1) {
      if (this.props.pagination) {
        this.state.curPage = curPage;
        this.refreshObjects();
      } else {
        this.setState({ curPage });
      }
    }
  };

  calculateLeftPosition = () => {
    setTimeout(() => {
      let leftOffset = 0;
      let modalWidth = 0;

      if (
        ReactDOM &&
        ReactDOM.findDOMNode(this.refs["GenericScreenContainer"])
      ) {
        leftOffset = ReactDOM.findDOMNode(
          this.refs["GenericScreenContainer"]
        ).getBoundingClientRect().left;
        modalWidth = window.innerWidth - leftOffset - 100;
      }

      this.setState({ leftOffset, modalWidth });
    }, 300);
  };

  // Final UI
  render() {
    let that = this;

    let maxEl = this.props.pagination
      ? this.props.numberElements
      : this.state.objectList.filter(this.filterList).length;
    let minElPage = (this.state.curPage - 1) * PAGE_INTERVAL + 1;
    let maxElPage =
      this.state.curPage * PAGE_INTERVAL > maxEl
        ? maxEl
        : this.state.curPage * PAGE_INTERVAL;

    return (
      <div
        style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
        ref={"GenericScreenContainer"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "10px 30px 10px 20px",
          }}
        >
          {this.addButton_placeholder ? (
            <div style={{ paddingRight: "10px" }}>
              <Button
                variant="contained"
                onClick={() => this.setState({ add_role: true })}
              >
                <AddCircleIcon style={{ marginRight: "8px" }} />{" "}
                {this.addButton_placeholder}
              </Button>
            </div>
          ) : null}
          {this.bannerButton_placeholder &&
          localStorage.getItem("ID_APP") == 4 ? (
            <div style={{ paddingRight: "10px" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#ff9421",
                  color: "#FFF",
                }}
                onClick={this.addBanner}
              >
                <AddCircleIcon style={{ marginRight: "8px" }} />{" "}
                {this.bannerButton_placeholder}
              </Button>
            </div>
          ) : null}
          <div style={{ paddingRight: "10px" }}>
            <Button variant="contained" onClick={this.refreshObjects}>
              <i
                className="fa fa-fw fa-refresh"
                style={{ fontSize: "1.75em", verticalAlign: "middle" }}
              />
            </Button>
          </div>
          <div style={{ flex: "1" }}>
            <SearchTextFieldWithFilter
              placeholder={this.searchText_placeholder}
              onSearch={this.searchText_onSearch}
              onClickFilter={this.searchText_onClickFilter}
            ></SearchTextFieldWithFilter>
          </div>
        </div>
        <div style={{ flex: "1" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
                display: this.state.showList ? "flex" : "none",
              }}
            >
              <Grid container>
                <Grid xs={12} style={{ height: "82vh" }}>
                  <PerfectScrollbar
                    style={{
                      width: "100%",
                      height: "82vh",
                      overflow: "hidden",
                      position: "absolute",
                    }}
                    options={{ suppressScrollX: "true" }}
                  >
                    {this.state.objectList
                      ?.filter(this.filterList)
                      .map((object, index) => {
                        return (
                          <div
                            style={{
                              marginBottom: "5px",
                              marginLeft: "20px",
                              marginRight: "20px",
                            }}
                          >
                            {
                              new this.objectCard({
                                object: object,
                                updateObject: this.updateObject,
                                deleteObject: this.deleteObject,
                                callDescription:
                                  this.objectCard_CallDescription,
                                relations: this.state.relations,
                                authors: this.state.authors,
                                subjects: this.state.subjects,
                                descriptionState: this.state.descriptionState
                                  ? this.state.descriptionState
                                  : null,
                                chosenId: this.state.chosenObjectId,
                              })
                            }
                          </div>
                        );
                      })
                      .slice(
                        this.props.pagination
                          ? 0
                          : (this.state.curPage - 1) * PAGE_INTERVAL,
                        this.props.pagination
                          ? undefined
                          : this.state.curPage * PAGE_INTERVAL
                      )}
                  </PerfectScrollbar>
                </Grid>
                <Grid xs={12} style={{ marginBottom: "10px" }}>
                  <Button
                    variant="contained"
                    style={{ margin: "0px 10px", fontWeight: "bolder" }}
                    onClick={this.previousPage}
                  >
                    &lt;
                  </Button>
                  Exibindo {minElPage}-{maxElPage} de {maxEl}
                  <Button
                    variant="contained"
                    style={{ margin: "0px 10px", fontWeight: "bolder" }}
                    onClick={this.nextPage}
                  >
                    &gt;
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/*<Rodal
          visible={this.state.is_editing_item}
          onClose={this.onModalClose}
          customStyles={{
            width: this.state.modalWidth,
            height: "80%",
            left: this.state.leftOffset,
          }}
        >
          <PerfectScrollbar
            style={{
              height: "80vh",
              overflow: "hidden",
              position: "absolute",
              width: "100%",
            }}
            options={{ suppressScrollX: "true" }}
            ref={(ref) => {
              this._scrollBarRef = ref;
            }}
          >
            <div style={{ margin: "20px" }}>
              {this.state.chosenObject !== {} && this.state.isCalled === true
                ? this.Description(this.state.chosenObject)
                : ""}
            </div>
          </PerfectScrollbar>
        </Rodal>*/}
        <Dialog
          open={this.state.add_role}
          scroll={"paper"}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={this.onModalClose}
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="answer"
              label="Segmento"
              fullWidth
              variant="standard"
              onChange={(event) =>
                this.handleRoleNameChange(event.target.value)
              }
              value={this.state.role_name}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={this.onModalClose}>Fechar</Button>
            <Button onClick={() => this.addObject(this.state.role_name)}>
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.is_editing_item}
          scroll={"paper"}
          fullWidth={false}
          maxWidth={"md"}
          onClose={this.onModalClose}
          aria-describedby="scroll-dialog-description"
        >
          <DialogContent style={{ overflow: "hidden" }}>
            <div style={{ display: "flex", width: "500px" }}>
              <TextField
                autoFocus
                margin="dense"
                id="role_name"
                label="Segmento"
                variant="standard"
                onChange={(event) =>
                  this.handleRoleNameChange2(event.target.value)
                }
                value={this.state.chosenObject.role_name}
                style={{width: "250px" }}
              />
              <div>
              <TextField
                margin="dense"
                id="id_role"
                label="ID"
                disabled={true}
                variant="standard"
                value={this.state.chosenObject.id_role}
                style={{ marginLeft: "15px" }}
              />
              </div>
              
            </div>
          </DialogContent>
          <DialogContent style={{ overflow: "hidden" }}>
            <div style={{ display: "flex", width: "500px" }}>
              <div style={{ display: "flex"}}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="role_name_type"
                  label={`Adicionar novo ${this.state.chosenObject.role_name}...`}
                  variant="standard"
                  onChange={(event) =>
                    this.handleRoleNameTypeChange(event.target.value)
                  }
                  value={this.state.role_name_type}
                  style={{width: "250px" }}
                />
              </div>

              <div
                style={{
                  marginLeft: "15px",
                  display: "flex",
                  alignItems: "flex-end",
                  marginBottom: "5px", 
                }}
              >
                {" "}
                <Button
                  startIcon={<AddCircleIcon />}
                  size="medium"
                  variant="contained"
                  onClick={() =>
                    this.addObjectType(
                      this.state.role_name_type,
                      this.state.chosenObject.id_role
                    )
                  }
                >
                  Adicionar
                </Button>
              </div>
            </div>
          </DialogContent>

          <DialogContent>
            <PerfectScrollbar>
              {this.state.objectListTypes &&
              this.state.objectListTypes.length > 0
                ? this.state.objectListTypes.map((role_type, index) => {
                    return (
                      <div style={{ display: "flex" }} key={role_type.id_role}>
                        <div style={{ display: "flex" }}>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="role_name_type"
                            variant="standard"
                            onChange={(event) =>
                              this.handleRoleNameTypeChange(event.target.value)
                            }
                            disabled
                            value={role_type.role_name}
                            style={{width: "250px" }}
                          />
                        </div>

                        <div
                          style={{
                            marginLeft: "15px",
                            display: "flex",
                            alignItems: "flex-end",
                            marginBottom: "5px",
                          }}
                        >
                          {" "}
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() =>
                              this.deleteObjectType(
                                role_type
                              )
                            }
                            style={{ background: "#CC0B0B" }}
                          >
                            {<DeleteIcon style={{ color: "#FFF" }} />}
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : null}
            </PerfectScrollbar>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => this.setState({ deleted_role : true })}>Remover</Button>
            <Button onClick={this.onModalClose}>Fechar</Button>
            <Button onClick={() => this.saveObject(this.state.chosenObject)}>Salvar</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.delete_role}
          scroll={"paper"}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={this.onModalClose}
          aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
           <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja mesmo remover esse segmento?
          </DialogContentText>
        </DialogContent>

          <DialogActions>
            <Button onClick={this.onModalClose}>Não</Button>
            <Button onClick={() => this.deleteObject(this.state.chosenObject)}>
              Sim
            </Button>
          </DialogActions>
        </Dialog>

        <SlidingPane
          isOpen={this.state.showFilter}
          title="Filtros"
          from="right"
          width="40vw"
          onRequestClose={() => this.setState({ showFilter: false })}
        >
          <PerfectScrollbar
            style={{
              width: "100%",
              height: "87vh",
              overflow: "hidden",
              position: "absolute",
            }}
            options={{ suppressScrollX: "true" }}
            ref={(ref) => {
              this._scrollBarRef = ref;
            }}
          >
            {this.state.fieldsToFilter
              ? this.state.fieldsToFilter.map((el) => {
                  el.operator = el.operator
                    ? el.operator
                    : el.possibleValues && el.possibleValues[0]
                    ? el.possibleValues[0].value
                    : null;

                  const handleChange = (selectedOptions) => {
                    let selectedValues = selectedOptions.map((obj) => {
                      return obj.value;
                    });

                    let fieldsToFilter = this.state.fieldsToFilter;
                    let fieldObj = fieldsToFilter.find((filterObj) => {
                      return filterObj.parameter === el.parameter;
                    });

                    fieldObj.selectedValues = selectedValues;
                    fieldObj.selectedOptions = selectedOptions;

                    if (this.onFilterReload) this.refreshObjects();

                    let maxEl = this.props.pagination
                      ? this.props.numberElements
                      : this.state.objectList.filter(this.filterList).length;
                    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

                    that.setState({
                      maxEl: maxEl,
                      maxPage: maxPage,
                      fieldsToFilter: fieldsToFilter,
                    });
                  };

                  const handleDateChange = (date_field) => (event) => {
                    event = event ? new Date(event) : undefined;

                    let fieldsToFilter = this.state.fieldsToFilter;
                    let fieldObj = fieldsToFilter.find((filterObj) => {
                      return filterObj.parameter === el.parameter;
                    });

                    fieldObj[date_field] = event;

                    if (this.onFilterReload) this.refreshObjects();

                    let maxEl = this.props.pagination
                      ? this.props.numberElements
                      : this.state.objectList.filter(this.filterList).length;
                    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

                    that.setState({
                      maxEl: maxEl,
                      maxPage: maxPage,
                    });

                    //that.setState(fieldsToFilter);
                  };

                  const handleNumericChange = (numeric_field) => (event) => {
                    let fieldsToFilter = this.state.fieldsToFilter;
                    let fieldObj = fieldsToFilter.find((filterObj) => {
                      return filterObj.parameter === el.parameter;
                    });

                    fieldObj[numeric_field] = event.target.value;

                    if (this.onFilterReload) this.refreshObjects();

                    let maxEl = this.props.pagination
                      ? this.props.numberElements
                      : this.state.objectList.filter(this.filterList).length;
                    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

                    that.setState({
                      maxEl: maxEl,
                      maxPage: maxPage,
                    });

                    that.setState(fieldsToFilter);
                  };

                  return (
                    <Grid container>
                      <Grid item xs={5}>
                        <p>{el.title}</p>
                      </Grid>

                      {el.filter_type === "DATE" ? (
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid xs={6}>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                style={{
                                  border: "0px",
                                  color: "black",
                                  textDecoration: "none",
                                }}
                              >
                                <DateTimePicker
                                  clearable
                                  label="De"
                                  value={el.from_date}
                                  style={{
                                    width: "100%",
                                    textDecoration: "none",
                                  }}
                                  format="dd/MM/yyyy"
                                  onChange={handleDateChange("from_date")}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid xs={6}>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                style={{
                                  border: "0px",
                                  color: "black",
                                  textDecoration: "none",
                                }}
                              >
                                <DateTimePicker
                                  clearable
                                  label="Até"
                                  value={el.to_date}
                                  style={{
                                    width: "100%",
                                    textDecoration: "none",
                                  }}
                                  format="dd/MM/yyyy"
                                  onChange={handleDateChange("to_date")}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : el.filter_type === "NUMERIC" ? (
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid item xs={3}>
                              <TextField
                                id="comparison_operator"
                                select
                                value={el.operator}
                                defaultValue={el.operator}
                                onChange={handleNumericChange("operator")}
                              >
                                {el.possibleValues.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "30px" }}>
                              <TextField
                                id="comparison_number"
                                type="number"
                                value={el.number}
                                defaultValue={el.number ? el.number : undefined}
                                onChange={handleNumericChange("number")}
                                inputProps={{ min: "0", max: "100", step: "1" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={6}>
                          <ReactMultiSelectCheckboxes
                            style={{ dropdownButton: { width: "100%" } }}
                            options={el.possibleValues}
                            defaultValue={el.selectedOptions}
                            onChange={handleChange}
                          />
                        </Grid>
                      )}
                    </Grid>
                  );
                })
              : null}
          </PerfectScrollbar>
        </SlidingPane>
        <Modal
          open={this.state.is_saving}
          showCloseIcon={false}
          focusTrapped={false}
          center={true}
          onClose={() => {}}
        >
          <h2>Salvando alterações...</h2>
        </Modal>
        <Modal
          open={this.state.is_loading}
          showCloseIcon={false}
          focusTrapped={false}
          center={true}
          onClose={() => {}}
        >
          <h2>Carregando...</h2>
        </Modal>
      </div>
    );
  }
}

export default RoleAdminScreen;
