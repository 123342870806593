import GenericModel from './generic_model/GenericModel'

const API = '/manuals';

class Manual extends GenericModel {
    API = API;
    static API = API;

    static getContent = async function (id) {

        let queryString = "";
        if (id) {
            queryString = id;
        }


        let authHeader = null;
        try {
            let that = this;
            authHeader = await GenericModel.insertAuthHeader();
            const response = await fetch(this.API + '/getContent/' + queryString, {
                headers: authHeader,
            });
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let ans = {};

            function mountObjArray(elementsArray) {
                let result = [];

                for (let i = 0; i < elementsArray.length; i++) {
                    result.push(new GenericModel(elementsArray[i], that.API));
                }

                return result;
            }

            if (body && body.elements) {
                for (let prop in body) {
                    if (body.hasOwnProperty(prop) && prop !== "elements") {
                        ans[prop] = body[prop];
                    }
                }
                ans.elements = mountObjArray(body.elements);
            } else if (body instanceof Array) {
                ans = mountObjArray(body);
            } else {
                ans = new GenericModel(body, that.API);
            }

            return ans;
        } catch (err) {
            throw Error(err);
        }
    };
}

export default Manual;