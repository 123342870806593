import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { DateTimePicker } from "@material-ui/pickers/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faEye
} from "@fortawesome/free-solid-svg-icons";

export default function MessageCard(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;

    // Functions
    this.updateMessage = props.updateObject;
    this.deleteMessage = props.deleteObject;
    this.callDescription = props.callDescription;

    //Function to Call Description in GenericAdminScreen.js
    this.onClickCallDescription = () => {
        this.callDescription(this.object);
    };

    return (
        <div style={{
            'height': '6vw',
            'backgroundColor': props.chosenId === this.object.id_message ? '#4498A7' : '#f2f2f2',
            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
            padding: '10px 0 ',
        }} onClick={this.onClickCallDescription}>

            <div style={{ flex: 1 }}>
                <span
                    style={{

                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em',
                        textAlign: 'center',
                    }}>
                    {this.object.message_title}
                </span>

            </div>

            <div style={{ flex: 1 }}>
                <span
                    style={{

                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em',
                        textAlign: 'center',
                    }}>
                    {this.object.is_published && (!this.object.is_archived || this.object.is_archived === 0) ?
                        this.object.target_user == 'all' ? 'Enviado para: Todos'
                            : this.object.target_user == 'individual' ? `Enviado para: ${this.object.user[0]?.label}`
                                : this.object.target_user == 'roles' ? `Enviado para usuários com papéis. Clique para ver detalhes`
                                    : 'Não enviado' : 'Não enviado'}
                </span>

            </div>
            <FontAwesomeIcon
                icon={faEye}
                style={{ width: '5vw', height: '2vw', objectFit: 'cover', marginRight: '5px', }}
                id={'image-modal'}
                color={'#424242'}
            />

            <div style={{
                flex: 1, justifyContent: 'center', alignItems: 'center',
                maxWidth: '5%',
            }}>
                <span
                    style={{

                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em',
                        textAlign: 'center',
                    }}>
                    {`${this.object.users_feedback ? this.object.users_feedback : 0}%`}
                </span>
            </div>
            {this.object.is_banner ? (
                <div style={{
                    flex: 1, justifyContent: 'center', alignSelf: 'center', maxWidth: '15%'
                }}>
                    <span
                        style={{
                            padding: '0',
                            fontSize: '18px',
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            fontWeight: '400',
                            lineHeight: '1',
                            letterSpacing: '0.00938em',
                            textAlign: 'center',
                            color: '#ff9421'
                        }}>
                        BANNER
                    </span>
                </div>
            ) :
                <div style={{
                    flex: 1, justifyContent: 'center', alignSelf: 'center', maxWidth: '15%'
                }}>
                </div>
            }


            <div style={{ flex: 1 }}>
                <span
                    style={{

                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em',
                        textAlign: 'center',
                    }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            label={this.object.is_published == 1 ? "Data de envio" : "Data da ultima alteração"}
                            value={this.object.updated_date}
                            style={{ width: "100%" }}
                            format="dd/MM/yyyy HH:mm"
                            disabled
                        />
                    </MuiPickersUtilsProvider>
                </span>
            </div>
        </div>
    );

}