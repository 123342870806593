import React from "react";
import {
  Modal, 
  Box,
  Button
} from '@material-ui/core';

export default function ModalSelectClassType({isOpen, handleClose, handleSelectClassType}){
  return(
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <Box
          sx={{
            bgcolor: "#FFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'center',
            margin: '10% auto',
            padding: 24,
            width: "50%"
          }}
        >
          <span
            style={{
              fontSize: 24,
              marginBottom: 16,
              fontWeight: 'bold'
            }}
          >
            Selecione o tipo de curso
          </span>

          <Box
            display="flex"
          >
            <Button
              onClick={() => handleSelectClassType("trail")}
              size="large"
            >
              Curso
            </Button>

            <Button
              onClick={() => handleSelectClassType("pill")}
              size="large"
            >
              Pílula
            </Button>
          </Box>
        </Box>
      </Modal>
  );
}