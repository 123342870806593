import React, { Component } from 'react';
import ManualContentAdminScreen from '../components/generic_screens/ManualContentAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Manual from '../../models/Manual';
import ManualContent from '../../models/ManualContent';
import Role from '../../models/Role';

import ContentCard from '../components/cards/ManualContentCard';
import ManualContentDescription from '../components/description_cards/ManualContentDescription';
import Spinner from '../components/buttons/Spinner';

class AdminManualContentTrailScreen extends Component {
  editionTimeout = null;

  state = {
    contents: [],
    relations: [],
    filters: [],
    loading: true,
  };

  // TEXTS
  searchText_placeholder = 'Título do Conteúdo';
  addButton_placeholder = 'Conteúdo';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    Manual.getContent(this.props.manual.id_manual)
      .then((contents) => {
        this.setState({ contents: contents });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
    Role.query().then((roles) => {
      let relations = this.state.relations;
      relations[0] = roles;
      this.setState({ relations: relations });
    });
    this.reloadContent();
  }

  async reloadContent(parameters) {
    try {
      let contents = await Manual.getContent(this.props.manual.id_manual);

      if (contents && contents.length > 0) {
        contents = contents.sort((obj1, obj2) => {
          let obj1_order_content = obj1.order_content;
          let obj2_order_content = obj2.order_content;

          if (!obj1_order_content) {
            obj1_order_content = 99999999999999;
          }

          if (!obj2_order_content) {
            obj2_order_content = 99999999999999;
          }

          if (obj1.order_content < obj2.order_content) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      this.setState({ contents: contents });
      this.setState({ loading: false });
    } catch (err) {
     toast.error('Erro ao carregar conteúdos');
    }
  }

  addContent = async (contentType) => {
    let contentList = [];
    if (this.state.contents && this.state.contents.length > 0) {
      contentList = this.state.contents;
    }
    let order_content =
      contentList && contentList.length > 0 ? contentList.length : 0;
    let newContent = new ManualContent();
    newContent.content = {
      order_content: order_content,
      id_manual: this.props.manual.id_manual,
    };
    let contentObj = {};
    try {
      contentObj = await newContent.saveContent();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }

    contentList.push(contentObj);
    this.setState({ contents: contentList });
    this.state.contents = contentList;
    return contentObj;
  };

  updateContent = (contentObj, field, value, force) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_manual_content === contentObj.id_manual_content;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_manual_content === contentObj.id_manual_content;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          contentToUpdate[prop] = value[prop];
        }
      }
    } else if (field.indexOf('.') > 0) {
      let hierarchy = field.split('.');
      let obj = contentToUpdate;
      for (let i = 0; i < hierarchy.length; i++) {
        if (i === hierarchy.length - 1) {
          obj[hierarchy[i]] = value;
          break;
        }
        obj = obj[hierarchy[i]];
      }
    } else {
      contentToUpdate[field] = value;
    }

    this.setState({ contents: contentList });
    return contentList[position];
  };

  deleteContent = async (contentObj) => {
    let contentList = this.state.contents;

    // Finds Edited content on contents list and change Content_name value
    for (let i = 0; i < contentList.length; i++) {
      if (contentObj.id_manual_content === contentList[i].id_manual_content) {
        let contentListObj = new ManualContent(contentList[i]);
        await contentListObj.removeContent();
        contentList.splice(i, 1);
        break;
      }
    }

    this.setState({ contents: contentList });
  };

  saveContent = async (contentObj) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_manual_content === contentObj.id_manual_content;
    });

    let contentToUpdate = new ManualContent(
      contentList.find((obj) => {
        return obj.id_manual_content === contentObj.id_manual_content;
      }),
    );

    if (contentToUpdate != null) {
      let contentObj = contentToUpdate;
      try {
        let order = position;
        if (order < 0) {
          throw new Error('content_order');
        } else contentObj = await contentToUpdate.updateContent();
        // Content upload to server worked
        contentList[position] = contentObj;
        this.setState({ contents: contentList });
        this.state.contents = contentList;

        toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
       toast.error('Erro ao salvar conteúdo!');
      }
    }
  };

  refreshContents = async (parameters) => {
    this.reloadContent(parameters);
  };

  saveContentList = async (contentListObj) => {
    let contentListToUpdate = contentListObj.map((content, index) => {
      content.order_content = index;
      return content;
    });

    if (contentListToUpdate != null) {
      try {
        for (let i = 0; i < contentListToUpdate.length; i++) {
          /*let contentObjtoUpdate = */
          contentListToUpdate[i].save();
          //contentListToUpdate[i] = contentObjtoUpdate[i];
        }

        this.setState({ contents: contentListToUpdate });
        toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
        switch (err.message) {
          default:
           toast.error('Erro ao salvar conteúdo!');
            break;
        }
      }
    }
  };

  updateContentList = (objectList) => {
    this.state.contents = objectList;
    this.saveContentList(this.state.contents);
  };

  render() {
    return (
      <>
             {this.state.contents.length===0 && this.state.loading ? <Spinner
       
               margin='13px' 
              texto={'carregando...'}
              border={'#9BB3D4'}
              top={'#1D2D44'}/>
              :
      <ManualContentAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.contents}
        objectCard={ContentCard}
        objectDescription={ManualContentDescription}
        addObject={this.addContent}
        updateObject={this.updateContent}
        deleteObject={this.deleteContent}
        saveObject={this.saveContent}
        refreshObjects={this.refreshContents}
        relations={this.state.relations}
        objectIdName={'id_manual_content'}
        objectModel={ManualContent}
        onFilterReload={true}
        updateObjectList={this.updateContentList}
      ></ManualContentAdminScreen>
             }</>
    );
  }
}

export default AdminManualContentTrailScreen;
