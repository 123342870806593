import DateFnsUtils from "@date-io/date-fns";
import {
  Button, FormControlLabel, Grid, Radio, RadioGroup, TextField
} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import AddIcon from "@material-ui/icons/Add";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers/";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import Training from "../../../models/Training";
import AdminContentTrailScreen from "../../screens/AdminContentTrailScreen";
import ArchiveButton from "../buttons/ArchiveButton";
import DeleteButton from "../buttons/DeleteButton";
import SaveButton from "../buttons/SaveButton";
import FormQuestionCard from "../cards/FormQuestionCard";
import { CustomDropzone } from "../CustomDropzone";
import SearchableSelect from "../selectbox/SearchableSelect.js";
import {urlModify} from '../../../models/helpers/firebaseSend'

export default function ContentTaskDescription(props) {
  this.editionTimeout = null;
  // Object to Render
  this.object = props.object;

  if (
    this.object.training &&
    this.object.training.training_images &&
    this.object.training.training_images.length > 0
  ) {
    this.object.training.training_images.sort((image1, image2) => {
      return image1.training_image_position - image2.training_image_position;
    });
  }
  this.roles = props.relations[0];
  this.subjects = props.subjects[0];
  this.brands = props.brands ? props.brands[0] : [];
  //dani
  // console.log(" this.object.training :::", this.object.training);
  this.contents = props.contents ? props.contents : [];

  // Functions
  this.updateContent = props.updateObject;

  if (!this.object.content_type) {
    this.updateContent(this.object, "content_type", 2);
  }

  let form = this.object.form ? this.object.form : { form_type: 5 };

  if (this.object.content_type === 8) {
    form.form_type = 5;
  }

  this.deleteContent = props.deleteObject;
  this.publishObject = props.publishObject;
  this.saveContent = props.saveObject;
  this.nullDescription = props.nullDescription;

  this.showList = props.showList;

  let content_type = null;
  switch (this.object.content_type) {
    case 1:
      content_type = "video";
      break;
    case 2:
      content_type = "image";
      break;
    case 3:
      content_type = "form";
      break;
    case 4:
      content_type = "challenge";
      break;
    case 5:
      content_type = "pdf";
      break;
    case 6:
      content_type = "trail";
      break;
    case 8:
      content_type = "quiz";
      break;
  }

  if (
    (content_type === "video" ||
      content_type === "pdf" ||
      content_type === "challenge" ||
      content_type === "image" ||
      content_type === "trail") &&
    !this.object.training
  ) {
    this.object.training = {
      id_app: localStorage.getItem("ID_APP"),
      id_training: this.object.id_training ? this.object.id_training : null,
    };
  } else if (
    (content_type === "form" || content_type === "quiz") &&
    !this.object.form
  ) {
    this.object.form = { id_app: localStorage.getItem("ID_APP") };
  }

  switch (content_type) {
    case "video":
      content_type = "video";
      this.object.content_type = 1;
      break;
    case "image":
      content_type = "image";
      this.object.content_type = 2;
      break;
    case "form":
      content_type = "form";
      this.object.content_type = 3;
      break;
    case "challenge":
      content_type = "challenge";
      this.object.content_type = 4;
      // delete this.object.form; //back aqui
      break;
    case "pdf":
      content_type = "pdf";
      this.object.content_type = 5;
      break;
    case "trail":
      content_type = "trail";
      this.object.content_type = 6;
      break;
    case "quiz":
      content_type = "quiz";
      this.object.content_type = 8;
      break;
  }

  this.object.roleOptions = this.roles.map((role) => {
    let roleOption = {
      value: role.id_role,
      label: role.role_name,
    };
    return roleOption;
  });

  if (this.object.roles !== undefined) {
    this.object.defaultRoleValues = this.object.roles.map((role) => {
      return {
        value: role.id_role ? role.id_role : null,
        label: role.role_name ? role.role_name : null,
      };
    });
  } else {
    this.object.defaultRoleValues = [];
  }
  //dani
  if (this.contents) {
    this.object.defaultContentValues = this.contents.map((content) => {
      if (content.id === this.object.id_predecessor_content) {
        let contentOptions = {
          value: content.id,
          label: content.title,
        };
        return contentOptions;
      }
    });
    this.object.defaultContentValues = this.object.defaultContentValues.filter(
      (item) => item
    );
  } else {
    this.object.defaultContentValues = [];
  }

  if (this.object.id_topic) {
    if (!this.object.defaultSubjectValue) {
      this.object.defaultSubjectValue = {
        value: this.object.id_topic ? this.object.id_topic : null,
        label: this.object.topic_name ? this.object.topic_name : null,
      };
    }
  }

  if (this.object.class_brand) {
    if (!this.object.defaultBrandValue) {
      this.object.defaultBrandValue = {
        value: this.object.class_brand ? this.object.class_brand : null,
        label: this.object.brand_name ? this.object.brand_name : null,
      };
    }
  }

  if (this.subjects !== undefined) {
    this.object.subjectOptions = this.subjects.map((subjects) => {
      let subjectOption = {
        value: subjects.id_topic,
        label: subjects.topic_name,
      };
      return subjectOption;
    });
  } else {
    this.object.subjectOptions = [];
  }
  if (this.contents !== undefined) {
    this.object.contentOptions = this.contents.map((content) => {
      let contentOptions = {
        value: content.id,
        label: content.title,
      };
      return contentOptions;
    });
  } else {
    this.object.contentOptions = [];
  }

  this.object.contentOptions = this.object.contentOptions.filter(
    (c) => c.value != this.object.id_content
  );

  if (this.brands !== undefined) {
    this.object.brandsOptions = this.brands.map((brands) => {
      let brandOption = {
        value: brands.id_brand,
        label: brands.brand_name,
      };
      return brandOption;
    });
  } else {
    this.object.brandsOptions = [];
  }

  if (this.object.predecessor_content && this.object.id_predecessor_content) {
    this.object.defaultPredecessorContentValue = {
      value: this.object.predecessor_content,
      label: this.object.predecessor_content.form
        ? this.object.predecessor_content.form.form_title
        : this.object.predecessor_content.training
        ? this.object.predecessor_content.training.training_title
        : this.object.predecessor_content.form_title
        ? this.object.predecessor_content.form_title
        : this.object.predecessor_content.training_title
        ? this.object.predecessor_content.training_title
        : "ID do Conteúdo:" + this.object.predecessor_content.id_content,
    };
  } /*else {
        this.object.defaultPredecessorContentValue = {
            value: null,
            label: "",
        };
    }*/

  if (!this.object.defaultQuizContentValue) {
    if (this.object.quiz_content && this.object.id_content_quiz) {
      this.object.defaultQuizContentValue = {
        value: this.object.quiz_content,
        label: this.object.quiz_content.form
          ? this.object.quiz_content.form.form_title
          : this.object.quiz_content.training
          ? this.object.quiz_content.training.training_title
          : this.object.quiz_content.form_title
          ? this.object.quiz_content.form_title
          : this.object.quiz_content.training_title
          ? this.object.quiz_content.training_title
          : "ID do Conteúdo:" + this.object.quiz_content.id_content,
      };
    }
  }

  let updateDescriptionState = props.updateDescriptionState
    ? props.updateDescriptionState
    : () => {};
  if (!props.descriptionState.content_type) {
    updateDescriptionState({ content_type: content_type });
  }

  this.onClickArchiveContent = () => {
    if (this.object.title === null || this.object.title === undefined) {
      toast.info("Para arquivar adicione um titulo");
      return;
    } else if (this.object.title?.trim().length === 0) {
      toast.info("Para arquivar adicione um titulo");
      return;
    }
    this.onChangeUpdate("is_archived")({
      target: {
        value: this.object.is_archived ? 0 : 1,
      },
    });
    this.saveContent(this.object);
  };

  this.onClickPublishContent = () => {
    if (this.object.title === null || this.object.title === undefined) {
      toast.info("Para publicar adicione um titulo");
      return;
    } else if (this.object.title?.trim().length === 0) {
      toast.info("Para publicar adicione um titulo");
      return;
    }
    if (content_type === "form" || content_type === "quiz") {
      delete this.object.training;
      this.object.id_training = null;
    } else {
      this.object.training.is_challenge_photo =
        content_type === "challenge" ? 1 : 0;
      this.object.id_form = null;

      if (this.object.training.is_challenge_photo)
        this.object.training.challenge_photos_limit = 1;
    }
    this.object.is_published = 1;
    this.publishObject(this.object);
  };

  this.onClickSaveContent = () => {
    if (this.object.title === null || this.object.title === undefined) {
      toast.info("Para salvar adicione um titulo");
      return;
    } else if (this.object.title?.trim().length === 0) {
      toast.info("Para salvar adicione um titulo");
      return;
    }
    if (content_type === "form" || content_type === "quiz") {
      delete this.object.training;
      this.object.id_training = null;
    } else {
      this.object.training.is_challenge_photo =
        content_type === "challenge" ? 1 : 0;
      this.object.id_form = null;
    }
    this.saveContent(this.object);
  };

  this.onClickDeleteContent = () => {
    if (window.confirm("Tem certeza que deseja remover esse item?")) {
      this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the content
      this.deleteContent(this.object);
    }
  };

  this.onChangeUpdate =
    (field, force = false) =>
    (event) => {
      let value = event && event.target ? event.target.value : null;

      if (
        field === "starting_content" ||
        field === "is_fixed" ||
        field === "is_marketing" ||
        field === "is_challenge" ||
        field === "is_removed_when_completed"
      ) {
        value = event.target.checked ? 1 : 0;
      } else if (field === "content_title") {
        switch (content_type) {
          case "challenge":
          case "video":
          case "pdf":
            field = "training.training_title";
            break;
          case "form":
          case "quiz":
            field = "form.form_title";
            break;
          default:
            field = "training.training_title";
        }
      }

      this.updateContent(this.object, field, value, force);
    };

  this.onChangeUpdateSubjects = (value) => {
    this.updateContent(this.object, "id_topic", value.value, true);
    this.object.defaultSubjectValue = {
      value: value.value,
      label: value.label,
    };
  };

  this.onChangeUpdateBrands = (value) => {
    this.updateContent(this.object, "class_brand", value.value, true);
    this.object.defaultBrandValue = {
      value: value.value,
      label: value.label,
    };
  };

  this.onDropActionCoverImage = async (file) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedCoverImage: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = null;
      switch (this.object.content_type) {
        case 1:
        case 2:
        case 4:
        case 5:
        case 6:
          imageUrl = await Training.sendTrainingCoverImage( //olhar
            file,
            null,
            progressFunction
          );
          this.updateContent(
            this.object,
            "training.training_cover_image",
            imageUrl.url,
            true
          );
          break;
        case 3:
        case 8:
          imageUrl = await Training.sendTrainingCoverImage(
            file,
            null,
            progressFunction
          );
          this.updateContent(
            this.object,
            "form.form_image",
            imageUrl.url,
            true
          );
          break;
        default:
          toast.error("Tipo de conteúdo não permite imagem de capa!");
      }
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteCoverImage = () => {
    switch (content_type) {
      case "video":
      case "pdf":
      case "challenge":
      case "image":
      case "trail":
        return this.updateContent(
          this.object,
          "training.training_cover_image",
          null,
          true
        );
      case "form":
      case "quiz":
        return this.updateContent(this.object, "form.form_image", null, true);
      default:
        toast.error("Tipo de conteúdo não permite imagem de capa!");
    }
  };

  this.onDropActionVideo = async (file) => {
    const videoTypes = ["video/mp4"];

    file = file[0];
    const fileType = file.type;
  
    if(!videoTypes.includes(fileType)){
      toast.error("Só são permitidos arquivos no formato: .mp4");
      return;
    }

    const progressFunction = (completed) => {
      const completedState = {
        completedVideo: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = await Training.sendTrainingVideo(
        file,
        this.object.training.id_training,
        progressFunction
      );
      let urlClean= urlModify(imageUrl.url)

      this.updateContent(
        this.object,
        "training.training_video",
        urlClean,
        true
      );
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteVideo = () => {
    return this.updateContent(
      this.object,
      "training.training_video",
      null,
      true
    );
  };

  this.onDropActionFile = async (file, field) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedFile: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = await Training.sendTrainingFile(
        file,
        this.object.training.id_training,
        progressFunction
      );
      this.updateContent(this.object, field, imageUrl.url, true);
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.onDropActionImage = async (file, idx) => {
    let files = file;

    const progressFunction = (completed) => {
      let completedImages = props.completed.completedImages
        ? props.completed.completedImages
        : [];
      completedImages[idx] = completed;
      const completedState = {
        completedImages: completedImages,
      };
      props.progressFunction(completedState);
    };
    let index = idx;
    try {
      if (files.length > 10) {
        toast.error("arquivo muito grande ");
        toast.info("maximo de 10 imagens para envio ");
        return;
      }
      for (let i = 0; i < files.length; i++) {
        idx = index + i;
        file = files[i];
        let imageUrl = await Training.sendTrainingFile(
          file,
          this.object.training.id_training,
          progressFunction
        );
        let imageToUpdate = { training_image_filename: imageUrl.url };
        let training_images = this.object.training.training_images
          ? this.object.training.training_images
          : [];
        training_images[idx].training_image_filename =
          imageToUpdate.training_image_filename;
        this.updateContent(
          this.object,
          "training.training_images",
          training_images,
          true
        );
        // Reset completed variable (progress bar)
        // progressFunction(0);
        if (i < files.length - 1) this.addImage();
        if (i === files.length - 1) {
          this.saveContent(this.object);
          progressFunction(0);
        } else {
          progressFunction(1);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteFile = () => {
    return this.updateContent(
      this.object,
      "training.training_file",
      null,
      true
    );
  };

  this.changeRadioFormType = (event) => {
    let value = event.target.value;

    value = Number(value);

    let form = this.object.form ? this.object.form : {};

    form.form_type = value;

    updateDescriptionState({ form: form });

    if (value === 2) {
      this.updateContent(this.object, "form.forms_questions", [
        { form_question_type: 2 },
      ]);
    } else if (value === 3) {
      this.updateContent(this.object, "form.forms_questions", [
        { form_question_type: 4 },
      ]);
    }

    this.updateContent(this.object, "form", form, true);
  };

  this.changeRadioTypeContent = (event) => {
    if (
      (event.target.value === "form" || event.target.value === "quiz") &&
      !this.object.form
    ) {
      this.updateContent(
        this.object,
        "form",
        {
          form_type: 1,
          id_form: this.object.id_form ? this.object.id_form : null,
        },
        true
      );
    }

    switch (event.target.value) {
      case "video":
        content_type = "video";
        this.object.content_type = 1;
        break;
      case "image":
        content_type = "image";
        this.object.content_type = 2;
        break;
      case "form":
        content_type = "form";
        this.object.content_type = 3;
        break;
      case "challenge":
        content_type = "challenge";
        this.object.content_type = 4;
        // delete this.object.form;
        break;
      case "pdf":
        content_type = "pdf";
        this.object.content_type = 5;
        break;
      case "trail":
        content_type = "trail";
        this.object.content_type = 6;
        break;
      case "quiz":
        content_type = "quiz";
        this.object.content_type = 8;
        break;
    }

    updateDescriptionState({
      content_type: event.target.value,
      user_defined_content_type: event.target.value,
    });
  };

  this.onQuestionChange = (field, idxQuestion) => (event) => {
    let value = event && event.target ? event.target.value : null;
    let formsQuestionsList = cloneDeep(this.object.form.forms_questions);
    let formQuestionObj = formsQuestionsList[idxQuestion];

    if (formQuestionObj) {
      if (!isNaN(value)) {
        value = Number(value);
      }
      formQuestionObj[field] = value;
      this.updateContent(
        this.object,
        "form.forms_questions",
        formsQuestionsList
      );
    }
  };

  this.addQuestion = () => {
    let formsQuestionsList = this.object.form.forms_questions
      ? cloneDeep(this.object.form.forms_questions)
      : [];
    formsQuestionsList.push({ form_question_type: 1 });
    this.updateContent(this.object, "form.forms_questions", formsQuestionsList);
  };

  this.addImage = () => {
    if (
      !this.object.training.training_images ||
      this.object.training.training_images.length < 10
    ) {
      let imagesList = this.object.training.training_images
        ? cloneDeep(this.object.training.training_images)
        : [];
      imagesList.push({ training_image_position: imagesList.length });
      this.updateContent(this.object, "training.training_images", imagesList);
    } else {
      toast.dark("limite de 10 imagens atingido");
    }
  };

  this.showForm = () => {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            textAlign: "left",
          }}
        >
          Perguntas:
        </span>
        {this.object.form && this.object.form.forms_questions
          ? this.object.form.forms_questions.map((question, idx) => {
              let deleteQuestion = () => {
                let formsQuestionsList = cloneDeep(
                  this.object.form.forms_questions
                );
                formsQuestionsList.splice(idx, 1);
                this.updateContent(
                  this.object,
                  "form.forms_questions",
                  formsQuestionsList
                );
              };

              return (
                <FormQuestionCard
                  formQuestion={question}
                  onQuestionChange={this.onQuestionChange}
                  deleteQuestion={deleteQuestion}
                  idx={idx}
                  formType={
                    this.object.form ? this.object.form.form_type : null
                  }
                />
              );
            })
          : null}
        {this.object.form &&
        this.object.form.form_type !== 2 &&
        this.object.form.form_type !== 3 ? (
          <Button onClick={this.addQuestion} variant="contained">
            Adicionar Pergunta
            <AddIcon />
          </Button>
        ) : null}
      </Grid>
    );
  };

  this.deleteImage = (idx) => {
    let ImagesList = cloneDeep(this.object.training.training_images);
    ImagesList.splice(idx, 1);
    this.updateContent(this.object, "training.training_images", ImagesList);
  };

  this.onChangeUpdateTrainingImage = (event, idx) => {
    let imagesList = cloneDeep(this.object.training.training_images);
    imagesList[idx].training_image_description = event.target.value;
    this.object.training.training_images = imagesList;
    this.updateContent(this.object, "training.training_images", imagesList);
  };
  this.onChangeUpdateTrainingVideo = (event) => {
    if (event && event.target && event.target.value) {
      let videoNow = cloneDeep(this.object.training);
      videoNow.training_description = event.target.value;
      this.object.training = videoNow;
      this.updateContent(
        this.object,
        "training.training_description",
        videoNow.training_description
      );
    }
  };

  this.showTrailContent = () => {
    return (
      <Grid xs={12} item style={{ width: "100%" }} contain spacing={2}>
        <AdminContentTrailScreen startContent={this.object} />
      </Grid>
    );
  };

  this.showImage = () => {
    return (
      <Grid xs={12} item style={{ width: "100%" }} spacing={2}>
        {this.object.training &&
        this.object.training.training_images &&
        this.object.training.training_images.length > 0
          ? this.object.training.training_images.map((image, idx) => {
              return (
                <Grid container spacing={2} direction={"row"}>
                  {image.training_image_filename ? (
                    <Grid container xs={6}>
                      <Grid item xs={12}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            lineHeight: 1,
                            letterSpacing: "0.00938em",
                            float: "left",
                            color: "rgba(0, 0, 0, 0.54)",
                            paddingBottom: "10px",
                          }}
                        >
                          Imagem {idx + 1}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <img
                          style={{ maxHeight: "30vh" }}
                          src={image.training_image_filename}
                          id={"image"}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid xs={6}>
                      <CustomDropzone
                        onDropAction={(file) => {
                          this.onDropActionImage(file, idx);
                        }}
                        completed={
                          props.completed.completedImages &&
                          props.completed.completedImages.length > 0
                            ? props.completed.completedImages[idx]
                            : 0
                        }
                        inactiveLabel={
                          "Arraste a imagem(.jpeg, .png, .gif) para cá, \n adicione até 10 imagens de uma vez"
                        }
                        activeLabel={"Solte a imagem"}
                      />
                      <Grid xs={12}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            lineHeight: 1,
                            letterSpacing: "0.00938em",
                            float: "bottom",
                            color: "rgba(0, 0, 0, 0.54)",
                            paddingBottom: "10px",
                          }}
                        >
                          Tamanho Máximo da Imagem: 10 Mb {"\n"}
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  {!this.object.training.challenge_photos || content_type !=="challenge" && 
                    <Grid xs={6} item>
                      
                    <Grid item xs={12}>
                      <span
                        style={{
                          float: "left",
                          color: "rgba(175, 166, 166, 0.54)",
                          padding: "0",
                          fontSize: "13px",
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          fontWeight: "400",
                          lineHeight: "1",
                          letterSpacing: "0.00938em",
                          marginTop: "10px",
                          paddingLeft: "20px",
                          paddingBottom: "10px",
                        }}
                      >
                        legenda{idx + 1} -{" "}
                        {image.training_image_description
                          ? image.training_image_description.length
                          : 0}
                        /1000
                      </span>
                    </Grid>
                    <TextareaAutosize
                      aria-label={"legenda"}
                      placeholder={"legenda(Máximo de 1000 caracteres)"}
                      maxLength={1000}
                      rows={4}
                      value={image.training_image_description}
                      onChange={(event) => {
                        this.onChangeUpdateTrainingImage(event, idx);
                      }}
                      style={{
                        width: "90%",
                        height: "70%",
                        padding: "10px 10px",
                      }}
                    />
                  </Grid>
                    
                  }
                  <Grid xs={12}>
                    <Button
                      onClick={() => {
                        this.deleteImage(idx);
                      }}
                      variant="contained"
                      color={"secondary"}
                      style={{ background: "#CC0B0B" }}
                    >
                      Remover Imagem {idx + 1}
                    </Button>
                  </Grid>
                  <Grid xs={12}>
                    <div style={{ height: "40px" }} />
                  </Grid>
                </Grid>
              );
            })
          : null}
        {this.object.training?.training_images?.length < 10 && (
          <Button onClick={this.addImage} variant="contained" color={"primary"}>
            Adicionar Imagem
            <AddIcon />
          </Button>
        )}
        {!this.object.training?.training_images && (
          <Button onClick={this.addImage} variant="contained" color={"primary"}>
            Adicionar Imagem
            <AddIcon />
          </Button>
        )}
        <Grid item xs={12}>
          <span
            style={{
              float: "left",
              color: "rgba(0, 0, 0, 0.54)",
              padding: "0",
              fontSize: "13px",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontWeight: "400",
              lineHeight: "1",
              letterSpacing: "0.00938em",
              marginTop: "10px",
            }}
          ></span>
        </Grid>
      </Grid>
    );
  };

  this.showQuiz = () => {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            textAlign: "left",
          }}
        >
          Perguntas:
        </span>
        {this.object.form && this.object.form.forms_questions
          ? this.object.form.forms_questions.map((question, idx) => {
              let deleteQuestion = () => {
                let formsQuestionsList = cloneDeep(
                  this.object.form.forms_questions
                );
                formsQuestionsList.splice(idx, 1);
                this.updateContent(
                  this.object,
                  "form.forms_questions",
                  formsQuestionsList
                );
              };

              return (
                <FormQuestionCard
                  formQuestion={question}
                  onQuestionChange={this.onQuestionChange}
                  deleteQuestion={deleteQuestion}
                  idx={idx}
                  formType={
                    this.object.form ? this.object.form.form_type : null
                  }
                />
              );
            })
          : null}
        {this.object.form &&
        this.object.form.form_type !== 2 &&
        this.object.form.form_type !== 3 ? (
          <Button onClick={this.addQuestion} variant="contained">
            Adicionar Pergunta
            <AddIcon />
          </Button>
        ) : null}
      </Grid>
    );
  };

  this.onChangeUpdateRoles = (value) => {
    let roles;
    if (value != null) {
      //null cant use map function
      roles = value.map((obj) => {
        return {
          //revert the label to put the id_role and role_name in the Content Object
          id_role: obj.value,
          role_name: obj.label,
        };
      });
    } else {
      roles = [];
    }
    this.updateContent(this.object, "roles", roles, true);
  };
  this.onChangeUpdateContents = (value) => {
    this.updateContent(
      this.object,
      "id_predecessor_content",
      value.value,
      true
    );
  };

  //this.contentType = props.descriptionState.content_type;

  this.predecessorContentChosen = (value) => {
    this.updateContent(
      this.object,
      "id_predecessor_content",
      value ? (value.id_content ? value.id_content : null) : null,
      true
    );
    this.updateContent(this.object, "predecessor_content", value, true);
    if (value) {
      this.object.defaultPredecessorContentValue = {
        value: value,
        label: value.training_title
          ? value.training_title
          : value.form_title
          ? value.form_title
          : "ID:" + value.id_content,
      };
    } else {
      this.object.defaultPredecessorContentValue = {
        value: null,
        label: "",
      };
    }
  };

  this.quizContentChosen = (value) => {
    this.updateContent(
      this.object,
      "id_content_quiz",
      value ? (value.id_content ? value.id_content : null) : null,
      true
    );
    this.updateContent(this.object, "quiz_content", value, true);
    if (value) {
      this.object.defaultQuizContentValue = {
        value: value,
        label: value.training_title
          ? value.training_title
          : value.form_title
          ? value.form_title
          : "ID:" + value.id_content,
      };
    } else {
      this.object.defaultQuizContentValue = {
        value: null,
        label: "",
      };
    }
  };

  return (
    <div style={{ height: "auto" }}>
      {this.object.is_marketing ? (
        // <Grid xs={6} item>
        <Grid container>
          <Grid item xs={12}>
            {(this.object.training &&
              this.object.training.training_cover_image) ||
            (this.object.form && this.object.form.form_image) ? (
              <img
                alt="capa"
                style={{
                  width: "30vw",
                  height: "15vw",
                  objectFit: "cover",
                }}
                src={
                  (content_type === "video" ||
                    content_type === "pdf" ||
                    content_type === "image" ||
                    content_type === "challenge" ||
                    content_type === "trail" ||
                    content_type === "quiz") &&
                  this.object.training
                    ? this.object.training.training_cover_image
                    : content_type === "form"
                    ? this.object.form.form_image
                    : null
                }
                id={"image-modal"}
              />
            ) : (
              <div id="picker">
                <CustomDropzone
                  onDropAction={this.onDropActionCoverImage}
                  completed={props.completed.completedCoverImage}
                  inactiveLabel={
                    "Arraste a imagem(.jpeg, .png, .gif) de capa para cá"
                  }
                  activeLabel={"Solte a imagem de capa"}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
                lineHeight: 1,
                letterSpacing: "0.00938em",
                // float: 'left',
                color: "rgba(0, 0, 0, 0.54)",
                paddingBottom: "10px",
                textAlign: "center",
              }}
            >
              {this.object.is_marketing
                ? "Imagem de Capa (864px X 864px)"
                : "Imagem de Capa (1700px X 864px)"}
              {(this.object.training &&
                this.object.training.training_cover_image) ||
              (this.object.form && this.object.form.form_image) ? (
                <a href="#" onClick={this.deleteCoverImage}>
                  {" "}
                  Remover Imagem
                </a>
              ) : null}
            </span>
          </Grid>
        </Grid>
      ) : // </Grid>
      null}
      {/* {this.object.is_marketing ? (
        <Grid container>
          <Grid item xs={12}>
            {(this.object.training &&
              this.object.training.training_cover_image) ||
            (this.object.form && this.object.form.form_image) ? (
              <img
                style={{
                  width: '30vw',
                  height: '15vw',
                  objectFit: 'cover',
                }}
                src={
                  (this.contentType === 'video' ||
                    this.contentType === 'pdf' ||
                    this.contentType === 'image' ||
                    this.contentType === 'challenge' ||
                    this.contentType === 'trail' ||
                    this.contentType === 'quiz') &&
                  this.object.training
                    ? this.object.training.training_cover_image
                    : this.contentType === 'form'
                    ? this.object.form.form_image
                    : null
                }
                id={'image-modal'}
              />
            ) : (
              <div id='picker'>
                <CustomDropzone
                  onDropAction={this.onDropActionCoverImage}
                  completed={props.completed.completedCoverImage}
                  inactiveLabel={
                    'Arraste a imagem(.jpeg, .png, .gif) de capa para cá'
                  }
                  activeLabel={'Solte a imagem de capa'}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <span
              style={{
                fontSize: '12px',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
                lineHeight: 1,
                letterSpacing: '0.00938em',
                // float: 'left',
                color: 'rgba(0, 0, 0, 0.54)',
                paddingBottom: '10px',
              }}
            >
              {this.object.is_marketing
                ? 'Imagem de Capa (864px X 864px)'
                : 'Imagem de Capa (1700px X 864px)'}
              {(this.object.training &&
                this.object.training.training_cover_image) ||
              (this.object.form && this.object.form.form_image) ? (
                <a href='#' onClick={this.deleteCoverImage}>
                  {' '}
                  Remover Imagem
                </a>
              ) : null}
            </span>
          </Grid>
        </Grid>
      ) : null} */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          alignItems: "center",
          padding: "30px 20px 10px 20px",
        }}
      >
        {/* <Grid container spacing={2}> */}
        <Grid xs={12} container direction="row" spacing={1}>
          <Grid xs={12} container spacing={2}>
            <Grid xs={6} item>
              <TextField
                // label="Título Conteúdo "
                label={

                  this.object.training && this.object.training.training_title
                  ? "Título Conteúdo " + this.object.training.training_title.length+"/200"
                  : this.object.form && this.object.form.form_title
                  ? "Título Conteúdo " + this.object.form.form_title.length+"/200"
                  : "Título Conteúdo 0/200"
              }
                maxLength={200}

                value={
                  this.object.training && this.object.training.training_title
                    ? this.object.training.training_title
                    : this.object.form && this.object.form.form_title
                    ? this.object.form.form_title
                    : ""
                }
                style={{ width: "100%" }}
                onChange={this.onChangeUpdate("content_title", false)}
                onBlur={this.onChangeUpdate("content_title", true)}
                multiline
              />
            </Grid>
            {/* //dani   */}
            <Grid xs={2} item></Grid>
            <Grid xs={1} item>
              <TextField label="ID" value={this.object.id_content} disabled />
            </Grid>
            <Grid xs={3} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label="Data da ultima alteração"
                  value={this.object.updated_date}
                  style={{ width: "100%" }}
                  format="dd/MM/yyyy HH:mm"
                  disabled
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid xs={2} item></Grid>
          <Grid xs={12} container direction="row" spacing={1}>
            <Grid xs={3} container direction={"column"}>
              <Grid item>
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "0",
                    fontSize: "13px",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: "400",
                    lineHeight: "1",
                    letterSpacing: "0.00938em",
                  }}
                >
                  Papéis:
                </span>
              </Grid>
              <Grid item>
                <Select
                  placeholder={"Selecione os Papéis"}
                  options={this.object.roleOptions}
                  value={this.object.defaultRoleValues}
                  onChange={this.onChangeUpdateRoles}
                  isMulti
                ></Select>
              </Grid>
            </Grid>
            <Grid xs={0.5} item></Grid>
            <Grid xs={3} container direction={'column'}>
                <Grid item>
                    <span
                        style={{
                            float: 'left',
                            color: 'rgba(0, 0, 0, 0.54)',
                            padding: '0',
                            fontSize: '13px',
                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                            fontWeight: '400',
                            lineHeight: '1',
                            letterSpacing: '0.00938em',
                            // marginTop:'5px'
                            margin: "8px 0px 0px 0px",

                        }}
                    >
                        Tema:
                    </span>
                </Grid>
                <Grid item>
                    <Select
                        placeholder={'Selecione o Tema'}
                        options={this.object.subjectOptions}
                        value={this.object.defaultSubjectValue}
                        onChange={this.onChangeUpdateSubjects}
                    ></Select>
                </Grid>
            </Grid>

            <Grid xs={0.5} item></Grid>
            {!this.object.is_marketing || this.object.is_marketing === 0 ? (
              <Grid xs={4} container direction={"column"}>
                <Grid item>
                  <span
                    style={{
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Conteúdo pré requisito:
                  </span>
                </Grid>
                <Grid item>
                  <Select
                    placeholder={"Selecione o Conteúdo pré requisito"}
                    value={this.object.defaultContentValues}
                    onChange={this.onChangeUpdateContents}
                    options={this.object.contentOptions}
                  ></Select>
                </Grid>
              </Grid>
            ) : null}

            <Grid xs={0.5} item></Grid>
            {this.object.content_type === 8 ? (
              <Grid xs={4} container direction={"column"}>
                <Grid item>
                  <span
                    style={{
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Conteúdo do Quiz:
                  </span>
                </Grid>
                <Grid item>
                  <SearchableSelect
                    placeholder={"Selecione o Conteúdo do Quiz"}
                    searchApiUrl={"/content/select-contents_quiz"}
                    actionOnSelectedOption={(value) => {
                      this.quizContentChosen(value);
                    }}
                    defaultValue={this.object.defaultQuizContentValue}
                  ></SearchableSelect>
                </Grid>
              </Grid>
            ) : null}

            <Grid xs={0.5} item></Grid>
          </Grid>
          <Grid xs={2} item></Grid>
          <Grid
            xs={12}
            container
            direction="row"
            spacing={1}
            style={{ marginTop: "1vh" }}
          >
            {/* {this.object.content_type !== 6 &&
            localStorage.getItem('ID_APP') !== '5' ? (
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <span
                      style={{
                        fontSize: '12px',
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        letterSpacing: '0.00938em',
                        float: 'left',
                        color: 'rgba(0, 0, 0, 0.54)',
                        paddingBottom: '0px',
                      }}
                    >
                      {content_type === 'form' ||
                      content_type === 'quiz'
                        ? 'Responder somente uma vez?'
                        : 'Some quando Completado:'}
                    </span>
                  </Grid>
                  <Grid xs={2} item>
                    <Grid style={{ float: 'left' }}>
                      <Checkbox
                        onChange={this.onChangeUpdate(
                          'is_removed_when_completed',
                          true,
                        )}
                        checked={this.object.is_removed_when_completed === 1}
                      />{' '}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null} */}
            {/* {localStorage.getItem('ID_APP') === '4' ? null : (
              <Grid xs={2} item>
                <TextField
                  label={
                    localStorage.getItem('ID_APP') != 3 ? 'Moedas' : 'Pontos'
                  }
                  value={
                    this.object.content_points &&
                    this.object.content_points >= 0 &&
                    !this.object.is_marketing
                      ? this.object.content_points
                      : ''
                  }
                  disabled={this.object.is_marketing}
                  style={{ width: '100%' }}
                  onChange={this.onChangeUpdate('content_points', false)}
                  onBlur={this.onChangeUpdate('content_points', true)}
                  type={'number'}
                />
              </Grid>
            )} */}
            {localStorage.getItem("ID_APP") === "4" ? (
              <Grid xs={2} item>
                <TextField
                  label="Cupons"
                  value={
                    this.object.normal_tickets && !this.object.is_marketing
                      ? this.object.normal_tickets
                      : 0
                  }
                  disabled={this.object.is_marketing}
                  style={{ width: "100%" }}
                  onChange={this.onChangeUpdate("normal_tickets", false)}
                  onBlur={this.onChangeUpdate("normal_tickets", true)}
                  type={"number"}
                />
              </Grid>
            ) : null}

            <Grid xs={2} item>
              <TextField
                label="Ordem Conteúdo"
                value={this.object.order_content}
                onChange={this.onChangeUpdate("order_content", false)}
                style={{ width: "100%" }}
              />
            </Grid>
            {localStorage.getItem("ID_APP") === 5 ? (
              <Grid xs={0.5} item></Grid>
            ) : null}
            {localStorage.getItem("ID_APP") === "5" ? (
              <Grid xs={3} container direction={"column"}>
                <Grid item>
                  <span
                    style={{
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Marca:
                  </span>
                </Grid>
                <Grid item>
                  <Select
                    placeholder={"Selecione a Marca"}
                    options={this.object.brandsOptions}
                    value={this.object.defaultBrandValue}
                    onChange={this.onChangeUpdateBrands}
                  ></Select>
                </Grid>
              </Grid>
            ) : null}
          </Grid>

          {this.object.content_type === 6 ? <Grid xs={2} item></Grid> : null}

          <Grid xs={12} container direction="row" spacing={1}>
            <Grid item xs={12}>
              <span
                style={{
                  fontSize: "18px",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 400,
                  lineHeight: 1,
                  letterSpacing: "0.00938em",
                  float: "left",
                  color: "rgba(0, 0, 0, 0.54)",
                  paddingBottom: "15px",
                  paddingTop: "40px",
                }}
              >
                Conteúdo:
              </span>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Grid container>
              {content_type === "form" || content_type === "quiz" ? (
                <Grid item xs={12}>
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      lineHeight: 1,
                      letterSpacing: "0.00938em",
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      paddingBottom: "0px",
                    }}
                  >
                    Tipo de Formulário
                  </span>
                </Grid>
              ) : null}
              {content_type === "form" || content_type === "quiz" ? (
                <Grid item xs={12}>
                  <RadioGroup
                    aria-label="form_type"
                    name="form_type"
                    value={form.form_type}
                    onChange={this.changeRadioFormType}
                    row
                    style={{ marginLeft: "10px" }}
                  >
                    <div>
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Questionario"
                        labelPlacement="end"
                        disabled={content_type === "quiz"}
                      />
                      <FormControlLabel
                        value={5}
                        control={<Radio color="primary" />}
                        label="Prova"
                        labelPlacement="end"
                        disabled={content_type === "quiz"}
                      />
                      {/*<FormControlLabel
                        value={2}
                        control={<Radio color='primary' />}
                        label='Fale Conosco'
                        labelPlacement='end'
                        disabled={content_type === 'quiz'}
                      />*/}
                      <FormControlLabel
                        value={3}
                        control={<Radio color="primary" />}
                        label="Orçamento"
                        labelPlacement="end"
                        disabled={content_type === "quiz"}
                      />
                      {/*<FormControlLabel
                        value={4}
                        control={<Radio color='primary' />}
                        label='Adesão'
                        labelPlacement='end'
                        disabled={content_type === 'quiz'}
                      />*/}
                    </div>
                  </RadioGroup>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {/* //daniii */}
          {/* {this.object.is_marketing ? (
            <Grid xs={6} item>
              <Grid container>
                <Grid item xs={12}>
                  <span
                    style={{
                      fontSize: '12px',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      lineHeight: 1,
                      letterSpacing: '0.00938em',
                      float: 'left',
                      color: 'rgba(0, 0, 0, 0.54)',
                      paddingBottom: '10px',
                    }}
                  >
                    {this.object.is_marketing ? 'Imagem de Capa (864px X 864px)' : 'Imagem de Capa (1700px X 864px)'}
                    {(this.object.training &&
                      this.object.training.training_cover_image) ||
                    (this.object.form && this.object.form.form_image) ? (
                      <a href='#' onClick={this.deleteCoverImage}>
                        {' '}
                        Remover Imagem
                      </a>
                    ) : null}
                  </span>
                </Grid> 
                
                <Grid item xs={12}>
                  {(this.object.training &&
                    this.object.training.training_cover_image) ||
                  (this.object.form && this.object.form.form_image) ? (
                    <img
                      alt='capa'
                      style={{
                        width: '30vw',
                        height: '15vw',
                        objectFit: 'cover',
                      }}
                      src={
                        (content_type === 'video' ||
                          content_type === 'pdf' ||
                          content_type === 'image' ||
                          content_type === 'challenge' ||
                          content_type === 'trail' ||
                          content_type === 'quiz') &&
                        this.object.training
                          ? this.object.training.training_cover_image
                          : content_type === 'form'
                          ? this.object.form.form_image
                          : null
                      }
                      id={'image-modal'}
                    />
                  ) : (
                    <div id='picker'>
                      <CustomDropzone
                        onDropAction={this.onDropActionCoverImage}
                        completed={props.completed.completedCoverImage}
                        inactiveLabel={
                          'Arraste a imagem(.jpeg, .png, .gif) de capa para c 22á'
                        }
                        activeLabel={'Solte a imagem de capa'}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : null} */}

          <Grid xs={12}>
            <div style={{ height: "40px" }}></div>
          </Grid>
          {content_type === "video" ? (
            <Grid xs={12} item>
              {/* <Grid xs={6} item> */}
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    fontSize: "12px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    textAlign: "center",
                    letterSpacing: "0.00938em",
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingBottom: "10px",
                    paddingRight: "50%",
                  }}
                >
                  <span
                    style={{
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                      marginTop: "10px",
                      paddingLeft: "20px",
                      paddingBottom: "10px",
                    }}
                  >
                    Vídeo de Treinamento - Tamanho Máximo: 1 Gb
                    {this.object.training &&
                    this.object.training.training_video ? (
                      <a href="#" onClick={this.deleteVideo}>
                        {" "}
                        Remover Video
                      </a>
                    ) : null}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  {this.object.training &&
                  this.object.training.training_video ? (
                    <video
                      style={{ maxHeight: "100%", maxWidth: "100%" }}
                      controls
                    >
                      <source
                        src={
                          this.object.training &&
                          this.object.training.training_video
                            ? this.object.training.training_video
                            : null
                        }
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <div id="picker">
                      <CustomDropzone
                        onDropAction={this.onDropActionVideo}
                        completed={props.completed.completedVideo}
                        inactiveLabel={
                          "Arraste o vídeo(.mp4) de treinamento para cá"
                        }
                        activeLabel={"Solte o vídeo de treinamento"}
                      />
                    </div>
                  )}
                </Grid>
                {/* aqui */}
                <Grid item xs={6}>
                  {/* <Grid container> */}
                  <span
                    style={{
                      // float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                      marginTop: "10px",
                      paddingLeft: "20px",
                      paddingBottom: "10px",
                    }}
                  >
                    legenda - {/* //aqui  */}
                    {this.object.training &&
                    this.object.training.training_description
                      ? this.object.training.training_description.length
                      : 0}
                    /1000
                  </span>
                  {/* </Grid> */}
                  <TextareaAutosize
                    aria-label={"legenda"}
                    placeholder={"legenda(Máximo de 1000 caracteres)"}
                    maxLength={1000}
                    rows={4}
                    value={
                      this.object.training &&
                      this.object.training.training_description
                        ? this.object.training.training_description
                        : ""
                    }
                    onChange={(event) => {
                      this.onChangeUpdateTrainingVideo(event);
                    }}
                    style={{
                      width: "90%",
                      height: "70%",
                      padding: "10px 10px",
                    }}
                  />
                </Grid>
                {/* aqui */}
              </Grid>
              {/* </Grid> */}
            </Grid>
          ) : null}
          {content_type === "pdf" ? (
            <Grid xs={6} item>
              <Grid container>
                <Grid item xs={12}>
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      lineHeight: 1,
                      letterSpacing: "0.00938em",
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      paddingBottom: "10px",
                    }}
                  >
                    Material de Treinamento - Tamanho Máximo: 100 Mb
                    {this.object.training &&
                    this.object.training.training_file ? (
                      <a href="#" onClick={this.deleteFile}>
                        {" "}
                        Remover Arquivo
                      </a>
                    ) : null}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  {this.object.training &&
                  this.object.training.training_file ? (
                    <a
                      target="_blank"
                      href={
                        this.object.training &&
                        this.object.training.training_file
                          ? this.object.training.training_file
                          : null
                      }
                      rel="noreferrer"
                    >
                      Clique aqui para acessar material.
                    </a>
                  ) : (
                    <div id="picker">
                      <CustomDropzone
                        onDropAction={(file) => {
                          this.onDropActionFile(file, "training.training_file");
                        }}
                        completed={props.completed.completedFile}
                        inactiveLabel={"Arraste o PDF de treinamento para cá"}
                        activeLabel={"Solte o material de treinamento"}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {content_type === "challenge" ? (
            <Grid xs={6} item>
              <div style={{ minHeight: 200 }}>
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: "0.00938em",
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingBottom: "10px",
                  }}
                >
                  {/* aqui mesmo */}
                  Descrição do Desafio: {
                    this.object.training &&
                    this.object.training.training_description
                      ? this.object.training.training_description.length
                      : '0'
                  }/5000
                  {this.object.training &&
                  this.object.training.training_file ? (
                    <a href="#" onClick={this.deleteFile}>
                      {" "}
                      Remover Arquivo
                    </a>
                  ) : null}
                </span>
                <TextareaAutosize
                  aria-label="Descrição do Desafio"
                  placeholder="Descrição do Desafio"
                  rows={4}
                  maxLength={5000}
                  value={
                    this.object.training &&
                    this.object.training.training_description
                      ? this.object.training.training_description
                      : ""
                  }
                  onChange={this.onChangeUpdate(
                    "training.training_description",
                    false
                  )}
                  onBlur={this.onChangeUpdate(
                    "training.training_description",
                    true
                  )}
                  style={{
                    width: "90%",
                    height: "80%",
                    padding: "10px 10px",
                  }}
                />





                {/* <TextField
                  label='Limite de envio de fotos   '
                  value={this.object.training.challenge_photos_limit}
                  style={{ width: '100%' }}
                  onChange={this.onChangeUpdate(
                    'training.challenge_photos_limit',
                    false,
                  )}
                  onBlur={this.onChangeUpdate(
                    'training.challenge_photos_limit',
                    true,
                  )}
                  type={'number'}
                /> */}
              </div>
            </Grid>
          ) : null}
          <Grid container direction="row" spacing={2}>
            {content_type === "form" ? this.showForm() : null}
            {content_type === "image" ? this.showImage() : null}
            {content_type === "challenge" ? this.showImage() : null}
            {content_type === "quiz" ? this.showQuiz() : null}
            {content_type === "trail" ? this.showTrailContent() : null}
          </Grid>
          <Grid xs={12} item>
            <SaveButton
              style={{ float: "right" }}
              onClickSave={this.onClickPublishContent}
              ref={(ref) => {
                this.saveButton = ref;
              }}
              text={"Publicar"}
            />
            <SaveButton
              style={{ float: "right" }}
              onClickSave={this.onClickSaveContent}
              ref={(ref) => {
                this.saveButton = ref;
              }}
              text={"Salvar Rascunho"}
            />
            <DeleteButton
              style={{ float: "right", background: "#CC0B0B" }}
              onClickDelete={this.onClickDeleteContent}
            />
            <ArchiveButton
              style={{ float: "right" }}
              onClickArchive={this.onClickArchiveContent}
              is_archived={this.object.is_archived}
              ref={(ref) => {
                this.archiveButton = ref;
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
