import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Modal from '@mui/material/Modal';

import { ButtonWhite, ButtonBlueBorder, CustomIconButton } from '../buttons/ThemeButtons';
import { Box } from '@mui/system';

export default function AlertDialog({
  isOpen,
  handleClose,
  handleConfirm,
  title,
  description,
  confirmTextButton,
  cancelTextButton = "Voltar",
  alertFooter = null
}) {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        bgcolor="#FFF"
        border="1px solid #E0E5EC"
        borderRadius={2}
        display='flex'
        flexDirection='column'
        margin='15% auto'
        width='50%'
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          px={4}
          py={2}
        >
          <Box
            isplay="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <WarningRoundedIcon
                sx={{ color: "#F9A825", marginRight: 2 }}
              />
              <span
                style={{
                  color: "#333333",
                  fontSize: 22,
                  display: 'block'
                }}
              >
                {title}
              </span>
            </Box>
          </Box>

          <CustomIconButton
            onClick={handleClose}
          >
            <CloseIcon />
          </CustomIconButton>
        </Box>

        <Box
          paddingX={4}
        >
          <Box>
            <span
              style={{
                color: "#333",
                fontSize: 15,
                fontWeight: 400
              }}
            >
              {description}
            </span>
          </Box>
        </Box>
        <Box
          display='flex'
          justifyContent='flex-end'
          marginY={5}
          marginX={5}
        >
          {
            alertFooter ? (alertFooter)
            : (
              <>
                <ButtonWhite
                  style={{ marginRight: 16 }}
                  onClick={handleClose}
                >
                  {cancelTextButton}
                </ButtonWhite>

                <ButtonBlueBorder
                  onClick={handleConfirm}
                >
                  {confirmTextButton}
                </ButtonBlueBorder>
              </>
            )
          }
        </Box>
      </Box>
    </Modal>
  );
}