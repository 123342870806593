import React from 'react';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { red, green, blue } from '@material-ui/core/colors';
import DeleteButton from '../buttons/DeleteButton'
import { DateTimePicker } from '@material-ui/pickers/'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select'


export default function ProductDescription(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;
    this.relations = props.relations;
    this.brands = this.relations[0];



    // Functions
    this.updateProduct = props.updateObject;
    this.deleteProduct = props.deleteObject;
    this.nullDescription = props.nullDescription;

    //beginning of the brands section
    //get the values for <Select> from object.brands and put labels "value" and "label"
    this.putLabel = (id) => {
        for (let i = 0; i < this.brands.length; i++) {
            if (this.brands[i].id_brand === id) {
                return {
                    value: id,
                    label: this.brands[i].brand_name
                }
            }
        }
        return [];
    }
    //make the brandDefaultValue for <Select>
    this.object.brandDefaultValue = this.putLabel(this.object.id_brand);

    //Function to Update the Brands Value in AdminProductScreen
    this.onChangeUpdateBrand = (brand) => {
        this.object.brandDefaultValue = this.putLabel(brand.value)
        this.updateProduct(this.object, 'id_brand', brand.value, true);
    }

    //put the labels "value" and "label" in the brandOptions, because the <Select> needs it to work
    this.object.brandOptions = this.brands.map((brand) => {
        let brandOption = {
            value: brand.id_brand,
            label: brand.brand_name
        };
        return brandOption;
    });


    this.onChangeUpdate = (field, force) => event => {
        let value = (event && event.target) ? event.target.value : null;
        this.updateProduct(this.object, field, value, force);
    };

    this.onClickDeleteProduct = () => {
        if (window.confirm('Deseja mesmo deletar esse item?')) {
            this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the product
            this.deleteProduct(this.object);
        }
    };

    return (
        <div style={{ 'height': 'auto' }}>
            <Grid container>
                <Grid xs={1}>

                </Grid>
                <Grid xs={10} container direction="row" spacing={1} style={{ marginTop: "10px" }}>
                    <Grid xs={3} item>
                        <TextField label="ID" value={this.object.id_product} disabled />
                    </Grid>
                    <Grid xs={9} item>
                        <TextField label="Nome" value={this.object.product_name ? this.object.product_name : ''} style={{ 'width': '100%' }}
                            onChange={this.onChangeUpdate('product_name', false)}
                            onBlur={this.onChangeUpdate('product_name', true)} />
                    </Grid>
                    <Grid xs={3} item>
                        <TextField label="Preço" value={this.object.product_price ? this.object.product_price : ''} style={{ 'width': '100%' }}
                            onChange={this.onChangeUpdate('product_price', false)}
                            onBlur={this.onChangeUpdate('product_price', true)} />
                    </Grid>
                    <Grid xs={9} item>
                        <TextField label="Código do produto" value={this.object.product_code ? this.object.product_code : ''} style={{ 'width': '100%' }}
                            onChange={this.onChangeUpdate('product_code', false)}
                            onBlur={this.onChangeUpdate('product_code', true)} />
                    </Grid>
                    <Grid xs={6} item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker label="Data de Criação" value={this.object.created_date} style={{ 'width': '100%' }} format="dd/MM/yyyy HH:mm" disabled />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid xs={6} item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker label="Data da ultima alteração" value={this.object.updated_date} style={{ 'width': '100%' }} format="dd/MM/yyyy HH:mm" label-style={{ 'overflowX': 'hidden' }} disabled />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid xs={12}>
                        <h><span style={{
                            float: 'left', color: 'rgba(0, 0, 0, 0.54)', padding: '0', fontSize: '13px',
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                            letterSpacing: '0.00938em'
                        }}>Marca:</span></h>
                    </Grid>
                    <Grid xs={12} style={{ marginTop: '5px' }}>
                        <Select placeholder={'Selecione a Marca'} options={this.object.brandOptions} value={this.object.brandDefaultValue} onChange={this.onChangeUpdateBrand} />
                    </Grid>
                </Grid>
                <Grid xs={11}>
                    <DeleteButton style={{ float: 'right', background: '#CC0B0B' }} onClickDelete={this.onClickDeleteProduct}></DeleteButton>
                </Grid>
            </Grid>
        </div>
    );

}