import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Message from '../../models/Message';
import Role from '../../models/Role';
import Content from '../../models/Content';
import MessageCard from '../components/cards/MessageCard';
import CupomDescription from '../components/description_cards/CupomDescription';
import Spinner from '../components/buttons/Spinner';

class AdminNewsScreen extends Component {
  editionTimeout = null;

  state = {
    messages: [],
    relations: [],
    loading: true
  };

  // TEXTS
  searchText_placeholder = 'Título ou descrição do Boleto';
  addButton_placeholder = 'Boleto';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    this.reloadMessages();
  }

  refreshMessages = async (parameters) => {
    await this.reloadMessages(parameters);
  };

  reloadMessages = async (parameters) => {
    try {
      let [messages, roles] = await Promise.all([
        Message.query(parameters),
        Role.query(),
      ]);
      messages = messages.filter(message => {
          return message.is_cupon === 1;
      })
      this.setState({ messages: messages });
      let relations = this.state.relations;
      relations[0] = roles;
      this.setState({ relations: relations });
      this.setState({loading: false});
    } catch (err) {
     toast.error('Não foi possível carregar as seus cupons. Tente novamente.');
      console.warn(err);
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
  };

  addMessage = async () => {
    let newMessage = new Message();
    let messageObj = {};
    let contentObj = {};
    try {
      newMessage.is_cupon = 1;
      messageObj = await newMessage.save();
      let newContent = new Content();
      newContent.content = {
        id_app: localStorage.getItem('ID_APP'),
        is_challenge: 1,
        content_type: 7,
        id_message: messageObj.id_message,
      };

      try {
        contentObj = await newContent.saveMessage();
      } catch (err) {
        console.error(err);
        throw Error(err);
      }
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let messageList = this.state.messages;
    messageList.push(messageObj);
    this.setState({ messages: messageList });
    messageObj.content = contentObj;
    return messageObj;
  };

  updateMessage = (messageObj, field, value, force) => {
    let messageList = this.state.messages;
    let position = messageList.findIndex((obj) => {
      return obj.id_message === messageObj.id_message;
    });
    let messageToUpdate = messageList.find((obj) => {
      return obj.id_message === messageObj.id_message;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          messageToUpdate[prop] = value[prop];
        }
      }
    } else if (field.indexOf('.') > 0) {
      let hierarchy = field.split('.');
      let obj = messageToUpdate;
      for (let i = 0; i < hierarchy.length; i++) {
        if (i === hierarchy.length - 1) {
          obj[hierarchy[i]] = value;
          break;
        }
        obj = obj[hierarchy[i]];
      }
    } else {
      messageToUpdate[field] = value;
    }
    this.setState({ messages: messageList });
    return messageList[position];
  };

  saveMessage = async (messageObj) => {
    let messageList = this.state.messages;
    let position = messageList.findIndex((obj) => {
      return obj.id_message === messageObj.id_message;
    });
    let messageToUpdate = messageList.find((obj) => {
      return obj.id_message === messageObj.id_message;
    });

    let contentToUpdate = await Content.getMessages(messageObj.id_message);
    if (messageObj.send_type === 2) {
      contentToUpdate[0].roles = messageObj.content.roles;
    }
    
    messageToUpdate.users = messageObj.users;
    messageToUpdate = new Message(messageToUpdate);
    if (messageToUpdate != null) {
      await messageToUpdate
        .saveMessageDraft()
        .then(async (messageObj) => {
          // Message upload to server worked
          if (messageObj.send_type === 2) {
            messageObj.content.roles = contentToUpdate[0].roles;
            messageList[position] = messageObj;
            this.setState({ messages: messageList });
            if (contentToUpdate != null) {
              await contentToUpdate[0]
                .save()
                .then((messageObj) => {
                  // Message upload to server worked
                  // messageList[position] = messageObj;
                  // this.setState({ messages: messageList });
                  //toast.success('Mensagem salva com sucesso!');
                })
                .catch((err) => {
                  console.error(err);
                 toast.error('Erro ao salvar mensagem!');
                 return;
                });
            }
          }
         

          toast.success("Mensagem salva com sucesso!");
        })
        .catch((err) => {
          console.error(err);
          toast.error('Erro ao salvar mensagem!');
        });
    }
  };

  archiveMessage = async (messageObj) => {
    let messageList = this.state.messages;
    let position = messageList.findIndex((obj) => {
      return obj.id_message === messageObj.id_message;
    });
    let messageToUpdate = messageList.find((obj) => {
      return obj.id_message === messageObj.id_message;
    });
    messageToUpdate.users = messageObj.users;
    messageToUpdate = new Message(messageToUpdate);
    if (messageToUpdate != null) {
      await messageToUpdate
        .archiveMessage()
        .then(async (messageObj) => {
          // Message upload to server worked
          messageList[position] = messageObj;
          this.setState({ messages: messageList });
          let contentObj = new Content(messageObj.content);
          contentObj.is_archived = messageObj.is_archived;
          await contentObj.saveMessage();
          toast.success('Mensagem salva com sucesso!');
        })
        .catch((err) => {
          console.error(err);
         toast.error('Erro ao salvar mensagem!');
        });
    }
  };

  sendMessageObj = async (messageObj) => {
    let messageList = this.state.messages;
    let position = messageList.findIndex((obj) => {
      return obj.id_message === messageObj.id_message;
    });
    let messageToUpdate = messageList.find((obj) => {
      return obj.id_message === messageObj.id_message;
    });
    messageToUpdate.user = messageObj.user;
    if (messageToUpdate != null) {
      try {
        if (!messageToUpdate.target_user || messageToUpdate.target_user == '') {
          throw new Error('users');
        }
        if (messageToUpdate.target_user == 'individual') {
          if (!messageToUpdate.user || messageToUpdate.user.length === 0) {
            throw new Error('users');
          }
        }

        if (messageToUpdate.target_user == 'roles') {
          if (
            !messageToUpdate.content.roles ||
            messageToUpdate.content.roles === 0
          ) {
            throw new Error('roles');
          }
        }

        if (
          !messageToUpdate.message_title ||
          messageToUpdate.message_title == ''
        ) {
          throw new Error('title');
        }
        if (
          !messageToUpdate.message_content ||
          messageToUpdate.message_content == ''
        ) {
          throw new Error('content');
        }
        messageToUpdate.is_published = 1;
        messageToUpdate = new Message(messageToUpdate);
        await messageToUpdate
          .sendMessage()
          .then(async (messageObj) => {
            // Message upload to server worked
            messageList[position] = messageObj;
            this.setState({ messages: messageList });
            let contentObj = new Content(messageObj.content);
            contentObj.is_published = messageObj.is_published;
            await contentObj.saveMessage();
            let contentToUpdate = await Content.getMessages(
              messageObj.id_message,
            );
            contentToUpdate[0].roles = messageObj.content?.roles;
            if (contentToUpdate != null) {
              await contentToUpdate[0]
                .save()
                .then((messageObj) => {
                  // Message upload to server worked
                  // messageList[position] = messageObj;
                  // this.setState({ messages: messageList });
                  toast.success('Mensagem enviada com sucesso!');
                  this.refreshMessages();
                })
                .catch((err) => {
                  console.error(err);
                 toast.error('Erro ao salvar mensagem!');
                });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (err) {
        console.error(err);
        switch (err.message) {
          case 'users':
           toast.error(
              'Erro ao salvar mensagem! Selecione um ou mais usuários antes de enviar.',
            );
            throw new Error('message_user');
            break;
          case 'title':
           toast.error(
              'Erro ao salvar mensagem! Insira um título para a sua mensagem antes de enviar.',
            );
            throw new Error('message_title');
            break;
          case 'content':
           toast.error(
              'Erro ao salvar mensagem! Insira uma mensagem antes de enviar.',
            );
            throw new Error('message_title');
            break;
          default:
           toast.error('Erro ao salvar mensagem!');
            throw new Error('message_general');
            break;
        }
      }
    }
  };

  deleteMessage = (messageObj) => {
    let messageList = this.state.messages;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < messageList.length; i++) {
      if (messageObj.id_message === messageList[i].id_message) {
        messageList[i] = new Message(messageList[i]);
        messageList[i].removeMessage();
        messageList.splice(i, 1);
        break;
      }
    }

    this.setState({ messages: messageList });
  };

  render() {
    return (
      <>
        {
          this.state.messages.length === 0  
          && this.state.loading
          ? (
            <Spinner
              margin='13px' 
              texto={'carregando...'}
              border={'#9BB3D4'}
              top={'#1D2D44'}
            />
          )
          :
          <GenericAdminScreen
            searchText_placeholder={this.searchText_placeholder}
            addButton_placeholder={this.addButton_placeholder}
            objectList={this.state.messages}
            objectCard={MessageCard}
            objectDescription={CupomDescription}
            addObject={this.addMessage}
            updateObject={this.updateMessage}
            saveObject={this.saveMessage}
            archiveObject={this.archiveMessage}
            sendMessage={this.sendMessageObj}
            deleteObject={this.deleteMessage}
            refreshObjects={this.refreshMessages}
            relations={this.state.relations}
            objectIdName={'id_message'}
            objectModel={Message}
            onFilterReload={true}
          />
        }
      </>
    );
  }
}

export default AdminNewsScreen;
