import styled from 'styled-components';
import SideNav, {
    Toggle,
    Nav,
    NavItem,
    NavIcon,
    NavText,
} from '@trendmicro/react-sidenav';

// SideNav
const StyledSideNav = styled(SideNav)`
    background-color: #092023;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    text-align: left;
`;
StyledSideNav.defaultProps = SideNav.defaultProps;

// Toggle
const StyledToggle = styled(Toggle)`
    background-color: #092023;
`;
StyledToggle.defaultProps = Toggle.defaultProps;

// Nav
const StyledNav = styled(Nav)`
    background-color: #092023;
    height: 135vh;
    

    &&[class*="expanded--"] {
    
        > [class*="sidenav-navitem--"] {
            > [class*="navitem--"] {
                > [class*="navtext--"] {
                    color: #DDD;
                    float: left !important;
                }
            }
        }
    
        [class*="sidenav-subnav--"] {
            > [class*="sidenav-subnavitem--"] {
              > [class*="navitem--"] {
                  color: #DDD;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  
                  > [class*="navicon--"] {
                      float: left !important;
                      width: 40px;
                  }
              }
            }
            > [class*="sidenav-subnavitem--"]:hover {
                > [class*="navitem--"] {
                    color: #DDD;
                }
            }
            > [class*="sidenav-subnavitem--"]:hover {
                > [class*="navitem--"] {
                    background-color: #4498A7;
                }
            }
            > [class*="sidenav-subnavitem--"][class*="selected--"] {
                > [class*="navitem--"] {
                    background-color: #4498A7;
                    color: #DDD;
                }
                > [class*="navitem--"]::before {
                    border-left: none !important;
                }
            }
        }
    }
    
    &&[class*="collapsed--"] {
    
        > [class*="sidenav-navitem--"] {
    
            > [class*="sidenav-subnav--"] {
            
              background-color: '#4498A7';
            
                > [class*="sidenav-subnavitem--"] {
                  > [class*="navitem--"] {
                      color: #DDD;
                      padding-top: 10px;
                      padding-bottom: 10px;
                      
                      > [class*="navicon--"] {
                          float: left !important;
                          width: 40px;
                      }
                  }
                }
                > [class*="sidenav-subnavitem--"]:hover {
                    > [class*="navitem--"] {
                        color: #DDD;
                    }
                }
                > [class*="sidenav-subnavitem--"]:hover {
                    > [class*="navitem--"] {
                        background-color: #4498A7;
                    }
                }
                > [class*="sidenav-subnavitem--"][class*="selected--"] {
                    > [class*="navitem--"] {
                        background-color: #4498A7;
                        color: #DDD;
                    }
                    > [class*="navitem--"]::before {
                        border-left: none !important;
                    }
                }
            }
        }
    }

    && > [class*="sidenav-navitem--"] {
        > [class*="navitem--"] {
            color: #DDD !important;
        }
    }
    && > [class*="sidenav-navitem--"]:hover {
        > [class*="navitem--"] {
            background-color: #4498A7;
        }
    }
    && > [class*="sidenav-navitem--"],
    && > [class*="sidenav-navitem--"]:hover {
        > [class*="navitem--"] {
            [class*="navicon--"] {
                &, > * {
                    color: #DDD;
                }
            }
            [class*="sidenav-nav-text--"] {
                &, > * {
                    color: #222;
                }
            }
        }
    }

    && > [class*="sidenav-navitem--"][class*="highlighted--"],
    && > [class*="sidenav-navitem--"][class*="highlighted--"]:hover {
        > [class*="navitem--"] {
        
            color: #4498A7 !important;
        
            [class*="navicon--"],
            [class*="navtext--"] {
                &, > * {
                    color: #DDD;
                }
            }
            [class*="sidenav-nav-text--"] {
                font-weight: bolder;
            }
        }
    }
`;
StyledNav.defaultProps = Nav.defaultProps;

// NavItem
const StyledNavItem = styled(NavItem)``;
StyledNavItem.defaultProps = NavItem.defaultProps;

// NavIcon
const StyledNavIcon = styled(NavIcon)``;
StyledNavIcon.defaultProps = NavIcon.defaultProps;

// NavText
const StyledNavText = styled(NavText)``;
StyledNavText.defaultProps = NavText.defaultProps;

export {
    StyledToggle as Toggle,
    StyledNav as Nav,
    StyledNavItem as NavItem,
    StyledNavIcon as NavIcon,
    StyledNavText as NavText
};
export default StyledSideNav;
