import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from "../config/firebaseConfig";

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();

class AuthManager {

  static user = null;

  static recaptcha(buttonId){
    return new firebase.auth.RecaptchaVerifier(buttonId, {
      'size': 'invisible',
      'callback': function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.]
        return true;
      }
    });
  }

  static signInWithPhoneNumber(phoneNumber,buttonId,cb) {
    firebaseAppAuth.signInWithPhoneNumber(phoneNumber,AuthManager.recaptcha(buttonId)).then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      AuthManager.confirmationResult = confirmationResult;
      return cb();
    }).catch(err => {
      cb(err);
    });
  }

  static sendPhoneCode(code, cb) {
    if(AuthManager.confirmationResult) {
      AuthManager.confirmationResult.confirm(code).then(function () {
        // User signed in successfully.
        return cb();
      }).catch(function (error) {
        return cb(error);
      });
    } else {
      return cb('No phone was informed (Login wasn\'t attempted)');
    }
  }

  static async getUser() {

    async function delay(ms) {
      return await new Promise(resolve => setTimeout(resolve, ms));
    }

    if(AuthManager.user) {
      return AuthManager.user;
    } else if(firebaseAppAuth.currentUser) {
      AuthManager.user = firebaseAppAuth.currentUser;
      return AuthManager.user;
    }

    // If App is initializing, must wait user to load (Wait up to 5 seconds)
    const MAX_TRIES = 50;
    const MS_TO_RETRY = 100;

    for (let i = 0; i < MAX_TRIES; i++) {
      await delay(MS_TO_RETRY);
      if(AuthManager.user) {
        break;
      }
    }

    if(AuthManager.user) {
      return AuthManager.user;
    } else {
      throw Error('Not logged in!');
    }
  }

  static async getUserToken() {
    let user = null;

    try {
      user = await AuthManager.getUser();
      return await user.getIdToken();
    } catch(err) {
      throw Error(err);
    }
  }

  static async signOut(){
    try {
      await firebaseAppAuth.signOut();
      localStorage.removeItem('ID_APP');
      localStorage.removeItem('CLIENT_ID');
      AuthManager.user = null;
    } catch (err) {
      console.error(err);
    }
  }
}

firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    AuthManager.user = user;
  } else {
    AuthManager.user = null;
  }
});

export default AuthManager;