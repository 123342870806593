import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { runInThisContext } from "vm";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  rightIconNew: {
    marginLeft: theme.spacing(1),
    fontSize:'40px'
  },
  iconSmall: {
    fontSize: 20,
  },
}));

export default function DeleteButton(props) {
  const classes = useStyles();
  return (
    <Button
      onClick={props.onClickDelete}
      variant= {props.variant ? props.variant: "contained"}
      color=  "secondary"
      className={classes.button}
      style={props.style}
    >
      {props.text ? props.text : "Remover"}
      {props.text ? (
        props.text === "Bloquear" ? (
          <BlockIcon className={classes.rightIcon} />
        ) : props.text === "Desbloquear" ? (
          <CheckCircleOutlineIcon className={classes.rightIcon} />
        ) : (
          <DeleteIcon className={classes.rightIcon} />
        )
      ) : (
        <DeleteIcon className={classes.rightIcon} />
      )}
    </Button>
  );
}
