import React from "react";

import {
  Button,
  Grid,
  Paper,
  TextField,
  MenuItem,
  Card,
  Dialog,
} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faVideo,
  faCamera,
  faFileAlt,
  faFilePdf,
  faImages,
  faTicketAlt,
} from "@fortawesome/free-solid-svg-icons";

export default function TaskDescription(props) {
  this.addObject = props.addObject;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        padding: "3vw",
      }}
    >
      {localStorage.getItem("ID_APP") === "31" || localStorage.getItem("ID_APP") === "34" ? null : (
        <Card
          style={{
            display: "flex",
            width: "10vw",
            height: "10vw",
            justifyContent: "center",
            backgroundColor: "#eeee",
            flexDirection: "column",
            alignItems: "center",
            marginRight: "1vw",
          }}
        >
          <Button
            style={{
              width: "10vw",
              height: "10vw",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2vh",
            }}
            onClick={() => this.addObject(1)}
          >
            <FontAwesomeIcon
              icon={faVideo}
              style={{ objectFit: "cover", width: "5vw", height: "5vw" }}
              id={"form-modal"}
              color={"#424242"}
              title={"Vídeo"}
            />
          </Button>
          <h5 id="transition-modal-title">Vídeo</h5>
        </Card>
      )}

      {localStorage.getItem("ID_APP") === "31" || localStorage.getItem("ID_APP") === "34" ? null : (
        <Card
          style={{
            display: "flex",
            width: "10vw",
            height: "10vw",
            justifyContent: "center",
            backgroundColor: "#eeee",
            flexDirection: "column",
            alignItems: "center",
            marginRight: "1vw",
          }}
        >
          <Button
            style={{
              width: "10vw",
              height: "10vw",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2vh",
            }}
            onClick={() => this.addObject(2)}
          >
            <FontAwesomeIcon
              icon={faImage}
              style={{ objectFit: "cover", width: "5vw", height: "5vw" }}
              id={"form-modal"}
              color={"#424242"}
              title={"Imagem"}
            />
          </Button>
          <h5 id="transition-modal-title">Imagem</h5>
        </Card>
      )}

      {localStorage.getItem("ID_APP") === "31" || localStorage.getItem("ID_APP") === "34" ? null : (
        <Card
          style={{
            display: "flex",
            width: "10vw",
            height: "10vw",
            justifyContent: "center",
            backgroundColor: "#eeee",
            flexDirection: "column",
            alignItems: "center",
            marginRight: "1vw",
          }}
        >
          <Button
            style={{
              width: "10vw",
              height: "10vw",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2vh",
            }}
            onClick={() => this.addObject(3)}
          >
            <FontAwesomeIcon
              icon={faFileAlt}
              style={{ objectFit: "cover", width: "5vw", height: "5vw" }}
              id={"form-modal"}
              color={"#424242"}
              title={"Form"}
            />
          </Button>
          <h5 id="transition-modal-title">Formulário</h5>
        </Card>
      )}

      {localStorage.getItem("ID_APP") === "24" ||
      localStorage.getItem("ID_APP") === "4" ||
      localStorage.getItem("ID_APP") === "29" ||
      localStorage.getItem("ID_APP") === "31"  || localStorage.getItem("ID_APP") === "34" ?
      //olhar aqui
      (
        <Card
          style={{
            display: "flex",
            width: "10vw",
            height: "10vw",
            justifyContent: "center",
            backgroundColor: "#eeee",
            flexDirection: "column",
            alignItems: "center",
            marginRight: "1vw",
          }}
        >
          <Button
            style={{
              width: "10vw",
              height: "10vw",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2vh",
            }}
            onClick={() => this.addObject(4)}
          >
            <FontAwesomeIcon
              icon={faCamera}
              style={{ objectFit: "cover", width: "5vw", height: "5vw" }}
              id={"desFoto-modal"}
              color={"#424242"}
              title={"Desafio de foto"}
            />
          </Button>
          <h5 id="transition-modal-title">Desafio de foto</h5>
        </Card>
      ) : null}

      {localStorage.getItem("ID_APP") === "31" || localStorage.getItem("ID_APP") === "34" ? null : (
        <Card
          style={{
            display: "flex",
            width: "10vw",
            height: "10vw",
            justifyContent: "center",
            backgroundColor: "#eeee",
            flexDirection: "column",
            alignItems: "center",
            marginRight: "1vw",
          }}
        >
          <Button
            style={{
              width: "10vw",
              height: "10vw",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2vh",
            }}
            onClick={() => this.addObject(5)}
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{ objectFit: "cover", width: "5vw", height: "5vw" }}
              id={"form-modal"}
              color={"#424242"}
              title={"PDF"}
            />
          </Button>
          <h5 id="transition-modal-title">PDF</h5>
        </Card>
      )}

      {localStorage.getItem("ID_APP") === "31"  || localStorage.getItem("ID_APP") === "34" ? null : (
        <Card
          style={{
            display: "flex",
            width: "10vw",
            height: "10vw",
            justifyContent: "center",
            backgroundColor: "#eeee",
            flexDirection: "column",
            alignItems: "center",
            marginRight: "1vw",
          }}
        >
          <Button
            style={{
              width: "10vw",
              height: "10vw",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2vh",
            }}
            onClick={() => this.addObject(6)}
          >
            <FontAwesomeIcon
              icon={faImages}
              style={{ objectFit: "cover", width: "5vw", height: "5vw" }}
              id={"form-modal"}
              color={"#424242"}
              title={"Trilha"}
            />
          </Button>
          <h5 id="transition-modal-title">Trilha</h5>
        </Card>
      )}

      {localStorage.getItem("ID_APP") === "31"  || localStorage.getItem("ID_APP") === "34" ? null : (
        <Card
          style={{
            display: "flex",
            width: "10vw",
            height: "10vw",
            justifyContent: "center",
            backgroundColor: "#eeee",
            flexDirection: "column",
            alignItems: "center",
            marginRight: "1vw",
          }}
        >
          <Button
            style={{
              width: "10vw",
              height: "10vw",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2vh",
            }}
            onClick={() => this.addObject(8)}
          >
            <FontAwesomeIcon
              icon={faTicketAlt}
              style={{ objectFit: "cover", width: "5vw", height: "5vw" }}
              id={"form-modal"}
              color={"#424242"}
              title={"Quiz"}
            />
          </Button>
          <h5 id="transition-modal-title">Quiz</h5>
        </Card>
      )}
    </div>
  );
}
