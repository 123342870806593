import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

export default function SaveButton(props) {

  const classes = useStyles();
  return (
    <Button 
      onClick={props.onClickArchive}
      variant="contained"
      className={classes.button} 
      style={props.style}
    >
      {
        props.is_archived ?
          "Desarquivar" :
          "Arquivar"
      }
      {
        props.is_archived ?
        <UnarchiveIcon className={classes.rightIcon} /> :
        <ArchiveIcon className={classes.rightIcon} />
      }
    </Button>
  )
}