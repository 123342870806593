import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Content from '../../models/Content';

import ContentEngagementCard from '../components/cards/ContentEngagementCardOld';
import ContentEngagementDescription from '../components/description_cards/ContentEngagementDescription';

class AdminContentEngagementScreen extends Component {
  editionTimeout = null;

  state = {
    contents: [],
    relations: [],
  };

  // TEXTS
  searchText_placeholder = 'Nome do conteúdo';

  // FUNCTIONS
  // Load all data from DB
  componentDidMount() {
    Content.statistics()
      .then((contents) => {
        this.setState({ contents: contents });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
    this.reloadStatistics();
  }

  async reloadStatistics(parameters) {
    try {
      let contents = await Content.statistics(parameters);
      contents = contents.sort((obj1, obj2) => {
        let obj1_order_content = obj1.order_content;
        let obj2_order_content = obj2.order_content;

        if (!obj1_order_content) {
          obj1_order_content = 99999999999999;
        }

        if (!obj2_order_content) {
          obj2_order_content = 99999999999999;
        }

        if (obj1.is_fixed) {
          if (obj2.is_fixed) {
            if (obj1_order_content < obj2_order_content) {
              return -1;
            } else if (obj1_order_content > obj2_order_content) {
              return 1;
            }
          } else {
            return -1;
          }
        } else {
          if (obj2.is_fixed) {
            return 1;
          } else {
            if (obj1_order_content < obj2_order_content) {
              return -1;
            } else if (obj1_order_content > obj2_order_content) {
              return 1;
            }
          }
        }

        if (obj1.id_content < obj2.id_content) {
          return -1;
        } else {
          return 1;
        }
      });

      this.setState({ contents: contents });
    } catch (err) {
     toast.error('Erro ao carregar estatísticas de conteúdo');
    }
  }

  refreshStatistics = async (parameters) => {
    this.reloadStatistics(parameters);
  };

  render() {
    return (
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        objectList={this.state.contents}
        objectCard={ContentEngagementCard}
        objectDescription={ContentEngagementDescription}
        refreshObjects={this.refreshStatistics}
        objectModel={Content}
        relations={this.state.relations}
        contents={this.state.contents}
        objectIdName={'id_content'}
        onFilterReload={true}
      ></GenericAdminScreen>
    );
  }
}

export default AdminContentEngagementScreen;
