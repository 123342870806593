import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

export const ButtonDarkBlue = withStyles(() => ({
  root: {
    backgroundColor: '#162233',
    color: '#FFF',
    fontWeight: 500,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#162233',
    },
    margin:'2px'}
}))(Button);

export const ButtonLightBlue = withStyles(() => ({
  root: {
    backgroundColor: '#0EA8BC',
    color: '#FFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0C9CAE',
    }
  }
}))(Button);

export const ButtonBlueBorder = withStyles(() => ({
  root: {
    backgroundColor: '#FFF',
    color: '#162233',
    fontWeight: 700,
    border: '1px solid #162233',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#E0E5EC',
    }
  }
}))(Button);

export const ButtonWhite = withStyles(() => ({
  root: {
    backgroundColor: '#FFF',
    color: '#4F4F4F',
    fontWeight: 700,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#E0E5EC',
    }
  }
}))(Button);

export const CustomIconButton = styled(IconButton)({
  '&:hover': {
    backgroundColor: '#E0E5EC',
    color: "#162233",
  }
});