import React from 'react';
import AsyncSelect from 'react-select/async';
import GenericModel from '../../../models/generic_model/GenericModel.js'

export default function SearchableSelect(props) {

    let selectedOption = props.defaultValue;

    let disabled = props.disabled;

    let isMulti = props.isMulti ? props.isMulti : false

    const getOptionValue = (option) => option ? option.value : null;
    const getOptionLabel = (option) => option ? option.label : "";

    const handleChange = (newSelectedOption) => {
        selectedOption = newSelectedOption;
        if (selectedOption) {
            if (!isMulti) {
                props.actionOnSelectedOption(selectedOption.value);
            }
            else {
                if (selectedOption != null) {
                    selectedOption = selectedOption.map(object => {
                        return object.value;
                    });
                }
                props.actionOnSelectedOption(selectedOption);
            }
        } else {
            props.actionOnSelectedOption(selectedOption);
        }

        // this is for update action on selectedOption
    };

    const getOptions = async (inputValue) => {
        return new Promise((resolve) => {
            if (!inputValue) {
                return resolve([]);
            }

            GenericModel.insertAuthHeader().then(authHeader => {
                fetch(`${props.searchApiUrl}?search=${inputValue}&limit=${props.limit}${props.search ? `&${props.search}` : ""}`, {
                    headers: authHeader
                }).then(response => {
                    response.json().then(json => {
                        return resolve(json);
                    });
                });
            });
        })
    };

    const noOptionsMessage = (inputValue) => {
        if (props.options && props.options.length) return null;
        if (!inputValue) {
            return props.searchLabel;
        }
        return "Sem Resultados! Cadastre a entidade.";
    };


    const { defaultOptions, placeholder } = props;
    return (
        <AsyncSelect
            cacheOptions
            value={selectedOption}
            noOptionsMessage={noOptionsMessage}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            defaultOptions={defaultOptions}
            loadOptions={getOptions}
            placeholder={placeholder}
            onChange={handleChange}
            isDisabled={disabled}
            isMulti={isMulti}
            isClearable
        />
    );
}