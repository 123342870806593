import GenericModel from './generic_model/GenericModel'
const API = '/app';

class App extends GenericModel {
    API = API;
    static API = API;

    static listApps = async function () {

        let authHeader = null;
        try {
            authHeader = await GenericModel.insertAuthHeader();

            const response = await fetch(API + '/list_by_phone', {
                headers: authHeader
            });

            const body = await response.json();

            if (response.status !== 200) throw new Error(body.message);

            return body;
        } catch (err) {
            throw Error(err);
        }
    };
}

export default App;