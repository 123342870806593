import { Box } from '@material-ui/core';
import CampaignIcon from '@mui/icons-material/Campaign';
import SearchOffIcon from '@mui/icons-material/SearchOff';

export function EmptyListFeedBackOnSearch(){
  return(
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      marginTop='10%'
    >
    <SearchOffIcon 
      sx={{
        fontSize:120,
        color: "#C9CACC"
      }}
    />
      <span
        style={{
          color: '#828282',
          fontSize: 15,
          marginTop: 16,
          display: 'inline-block'
        }}
      >
        Não encontramos resultado para sua busca, utilize outras palavras ou mude os filtros selecionados.
      </span>
    </Box> 
  );
}

export function EmptyListFeedBack(){
  return(
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      marginTop='10%'
    >
    <CampaignIcon 
      sx={{
        fontSize:120,
        color: "#C9CACC"
      }}
    />
      <span
        style={{
          color: '#828282',
          fontSize: 15,
          marginTop: 16,
          display: 'inline-block'
        }}
      >
        Você ainda não cadastrou conteúdos de Visual Merchandising.
      </span>
      <span
        style={{
        color: '#828282',
        fontSize: 15,
        marginTop: 16,
        display: 'inline-block'
        }}
      >
        Cadastre um novo conteúdo e acompanhe as respostas dos lojistas.
      </span>
    </Box> 
  );
}