import React from "react";
import { Grid, Paper, TextField, Button, Checkbox } from "@material-ui/core";
import { red, green, blue } from "@material-ui/core/colors";
import DeleteButton from "../buttons/DeleteButton";
import { DateTimePicker } from "@material-ui/pickers/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CustomDropzone } from "../CustomDropzone";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import Role from "../../../models/Role";
import SaveButton from "../buttons/SaveButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import PerfectScrollbar from "react-perfect-scrollbar";
import ClassWeAuthor from "../../../models/ClassWeAuthor";

export default function AuthorDescription(props) {
    this.editionTimeout = null;

    // Functions
    this.updateAuthor = props.updateObject;
    this.deleteAuthor = props.deleteObject;
    this.saveAuthor = props.saveObject;
    this.nullDescription = props.nullDescription;

    // Object to Render
    this.object = props.object;
    this.roles = props.relations[0];

    this.object.roleOptions = this.roles.map(role => {
        let roleOption = {
            value: role.id_role,
            label: role.role_name
        };
        return roleOption;
    });

    let objectRole = this.roles.filter(value => {
        return this.object.id_role ? value.id_role == this.object.id_role : false;
    })[0];

    if (objectRole) {
        this.object.defaultRoleValue = {
            value: objectRole.id_role,
            label: objectRole.role_name
        };
    } else {
        this.object.defaultRoleValue = [];
    }

    this.onChangeUpdate = (field, force) => event => {
        let value = event && event.target ? event.target.value : null;
        this.updateAuthor(this.object, field, value, force);
    };

    this.onClicksaveAuthor = () => {
        this.saveAuthor(this.object);
    };

    this.onClickdeleteAuthor = () => {
        if (window.confirm("Deseja mesmo deletar esse item?")) {
            this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the user
            this.deleteAuthor(this.object);
        }
    };

    this.onDropActionCoverImage = async (file) => {
        file = file[0];

        const progressFunction = (completed) => {
            const completedState = {
                completedCoverImage: completed,
            };
            props.progressFunction(completedState);
        };

        try {
            let imageUrl = null;
            if (this.object) {
                imageUrl = await ClassWeAuthor.sendAuthorProfileImage(
                    file,
                    null,
                    progressFunction
                );
                this.updateAuthor(
                    this.object,
                    "author_photo",
                    imageUrl.url,
                    true
                );
            }
            // Reset completed variable (progress bar)
            progressFunction(0);
        } catch (err) {
            console.error(err);
        }
    };

    this.deleteCoverImage = () => {
        if (this.object && this.object.author_photo) {
            return this.updateAuthor(
                this.object,
                "author_photo",
                null,
                true
            );
        }
    };

    return (
        <div style={{ height: "auto" }}>
            <Grid container spacing={2}>
                <Grid xs={1}></Grid>
                <Grid
                    xs={10}
                    container
                    direction="row"
                    spacing={2}
                    style={{ marginTop: "10px" }}
                >
                    <Grid xs={6} item>
                        <TextField
                            label="Nome"
                            value={this.object.author_name ? this.object.author_name : ""}
                            style={{ width: "100%" }}
                            onChange={this.onChangeUpdate("author_name", false)}
                            onBlur={this.onChangeUpdate("author_name", true)}
                        />
                    </Grid>
                    <Grid xs={3} item>
                        <TextField
                            label="ID"
                            value={this.object.id_author ? this.object.id_author : ""}
                            style={{ width: "100%" }}
                            disabled
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                label="Data de Criação"
                                value={this.object.created_date}
                                style={{ width: "100%" }}
                                format="dd/MM/yyyy HH:mm"
                                disabled
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid xs={6} item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                label="Data de Alteração"
                                value={this.object.updated_date}
                                style={{ width: "100%" }}
                                format="dd/MM/yyyy HH:mm"
                                disabled
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>


                    <Grid container spacing={3} style={{ marginTop: 10 }}>
                        <Grid xs={6} item>
                            <Grid container direction={"column"}>
                                <Grid item xs={12}>
                                    <span
                                        style={{
                                            fontSize: "12px",
                                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                            fontWeight: 400,
                                            lineHeight: 1,
                                            letterSpacing: "0.00938em",
                                            float: "left",
                                            color: "rgba(0, 0, 0, 0.54)",
                                            paddingBottom: "10px",
                                        }}
                                    >
                                        Imagem de Capa (1700px X 864px)
                                        {this.object && this.object.author_photo ? (
                                            <a href="#" onClick={this.deleteCoverImage}>
                                                {" "}
                                                Remover Imagem
                                            </a>
                                        ) : null}
                                    </span>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.object &&
                                        this.object.author_photo ? (
                                        <img
                                            style={{
                                                width: "30vw",
                                                height: "15vw",
                                                objectFit: "cover",
                                            }}
                                            src={this.object.author_photo ? this.object.author_photo : null}
                                            id={"image-modal"}
                                        />
                                    ) : (
                                        <div id="picker">
                                            <CustomDropzone
                                                onDropAction={this.onDropActionCoverImage}
                                                completed={props.completed.completedCoverImage}
                                                inactiveLabel={"Arraste a imagem(.jpeg, .png, .gif) de capa para cá"}
                                                activeLabel={"Solte a imagem de capa"}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={6}>
                            <Grid xs={12} item>
                                <span
                                    style={{
                                        fontSize: "12px",
                                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                        fontWeight: 400,
                                        lineHeight: 1,
                                        letterSpacing: "0.00938em",
                                        float: "left",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        paddingBottom: "10px",
                                        paddingLeft: "20px",
                                    }}
                                >
                                    Descrição - {this.object && this.object.author_description ? this.object.author_description.length : 0}/5000
                                </span>
                            </Grid>
                            <TextareaAutosize
                                aria-label={"Descrição"}
                                placeholder={"Descrição(Máximo de 5000 caracteres)"}
                                maxLength={5000}
                                rows={4}
                                value={
                                    this.object &&
                                        this.object.author_description
                                        ? this.object.author_description
                                        : ""
                                }
                                onChange={this.onChangeUpdate(
                                    "author_description",
                                    false
                                )}
                                onBlur={this.onChangeUpdate(
                                    "author_description",
                                    true
                                )}
                                style={{
                                    width: "90%",
                                    height: "70%",
                                    padding: "10px 10px",
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid xs={12} style={{ marginTop: "20px" }}>
                        <SaveButton
                            style={{ float: "right" }}
                            onClickSave={this.onClicksaveAuthor}
                            ref={ref => {
                                this.saveButton = ref;
                            }}
                        />
                        <DeleteButton
                            style={{ float: "right", background: "#CC0B0B" }}
                            onClickDelete={this.onClickdeleteAuthor}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
