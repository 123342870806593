import DateFnsUtils from "@date-io/date-fns";
import { Button, Grid, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers/";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import Select from "react-select";
import ArchiveButton from "../buttons/ArchiveButton";
import DeleteButton from "../buttons/DeleteButton";
import SaveButton from "../buttons/SaveButton";
// import FormQuestionCardModular from "../cards/FormQuestionCardModular";
import FormQuestionCard from "../cards/FormQuestionCard";

function ContentDescriptionFormResearch(props) {
  let typeQuestion = props.typeQuestion ? props.typeQuestion : null;
  //   let that= this;

  //   //  constructor(props) => {
  //   //   super(props);
  //   //   this.updateInput = this.updateInput.bind(this);
  //   // }
  // const updateInputValue = (e) =>{
  //    this.addQuestion(e, false)
  //    typeQuestion =null;

  //   }
  //   if(typeQuestion){
  //     console.log("to no if")
  //     updateInputValue(typeQuestion);
  //     typeQuestion =null;
  //   }
  // console.log("new Question number", props.);
  this.editionTimeout = null;
  // Object to Render
  this.object = props.object;

  this.roles = props.relations[0];
  // Functions
  this.updateContent = props.updateObject;

  if (!this.object.content_type) {
    this.updateContent(this.object, "content_type", 3);
  }

  let form = this.object.form ? this.object.form : { form_type: 6 };

  this.deleteContent = props.deleteObject;
  this.saveContent = props.saveObject;
  this.publishObject = props.publishObject;
  this.nullDescription = props.nullDescription;
  // console.log(this.nullDescription,'this.nullDescription')
  // console.log(this.deleteContent,'this.deleteContent')

  this.showList = props.showList;

  let content_type = null;
  switch (this.object.content_type) {
    case 1:
      content_type = "video";
      break;
    case 2:
      content_type = "image";
      break;
    case 3:
      content_type = "form";
      break;
    case 4:
      content_type = "challenge";
      break;
    case 5:
      content_type = "pdf";
      break;
    case 6:
      content_type = "trail";
      break;
  }

  if (
    (content_type === "video" ||
      content_type === "pdf" ||
      content_type === "challenge" ||
      content_type === "image" ||
      content_type === "trail") &&
    !this.object.training
  ) {
    this.object.training = {
      id_app: localStorage.getItem("ID_APP"),
      id_training: this.object.id_training ? this.object.id_training : null,
    };
  } else if (content_type === "form" && !this.object.form) {
    this.object.form = { id_app: localStorage.getItem("ID_APP") };
  }

  switch (content_type) {
    case "video":
      content_type = "video";
      this.object.content_type = 1;
      this.object.screenOptions = this.object.screenOptionsDefault;
      break;
    case "image":
      content_type = "image";
      this.object.content_type = 2;
      this.object.screenOptions = this.object.screenOptionsDefault;
      break;
    case "form":
      content_type = "form";
      this.object.content_type = 3;
      this.object.screenOptions = this.object.screenOptionsDefault;
      break;
    case "challenge":
      content_type = "challenge";
      this.object.content_type = 4;
      delete this.object.form;
      this.object.screenOptions = this.object.screenOptionsChallenge;
      break;
    case "pdf":
      content_type = "pdf";
      this.object.content_type = 5;
      this.object.screenOptions = this.object.screenOptionsDefault;
      break;
    case "trail":
      content_type = "trail";
      this.object.content_type = 6;
      this.object.screenOptions = this.object.screenOptionsTrail;
      break;
  }

  this.object.roleOptions = this.roles.map((role) => {
    let roleOption = {
      value: role.id_role,
      label: role.role_name,
    };
    return roleOption;
  });

  if (this.object.roles !== undefined) {
    this.object.defaultRoleValues = this.object.roles.map((role) => {
      return {
        value: role.id_role ? role.id_role : null,
        label: role.role_name ? role.role_name : null,
      };
    });
  } else {
    this.object.defaultRoleValues = [];
  }

  if (this.object.predecessor_content) {
    this.object.defaultPredecessorContentValue = {
      value: this.object.predecessor_content,
      label: this.object.predecessor_content.form
        ? this.object.predecessor_content.form.form_title
        : this.object.predecessor_content.training
        ? this.object.predecessor_content.training.training_title
        : "ID do Conteúdo:" + this.object.predecessor_content.id_content,
    };
  }

  let updateDescriptionState = props.updateDescriptionState
    ? props.updateDescriptionState
    : () => {};
  if (!props.descriptionState.content_type) {
    updateDescriptionState({ content_type: content_type });
  }

  this.onClickArchiveContent = () => {
    this.onChangeUpdate("is_archived")({
      target: {
        value: this.object.is_archived ? 0 : 1,
      },
    });
    this.saveContent(this.object);
  };

  this.onClickSaveContent = () => {
    if (content_type === "form") {
      delete this.object.training;
      this.object.id_training = null;
    } else {
      this.object.training.is_challenge_photo =
        content_type === "challenge" ? 1 : 0;
      this.object.id_form = null;
    }
    if (this.object.form) {
      this.object.form.form_type = 6;
    } else {
      this.object.form = { form_type: 6 };
    }
    this.saveContent(this.object);
  };

  this.onClickDeleteContent = () => {
    if (window.confirm("Tem certeza que deseja remover esse item?")) {
      let result = this.nullDescription(null); //make the genericadminscreen change the descriptionCard specs to null before delete the content
      result = this.deleteContent(this.object);
      //add close aqui
    }
  };

  this.onChangeUpdate =
    (field, force = false) =>
    (event) => {
      let value = event && event.target ? event.target.value : null;

      if (
        field === "starting_content" ||
        field === "is_fixed" ||
        field === "is_marketing" ||
        field === "is_challenge" ||
        field === "is_removed_when_completed"
      ) {
        value = event.target.checked ? 1 : 0;
      } else if (field === "content_title") {
        switch (content_type) {
          case "challenge":
          case "video":
          case "pdf":
            field = "training.training_title";
            break;
          case "form":
            field = "form.form_title";
            break;
          default:
            field = "training.training_title";
        }
      }

      this.updateContent(this.object, field, value, force);
    };

  this.changeRadioFormType = (event) => {
    let value = event.target.value;

    value = Number(value);

    let form = this.object.form ? this.object.form : {};

    form.form_type = value;

    updateDescriptionState({ form: form });

    if (value === 2) {
      this.updateContent(this.object, "form.forms_questions", [
        { form_question_type: 2 },
      ]);
    } else if (value === 3) {
      this.updateContent(this.object, "form.forms_questions", [
        { form_question_type: 4 },
      ]);
    }

    this.updateContent(this.object, "form", form, true);
  };

  this.changeRadioTypeContent = (event) => {
    if (event.target.value === "form" && !this.object.form) {
      this.updateContent(
        this.object,
        "form",
        {
          form_type: 1,
          id_form: this.object.id_form ? this.object.id_form : null,
        },
        true
      );
    }

    switch (event.target.value) {
      case "video":
        content_type = "video";
        this.object.content_type = 1;
        this.object.screenOptions = this.object.screenOptionsDefault;
        break;
      case "image":
        content_type = "image";
        this.object.content_type = 2;
        this.object.screenOptions = this.object.screenOptionsDefault;
        break;
      case "form":
        content_type = "form";
        this.object.content_type = 3;
        this.object.screenOptions = this.object.screenOptionsDefault;
        break;
      case "challenge":
        content_type = "challenge";
        this.object.content_type = 4;
        delete this.object.form;
        this.object.screenOptions = this.object.screenOptionsChallenge;
        break;
      case "pdf":
        content_type = "pdf";
        this.object.content_type = 5;
        this.object.screenOptions = this.object.screenOptionsDefault;
        break;
      case "trail":
        content_type = "trail";
        this.object.content_type = 6;
        this.object.screenOptions = this.object.screenOptionsTrail;
        break;
    }

    updateDescriptionState({
      content_type: event.target.value,
      user_defined_content_type: event.target.value,
    });
  };

  this.onQuestionChange = (field, idxQuestion) => (event) => {
    let value = event && event.target ? event.target.value : null;
    let formsQuestionsList = cloneDeep(this.object.form.forms_questions);
    let formQuestionObj = formsQuestionsList[idxQuestion];

    if (formQuestionObj) {
      if (!isNaN(value)) {
        value = Number(value);
      }
      formQuestionObj[field] = value;
      this.updateContent(
        this.object,
        "form.forms_questions",
        formsQuestionsList
      );
    }
  };

  this.addQuestion = (defaultValue = 1, show) => {
    let formValue =
      defaultValue === null || defaultValue === undefined ? 1 : defaultValue;
    let formsQuestionsList = this.object.form.forms_questions
      ? cloneDeep(this.object.form.forms_questions)
      : [];

    formsQuestionsList.push({ form_question_type: formValue });
    show = show === undefined || show === null ? true : show;
    // formsQuestionsList.push({ form_question_type: formValue });
    //add de baixo vai crashar
    this.updateContent(this.object, "form.forms_questions", formsQuestionsList);
  };
  // if (this.object.form && !this.object.form.form_question && typeQuestion) {
  //   console.log("agira");
  //   console.log(typeQuestion);
  //   typeQuestion = null;
  //   let that = this;
  //   that.addQuestion(typeQuestion, false);
  // }

  this.showForm = () => {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            textAlign: "left",
          }}
        >
          {/* {typeQuestion ===9} */}
          Atividade :
        </span>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            float: "left",
          }}
        >
          <Grid xs={12}>
            <Grid xs={3} item>
              <Button
                onClick={() => this.addQuestion(8, false)}
                variant="outlined"
                // color="success"
              >
                Adicionar Atividade <AddIcon />
              </Button>
            </Grid>
            <Grid xs={3} item>
              <Button
                onClick={() => this.addQuestion(9, false)}
                variant="outlined"
                // color="success"
              >
                Adicionar Formulario <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </div> */}

        {/* //deves ter o form question antes de add a quetão  */}
        {this.object.form && this.object.form.forms_questions
          ? this.object.form.forms_questions.map((question, idx) => {
              let deleteQuestion = () => {
                let formsQuestionsList = cloneDeep(
                  this.object.form.forms_questions
                );
                formsQuestionsList.splice(idx, 1);
                this.updateContent(
                  this.object,
                  "form.forms_questions",
                  formsQuestionsList
                );
              };

              return (
                //uma olhada aqui
                <>
                  {/* <p>aaaaaaaaaaaaaaaaaaaaaa</p> */}
                  <FormQuestionCard
                    formQuestion={question}
                    onQuestionChange={this.onQuestionChange}
                    deleteQuestion={deleteQuestion}
                    idx={idx}
                    forms={props.forms}
                    typeQuestion={props.typeQuestion ? props.typeQuestion : -1}
                    // mudar aqui
                    objectList={props.objectList}
                    formType={6}
                  />
                  {/* <FormQuestionCardModular
                    formQuestion={question}
                    onQuestionChange={this.onQuestionChange}
                    deleteQuestion={deleteQuestion}
                    idx={idx}
                    forms={props.forms}
                    typeQuestion={props.typeQuestion ? props.typeQuestion : -1}
                    // mudar aqui
                    objectList={props.objectList}
                    formType={6}
                  /> */}
                </>
              );
            })
          : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            float: "left",
          }}
        >
          {this.object.form &&
            this.object.form.form_type !== 2 &&
            this.object.form.form_type !== 3 && (
              <div
                style={{
                  marginRight: "10px",
                }}
              >
                <Button onClick={() => this.addQuestion(1)} variant="contained">
                  Adicionar Pergunta
                  <AddIcon />
                </Button>
              </div>
            )}
          {/* olhar aqui */}

          <div
            style={{
              marginRight: "10px",
            }}
          >
            <Button
              onClick={() => this.addQuestion(8, false)}
              // variant="outlined"
              variant="contained"
              // color="success"
            >
              Adicionar Atividade <AddIcon />
            </Button>
          </div>
          <div
            style={{
              marginRight: "10px",
            }}
          >
            <Button
              onClick={() => this.addQuestion(9, false)}
              variant="contained" // color="success"
            >
              Adicionar Formulario <AddIcon />
            </Button>
          </div>
        </div>
      </Grid>
    );
  };

  this.onClickSaveContent = () => {
    if (content_type === "form") {
      delete this.object.training;
      this.object.id_training = null;
    } else {
      this.object.training.is_challenge_photo =
        content_type === "challenge" ? 1 : 0;
      this.object.id_form = null;
    }
    this.object.is_challenge = 1;
    this.saveContent(this.object);
  };

  this.onClickPublishContent = () => {
    this.object.is_published = 1;
    this.object.is_challenge = 1;
    if (this.object.form) {
      this.object.form.form_type = 6;
    } else {
      this.object.form = { form_type: 6 };
    }

    this.publishObject(this.object);
  };

  this.onChangeUpdateRoles = (value) => {
    let roles;
    if (value != null) {
      //null cant use map function
      roles = value.map((obj) => {
        return {
          //revert the label to put the id_role and role_name in the Content Object
          id_role: obj.value,
          role_name: obj.label,
        };
      });
    } else {
      roles = [];
    }
    this.updateContent(this.object, "roles", roles, true);
  };

  this.contentType = props.descriptionState.content_type;

  this.predecessorContentChosen = (value) => {
    this.updateContent(
      this.object,
      "id_predecessor_content",
      value && value.id_content ? value.id_content : null,
      true
    );
    this.object.defaultPredecessorContentValue = value
      ? {
          value: value,
          label: value.training_title
            ? value.training_title
            : value.form_title
            ? value.form_title
            : "ID:" + value.id_content,
        }
      : null;
  };

  return (
    <div style={{ height: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          alignItems: "center",
          padding: "30px 20px 10px 20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid xs={12} container direction="row" spacing={1}>
            <Grid xs={6} item>
              <TextField
                label="Título Conteúdo"
                value={
                  this.object.training && this.object.training.training_title
                    ? this.object.training.training_title
                    : this.object.form && this.object.form.form_title
                    ? this.object.form.form_title
                    : ""
                }
                style={{ width: "100%" }}
                onChange={this.onChangeUpdate("content_title", false)}
                onBlur={this.onChangeUpdate("content_title", true)}
                multiline
              />
            </Grid>

            {/* {this.object.content_type === 6 ? <Grid xs={2} item></Grid> : null} */}
            <Grid xs={2} item></Grid>

            <Grid justify="flex-start" xs={1} item>
              <TextField label="ID" value={this.object.id_content} disabled />
            </Grid>
            <Grid justify="flex-start" xs={3} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label="Data da ultima alteração"
                  value={this.object.updated_date}
                  style={{ width: "100%" }}
                  format="dd/MM/yyyy HH:mm"
                  disabled
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid xs={3} container direction={"column"}>
              <Grid item>
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: "0.00938em",
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  Papéis:
                </span>
              </Grid>
              <Grid item>
                <Select
                  placeholder={"Selecione os Papéis"}
                  options={this.object.roleOptions}
                  value={this.object.defaultRoleValues}
                  onChange={this.onChangeUpdateRoles}
                  isMulti
                ></Select>
              </Grid>
            </Grid>
            {/*this.object.content_type === 3 ? (
              <Grid item xs={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <span
                      style={{
                        fontSize: '12px',
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        letterSpacing: '0.00938em',
                        float: 'none',
                        color: 'rgba(0, 0, 0, 0.54)',
                        paddingTop: '20px',
                      }}
                    >
                      Responder somente uma vez?
                    </span>
                  </Grid>
                  <Grid xs={12} item>
                    <Grid style={{ float: 'center', paddingTop: '0px' }}>
                      <Checkbox
                        onChange={this.onChangeUpdate(
                          'is_removed_when_completed',
                          true,
                        )}
                        checked={this.object.is_removed_when_completed === 1}
                        title={'Responder somente uma vez?'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
                        ) : null*/}

            <Grid xs={6} item></Grid>
            <Grid xs={12}>
              <div style={{ height: "40px" }}></div>
            </Grid>

            <Grid container direction="row" spacing={2}>
              {this.contentType === "form" ? this.showForm() : null}
              {this.contentType === "image" ? this.showImage() : null}
              {this.contentType === "trail" ? this.showTrailContent() : null}
            </Grid>

            <Grid xs={12} justify="center" item>
              <SaveButton
                style={{ float: "right" }}
                onClickSave={this.onClickPublishContent}
                ref={(ref) => {
                  this.saveButton = ref;
                }}
                text={"Publicar"}
              />
              <SaveButton
                style={{ float: "right" }}
                onClickSave={this.onClickSaveContent}
                ref={(ref) => {
                  this.saveButton = ref;
                }}
                text={"Salvar Rascunho"}
              />
              <ArchiveButton
                style={{ float: "right" }}
                onClickArchive={this.onClickArchiveContent}
                is_archived={this.object.is_archived}
                ref={(ref) => {
                  this.archiveButton = ref;
                }}
              />
              <DeleteButton
                style={{ float: "right", background: "#CC0B0B" }}
                onClickDelete={this.onClickDeleteContent}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ContentDescriptionFormResearch;
