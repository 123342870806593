const config = {
    apiKey: "AIzaSyBbsQFDFx2tZL6QIZy-fxUmZLo3p9p823Y",
    authDomain: "conductive-coil-239500.firebaseapp.com",
    databaseURL: "https://conductive-coil-239500.firebaseio.com",
    projectId: "conductive-coil-239500",
    storageBucket: "insidethebox-video",
    messagingSenderId: "730465425058",
    cloudMessagingToken: "AAAAqhMh1qI:APA91bHsXjMR488DCHC-R9ywNFFZy1HhS0QmEhdKm1BOL64U_NKEC0VBCx6yzAbHWN7VP-glUDo_BH1rQDkUz9ZuoE0i_547r8j5oO0JBjUEufuy-SSdzydefrriVrv1hQHSeLv2pWVD",
    appId: "1:730465425058:web:4fa28bff2e2cd8d1"
};

export default config;