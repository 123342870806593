import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Content from '../../models/Content';
import Role from '../../models/Role';
import App from '../../models/App';
import Medal from '../../models/Medals';
import TopicsOfInterest from '../../models/TopicOfInterest';

import ContentCard from '../components/cards/ContentEngagementCard';
import ContentDescription from '../components/description_cards/ContentDescription';
import Spinner from '../components/buttons/Spinner';

class AdminContentScreen extends Component {
  editionTimeout = null;

  state = {
    contents: [],
    relations: [],
    filters: [],
    subjects: [],
    app: [],
    loading: true,
  };

  // TEXTS
  searchText_placeholder =
    localStorage.getItem('ID_APP') === '4'
      ? 'Título do Conteúdo'
      : 'Título do Curso';
  addButton_placeholder =
    localStorage.getItem('ID_APP') === '4' ? 'Conteúdo' : 'Curso';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    App.get(localStorage.getItem('ID_APP'))
      .then((app) => {
        this.setState({ app: app });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
    Content.query()
      .then((contents) => {
        contents = contents.filter((content) => {
          return content.is_fixed && !content.is_campaign;
        });
        this.setState({ contents: contents });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
    Role.query().then((roles) => {
      let relations = this.state.relations;
      relations[0] = roles;
      this.setState({ relations: relations });
    });

    TopicsOfInterest.query().then((topics) => {
      let subjects = this.state.subjects;
      subjects[0] = topics;
      this.setState({ subjects: subjects });
    });
    this.reloadContent();
  }

  async reloadContent(parameters) {
    try {
      let contents = await Content.query(parameters);
      contents = contents.filter((content) => {
        return content.is_fixed && !content.is_campaign;
      });

      contents = contents.sort((obj1, obj2) => {
        let obj1_order_content = obj1.order_content;
        let obj2_order_content = obj2.order_content;

        if (!obj1_order_content) {
          obj1_order_content = 99999999999999;
        }

        if (!obj2_order_content) {
          obj2_order_content = 99999999999999;
        }

        if (obj1.is_fixed) {
          if (obj2.is_fixed) {
            if (obj1_order_content < obj2_order_content) {
              return -1;
            } else if (obj1_order_content > obj2_order_content) {
              return 1;
            }
          } else {
            return -1;
          }
        } else {
          if (obj2.is_fixed) {
            return 1;
          } else {
            if (obj1_order_content < obj2_order_content) {
              return -1;
            } else if (obj1_order_content > obj2_order_content) {
              return 1;
            }
          }
        }

        if (obj1.id_content < obj2.id_content) {
          return -1;
        } else {
          return 1;
        }
      });

      this.setState({ contents: contents });
      this.setState({ loading: false });
    } catch (err) {
     toast.error('Erro ao carregar conteúdos');
    }
  }

  addContent = async (contentType) => {
    let newContent = new Content();
    newContent.content = {
      id_app: localStorage.getItem('ID_APP'),
      content_type: 6,
      is_fixed: 1,
      is_pill: contentType === "trail" ? 0 : 1
    };
    let contentObj = {};
    try {
      contentObj = await newContent.saveMainTrail();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let contentList = this.state.contents;
    contentList.push(contentObj);
    this.setState({ contents: contentList });
    return contentObj;
  };

  updateContent = (contentObj, field, value, force) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_content === contentObj.id_content;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          contentToUpdate[prop] = value[prop];
        }
      }
    } else if (field.indexOf('.') > 0) {
      let hierarchy = field.split('.');
      let obj = contentToUpdate;
      for (let i = 0; i < hierarchy.length; i++) {
        if (i === hierarchy.length - 1) {
          obj[hierarchy[i]] = value;
          break;
        }
        obj = obj[hierarchy[i]];
      }
    } else {
      contentToUpdate[field] = value;
    }

    this.setState({ contents: contentList });
    return contentList[position];
  };

  deleteContent = async (contentObj) => {
    let contentList = this.state.contents;

    // Finds Edited content on contents list and change Content_name value
    for (let i = 0; i < contentList.length; i++) {
      if (contentObj.id_content === contentList[i].id_content) {
        if (contentObj.content_type === 6) {
          Content.getContentTrail(contentObj.id_content).then(
            async (contentTrail) => {
              if (contentTrail && contentTrail.length) {
                for (let j = 0; j < contentTrail.length; j++) {
                  contentTrail[j].remove();
                }
              }
            },
          );
        }
        contentList[i].remove();
        contentList.splice(i, 1);
        break;
      }
    }

    this.setState({ contents: contentList });
  };

  saveContent = async (contentObj) => {
    let contentList = this.state.contents;

    let position = contentList.findIndex((obj) => {
      return obj.id_content === contentObj.id_content;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    if (contentToUpdate != null) {
      let contentObj = contentToUpdate;

      try {
        if (contentToUpdate.content_type === 3) {
          if (
            contentToUpdate.form.forms_questions &&
            contentToUpdate.form.forms_questions.length
          ) {
            let form_questions = contentToUpdate.form.forms_questions;
            for (let k = 0; k < form_questions.length; k++) {
              if (form_questions[k].form_question_type === 1) {
                if (!form_questions[k].forms_choices) {
                  throw new Error('form_choices');
                } else if (
                  !form_questions[k].forms_choices.length ||
                  form_questions[k].forms_choices.length === 0
                ) {
                  throw new Error('form_choices');
                } else if (!form_questions[k].forms_choices[0].form_choice) {
                  throw new Error('form_choices');
                }
              }
            }
          } else {
            throw new Error('form_questions');
          }
        }
        contentObj = await contentToUpdate.save();
        if (contentObj.have_medal) {
          let content_medal = await Medal.getContentMedal(
            contentObj.id_content,
          );
          if (!content_medal || content_medal.length === 0) {
            let medalObjUpdate = new Medal();
            let medal = await medalObjUpdate.save();
            if (medal) {
              let contentMedalObj = {
                id_medal: medal.id_medal,
                id_content: contentObj.id_content,
              };
              await Medal.saveContentMedal(contentMedalObj);
            }
          }
        }

        if (contentObj.content_type === 6) {
          Content.getContentTrail(contentObj.id_content).then(
            async (contentTrail) => {
              if (contentTrail && contentTrail.length) {
                for (let j = 0; j < contentTrail.length; j++) {
                  contentTrail[j].is_challenge = contentObj.is_challenge;
                  contentTrail[j].is_fixed = contentObj.is_fixed;
                  contentTrail[j].is_marketing = contentObj.is_marketing;
                  contentTrail[j].roles = contentObj.roles;
                  contentTrail[j].starting_content =
                    contentObj.starting_content;
                  contentTrail[j].is_archived = contentObj.is_archived;
                  contentTrail[j].is_published = contentObj.is_published;
                  await contentTrail[j].save();
                }
              }
            },
          );
        }
        // Content upload to server worked
        contentList[position] = contentObj;
        this.setState({ contents: contentList });
        toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
        switch (err.message) {
          case 'form_questions':
           toast.error(
              'Erro ao salvar conteúdo! Elabore uma pergunta para seu formulário.',
            );
            break;
          case 'form_choices':
           toast.error(
              'Erro ao salvar conteúdo! Pergunta do tipo múltipla deve ter pelo menos uma alternativa.',
            );
            break;
          default:
           toast.error('Erro ao salvar conteúdo!');
            break;
        }
      }
    }
  };

  refreshContents = async (parameters) => {
   await this.reloadContent(parameters);
  };

  handleSelectClassType = async (selectedType) => {
    const newContent = await this.addContent(selectedType);
    return newContent;
  }

  render() {
    return (
      <> 
      {this.state.contents.length===0 
      &&this.state.loading
      ? <Spinner
       margin='13px' 
      texto={'carregando...'}
      border={'#9BB3D4'}
      top={'#1D2D44'}/>
      :
      
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.contents}
        app={this.state.app}
        subjects={this.state.subjects}
        contents={this.state.contents}
        objectCard={ContentCard}
        objectDescription={ContentDescription}
        addObject={this.addContent}
        updateObject={this.updateContent}
        deleteObject={this.deleteContent}
        saveObject={this.saveContent}
        refreshObjects={this.refreshContents}
        relations={this.state.relations}
        objectIdName={'id_content'}
        objectModel={Content}
        onFilterReload={true}
        contentType={{
          content_type: "contents"
        }}
        showSelectTypeModal={true}
        handleSelectClassType={this.handleSelectClassType}
      ></GenericAdminScreen>}</>
    );
  }
}

export default AdminContentScreen;
