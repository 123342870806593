import React from "react";
import {
  Grid,
  Paper,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  Button,
} from "@material-ui/core";
import SaveButton from "../buttons/SaveButton";
import DeleteButton from "../buttons/DeleteButton";
import ArchiveButton from "../buttons/ArchiveButton";
import SearchableSelect from "../selectbox/SearchableSelect.js";
import { DateTimePicker } from "@material-ui/pickers/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { CustomDropzone } from "../CustomDropzone";
import FormQuestionCard from "../cards/FormQuestionCard";
import Training from "../../../models/Training";
import Content from "../../../models/Content";
import Select from "react-select";
import cloneDeep from "lodash/cloneDeep";
import AddIcon from "@material-ui/icons/Add";
import AdminContentTrailScreen from "../../screens/AdminContentTrailScreen";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {urlModify} from '../../../models/helpers/firebaseSend'

export default function ContentTrialDescription(props) {
  this.editionTimeout = null;
  // Object to Render
  this.object = props.object;
  if (
    this.object.training &&
    this.object.training.training_images &&
    this.object.training.training_images.length > 0
  ) {
    this.object.training.training_images.sort((image1, image2) => {
      return image1.training_image_position - image2.training_image_position;
    });
  }
  this.roles = props.relations[0];
  this.contents = props.contents ? props.contents : [];
  // Functions
  this.updateContent = props.updateObject;
  //dani



  if (!this.object.content_type) {
    this.updateContent(this.object, "content_type", 2);
  }

  let form = this.object.form ? this.object.form : { form_type: 5 };
  if (this.object.id_form) {
    this.object.form.form_type = this.object.form.form_type
      ? this.object.form.form_type
      : 5;
  }

  this.deleteContent = props.deleteObject;
  this.saveContent = props.saveObject;
  this.publishObject = props.publishObject;
  this.nullDescription = props.nullDescription;
  this.showList = props.showList;
  this.startingContent  = props.startingContent;

  let content_type = null;
  switch (this.object.content_type) {
    case 1:
      content_type = "video";
      break;
    case 2:
      content_type = "image";
      break;
    case 3:
      content_type = "form";
      break;
    case 4:
      content_type = "challenge";
      break;
    case 5:
      content_type = "pdf";
      break;
    case 6:
      content_type = "trail";
      break;
    case 9:
      content_type = "text";
      break;
  }

  if (
    (content_type === "video" ||
      content_type === "text" ||
      content_type === "pdf" ||
      content_type === "challenge" ||
      content_type === "image") &&
    !this.object.training
  ) {
    this.object.training = {
      id_app: localStorage.getItem("ID_APP"),
      id_training: this.object.id_training ? this.object.id_training : null,
    };
  } else if (content_type === "form" && !this.object.form) {
    this.object.form = { id_app: localStorage.getItem("ID_APP") };
  }

  this.object.roleOptions = this.roles.map((role) => {
    let roleOption = {
      value: role.id_role,
      label: role.role_name,
    };
    return roleOption;
  });

  if (this.object.roles !== undefined) {
    this.object.defaultRoleValues = this.object.roles.map((role) => {
      return {
        value: role.id_role ? role.id_role : null,
        label: role.role_name ? role.role_name : null,
      };
    });
  } else {
    this.object.defaultRoleValues = [];
  }
  //dani

  if (this.contents) {
    this.object.defaultContentValues = this.contents.map((content) => {
      if (content.id === this.object.id_predecessor_content) {
        let contentOptions = {
          value: content.id,
          label: content.title,
        };
        return contentOptions;
      }
    });
    this.object.defaultContentValues = this.object.defaultContentValues.filter(
      (item) => item
    );
  } else {
    this.object.defaultContentValues = [];
  }
  if (this.contents !== undefined) {
    this.object.contentOptions = this.contents.map((content) => {
      let contentOptions = {
        value: content.id,
        label: content.title,
      };
      return contentOptions;
    });
  } else {
    this.object.contentOptions = [];
  }
  //dani

  if (this.object.predecessor_content) {
    this.object.defaultPredecessorContentValue = {
      value: this.object.predecessor_content,
      label: this.object.predecessor_content.form
        ? this.object.predecessor_content.form.form_title
        : this.object.predecessor_content.training
        ? this.object.predecessor_content.training.training_title
        : "ID do Conteúdo:" + this.object.predecessor_content.id_content,
    };
  }

  let updateDescriptionState = props.updateDescriptionState
    ? props.updateDescriptionState
    : () => {};
  if (!props.descriptionState.content_type) {
    updateDescriptionState({ content_type: content_type });
  }

  this.onClickArchiveContent = () => {
    this.onChangeUpdate("is_archived")({
      target: {
        value: this.object.is_archived ? 0 : 1,
      },
    });
    this.saveContent(this.object);
  }; 

  this.onClickSaveContent = () => {
    if (content_type === "form") {
      delete this.object.training;
      this.object.id_training = null;
    } else {
      this.object.training.is_challenge_photo =
        content_type === "challenge" ? 1 : 0;
      this.object.id_form = null;
    }

    this.saveContent(this.object);
  };

  this.onClickPublishContent = () => {
    if (content_type === "form") {
      delete this.object.training;
      this.object.id_training = null;
    } else {
      this.object.training.is_challenge_photo =
        content_type === "challenge" ? 1 : 0;
      this.object.id_form = null;
    }
    this.object.is_published = 1;
    this.publishObject(this.object);
  };

  this.onClickDeleteContent = () => {
    if (window.confirm("Tem certeza que deseja remover esse item?")) {
      this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the content
      this.object.is_published = null;
      this.deleteContent(this.object);
    }
  };
  
  this.onChangeUpdate =
    (field, force = false) =>
    (event) => {
      let value = event && event.target ? event.target.value : null;

      if (
        field === "starting_content" ||
        field === "is_fixed" ||
        field === "is_marketing" ||
        field === "is_challenge" ||
        field === "is_removed_when_completed"
      ) {
        value = event.target.checked ? 1 : 0;
      } else if (field === "content_title") {
        switch (content_type) {
          case "challenge":
          case "video":
          case "text":
          case "pdf":
            field = "training.training_title";
            break;
          case "form":
            field = "form.form_title";
            break;
          default:
            field = "training.training_title";
        }
      }

      this.updateContent(this.object, field, value, force);
    };

  this.onDropActionCoverImage = async (file) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedCoverImage: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = null;
      switch (this.object.content_type) {
        case 1:
        case 2:
        case 4:
        case 5:
        case 6:
          imageUrl = await Training.sendTrainingCoverImage(
            file,
            null,
            progressFunction
          );
          this.updateContent(
            this.object,
            "training.training_cover_image",
            imageUrl.url,
            true
          );
          break;
        case 3:
          imageUrl = await Training.sendTrainingCoverImage(
            file,
            null,
            progressFunction
          );
          this.updateContent(
            this.object,
            "form.form_image",
            imageUrl.url,
            true
          );
          break;
        default:
          toast.error("Tipo de conteúdo não permite imagem de capa!");
      }
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteCoverImage = () => {
    switch (content_type) {
      case "video":
      case "text":
      case "pdf":
      case "challenge":
      case "image":
        return this.updateContent(
          this.object,
          "training.training_cover_image",
          null,
          true
        );
      case "form":
        return this.updateContent(this.object, "form.form_image", null, true);
      default:
        toast.error("Tipo de conteúdo não permite imagem de capa!");
    }
  };

  this.onDropActionVideo = async (file) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedVideo: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = await Training.sendTrainingVideo(
        file,
        this.object.training.id_training,
        progressFunction
      );
      // import {urlModify} from '../../../models/helpers/firebaseSend'
      let urlClean= urlModify(imageUrl.url)
      /*console.log(urlClean,'urlClean')
      console.log(imageUrl.url,'imageUrl.url')*/
          
      let video = await this.loadVideo(file);
      let videoDuration = parseInt(video.duration);
      this.updateContent(
        this.object,
        "training.training_video",
        urlClean,
        true
      );
      this.updateContent(
        this.object,
        "training.training_estimated_time",
        videoDuration,
        true
      );
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.loadVideo = (file) =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject("Invalid video. Please select a video file.");
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  this.deleteVideo = () => {
    return this.updateContent(
      this.object,
      "training.training_video",
      null,
      true
    );
  };

  this.onDropActionFile = async (file, field) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedFile: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = await Training.sendTrainingFile(
        file,
        this.object.training.id_training,
        progressFunction
      );
      this.updateContent(this.object, field, imageUrl.url, true);
      this.updateContent(
        this.object,
        "training.training_estimated_time",
        30,
        true
      );
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.onDropActionImage = async (file, idx) => {
    let files = file;
    const progressFunction = (completed) => {
      let completedImages = props.completed.completedImages
        ? props.completed.completedImages
        : [];
      completedImages[idx] = completed;
      const completedState = {
        completedImages: completedImages,
      };
      props.progressFunction(completedState);
    };

    let index = idx;
    try {
      if (files.length > 10) {
        toast.error("arquivo muito grande");
        return;
      }
      for (let i = 0; i < files.length; i++) {
        // if (this.object.training.training_images.length > 10) {

        //   return;
        // }
        if (i < files.length - 1) this.addImage();
        idx = index + i;
        file = files[i];
        let imageUrl = await Training.sendTrainingFile(
          file,
          this.object.training.id_training,
          progressFunction
        );
        let imageToUpdate = { training_image_filename: imageUrl.url };
        let training_images = this.object.training.training_images
          ? this.object.training.training_images
          : [];
        training_images[idx].training_image_filename =
          imageToUpdate.training_image_filename;
        this.updateContent(
          this.object,
          "training.training_images",
          training_images,
          true
        );
        this.updateContent(
          this.object,
          "training.training_estimated_time",
          30,
          true
        );

        // Reset completed variable (progress bar)
        // progressFunction(0);
        if (i === files.length - 1) {
          this.saveContent(this.object);
          progressFunction(0);
        } else {
          progressFunction(1);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  this.onDropActionPDF = async (file, idx) => {
    let files = file;
    const progressFunction = (completed) => {
      let completedImages = props.completed.completedImages
        ? props.completed.completedImages
        : [];
      completedImages[idx] = completed;
      const completedState = {
        completedImages: completedImages,
      };
      props.progressFunction(completedState);
    };

    let index = idx;
    try {
      if (files.length > 1) {
        toast.error("Só é permitido um arquivo PDF.");
        return;
      }

      if(files[0].type !== "application/pdf"){
        toast.error("O tipo do arquivo deve ser PDF.");
        return;
      }

      for (let i = 0; i < files.length; i++) {
        if (i < files.length - 1) this.addImage();
        idx = index + i;
        file = files[i];
        let imageUrl = await Training.sendTrainingFile(
          file,
          this.object.training.id_training,
          progressFunction
        );
        let imageToUpdate = { training_image_filename: imageUrl.url };
        let training_images = this.object.training.training_images
          ? this.object.training.training_images
          : [];
        training_images[idx].training_image_filename =
          imageToUpdate.training_image_filename;
        this.updateContent(
          this.object,
          "training.training_images",
          training_images,
          true
        );
        this.updateContent(
          this.object,
          "training.training_estimated_time",
          30,
          true
        );

        // Reset completed variable (progress bar)
        // progressFunction(0);
        if (i === files.length - 1) {
          this.saveContent(this.object);
          progressFunction(0);
        } else {
          progressFunction(1);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteFile = () => {
    return this.updateContent(
      this.object,
      "training.training_file",
      null,
      true
    );
  };

  this.changeRadioFormType = (event) => {
    let value = event.target.value;

    value = Number(value);

    let form = this.object.form ? this.object.form : {};

    form.form_type = value;

    updateDescriptionState({ form: form });

    if (value === 2) {
      this.updateContent(this.object, "form.forms_questions", [
        { form_question_type: 2 },
      ]);
    } else if (value === 3) {
      this.updateContent(this.object, "form.forms_questions", [
        { form_question_type: 4 },
      ]);
    }

    this.updateContent(this.object, "form", form, true);
  };

  this.changeRadioTypeContent = (event) => {
    if (event.target.value === "form" && !this.object.form) {
      this.updateContent(
        this.object,
        "form",
        {
          form_type: 1,
          id_form: this.object.id_form ? this.object.id_form : null,
        },
        true
      );
    }

    switch (event.target.value) {
      case "video":
        content_type = "video";
        this.object.content_type = 1;
        break;
      case "image":
        content_type = "image";
        this.object.content_type = 2;
        break;
      case "form":
        content_type = "form";
        this.object.content_type = 3;
        break;
      case "challenge":
        content_type = "challenge";
        this.object.content_type = 4;
        delete this.object.form;
        break;
      case "pdf":
        content_type = "pdf";
        this.object.content_type = 5;
        break;
      case "text":
        content_type = "text";
        this.object.content_type = 9;
        break;
      case "trail":
        content_type = "trail";
        this.object.content_type = 6;
        break;
    }

    updateDescriptionState({
      content_type: event.target.value,
      user_defined_content_type: event.target.value,
    });
  };

  this.onQuestionChange = (field, idxQuestion) => (event) => {
    let value = event && event.target ? event.target.value : null;
    let formsQuestionsList = cloneDeep(this.object.form.forms_questions);
    let formQuestionObj = formsQuestionsList[idxQuestion];

    if (formQuestionObj) {
      formQuestionObj[field] = value;
      this.updateContent(
        this.object,
        "form.forms_questions",
        formsQuestionsList
      );
    }
  };

  this.addQuestion = () => {
    let formsQuestionsList = this.object.form.forms_questions
      ? cloneDeep(this.object.form.forms_questions)
      : [];
    formsQuestionsList.push({ form_question_type: 1 });
    this.updateContent(this.object, "form.forms_questions", formsQuestionsList);
  };

  this.addImage = () => {
    if (
      !this.object.training.training_images ||
      this.object.training.training_images.length < 10
    ) {
      let imagesList = this.object.training.training_images
        ? cloneDeep(this.object.training.training_images)
        : [];
      imagesList.push({ training_image_position: imagesList.length });
      this.updateContent(this.object, "training.training_images", imagesList);
    } else {
      toast.dark("limite de 10 imagens atingido");
    }
  };

  this.showForm = () => {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            textAlign: "left",
          }}
        >
          Perguntas:
        </span>
        {this.object.form && this.object.form.forms_questions
          ? this.object.form.forms_questions.map((question, idx) => {
              let deleteQuestion = () => {
                let formsQuestionsList = cloneDeep(
                  this.object.form.forms_questions
                );
                formsQuestionsList.splice(idx, 1);
                this.updateContent(
                  this.object,
                  "form.forms_questions",
                  formsQuestionsList
                );
              };

              return (
                <FormQuestionCard
                  formQuestion={question}
                  onQuestionChange={this.onQuestionChange}
                  deleteQuestion={deleteQuestion}
                  idx={idx}
                  formType={
                    this.object.form ? this.object.form.form_type : null
                  }
                />
              );
            })
          : null}
        {this.object.form &&
        this.object.form.form_type !== 2 &&
        this.object.form.form_type !== 3 ? (
          <Button onClick={this.addQuestion} variant="contained">
            Adicionar Pergunta
            <AddIcon />
          </Button>
        ) : null}
      </Grid>
    );
  };

  this.deleteImage = (idx) => {
    let ImagesList = cloneDeep(this.object.training.training_images);
    ImagesList.splice(idx, 1);
    this.updateContent(this.object, "training.training_images", ImagesList);
  };

  this.onChangeUpdateTrainingImage = (event, idx) => {
    let imagesList = cloneDeep(this.object.training.training_images);
    imagesList[idx].training_image_description = event.target.value;
    this.object.training.training_images = imagesList;
    this.updateContent(this.object, "training.training_images", imagesList);
  };

  this.showTrailContent = () => {
    return (
      <Grid xs={12} item style={{ width: "100%" }} spacing={2}>
        <AdminContentTrailScreen startContentID={this.object.id_content} />
      </Grid>
    );
  };

  this.showImage = () => {
    return (
      <Grid xs={12} item style={{ width: "100%" }} spacing={2}>
        {this.object.training &&
        this.object.training.training_images &&
        this.object.training.training_images.length > 0
          ? this.object.training.training_images.map((image, idx) => {
              return (
                <Grid container spacing={2} direction={"row"}>
                  {image.training_image_filename ? (
                    <Grid container xs={6}>
                      <Grid item xs={12}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            lineHeight: 1,
                            letterSpacing: "0.00938em",
                            float: "left",
                            color: "rgba(0, 0, 0, 0.54)",
                            paddingBottom: "10px",
                          }}
                        >
                          Imagem {idx + 1}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <img
                          style={{ maxHeight: "30vh" }}
                          src={image.training_image_filename}
                          id={"image"}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid xs={6}>
                      <CustomDropzone
                        onDropAction={(file) => {
                          this.onDropActionImage(file, idx);
                        }}
                        completed={
                          props.completed.completedImages &&
                          props.completed.completedImages.length > 0
                            ? props.completed.completedImages[idx]
                            : 0
                        }
                        inactiveLabel={
                          "Arraste a imagem(.jpeg, .png, .gif) para cá, \n adicione até 10 imagens de uma vez"
                        }
                        activeLabel={"Solte a imagem"}
                      />
                      <Grid xs={12}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            lineHeight: 1,
                            letterSpacing: "0.00938em",
                            float: "bottom",
                            color: "rgba(0, 0, 0, 0.54)",
                            paddingBottom: "10px",
                          }}
                        >
                          Tamanho Máximo da Imagem: 10 Mb
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  <Grid xs={6} item>
                    <Grid item xs={12}>
                      <span
                        style={{
                          float: "left",
                          color: "rgba(0, 0, 0, 0.54)",
                          padding: "0",
                          fontSize: "13px",
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          fontWeight: "400",
                          lineHeight: "1",
                          letterSpacing: "0.00938em",
                          marginTop: "10px",
                          paddingLeft: "20px",
                          paddingBottom: "10px",
                        }}
                      >
                        Descrição da Imagem {idx + 1} -{" "}
                        {image.training_image_description
                          ? image.training_image_description.length
                          : 0}
                        /1000
                      </span>
                    </Grid>
                    <TextareaAutosize
                      aria-label={"Descrição da Imagem"}
                      placeholder={
                        "Descrição da Imagem(Máximo de 1000 caracteres)..."
                      }
                      maxLength={1000}
                      rows={4}
                      value={image.training_image_description}
                      onChange={(event) => {
                        this.onChangeUpdateTrainingImage(event, idx);
                      }}
                      style={{
                        width: "90%",
                        height: "70%",
                        padding: "10px 10px",
                      }}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Button
                      onClick={() => {
                        this.deleteImage(idx);
                      }}
                      variant="contained"
                      color={"secondary"}
                      style={{ background: "#CC0B0B" }}
                    >
                      Remover Imagem {idx + 1}
                    </Button>
                  </Grid>
                  <Grid xs={12}>
                    <div style={{ height: "40px" }} />
                  </Grid>
                </Grid>
              );
            })
          : null}
        {this.object.training?.training_images?.length < 10 && (
          <Button onClick={this.addImage} variant="contained" color={"primary"}>
            Adicionar Imagem
            <AddIcon />
          </Button>
        )}
        {!this.object.training?.training_images && (
          <Button onClick={this.addImage} variant="contained" color={"primary"}>
            Adicionar Imagem
            <AddIcon />
          </Button>
        )}

        <Grid item xs={12}>
          <span
            style={{
              float: "left",
              color: "rgba(0, 0, 0, 0.54)",
              padding: "0",
              fontSize: "13px",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontWeight: "400",
              lineHeight: "1",
              letterSpacing: "0.00938em",
              marginTop: "10px",
            }}
          ></span>
        </Grid>
      </Grid>
    );
  };

  this.showPDF = () => {
    return (
      <Grid xs={12} item style={{ width: "100%" }} spacing={2}>
        {this.object.training &&
        this.object.training.training_images &&
        this.object.training.training_images.length > 0
          ? this.object.training.training_images.map((image, idx) => {
              return (
                <Grid container spacing={2} direction={"row"}>
                  {image.training_image_filename ? (
                    <Grid container xs={6}>
                      <Grid item xs={12}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            lineHeight: 1,
                            letterSpacing: "0.00938em",
                            float: "left",
                            color: "rgba(0, 0, 0, 0.54)",
                            paddingBottom: "10px",
                          }}
                        >
                          Arquivo {idx + 1}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <a href={image.training_image_filename} target="_blank">Visualizar arquivo</a>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid xs={6}>
                      <CustomDropzone
                        onDropAction={(file) => {
                          this.onDropActionPDF(file, idx);
                        }}
                        completed={
                          props.completed.completedImages &&
                          props.completed.completedImages.length > 0
                            ? props.completed.completedImages[idx]
                            : 0
                        }
                        inactiveLabel={
                          "Arraste o PDF para cá, \n adicione até 1 arquivos de uma vez"
                        }
                        activeLabel={"Solte o arquivo"}
                      />
                      <Grid xs={12}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            lineHeight: 1,
                            letterSpacing: "0.00938em",
                            float: "bottom",
                            color: "rgba(0, 0, 0, 0.54)",
                            paddingBottom: "10px",
                          }}
                        >
                          Tamanho Máximo do PDF: 10 Mb
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  <Grid xs={12} style={{
                    marginTop: 32,
                    marginBottom: 32
                  }}>
                    <Button
                      onClick={() => {
                        this.deleteImage(idx);
                      }}
                      variant="contained"
                      color={"secondary"}
                      style={{ background: "#CC0B0B" }}
                    >
                      Remover Arquivo {idx + 1}
                    </Button>
                  </Grid>
                </Grid>
              );
            })
          : null}
        {this.object.training?.training_images?.length < 1 && (
          <Button onClick={this.addImage} variant="contained" color={"primary"}>
            Adicionar Arquivo
            <AddIcon />
          </Button>
        )}
        {!this.object.training?.training_images && (
          <Button onClick={this.addImage} variant="contained" color={"primary"}>
            Adicionar Arquivo
            <AddIcon />
          </Button>
        )}
      </Grid>
    );
  };

  this.onChangeUpdateRoles = (value) => {
    let roles;
    if (value != null) {
      //null cant use map function
      roles = value.map((obj) => {
        return {
          //revert the label to put the id_role and role_name in the Content Object
          id_role: obj.value,
          role_name: obj.label,
        };
      });
    } else {
      roles = [];
    }
    this.updateContent(this.object, "roles", roles, true);
  };

  //this.contentType = props.descriptionState.content_type;

  this.predecessorContentChosen = (value) => {
    this.updateContent(
      this.object,
      "id_predecessor_content",
      value && value.id_content ? value.id_content : null,
      true
    );
    this.object.defaultPredecessorContentValue = value
      ? {
          value: value,
          label: value.training_title
            ? value.training_title
            : value.form_title
            ? value.form_title
            : "ID:" + value.id_content,
        }
      : null;
  };

  return (
    <div style={{ height: "auto", backgroundColor: "#eeeeee" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          alignItems: "center",
          padding: "30px 20px 10px 20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid xs={12} container direction="row" spacing={1}>
            <Grid xs={12} container spacing={2}>
              {!this.startingContent.is_pill ?  <>
                <Grid xs={4} item>
                <TextField
                  label="Título Conteúdo"
                  value={
                    this.object.training && this.object.training.training_title
                      ? this.object.training.training_title
                      : this.object.form && this.object.form.form_title
                      ? this.object.form.form_title
                      : ""
                  }
                  style={{ width: "100%" }}
                  onChange={this.onChangeUpdate("content_title", false)}
                  onBlur={this.onChangeUpdate("content_title", true)}
                  multiline
                />
              </Grid>
                <Grid xs={4} item></Grid> </>: null}
              <Grid xs={1} item>
                <TextField label="ID" value={this.object.id_content} disabled />
              </Grid>
              <Grid xs={3} item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    label="Data da ultima alteração"
                    value={this.object.updated_date}
                    style={{ width: "100%" }}
                    format="dd/MM/yyyy HH:mm"
                    disabled
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            {this.object.id_app === 4 ? (
              <Grid xs={2} item>
                <TextField
                  label="Cupons"
                  value={
                    this.object.normal_tickets && !this.object.is_marketing
                      ? this.object.normal_tickets
                      : 0
                  }
                  disabled={this.object.is_marketing}
                  style={{ width: "100%" }}
                  onChange={this.onChangeUpdate("normal_tickets", false)}
                  onBlur={this.onChangeUpdate("normal_tickets", true)}
                  type={"number"}
                />
              </Grid>
            ) : this.object.id_app === 3 ||
              this.object.id_app === 5 ||
              this.object.id_app === 14 ||
              this.object.id_app === 26 ||
              this.object.id_app === 27 ||
              this.object.id_app === 32 ||
              this.object.id_app === 33 ||
              this.object.id_app === 34 ||
              this.startingContent.is_campaign === 1 ? null : (
              <Grid xs={2} item>
                <TextField
                  label={
                    localStorage.getItem("ID_APP") != 3 ? "Moedas" : "Pontos"
                  }
                  value={
                    this.object.content_points &&
                    this.object.content_points >= 0 &&
                    !this.object.is_marketing
                      ? this.object.content_points
                      : ""
                  }
                  disabled={this.object.is_marketing}
                  style={{ width: "100%", paddingTop: "5px" }}
                  onChange={this.onChangeUpdate("content_points", false)}
                  onBlur={this.onChangeUpdate("content_points", true)}
                  type={"number"}
                />
              </Grid>
            )}

            <Grid xs={2} item>
              <Grid xs={12} item>
                <TextField
                  label="Ordem Conteúdo"
                  value={this.object.order_content}
                  onChange={this.onChangeUpdate("order_content", false)}
                  inputProps={{ style: {paddingTop: "5px"}}}
                  style={{ width: "100%", textAlign: "left", paddingTop: "5px" }}
                />
              </Grid>
            </Grid>
            {this.object.content_type === 3 ? (
              <Grid item xs={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        textAlign: "left",
                        letterSpacing: "0.00938em",
                        float: "left",
                        color: "rgba(0, 0, 0, 0.54)",
                        paddingBottom: "0px",
                      }}
                    >
                      Responder somente uma vez?
                    </span>
                  </Grid>
                  <Grid xs={12} item>
                    <Grid style={{ float: "left" }}>
                      <Checkbox
                        onChange={this.onChangeUpdate(
                          "is_removed_when_completed",
                          true
                        )}
                        checked={this.object.is_removed_when_completed === 1}
                        title={"Responder somente uma vez?"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            <Grid xs={12} container direction="row" spacing={1}>
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: "18px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: "0.00938em",
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingBottom: "15px",
                    paddingTop: "40px",
                  }}
                >
                  Conteúdo:
                </span>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid container>
                {content_type === "form" && this.startingContent.is_campaign !== 1 ? (
                  <Grid item xs={12}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        letterSpacing: "0.00938em",
                        float: "left",
                        color: "rgba(0, 0, 0, 0.54)",
                        paddingBottom: "0px",
                      }}
                    >
                      Tipo de Prova
                    </span>
                  </Grid>
                ) : null }
                {content_type === "form" ? (
                  <Grid item xs={12}>
                    <RadioGroup
                      aria-label="form_type"
                      name="form_type"
                      value={form.form_type}
                      onChange={this.changeRadioFormType}
                      row
                      style={{ marginLeft: "10px" }}
                    >
                      <div style={{ display: "none" }}>
                        <FormControlLabel
                          value={5}
                          control={<Radio color="primary" />}
                          label="Prova"
                          labelPlacement="end"
                          // checked
                        />
                        {/* 
                            <FormControlLabel
                          value={1}
                          control={<Radio color='primary' />}
                          label='Questionario'
                          labelPlacement='end'
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color='primary' />}
                          label='Fale Conosco'
                          labelPlacement='end'
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio color='primary' />}
                          label='Orçamento'
                          labelPlacement='end'
                        />
                        <FormControlLabel
                          value={4}
                          control={<Radio color='primary' />}
                          label='Adesão'
                          labelPlacement='end'
                        /> */}
                      </div>
                    </RadioGroup>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {content_type !== "video" && content_type !== "text" ? null : (
              <Grid xs={6}>
                <Grid xs={12} item>
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      lineHeight: 1,
                      letterSpacing: "0.00938em",
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      paddingBottom: "10px",
                      paddingLeft: "20px",
                    }}
                  >
                    Descrição: -{" "}
                    {this.object.training &&
                    this.object.training.training_description
                      ? this.object.training.training_description.length
                      : 0}
                    /5000
                  </span>
                </Grid>
                <TextareaAutosize
                  aria-label={"Descrição"}
                  placeholder={"Descrição(Máximo de 5000 caracteres)"}
                  maxLength={5000}
                  rows={4}
                  value={
                    this.object.training &&
                    this.object.training.training_description
                      ? this.object.training.training_description
                      : ""
                  }
                  onChange={this.onChangeUpdate(
                    "training.training_description",
                    false
                  )}
                  onBlur={this.onChangeUpdate(
                    "training.training_description",
                    true
                  )}
                  style={{
                    width: "90%",
                    height: "70%",
                    padding: "10px 10px",
                  }}
                />
              </Grid>
            )}
            <Grid xs={12}>
              <div style={{ height: "40px" }}></div>
            </Grid>
            {content_type === "video" ? (
              <Grid xs={6} item>
                <Grid container>
                  <Grid item xs={12}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        letterSpacing: "0.00938em",
                        float: "left",
                        color: "rgba(0, 0, 0, 0.54)",
                        paddingBottom: "10px",
                      }}
                    >
                      Vídeo de Treinamento - Tamanho Máximo: 1 Gb
                      {this.object.training &&
                      this.object.training.training_video ? (
                        <a href="#" onClick={this.deleteVideo}>
                          {" "}
                          Remover Video
                        </a>
                      ) : null}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    {this.object.training &&
                    this.object.training.training_video ? (
                      <video
                        style={{ maxHeight: "100%", maxWidth: "100%" }}
                        controls
                      >
                        <source
                          src={
                            this.object.training &&
                            this.object.training.training_video
                              ? this.object.training.training_video
                              : null
                          }
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <div id="picker">
                        <CustomDropzone
                          onDropAction={this.onDropActionVideo}
                          completed={props.completed.completedVideo}
                          inactiveLabel={
                            "Arraste o vídeo(.mp4, .avi, .gif) de treinamento para cá"
                          }
                          activeLabel={"Solte o vídeo de treinamento"}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {content_type === "text" ? (
              <Grid xs={6} item>
                <Grid container></Grid>
              </Grid>
            ) : null}
            {content_type === "challenge" ? (
              <Grid xs={6} item>
                <div style={{ minHeight: 200 }}>
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      lineHeight: 1,
                      letterSpacing: "0.00938em",
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      paddingBottom: "10px",
                    }}
                  >
                    Descrição do Desafio:
                    {this.object.training &&
                    this.object.training.training_file ? (
                      <a href="#" onClick={this.deleteFile}>
                        {" "}
                        Remover Arquivo
                      </a>
                    ) : null}
                  </span>
                  <TextareaAutosize
                    aria-label="Descrição do Desafio"
                    placeholder="Descrição do Desafio"
                    rows={4}
                    value={
                      this.object.training &&
                      this.object.training.training_description
                        ? this.object.training.training_description
                        : ""
                    }
                    onChange={this.onChangeUpdate(
                      "training.training_description",
                      false
                    )}
                    onBlur={this.onChangeUpdate(
                      "training.training_description",
                      true
                    )}
                    style={{
                      width: "90%",
                      height: "80%",
                      padding: "10px 10px",
                    }}
                  />
                  <TextField
                    label="Limite de envio de fotos"
                    value={this.object.training.challenge_photos_limit}
                    style={{ width: "100%" }}
                    onChange={this.onChangeUpdate(
                      "training.challenge_photos_limit",
                      false
                    )}
                    onBlur={this.onChangeUpdate(
                      "training.challenge_photos_limit",
                      true
                    )}
                    type={"number"}
                  />
                </div>
              </Grid>
            ) : null}
            <Grid container direction="row" spacing={2}>
              {content_type === "form" ? this.showForm() : null}
              {content_type === "image" ? this.showImage() : null}
              {content_type === "pdf" ? this.showPDF() : null}
              {content_type === "trail" ? this.showTrailContent() : null}
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <SaveButton
              style={{ float: "right" }}
              text={"Publicar"}
              onClickSave={this.onClickPublishContent}
              ref={(ref) => {
                this.saveButton = ref;
              }}
            />
            <SaveButton
              style={{ float: "right" }}
              text={"Salvar Rascunho"}
              onClickSave={this.onClickSaveContent}
              ref={(ref) => {
                this.saveButton = ref;
              }}
            />
            <DeleteButton
              style={{ float: "right", background: "#CC0B0B" }}
              onClickDelete={this.onClickDeleteContent}
            />
            <ArchiveButton
              style={{ float: "right" }}
              onClickArchive={this.onClickArchiveContent}
              is_archived={this.object.is_archived}
              ref={(ref) => {
                this.archiveButton = ref;
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
