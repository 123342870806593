import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Content from '../../models/Content';
import ClassWe from '../../models/ClassWe';
import ClassWeAuthor from '../../models/ClassWeAuthor';
import TopicsOfInterest from '../../models/TopicOfInterest';
import Role from '../../models/Role';

import ContentCard from '../components/cards/ContentCard';
import ContentClassWeCard from '../components/cards/ClassWeCard';
import ContentClassWeDescription from '../components/description_cards/ContentClassWeDescription';

class AdminContentScreenWe extends Component {
  editionTimeout = null;

  state = {
    contents: [],
    relations: [],
    filters: [],
    authors: [],
    subjects: [],
  };

  // TEXTS
  searchText_placeholder = 'Título do Curso';
  addButton_placeholder = 'Curso';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    ClassWe.query()
      .then((contents) => {
        this.setState({ contents: contents });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
    Role.query().then((roles) => {
      let relations = this.state.relations;
      relations[0] = roles;
      this.setState({ relations: relations });
    });

    ClassWeAuthor.query().then((authors) => {
      let authorsObj = this.state.authors;
      authorsObj[0] = authors;
      this.setState({ authors: authorsObj });
    });

    TopicsOfInterest.query().then((topics) => {
      let subjects = this.state.subjects;
      subjects[0] = topics;
      this.setState({ subjects: subjects });
    });

    this.reloadContent();
  }

  async reloadContent(parameters) {
    try {
      let contents = await ClassWe.query(parameters);

      this.setState({ contents: contents });
    } catch (err) {
     toast.error('Erro ao carregar conteúdos');
    }
  }

  addContent = async () => {
    let newContent = new ClassWe();
    newContent.content = {
      id_app: localStorage.getItem('ID_APP'),
    };
    let contentObj = {};
    try {
      contentObj = await newContent.save();
      ClassWe.addOrUpdateProduct(contentObj);
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let contentList = this.state.contents;
    contentList.push(contentObj);
    this.setState({ contents: contentList });
    return contentObj;
  };

  updateContent = (contentObj, field, value, force) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_class === contentObj.id_class;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_class === contentObj.id_class;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          contentToUpdate[prop] = value[prop];
        }
      }
    } else if (field.indexOf('.') > 0) {
      let hierarchy = field.split('.');
      let obj = contentToUpdate;
      for (let i = 0; i < hierarchy.length; i++) {
        if (i === hierarchy.length - 1) {
          obj[hierarchy[i]] = value;
          break;
        }
        obj = obj[hierarchy[i]];
      }
    } else {
      contentToUpdate[field] = value;
    }

    this.setState({ contents: contentList });
    return contentList[position];
  };

  deleteContent = async (contentObj) => {
    let contentList = this.state.contents;

    // Finds Edited content on contents list and change Content_name value
    for (let i = 0; i < contentList.length; i++) {
      if (contentObj.id_class === contentList[i].id_class) {
        contentList[i].remove();
        contentList.splice(i, 1);
        break;
      }
    }

    this.setState({ contents: contentList });
  };

  saveContent = async (contentObj) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_class === contentObj.id_class;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_class === contentObj.id_class;
    });

    if (contentToUpdate != null) {
      let contentObj = contentToUpdate;

      try {
        contentObj = await contentToUpdate.save();
        ClassWe.addOrUpdateProduct(contentObj);

        // Content upload to server worked
        contentList[position] = contentObj;
        if (contentList[position].is_archived === 1) {
          contentList.splice(position, 1);
        }
        this.setState({ contents: contentList });
        toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
        switch (err.message) {
          case 'form_questions':
           toast.error(
              'Erro ao salvar conteúdo! Elabore uma pergunta para seu formulário.',
            );
            break;
          case 'form_choices':
           toast.error(
              'Erro ao salvar conteúdo! Pergunta do tipo múltipla deve ter pelo menos uma alternativa.',
            );
            break;
          default:
           toast.error('Erro ao salvar conteúdo!');
            break;
        }
      }
    }
  };

  refreshContents = async (parameters) => {
    this.reloadContent(parameters);
  };

  render() {
    return (
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.contents}
        objectCard={ContentClassWeCard}
        objectDescription={ContentClassWeDescription}
        addObject={this.addContent}
        updateObject={this.updateContent}
        deleteObject={this.deleteContent}
        saveObject={this.saveContent}
        refreshObjects={this.refreshContents}
        relations={this.state.relations}
        authors={this.state.authors}
        subjects={this.state.subjects}
        contents={this.state.contents}
        objectIdName={'id_class'}
        objectModel={ClassWe}
        onFilterReload={true}
      ></GenericAdminScreen>
    );
  }
}

export default AdminContentScreenWe;
