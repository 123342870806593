import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { TextInput } from '../text_fields/ThemeTextInputs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export const AutoCompleteInput = ({onChange, options, inputValue, onInputChange}) => {
  return(
    <Autocomplete
      disablePortal
      noOptionsText="Nenhum usuário encontrado."
      id="combo-box-demo"
      onChange={(event, newValue) => {
        if(newValue){
          onChange(newValue.id);
        }else {
          onChange(null);
        }        
      }}
      options={options}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        onInputChange(newInputValue);
      }}
      renderInput={(params) => 
      <TextInput 
        {...params} 
        label="Usuário"
        placeholder="Nome ou número de telefone"
        variant="outlined"
      />}
    />
  );
}

export const MultipleSelectInput = ({value, onChange, options}) => {
  function formatRole(role){
    return role.replace(/ \((\d+)\)/, "");
  }

  return(
    <FormControl sx={{ width: '100%','& label.Mui-focused': {
      color: '#405066',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#94A1B2',
      },
      '&:hover fieldset': {
        borderColor: '#405066',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#405066',
    }}}}>
    <InputLabel id="demo-multiple-chip-label">Papéis</InputLabel>
    <Select
      labelId="demo-multiple-chip-label"
      id="demo-multiple-chip"
      multiple
      multiline={true}
      value={value}
      onChange={onChange}
      input={<OutlinedInput id="select-multiple-chip" label="Papéis" />}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => (
            <Chip key={value} label={formatRole(value)}  sx={{backgroundColor: "#B3F6FF"}} />
          ))}
        </Box>
      )}
      MenuProps={MenuProps}
    >
      {options.map((option) => (
        <MenuItem
          key={option}
          value={option}
        >
          {formatRole(option)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
  );
}