import { Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileExcel,
    faTrash,
    faFileWord,
    faFilePdf,
    faFilePowerpoint,
    faFileImage,
    faFileVideo,
    faFileAlt
} from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import AttachFileButton from '../buttons/AttachFileButton';
import { DateTimePicker } from '@material-ui/pickers/';
import { CustomDropzone } from "../CustomDropzone";
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import SearchTextFieldWithFilter from "../text_fields/SearchTextFieldWithFilter";
import NumberFormat from 'react-number-format';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';
import SRFilteredSelect from '../selectbox/SRFilteredSelect'
import SaveButton from "../buttons/SaveButton";
import Store from "../../../models/Store";
import "../../../../node_modules/rodal/lib/rodal.css";
import Rodal from "rodal";

export default function StoreAttachmentDescription(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;
    this.objectAttachList = props.objectAttachList;

    //FUNCTIONS
    this.saveStore = props.saveObject;
    this.updateStore = props.updateObject;
    this.deleteStore = props.deleteObject;
    this.nullDescription = props.nullDescription;
    this.refreshStore = props.refreshObject;
    this.searchDoc = props.searchDoc;
    this.searchTimeout = null;
    this.filterText = '';

    this.onClickDeleteStore = () => {
        if (window.confirm('Deseja mesmo deletar esse item?')) {
            this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the store
            this.deleteStore(this.object);
        }
    };


    function NumberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                isNumericString
                allowNegative={false}
                decimalScale={0}
            />
        );
    };

    this.onDropActionAttachFiles = async (files, allFiles) => {
        files = files;

        const progressFunction = (completed) => {
            const completedState = {
                completedAttachedFiles: completed,
            };
            props.progressFunction(completedState);
        };

        try {
            for (let i = 0; i < files.length; i++) {
                let imageUrl = null;
                let videoUrl = null;
                let file = files[i];
                let filetype = file.type.split('/')[0];
                if (filetype === 'video') {
                    videoUrl = await Store.sendAttachFileVideo(
                        file,
                        progressFunction
                    );
                    if (videoUrl) {
                        await Store.saveStoreDocument(
                            this.object,
                            videoUrl.url,
                            file.name
                        )
                    }
                } else {
                    imageUrl = await Store.sendAttachFile(
                        file,
                        progressFunction
                    );
                    if (imageUrl) {
                        await Store.saveStoreDocument(
                            this.object,
                            imageUrl.url,
                            file.name
                        )
                    }
                }

                // Reset completed variable (progress bar)

                if (i === files.length - 1) {
                    this.saveStore(this.object);
                    progressFunction(0);
                }
                else {
                    progressFunction(1);
                }

            }

        } catch (err) {
            console.error(err);
        }
    };

    this.deleteStoreAttachment = async (attachment) => {
        if (this.object && attachment) {
            await Store.removeAttachFile(attachment);
            this.saveStore(this.object);
        }

    };


    this.searchText_onSearch = (event) => {
        this.searchDoc(event);
    };

    return (
        <div style={{ 'height': 'auto' }} key={this.keyReload}>
            <Grid xs={12} container direction="row" spacing={2}>
                <Grid xs={12}>
                    {
                        <CustomDropzone
                            onDropAction={this.onDropActionAttachFiles}
                            completed={props.completed.completedAttachedFiles}
                            inactiveLabel={"Arraste arquivos para cá ou clique para anexar!"}
                            activeLabel={"Solte a imagem de capa"}
                        />}

                </Grid>
                <Grid xs={12}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "10px 30px 10px 20px",
                        }}
                    >
                        <div style={{ paddingRight: "10px" }}>
                            <Button variant="contained" onClick={this.refreshObjects}>
                                <i
                                    className="fa fa-fw fa-refresh"
                                    style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                                />
                            </Button>
                        </div>
                        <div style={{ flex: "1" }}>
                            <SearchTextFieldWithFilter
                                placeholder={"Nome do documento"}
                                onSearch={this.searchText_onSearch}
                            ></SearchTextFieldWithFilter>
                        </div>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <div style={{ flex: "1" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    position: "relative",
                                    display: "flex",
                                }}
                            >
                                <Grid container>
                                    <Grid xs={12} >
                                        <PerfectScrollbar
                                            style={{
                                                width: "100%",
                                                height: "40vh",
                                                overflow: "hidden",
                                                position: "absolute",
                                            }}
                                            options={{ suppressScrollX: "true" }}
                                        >
                                            {this.objectAttachList
                                                .map((object, index) => {
                                                    let docType = object.store_document_name.split('.').pop();
                                                    let docIcon = null;
                                                    let colorIcon = null;
                                                    if (docType == "doc" || docType == "docx" || docType == "txt" || docType == "rtf" || docType == "ott") {
                                                        docIcon = faFileWord;
                                                        colorIcon = 'blue';
                                                    }
                                                    if (docType == "xlsx" || docType == "ods" || docType == "csv" || docType == "xls") {
                                                        docIcon = faFileExcel;
                                                        colorIcon = 'green';
                                                    }
                                                    if (docType == "pdf" || docType == "html" || docType == "json" || docType == "xml") {
                                                        docIcon = faFilePdf;
                                                        colorIcon = 'red';
                                                    }
                                                    if (docType == "pptx" || docType == "ppt" || docType == "odp") {
                                                        docIcon = faFilePowerpoint;
                                                        colorIcon = 'orange';
                                                    }
                                                    if (docType == "jpg" || docType == "jpeg" || docType == "png") {
                                                        docIcon = faFileImage;
                                                        colorIcon = '#000';
                                                    }
                                                    if (docType == "gif" || docType == "mkv" || docType == "avi" || docType == "wmv"
                                                        || docType == "mov" || docType == "rm" || docType == "rmvb" || docType == "amv" || docType == "mp4"
                                                        || docType == "mpg" || docType == "mpeg" || docType == "svi") {
                                                        docIcon = faFileVideo;
                                                        colorIcon = '#000';
                                                    }

                                                    return (
                                                        <div
                                                            style={{
                                                                marginBottom: "5px",
                                                                marginLeft: "20px",
                                                                marginRight: "20px",
                                                            }}
                                                        >
                                                            <div itemID={object.id_store_document}
                                                                style={{
                                                                    backgroundColor: "#bdbdbd",
                                                                    borderRadius: 30,
                                                                    padding: "10px"
                                                                }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: 'row',
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "center",
                                                                    marginLeft: "5px",

                                                                }}>
                                                                    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                                        <FontAwesomeIcon
                                                                            icon={docIcon ? docIcon : faFileAlt}
                                                                            style={{ fontSize: "1.75em", verticalAlign: "middle", marginRight: "5px" }}
                                                                            color={colorIcon ? colorIcon : '#000'}
                                                                        />
                                                                    </div>
                                                                    <div style={{ display: "flex", flex: 5, justifyContent: "center", alignItems: 'center' }}>
                                                                        <a href={object.store_document_file}
                                                                            target="_blank"
                                                                            style={{ textDecoration: 'none', color: '#000' }}>
                                                                            {object.store_document_name}
                                                                        </a>
                                                                    </div>
                                                                    <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                                        <Button onClick={() => this.deleteStoreAttachment(object)}>
                                                                            <FontAwesomeIcon
                                                                                icon={faTrash}
                                                                                style={{ fontSize: "1.20em", verticalAlign: "middle", marginLeft: "5px" }}
                                                                                color={"#CC0B0B"}
                                                                            />
                                                                        </Button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </PerfectScrollbar>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>


            </Grid>

        </div>

    );
}