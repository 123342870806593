import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/SaveAlt';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    button: {
        //margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        //marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

export default function DownloadButton(props) {

    const classes = useStyles();
    return (
        <IconButton
            onClick={props.onClickDownload}
            variant="contained"
            color="#FFF"
            className={classes.button}
            style={props.style}
            title={props.title? props.title :'Baixar respostas em Excel'}

        >
            {/*props.text ? props.text : "Baixar"*/}
            <SaveIcon style={{ color: "#FFF" }} />

        </IconButton>
    )
}