import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen'
// DB Models
import Product from '../../models/Product';
import Brand from '../../models/Brand'
import ProductCard from '../components/cards/ProductCard'
import ProductDescription from '../components/description_cards/ProductDescription'

class AdminProductScreen extends Component {

  editionTimeout = null;

  state = {
    products: [],
    relations: []
  };

  // TEXTS
  searchText_placeholder = 'Nome ou Código do Produto';
  addButton_placeholder = 'Produto';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    Product.query().then(products => {
      this.setState({ 'products': products });
    }).catch(err => {
      if(window.location.pathname !== '/') {
        window.location.href = '/';
      }
    });
    Brand.query().then(brands => {
      let relations = this.state.relations;
      relations[0] = brands;
      this.setState({ 'relations': relations });
    }).catch(err => {
      if(window.location.pathname !== '/') {
        window.location.href = '/';
      }
    });
  }

  addProduct = async () => {
    let newProduct = new Product();
    let productObj = {};
    try {
      productObj = await newProduct.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let productList = this.state.products;
    productList.push(productObj);
    this.setState({ 'products': productList });
    return productObj;
  };

  updateProduct = (productObj, field, value, force) => {
    let that = this;
    let productList = this.state.products;
    let position = productList.findIndex((obj) => {
      return obj.id_product === productObj.id_product;
    });
    let productToUpdate = productList.find((obj) => {
      return obj.id_product === productObj.id_product;
    });

    if (!field) {
      for (let prop in value) {
        if(value.hasOwnProperty(prop)) {
          productToUpdate[prop] = value[prop];
        }
      }
    } else {
      productToUpdate[field] = value;
    }

    // Timeout: Wait 500 milliseconds before sending API POST call to Server
    if (this.editionTimeout) clearTimeout(this.editionTimeout);

    function uploadProduct() {
      if (productToUpdate != null) {
        productToUpdate.save().then(productObj => {
          // Product upload to server worked
          productList[position] = productObj;
          that.setState({ 'products': productList });
        }).catch(err => {
          console.error(err);
        });
      }
    }

    if (force) uploadProduct();
    else {
      this.editionTimeout = setTimeout(() => {
        uploadProduct()
      }, 500);
    }
    this.setState({'products': productList});
    return productList[position];
  };

  deleteProduct = (productObj) => {
    let productList = this.state.products;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < productList.length; i++) {
      if (productObj.id_product === productList[i].id_product) {
        productList[i].remove();
        productList.splice(i, 1);
        break;
      }
    }

    this.setState({ 'products': productList });
  };

  render() {
    return (
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.products}
        objectCard={ProductCard}
        objectDescription={ProductDescription}
        addObject={this.addProduct}
        updateObject={this.updateProduct}
        deleteObject={this.deleteProduct}
        relations={this.state.relations}
        objectIdName={"id_product"}>
      </GenericAdminScreen>
    )
  }
}

export default AdminProductScreen;