import React, { Component, Text } from "react";
import GenericAdminScreen from "../components/generic_screens/GenericAdminScreen";
import Button from "@material-ui/core/Button";
// DB Models
import PerfectScrollbar from "react-perfect-scrollbar";
import { CustomDropzone } from "../components/CustomDropzone";
import App from "../../models/App";
import Brand from "../../models/Brand";
import BackgroundImage from "../../models/BackgroundImage";
import TutorialImage from "../../models/TutorialImage";
import Select from "react-select";
import SearchableSelect from "../components/selectbox/SearchableSelect";
import UserProperty from "../../models/UserProperty";
import { Grid, Paper, TextField, Box, Checkbox } from "@material-ui/core";
import DeleteButton from "../components/buttons/DeleteButton";
import { toast } from "react-toastify";

class AdminAppScreen extends Component {
  editionTimeout = null;

  state = {
    app: {},
    background_images: [],
    completedBackgroundImagesArray: [],
    tutorial_images: [],
    completedTutorialImagesArray: [],
    user_properties: [],
  };

  componentDidMount() {
    App.get(localStorage.getItem("ID_APP"))
      .then((app) => {
        this.setState({ app: app });
      })
      .catch((err) => {
        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
      });
    BackgroundImage.query()
      .then((background_images) => {
        this.setState({ background_images: background_images });
      })
      .catch((err) => {
        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
      });
    TutorialImage.query()
      .then((tutorial_images) => {
        tutorial_images = tutorial_images.sort((el1, el2) => {
          return el1.tutorial_image_order - el2.tutorial_image_order;
        });
        this.setState({ tutorial_images: tutorial_images });
      })
      .catch((err) => {
        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
      });
    UserProperty.query()
      .then((user_properties) => {
        this.setState({ user_properties: user_properties });
      })
      .catch((err) => {
        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
      });
  }

  onClickSaveAdmin  (appState) {
    let app = appState;
    let tutorial_images = this.state.tutorial_images;

    async function  uploadTutorialImage  (app) {
      if (app) {
        app.save();
        await TutorialImage.updateOrder(tutorial_images)
      }
    }
    if (this.editionTimeout) clearTimeout(this.editionTimeout);
    this.editionTimeout = setTimeout(() => {
      uploadTutorialImage(app);
    }, 500);
  }

  onChangeUpdateApp = (event, field, isCheckbox = false) => {
    let app = this.state.app;
    app[field] =
      event && event.target
        ? !isCheckbox
          ? event.target.value !== ""
            ? event.target.value
            : null
          : event.target.checked
        : null;
    this.setState({ app: app });
    return app;
  };

  brandChosen = (brand, deleteBrand) => {
    if (
      brand &&
      brand.brand_name !== null &&
      brand.brand_name !== "" &&
      brand.brand_name !== undefined
    ) {
      let app = this.state.app;
      app.brands.pop();
      app.brands.push(brand);
      this.setState({ app: app });
      //clear
      // console.log(app, "app 1");
      // console.log(brand, "brand");
      // console.log(deleteBrand, "new Brand");

      const result = app.save();
      // console.log(app, "app");
      if (!!result === true) {
        toast.success(
          ` Marca   ${brand.brand_name}
         adicionada
         com sucesso`
        );
      } else {
        toast.error("error ao adicionar marcar");
      }
    } else if (deleteBrand !== undefined && deleteBrand !== null) {
      // console.log("deletar");
      // console.log(deleteBrand);
      this.deleteBrand(deleteBrand, true);
    } else {
      //clear

      // console.log(brand, "brand");
      // console.log(deleteBrand);
    }
  };

  deleteBrand = (brand, addCampo) => {
    //clear

    // console.log("brands aqui");
    // console.log(brand);
    if (addCampo) {
      let app = this.state.app;
      app.brands = app.brands.filter((appBrand) => {
        return appBrand.id_brand !== brand.id_brand;
      });


      app.brands.push({});
      this.setState({ app: app });
      const result = app.save();
      if (!!result === true) {
        toast.success("Marca deletada com sucesso.");
      } else {
        toast.error("error ao deletar marcar");
      }
    } else if (window.confirm("Deseja remover essa marca?")) {
   
      let app = this.state.app;
      app.brands = app.brands.filter((appBrand) => {
        return appBrand.id_brand !== brand.id_brand;
      });
      this.setState({ app: app });
      const result = app.save();
      if (!!result === true) {
        toast.success("Marca deletada com sucesso.");
      } else {
        toast.error("error ao deletar marcar");
      }
    }
  };

  onSelectChangeUserProperty = (event, idx) => {
    let value = event.value;
    let user_properties = this.state.user_properties;
    user_properties[idx].property_type = value;
    this.setState({ user_properties: user_properties });
    user_properties[idx].save();
  };

  onChangeUpdateUserProperty = async (event, field, idx) => {
    let value;
    if (field !== "is_mandatory") {
      value = event && event.target ? event.target.value : null;
    } else {
      value = event && event.target ? event.target.checked : false;
    }
    if (this.editionTimeout) clearTimeout(this.editionTimeout);
    let user_properties = this.state.user_properties;
    user_properties[idx][field] = value;

    let that = this;

    function uploadUserProperty(user_property, idx) {
      if (user_property !== null && user_property.id_user_property) {
        user_property.save();
      } else {
        user_property.save().then((user_property) => {
          let user_propertiesList = that.state.user_properties;
          user_propertiesList[idx] = user_property;
          that.setState({ user_properties: user_propertiesList });
        });
      }
    }

    if (this.editionTimeout) clearTimeout(this.editionTimeout);

    this.editionTimeout = setTimeout(() => {
      uploadUserProperty(user_properties[idx], idx);
    }, 500);
    this.setState({ user_properties: user_properties });

    return user_properties;
  };

  onChangeUpdateBackgroundImage = (field, idx) => (event) => {
    let value = event && event.target ? event.target.value : null;
    let background_images = this.state.background_images;
    background_images[idx][field] = value;
    this.setState({ background_images: background_images });

    function uploadBackgroundImage(background_image) {
      background_image.save();
    }

    if (this.editionTimeout) clearTimeout(this.editionTimeout);

    this.editionTimeout = setTimeout(() => {
      uploadBackgroundImage(background_images[idx]);
    }, 500);
  };

  onChangeUpdateTutorialImage =
    (field, id, pos = 0) =>
    (event) => {
      let value = event && event.target ? event.target.value : null;
      value= parseInt(value)
      let tutorial_images = this.state.tutorial_images;
      let modifyImage = tutorial_images.filter(
        (imgs) => imgs.id_tutorial_image === id
      );

      modifyImage[0][field] = value;
      tutorial_images = tutorial_images.map((imgs) =>
        imgs.id_tutorial_image !== id ? imgs : modifyImage[0]
      );

      this.setState({ tutorial_images: tutorial_images });
      return tutorial_images;
    };

  onDropActionBackgroundImage = async (file, idx) => {
    file = file[0];
    let background_images = this.state.background_images;

    const progressFunction = (completed) => {
      let completedBackgroundImagesArray =
        this.state.completedBackgroundImagesArray;
      completedBackgroundImagesArray[idx] = completed;
      this.setState({
        completedBackgroundImagesArray: completedBackgroundImagesArray,
      });
    };

    try {
      let imageUrl = await BackgroundImage.sendBackgroundImageFile(
        file,
        background_images[idx].id_background_image,
        progressFunction
      );
      let imageToUpdate = {
        background_image_filename: imageUrl.url,
        id_background_image: imageUrl.id_background_image,
      };
      background_images[idx].background_image_filename = imageUrl.url;
      this.setState({ background_images: background_images });
      // Reset completed variable (progress bar)
    } catch (err) {
      console.error(err);
    }
  };

  onDropActionTutorialImage = async (
    file,
    idx,
    data = null,
    posTutorial = null
  ) => {
    file = file[0];
    let tutorial_images = this.state.tutorial_images;
    const progressFunction = (completed) => {
      let completedTutorialImagesArray =
        this.state.completedTutorialImagesArray;
      completedTutorialImagesArray[idx] = completed;
      this.setState({
        completedTutorialImagesArray: completedTutorialImagesArray,
      });
    };

    try {
      let imageUrl = await TutorialImage.sendTutorialImageFile(
        file,
        tutorial_images[idx].id_tutorial_image,
        progressFunction,
        tutorial_images[idx].is_register_tutorial
      );
      let imageToUpdate = {
        tutorial_image_filename: imageUrl.url,
        id_tutorial_image: imageUrl.id_tutorial_image,
      };
      tutorial_images[idx].tutorial_image_filename = imageUrl.url;
      tutorial_images[idx].id_tutorial_image = imageUrl.id_tutorial_image;
      this.setState({
        tutorial_images,
      });
      // Reset completed variable (progress bar)
      //voltar aqui
      this.state.app.save();
    } catch (err) {
      console.error(err);
    }
  };

  getUserProperties = () => {
    let result =
      this.state.user_properties.length > 0
        ? this.state.user_properties.map((user_property, idx) => {
            return (
              <Grid
                xs={12}
                spacing={2}
                padding={2}
                style={{ padding: "20px" }}
                direction={"row"}
                container
              >
                <Grid item xs={3} style={{ padding: "20px" }}>
                  <TextField
                    label={"Nome do Campo"}
                    onChange={(event) =>
                      this.onChangeUpdateUserProperty(
                        event,
                        "property_name",
                        idx
                      )
                    }
                    disabled={this.state.isSaving}
                    style={{ width: "100%" }}
                    value={
                      user_property.property_name
                        ? user_property.property_name
                        : ""
                    }
                  />
                </Grid>
                <Grid xs={3} container style={{ padding: "20px" }}>
                  <Grid xs={12}>
                    <h>
                      <span
                        style={{
                          float: "left",
                          color: "rgba(0, 0, 0, 0.54)",
                          padding: "0",
                          fontSize: "13px",
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          fontWeight: "400",
                          lineHeight: "1",
                          letterSpacing: "0.00938em",
                        }}
                      >
                        Tipo:
                        {this.state.user_properties[idx]
                          .property_type ? null : (
                          <span style={{ color: "rgba(255, 0, 0, 1)" }}>
                            (sem tipo o campo não aparecerá no aplicativo)
                          </span>
                        )}
                      </span>
                    </h>
                  </Grid>
                  <Grid xs={12}>
                    <Select
                      placeholder={"Selecione o Tipo"}
                      options={[
                        { value: "TEXT", label: "Texto" },
                        { value: "DATE", label: "Data" },
                      ]}
                      defaultValue={
                        user_property.property_type
                          ? {
                              value: user_property.property_type,
                              label:
                                user_property.property_type === "TEXT"
                                  ? "Texto"
                                  : user_property.property_type === "DATE"
                                  ? "Data"
                                  : "Indefinido",
                            }
                          : null
                      }
                      onChange={(event) => {
                        this.onSelectChangeUserProperty(event, idx);
                      }}
                    ></Select>
                  </Grid>
                </Grid>
                <Grid xs={3} container style={{ padding: "20px" }}>
                  <Grid xs={12}>
                    <Checkbox
                      onChange={(event) => {
                        this.onChangeUpdateUserProperty(
                          event,
                          "is_mandatory",
                          idx
                        );
                      }}
                      checked={user_property.is_mandatory}
                    />
                    Obrigatória
                  </Grid>
                </Grid>
                <Grid xs={3} container style={{ padding: "20px" }}>
                  <Grid xs={12}>
                    <DeleteButton
                      onClickDelete={() => {
                        if (window.confirm("Deletar propriedade?")) {
                          let user_properties = this.state.user_properties;
                          if (
                            JSON.stringify(
                              user_properties[idx] === JSON.stringify({})
                            )
                          ) {
                            user_properties[idx].remove();
                          }
                          user_properties.splice(idx, 1);
                          this.setState({ tutorial_images: user_properties });
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        : null;
    return result;
  };

  getBackgroundImages = () => {
    if (
      this.state.background_images &&
      this.state.background_images.length > 0
    ) {
      let result = this.state.background_images.map((background_image, idx) => {
        return (
          <Box style={{ height: "800px", width: "400px" }} key={idx + 1}>
            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: "25px",
              }}
              direction={"column"}
            >
              <TextField
                style={{ width: "100%", paddingBottom: "20px" }}
                label={`Citação da Imagem ${idx + 1}`}
                value={
                  background_image.background_image_quote
                    ? background_image.background_image_quote
                    : ""
                }
                onChange={this.onChangeUpdateBackgroundImage(
                  "background_image_quote",
                  idx
                )}
                disabled={
                  !this.state.background_images[idx].background_image_filename
                }
              ></TextField>
              <div
                style={{
                  height: "700px",
                  width: "360px",
                  paddingBottom: "20px",
                }}
              >
                {background_image.background_image_filename ? (
                  <img
                    src={background_image.background_image_filename}
                    style={{ height: "640px", width: "360px" }}
                  />
                ) : (
                  <div style={{ height: "640px", width: "360px" }}>
                    <CustomDropzone
                      onDropAction={(file) => {
                        this.onDropActionBackgroundImage(file, idx);
                      }}
                      completed={
                        this.state.completedBackgroundImagesArray &&
                        this.state.completedBackgroundImagesArray.length > 0 &&
                        this.state.completedBackgroundImagesArray[idx]
                          ? this.state.completedBackgroundImagesArray[idx]
                          : 0
                      }
                      inactiveLabel={"Arraste a imagem para cá.."}
                      activeLabel={"Solte a imagem"}
                    />
                    <Grid xs={12}>
                      <span
                        style={{
                          fontSize: "12px",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: 400,
                          lineHeight: 1,
                          letterSpacing: "0.00938em",
                          float: "bottom",
                          color: "rgba(0, 0, 0, 0.54)",
                          paddingBottom: "10px",
                        }}
                      >
                        Tamanho Máximo da Imagem: 10 Mb
                        
                      </span>
                    </Grid>
                  </div>
                )}
                <DeleteButton
                  onClickDelete={() => {
                    if (window.confirm("Deletar imagem de fundo?")) {
                      let background_images = this.state.background_images;
                      if (
                        JSON.stringify(
                          background_images[idx] === JSON.stringify({})
                        )
                      ) {
                        background_images[idx].remove();
                      }
                      background_images.splice(idx, 1);
                      this.setState({ background_images: background_images });
                    }
                  }}
                />
                {background_image.background_image_quote &&
                background_image.background_image_quote != "" ? (
                  <div
                    style={{
                      position: "relative",
                      maxWidth: "200px",
                      right: "-20px",
                      bottom: "500px",
                      flexWrap: "wrap",
                      display: "flex",
                      wordBreak: "break-word",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "white",
                        float: "left",
                        fontSize: "18px",
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: "400",
                        lineHeight: "1",
                        letterSpacing: "0.00938em",
                        padding: "5px",
                        textAlign: "left",
                      }}
                    >
                      {background_image.background_image_quote}
                    </span>
                  </div>
                ) : null}
              </div>
            </Grid>
          </Box>
        );
      });
      return result;
    }
  };
  // dan
  getTutorialImages = (isRegister = null) => {
    //if dan
    if (
      this.state.tutorial_images &&
      this.state.tutorial_images.length > 0 &&
      isRegister === 1
    ) {
      let result = this.state.tutorial_images;
      let tutorialLength = result.filter(
        (itens) => itens.is_register_tutorial !== 1
      );

      tutorialLength = tutorialLength.length;
      tutorialLength = tutorialLength > 0 ? tutorialLength : 0;
      result = result.filter((itens) => itens.is_register_tutorial);
      result = result.map((tutorial_image, idx) => {
        return (
          <Box style={{ height: "800px", width: "400px" , paddingBottom:'20px'}} key={idx + 1}>
            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: "25px",
              }}
              direction={"column"}
            >
              <TextField
                style={{ width: "100%", paddingBottom: "20px" }}
                label={`Ordem da Imagem ${idx + 1}`}
                value={
                  tutorial_image.tutorial_image_order
                    ? tutorial_image.tutorial_image_order
                    : ""
                }
       
                onChange={this.onChangeUpdateTutorialImage(
                  "tutorial_image_order",
                  tutorial_image.id_tutorial_image
                )}

                disabled={
                  !this.state.tutorial_images[idx].tutorial_image_filename
                }
                type={"number"}
              ></TextField>
              <div
                style={{
                  height: "700px",
                  width: "360px",
                  paddingBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {tutorial_image.tutorial_image_filename ? (
                  <img
                    src={tutorial_image.tutorial_image_filename}
                    style={{ height: "640px", width: "360px" }}
                  />
                ) : (
                  <div
                    style={{
                      height: "640px",
                      width: "360px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CustomDropzone
                      onDropAction={(file) => {
                        this.onDropActionTutorialImage(
                          //dan manuteção
                          file,
                          idx + tutorialLength,
                          result,
                          true
                        );
                      }}
                      completed={
                        this.state.completedBackgroundImagesArray &&
                        this.state.completedBackgroundImagesArray.length > 0 &&
                        this.state.completedBackgroundImagesArray[idx]
                          ? this.state.completedBackgroundImagesArray[idx]
                          : 0
                      }
                      inactiveLabel={"Arraste a imagem para cá pos"}
                      activeLabel={"Solte a imagem"}
                    />
                    <Grid xs={12}>
                      <span
                        style={{
                          fontSize: "12px",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: 400,
                          lineHeight: 1,
                          letterSpacing: "0.00938em",
                          float: "bottom",
                          color: "rgba(0, 0, 0, 0.54)",
                          paddingBottom: "10px",
                        }}
                      >
                        Tamanho Máximo da Imagem: 10 Mb
                        <br/>
                        Dimensões recomendadas (1920 x 1080)

                      </span>
                    </Grid>
                  </div>
                )}
                <div>
                  <DeleteButton
                    onClickDelete={() => {
                      if (window.confirm("Deletar imagem de tutorial?")) {
                        let id = tutorial_image.id_tutorial_image;
                        let tutorial_images = this.state.tutorial_images;
                        let removeImg = tutorial_images.filter(
                          (itens) => itens.id_tutorial_image === id
                        );
                        removeImg[0].remove();
                        tutorial_images = tutorial_images.filter(
                          (itens) => itens.id_tutorial_image !== id
                        );
                        this.setState({ tutorial_images: tutorial_images });
                      }
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Box>
        );
      });
      return result;
    }
    // be else
    else if (
      this.state.tutorial_images &&
      this.state.tutorial_images.length > 0
      // && !isRegister !==1
      //pos tutorial
    ) {
      let result = this.state.tutorial_images;
      let tutorialLength = result.filter(
        (itens) => itens.is_register_tutorial === 1
      );
      result = result.filter((itens) => itens.is_register_tutorial !== 1);
      tutorialLength = tutorialLength.length;
      tutorialLength = tutorialLength > 0 ? tutorialLength : 0;
      result = result.map((tutorial_image, idx) => {
        return (
          <Box style={{ height: "800px", width: "400px" ,paddingBottom:'20px' }} key={idx + 1}>
            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: "25px",
              }}
              direction={"column"}
            >
              <TextField
                style={{ width: "100%", paddingBottom: "20px" }}
                label={`Ordem da Imagem ${idx + 1}`}
                value={
                  tutorial_image.tutorial_image_order
                    ? tutorial_image.tutorial_image_order
                    : ""
                }
                onChange={this.onChangeUpdateTutorialImage(
                  "tutorial_image_order",
                  tutorial_image.id_tutorial_image
                )}
                type={"number"}
              ></TextField>
              {/* <p>{tutorial_image.id_tutorial_image}</p> */}
              <div
                style={{
                  height: "700px",
                  width: "360px",
                  paddingBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingBottom:'20px'
                }}
              >
                {tutorial_image.tutorial_image_filename ? (
                  <img
                    src={tutorial_image.tutorial_image_filename}
                    style={{ height: "640px", width: "360px" }}
                  />
                ) : (
                  <div
                    style={{
                      height: "640px",
                      width: "360px",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CustomDropzone
                      onDropAction={(file) => {
                        this.onDropActionTutorialImage(
                          file,
                          idx + tutorialLength,
                          result
                        );
                      }}
                      completed={
                        this.state.completedBackgroundImagesArray &&
                        this.state.completedBackgroundImagesArray.length > 0 &&
                        this.state.completedBackgroundImagesArray[idx]
                          ? this.state.completedBackgroundImagesArray[idx]
                          : 0
                      }
                      inactiveLabel={"Arraste a imagem para cá ."}
                      activeLabel={"Solte a imagem"}
                    />
                    <Grid xs={12}>
                      <span
                        style={{
                          fontSize: "12px",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: 400,
                          lineHeight: 1,
                          letterSpacing: "0.00938em",
                          float: "bottom",
                          color: "rgba(0, 0, 0, 0.54)",
                          paddingBottom: "10px",
                        }}
                      >
                           Tamanho Máximo da Imagem: 10 Mb
                        <br/>
                        Dimensões recomendadas (1920 x 1080)                    
                          </span>
                    </Grid>
                  </div>
                )}
                <div>
                  <DeleteButton
                    onClickDelete={() => {
                      if (window.confirm("Deletar imagem de tutorial?")) {
                        let id = tutorial_image.id_tutorial_image;
                        let tutorial_images = this.state.tutorial_images;
                        let removeImg = tutorial_images.filter(
                          (itens) => itens.id_tutorial_image === id
                        );
                        removeImg[0].remove();
                        tutorial_images = tutorial_images.filter(
                          (itens) => itens.id_tutorial_image !== id
                        );
                        this.setState({ tutorial_images: tutorial_images });
                      }
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Box>
        );
      });
      return result;
    }
  };
  //dan2

  render() {
    return (
      <PerfectScrollbar>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Paper style={{ width: "100%" }}>
            <Grid container spacing={2} padding={2} style={{ padding: "20px" }}>
              {/* aqui */}
              <Grid xs={12}>
                <b>Configurações Gerais do Aplicativo</b>
              </Grid>
              {localStorage.getItem("ID_APP") == 4 ||
              localStorage.getItem("ID_APP") == 11 ||
              localStorage.getItem("ID_APP") == 12 ||
              localStorage.getItem("ID_APP") == 14 ||
              localStorage.getItem("ID_APP") == 22 ||
              localStorage.getItem("ID_APP") == 26 ||
              localStorage.getItem("ID_APP") == 27 ||
              localStorage.getItem("ID_APP") == 29 ||
              localStorage.getItem("ID_APP") == 30 ||
              localStorage.getItem("ID_APP") == 32 ||
              localStorage.getItem("ID_APP") == 33 ||
              localStorage.getItem("ID_APP") == 34 ||
              localStorage.getItem("ID_APP") == 35 ||
              localStorage.getItem("ID_APP") == 36 ||
              localStorage.getItem("ID_APP") == 37 ||
              localStorage.getItem("ID_APP") == 38 ||
              localStorage.getItem("ID_APP") == 39 ||
              localStorage.getItem("ID_APP") == 40 ||
              localStorage.getItem("ID_APP") == 41 ? null : (
                <Grid
                  xs={12}
                  container
                  spacing={2}
                  padding={2}
                  style={{ paddingBottom: 20, padding: "20px" }}
                >
                  <span
                    style={{
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                      paddingTop: "5px",
                      paddingLeft: "-40px",
                    }}
                  >
                    Marcas:
                  </span>
                  <Grid
                    xs={12}
                    container
                    style={{ padding: "20px" }}
                    direction={"row"}
                    spacing={2}
                    padding={2}
                  >
                    {this.state.app.brands && this.state.app.brands.length > 0
                      ? this.state.app.brands.map((brand) => {
                          return (
                            <Grid xs={12} direction={"row"} container>
                              {" "}
                              <Grid
                                xs={5}
                                style={{
                                  paddingTop: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                <SearchableSelect
                                  placeholder={"Selecione a Marca"}
                                  searchApiUrl={"/brand/select-full-brand"}
                                  actionOnSelectedOption={(value) => {
                                    this.brandChosen(value, brand);
                                  }}
                                  defaultValue={{
                                    value: brand,
                                    // bug aqui
                                    label: brand.brand_name,
                                    // ? brand.brand_name
                                    // : "",
                                  }}
                                />
                              </Grid>
                              <Grid
                                xs={5}
                                style={{
                                  paddingTop: "12px",
                                  paddingLeft: "20px",
                                }}
                              >
                                <DeleteButton
                                  onClickDelete={() => {
                                    this.deleteBrand(brand);
                                  }}
                                ></DeleteButton>
                              </Grid>
                            </Grid>
                          );
                        })
                      : null}
                    <Grid xs={12} style={{ paddingTop: "20px" }}>
                      <div
                        style={{
                          height: 45,
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          style={{
                            height: 45,
                            width: "40%",
                            backgroundColor: "#4498A7",
                            color: "white",
                          }}
                          variant={"contained"}
                          onClick={() => {
                            let app = this.state.app;
                            app.brands.push({});
                            this.setState({ app: app });
                          }}
                        >
                          + Adicionar Marca
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* //fim do marcas */}
              <Grid
                xs={12}
                container
                spacing={2}
                padding={2}
                style={{ paddingBottom: 20, padding: "20px" }}
              >
                <span
                  style={{
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "0",
                    fontSize: "13px",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: "400",
                    lineHeight: "1",
                    letterSpacing: "0.00938em",
                    paddingTop: "5px",
                    paddingLeft: "-40px",
                  }}
                >
                  E-Mails:
                </span>
                <Grid
                  xs={12}
                  container
                  style={{ padding: "20px" }}
                  direction={"row"}
                  spacing={2}
                  padding={2}
                >
                  <Grid
                    xs={5}
                    style={{ paddingTop: "20px", paddingRight: "20px" }}
                  >
                    <TextField
                      label={"E-mail para Envio"}
                      onChange={(event) => {
                        this.onChangeUpdateApp(event, "email_from");
                      }}
                      style={{ width: "100%" }}
                      value={
                        this.state.app.email_from
                          ? this.state.app.email_from
                          : ""
                      }
                    />
                  </Grid>
                  <Grid
                    xs={5}
                    style={{ paddingTop: "20px", paddingLeft: "20px" }}
                  >
                    <TextField
                      label={"E-mail para Recebimento"}
                      onChange={(event) => {
                        this.onChangeUpdateApp(event, "email_to");
                      }}
                      style={{ width: "100%" }}
                      value={
                        this.state.app.email_to ? this.state.app.email_to : ""
                      }
                    />
                  </Grid>
                  <Grid
                    xs={5}
                    style={{ paddingTop: "20px", paddingRight: "20px" }}
                  >
                    <TextField
                      label={"E-mail de Suporte"}
                      onChange={(event) => {
                        this.onChangeUpdateApp(event, "email_support_to");
                      }}
                      style={{ width: "100%" }}
                      value={
                        this.state.app.email_support_to
                          ? this.state.app.email_support_to
                          : ""
                      }
                    />
                  </Grid>
                  <Grid
                    xs={5}
                    style={{ paddingTop: "20px", paddingLeft: "20px" }}
                  >
                    <TextField
                      label={"E-mail do App"}
                      onChange={(event) => {
                        this.onChangeUpdateApp(event, "app_email");
                      }}
                      style={{ width: "100%" }}
                      value={
                        this.state.app.app_email ? this.state.app.app_email : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                container
                spacing={2}
                padding={2}
                style={{ paddingBottom: 20, padding: "20px" }}
              >
                {this.state.app.requires_approval ? (
                  <>
                    <span
                      style={{
                        float: "left",
                        color: "rgba(0, 0, 0, 0.54)",
                        padding: "0",
                        fontSize: "13px",
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: "400",
                        lineHeight: "1",
                        letterSpacing: "0.00938em",
                        paddingTop: "5px",
                        paddingLeft: "-40px",
                      }}
                    >
                      Registro e Aprovação:
                    </span>
                  </>
                ) : null}
                <Grid
                  xs={12}
                  container
                  style={{ padding: "20px" }}
                  direction="row"
                  spacing={2}
                  padding={2}
                >
                  <Grid
                    xs={3}
                    style={{ paddingTop: "20px", paddingRight: "20px" }}
                  >
                    {/*
                    <Checkbox
                      onChange={(event) => {
                        this.onChangeUpdateApp(
                          event,
                          "requires_approval",
                          true
                        );
                      }}
                      checked={
                        this.state.app.requires_approval === 1 ? true : false
                      }
                    />{" "}
                    Requer Aprovação
                    */}
                  </Grid>
                  {this.state.app.requires_approval ? (
                    <Grid
                      xs={3}
                      style={{ paddingTop: "20px", paddingLeft: "20px" }}
                    >
                      <div>
                        <Checkbox
                          onChange={(event) => {
                            this.onChangeUpdateApp(
                              event,
                              "approve_after_register",
                              true
                            );
                          }}
                          checked={this.state.app.approve_after_register}
                        />{" "}
                        <text>Aprovação após registro</text>
                      </div>
                    </Grid>
                  ) : null}

                  {/* 
                  <Grid xs={4} style={{ padding: "20px", paddingLeft: "20px" }}>
                    <div
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        label={"Pontos por Registro"}
                        onChange={(event) => {
                          this.onChangeUpdateApp(event, "register_points");
                        }}
                        type={"number"}
                        value={
                          this.state.app.register_points
                            ? this.state.app.register_points
                            : 0
                        }
                      />
                    </div>
                  </Grid>
                  */}

                  {/* 
                  <Grid
                    xs={3}
                    style={{
                      paddingTop: "20px",
                      paddingRight: "20px",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        onChange={(event) => {
                          this.onChangeUpdateApp(event, "have_roles", true);
                        }}
                        checked={this.state.app.have_roles}
                      />{" "}
                      <text>Tem Cargos</text>
                    </div>
                  </Grid>
                  */}
                  {/* 
                  <Grid
                    xs={3}
                    style={{
                      paddingTop: "20px",
                      paddingLeft: "20px",
                      justifyContent: "center",
                    }}
                  >
                    {this.state.app.have_roles ? (
                      <div
                        style={{
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Checkbox
                          onChange={(event) => {
                            this.onChangeUpdateApp(
                              event,
                              "has_store_defined_role",
                              true
                            );
                          }}
                          checked={this.state.app.has_store_defined_role}
                        />{" "}
                        <text>Tem Cargos definidos pela Loja</text>
                      </div>
                    ) : null}
                  </Grid>
                  */}
                  <Grid xs={4} />
                </Grid>
              </Grid>
              {/*
              <Grid
                xs={12}
                container
                spacing={2}
                padding={2}
                style={{ paddingBottom: 20, padding: "20px" }}
              >
                <span
                  style={{
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "0",
                    fontSize: "13px",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: "400",
                    lineHeight: "1",
                    letterSpacing: "0.00938em",
                    paddingTop: "5px",
                    paddingLeft: "-40px",
                  }}
                >
                  Recompensas:
                </span>
                <Grid
                  xs={12}
                  container
                  direction="row"
                  spacing={2}
                  padding={2}
                  style={{ paddingBottom: 20, padding: "20px" }}
                >

                  <Grid xs={4}>
                    <div
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        label={"Cupons"}
                        onChange={(event) => {
                          this.onChangeUpdateApp(event, "normal_tickets");
                        }}
                        type={"number"}
                        value={
                          this.state.app.normal_tickets
                            ? this.state.normal_tickets
                            : 0
                        }
                      />
                    </div>
                  </Grid>
                  

                  <Grid xs={4}>
                    <div
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TextField
                        label={"Pontos"}
                        onChange={(event) => {
                          this.onChangeUpdateApp(event, "content_points");
                        }}
                        type={"number"}
                        value={
                          this.state.app.content_points
                            ? this.state.app.content_points
                            : 0
                        }
                      />
                    </div>
                  </Grid>
                  

                  <Grid xs={3} style={{ paddingTop: "20px", paddingLeft: "20px" }}>
                    <div>
                      <Checkbox
                        onChange={(event) => {
                          this.onChangeUpdateApp(event, "have_medals", true);
                        }}
                        checked={this.state.app.have_medals}
                      />
                      {"Tem medalhas"}
                      *<TextField
                        label={"Moedas"}
                        onChange={(event) => {
                          this.onChangeUpdateApp(event, "content_points");
                        }}
                        type={"number"}
                        value={
                          this.state.app.content_points
                            ? this.state.app.content_points
                            : 0
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              */}

              {/*
                <Grid xs={12} spacing={2} padding={2} container>
                  <span
                    style={{
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                      paddingTop: "5px",
                      paddingLeft: "8px",
                    }}
                  >
                    Campos de Cadastro Personalizado:
                  </span>
                  {this.getUserProperties()}
                </Grid>
              */}
              {/*
              <Grid
                xs={12}
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                  <Button
                    variant="contained"
                    className={"add-user-property"}
                    style={{
                      height: 45,
                      width: "40%",
                      backgroundColor: "#4498A7",
                      color: "white",
                    }}
                    onClick={() => {
                      let user_properties = this.state.user_properties;
                      user_properties.push(new UserProperty({}));
                      this.setState({ user_properties: user_properties });
                    }}
                  >
                    Adicionar Campo de Cadastro
                  </Button>
                
                
                  <Grid
                    xs={12}
                    container
                    spacing={2}
                    padding={2}
                    style={{ paddingBottom: 20, padding: "20px" }}
                  >
                    <span
                      style={{
                        float: "left",
                        color: "rgba(0, 0, 0, 0.54)",
                        padding: "0",
                        fontSize: "13px",
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: "400",
                        lineHeight: "1",
                        letterSpacing: "0.00938em",
                        paddingTop: "5px",
                        paddingLeft: "-40px",
                      }}
                    >
                      Cupom Fiscal:
                    </span>
                    <Grid
                      xs={12}
                      container
                      style={{ padding: "20px" }}
                      direction={"row"}
                      spacing={2}
                      padding={2}
                    >

                      <Grid xs={6} style={{ paddingTop: "20px" }}>
                        <TextField
                          label={"Cupons Necessários para 1 ticket normal"}
                          onChange={(event) => {
                            this.onChangeUpdateApp(
                              event,
                              "value_normal_tickets"
                            );
                          }}
                          type={"number"}
                          style={{ width: "100%" }}
                          value={this.state.app.value_normal_tickets}
                        />
                      </Grid>
                      <Grid
                        xs={5}
                        style={{ paddingLeft: "16px", paddingTop: "20px" }}
                      >
                        <TextField
                          label={"Cupons Necessários para 1 ticket golden"}
                          onChange={(event) => {
                            this.onChangeUpdateApp(
                              event,
                              "value_golden_tickets"
                            );
                          }}
                          type={"number"}
                          style={{ width: "100%" }}
                          value={this.state.app.value_golden_tickets}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
              </Grid>
              */}
              {/* 
              {localStorage.getItem("ID_APP") == 1 ? null : (
                <Grid xs={6}>
                  <span
                    style={{
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                      paddingTop: "5px",
                      paddingLeft: "8px",
                    }}
                  >
                    Imagens de Fundo da Tela Inicial:
                  </span>
                </Grid>
              )}
              <Grid xs={6} />
              <Grid
                container
                xs={12}
                style={{
                  paddingTop: "5px",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  display={"flex"}
                  css={{ height: "100%", width: "100%" }}
                  p={1}
                  m={1}
                  flexWrap={"wrap"}
                >
                  {this.getBackgroundImages()}
                </Box>
              </Grid>
              {localStorage.getItem("ID_APP") == 1 ? null : (
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "40px",
                    justifyContent: "center",
                    paddingBottom: "40px",
                  }}
                >
                  <Button
                    onClick={() => {
                      let background_images = this.state.background_images;
                      background_images.push(new BackgroundImage({}));
                      this.setState({ background_images: background_images });
                    }}
                    variant="contained"
                    className={"add-background-image"}
                    style={{
                      height: 45,
                      width: "40%",
                      backgroundColor: "#4498A7",
                      color: "white",
                    }}
                  >
                    + Adicionar Imagem de Fundo
                  </Button>
                </div>
              )}
              */}

              <Grid xs={6}>
                <span
                  style={{
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "0",
                    fontSize: "13px",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: "400",
                    lineHeight: "1",
                    letterSpacing: "0.00938em",
                    paddingTop: "5px",
                    paddingLeft: "8px",
                  }}
                >
                  Imagens de Tutorial: Dimensões (1920 x 1080)
                </span>
              </Grid>
              <Grid xs={6} />
              {/* //da */}
              <Grid
                container
                xs={12}
                style={{
                  paddingTop: "5px",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  display={"flex"}
                  css={{ height: "100%", width: "100%" }}
                  p={1}
                  m={1}
                  flexWrap={"wrap"}
                >
                  {this.getTutorialImages()}
                </Box>
              </Grid>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  height: "40px",
                  justifyContent: "center",
                  paddingBottom: "40px",
                }}
              >
                <Button
                  onClick={() => {
                    let tutorial_images = this.state.tutorial_images;
                    let NewTutorial = new TutorialImage({});
                    NewTutorial.is_register_tutorial = 0;
                    tutorial_images.push(NewTutorial);
                    this.setState({ tutorial_images });
                  }}
                  variant="contained"
                  className={"add-background-image"}
                  style={{
                    height: 45,
                    width: "40%",
                    backgroundColor: "#4498A7",
                    color: "white",
                  }}
                >
                  + Adicionar Imagem de Tutorial
                </Button>
              </div>
            </Grid>
            {localStorage.getItem("ID_APP") == 4 && (
              <>
                <Grid xs={6}>
                  <span
                    style={{
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                      paddingTop: "5px",
                      paddingLeft: "8px",
                    }}
                  >
                    Imagens de Pós Tutorial:
                  </span>
                </Grid>
                <Grid xs={6} />
                {/* //da */}
                <Grid
                  container
                  xs={12}
                  style={{
                    paddingTop: "5px",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    display={"flex"}
                    css={{ height: "100%", width: "100%" }}
                    p={1}
                    m={1}
                    flexWrap={"wrap"}
                  >
                    {this.getTutorialImages(1)}
                  </Box>
                </Grid>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "40px",
                    justifyContent: "center",
                    paddingBottom: "40px",
                  }}
                >
                  <Button
                    onClick={() => {
                      let tutorial_images = this.state.tutorial_images;
                      let NewTutorial_images = new TutorialImage({});
                      NewTutorial_images.is_register_tutorial = 1;
                      tutorial_images.push(NewTutorial_images);
                      this.setState({ tutorial_images: tutorial_images });
                    }}
                    variant="contained"
                    className={"add-background-image"}
                    style={{
                      height: 45,
                      width: "40%",
                      backgroundColor: "#4498A7",
                      color: "white",
                    }}
                  >
                    + Adicionar Imagem pos Tutorial
                  </Button>
                </div>
              </>
            )}
            <Button
              onClick={(event) => {
                if (window.confirm("Deseja salvar todas as alterações?")) {
                  this.onClickSaveAdmin(this.state.app);
                  toast.success("Configurações salva com sucesso!");
                }
              }}
              variant="contained"
              className={"save-admin-changes"}
              style={{
                height: 45,
                width: "40%",
                backgroundColor: "green",
                color: "white",
                marginBottom: "10px",
              }}
            >
              Salvar Configurações
            </Button>
            {/* //dan */}
          </Paper>
        </div>
      </PerfectScrollbar>
    );
  }
}

export default AdminAppScreen;

