import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CustomIconButton } from '../buttons/ThemeButtons';

export const TextInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#405066',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#94A1B2',
      },
      '&:hover fieldset': {
        borderColor: '#405066',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#405066',
      },
    },
  },
})(TextField);

const StyledFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#405066',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#94A1B2',
      },
      '&:hover fieldset': {
        borderColor: '#405066',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#405066',
      },
    },
  },
})(FormControl);

export const SearchInput = ({ onChange, value, clear, onBlur }) => {
  return (
    <StyledFormControl variant="outlined" size='small' style={{width: '100%'}}>
      <InputLabel htmlFor="outlined-adornment-password">Digite sua busca</InputLabel>
      <OutlinedInput
        id="search-input"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        endAdornment={
          <InputAdornment position="end">
            <CustomIconButton
              onClick={clear}
              edge="end"
            >
              <CloseIcon />  
            </CustomIconButton>
          </InputAdornment>
        }
        label="Digite sua busca"
      />
    </StyledFormControl>
  );
}

export const ChatInput = ({ onChange, value, clear, onBlur, handleSend, helperText }) => {
  return (
    <StyledFormControl variant="outlined" style={{width: '100%'}}>
      <InputLabel htmlFor="chat-input">Escreva um comentário</InputLabel>
      <OutlinedInput
        id="chat-input"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder="Você pode sugerir uma melhoria na foto"
        endAdornment={
          <InputAdornment position="end">
            <CustomIconButton
              onClick={handleSend}
              edge="end"
              sx={{marginRight: 1}}
            >
             <SendIcon />
            </CustomIconButton>
            <CustomIconButton
              onClick={clear}
              edge="end"
            >
              <CloseIcon /> 
            </CustomIconButton>
          </InputAdornment>
        }
        label="Escreva um comentário"
      />
      <FormHelperText>
        {helperText}
      </FormHelperText>
    </StyledFormControl>
  );
}