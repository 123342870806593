import React, { Component } from 'react';
import ContentTrailAdminScreen from '../components/generic_screens/ContentTrailAdminScreen';
// DB Models
import Content from '../../models/Content';
import ContentWe from '../../models/ContentWe';
import ClassWeAuthor from '../../models/ClassWeAuthor';
import Role from '../../models/Role';

import ContentTrailCard from '../components/cards/ContentTrailCard';
import ContentTrialDescription from '../components/description_cards/ContentTrialDescription';
import { toast } from 'react-toastify';
import Spinner from '../components/buttons/Spinner';
class AdminContentClassWeScreen extends Component {
  editionTimeout = null;

  state = {
    contents: [],
    relations: [],
    filters: [],
    authors: [],
    loading:true,
  };

  // TEXTS
  searchText_placeholder = 'Título do Conteúdo';
  addButton_placeholder = 'Conteúdo';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    ContentWe.getContentClass(this.props.startContent.id_class)
      .then((contents) => {
        this.setState({ contents: contents });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
    Role.query().then((roles) => {
      let relations = this.state.relations;
      relations[0] = roles;
      this.setState({ relations: relations });
    });
    ClassWeAuthor.query().then((authors) => {
      let authorsObj = this.state.authors;
      authorsObj[0] = authors;
      this.setState({ authors: authorsObj });
    });

    this.reloadContent();
  }

  reloadContent = async (parameters) => {
    try {
      let contents = await ContentWe.getContentClass(
        this.props.startContent.id_class,
      );

      if (contents && contents.length > 0) {
        contents = contents.sort((obj1, obj2) => {
          let obj1_order_content = obj1.order_content;
          let obj2_order_content = obj2.order_content;

          if (!obj1_order_content) {
            obj1_order_content = 99999999999999;
          }

          if (!obj2_order_content) {
            obj2_order_content = 99999999999999;
          }

          if (obj1.order_content < obj2.order_content) {
            return -1;
          } else {
            return 1;
          }
        });
      }

      this.setState({ loading: false });
      this.setState({ contents: contents });
    } catch (err) {
     toast.error('Erro ao carregar conteúdos');
    }
  };

  /*addContent = async () => {
        let newContent = new Content();
        newContent.content = {
            id_app: localStorage.getItem("ID_APP"),
            id_class: this.props.startContent.id_class,
            is_challenge: 1,
            is_fixed: null,
            is_marketing: null,
            content_type: null,
            roles: this.props.startContent.roles,
            starting_content: this.props.startContent.starting_content
        };
        let contentObj = {};
        try {
            contentObj = await newContent.saveTrail();
        } catch (err) {
            console.error(err);
            throw Error(err);
        }
        let contentList = [];
        if (this.state.contents && this.state.contents.length > 0) {
            contentList = this.state.contents;
        }

        contentList.push(contentObj);
        this.setState({ contents: contentList });
        return contentObj;
    };*/

  addContent = async (contentType) => {
    let contentList = [];
    if (this.state.contents && this.state.contents.length > 0) {
      contentList = this.state.contents;
    }
    let order_content =
      contentList && contentList.length > 0 ? contentList.length : 0;
    let newContent = new Content();
    newContent.content = {
      id_app: localStorage.getItem('ID_APP'),
      id_class: this.props.startContent.id_class,
      order_content: order_content,
      content_type: contentType,
    };

    let contentObj = {};
    try {
      contentObj = await newContent.saveTrailClassWe();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }

    contentList.push(contentObj);
    this.setState({ contents: contentList });
    this.state.contents = contentList;
    return contentObj;
  };

  updateContentList = (objectList) => {
    this.state.contents = objectList;
    this.saveContentList(this.state.contents);
  };

  updateContent = (contentObj, field, value, force) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_content === contentObj.id_content;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          contentToUpdate[prop] = value[prop];
        }
      }
    } else if (field.indexOf('.') > 0) {
      let hierarchy = field.split('.');
      let obj = contentToUpdate;
      for (let i = 0; i < hierarchy.length; i++) {
        if (i === hierarchy.length - 1) {
          obj[hierarchy[i]] = value;
          break;
        }
        obj = obj[hierarchy[i]];
      }
    } else {
      contentToUpdate[field] = value;
    }

    this.setState({ contents: contentList });
    return contentList[position];
  };

  deleteContent = async (contentObj) => {
    let contentList = this.state.contents;

    // Finds Edited content on contents list and change Content_name value
    for (let i = 0; i < contentList.length; i++) {
      if (contentObj.id_content === contentList[i].id_content) {
        contentList[i].remove();
        contentList.splice(i, 1);
        break;
      }
    }

    this.setState({ contents: contentList });
  };

  saveContentList = async (contentListObj) => {
    let contentListToUpdate = contentListObj.map((content, index) => {
      content.order_content = index;
      return content;
    });

    if (contentListToUpdate != null) {
      try {
        for (let i = 0; i < contentListToUpdate.length; i++) {
          /*let contentObjtoUpdate = */
          contentListToUpdate[i].save();
          //contentListToUpdate[i] = contentObjtoUpdate[i];
        }

        this.setState({ contents: contentListToUpdate });
        toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
        switch (err.message) {
          default:
           toast.error('Erro ao salvar conteúdo!');
            break;
        }
      }
    }
  };

  saveContent = async (contentObj) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    let contentToUpdate = contentList.find((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    if (contentToUpdate != null) {
      /*let contentObj = contentToUpdate;*/
      let contentObj = contentToUpdate;

      try {
        let order = position; //contentToUpdate.order_content;
        contentToUpdate.order_content = order;
        //let predecessor = contentList.find(content => content.order_content === order - 1);
        if (order < 0) {
          throw new Error('content_order');
          /*} else if (order > 1 && !predecessor) {
                        throw new Error('content_order_number');*/
        } else if (contentToUpdate.content_type === 3) {
          if (
            contentToUpdate.form.forms_questions &&
            contentToUpdate.form.forms_questions.length
          ) {
            let form_questions = contentToUpdate.form.forms_questions;
            for (let k = 0; k < form_questions.length; k++) {
              if (
                form_questions[k].form_question_type === 1 &&
                !form_questions[k].forms_choices
              ) {
                throw new Error('form_choices');
              } else if (
                !form_questions[k].forms_choices.length ||
                form_questions[k].forms_choices.length === 0
              ) {
                throw new Error('form_choices');
              } else if (!form_questions[k].forms_choices[0].form_choice) {
                throw new Error('form_choices');
              }
            }
          } else {
            throw new Error('form_questions');
          }
        }
        contentObj = await contentToUpdate.save();
        // Content upload to server worked
        contentList[position] = contentObj;
        this.setState({ contents: contentList });
        toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
        switch (err.message) {
          case 'form_questions':
           toast.error(
              'Erro ao salvar conteúdo! Elabore uma pergunta para seu formulário.',
            );
            break;
          case 'form_choices':
           toast.error(
              'Erro ao salvar conteúdo! Pergunta do tipo múltipla deve ter pelo menos uma alternativa.',
            );
            break;
          case 'content_order':
           toast.error('Erro ao salvar conteúdo! Defina uma ordem.');
            break;
          case 'content_order_number':
           toast.error(
              'Erro ao salvar conteúdo! Conteúdo não apresenta ordem correta.',
            );
            break;
          default:
           toast.error('Erro ao salvar conteúdo!');
            break;
        }
      }
    }
  };

  refreshContents = async (parameters) => {
    this.reloadContent(parameters);
  };

  render() {
    return (
    //   <>
    // {/* //nada */}
    // <Spinner texto ={'procurando class we'}/>
    //   {this.state.contents.length===0 && this.state.loading ? <Spinner
    //     margin='13px' 
    //    texto={'carregando...'}
    //    border={'#9BB3D4'}
    //    top={'#1D2D44'}/>
    //    :
      <ContentTrailAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.contents}
        objectCard={ContentTrailCard}
        objectDescription={ContentTrialDescription}
        addObject={this.addContent}
        updateObject={this.updateContent}
        deleteObject={this.deleteContent}
        saveObject={this.saveContent}
        refreshObjects={this.refreshContents}
        relations={this.state.relations}
        authors={this.state.authors}
        objectIdName={'id_content'}
        objectModel={Content}
        onFilterReload={true}
        updateObjectList={this.updateContentList}
      ></ContentTrailAdminScreen>
  // }</>
    );
  }
}

export default AdminContentClassWeScreen;
