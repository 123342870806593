import React, { Component, View } from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers/";
import SearchableSelect from "../components/selectbox/SearchableSelect.js";
import PushNotification from "../../models/PushNotification";
import User from "../../models/User";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class AdminPushNotificationScreen extends Component {
  state = {
    users: [],
    label: null,
    value: null,
    title: null,
    text: null,
    is_individual: false,
    is_multi: false,
    target_users: "",
    roles: [],
  };

  constructor() {
    super();
  }

  selectHandler = async (user) => {
    await this.setState({ label: user.user_name + "-" + user.user_phone });
    await this.setState({ value: user });
    await this.setState({ users: [user] });
  };

  multiSelectHandler = async (users) => {
    await this.setState({ users: users });
    await this.setState({ value: users });
  };

  roleSelectHandler = async (roles) => {
    await this.setState({ roles: roles });
  };

  changeStateValue = (stateName) => (event) => {
    let stateObj = {};
    stateObj[stateName] = event && event.target ? event.target.value : null;
    this.setState(stateObj);
  };

  radioGroupHandler = async (event) => {
    let value = event.target.value;
    let users;
    try {
      switch (value) {
        case "all":
          this.setState({
            is_individual: false,
            is_multi: false,
            target_users: value,
          });
          users = await User.query();
          break;
        case "active":
          this.setState({
            is_individual: false,
            is_multi: false,
            target_users: value,
          });
          users = await User.query();
          users = users.filter((user) => {
            return user.is_approved;
          });
          break;
        case "inactive":
          this.setState({
            is_individual: false,
            is_multi: false,
            target_users: value,
          });
          users = await User.query();
          users = users.filter((user) => {
            if (user.is_approved) {
              return false;
            } else {
              return true;
            }
          });
          break;
        case "individual":
          this.setState({
            is_individual: true,
            is_multi: false,
            is_role: false,
            target_users: value,
          });
          if (this.state.value) {
            this.selectHandler(this.state.value);
          }
          break;
        case "multi":
          this.setState({
            is_multi: true,
            is_individual: false,
            is_role: false,
            target_users: value,
          });
          if (Array.isArray(this.state.value) && this.state.value.length > 0) {
            this.multiSelectHandler(this.state.value);
          }
          break;
        case "roles":
          this.setState({
            is_role: true,
            is_individual: false,
            is_multi: false,
            target_users: value,
          });
      }
      if (users) {
        await this.setState({ users: users });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  render() {
    return (
      <div style={{ height: "96%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            height: "100%",
            alignItems: "center",
            overflowY: "scroll",
          }}
        >
          <Paper style={{ height: "700px", width: "700px" }}>
            <Grid container style={{ padding: "20px" }} spacing={4}>
              <Grid xs={2}></Grid>
              <Grid item xs={8} alignContent="center" direction={"column"}>
                <Grid container spacing={2}>
                  <Grid xs={12} style={{ float: "left" }}>
                    <RadioGroup
                      column
                      style={{ paddingLeft: 9, paddingTop: 20 }}
                      onChange={this.radioGroupHandler}
                      value={this.state.target_users}
                    >
                      -
                      <FormControlLabel
                        control={<Radio color={"primary"} />}
                        label={"Enviar Para Todos"}
                        value={"all"}
                      />
                      {localStorage.getItem("ID_APP") === "3" && (
                        <FormControlLabel
                          control={<Radio color={"primary"} />}
                          label={"Enviar Para Todos os Ativos"}
                          value={"active"}
                        />
                      )}
                      {localStorage.getItem("ID_APP") === "3" && (
                        <FormControlLabel
                          control={<Radio color={"primary"} />}
                          label={"Enviar Para Todos os Inativos"}
                          value={"inactive"}
                        />
                      )}
                      <FormControlLabel
                        control={<Radio color={"primary"} />}
                        label={"Enviar para um usuário específico"}
                        value={"individual"}
                      />
                      <SearchableSelect
                        label="Texto da Notificação"
                        style={{ width: "80%" }}
                        placeholder={"Selecione o Usuário"}
                        searchApiUrl={"/user/select_full_user"}
                        actionOnSelectedOption={this.selectHandler}
                        value={{
                          label: this.state.label,
                          value: this.state.value,
                        }}
                        disabled={!this.state.is_individual}
                      ></SearchableSelect>
                      <FormControlLabel
                        control={<Radio color={"primary"} />}
                        label={"Enviar para múltiplos Usuários"}
                        value={"multi"}
                      />
                      <SearchableSelect
                        label="Texto da Notificação"
                        style={{ width: "80%" }}
                        placeholder={"Selecione o Usuário"}
                        searchApiUrl={"/user/select_full_user"}
                        actionOnSelectedOption={this.multiSelectHandler}
                        disabled={!this.state.is_multi}
                        isMulti={true}
                      ></SearchableSelect>
                      <FormControlLabel
                        control={<Radio color={"primary"} />}
                        label={"Enviar para Usuários de papéis específicos"}
                        value={"roles"}
                      />
                      <SearchableSelect
                        label="Texto da Notificação"
                        style={{ width: "80%" }}
                        placeholder={"Selecione o Papel"}
                        searchApiUrl={"/role/select"}
                        actionOnSelectedOption={this.roleSelectHandler}
                        disabled={!this.state.is_role}
                        isMulti={true}
                      ></SearchableSelect>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={2}></Grid>
              <Grid xs={2}></Grid>
              <Grid item xs={8}>
                <TextField
                  label="Título da Push Notification(100 caracteres)"
                  style={{ width: "100%" }}
                  onChange={this.changeStateValue("title")}
                ></TextField>
              </Grid>
              <Grid xs={2}></Grid>
              <Grid xs={2}></Grid>
              <Grid item xs={8}>
                <TextField
                  label="Conteúdo da Push Notification(200 caracteres)"
                  style={{
                    width: "100%",
                  }}
                  multiline={true}
                  InputProps={{
                    multiline: true,
                    rowsMax: 5,
                  }}
                  onChange={this.changeStateValue("text")}
                  inputProps={{ maxLength: 200 }}
                ></TextField>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <div style={{ width: "100%", height: "100%" }}>
                  <Button
                    variant="contained"
                    onClick={async () => {
                      if (this.state.is_role && this.state.roles.length > 0) {
                        let users;
                        try {
                          users = await User.query();
                          users = users.filter((user) => {
                            return this.state.roles.some(
                              (role) => role.id_role === user.id_role
                            );
                          });

                          await PushNotification.sendPushNotification({
                            ...this.state,
                            users: users.map((userObj) => {
                              return userObj.id_user;
                            }),
                          });
                        } catch (err) {
                          console.warn(err);
                        }
                      } else {
                        try {
                          const users = this.state.users.map((userObj) => {
                            return userObj.id_user;
                          });

                          if (users.length > 0) {
                            await PushNotification.sendPushNotification({
                              ...this.state,
                              users: this.state.users.map((userObj) => {
                                return userObj.id_user;
                              }),
                            });
                          }
                        } catch (e) {
                          console.warn(e);
                        }
                      }
                    }}
                    color="#00FF00"
                    style={{
                      backgroundColor: "#4498A7",
                      color: "white",
                      paddingRight: "10px",
                    }}
                  >
                    Enviar Push Para:{" "}
                    {this.state.target_users === "individual" &&
                    this.state.users.length > 0
                      ? this.state.users[0].user_name
                      : this.state.target_users === "all"
                      ? "Todos"
                      : this.state.target_users === "multi"
                      ? "Muitos"
                      : this.state.target_users === "active"
                      ? "Todos os Ativos"
                      : this.state.target_users === "inactive"
                      ? "Todos os Inativos"
                      : this.state.target_users === "roles"
                      ? "Papéis específicos"
                      : "Nenhum Usuário"}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
    );
  }
}
