// import React, { Component } from "react";
// import GenericAdminScreen from "../components/generic_screens/GenericAdminScreen";
// // DB Models
// import Selling_Region from "../../models/Selling_Region";
// import Brand from "../../models/Brand";
// import Store from "../../models/Store";
// import Selling_RegionCard from "../components/cards/Selling_RegionCard";
// import Selling_RegionDescription from "../components/description_cards/Selling_RegionDescription.js";
// import Product from "../../models/Product";

// class ZenDesk extends Component {
//   state = {
//     id_app: null,
//   };

//   componentDidMount() {
//     this.state.id_app = localStorage.getItem("ID_APP");
//   }

//   render() {
//     return (
//       <div style={{ height: "100%", padding: "0px" }}>
//         <p>aqyuuuuuuooooooooooooooooooo</p>
//         <iframe
//           width="100%"
//           height="100%"
//           alignItems="center"
//           justifyContent="center"
//           src="https://www.youtube.com/embed/0vEPVBtkyaU"
//           frameborder="0"
//           onmousewheel=""
//           width="100%"
//           height="100%"
//           style="background: transparent; border: 1px solid #ccc;"
//           frameBorder="0"
//           style={{ border: 0 }}
//           allowFullScreen
//         ></iframe>
//       </div>
//     );
//   }
// }

// export default ZenDesk;

import React from "react";

const ZenDesk = () => {
  return (
    <div style={{ height: "100%", padding: "0px" }}>
      <a href="https://insidetheboxhelp.zendesk.com/hc/pt-br" target="_blank">
        redirecionando{" "}
      </a>
      <iframe
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        // src="https://www.youtube.com/embed/0vEPVBtkyaU"
        // src="insidetheboxhelp.zendesk.com"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="100%"
        style="background: transparent; border: 1px solid #ccc;"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default ZenDesk;
