import React from "react";
import Slider from "react-slick";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, 
        display: "flex", 
        background: "linear-gradient(270deg, #162233 33.49%, rgba(22, 34, 51, 0) 100%)",  
        height: "100%",
        marginLeft: -30,
        width: "5%",
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, 
        display: "flex", 
        background: "linear-gradient(90deg, #162233 33.49%, rgba(22, 34, 51, 0) 100%)", 
        height: "100%",
        width: "5%",
        marginRight: -30,
        zIndex: 2,
        alignItems: 'center',
        justifyContent: 'center'
      }}
      onClick={onClick}
    />
  );
}

export default function SlickCarrousel({images, onClickImage}) {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
     { 
      images.map(image => (
        <div onClick={() => onClickImage(image)}>
          <img 
            src={image.image_url}
            alt="image"
            style={{
              width: '100%',
              height: 80,
              cursor: 'pointer'
            }}
          />
        </div>
      ))
     }
    </Slider>
  );
}