import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Invoice from '../../models/Invoice';
import InvoiceCard from '../components/cards/InvoiceCard';
import InvoiceDescription from '../components/description_cards/InvoiceDescription';
import Brand from '../../models/Brand';
import AuthManager from '../../lib/AuthManager';

class AdminInvoiceMaskScreen extends Component {
  editionTimeout = null;

  state = {
    invoices: [],
    relations: [],
    filters: [],
    numberInvoices: 0,
  };

  // TEXTS
  searchText_placeholder = 'Procure a Nota Fiscal';
  addButton_placeholder = 'Nota Fiscal';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    Promise.all([
      Invoice.query({
        invoice_status: "('1. PENDENTE')",
        offset: 0,
        interval: 20,
      }),
      Brand.query(),
    ])
      .then((results) => {
        this.setState({
          invoices: results[0].elements,
          numberInvoices: results[0].countElements,
          relations: [results[1]],
        });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
  }

  refreshInvoices = async (
    parameters = {
      invoice_status: "('1. PENDENTE')",
      offset: 0,
      interval: 20,
    },
  ) => {
    try {
      let invoiceResponse = await Invoice.query(parameters);

      this.setState({
        invoices: invoiceResponse.elements,
        numberInvoices: invoiceResponse.countElements,
      });
    } catch (err) {
      console.error(err);
    }
  };

  addInvoice = async () => {
    let newInvoice = new Invoice();
    let invoiceObj = {};
    try {
      invoiceObj = await newInvoice.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let invoiceList = this.state.invoices;
    invoiceList.push(invoiceObj);
    this.setState({ invoices: invoiceList });
    return invoiceObj;
  };

  saveInvoice = async (invoiceObj) => {
    let commit_time_inc = new Date() - invoiceObj.start_commit_time;
    invoiceObj.commit_time += commit_time_inc;
    let invoiceList = this.state.invoices;
    let position = invoiceList.findIndex((obj) => {
      return obj.id_invoice === invoiceObj.id_invoice;
    });
    let invoiceToUpdate = invoiceList.find((obj) => {
      return obj.id_invoice === invoiceObj.id_invoice;
    });

    if (invoiceToUpdate != null) {
      let invoiceObj = invoiceToUpdate;

      try {
        invoiceObj.start_commit_time = new Date() / 1;
        invoiceObj = await invoiceToUpdate.save();
        // Invoice upload to server worked
        invoiceList[position] = invoiceObj;
        this.setState({ invoices: invoiceList });
       toast.success('Cupom salvo com sucesso!');
      } catch (err) {
       toast.error('Erro ao salvar cupom!');
        console.error(err);
      }
    }
  };

  updateInvoice = (invoiceObj, field, value, force) => {
    let that = this;
    let invoiceList = this.state.invoices;
    let position = invoiceList.findIndex((obj) => {
      return obj.id_invoice === invoiceObj.id_invoice;
    });
    let invoiceToUpdate = invoiceList.find((obj) => {
      return obj.id_invoice === invoiceObj.id_invoice;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          invoiceToUpdate[prop] = value[prop];
        }
      }
    } else {
      invoiceToUpdate[field] = value;
    }

    this.setState({ invoices: invoiceList });
    return invoiceList[position];
  };

  deleteInvoice = (invoiceObj) => {
    let invoiceList = this.state.invoices;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < invoiceList.length; i++) {
      if (invoiceObj.id_invoice === invoiceList[i].id_invoice) {
        invoiceList[i].remove();
        invoiceList.splice(i, 1);
        break;
      }
    }

    this.setState({ invoices: invoiceList });
  };

  render() {
    return (
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.invoices}
        objectCard={InvoiceCard}
        objectDescription={InvoiceDescription}
        addObject={this.addInvoice}
        saveObject={this.saveInvoice}
        updateObject={this.updateInvoice}
        deleteObject={this.deleteInvoice}
        refreshObjects={this.refreshInvoices}
        relations={this.state.relations}
        objectIdName={'id_invoice'}
        objectModel={Invoice}
        onFilterReload={true}
        pagination={true}
        numberElements={this.state.numberInvoices}
      />
    );
  }
}

export default AdminInvoiceMaskScreen;
