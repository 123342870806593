import React, { Component } from "react";
import GenericAdminScreen from "../components/generic_screens/GenericAdminScreen";
// DB Models
import Selling_Region from "../../models/Selling_Region";
import Brand from "../../models/Brand";
import Store from "../../models/Store";
import Selling_RegionCard from "../components/cards/Selling_RegionCard";
import Selling_RegionDescription from "../components/description_cards/Selling_RegionDescription.js";
import Product from "../../models/Product";

class AdminDataPanelScreen extends Component {
    state = {
        id_app: null,
    };

    componentDidMount() {
        this.state.id_app = localStorage.getItem("ID_APP");
    }

    render() {
        return (
            <div style={{ height: "100%", padding: "20px" }} key={this.keyReload}>
                <iframe
                    width="100%"
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    src={
                        localStorage.getItem("ID_APP") == 3
                            ? "https://datastudio.google.com/embed/reporting/ef4b6f90-56b1-46ed-90cc-1629b81fddb1/page/sFHGC"
                            : localStorage.getItem("ID_APP") == 10 ? "https://datastudio.google.com/embed/reporting/c6044d96-0637-4e6c-a390-a1bdd489eb96/page/sFHGC"
                                : localStorage.getItem("ID_APP") == 14 ? "https://datastudio.google.com/embed/reporting/d6609010-2a9b-4b01-82bb-a9e6fac65ca9/page/sFHGC"
                                    : localStorage.getItem("ID_APP") == 15 ? "https://datastudio.google.com/embed/reporting/8b6ee462-054f-48dd-89d7-1d39f72ddbda/page/sFHGC"
                                        : localStorage.getItem("ID_APP") == 21 ? "https://datastudio.google.com/embed/reporting/93c3e05a-404b-49f4-bbb0-53a1d33bffd6/page/Mx8UC"
                                            : localStorage.getItem("ID_APP") == 22 ? "https://datastudio.google.com/embed/reporting/b8a93323-4db6-4d2d-a67b-03023fe261e1/page/sFHGC"
                                                : localStorage.getItem("ID_APP") == 23 ? "https://datastudio.google.com/embed/reporting/1d961cf8-c81a-4fad-8d60-2328235430c8/page/sFHGC"
                                                    : localStorage.getItem("ID_APP") == 24 ? "https://datastudio.google.com/embed/reporting/9d48518b-c35a-4191-b414-20a272ed7779/page/sFHGC"
                                                        : localStorage.getItem("ID_APP") == 25 ? "https://datastudio.google.com/embed/u/0/reporting/f22f18f1-f700-4b35-b24e-ac4adb77fa55/page/sFHGC"
                                                            : localStorage.getItem("ID_APP") == 27 ? "https://datastudio.google.com/embed/u/0/reporting/9a7d30b7-c6d4-415c-bd52-a776ffc0e54e/page/eemWC"
                                                                : null
                    }
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                ></iframe>
            </div >
        );
    }
}

export default AdminDataPanelScreen;
