import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

export default function ClassWeCard(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;

    this.title = this.object ? this.object.class_title : '';

    this.id = this.object.id_class ? this.object.id_class : 'SEM ID';

    // Functions
    this.updateContent = props.updateObject;
    this.deleteContent = props.deleteObject;
    this.callDescription = props.callDescription;

    this.onChangeUpdate = (field, force) => event => {
        let value = (event && event.target) ? event.target.value : null;
        this.updateContent(this.object, field, value, force);
    };

    this.onClickCallDescription = () => {
        this.callDescription(this.object);
    };

    return (
        <div style={{
            'height': '6vw',
            'backgroundColor': props.chosenId === this.object.id_content ? '#4498A7' : '#f2f2f2',
            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
        }} onClick={this.onClickCallDescription}>
            <img
                style={{ width: '10vw', height: '5vw', objectFit: 'cover', marginRight: '20px', marginLeft: '20px' }}
                src={this.object.class_banner ? this.object.class_banner : null}
                id={'image-modal'}
            />
            <div style={{ flex: 3 }}>
                <span
                    style={{
                        float: 'left',
                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em'
                    }}>
                    {this.title}
                </span>
            </div>
            <div style={{ flex: 1 }}>
                <span
                    style={{
                        float: 'left',
                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em'
                    }}>
                    {'Curso'}
                </span>
            </div>
        </div>
    );
}