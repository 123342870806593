import GenericModel from './generic_model/GenericModel'
import axios from "axios";
import * as firebase from 'firebase/app';
import 'firebase/storage';

const API = '/training';

class Training extends GenericModel {
  API = API;
  static API = API;

  static sendTrainingCoverImage = async (obj, id_training, progressFunction) => {

    try {
      let formData = new FormData();
      formData.append('cover_image', obj, (new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.png');

      let headerObj = await Training.insertAuthHeader();

      let response = await axios.request({
        url: API + '/send-training-cover-image',
        method: "post",
        data: formData,
        headers: headerObj,
        onUploadProgress: (p) => {
          if (progressFunction) {
            progressFunction(Math.round((p.loaded * 100) / p.total));
          }
        }
      });

      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };

  static sendTrainingVideo = async (obj, id_training, progressFunction) => { ///olhar

    return new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref();

      let bucketImg = storageRef.child((new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + obj.name.split('.').pop());

      let uploadTask = bucketImg.put(obj);

      uploadTask.on('state_changed', function(snapshot){
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressFunction(progress);

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, function(error) {
        reject(error);
        // Handle unsuccessful uploads
      }, function() {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          resolve({url:downloadURL});
        });
      });
    });
  };

  static sendTrainingFile = async (obj, id_training, progressFunction) => { //voltar aqui agr

    try {
      let formData = new FormData();
      // urlModify(videoUrlSend)

      formData.append('file', obj, (new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + obj.name.split('.').pop());

      let headerObj = await Training.insertAuthHeader();

      let response = await axios.request({
        url: API + '/send-training-file',
        method: "post",
        data: formData,
        headers: headerObj,
        onUploadProgress: (p) => {
          if (progressFunction) {
            progressFunction(Math.round((p.loaded * 100) / p.total));
          }
        }
      });

      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };
}

export default Training;