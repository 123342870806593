import React from 'react';
import { Grid, Paper } from '@material-ui/core';

export default function AuthorCard(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;

    // Functions
    this.updateAuthor = props.updateObject;
    this.deleteAuthor = props.deleteObject;
    this.callDescription = props.callDescription;

    //Function to Call Description in GenericAdminScreen.js
    this.onClickCallDescription = () => {
        this.callDescription(this.object);
    };

    return (
        <Paper style={{ 'height': '100px', 'backgroundColor': props.chosenId === this.object.id_author ? '#4498A7' : '#f2f2f2' }}>
            <Grid container onClick={this.onClickCallDescription} style={{ 'height': '80px', 'width': '100%' }}>
                <Grid xs={3}>
                    <div style={{ height: '80px', background: '#f2f2f2', top: '10px', margin: 'auto', position: 'relative', width: '75px' }}>
                        <img
                            style={{ width: '10vw', height: '5vw', objectFit: 'cover', marginRight: '20px' }}
                            src={this.object.author_photo ? this.object.author_photo : null}
                            id={'image-modal'}
                        />
                    </div>
                </Grid>
                <Grid xs={8}>
                    <table>
                        <tr>
                            <Grid xs={12} >
                                <div style={{ float: 'left', top: '10px', position: 'relative' }}><span style={{
                                    float: 'left', padding: '0', fontSize: '18px',
                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                                    letterSpacing: '0.00938em'
                                }}>{this.object.author_name ? this.object.author_name : ''}</span></div>
                            </Grid>
                        </tr>
                    </table>
                </Grid>
            </Grid>
        </Paper>
    );

}