import React, { Component } from "react";
import GenericAdminScreen from "../components/generic_screens/GenericAdminScreen";
// DB Models
import Selling_Region from "../../models/Selling_Region";
import Brand from "../../models/Brand";
import Store from "../../models/Store";
import Selling_RegionCard from "../components/cards/Selling_RegionCard";
import Selling_RegionDescription from "../components/description_cards/Selling_RegionDescription.js";
import Product from "../../models/Product";

class AdminDataPanelScreenCafeCultura extends Component {
  render() {
    return (
      <div style={{ height: "100%", padding: "20px" }} key={this.keyReload}>
        <iframe
          width="100%"
          height="100%"
          src={
            "https://datastudio.google.com/embed/reporting/e68cc963-34dc-4411-8018-a53dc6053f5d/page/6zXD"
          }
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            alignItems: "center",
            padding: "60px 20px 10px 20px",
          }}
        ></div>
      </div>
    );
  }
}

export default AdminDataPanelScreenCafeCultura;
