import React, { Component } from 'react';
import StoreAdminScreen from '../components/generic_screens/StoreAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Store from '../../models/Store';
import Role from '../../models/Role';
import User from '../../models/User';
import StoreCard from '../components/cards/StoreCard';
import StoreDescription from '../components/description_cards/StoreDescription';
import StoreAttachmentDescription from '../components/description_cards/StoreAttachmentDescription';
import StoreUserListDescription from '../components/description_cards/StoreUserListDescription';
import StoreUserDescription from '../components/description_cards/StoreUserDescription';
import Utils from "../../lib/utils";
import Spinner from '../components/buttons/Spinner';


class AdminStoreScreen extends Component {
  editionTimeout = null;

  state = {
    stores: [],
    relations: [],
    loading:true
  };

  // TEXTS
  searchText_placeholder = 'Nome da Loja ou nome/telefone do usuário';
  addButton_placeholder = 'Loja';

  // FUNCTIONS
  // Load all data from DB
  componentDidMount() {
    this.refreshStores();
  }

  refreshStores = async () => {
    Promise.all([Store.query(), Role.query()])
      .then((relations) => {
        this.setState({
          stores: relations[0],
          relations: [relations[1]],
          loading:false
        });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
  };

  addStore = async () => {
    let newStore = new Store();
    let storeObj = {};
    try {
      storeObj = await newStore.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let storeList = this.state.stores;
    storeList.push(storeObj);
    this.setState({ stores: storeList });
    return storeObj;
  };

  updateStore = (storeObj, field, value, force) => {
    let storeList = this.state.stores;
    let position = storeList.findIndex((obj) => {
      return obj.id_store === storeObj.id_store;
    });
    let storeToUpdate = storeList.find((obj) => {
      return obj.id_store === storeObj.id_store;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          storeToUpdate[prop] = value[prop];
        }
      }
    } else {
      storeToUpdate[field] = value;
    }

    this.setState({ stores: storeList });
    return storeList[position];
  };

  saveStore = async (storeObj) => {
    let storeList = this.state.stores;
    let position = storeList.findIndex((obj) => {
      return obj.id_store === storeObj.id_store;
    });
    let storeToUpdate = storeList.find((obj) => {
      return obj.id_store === storeObj.id_store;
    });

    if (storeToUpdate != null) {
      let storeObj = storeToUpdate;

      try {
        storeObj = await storeToUpdate.save();
        // Store upload to server worked
        storeList[position] = storeObj;
        this.setState({ stores: storeList });
       toast.success('Loja salva com sucesso!');
      } catch (err) {
        console.error(err);
       toast.error('Erro ao salvar loja!');
      }
    }
  };

  deleteStore = (storeObj) => {
    let storeList = this.state.stores;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < storeList.length; i++) {
      if (storeObj.id_store === storeList[i].id_store) {
        storeList[i].remove();
        storeList.splice(i, 1);
        break;
      }
    }

    this.setState({ stores: storeList });
  };

  render() {
    return (
      <> 
      {/*this.state.stores.length===0 
      &&this.state.loading
      ? <Spinner
       margin='13px' 
      texto={'carregando...'}
      border={'#9BB3D4'}
      top={'#1D2D44'}/>
      :*/
      <StoreAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.stores}
        objectCard={StoreCard}
        objectDescription={StoreDescription}
        objectDescriptionAttachment={StoreAttachmentDescription}
        objectDescriptionUserList={StoreUserListDescription}
        objectStoreUserDescription={StoreUserDescription}
        addObject={this.addStore}
        saveObject={this.saveStore}
        updateObject={this.updateStore}
        deleteObject={this.deleteStore}
        refreshObjects={this.refreshStores}
        relations={this.state.relations}
        objectIdName={'id_store'}
        objectModel={Store}
      />
  }</>
    );
  }
}

export default AdminStoreScreen;
