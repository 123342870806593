import React from 'react';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { red, green, blue } from '@material-ui/core/colors';
import DeleteButton from '../buttons/DeleteButton';
import { DateTimePicker } from '@material-ui/pickers/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';

export default function Selling_RegionDescription(props) {

    this.editionTimeout = null;

    // Functions
    this.updateSelling_Region = props.updateObject;
    this.deleteSelling_Region = props.deleteObject;
    this.nullDescription = props.nullDescription;


    // Object to Render
    this.object = props.object;
    this.relations = props.relations;
    this.brands = this.relations[0]

    //beginning of the brands section
    //get the values for <Select> from object.brands and put labels "value" and "label"
    this.putLabel = (id) => {
        for (let i = 0; i < this.brands.length; i++) {
            if (this.brands[i].id_brand === id) {
                return {
                    value: id,
                    label: this.brands[i].brand_name
                }
            }
        }
        return [];
    }
    //make the brandDefaultValue for <Select>
    this.object.brandDefaultValue = this.putLabel(this.object.id_brand);

    //Function to Update the Brands Value in AdminSellingRegionScreen
    this.onChangeUpdateBrand = (brand) => {
        this.object.brandDefaultValue = this.putLabel(brand.value);
        this.updateSelling_Region(this.object, 'id_brand', brand.value, true);
    }

    //put the labels "value" and "label" in the brandOptions, because the <Select> needs it to work
    this.object.brandOptions = this.brands.map((brand) => {
        let brandOption = {
            value: brand.id_brand,
            label: brand.brand_name
        };
        return brandOption;
    });

    //beginning of the Stores Section
    if (this.object.stores != undefined)//if stores is "undefined", cant use map()
    {
        this.object.storeDefaultValue = this.object.stores.map((store) => { return { value: store.id_store ? store.id_store : null, label: store.store_name ? store.store_name : null } });
    }
    else {
        this.object.storeDefaultValue = []
    }
    //Function to Update the Stores Value in AdminSelling_RegionScreen
    this.onChangeUpdateStores = (value) => {
        let stores;
        if (value != null)//null cant use map function
        {
            stores = value.map((obj) => {
                return {
                    //revert the label to put the id_store and store_name in the Selling_Region Object
                    id_store: obj.value,
                    store_name: obj.label
                }
            })
        }
        else {
            stores = []
        }
        this.updateSelling_Region(this.object, 'stores', stores, true);
    }

    // put the labels "value" and "label" in the storeOptions, because the <Select> needs it to work
    this.object.storeOptions = this.relations[1].map(function (store) {
        let storeOption = {
            value: store.id_store,
            label: store.store_name
        };
        return storeOption;
    });


    this.onChangeUpdate = (field, force) => event => {
        let value = (event && event.target) ? event.target.value : null;
        this.updateSelling_Region(this.object, field, value, force);
    };

    this.onClickDeleteSelling_Region = () => {
        if (window.confirm('Deseja mesmo deletar esse item?')) {
            this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the selling_region
            this.deleteSelling_Region(this.object);
        }
    };


    return (
        <div style={{ 'height': 'auto' }}>
            <Grid container spacing={2}>
                <Grid xs={1}>

                </Grid>
                <Grid xs={10} container direction="row" spacing={1} style={{ marginTop: "10px" }}>
                    <Grid xs={3} item>
                        <TextField label="ID" value={this.object.id_selling_region ? this.object.id_selling_region : ''} disabled />
                    </Grid>
                    <Grid xs={9} item>
                        <TextField label="Nome" value={this.object.selling_region_name ? this.object.selling_region_name : ''} style={{ 'width': '100%' }}
                            onChange={this.onChangeUpdate('selling_region_name', false)}
                            onBlur={this.onChangeUpdate('selling_region_name', true)} />
                    </Grid>
                    <Grid xs={12} container>
                        <Grid xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Data de Criação" value={this.object.created_date} style={{ 'width': '100%' }} format="dd/MM/yyyy HH:mm" disabled />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker label="Data da ultima alteração" value={this.object.updated_date} style={{ 'width': '100%' }} format="dd/MM/yyyy HH:mm" disabled />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} spacing={2} style={{ marginTop: '20px' }}>
                        <Grid xs={12}>
                            <h><span style={{
                                float: 'left', color: 'rgba(0, 0, 0, 0.54)', padding: '0', fontSize: '13px',
                                fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                                letterSpacing: '0.00938em'
                            }}>Marca relacionada com essa Região:</span></h>
                        </Grid>
                        <Grid xs={12} style={{ marginTop: '5px' }}>
                            <Select placeholder={'Selecione a Marca'} options={this.object.brandOptions} value={this.object.brandDefaultValue} onChange={this.onChangeUpdateBrand} />
                        </Grid>
                        <Grid xs={12} style={{ marginTop: '20px' }}>
                            <h><span style={{
                                float: 'left', color: 'rgba(0, 0, 0, 0.54)', padding: '0', fontSize: '13px',
                                fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                                letterSpacing: '0.00938em'
                            }}>Lojas relacionadas com essa Região:</span></h>
                        </Grid>
                        <Grid xs={12} style={{ marginTop: '5px' }}>
                            <Select placeholder={'Selecione a Loja'} options={this.object.storeOptions} value={this.object.storeDefaultValue} onChange={this.onChangeUpdateStores} isMulti />
                        </Grid>
                    </Grid>
                    <Grid xs={11} style={{ marginTop: '20px' }}>
                        <DeleteButton style={{ float: 'right', background: '#CC0B0B' }} onClickDelete={this.onClickDeleteSelling_Region}></DeleteButton>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    );

}