import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen'
// DB Models
import Brand from '../../models/Brand';
import User from '../../models/User';
import Product from '../../models/Product';
import BrandCard from '../components/cards/BrandCard'
import BrandDescription from '../components/description_cards/BrandDescription'
import Selling_Region from '../../models/Selling_Region';
import Spinner from '../components/buttons/Spinner';
class AdminBrandScreen extends Component {

  editionTimeout = null;

  state = {
    brands: [],
    relations: [],
    loading: true
  };

  // TEXTS
  searchText_placeholder = 'Nome da Marca';
  addButton_placeholder = 'Marca';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    Brand.query().then(brands => {
      this.setState({ 'brands': brands });
    }).catch(err => {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    });
    this.reloadBrands();
  }

  refreshBrands = async (parameters) => {
    this.reloadBrands();
  }

  reloadBrands = async (parameters) => {
    try {
      const [brands] = await Promise.all([Brand.query()]);
      this.setState({ 'brands': brands });
      this.setState({ loading: false });
    } catch (err) {
      console.warn(err);
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
  };

  addBrand = async () => {
    let newBrand = new Brand({ id_app: localStorage.getItem('id_app') });
    let brandObj = {};
    try {
      brandObj = await newBrand.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let brandList = this.state.brands;
    brandList.push(brandObj);
    this.setState({ 'brands': brandList });
    return brandObj;
  };

  updateBrand = (brandObj, field, value, force) => {
    let that = this;
    let brandList = this.state.brands;
    let position = brandList.findIndex((obj) => {
      return obj.id_brand === brandObj.id_brand;
    });
    let brandToUpdate = brandList.find((obj) => {
      return obj.id_brand === brandObj.id_brand;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          brandToUpdate[prop] = value[prop];
        }
      }
    } else {
      brandToUpdate[field] = value;
    }

    // Timeout: Wait 500 milliseconds before sending API POST call to Server
    if (this.editionTimeout) clearTimeout(this.editionTimeout);

    function uploadBrand() {
      if (brandToUpdate != null) {
        brandToUpdate.save().then(brandObj => {
          // Brand upload to server worked
          brandList[position] = brandObj;
          that.setState({ 'brands': brandList });
          that.setState({ loading: false });
        }).catch(err => {
          console.error(err);
        });
      }
    }

    if (force) uploadBrand();
    else {
      this.editionTimeout = setTimeout(() => {
        uploadBrand()
      }, 500);
    }
    this.setState({ 'brands': brandList });
    return brandList[position];
  };

  deleteBrand = (brandObj) => {
    let brandList = this.state.brands;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < brandList.length; i++) {
      if (brandObj.id_brand === brandList[i].id_brand) {
        brandList[i].remove();
        brandList.splice(i, 1);
        break;
      }
    }

    this.setState({ 'brands': brandList });
  };

  saveBrand = async (roleObj) => {
    let brandList = this.state.brands;
    let position = brandList.findIndex((obj) => {
      return obj.id_brand === roleObj.id_brand;
    });
    let brandToUpdate = brandList.find((obj) => {
      return obj.id_brand === roleObj.id_brand;
    });
  }

  render() {
    return (
      <>
        {this.state.brands.length === 0
          && this.state.loading
          ? <Spinner
            margin='13px'
            texto={'carregando...'}
            border={'#9BB3D4'}
            top={'#1D2D44'} />
          :
          <GenericAdminScreen
            searchText_placeholder={this.searchText_placeholder}
            addButton_placeholder={this.addButton_placeholder}
            objectList={this.state.brands}
            objectCard={BrandCard}
            objectDescription={BrandDescription}
            addObject={this.addBrand}
            saveObject={this.saveBrand}
            updateObject={this.updateBrand}
            deleteObject={this.deleteBrand}
            refreshObjects={this.refreshBrands}
            relations={this.state.relations}
            objectIdName={"id_brand"}
            objectModel={Brand}>
          </GenericAdminScreen>
        }
      </>
    )
  }
}

export default AdminBrandScreen;