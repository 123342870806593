import GenericModel from "./generic_model/GenericModel";
import axios from "axios";
import moment from "moment";
const API = "/form";

class Form extends GenericModel {
  API = API;
  static API = API;

  static sendAttachImage = async (obj, progressFunction) => {
    try {
      let formData = new FormData();
      formData.append(
        "file",
        obj,
        new Date().getTime() +
          "-" +
          Math.round(Math.random() * 100000) +
          "." +
          obj.name.split(".").pop()
      );

      let headerObj = await GenericModel.insertAuthHeader();

      let response = await axios.request({
        url: API + "/send-form-attach-image",
        method: "post",
        data: formData,
        headers: headerObj,
        onUploadProgress: (p) => {
          if (progressFunction) {
            progressFunction(Math.round((p.loaded * 100) / p.total));
          }
        },
      });
      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };

  static saveFormImage = async (idFormChoice, imageUrl, fileName) => {
    try {
      let formChoicesObj = {
        id_form_choice: idFormChoice,
        form_product_image: imageUrl,
        form_choice: fileName,
      };
      let headerObj = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(this.API + "/save-forms-image-file", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(formChoicesObj),
      });
      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };

  static saveFormQuestion = async (formQuestionObj) => {
    try {
      let headerObj = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(this.API + "/save-form-question", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(formQuestionObj),
      });
      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };

  static saveFormQuestionChoice = async (formQuestionChoicesObj) => {
    try {
      let headerObj = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(this.API + "/save-form-question-choice", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(formQuestionChoicesObj),
      });
      let body = await response.json();
      return body;
    } catch (err) {
      throw Error(err);
    }
  };

  static addFormQuestion = async (formQuestionObj) => {
    try {
      let headerObj = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(this.API + "/add-form-question", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(formQuestionObj),
      });
      let body = await response.json();
      return body;
    } catch (err) {
      throw Error(err);
    }
  };

  static addFormQuestionChoice = async (formQuestionChoiceObj) => {
    try {
      let headerObj = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(this.API + "/add-form-question-choice", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(formQuestionChoiceObj),
      });
      let body = await response.json();
      return body;
    } catch (err) {
      throw Error(err);
    }
  };

  static removeAttachedFile = async (attachment) => {
    try {
      attachment.form_product_image = null;
      let headerObj = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(this.API + "/save-forms-image-file", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(attachment),
      });
      let object = await response.json();
      let returnData = new GenericModel(object, this.API);
      return returnData;
    } catch (err) {
      throw Error(err);
    }
  };

  static getFormQuestion = async function (id_form) {
    let queryString = "";
    if (id_form) {
      queryString = id_form;
    }

    let authHeader = null;
    try {
      let that = this;
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(
        this.API + "/get-form-question/" + queryString,
        {
          headers: authHeader,
        }
      );
      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

      /*let ans = {};

            function mountObjArray(elementsArray) {
                let result = [];

                for (let i = 0; i < elementsArray.length; i++) {
                    result.push(new GenericModel(elementsArray[i], that.API));
                }

                return result;
            }

            if (body && body.elements) {
                for (let prop in body) {
                    if (body.hasOwnProperty(prop) && prop !== "elements") {
                        ans[prop] = body[prop];
                    }
                }
                ans.elements = mountObjArray(body.elements);
            } else if (body instanceof Array) {
                ans = mountObjArray(body);
            } else {
                ans = new GenericModel(body, that.API);
            }*/

      return body;
    } catch (err) {
      throw Error(err);
    }
  };

  static getFormChoices = async function (id_form_question) {
    let queryString = "";
    if (id_form_question) {
      queryString = id_form_question;
    }

    let authHeader = null;
    try {
      let that = this;
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(
        this.API + "/get-form-choices/" + queryString,
        {
          headers: authHeader,
        }
      );
      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

      /*let ans = {};

            function mountObjArray(elementsArray) {
                let result = [];

                for (let i = 0; i < elementsArray.length; i++) {
                    result.push(new GenericModel(elementsArray[i], that.API));
                }

                return result;
            }

            if (body && body.elements) {
                for (let prop in body) {
                    if (body.hasOwnProperty(prop) && prop !== "elements") {
                        ans[prop] = body[prop];
                    }
                }
                ans.elements = mountObjArray(body.elements);
            } else if (body instanceof Array) {
                ans = mountObjArray(body);
            } else {
                ans = new GenericModel(body, that.API);
            }*/

      return body;
    } catch (err) {
      throw Error(err);
    }
  };

  static getFormChoicesForm = async function (id_form) {
    let queryString = "";
    if (id_form) {
      queryString = id_form;
    }

    let authHeader = null;
    try {
      let that = this;
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(
        this.API + "/get-form-choices-form/" + queryString,
        {
          headers: authHeader,
        }
      );
      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

      return body;
    } catch (err) {
      throw Error(err);
    }
  };
}

export default Form;
