import GenericModel from "./generic_model/GenericModel";
import axios from "axios";
import { toast } from "react-toastify";
import User from "./User";
const API = "/push_notification";

const onesignal = {
  1: {
    app_id: "464797d1-0dc6-4488-a928-dff587d2362d",
    token: "MGVlMjJiNjktNGY1YS00YzRlLTgyMmUtZjE0ZTQ3YjBiODk1",
    android_accent_color: "4498A7",
  },
  2: {
    app_id: "226c8229-f491-4fe2-ae98-79c809898022",
    token: "OTBiOGRiMTItMzRiNC00NTgyLWJlN2MtMzFlMDllOGM5OTk3",
    android_accent_color: "000000",
  },
  3: {
    app_id: "5ca42f12-f3bc-4e5c-8247-f566da0282ac",
    token: "MzhkZjg0ZTktYzA3Ny00ZTdmLWFlOWUtZmUwNDQ1YmYyNTlm",
    android_accent_color: "CF5A79",
  },
  4: {
    app_id: "6348f760-994d-4c21-87a0-fa5f8c3c4975",
    token: "NzY1OWZjZmYtYmQ4My00MTRjLThmNTctODcwYTRiOGMxODMw",
    android_accent_color: "E7531F",
  },
  5: {
    app_id: "7119fc0c-c4f6-4a68-9f3b-709fd2ed1edc",
    token: "MTlkYmI4MWUtZGVmNy00NzIzLTg5ZWMtOTUxMWJhYjhhZmU1",
    android_accent_color: "232936",
  },
  11: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  12: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  13: {
    app_id: "464797d1-0dc6-4488-a928-dff587d2362d",
    token: "MGVlMjJiNjktNGY1YS00YzRlLTgyMmUtZjE0ZTQ3YjBiODk1",
    android_accent_color: "4498A7",
  },
  14: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  15: {
    app_id: "464797d1-0dc6-4488-a928-dff587d2362d",
    token: "MGVlMjJiNjktNGY1YS00YzRlLTgyMmUtZjE0ZTQ3YjBiODk1",
    android_accent_color: "4498A7",
  },
  21: {
    app_id: "464797d1-0dc6-4488-a928-dff587d2362d",
    token: "MGVlMjJiNjktNGY1YS00YzRlLTgyMmUtZjE0ZTQ3YjBiODk1",
    android_accent_color: "4498A7",
  },
  22: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  23: {
    app_id: "464797d1-0dc6-4488-a928-dff587d2362d",
    token: "MGVlMjJiNjktNGY1YS00YzRlLTgyMmUtZjE0ZTQ3YjBiODk1",
    android_accent_color: "4498A7",
  },
  24: {
    app_id: "0a97a595-9784-460c-ad6b-567b9aed57d9",
    token: "Mzk0MjVlNDQtN2U1My00ODFkLWEwZjAtODVlZjRlZGI5NzEx",
    android_accent_color: "4498A7",
  },
  25: {
    app_id: "464797d1-0dc6-4488-a928-dff587d2362d",
    token: "MGVlMjJiNjktNGY1YS00YzRlLTgyMmUtZjE0ZTQ3YjBiODk1",
    android_accent_color: "4498A7",
  },
  26: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "D78855",
  },
  27: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  28: {
    app_id: "464797d1-0dc6-4488-a928-dff587d2362d",
    token: "MGVlMjJiNjktNGY1YS00YzRlLTgyMmUtZjE0ZTQ3YjBiODk1",
    android_accent_color: "4498A7",
  },
  29: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  31: {
    app_id: "cb06615a-4c37-4b80-8bd9-084022d92581",
    token: "NGVmZjhhYmEtZWRkZS00OWNjLTkyZjItMDBhOWU5NjY2NjFh",
    android_accent_color: "D78855",
  }, //ojo
  32: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },

  33: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  34: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  35: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  36: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  37: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  39: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  40: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  41: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  42: {
    app_id: "6a3769df-9065-4309-b5e6-83a5a377e3bc",
    token: "NWRmN2Y4NDctNjA1Yy00ODFhLTg2ZDYtMjdmNjVmMGE5N2Uy",
    android_accent_color: "4498A7",
  },
  43: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  44: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  45: {
    app_id: "302b1213-8b1c-4207-9475-32c6c45d0024",
    token: "ZDU2NzY1YTUtMmNmMS00ODQ0LWFjNWUtMDQ4NjhjMDZlMzI2",
    android_accent_color: "4498A7",
  },
  46: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  48: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
  50: {
    app_id: "1015f81f-5de0-4d59-8ee5-bc042c338a85",
    token: "NTg5Mzg0N2ItYjkwMy00MTJjLTkzMzYtY2EwMzgwNzgxNmE2",
    android_accent_color: "4498A7",
  },
};

class PushNotification extends GenericModel {
  API = API;
  static API = API;

  static savePush = async function ({ authHeader, body }) {
    let that = this;

    try {
      let response = await fetch(that.API + "/", {
        method: "POST",
        headers: {
          ...authHeader,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  };

  static sendPushNotification = async (pushNotificationObj) => {
    try {
      GenericModel.insertAuthHeader().then((authHeader) => {
        const { id_app } = authHeader;
        const { token, app_id, android_accent_color } = onesignal[id_app];
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Basic '${token}'`,
        };

        /*
                    filter só pode ser usado sem o include_external_user_ids
                    let filters = [
                        { "field": "tag", "key": "id_app", "relation": "=", "value": id_app },
                    ]
                */

        let endpoint = "https://onesignal.com/api/v1/notifications";
        let params = {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            app_id,
            data: {},
            headings: {
              pt: pushNotificationObj.title,
              en: pushNotificationObj.title,
            },
            contents: {
              pt: pushNotificationObj.text,
              en: pushNotificationObj.text,
            },
            android_group: id_app,
            thread_id: id_app,
            include_external_user_ids: pushNotificationObj.users.map(
              (item) => `${item}`
            ),
            android_accent_color: android_accent_color,
            ios_badgeType: "Increase",
            ios_badgeCount: 1,
          }),
        };
        //console.log({ params });
        fetch(endpoint, params)
          .then((res) => res.json())
          .then(({ recipients, ...data }) => {
            if (recipients && recipients > 0) {
              PushNotification.savePush({
                authHeader,
                body: {
                  users: pushNotificationObj.users,
                  push_notification_title: pushNotificationObj.title,
                  push_notification_text: pushNotificationObj.text,
                },
              });
              toast.success(
                `Notificação enviada para ${recipients} recipiente(s)`,
                {
                  type: "success",
                }
              );
            } else {
              toast.error("Nenhum recipiente encontrado!", {
                type: "error",
              });
            }
          });
      });
    } catch (e) {
      console.log(e);
    }
  };
}
export default PushNotification;

/*


                const extraProps = {}

                if(target_users !== "all"){
                    if(target_users !== "roles"){
                        { "field": "include_external_user_ids", }
                        pushNotificationObj.users && pushNotificationObj.users.map((id, index) => {
                            if (index > 0) {
                                filters.push({ "operator": "OR" })
                            } else {
                                filters.push({ "operator": "AND" })
                            }
                            filters.push({ "field": "tag", "key": "id_user", "relation": "=", "value": id })
                        });
                    } else {
                        pushNotificationObj.roles && pushNotificationObj.roles.map((id, index) => {
                            if (index > 0) {
                                filters.push({ "operator": "OR" })
                            } else {
                                filters.push({ "operator": "AND" })
                            }
                            filters.push({ "field": "tag", "key": "id_user", "relation": "=", "value": id })
                        });
                    }

                }
*/
