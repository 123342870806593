import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, IconButton, InputBase, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterList from '@material-ui/icons/FilterList';
import { toast } from 'react-toastify';
const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: 8,
    flex: 1,
    padding: 10
  },
  iconButton: {
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

export default function SearchTextFieldWithFilter(props) {
  const [hasFilters, setHasFilters] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    if(props.filters){
      if(props.filters.length === 0){
        setHasFilters(false);
        return;
      }

      setHasFilters(true);
    }
  }, [props.filters]);

  let text = '';
  let timer = null;

  let defaultProps = {};
  defaultProps.onSearch = (event) => {
    text = event.target.value;
    if (props && props.onSearch) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        props.onSearch(text);
      }, 500);
    }
  };
  defaultProps.onClickFilter = () => {
    toast.dark('On Click Filter');
  };

  defaultProps.placeholder = '';

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={
          props && props.placeholder
            ? props.placeholder
            : defaultProps.placeholder
        }
        inputProps={{ 'aria-label': 'Search Google Maps' }}
        onChange={defaultProps.onSearch}
      />
      {props.onClickFilter ? (
        <>
          {
           hasFilters ?
           (
              <>
                <Divider className={classes.divider} />
                <IconButton
                  color='primary'
                  className={classes.iconButton}
                  aria-label='FilterList'
                  onClick={
                    props && props.onClickFilter
                      ? props.onClickFilter
                      : defaultProps.onClickFilter
                  }
                >
                  <FilterList />
                </IconButton>
              </>
            )
            : null
          }
          
        </>
      ) : null}
    </Paper>
  );
}
