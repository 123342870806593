import GenericModel from './generic_model/GenericModel'
import axios from "axios";
import * as firebase from 'firebase/app';
import 'firebase/storage';

const API = '/background_image';

class BackgroundImage extends GenericModel {
  API = API;
  static API = API;

  static sendBackgroundImageFile = async (obj, id_background_image, progressFunction) => {

    try {

      let formData = new FormData();

      formData.append('file', obj, (new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' +
        obj.name.split('.').pop());

      formData.append('id_background_image', JSON.stringify(id_background_image));

      let headerObj = await BackgroundImage.insertAuthHeader();

      let response = await axios.request({
        url: API + '/send-background-image-file',
        method: "post",
        data: formData,
        headers: headerObj,
        onUploadProgress: (p) => {
          if (progressFunction) {
            progressFunction(Math.round((p.loaded * 100) / p.total));
          }
        }
      });

      return response.data;
      
    } catch (err) {
      throw Error(err);
    }
  };
}

export default BackgroundImage;