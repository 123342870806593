import React from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { red, green, blue } from "@material-ui/core/colors";
import DeleteButton from "../buttons/DeleteButton";
import ArchiveButton from "../buttons/ArchiveButton";
import { DateTimePicker } from "@material-ui/pickers/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CustomDropzone } from "../CustomDropzone";
import DateFnsUtils from "@date-io/date-fns";
import SearchableSelect from "../../components/selectbox/SearchableSelect.js";
import Select from "react-select";
import Role from "../../../models/Role";
import SaveButton from "../buttons/SaveButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import PerfectScrollbar from "react-perfect-scrollbar";
import Message from "../../../models/Message";
import User from "../../../models/User";
import { toast } from "react-toastify";

export default function CupomDescription(props) {
  this.editionTimeout = null;

  // Functions
  this.updateMessage = props.updateObject;
  this.deleteMessage = props.deleteObject;
  this.archiveMessage = props.archiveObject;
  this.sendMessage = props.sendMessage;
  this.saveMessage = props.saveObject;
  this.nullDescription = props.nullDescription;

  // Object to Render
  this.object = props.object;
  this.roles = props.relations[0];
  this.target_users = this.object.target_user;
  this.is_individual = this.object.users_count === 1;
  this.users = [];

  let updateDescriptionState = props.updateDescriptionState
    ? props.updateDescriptionState
    : () => {};

  this.object.roleOptions = this.roles.map((role) => {
    let roleOption = {
      value: role.id_role,
      label: role.role_name,
    };
    return roleOption;
  });


  if (this.object.content?.roles) {
    if (this.object.content.roles !== undefined) {
      this.object.defaultRoleValues = this.object.content.roles.map((role) => {
        return {
          value: role.id_role ? role.id_role : null,
          label: role.role_name ? role.role_name : null,
        };
      });
    } else {
      this.object.defaultRoleValues = [];
    }
  }

  this.onChangeUpdate = (field, force) => (event) => {
    let value = event && event.target ? event.target.value : null;
    if (
      field === 'is_paid'
    ) {
      value = event.target.checked ? 1 : 0;
  }
  this.updateMessage(this.object, field, value, force);
}

  this.onClicksaveMessage = () => {
    this.saveMessage(this.object);
  };

  this.onClickSendMessage = () => {
    this.sendMessage(this.object);
  };

  this.onClickdeleteMessage = () => {
    if (window.confirm("Deseja mesmo deletar esse item?")) {
      this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the user
      this.deleteMessage(this.object);
    }
  };

  this.onClickArchiveContent = () => {
    this.onChangeUpdate("is_archived")({
      target: {
        value: this.object.is_archived ? 0 : 1,
      },
    });
    this.archiveMessage(this.object);
  };

  this.onChangeUpdateRoles = (value) => {
    let roles;
    if (value != null) {
      //null cant use map function
      roles = value.map((obj) => {
        return {
          //revert the label to put the id_role and role_name in the Content Object
          id_role: obj.value,
          role_name: obj.label,
        };
      });
    } else {
      roles = [];
    }
    this.updateMessage(this.object, "content.roles", roles, true);
  };

  this.onDropActionFile = async (file) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedFile: completed,
      };
      props.progressFunction(completedState);
    };
    let fileType = file.type;
    let messageFileType = fileType.split("/")[0];
    if (messageFileType === "application") {
      messageFileType = fileType.split("/")[1];
    }
    if (messageFileType === "image") {
      //alert("Arquivo de imagem!");
      try {
        let imageUrl = null;
        if (this.object) {
          imageUrl = await Message.sendImageFile(file, null, progressFunction);
          this.updateMessage(this.object, "message_file", imageUrl.url, true);
          this.updateMessage(this.object, "message_file_type", 1, true);
        }
        // Reset completed variable (progress bar)
        progressFunction(0);
      } catch (err) {
        console.error(err);
      }
    } else if (messageFileType === "pdf") {
      //alert("Arquivo de imagem!");
      try {
        let imageUrl = null;
        if (this.object) {
          imageUrl = await Message.sendImageFile(file, null, progressFunction);
          this.updateMessage(this.object, "message_file", imageUrl.url, true);
          this.updateMessage(this.object, "message_file_type", 3, true);
        }
        // Reset completed variable (progress bar)
        progressFunction(0);
      } catch (err) {
        console.error(err);
      }
    } else {
      toast.error("Arquivo não suportado!");
    }
  };

  this.deleteFile = () => {
    if (this.object && this.object.message_file) {
      return (
        this.updateMessage(this.object, "message_file", null, true),
        this.updateMessage(this.object, "message_file_type", null, true)
      );
    }
  };

  this.selectHandler = async (user) => {
    let label = user.user_name + "-" + user.user_phone;
    let value = user;
    let message = this.object ? this.object : {};
    if (message.user) {
      if (message.user.length > 0) {
        message.user[0].label = label;
        message.user[0].value = user;
      } else {
        message.user.push({
          label: label,
          value: user,
        });
      }
    } else {
      message.user.label = label;
      message.user.value = user;
    }
    updateDescriptionState({ user: message });
  };

  this.radioGroupHandler = async (event) => {
    let value = event.target.value;
    let message = this.object ? this.object : {};
    message.target_user = value;
    updateDescriptionState({ target_user: message });
    let users;
    try {
      switch (value) {
        case "all":
          this.is_individual = false;
          this.target_users = value;
          this.is_roles = false;
          break;
        case "individual":
          this.is_individual = true;
          this.target_users = value;
          this.is_roles = false;
        /*if (this.object.user.value) {
                    this.selectHandler(this.object.user.value);
                }*/
        case "roles":
          this.is_individual = false;
          this.target_users = value;
          this.is_roles = true;
          break;
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <div style={{ height: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          alignItems: "center",
          padding: "30px 20px 10px 20px",
        }}
      >
        <Grid container spacing={2}>
          <Grid xs={12} container direction="row" spacing={1}>
            <Grid xs={12} container spacing={2}>
              <Grid xs={4} item>
                <TextField
                  label="Título"
                  value={
                    this.object.message_title ? this.object.message_title : ""
                  }
                  style={{ width: "100%" }}
                  onChange={this.onChangeUpdate("message_title", false)}
                  onBlur={this.onChangeUpdate("message_title", true)}
                  multiline
                />
              </Grid>
              <Grid xs={4} item></Grid>
              <Grid xs={1} item>
                <TextField
                  label="ID"
                  value={this.object.id_message ? this.object.id_message : ""}
                  style={{ width: "100%" }}
                  disabled
                />
              </Grid>
              <Grid xs={3} item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    label="Data da ultima alteração"
                    value={this.object.updated_date}
                    style={{ width: "100%" }}
                    format="dd/MM/yyyy HH:mm"
                    disabled
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: 10 }}>
              <Grid xs={6} item>
                <Grid container>
                  <Grid item xs={12}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        letterSpacing: "0.00938em",
                        float: "left",
                        color: "rgba(0, 0, 0, 0.54)",
                        paddingBottom: "10px",
                      }}
                    >
                      Boleto - Tamanho Máximo: 1 GB
                      {this.object && this.object.message_file ? (
                        <a href="#" onClick={this.deleteFile}>
                          {" "}
                          Remover Arquivo
                        </a>
                      ) : null}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    {this.object &&
                    this.object.message_file &&
                    this.object.message_file_type === 1 ? (
                      <img
                        style={{
                          width: "30vw",
                          height: "15vw",
                          objectFit: "cover",
                        }}
                        src={
                          this.object.message_file
                            ? this.object.message_file
                            : null
                        }
                        id={"image-modal"}
                      />
                    ) : this.object.message_file &&
                      this.object.message_file_type === 3 ? (
                      <a
                        target="_blank"
                        href={
                          this.object.message_file
                            ? this.object.message_file
                            : null
                        }
                        rel="noreferrer"
                      >
                        Clique aqui para acessar o boleto.
                      </a>
                    ) : (
                      <div id="picker">
                        <CustomDropzone
                          onDropAction={this.onDropActionFile}
                          maxFiles={1}
                          multiple={false}
                          completed={props.completed.completedFile}
                          inactiveLabel={
                            "Arraste o PDF ou a imagem (.jpeg, .png) do seu boleto para cá"
                          }
                          activeLabel={"Solte o material de treinamento"}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={6}>
                <Grid xs={12} item>
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      lineHeight: 1,
                      letterSpacing: "0.00938em",
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      paddingBottom: "10px",
                      paddingLeft: "20px",
                      paddingTop: "11px",
                    }}
                  >
                    Mensagem -{" "}
                    {this.object && this.object.message_content
                      ? this.object.message_content.length
                      : 0}
                    /1000
                  </span>
                </Grid>
                <TextareaAutosize
                  aria-label={"Mensagem"}
                  placeholder={"Mensagem(1000 caracteres)"}
                  maxLength={1000}
                  rows={4}
                  value={
                    this.object && this.object.message_content
                      ? this.object.message_content
                      : ""
                  }
                  onChange={this.onChangeUpdate("message_content", true)}
                  onBlur={this.onChangeUpdate("message_content", true)}
                  style={{
                    width: "90%",
                    height: "60%",
                    padding: "10px 10px",
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              xs={12}
              container
              direction={"column"}
              style={{ height: "60px" }}
            >
              <Grid xs={6} item>
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: "0.00938em",
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingBottom: "10px",
                    paddingLeft: "20px",
                    paddingTop: "2px",
                  }}
                >
                  Código do boleto
                </span>
                <TextareaAutosize
                  aria-label={"Cupom"}
                  placeholder={
                    "Insira o código do cupom. Ex: XXXX-XXXX-XXX-XXX"
                  }
                  maxLength={100}
                  rows={1}
                  value={
                    this.object && this.object.cupon_code
                      ? this.object.cupon_code
                      : ""
                  }
                  onChange={this.onChangeUpdate("cupon_code", true)}
                  onBlur={this.onChangeUpdate("cupon_code", true)}
                  style={{
                    width: "90%",
                    height: "17%",
                    padding: "10px 10px",
                  }}
                />
              </Grid>
              <Grid xs={6} item>
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: "0.00938em",
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingBottom: "10px",
                    paddingLeft: "20px",
                    paddingTop: "2px",
                  }}
                >
                  Código do PIX
                </span>
                <TextareaAutosize
                  aria-label={"Pix"}
                  placeholder={"Insira o código do PIX. Ex: XXX-XXX-XXX-XX"}
                  maxLength={100}
                  rows={1}
                  value={
                    this.object && this.object.pix_code
                      ? this.object.pix_code
                      : ""
                  }
                  onChange={this.onChangeUpdate("pix_code", true)}
                  onBlur={this.onChangeUpdate("pix_code", true)}
                  style={{
                    width: "90%",
                    height: "17%",
                    padding: "10px 10px",
                  }}
                />
              </Grid>
            </Grid>

            <Grid xs={13} container direction={"column"}>
              <RadioGroup
                row
                style={{ paddingLeft: 9, paddingTop: 20 }}
                onChange={this.radioGroupHandler}
                value={this.target_users}
              >
                <Grid xs={2} item>
                  <FormControlLabel
                    control={<Radio color={"primary"} />}
                    label={"Enviar Para Todos"}
                    value={"all"}
                  />
                </Grid>
                <Grid xs={4} item>
                  <FormControlLabel
                    control={<Radio color={"primary"} />}
                    label={"Enviar para um usuário específico"}
                    value={"individual"}
                  />
                  <SearchableSelect
                    label="Texto da Notificação"
                    style={{ width: "80%", marginTop: "10px" }}
                    placeholder={"Selecione o Usuário"}
                    searchApiUrl={"/user/select_full_user"}
                    actionOnSelectedOption={this.selectHandler}
                    value={{
                      label: this.object.user.label,
                      value: this.object.user.value,
                    }}
                    defaultValue={this.object.user}
                    disabled={this.target_users != "individual"}
                  ></SearchableSelect>
                </Grid>

                <Grid xs={5} style={{ marginLeft: 3 }} item>
                  <FormControlLabel
                    control={<Radio color={"primary"} />}
                    label={"Enviar para Usuários de papéis específicos"}
                    value={"roles"}
                  />
                  <Select
                    style={{ marginTop: "10px" }}
                    placeholder={"Selecione os Papéis"}
                    options={this.object.roleOptions}
                    value={this.object.defaultRoleValues}
                    onChange={this.onChangeUpdateRoles}
                    isDisabled={this.target_users != "roles"}
                    isMulti
                  ></Select>
                </Grid>
              </RadioGroup>
            </Grid>
            <Grid item xs={3}>
                  <Checkbox
                    onChange={this.onChangeUpdate("is_paid", true)}
                    checked={this.object.is_paid === 1}
                  />
                  {"Marcar como pago "}
                </Grid>
            <Grid xs={12} style={{ marginTop: "20px" }}>
              <SaveButton
                style={{ float: "right" }}
                onClickSave={this.onClickSendMessage}
                ref={(ref) => {
                  this.saveButton = ref;
                }}
                text={"Enviar"}
              />
              <SaveButton
                style={{ float: "right" }}
                onClickSave={this.onClicksaveMessage}
                ref={(ref) => {
                  this.saveButton = ref;
                }}
                text={"Salvar Rascunho"}
              />
              <ArchiveButton
                style={{ float: "right" }}
                onClickArchive={this.onClickArchiveContent}
                is_archived={this.object.is_archived}
                ref={(ref) => {
                  this.archiveButton = ref;
                }}
              />
              <DeleteButton
                style={{ float: "right", background: "#CC0B0B" }}
                onClickDelete={this.onClickdeleteMessage}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
