import axios from "axios";
import * as firebase from 'firebase/app';
import 'firebase/storage';
import moment from "moment";
import GenericModel from './generic_model/GenericModel';
const API = '/messages';



class Message extends GenericModel {
    API = API;
    static API = API;

    static sendImageFile = async (obj, id_training, progressFunction) => {
//olhar
        try {
            let formData = new FormData();
            formData.append('file', obj, (new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + obj.name.split('.').pop());

            let headerObj = await GenericModel.insertAuthHeader();

            let response = await axios.request({
                url: API + '/send-image-file',
                method: "post",
                data: formData,
                headers: headerObj,
                onUploadProgress: (p) => {
                    if (progressFunction) {
                        progressFunction(Math.round((p.loaded * 100) / p.total));
                    }
                }
            });

            return response.data;
        } catch (err) {
            throw Error(err);
        }
    };

    static sendVideo = async (obj, id_training, progressFunction) => {

        return new Promise((resolve, reject) => {
            var storageRef = firebase.storage().ref();

            let bucketImg = storageRef.child((new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + obj.name.split('.').pop());

            let uploadTask = bucketImg.put(obj);

            uploadTask.on('state_changed', function (snapshot) {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                progressFunction(progress);

                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            }, function (error) {
                reject(error);
                // Handle unsuccessful uploads
            }, function () {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    resolve({ url: downloadURL });
                });
            });
        });
    };

    saveMessageDraft = async function () {
        let that = this;

        let authHeader = {};

        try {
            authHeader = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            let response = await fetch(that.API + '/draft_message', {
                method: "POST",
                headers: authHeader,
                body: JSON.stringify(this),
            });

            if (response.status >= 400) {
                throw Error(response.statusText);
            }

            let object = await response.json();

            return new GenericModel(object, that.API);
        } catch (err) {
            throw Error(err);
        }
    };

    sendMessage = async function () {
        let that = this;
        let authHeader = {};

        try {
            authHeader = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            let response = await fetch(that.API + '/send_message', {
                method: "POST",
                headers: authHeader,
                body: JSON.stringify(this),
            });

            if (response.status >= 400) {
                throw Error(response.statusText);
            }

            let object = await response.json();

            return new GenericModel(object, that.API);
        } catch (err) {
            throw Error(err);
        }
    };

    removeMessage = function () {
        return new Promise(async (resolve, reject) => {
            this.deleted_date = moment().format("YYYYMMDDHHmmss");

            let authHeader = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            fetch(this.API + '/remove_messages', {
                method: "POST",
                headers: authHeader,
                body: JSON.stringify(this),
            })
                .then(async function () {
                    return resolve();
                })
                .catch(function (err) {
                    return reject(err);
                });
        });
    };

    archiveMessage = async function () {
        let that = this;

        let authHeader = {};

        try {
            authHeader = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            let response = await fetch(that.API + '/archive_messages', {
                method: "POST",
                headers: authHeader,
                body: JSON.stringify(this),
            });

            if (response.status >= 400) {
                throw Error(response.statusText);
            }

            let object = await response.json();

            return new GenericModel(object, that.API);
        } catch (err) {
            throw Error(err);
        }
    };

}

export default Message;