import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Sector, Cell, Legend, Tooltip
} from 'recharts';

/*
const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
*/

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#d50000', '#aa00ff', '#304ffe', '#00c853'];
const OTHERSCOLOR = '#666666';
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor='middle' dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};



export default class DashboardPieChart extends PureComponent {
  
  state = {
    data: []
    };
    
    constructor(props) {
        super(props);
        this.state.data = props.data;
    }
    

    render() {
        return (
        <PieChart width={200} height={400}>
            
            <Pie
            data={this.state.data}
            cx={100}
            cy={100}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={90}
            innerRadius={0}
            fill="#8884d8"
            dataKey="value"
            >
            {
                this.state.data.map((entry, index) => <Cell key={`cell-${index}`} fill={(entry.name === "Outros") ? OTHERSCOLOR : COLORS[index % COLORS.length]} />)
            }
            </Pie>
            <Legend verticalAlign="top"/>
            <Tooltip />

            
        </PieChart>
        
        );
    }
}
//
//