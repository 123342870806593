import React from "react";
import { Grid, Paper } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faVideo,
  faCamera,
  faFileAlt,
  faFilePdf,
  faEye,
  faCommentDots,
  faFile,
  faTicketAlt,
  faImages,
} from "@fortawesome/free-solid-svg-icons";
import DownloadButton from "../buttons/DownloadButton";
import Content from "../../../models/Content";
import * as FileSaver from "file-saver";
import moment from "moment";

export default function ContentEngagementCard(props) {
  const page = window.location.pathname;

  const EXCEL_TYPE =
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";

  this.editionTimeout = null;

  // Object to Render
  this.object = props.object;

  let content_type = "video";
  /*if (this.object.training && this.object.training.training_video) {
        content_type = 'video';
    } else if (this.object.training && this.object.training.training_file) {
        content_type = 'pdf';
    } else if (this.object.form != null) {
        content_type = 'form';
    } else if (this.object.training && this.object.training.training_images && this.object.training.training_images.length > 0) {
        content_type = 'image';
    } else if (this.object.training && this.object.training.is_challenge_photo) {
        content_type = 'challenge';
    }*/
  switch (this.object.content_type) {
    case 1:
      content_type = "video";
      break;
    case 2:
      content_type = "image";
      break;
    case 3:
      content_type = "form";
      break;
    case 4:
      content_type = "challenge";
      break;
    case 5:
      content_type = "pdf";
      break;
    case 6:
      content_type = "trail";
      break;
    case 8:
      content_type = "quiz";
      break;
  }
  this.object.title =
    (content_type === "video" ||
      content_type === "pdf" ||
      content_type === "image" ||
      content_type === "challenge" ||
      content_type === "trail") &&
    this.object.training
      ? this.object.training.training_title
      : (content_type === "form" || content_type === "quiz") && this.object.form
      ? this.object.form.form_title
      : "";

  this.object.id = this.object.id_content ? this.object.id_content : "SEM ID";

  this.content_type =
    content_type === "video"
      ? "VIDEO"
      : content_type === "pdf"
      ? "ARQUIVO"
      : content_type === "form" || content_type === "quiz"
      ? "FORMULÁRIO"
      : content_type === "image"
      ? "IMAGEM"
      : content_type === "challenge"
      ? "DES. FOTO"
      : content_type === "trail"
      ? "TRILHA"
      : "";

  this.object.content_type_name = this.content_type;

  if (this.content_type === "FORMULÁRIO") {
    this.object.is_form = true;
  } else {
    this.object.is_form = false;
  }

  // Functions
  this.updateContent = props.updateObject;
  this.deleteContent = props.deleteObject;
  this.callDescription = props.callDescription;

  this.onChangeUpdate = (field, force) => (event) => {
    let value = event && event.target ? event.target.value : null;
    this.updateContent(this.object, field, value, force);
  };

  this.onClickCallDescription = () => {
    this.callDescription(this.object);
  };

  this.onClickDownloadAnswers = async (event) => {
    event.stopPropagation();
    await Content.getexcel(this.object.id_content.toString())
      .then((data) => {
        let filename = this.object.form_filename;
        let blob = new Blob([data], { type: EXCEL_TYPE });
        FileSaver.saveAs(blob, filename);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div
      style={{
        height: "6vw",
        backgroundColor:
          props.chosenId === this.object.id_content ? "#4498A7" : "#f2f2f2",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={this.onClickCallDescription}
    >
      {/* Verifies if the content should be a image on the contents page or icons in the task contents page*/}
      {this.object.is_challenge == 1 ? (
        <FontAwesomeIcon
          icon={
            content_type === "video"
              ? faVideo
              : content_type === "pdf"
              ? faFilePdf
              : content_type === "image"
              ? faImage
              : content_type === "challenge"
              ? faCamera
              : content_type === "form"
              ? faFileAlt
              : content_type === "quiz"
              ? faTicketAlt
              : content_type === "trail"
              ? faImages
              : faFile
          }
          style={{
            width: "10vw",
            height: "3vw",
            objectFit: "cover",
            marginRight: "20px",
            marginLeft: "20px",
          }}
          id={"image-modal"}
          color={"#424242"}
        />
      ) : (
        <img
          style={{
            width: "10vw",
            height: "5vw",
            objectFit: "cover",
            marginRight: "20px",
            marginLeft: "20px",
          }}
          src={
            (content_type === "video" ||
              content_type === "pdf" ||
              content_type === "image" ||
              content_type === "challenge" ||
              content_type === "trail") &&
            this.object.training
              ? this.object.training.training_cover_image
              : content_type === "form" || content_type === "quiz"
              ? this.object.form
                ? this.object.form.form_image
                  ? this.object.form.form_image
                  : null
                : null
              : null
          }
          id={"image-modal"}
        />
      )}
      <div style={{ flex: 3 }}>
        <span
          style={{
            float: "left",
            padding: "0",
            fontSize: "18px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1",
            letterSpacing: "0.00938em",
          }}
        >
          {this.object.title}
        </span>
        {
          page === "/contents" ? (
            <>
              <br />
              <span
                style={{
                  float: "left",
                  padding: "0",
                  fontSize: "18px",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: "400",
                  lineHeight: "1",
                  letterSpacing: "0.00938em",
                }}
              >
                {"Tipo: "} {this.object.is_pill === 1 ? "Pílula" : "Curso"}
              </span>
            </>
          ) : null
        }
      </div>
      <FontAwesomeIcon
        icon={this.content_type !== "FORMULÁRIO" ? faEye : faCommentDots}
        style={{
          width: "5vw",
          height: "2vw",
          objectFit: "cover",
          marginRight: "5px",
        }}
        id={"image-modal"}
        color={"#424242"}
      />
      <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <span
          style={{
            float: "left",
            padding: "0",
            fontSize: "18px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1",
            letterSpacing: "0.00938em",
          }}
        >
          {this.content_type !== "FORMULÁRIO"
            ? this.object.view_number
              ? this.object.view_number + "%"
              : "0" + "%"
            : this.object.answer_number
            ? this.object.answer_number + "%"
            : "0" + "%"}
        </span>
      </div>
      <div style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        {this.object.is_form && this.object.has_answers ? (
          <DownloadButton
            style={{ backgroundColor: "#4caf50" }}
            onClickDownload={this.onClickDownloadAnswers}
            ref={(ref) => {
              this.downloadButton = ref;
            }}
          ></DownloadButton>
        ) : null}
      </div>

      <div style={{ flex: 1 }}>
        <span
          style={{
            float: "flex",
            padding: "0",
            fontSize: "18px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1",
            letterSpacing: "0.00938em",
          }}
        >
          {this.object.is_published == 1 ? "Publicado" : "Salvo como rascunho"}
        </span>
      </div>
    </div>
  );
}
