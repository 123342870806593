import React, { useState, useEffect } from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

import { CustomIconButton } from '../../../components/buttons/ThemeButtons';

function ImageData({ label, data }) {
  return (
    <>
      <span
        style={{
          color: "#333333",
          fontWeight: 600,
          fontSize: 16
        }}
      >
        {label}
      </span>
      <p
        style={{
          color: "#333333",
          fontWeight: 400,
          fontSize: 14
        }}
      >
        {data}
      </p>
    </>
  );
}

export default function ImagePreviewModal({
  isVisible,
  handleClose,
  image
}) {
  const [imageTitle, setImageTitle] = useState("");
  const [imageDescritpion, setImageDescritpion] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    if (!image) {
      return;
    }

    const { title, image_url, description } = image;
    
    setImageTitle(title);
    setImageDescritpion(description);
    setImageUrl(image_url);
  }, [isVisible]);

  return (
    <Modal
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflowY: 'scroll' }}
    >
      <Box
        bgcolor="#FFF"
        border="1px solid #E0E5EC"
        borderRadius={2}
        display='flex'
        flexDirection='column'
        margin='16px auto'
        width='50%'
        p={2}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          mb={2}
        >
          <CustomIconButton
            onClick={handleClose}
          >
            <CloseIcon />
          </CustomIconButton>
        </Box>

        <Box>
          <img
            src={imageUrl}
            alt="merchandising-image"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: 4,
              maxHeight: "60vh",
              objectFit: 'cover'
            }}
          />
        </Box>
        <Box
          mt={3}
        >
          {
            imageTitle && (
              <ImageData
                label="Título"
                data={imageTitle}
              />
            )
          }
          {
            imageDescritpion && (
              <ImageData
                label="Legenda"
                data={imageDescritpion}
              />
            )
          }
        </Box>
      </Box>
    </Modal>
  );
}