import React, { useState, useEffect, useRef} from 'react';
import { Box, IconButton } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import Divider from '@mui/material/Divider';
import { useHistory, useParams } from 'react-router-dom';
import { Avatar, Modal, Stack } from '@mui/material';
import { Checkbox, FormControlLabel } from '@mui/material';
import moment from 'moment';

import { ChatInput, TextInput } from '../../../components/text_fields/ThemeTextInputs';
import AlertDialog from '../../../components/alert/ThemeAlertDialog';
import { ButtonBlueBorder, ButtonDarkBlue, CustomIconButton } from '../../../components/buttons/ThemeButtons';
import SlickCarrousel from '../../../components/slick_carrousel/SlickCarrousel';
import VisualMerchandisingAnswer from '../../../../models/VisualMerchandisingAnswer';
import { showToast } from '../../../components/toast/ThemeToast';
import { handleChangeInputTextValue } from '../../../utils';

function Message({
  direction,
  justifyContent,
  handleShowCommentaryInput,
  handleShowApprovalAlert,
  showImageModal,
  images,
  message=null,
  userName=null,
  wasApproved=false,
  hasFeedback=false
}) {
  return (
    <Stack direction="row" justifyContent={justifyContent} mr={1} mb={2}>
      <Stack
        direction={direction}
        alignItems="flex-end"
        spacing={1}
        justifyContent={justifyContent}
      >
        <Box
          bgcolor={wasApproved ? "#DBFFDD" : message !==null && images.length > 0 ? "#FFE0BF": "#E0E5EC"}
          borderRadius={justifyContent === "start" ? "16px 16px 16px 0px" : "16px 16px 0px 16px"}
          display="flex"
          flexDirection="row"
          padding="24px 8px"
          alignItems="center"
        >
          <Stack>
            <Stack 
              direction="row"
              alignItems="center" 
              spacing={2} 
              mb={1}
            >
              {
                images.length > 3 ? (
                  <Box
                    marginX={4}
                  >
                    <span>+{images.length - 3}</span>
                  </Box>
                ) : null
              }
              {
                images.map((image, index) => {
                  if(index > 2){
                    return;
                  }

                  return(
                    <Box
                      height={120}
                      width={120}
                    >
                      <img
                        src={image.image_url}
                        alt="merchandising-image"
                        onClick={() => showImageModal(index)} 
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: 160,
                          maxWidth: 160,
                          borderRadius: 8,
                          cursor: 'pointer'
                        }}
                      />
                    </Box>
                  )
                })
              }
            </Stack>
            {
              message && (
                <span
                  style={{
                    padding: '0 16px',
                    fontSize: 14,
                    color: "#333333"
                  }}
                >
                  {message}
                </span>
              )
            }
          </Stack>
        </Box>
        {
          userName ? (<Avatar sx={{ bgcolor: "#E0E5EC" }}>{userName[0]}</Avatar>) : null
        }
      </Stack>
      {
        justifyContent === "start" 
        && images.length > 0 
        && !hasFeedback?
          <Stack direction="column" justifyContent="start">
            <Stack direction="row" justifyContent="flex-start" spacing={1}>
              <CustomIconButton
                onClick={() => handleShowApprovalAlert(images[0])}
                sx={{
                  '&:hover': {
                    backgroundColor: '#DBFFDD',
                  },
                  marginLeft: 1
                }}
              >
                <CheckCircleIcon sx={{ color: "#4CAF50" }} />
              </CustomIconButton>

              <CustomIconButton
                onClick={() => handleShowCommentaryInput(images[0])}
                sx={{
                  '&:hover': {
                    backgroundColor: '#FFE0BF',
                  }
                }}
              >
                <CommentIcon sx={{ color: "#F28C1F " }} />
              </CustomIconButton>
            </Stack>
          </Stack>
          : null
      }
    </Stack>
  );
}

function DateDivider({ date }) {
  return (
    <Divider sx={{ marginY: 8 }}>
      <span
        style={{
          color: '#979899',
          fontWeight: 400,
          fontSize: 14,
        }}
      >
        {moment(date).format("DD/MM/YYYY")}
      </span>
    </Divider>
  );
}

function ImagesModal({ isVisible, handleClose, images, selectedImage, setSelectedImage}) {
  if(!selectedImage){
    return;
  }

  return (
    <Modal
      open={isVisible}
      onClose={handleClose}
    >
      <Box
        width="50%"
        margin=" 20px auto"
        border='none'       
      >
        <Box 
          position='relative' 
          height="70vh"
        >
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon sx={{ color: "#162233" }} />
          </IconButton>
          <Box
            width="100%"
            height="100%"
            display='flex'
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={selectedImage.image_url}
              alt="merchandising-image"
              style={{
                maxHeight: '70vh',
                maxWidth: "100%",
                borderRadius: 8
              }}
            />
          </Box>
        </Box>
        <Box
          width='100%'
          height={100}
          mt={1}
        >
          <SlickCarrousel
            images={images}
            onClickImage={(image) => setSelectedImage(image)}
          />
        </Box>
      </Box>
    </Modal>
  );
}

function ApproveImagesModal({ isVisible, handleClose }) {
  const [images, setImages] = useState([
    {
      id: 1,
      src: "https://images.unsplash.com/photo-1567958451986-2de427a4a0be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      isChecked: false,
      feedback: ""
    },
    {
      id: 2,
      src: "https://images.unsplash.com/photo-1567958451986-2de427a4a0be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      isChecked: false,
      feedback: ""
    },
    {
      id: 3,
      src: "https://images.unsplash.com/photo-1567958451986-2de427a4a0be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      isChecked: false,
      feedback: ""
    },
    {
      id: 4,
      src: "https://images.unsplash.com/photo-1567958451986-2de427a4a0be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
      isChecked: false,
      feedback: ""
    },
  ]);
  const [approvedCount, setApprovedCount] = useState(0);

  function handleApproveAll(){
    const checkAll = approvedCount !== images.length;
    setApprovedCount(0);

    setImages(old => old.map(image => {
      if(checkAll){
        image.isChecked = true;
        setApprovedCount(old => old + 1);
      }else {
        image.isChecked = false;
        setApprovedCount(old => old - 1);
      }

      return image;
    }));
  }

  function handleAproveOne(image){
    setImages(old => old.map(oldImage => {
      if(image.id === oldImage.id){
        oldImage.isChecked = !oldImage.isChecked;

        if(oldImage.isChecked){
          setApprovedCount(old => old + 1);
        }
        else {
          setApprovedCount(old => old - 1);
        }

        return oldImage;
      }
      return oldImage;
    }));
  }

  return (
    <Modal
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        bgcolor="#FFF"
        border="1px solid #E0E5EC"
        borderRadius={8}
        display='flex'
        flexDirection='column'
        margin='16px auto'
        width='50%'
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          borderBottom="1px solid #D5DCE6"
          px={2}
          py={2}
        >
          <span
            style={{
              color: "#333333",
              fontSize: 22
            }}
          >
            Avaliar fotos
          </span>

          <CustomIconButton
            onClick={handleClose}
          >
            <CloseIcon />
          </CustomIconButton>
        </Box>

        <Box
          paddingX={2}
          height="50vh"
          overflow="auto"
        >
          <p
            style={{
              color: "#162233",
              fontSize: 15
            }}
          >
            Marque todas para aprovar ou comente na fotos que deseja receber novamente.
          </p>
          <FormControlLabel
            sx={{color: "#666666", fontSize: 19, fontWeight: 600 }}
            label="Aprovar todas"
            control={
              <Checkbox
                checked={approvedCount === images.length}
                onChange={handleApproveAll}
                sx={{
                  color: "#979899",
                  '&.Mui-checked': {
                    color: "#4CAF50",
                  },
                }}
              />
            }
          />
          <Stack spacing={4}>
            {
              images.map(image => (
                <Box width="100%">
                  <Stack direction="row" spacing={2}>
                    <Stack>
                      <Checkbox
                        checked={image.isChecked}
                        onChange={() => handleAproveOne(image)}
                        sx={{
                          color: "#979899",
                          '&.Mui-checked': {
                            color: "#4CAF50",
                          },
                        }}
                      />
                      <IconButton>
                        {
                          !image.isChecked && (
                            <CommentIcon sx={{ color: "#F28C1F " }} />
                          )
                        }
                      </IconButton>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <img 
                        src={image.src}
                        alt="image-merchandising"
                        style={{
                          width: 180,
                          height: 180
                        }}
                      />

                      <span
                        style={{
                          color: "#979899",
                          fontSize: 10
                        }}
                      >
                        Envio: 00/00/0000 - 00:00
                      </span>
                    </Stack>
                  </Stack>
                  {
                    !image.isChecked && (
                      <TextInput 
                        label="Escreva seu comentário"
                        placeholder="Escreva seu comentário"
                        variant="outlined"
                        style={{width: '80%', marginTop: 16}}
                      />
                    )
                  }
                </Box>
            ))
          }
          </Stack>
        </Box>
        <Box
          display='flex'
          justifyContent='flex-end'
          marginY={2}
          paddingX={2}
          paddingTop={2}
          borderTop="1px solid #D5DCE6"
        >
          <ButtonDarkBlue>
            Enviar feedback
          </ButtonDarkBlue>
        </Box>
      </Box>
    </Modal>
  );
}

function VisualMerchandisingAnswerChat() {
  const [showCommentaryInput, setShowCommentaryInput] = useState(false);
  const [showApprovalAlert, setShowApprovalAlert] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [selectedImagesModal, setSelectedImagesModal] = useState([]);
  const [showApproveImagesModal, setShowApproveImagesModal] = useState(false);
  const [showApproveSubmissionModal, setShowApproveSubmissionModal] = useState(false);
  const [showReproveSubmissionModal, setShowReproveSubmissionModal] = useState(false);
  const [commentary, setCommentary] = useState("");
  const [user, setUser] = useState(null);
  const [visualMerchandisingTitle, setVisualMerchandisingTitle] = useState("");
  const [visualMerchandisingImages, setVisualMerchandisingImages] = useState([]);
  const [visualMerchandisingIdUser, setVisualMerchandisingIdUser] = useState(null);
  const [visualMerchandisingId, setVisualMerchandisingId] = useState(null);
  const [visualMerchandisingIdStatus, setVisualMerchandisingIdStatus] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImageIdCommentary, setSelectedImageIdCommentary] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const history = useHistory();
  const {id} = useParams();
  const messagesEndRef = useRef(null);
  const dates = new Set();

  useEffect(() => {
    fetchSubmissionDetail();
  }, []);

  async function fetchSubmissionDetail(){
    try{
      if(!id){
        return;
      }

      const response = await VisualMerchandisingAnswer.getSubmissionDetailById(id);

      setUser(response.user);
      setVisualMerchandisingTitle(response.visualMerchandising.title);
      setVisualMerchandisingImages(response.visualMerchandising.images);
      setVisualMerchandisingIdUser(response.id_user);
      setVisualMerchandisingId(response.id_visual_merchandising);
      setVisualMerchandisingIdStatus(response.id_status);
      setChatMessages(response.chatMessages);
      setIsLoading(false);

      response.chatMessages.map(async (chatMessage) => {
        if(!chatMessage.id_visual_merchandising_chat_messages){
          return;
        }
        
        if(chatMessage.id_user === response.id_user){
          if(chatMessage.was_read === 0){
            await VisualMerchandisingAnswer.readMessage(id, chatMessage.id_visual_merchandising_chat_messages);
          }
        }
      });
    }catch(err){
      console.log(err);
    }
  }

  function handleShowCommentaryInput(image) {
    if(!image){
      return;
    }

    if(!image.imageId){
      return;
    }

    setShowCommentaryInput(true);
    setSelectedImageIdCommentary(image.imageId);
  }

  async function handleApprove(image){
    try{
      if(!image){
        return;
      }

      const response = await VisualMerchandisingAnswer.approveImage(id, image.imageId);
      fetchSubmissionDetail();
    }catch(err){
      console.log(err);
    }
  }

  async function handleSendCommentary() {
    try {
      const response = await VisualMerchandisingAnswer.sendCommentaryFeedback(id, {
        message: commentary,
        imageId: selectedImageIdCommentary
      });// 6 reavaliando
        const stateChange= await  VisualMerchandisingAnswer.ChangeSubmissionState(id, 6)
      setCommentary("");
      fetchSubmissionDetail();
    } catch(err){
      console.log(err);
    }
  }

  function handleClearCommentaryInput(){
    setCommentary("");
    setShowCommentaryInput(false);
  }

  function handleOpenImagesModal(images, indexImageClicked){
    setSelectedImagesModal(images);
    setSelectedImage(images[indexImageClicked]);
    setShowImagesModal(true);
  }

  function handleApproveSubmission(){
    setShowApproveSubmissionModal(true);
  }
  function handleReproveSubmission(){
    setShowReproveSubmissionModal(true);
  }

  async function handleConfirmApproveSubmission(){
    try{
      const response = await VisualMerchandisingAnswer.approveSubmission(id);
      showToast("success", "Resposta aprovada com sucesso.");
      history.push(`/visual_merchandising/answers/${visualMerchandisingId}`);
    }catch(err){
      console.log(err);
      showToast("error", "Erro ao aprovar resposta. Tente novamente mais tarde.");
    }
  }
  async function handleChangeStatusSubmission(idStatus){
    try{
      const response = await VisualMerchandisingAnswer.ChangeSubmissionState(id,idStatus);
      showToast("success", idStatus===5?"Resposta foi reprovada com sucesso.":"Resposta serão aceitas com sucesso.");
      history.push(`/visual_merchandising/answers/${visualMerchandisingId}`);
      
    }catch(err){
      console.log(err);
      showToast("error", "Erro ao reprovar resposta. Tente novamente mais tarde.");
    }
  }

  const renderDate = (chatMessage, dateNum) => {
    dates.add(dateNum);
    return <DateDivider date={chatMessage.updated_date} />
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  if(isLoading){
    return null;
  }
  
  return (
    <>
    {/*   <AlertDialog
        title="Aprovar todas"
        description="Você deseja aprovar todas as fotos ou avaliá-las individualmente?"
        confirmTextButton="Aprovar todas as fotos"
        handleClose={() => setShowApprovalAlert(false)}
        isOpen={showApprovalAlert}
        alertFooter={
          <Box>
            <ButtonBlueBorder
              onClick={() => handleApproveIndividual()}
              style={{ marginRight: 16 }}
            >
              Avaliar individualmente
            </ButtonBlueBorder>

            <ButtonDarkBlue
              onClick={() => handleApproveAll()}
            >
              Aprovar todas as fotos
            </ButtonDarkBlue>
          </Box>
        }
      /> */}
      {
        selectedImagesModal.length > 0 ? (
          <ImagesModal
            isVisible={showImagesModal}
            handleClose={() => setShowImagesModal(false)}
            images={selectedImagesModal}
            selectedImage={selectedImage}
            setSelectedImage={(image) => setSelectedImage(image)}
          />
        )
        : null
      }
     {/*  <ApproveImagesModal
        handleClose={() => setShowApproveImagesModal(false)}
        isVisible={showApproveImagesModal}
      /> */}
      {visualMerchandisingIdStatus !== 5
      && <AlertDialog 
       title="Aprovar resposta"
       description="Você tem certeza que deseja aprovar a resposta?"
       confirmTextButton="Aprovar"
       handleClose={() => setShowApproveSubmissionModal(false)}
       handleConfirm={() => handleConfirmApproveSubmission()}
       isOpen={showApproveSubmissionModal}
       />
      }
       <AlertDialog 
        // title= "Reprovar permanentemente"
        title= {visualMerchandisingIdStatus === 5 ?"Reprovar permanentemente":"Aceitar respostas"}
        description={visualMerchandisingIdStatus === 5 ? "Você tem certeza que deseja aceitar respostas":"Você tem certeza que deseja Reprovar o desafio?"}
        confirmTextButton={visualMerchandisingIdStatus === 5 ? "Aceitar" :  "Reprovar" }
        handleClose={() => setShowReproveSubmissionModal(false)}
        handleConfirm={() => handleChangeStatusSubmission(visualMerchandisingIdStatus === 5 ?6:5)}
        isOpen={showReproveSubmissionModal}
      />
      <Box
        padding='16px 48px'
        bgcolor='#FAFAFA'
        height='99vh'
        overflow='auto'
      >
        <Container maxWidth="lg" style={{ marginBottom: 16 }}>
          <Box
            mb={4}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link onClick={() => history.push('/visual_merchandising')} style={{ cursor: 'pointer', color: "#666666" }}>
                Merchandising
              </Link>
              <span
                style={{
                  fontWeight: 400,
                  color: "#666666"
                }}
              >
                Respostas
              </span>
              <span
                style={{
                  fontWeight: 400,
                  color: "#666666"
                }}
              >
                Visualizar respostas
              </span>
              <span
                style={{
                  fontWeight: 700,
                  color: "#162233"
                }}
              >
                {user.store !== null ? user.store.store_name : "Sem loja"}
              </span>
            </Breadcrumbs>
          </Box>

          <Box
            bgcolor='#FFF'
            border='1px solid #E0E5EC'
            borderRadius={8}
            display='flex'
            alignItems='flex-start'
            flexDirection='column'
            padding={4}
          >
            <Box
              display='flex'
              alignItems='center'
              flexDirection='row'
            >
              <CustomIconButton
                onClick={() => history.push(`/visual_merchandising/answers/${visualMerchandisingId}`)}
              >
                <ArrowBackIosNewIcon
                  color='#979899'
                />
              </CustomIconButton>
              <h1 style={{
                color: '#666666',
                fontWeight: 700,
                fontSize: 19,
                marginLeft: 16
              }}>
                {user.user_name}
              </h1>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box
                mt={4}
              >
                <span
                  style={{
                    color: '#4F4F4F',
                    fontWeight: 600,
                    fontSize: 19,
                    display: 'block',
                    textAlign: 'start'
                  }}
                >
                  {user.store !== null ? user.store.store_name : "Sem loja"}
                </span>
                <span
                  style={{
                    color: '#4F4F4F',
                    fontWeight: 600,
                    fontSize: 19,
                    display: 'block'
                  }}
                >
                  {visualMerchandisingTitle}
                </span>
              </Box>
              {
                visualMerchandisingIdStatus !== 3 ? (
                  <div style={{
                    display: 'flex',
                    flexDirection:'column',
                  }}>
                  <ButtonDarkBlue
                    onClick={() => handleApproveSubmission()}
                  >
                    Aprovar resposta
                  </ButtonDarkBlue>
                  <ButtonDarkBlue
                    onClick={() => handleReproveSubmission(visualMerchandisingIdStatus === 5 ?6:5)}
                  >
                   {visualMerchandisingIdStatus === 5 ?"Aceitar respostas":"Reprovar permanentemente" }   
                  </ButtonDarkBlue>
                  </div>
                ) : null
              }
            </Stack>  
            <Box
              mt={4}
              width='100%'
              height="50vh"
              overflow="auto"
              borderTop="2px solid #E0E5EC"
              borderBottom="2px solid #E0E5EC"
              paddingTop={2}
              paddingBottom={2}
            >
             {
               visualMerchandisingImages.length > 0 ? (
                 <>
                    <DateDivider
                      date={visualMerchandisingImages[0].created_date}
                    />
                    <Stack spacing={2}>
                      <Message
                        justifyContent="flex-end"
                        direction="row"
                        handleShowCommentaryInput={(image) => handleShowCommentaryInput(image)}
                        handleShowApprovalAlert={() => setShowApprovalAlert(true)}
                        showImageModal={(indexImageClicked) => handleOpenImagesModal(visualMerchandisingImages, indexImageClicked)}
                        images={visualMerchandisingImages}
                      />
                    </Stack>
                 </>
               ) : null
             }
              {
                chatMessages.map((chatMessage, index) => {
                  const dateNum = moment(chatMessage.created_date).format("ddMMyyyy");
                  let image = [];
                  let message = null;
                  let wasApproved = false;
                  let messageDirection = "start";
                  let direction = "row";
                  let hasFeedback = false;

                  if(chatMessage.was_approved !== undefined){
                    image = [{image_url: chatMessage.image_url, imageId: chatMessage.id_visual_merchandising_submissions_images}];
                    messageDirection = "start";
                    direction = "row";
                    wasApproved = chatMessage.was_approved;

                    if(chatMessage.feedbackMessage !== null){
                      hasFeedback = true;
                    }

                  } else {
                    if(chatMessage.id_user !== visualMerchandisingIdUser){
                      messageDirection = "flex-end"
                      direction = "row-reverse";
                    }
                    
                    if(chatMessage.image !== null){
                      image = [chatMessage.image];
                      wasApproved = chatMessage.image.was_approved === 1 ? true : false;
                    }
                    
                    if(chatMessage.message !== null){
                      message = chatMessage.message;
                    }

                    hasFeedback = true;
                  }

                  if(index === chatMessages.length - 1){
                    scrollToBottom();
                  }

                  return(
                    <>
                      {dates.has(dateNum) ? null : renderDate(chatMessage, dateNum)}
                      <Message
                        justifyContent={messageDirection}
                        direction={direction}
                        handleShowCommentaryInput={(image) => handleShowCommentaryInput(image)}
                        handleShowApprovalAlert={(image) => handleApprove(image)}
                        showImageModal={(indexImageClicked) => handleOpenImagesModal(image, indexImageClicked)}
                        message={message}
                        wasApproved={wasApproved}
                        images={image}
                        hasFeedback={hasFeedback}
                      />
                    </>
                  )
                })
              }
             <div ref={messagesEndRef} />
            </Box>
            {
              showCommentaryInput ? (
                <Stack spacing={1} direction="row" width="100%" mt={4} alignItems="center" height={48}>
                  <ChatInput
                    value={commentary}
                    clear={() => handleClearCommentaryInput()}
                    onChange={(e) => handleChangeInputTextValue(e, commentary, 500, setCommentary)}
                    handleSend={() => handleSendCommentary()}
                    helperText={`${commentary.length}/500 caracteres`}
                  />
                  <Avatar sx={{ bgcolor: "#E0E5EC" }}>A</Avatar>
                </Stack>
              )
                : null
            }
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default VisualMerchandisingAnswerChat;