import axios from 'axios';
import GenericModel from './generic_model/GenericModel';

const API = "/visual_merchandising";

class VisualMerchandising {

  static async listAll(page, search, filters){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.get(API + `?page=${page}&search=${search}&filters=${filters.join(",")}`, {
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getById(visualMerchandisingId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.get(API + `/${visualMerchandisingId}`, {
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async create(title, description){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API,
        method: "POST",
        headers: authHeader,
        data: {
          title: title,
          description: description
        }
      });

      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }

  static async update(title, description, visualMerchandisingId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API + `/${visualMerchandisingId}`,
        method: "PUT",
        headers: authHeader,
        data: {
          title: title,
          description: description
        }
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async delete(visualMerchandisingId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API + `/${visualMerchandisingId}`,
        method: "DELETE",
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }

  static async archive(visualMerchandisingId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API + `/archive/${visualMerchandisingId}`,
        method: "PUT",
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async sendVisualMerchandising(sendType, userId, roles, visualMerchandisingId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API + `/${visualMerchandisingId}/send`,
        method: "POST",
        headers: authHeader,
        data: {
          sendType: sendType,
          userId: userId,
          roles: roles
        }
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  } 

  static async uploadFileImage(fileImage, visualMerchandisingId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const formData = new FormData();

      formData.append('file', fileImage);

      const response =  await axios.request({
        url: API + `/${visualMerchandisingId}/image`,
        method: "POST",
        headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader
        },
        data: formData
    });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }


  static async updateImage(title, description, imageId, visualMerchandisingId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API + `/${visualMerchandisingId}/image/${imageId}`,
        method: "PUT",
        headers: authHeader,
        data: {
          title: title,
          description: description
        }
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }


  static async deleteImage(imageId, visualMerchandisingId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API + `/${visualMerchandisingId}/image/${imageId}`,
        method: "DELETE",
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

}

export default VisualMerchandising;