import axios from "axios";
import * as firebase from "firebase/app";
import "firebase/storage";
import moment from "moment";
import GenericModel from "./generic_model/GenericModel";
import { urlModify } from "./helpers/firebaseSend";
const API = "/manual_contents";

class ManualContent extends GenericModel {
  API = API;
  static API = API;

  static sendAttachFile = async (obj, progressFunction) => { //go back
    try {
      let theName = obj.name.split('.').pop()
      /*console.log("aqui theName.replace(/[^\w\s]/gi, '')")
      console.log(theName.replace(/[^\w\s]/gi, ''))*/
      let formData = new FormData();
      // formData.append('file', obj, (new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + obj.name.split('.').pop());
      // formData.append('file', obj, ((new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + obj.name.split('.').pop()).substring(0,499));
      formData.append('file', obj, ((new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + theName.replace(/[^\w\s]/gi, '')).substring(0,499));

      // formData.append(
      //   "file",
      //   obj,
      //   obj.name.split(".")[0] +//q porra é essa
      //     new Date().getTime() +
      //     "-" +
      //     Math.round(Math.random() * 100000) +
      //     "." +
      //     obj.name.split(".").pop()
      // );

      let headerObj = await GenericModel.insertAuthHeader();

      let response = await axios.request({
        url: API + "/send-manual-attach-file",
        method: "post",
        data: formData,
        headers: headerObj,
        onUploadProgress: (p) => {
          if (progressFunction) {
            progressFunction(Math.round((p.loaded * 100) / p.total));
          }
        },
      });

      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };

  static sendAttachFileVideo = async (obj, progressFunction) => {
    let theName = obj.name.split('.').pop()
    /*console.log("aqui theName.replace(/[^\w\s]/gi, '')")
    console.log(theName.replace(/[^\w\s]/gi, ''))*/
    return new Promise((resolve, reject) => {
      var storageRef = firebase.storage().ref();
      //console.log(storageRef,'Storage ref')

      let bucketImg = storageRef.child(
        // new Date().getTime() +
        //   "-" +
        //   Math.round(Math.random() * 100000) +
        //   "." +
        //   obj.name.split(".").pop()
        ((new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + theName.replace(/[^\w\s]/gi, '')).substring(0,499)
      );

      // formData.append('file', obj, ((new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.' + theName.replace(/[^\w\s]/gi, '')).substring(0,499));

      let uploadTask = bucketImg.put(obj);

      uploadTask.on(
        "state_changed",
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          progressFunction(progress);

          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        function (error) {
          reject(error);
          // Handle unsuccessful uploads
        },
        function () {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            resolve({ url: downloadURL });
          });
        }
      );
    });
  };

  static saveManualImage = async (
    idManualContent,
    imageUrl,
    fileName,
    fileType
  ) => {
    //console.log(imageUrl,'a imageUrl')

    try {
      let manualContentTrainingObj = {
        id_manual_content: idManualContent,
        manual_content_training_type: fileType,
        manual_content_training_image: imageUrl,
        manual_content_training_name: fileName,
      };
      let headerObj = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(this.API + "/save-manual-image-file", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(manualContentTrainingObj),
      });

      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };

  static saveManualVideo = async (
    idManualContent, 
    videoUrlSend,
    fileName,
    fileType
  ) => {

const  videoUrl = urlModify(videoUrlSend)

    try {
      let manualContentTrainingObj = {
        id_manual_content: idManualContent,
        manual_content_training_type: fileType,
        manual_content_training_video: videoUrl,
        manual_content_training_name: fileName,
      };
      let headerObj = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(this.API + "/save-manual-video-file", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(manualContentTrainingObj),
      });

      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };

  saveContent = async function () {
    let that = this;

    let authHeader = {};

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API + "/save_content", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  };
  //aqui update
  updateContent = async function () {
    let that = this;

    let authHeader = {};

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API + "/update_content", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  };

  removeContent = async () => {
    let that = this;

    try {
      this.deleted_date = moment().format("YYYYMMDDHHmmss");
      let headerObj = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(this.API + "/update_content", {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(this),
      });

      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };

  static removeAttachFile = async (attachment) => {
    try {
      attachment.deleted_date = moment().format("YYYYMMDDHHmmss");
      let headerObj = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let apiRoute =
        attachment.manual_content_training_type === 1
          ? "/save-manual-video-file"
          : "/save-manual-image-file";

      let response = await fetch(this.API + apiRoute, {
        method: "POST",
        headers: headerObj,
        body: JSON.stringify(attachment),
      });

      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };
}

export default ManualContent;
