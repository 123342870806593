import GenericModel from './generic_model/GenericModel'

const API = '/ticket_status';

class TicketStatus extends GenericModel {
    API = API;
    static API = API;

    static getTicketsDepartmentStatus = async function (id_ticket_department) {
        let queryString = "";
        if (id_ticket_department) {
            queryString = id_ticket_department;
        }
    
        let authHeader = null;
        try {
          let that = this;
          authHeader = await GenericModel.insertAuthHeader();
          const response = await fetch(this.API + "/"+ queryString, {
            headers: authHeader,
          });
          const body = await response.json();
    
          if (response.status !== 200) throw Error(body.message);
    
          let ans = {};
    
          function mountObjArray(elementsArray) {
            let result = [];
    
            for (let i = 0; i < elementsArray.length; i++) {
              result.push(new GenericModel(elementsArray[i], that.API));
            }
    
            return result;
          }
    
          if (body && body.elements) {
            for (let prop in body) {
              if (body.hasOwnProperty(prop) && prop !== "elements") {
                ans[prop] = body[prop];
              }
            }
            ans.elements = mountObjArray(body.elements);
          } else if (body instanceof Array) {
            ans = mountObjArray(body);
          } else {
            ans = new GenericModel(body, that.API);
          }
    
          return ans;
        } catch (err) {
          console.log(err);
          throw Error(err);
        }
      };
}

export default TicketStatus;