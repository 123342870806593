import React from "react";
import { Grid, Paper, TextField, Button, Checkbox } from "@material-ui/core";
import { red, green, blue } from "@material-ui/core/colors";
import DeleteButton from "../buttons/DeleteButton";
import { DateTimePicker } from "@material-ui/pickers/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import Role from "../../../models/Role";
import SaveButton from "../buttons/SaveButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";
export default function UserDescription(props) {
  this.editionTimeout = null;

  // Functions
  this.updateUser = props.updateObject;
  this.deleteUser = props.deleteObject;
  this.saveUser = props.saveObject;
  this.nullDescription = props.nullDescription;

  // Object to Render
  this.object = props.object;
  this.roles = props.relations[0];

  this.object.roleOptions = this.roles.map((role) => {
    let roleOption = {
      value: role.id_role,
      label: role.role_name,
    };
    return roleOption;
  });

  let objectRole = this.roles.filter((value) => {
    return this.object.id_role ? value.id_role == this.object.id_role : false;
  })[0];

  if (objectRole) {
    this.object.defaultRoleValue = {
      value: objectRole.id_role,
      label: objectRole.role_name,
    };
  } else {
    this.object.defaultRoleValue = [];
  }

  this.onChangeUpdate = (field, force) => (event) => {
    let value = event && event.target ? event.target.value : null;

    if (field === "is_approved" || field === "app_admin") {
      value = event.target.checked ? 1 : 0;
    }

    this.updateUser(this.object, field, value, force);
  };

  this.onClickSaveUser = () => {
    if (this.object.user_name === null || this.object.user_name === undefined) {
      toast.info("Para salvar adicione um nome");
      return;
    } else if (this.object.user_name?.trim().length === 0) {
      toast.info("Para salvar adicione um nome");
      return;
    }
    this.saveUser(this.object);
  };

  this.onClickDeleteUser = () => {
    if (
      window.confirm(
        `Deseja mesmo ${
          this.object.deleted_date
            ? "desbloquear"
            : this.object.is_approved
            ? "bloquear"
            : "remover"
        } esse item?`
      )
    ) {
      this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the user
      this.deleteUser(this.object);
    }
  };

  this.onChangeUpdateRole = (value) => {
    if(this.object.defaultRoleValue.value === value.value){
        this.object.defaultRoleValue = null;
    }else{
        this.object.defaultRoleValue = { value: value.value, label: value.label };
    }

    this.object.id_role = this.object.defaultRoleValue ? this.object.defaultRoleValue.value : null;
    this.object.role = null;
    this.updateUser(this.object, "id_role", this.object.id_role, true);
  };

  return (
    <div style={{ height: "auto" }}>
      <Grid container spacing={2}>
        <Grid xs={1}></Grid>
        <Grid
          xs={10}
          container
          direction="row"
          spacing={2}
          style={{ marginTop: "10px" }}
        >
          <Grid xs={3} item>
            <TextField
              label="ID"
              value={this.object.id_user ? this.object.id_user : ""}
              style={{ width: "100%" }}
              disabled
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              label="Nome"
              value={this.object.user_name ? this.object.user_name : ""}
              style={{ width: "100%" }}
              onChange={this.onChangeUpdate("user_name", false)}
              onBlur={this.onChangeUpdate("user_name", true)}
            />
          </Grid>
          {this.object.id_app === 3 ? (
            <Grid xs={3} item>
              <TextField
                label="CV"
                value={
                  this.object.user_indication_code
                    ? this.object.user_indication_code
                    : ""
                }
                style={{ width: "100%" }}
                onChange={this.onChangeUpdate("user_indication_code", false)}
                onBlur={this.onChangeUpdate("user_indication_code", true)}
              />
            </Grid>
          ) : null}

          <Grid xs={6} item>
            <TextField
              label="Telefone"
              value={this.object.user_phone ? this.object.user_phone : ""}
              style={{ width: "100%" }}
              onChange={this.onChangeUpdate("user_phone", false)}
              onBlur={this.onChangeUpdate("user_phone", true)}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              label="E-mail"
              value={this.object.user_email ? this.object.user_email : ""}
              style={{ width: "100%" }}
              onChange={this.onChangeUpdate("user_email", false)}
              onBlur={this.onChangeUpdate("user_email", true)}
            />
          </Grid>
          {/*<Grid xs={6} item>
                        <TextField
                            label="CNPJ"
                            value={this.object.user_ein_code? this.object.user_ein_code : ""}
                            style={{ width: "100%" }}
                            onChange={this.onChangeUpdate("user_ein_code", false)}
                            onBlur={this.onChangeUpdate("user_ein_code", true)}
                        />
                            </Grid>*/}
          <Grid xs={6} item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                label="Data de Criação"
                value={this.object.created_date}
                style={{ width: "100%" }}
                format="dd/MM/yyyy HH:mm"
                disabled
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid xs={6} item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                label="Data de Alteração"
                value={this.object.updated_date}
                style={{ width: "100%" }}
                format="dd/MM/yyyy HH:mm"
                disabled
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid xs={12}>
            <h>
              <span
                style={{
                  float: "left",
                  color: "rgba(0, 0, 0, 0.54)",
                  padding: "0",
                  fontSize: "13px",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: "400",
                  lineHeight: "1",
                  letterSpacing: "0.00938em",
                }}
              >
                Papel:
              </span>
            </h>
          </Grid>
          <Grid xs={12} item>
            <Select
              placeholder={"Selecione o Papel"}
              options={this.object.roleOptions}
              value={this.object.defaultRoleValue}
              onChange={this.onChangeUpdateRole}
            />
          </Grid>
          {this.object.user_data && this.object.user_data.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              {this.object.user_data.map((data, idx) => {
                return (
                  <div
                    style={{
                      width: "50%",
                      height: 80,
                      padding: 12,
                    }}
                  >
                    <TextField
                      label={
                        data.user_property
                          ? data.user_property.property_name
                          : "Propriedade " + idx
                      }
                      value={data.value ? data.value : ""}
                      style={{ width: "100%" }}
                      disabled
                    />
                  </div>
                );
              })}
            </div>
          ) : null}
          {localStorage.getItem("ID_APP") == 3 ? (
            <>
              <Grid xs={6} item>
                <Checkbox
                  onChange={this.onChangeUpdate("is_approved", true)}
                  checked={this.object.is_approved}
                />{" "}
                Aprovar Usuário
              </Grid>
              <Grid xs={6} item>
                <Checkbox
                  onChange={this.onChangeUpdate("app_admin", true)}
                  checked={this.object.app_admin === 1}
                />{" "}
                Administrador App
              </Grid>
            </>
          ) : null}
          <Grid xs={12} item>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
                lineHeight: 1,
                letterSpacing: "0.00938em",
                float: "left",
                color: "rgba(0, 0, 0, 0.54)",
                paddingBottom: "10px",
              }}
            >
              Comentários
            </span>
            <TextareaAutosize
              placeholder="Digite aqui observaçōes sobre este usuário"
              rows={4}
              value={this.object ? this.object.user_text : ""}
              onChange={this.onChangeUpdate("user_text", false)}
              style={{
                width: "90%",
                padding: "10px 10px",
              }}
            />
          </Grid>
          <Grid xs={12} style={{ marginTop: "20px" }}>
            <SaveButton
              style={{ float: "right" }}
              onClickSave={this.onClickSaveUser}
              ref={(ref) => {
                this.saveButton = ref;
              }}
            />
            <DeleteButton
              style={{ float: "right", background: "#CC0B0B" }}
              text={
                this.object.deleted_date
                  ? "Desbloquear"
                  : this.object.is_approved
                  ? "Bloquear"
                  : "Remover"
              }
              onClickDelete={this.onClickDeleteUser}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
