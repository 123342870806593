import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faEye,
    faCommentDots,
    faTasks
} from "@fortawesome/free-solid-svg-icons";
export default function ManualCard(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;

    // Functions
    this.updateManual = props.updateObject;
    this.deleteFaqTopic = props.deleteObject;
    this.callDescription = props.callDescription;

    this.onChangeUpdate = (field, force) => event => {
        let value = (event && event.target) ? event.target.value : null;
        this.updateManual(this.object, field, value, force);
    };

    this.onClickCallDescription = () => {
        this.callDescription(this.object);
    };

    return (
        <div style={{
            'height': '6vw',
            'backgroundColor': props.chosenId === this.object.id_content ? '#4498A7' : '#f2f2f2',
            display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
        }} onClick={this.onClickCallDescription}>

            <div style={{ flex: 3, justifyContent: 'center', alignItems: 'center' }}>
                <span
                    style={{
                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em'
                    }}>
                    {this.object.manual_title ? this.object.manual_title : ''}
                </span>
            </div>
            <FontAwesomeIcon
                icon={faEye}
                style={{ width: '5vw', height: '2vw', objectFit: 'cover', marginRight: '5px', }}
                id={'image-modal'}
                color={'#424242'}
            />
            <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>

                <span
                    style={{
                        float: 'left',
                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em'
                    }}>
                    {this.object.view_number ? this.object.view_number + "%" : "0%"}
                </span>
            </div>

            <div style={{ flex: 1 }}>
                <span
                    style={{
                        float: 'flex',
                        padding: '0',
                        fontSize: '18px',
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: '400',
                        lineHeight: '1',
                        letterSpacing: '0.00938em'
                    }}>
                    {(this.object.is_published == 1 ? "Publicado" : "Salvo como rascunho")}
                </span>
            </div>
        </div>
    );
}