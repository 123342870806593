import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { GOOGLE_IMAGES_URL } from '../../../constants';
import { DateTimePicker } from '@material-ui/pickers/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import utils from '../../utils'

export default function InvoiceCard(props) {

  this.editionTimeout = null;

  // Object to Render
  this.object = props.object ? props.object : {};

  //Relations
  this.relations = props.relations;
  // Functions
  this.updateInvoice = props.updateObject;
  this.deleteInvoice = props.deleteObject;
  this.callDescription = props.callDescription;

  this.onClickDeleteInvoice = () => {
    this.deleteInvoice(this.object);
  };

  this.onChangeUpdate = (field, force) => event => {
    let value = (event && event.target) ? event.target.value : null;
    this.updateInvoice(this.object, field, value, force);
  };
  //Function to Call Description in GenericAdminScreen.js
  this.onClickCallDescription = () => {
    this.callDescription(this.object);
  };

  if (this.relations && this.relations[1]) {
    this.relations[1].map((store)=>{
      if(store && this.object && store.id_store === this.object.id_store) {
        this.object.store_name = store.store_name;
      }
    });
  }

  return (
    <div
      onClick={this.onClickCallDescription}
      style={{
        'height': '90px',
        'backgroundColor': (
          props.chosenId === this.object.id_invoice ? '#4498A7' :
          this.object.invoice_rejected ? '#ef9a9a' :
            this.object.invoice_review ? '#a5d6a7' :
              this.object.reply_text ? '#fff59d' :
            '#FFFFFF'),
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
      }}
    >
      <img
        style={{ height: '80px', width: '75px', marginRight: '20px', marginLeft: '20px' }}
        src={GOOGLE_IMAGES_URL + this.object.invoice_filename}
      />
      <div style={{ flex: 1 }}>
        <span style={{
          'width': '100%', float: 'left', padding: '0', fontSize: '18px',
          fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
          letterSpacing: '0.00938em'
        }}>
          Cupom {this.object.invoice_code ? this.object.invoice_code : ''}
        </span>
      </div>
      <div style={{ flex: 2 }}>
        <span style={{
          'width': '100%', float: 'left', padding: '0', fontSize: '18px',
          fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
          letterSpacing: '0.00938em'
        }}>
          Vendedor: {this.object.user && this.object.user.user_name ? this.object.user.user_name : ''}
        </span>
      </div>
      <div style={{ flex: 2 }}>
        <span style={{
          'width': '100%', float: 'left', padding: '0', fontSize: '18px',
          fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
          letterSpacing: '0.00938em'
        }}>
          Loja: {this.object.store && this.object.store.store_name ? this.object.store.store_name : ''}
        </span>
      </div>
      <div style={{ flex: 1 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ border: '0px', color: 'black',  textDecoration: 'none'}}>
          <DateTimePicker value={this.object.invoice_date} style={{ 'width': '100%', textDecoration: 'none'}} format="dd/MM/yyyy" disabled />
        </MuiPickersUtilsProvider>
      </div>
      <div style={{ flex: 1 }}>
        <span style={{
            'width': '100%', float: 'left', padding: '0', fontSize: '18px',
            fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
            letterSpacing: '0.00938em'
          }}>
            Total: R$ {utils(this.object.ticket_after_discount?this.object.ticket_after_discount:0)}
        </span>
      </div>
    </div>
  );

}