import React from 'react';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import DeleteButton from '../buttons/DeleteButton'
import { DateTimePicker } from '@material-ui/pickers/'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';

export default function BrandDescription(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;

    // Functions
    this.updateBrand = props.updateObject;
    this.deleteBrand = props.deleteObject;
    this.nullDescription = props.nullDescription;

    this.onClickDeleteBrand = () => {
        if (window.confirm('Deseja mesmo deletar esse item?')) {
            this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the brand
            this.deleteBrand(this.object);
        }
    };

    this.onChangeUpdate = (field, force) => event => {
        let value = (event && event.target) ? event.target.value : null;
        this.updateBrand(this.object, field, value, force);
    };

    return (
        <div style={{ 'height': 'auto' }}>
            <Grid container spacing={2}>
                <Grid xs={1}>

                </Grid>
                <Grid xs={10} container direction="row" spacing={1}>
                    <Grid xs={3} item>
                        <TextField label="ID" value={this.object.id_brand} disabled />
                    </Grid>
                    <Grid xs={9} item>
                        <TextField label="Nome da Marca" value={this.object.brand_name ? this.object.brand_name : ''}
                            style={{ 'width': '100%' }}
                            onChange={this.onChangeUpdate('brand_name', false)}
                            onBlur={this.onChangeUpdate('brand_name', true)} />
                    </Grid>
                    <Grid xs={6} item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker label="Data de Criação" value={this.object.created_date} style={{ 'width': '100%' }} format="dd/MM/yyyy HH:mm" disabled />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid xs={6} item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker label="Data da ultima alteração" value={this.object.updated_date} style={{ 'width': '100%' }} format="dd/MM/yyyy HH:mm" disabled />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid xs={12}>
                        <Grid container xs={12} spacing={2} style={{ marginTop: '20px' }}>
                            <Grid xs={12}>
                                <span style={{
                                    float: 'left', color: 'rgba(0, 0, 0, 0.54)', padding: '0', fontSize: '13px',
                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                                    letterSpacing: '0.00938em'
                                }}>Regiões de Venda:</span>
                            </Grid>
                            <Grid xs={12} style={{ marginTop: '5px' }}>
                                <Select isMulti value={this.object.selling_regionDefaultValue} disabled />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={11} style={{ marginTop: '20px' }}>
                    <DeleteButton style={{ float: 'right', background: '#CC0B0B' }} onClickDelete={this.onClickDeleteBrand}></DeleteButton>
                </Grid>
            </Grid>
        </div>
    );
}