import GenericModel from './generic_model/GenericModel'
import axios from "axios";
import moment from "moment";
const API = '/medals';

class Medals extends GenericModel {
    API = API;
    static API = API;

    static getMedals = async function () {

        let authHeader = null;
        try {
            authHeader = await GenericModel.insertAuthHeader();

            const response = await fetch(API + '/', {
                headers: authHeader
            });

            const body = await response.json();

            if (response.status !== 200) throw new Error(body.message);

            return new GenericModel(body, API);
        } catch (err) {
            throw Error(err);
        }
    };

    static getContentMedal = async function (id_content) {

        let queryString = "";
        if (id_content) {

            queryString = "id_content=" + id_content;
        }

        let authHeader = null;
        try {
            let that = this;
            authHeader = await GenericModel.insertAuthHeader();
            const response = await fetch(this.API + '/content-medals?' + queryString, {
                headers: authHeader,
            });
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let ans = {};

            function mountObjArray(elementsArray) {
                let result = [];

                for (let i = 0; i < elementsArray.length; i++) {
                    result.push(new GenericModel(elementsArray[i], that.API));
                }

                return result;
            }

            if (body && body.elements) {
                for (let prop in body) {
                    if (body.hasOwnProperty(prop) && prop !== "elements") {
                        ans[prop] = body[prop];
                    }
                }
                ans.elements = mountObjArray(body.elements);
            } else if (body instanceof Array) {
                ans = mountObjArray(body);
            } else {
                ans = new GenericModel(body, that.API);
            }

            return ans;
        } catch (err) {
            throw Error(err);
        }
    };


    static getUserMedal = async function (id_user) {

        let queryString = "";
        if (id_user) {

            queryString = "id_user=" + id_user;
        }


        let authHeader = null;
        try {
            let that = this;
            authHeader = await GenericModel.insertAuthHeader();
            const response = await fetch(this.API + '/user-medals?' + queryString, {
                headers: authHeader,
            });
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let ans = {};

            function mountObjArray(elementsArray) {
                let result = [];

                for (let i = 0; i < elementsArray.length; i++) {
                    result.push(new GenericModel(elementsArray[i], that.API));
                }

                return result;
            }

            if (body && body.elements) {
                for (let prop in body) {
                    if (body.hasOwnProperty(prop) && prop !== "elements") {
                        ans[prop] = body[prop];
                    }
                }
                ans.elements = mountObjArray(body.elements);
            } else if (body instanceof Array) {
                ans = mountObjArray(body);
            } else {
                ans = new GenericModel(body, that.API);
            }

            return ans;
        } catch (err) {
            throw Error(err);
        }
    };


    static saveContentMedal = async function (contentMedalObj) {
        let that = this;

        let authHeader = {};

        try {
            authHeader = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            let response = await fetch(that.API + '/new-content-medal', {
                method: "POST",
                headers: authHeader,
                body: JSON.stringify(contentMedalObj),
            });

            if (response.status >= 400) {
                throw Error(response.statusText);
            }

            let object = await response.json();

            return new GenericModel(object, that.API);
        } catch (err) {
            throw Error(err);
        }
    };


    static saveUserMedal = async function (id_medal, id_user) {

        let authHeader = null;
        try {
            authHeader = await GenericModel.insertAuthHeader();

            const response = await fetch(API + '/new-user-medal', {
                headers: authHeader
            });

            const body = await response.json();

            if (response.status !== 200) throw new Error(body.message);

            return new GenericModel(body, API);
        } catch (err) {
            throw Error(err);
        }
    };




}

export default Medals;