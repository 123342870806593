import React from "react";
import {
    Grid,
    Paper,
    TextField,
    Checkbox,
    InputAdornment,
    Button
} from "@material-ui/core";
import DeleteButton from "../buttons/DeleteButton";
import { DatePicker } from "@material-ui/pickers/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { GOOGLE_IMAGES_URL } from "../../../constants";
import { CustomDropzone } from "../CustomDropzone";
import Invoice from "../../../models/Invoice";
import SearchableSelect from "../selectbox/SearchableSelect.js";
import AddIcon from "@material-ui/icons/Add";
import SaveButton from "../buttons/SaveButton";
import moment from "moment";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

function InvoiceDescription(props) {
    this.editionTimeout = null;
    // Object to Render
    this.object = props.object;
    this.relations = props.relations;
    this.brands = this.relations[0];
    this.showList = props.showList;

    if (!this.object.products) {
        this.object.products = [];
    } else {
        for (let i = 0; i < this.object.products.length; i++) {
            let curProd = this.object.products[i];
            if (curProd.id_brand) {
                let brandObj = this.brands.find(el => {
                    return el.id_brand === curProd.id_brand;
                });

                if (brandObj) {
                    curProd.selectedBrandObj = {
                        label: brandObj.brand_name,
                        value: brandObj.id_brand
                    };
                }
            }
        }
    }

    // Functions
    this.saveInvoice = props.saveObject;
    this.updateInvoice = props.updateObject;
    this.deleteInvoice = props.deleteObject;
    this.nullDescription = props.nullDescription;

    //beginning of the users section
    //get the values for <Select> from object.users and put labels "value" and "label"
    //make the userDefaultValue for <Select>
    this.object.userDefaultValue = {
        value:
            this.object.users && this.object.users[0]
                ? this.object.users[0].id_user
                : "",
        label:
            this.object.users && this.object.users[0]
                ? this.object.users[0].user_name
                : ""
    };

    this.onChangeUpdate = (field, force) => event => {
        let value = event && event.target ? event.target.value : null;

        let newValue = null;

        if (
            field === "ticket_before_discount" ||
            field === "ticket_after_discount" ||
            field === "discount"
        ) {
            newValue = value.replace(",", ".");
            if (newValue === "" || newValue === null || newValue === undefined)
                newValue = "0";
        } else if (
            field === "invoice_review" ||
            field === "invoice_rejected" ||
            field === "invoice_dismissed"
        ) {
            newValue = event.target.checked ? 1 : 0;
        } else {
            newValue = value;
        }

        this.updateInvoice(this.object, field, newValue, force);
    };

    this.invoice_date = moment(
        this.object.invoice_date,
        "YYYY-MM-DDTHH:mm:ss.ZZZ"
    );

    if (
        !this.invoice_date ||
        (this.invoice_date.year && this.invoice_date.year() < 1980)
    ) {
        this.updateInvoice(this.object, "invoice_date", undefined);
    }

    this.onChangeUpdateDate = (field, force) => event => {
        event = new Date(event);
        event.setHours(11, 0, 0);

        this.updateInvoice(this.object, field, event, force);
    };

    this.onClickSaveInvoice = () => {
        this.saveInvoice(this.object);
    };

    this.onClickDeleteInvoice = () => {
        if (window.confirm("Deseja mesmo deletar esse item?")) {
            this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the invoice
            this.deleteInvoice(this.object);
        }
    };

    this.onDropAction = async file => {
        file = file[0];
        try {
            let invoiceObj = await Invoice.sendInvoiceFile(
                file,
                this.object.id_invoice,
                props.progressFunction
            );
            this.updateInvoice(this.object, null, invoiceObj, false);
            // Reset completed variable (progress bar)
            props.progressFunction(0);
        } catch (err) {
            console.error(err);
        }
    };

    this.userChosen = user => {
        this.object.user.id_user = user.id_user;
        this.object.user.value = user.user_name;
        this.object.user.user_phone = user.user_phone;
        this.updateInvoice(this.object, "user", user, true);
    };

    this.storeChosen = store => {
        this.object.user.store = store;
        this.updateInvoice(this.object, "store", store, true);
    };

    /** Product Functions **/
    this.addProduct = () => {
        let productsList = this.object.products;
        productsList.push({});
        this.updateInvoice(this.object, "products", productsList, true);
    };

    this.onChangeUpdateProduct = (position, field, force) => event => {
        let value = event && event.target ? event.target.value : null;

        if (field === "id_brand") {
            value = event;
        } else if (field === "product_price") {
            value = value.replace(",", ".");
            if (value === "" || value === null || value === undefined) value = "0";
        }

        let productsList = this.object.products;
        productsList[position][field] = value;
        this.updateInvoice(this.object, "products", productsList, force);
    };

    this.onClickDeleteProduct = position => () => {
        let productsList = this.object.products;
        productsList.splice(position, 1);
        this.updateInvoice(this.object, "products", productsList, true);
    };

    return (
        <div style={{ height: "auto" }}>
            <div style={{ width: "100%" }}>
                <Grid container spacing={2}>
                    <Grid xs={6} item>
                        {this.object.invoice_filename ? (
                            <img
                                style={{ height: "100%", width: "100%" }}
                                src={GOOGLE_IMAGES_URL + this.object.invoice_filename}
                                id={"image-modal"}
                            />
                        ) : (
                            <div id="picker">
                                <CustomDropzone
                                    onDropAction={this.onDropAction}
                                    completed={props.completed}
                                />
                            </div>
                        )}
                    </Grid>
                    <Grid xs={6} item>
                        <Grid container spacing={1}>
                        <Grid xs={3} item>
                                <TextField
                                    label="ID do Cupom"
                                    value={
                                        this.object.id_invoice ? this.object.id_invoice : ""
                                    }
                                    style={{ width: "100%" }}
                                    onChange={this.onChangeUpdate("id_invoice", false)}
                                    onBlur={this.onChangeUpdate("id_invoice", true)}
                                    disabled
                                />
                            </Grid>
                            <Grid xs={3} item>
                                <TextField
                                    label="Código da Nota"
                                    value={
                                        this.object.invoice_code ? this.object.invoice_code : ""
                                    }
                                    style={{ width: "100%" }}
                                    onChange={this.onChangeUpdate("invoice_code", false)}
                                    onBlur={this.onChangeUpdate("invoice_code", true)}
                                />
                            </Grid>
                            <Grid xs={3} item>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    style={{
                                        border: "0px",
                                        color: "black",
                                        textDecoration: "none"
                                    }}
                                >
                                    <DatePicker
                                        label="Data do Cupom"
                                        value={this.object.invoice_date}
                                        style={{ width: "100%", textDecoration: "none" }}
                                        format="dd/MM/yyyy"
                                        onChange={this.onChangeUpdateDate("invoice_date", false)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid xs={3} item>
                                <MuiPickersUtilsProvider
                                    utils={DateFnsUtils}
                                    style={{
                                        border: "0px",
                                        color: "black",
                                        textDecoration: "none"
                                    }}
                                >
                                    <DatePicker
                                        label="Envio do Cupom"
                                        value={this.object.created_date}
                                        style={{ width: "100%", textDecoration: "none" }}
                                        format="dd/MM/yyyy HH:mm"
                                        disabled
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            
                            <Grid xs={6} item style={{ marginTop: "10px" }}>
                                <SearchableSelect
                                    placeholder={"Selecione o Vendedor"}
                                    searchApiUrl={"/user/select_full_user"}
                                    actionOnSelectedOption={this.userChosen}
                                    defaultValue={
                                        this.object.user
                                            ? {
                                                value: this.object.user.id_user,
                                                label:
                                                    this.object.user.user_name +
                                                    " - " +
                                                    this.object.user.user_phone
                                            }
                                            : null
                                    }
                                    value={
                                        this.object.user
                                            ? {
                                                value: this.object.user.id_user,
                                                label:
                                                    this.object.user.user_name +
                                                    " - " +
                                                    this.object.user.user_phone
                                            }
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid xs={6} item style={{ marginTop: "10px" }}>
                                <SearchableSelect
                                    placeholder={"Selecione a Loja"}
                                    searchApiUrl={"/store/select"}
                                    actionOnSelectedOption={this.storeChosen}
                                    defaultValue={
                                        this.object.user.store
                                            ? {
                                                value: this.object.user.store.id_store,
                                                label:
                                                    this.object.user.store.store_name +
                                                    " - " +
                                                    this.object.user.store.store_ein_code
                                            }
                                            : null
                                    }
                                    value={
                                        this.object.user.store
                                            ? {
                                                value: this.object.user.store.id_store,
                                                label:
                                                    this.object.user.store.store_name +
                                                    " - " +
                                                    this.object.user.store.store_ein_code
                                            }
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <span
                                    style={{
                                        float: "left",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        padding: "0",
                                        fontSize: "13px",
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                        fontWeight: "400",
                                        lineHeight: "1",
                                        letterSpacing: "0.00938em",
                                        marginTop: "10px"
                                    }}
                                >
                                    Produtos desta Nota Fiscal:
                                </span>
                            </Grid>
                            <Grid xs={12}>
                                <table style={{ width: "100%" }}>
                                    {this.object.products.map((prodObj, position) => {
                                        return (
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <TextField
                                                            label="Nome"
                                                            style={{
                                                                width: "100%",
                                                                float: "left",
                                                                padding: "0",
                                                                fontSize: "18px",
                                                                fontFamily:
                                                                    "Roboto, Helvetica, Arial, sans-serif",
                                                                fontWeight: "400",
                                                                lineHeight: "1",
                                                                letterSpacing: "0.00938em"
                                                            }}
                                                            value={
                                                                prodObj.product_name ? prodObj.product_name : ""
                                                            }
                                                            onChange={this.onChangeUpdateProduct(
                                                                position,
                                                                "product_name",
                                                                false
                                                            )}
                                                            onBlur={this.onChangeUpdateProduct(
                                                                position,
                                                                "product_name",
                                                                true
                                                            )}
                                                        />
                                                    </td>
                                                    <td style={{ width: "150px", marginTop: "10px" }}>
                                                        <SearchableSelect
                                                            placeholder={"Marca"}
                                                            searchApiUrl={"/brand/select"}
                                                            actionOnSelectedOption={this.onChangeUpdateProduct(
                                                                position,
                                                                "id_brand",
                                                                true
                                                            )}
                                                            defaultValue={prodObj.selectedBrandObj}
                                                        />
                                                    </td>
                                                    <td style={{ width: "100px" }}>
                                                        <TextField
                                                            label={"Grupo"}
                                                            style={{
                                                                width: "100%",
                                                                float: "left",
                                                                padding: "0",
                                                                fontSize: "18px",
                                                                fontFamily:
                                                                    "Roboto, Helvetica, Arial, sans-serif",
                                                                fontWeight: "400",
                                                                lineHeight: "1",
                                                                letterSpacing: "0.00938em"
                                                            }}
                                                            value={
                                                                prodObj.product_group
                                                                    ? prodObj.product_group
                                                                    : ""
                                                            }
                                                            onChange={this.onChangeUpdateProduct(
                                                                position,
                                                                "product_group",
                                                                false
                                                            )}
                                                            onBlur={this.onChangeUpdateProduct(
                                                                position,
                                                                "product_group",
                                                                true
                                                            )}
                                                        />
                                                    </td>
                                                    <td style={{ width: "80px" }}>
                                                        <TextField
                                                            label="R$ Tot."
                                                            style={{
                                                                width: "100%",
                                                                float: "left",
                                                                padding: "0",
                                                                fontSize: "18px",
                                                                fontFamily:
                                                                    "Roboto, Helvetica, Arial, sans-serif",
                                                                fontWeight: "400",
                                                                lineHeight: "1",
                                                                letterSpacing: "0.00938em"
                                                            }}
                                                            value={
                                                                prodObj.product_price
                                                                    ? prodObj.product_price
                                                                    : ""
                                                            }
                                                            onChange={this.onChangeUpdateProduct(
                                                                position,
                                                                "product_price",
                                                                false
                                                            )}
                                                            onBlur={this.onChangeUpdateProduct(
                                                                position,
                                                                "product_price",
                                                                true
                                                            )}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        R$
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </td>
                                                    <td style={{ width: "100px" }}>
                                                        <DeleteButton
                                                            style={{ float: "right", background: "#CC0B0B" }}
                                                            onClickDelete={this.onClickDeleteProduct(
                                                                position
                                                            )}
                                                            text={" "}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        );
                                    })}
                                    <tbody>
                                        <tr>
                                            <td colSpan={this.showList ? 7 : 4}>
                                                <Button onClick={this.addProduct} variant="contained">
                                                    Adicionar Produto
                                                    <AddIcon />
                                                </Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                            <Grid xs={4} style={{ marginTop: "10px" }}>
                                <TextField
                                    label="R$"
                                    style={{
                                        width: "100%",
                                        float: "left",
                                        padding: "0",
                                        fontSize: "18px",
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                        fontWeight: "400",
                                        lineHeight: "1",
                                        letterSpacing: "0.00938em"
                                    }}
                                    value={
                                        this.object.ticket_before_discount
                                            ? this.object.ticket_before_discount
                                            : 0
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">R$</InputAdornment>
                                        )
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid xs={4} style={{ marginTop: "10px" }}>
                                <TextField
                                    label="Desc."
                                    style={{
                                        width: "100%",
                                        float: "left",
                                        padding: "0",
                                        fontSize: "18px",
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                        fontWeight: "400",
                                        lineHeight: "1",
                                        letterSpacing: "0.00938em"
                                    }}
                                    value={this.object.discount ? this.object.discount : 0}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">R$</InputAdornment>
                                        )
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid xs={4} style={{ marginTop: "10px" }}>
                                <TextField
                                    label="R$ Tot."
                                    style={{
                                        width: "100%",
                                        float: "left",
                                        padding: "0",
                                        fontSize: "18px",
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                        fontWeight: "400",
                                        lineHeight: "1",
                                        letterSpacing: "0.00938em"
                                    }}
                                    value={
                                        this.object.ticket_after_discount &&
                                            this.object.ticket_after_discount !== "0"
                                            ? this.object.ticket_after_discount
                                            : ""
                                    }
                                    onChange={this.onChangeUpdate("ticket_after_discount", false)}
                                    onBlur={this.onChangeUpdate("ticket_after_discount", true)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">R$</InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            
                            <Grid xs={6} item>
                                <Checkbox
                                    onChange={this.onChangeUpdate("invoice_rejected", true)}
                                    checked={this.object.invoice_rejected === 1}
                                />{" "}
                                Rejeitar Cupom
                            </Grid>
                            {this.object.invoice_rejected ? null : (
                                <Grid xs={6} item>
                                    <Checkbox
                                        onChange={this.onChangeUpdate("invoice_review", true)}
                                        checked={this.object.invoice_review === 1}
                                    />{" "}
                                    Commit Cupom
                                </Grid>
                            )}
                            
                            <Grid xs={12} item>
                                {this.object.invoice_rejected ||
                                    (this.object.reply_text && !this.object.invoice_review) ? (
                                    <Grid xs={12} item>
                                        <TextField
                                            label="Motivo"
                                            style={{
                                                width: "100%",
                                                float: "left",
                                                padding: "0",
                                                fontSize: "18px",
                                                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                                fontWeight: "400",
                                                lineHeight: "1",
                                                letterSpacing: "0.00938em"
                                            }}
                                            value={
                                                this.object.rejection_text
                                                    ? this.object.rejection_text
                                                    : ""
                                            }
                                            onChange={this.onChangeUpdate("rejection_text", false)}
                                        />
                                        <Checkbox
                                            onChange={this.onChangeUpdate("invoice_dismissed", true)}
                                            checked={this.object.invoice_dismissed === 1}
                                        />{" "}
                                        Rejeitar Permanentemente
                                    </Grid>
                                ) : null}
                            </Grid>
                            <Grid xs={12} item>
                                {this.object.reply_text && !this.object.invoice_review ? (
                                    <TextareaAutosize
                                        aria-label="Resposta"
                                        placeholder=""
                                        value={this.object.reply_text ? this.object.reply_text : ""}
                                        disabled
                                        style={{
                                            width: "100%",
                                            float: "left",
                                            padding: "0",
                                            fontSize: "18px",
                                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                            fontWeight: "400",
                                            lineHeight: "1",
                                            letterSpacing: "0.00938em"
                                        }}
                                    />
                                ) : null}
                            </Grid>
                            <Grid xs={12} item>
                                <SaveButton
                                    style={{ float: "right" }}
                                    onClickSave={this.onClickSaveInvoice}
                                    ref={ref => {
                                        this.saveButton = ref;
                                    }}
                                />
                                <DeleteButton
                                    style={{ float: "right", background: "#CC0B0B" }}
                                    onClickDelete={this.onClickDeleteInvoice}
                                />
                            </Grid>
                            {/*<Grid xs={12} item>
                            <Grid xs={3} item>
                                <TextField
                                    value={
                                        this.object.id_invoice ? this.object.id_invoice : ""
                                    }
                                    style={{ width: "100%" }}
                                    disabled={true}
                                />
                            </Grid>
                            </Grid>*/ }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default InvoiceDescription;
