import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import AddIcon from "@material-ui/icons/Add";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import Select from "react-select";
import DeleteButton from "../buttons/DeleteButton";

export default function FormQuestionCard(props) {
  let formQuestion = props.formQuestion;
  let idxQuestion = props.idx;
  const path = window.location.pathname;

  let typeQuestion = !props.typeQuestion ? -1 : props.typeQuestion; //5: "PROVA"
  let formType =
    props.formType === null
      ? path === "/newMarketing/campaigns"
        ? 1
        : 5
      : path === "/newMarketing/campaigns"
      ? 1
      : props.formType; //5: "PROVA"
  let show = props.show === undefined ? true : props.show; //5: "PROVA"

  if (path === "/contents") {
    formType = 5;
  }

  let forms_choices_order = props.forms_choices_order;
  let onQuestionChange = props.onQuestionChange
    ? props.onQuestionChange
    : () => {};

  // function iniciateQuestion(e) {
  //   console.log("to no iniciateQuestion");

  //   e.preventDefault();
  //   if (typeQuestion !== -1) {
  //     console.log("to no if");
  //     addChoice(9, false);
  //     typeQuestion = -1;
  //   }
  // }
  // iniciateQuestion();
  let onQuestionChoiceChange = (field, idx, forms_choices_order) => (event) => {
    let value = event && event.target ? event.target.value : null;

    if (field === "form_choice_correct") {
      value = event.target.checked ? 1 : 0;
    }

    let formsChoicesList = cloneDeep(formQuestion.forms_choices);

    let choice = formsChoicesList[idx];

    if (choice) {
      choice[field] = value;
      return onQuestionChange(
        "forms_choices",
        idxQuestion
      )({ target: { value: formsChoicesList } });
    }
  };

  let addChoice = () => {
    let formsChoicesList = cloneDeep(formQuestion.forms_choices);
    if (!formsChoicesList) {
      formsChoicesList = [];
    }
    let form_choice_order = formsChoicesList.length + 1;
    formsChoicesList.push({ forms_choices_order: form_choice_order });
    return onQuestionChange(
      "forms_choices",
      idxQuestion
    )({ target: { value: formsChoicesList } });
  };

  let onClickDeleteChoice = (position) => () => {
    let formsChoicesList = cloneDeep(formQuestion.forms_choices);
    formsChoicesList.splice(position, 1);
    return onQuestionChange(
      "forms_choices",
      idxQuestion
    )({ target: { value: formsChoicesList } });
  };

  let onClickDeleteQuestion = () => {
    if (props.deleteQuestion) {
      props.deleteQuestion();
    }
  };

  //console.log({ formQuestion, list: props.objectList });
  // let none = formType !== 5 ? 'initial' : 'none';
  // console.log(props);
  return (
    <>
      <Grid
        tabIndex={0}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          marginBottom: "5px",
          backgroundColor: "#f2f2f2",
        }}
      >
        <Grid style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          {formType !== 6 && !props.hidden && (
            <Grid style={{ flex: 1 }}>
              {/* //remove */}
              <span
                style={{
                  fontSize: "16px",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 400,
                  lineHeight: 1,
                  letterSpacing: "0.00938em",
                  float: "left",
                  color: "rgba(0, 0, 0, 0.54)",
                  paddingBottom: "0px",
                }}
              >
                # {idxQuestion + 1}.
              </span>
            </Grid>
          )}
          <Grid style={{ flex: 5 }}>
            <RadioGroup
              aria-label="form_question_type"
              name="form_question_type"
              value={formQuestion.form_question_type + ""}
              onChange={onQuestionChange("form_question_type", idxQuestion)}
              row
            >
              {formType === 6 &&
                //formQuestion.form_question_type != 9 &&
                !props.hidden &&
                formQuestion.form_question_type != 8 && (
                  <>
                    <FormControlLabel
                      disabled={![1, 4, 5, 6].includes(formType)}
                      value={"1"}
                      control={<Radio color="primary" />}
                      label="Múltipla"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //style={formType === 5 && { display: 'none' }}
                      disabled={![1, 4, 6].includes(formType)}
                      value={"2"}
                      control={<Radio color="primary" />}
                      label="Aberta"
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      //style={formType === 5 && { display: 'none' }}
                      disabled={![1, 4 /*, 6*/].includes(formType)}
                      value={"3"}
                      control={<Radio color="primary" />}
                      label="NPS"
                      labelPlacement="end"
                    />
                    {/*<FormControlLabel
                      //style={formType === 5 && { display: 'none' }}
                      disabled
                      value={"4"}
                      control={<Radio color="primary" />}
                      label="Produtos"
                      labelPlacement="end"
                    />*/}
                  </>
                )}

              {formType === 6 &&
                !props.hidden &&
                //formQuestion.form_question_type != 9 &&
                formQuestion.form_question_type != 8 && (
                  <>
                    <FormControlLabel
                      //style={formType !== 6 && { display: "none" }}
                      disabled={![1 /*, 6*/].includes(formType)}
                      value={"5"}
                      control={<Radio color="primary" />}
                      label="Texto em área"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //style={formType !== 6 && { display: "none" }}
                      disabled={![1 /*, 6*/].includes(formType)}
                      value={"6"}
                      control={<Radio color="primary" />}
                      label="Sim ou não"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      //style={formType !== 6 && { display: "none" }}
                      disabled={![1 /*, 6*/].includes(formType)}
                      value={"7"}
                      control={<Radio color="primary" />}
                      label="Estrelas"
                      labelPlacement="end"
                    />
                    {/* //AQUI CHECKBOX */}
                    {/* <FormControlLabel
                    disabled={![1, 6].includes(formType)}
                    value={"8"}
                    control={<Radio color="primary" />}
                    label="Checkbox"
                    labelPlacement="end"
                  /> */}
                    {/* aqui mudar */}
                    {/* {formQuestion.form_question_type === 9 && (
                    <FormControlLabel
                      disabled={![1, 6].includes(formType)}
                      value={"9"}
                      control={<Radio color="primary" />}
                      label="Formulário"
                      labelPlacement="end"
                    />
                  )} */}
                  </>
                )}
              {/*formType === 6 && formQuestion.form_question_type == 9 && (
                //  console.log(formQuestion, "questions aqui")

                <FormControlLabel
                  disabled={![1, 6].includes(formType)}
                  value={"9"}
                  control={<Radio color="primary" />}
                  label="Formulário"
                  labelPlacement="end"
                />
              )*/}
              {/*formType === 6 && formQuestion.form_question_type == 8 && (
                //  console.log(formQuestion, "questions aqui")
                <FormControlLabel
                  disabled={![1, 6].includes(formType)}
                  value={"8"}
                  control={<Radio color="primary" />}
                  label="Checkbox"
                  labelPlacement="end"
                />
              )*/}
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          <span
            style={{
              fontSize: "12px",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 400,
              lineHeight: 1,
              letterSpacing: "0.00938em",
              float: "left",
              color: "rgba(0, 0, 0, 0.54)",
              paddingBottom: "0px",
            }}
          >
            {formType != 6 || props.hidden ? "Pergunta" : "Atividade"} -{" "}
            {formQuestion.form_question ? formQuestion.form_question.length : 0}
            /500
          </span>
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          <Grid style={{ flex: 1 }}>
            <TextareaAutosize
              aria-label="Pergunta"
              // placeholder="Pergunta(Máximo de 500 caracteres)"
              placeholder={`${
                formType != 6 ? "Pergunta" : "Atividade"
              } (Máximo de 500 caracteres)`}
              rows={4}
              value={formQuestion.form_question}
              onChange={onQuestionChange("form_question", idxQuestion)}
              style={{
                width: "95%",
                padding: "10px 10px",
              }}
            />
          </Grid>
        </Grid>
        {formType === 6 ? (
          <>
            <Grid style={{ display: "flex", flexDirection: "row", flex: 1 }}>
              <span
                style={{
                  fontSize: "12px",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: 400,
                  lineHeight: 1,
                  letterSpacing: "0.00938em",
                  float: "left",
                  color: "rgba(0, 0, 0, 0.54)",
                  paddingBottom: "0px",
                }}
              >
                Peso:
              </span>
            </Grid>
            <Grid
              style={{ display: "flex", flexDirection: "row", width: "50px" }}
            >
              <Grid style={{ flex: 5 }}>
                <TextField
                  label={""}
                  value={
                    formQuestion.form_question_weigth
                      ? formQuestion.form_question_weigth
                      : "0"
                  }
                  style={{ width: "100%" }}
                  onChange={onQuestionChange(
                    "form_question_weigth",
                    idxQuestion
                  )}
                />
              </Grid>
            </Grid>
          </>
        ) : null}

        {/*(formQuestion.form_question_type === 9 || typeQuestion === 9) && (
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              paddingRight: 20,
              marginTop: 20,
              paddingLeft: 20,
            }}
          >
            {/* //olhar aqui o form }
            {/* {formType != 6 ? ( }
            <Select
              placeholder={"Selecione o formulário"}
              options={
                props.objectList
                  ? props.objectList
                      .filter(
                        (item) =>
                          item.form &&
                          item.form.id_form !== formQuestion.id_form
                      )
                      .map((list) => ({
                        value: list.form.id_form,
                        label: list.form.form_title || "Formulário",
                      }))
                  : []
              }
              onChange={({ value }) => {
                //console.log({ value });
                onQuestionChange(
                  "id_linked_form",
                  idxQuestion
                )({ target: { value } });
              }}
              value={
                props.objectList
                  ? props.objectList
                      .filter((item) => item.form)
                      .map((list) => ({
                        value: list.form.id_form,
                        label: list.form.form_title || "Formulário",
                      }))
                      .find(
                        (item) => item.value === formQuestion.id_linked_form
                      )
                  : undefined
              }
            />
            {/* ) : (
              <Select
                placeholder={"Selecione o formulário"}
                options={
                  props.forms
                    ? props.forms
                        .filter(
                          (item) =>
                            item.form &&
                            item.form.form_type != 6 &&
                            item.form.id_form !== formQuestion.id_form
                        )
                        .map((list) => ({
                          value: list.form.id_form,
                          label: list.form.form_title || "Formulário",
                        }))
                    : []
                }
                onChange={({ value }) => {
                  //console.log({ value });
                  onQuestionChange(
                    "id_linked_form",
                    idxQuestion
                  )({ target: { value } });
                }}
                value={
                  props.forms
                    ? props.forms
                        .filter((item) => item.form)
                        .map((list) => ({
                          value: list.form.id_form,
                          label: list.form.form_title || "Formulário",
                        }))
                        .find(
                          (item) => item.value === formQuestion.id_linked_form
                        )
                    : undefined
                }
              />
            )} }
          </Grid>
        )*/}
        {formQuestion.form_question_type === 1 ? (
          <Grid style={{ display: "flex", flexDirection: "column" }}>
            <Grid style={{ display: "flex", flexDirection: "row", flex: 1 }}>
              Alternativas
            </Grid>
            {formQuestion.forms_choices &&
              formQuestion.forms_choices
                .sort((choice1, choice2) => {
                  return (
                    choice1.forms_choices_order - choice2.forms_choices_order
                  );
                })
                .map((choice, idxChoice, forms_choices_order) => {
                  return (
                    <Grid
                      style={{ display: "flex", flexDirection: "row", flex: 1 }}
                    >
                      <Grid style={{ flex: 5 }}>
                        <TextField
                          label={`Alternativa ${idxChoice + 1}`}
                          value={choice.form_choice}
                          style={{ width: "100%" }}
                          onChange={onQuestionChoiceChange(
                            "form_choice",
                            idxChoice,
                            forms_choices_order
                          )}
                        />
                      </Grid>
                      {formType === 5 ? (
                        <Grid style={{ flex: 1 }}>
                          <Checkbox
                            onChange={onQuestionChoiceChange(
                              "form_choice_correct",
                              idxChoice,
                              forms_choices_order
                            )}
                            checked={choice.form_choice_correct === 1}
                          />
                          Correta
                        </Grid>
                      ) : null}
                      {formType === 6 ? (
                        <Grid style={{ flex: 1 }}>
                          <Checkbox
                            onChange={onQuestionChoiceChange(
                              "form_choice_correct",
                              idxChoice,
                              forms_choices_order
                            )}
                            checked={choice.form_choice_correct === 1}
                          />
                          Correta
                        </Grid>
                      ) : null}
                      <Grid style={{ flex: 1 }}>
                        <DeleteButton
                          style={{ float: "right", background: "#CC0B0B" }}
                          onClickDelete={onClickDeleteChoice(idxChoice)}
                          text={" "}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
            <Button onClick={addChoice} variant="contained">
              Adicionar Alternativa
              <AddIcon />
            </Button>
          </Grid>
        ) : null}
        {formQuestion.form_question_type === 3 ? (
          <Grid style={{ display: "flex", flexDirection: "row" }} spacing={2}>
            <TextField
              label={`Menor Nota`}
              value={formQuestion.form_question_lower_range}
              style={{ flex: 1, marginRight: "5px" }}
              onChange={onQuestionChange(
                "form_question_lower_range",
                idxQuestion
              )}
            />
            <TextField
              label={`Maior Nota`}
              value={formQuestion.form_question_upper_range}
              style={{ flex: 1, marginLeft: "5px" }}
              onChange={onQuestionChange(
                "form_question_upper_range",
                idxQuestion
              )}
            />
          </Grid>
        ) : null}
        {/* // retiro para todos ou somente se tiver mais de um perguntar dps */}
        {formType !== 2 &&
        formType !== 3 &&
        formQuestion.form_question_type !== 8 ? (
          <DeleteButton
            style={{ float: "right", background: "#CC0B0B" }}
            onClickDelete={onClickDeleteQuestion}
            // adcionar aqui
            text={"Remover Pergunta"}
            // text={
            //   formQuestion.form_question_type === 9 && formType === 6
            //     ? " Remover formulario"
            //     : formQuestion.form_question_type === 8 && formType === 6
            //     ? " Remover Atividade"
            //     : "Remover Pergunta"
            // }
            // text={"Remover Pergunta "+ formQuestion.form_question_type === 8 ? 'formulario'  }
            // text={`${formQuestion.form_question_type === 8 ? 'formulario' : ''""} Remover Pergunta ` }
            // {formQuestion.form_question_type === 8 ? 'formulario' :
          />
        ) : null}
      </Grid>
    </>
  );
}
