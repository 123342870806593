import React from 'react';
import styled from 'styled-components';
// import { makeStyles } from '@material-ui/core/styles';

 
export default function Spinner({margin ,border, top , texto}) {

  // const classes = useStyles();
  return (
   <>
   <Spin border={border} top={top} 
   margin={margin}/>
  {texto && <Texto>{texto}</Texto>}
     {/* <p>border e {border} o topo e {top}</p> */}
    </>
    )
}
const Texto = styled.p`
color:'black';
margin: 0 2px;
`
const Spin = styled.div`
  margin: 10px auto;
  margin: ${({ margin }) => ( `${margin? margin : '8px ' }auto`)};
   border: ${({ border }) => ( `5px solid ${border? border : '#929982' } `)};
   border-top: ${({ top }) => ( `5px solid ${top? top : '#524948' } `)};
   border: 5px solid #929982;
  border-top: 5px solid  #524948;
  border-radius: 50%;
  // height and width in the same value
  height:50px;
  width: 50px;
  animation: spin 0.8s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;
// const useStyles = makeStyles(theme => ({
//   button: {
//     margin: theme.spacing(1),
//   },
//   leftIcon: {
//     marginRight: theme.spacing(1),
//   },
//   rightIcon: {
//     marginLeft: theme.spacing(1),
//   },
//   iconSmall: {
//     fontSize: 20,
//   },
// }));


  