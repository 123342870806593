import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@mui/material/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete';
import CreateSharpIcon from '@material-ui/icons/CreateSharp';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';
import Grid from '@mui/material/Grid';
import ImageIcon from '@mui/icons-material/Image';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { ButtonDarkBlue, ButtonWhite, CustomIconButton } from '../../../components/buttons/ThemeButtons';
import AlertDialog from '../../../components/alert/ThemeAlertDialog';
import { CustomTooltip } from '../../../components/tooltip/ThemeTooltips';
import VisualMerchandising from '../../../../models/VisualMerchandising';
import { showToast } from '../../../components/toast/ThemeToast';
import { EmptyListFeedBack, EmptyListFeedBackOnSearch } from './EmptyListFeedBacks';
import ImagePreviewModal from './ImagePreviewModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFA",
    borderRadius: 8,
    cursor: 'pointer',
  },
}))(TableRow);


const StyledTableCell = withStyles((theme) => ({
  root: {
    border: 0,
    marginBottom: 24
  }
}))(TableCell);


function StatusBadge({ merchandising }) {
  if (merchandising.id_status === 1) {
    return (
      <Chip
        label={"Rascunho"}
        style={{
          backgroundColor: '#FFE5A2',
          color: "#162233"
        }}
      />
    );
  }

  if (merchandising.id_status === 2) {
    return (
      <Chip
        label={"Enviado"}
        style={{
          backgroundColor: "#DBFFDD",
          color: "#162233"
        }}
      />
    );
  }

  if (merchandising.id_status === 3) {
    return (
      <Chip
        label={"Arquivado"}
        style={{
          backgroundColor: '#E0E5EC',
          color: "#162233"
        }}
      />
    );
  }

  if (merchandising.id_status === 4) {
    return (
      <Chip
        label={"Enviado"}
        style={{
          backgroundColor: "#DBFFDD",
          color: "#162233"
        }}
      />
    );
  }
}

function VisualMerchandisingDescriptionModal({ 
  handleClose, 
  isVisible, 
  merchandising, 
  handleOpenDeleteAlert,
  handleEdit
}) {
  const [selectedImage, setSelectedImage] = useState();
  const [imagePreviewModalIsVisible, setImagePreviewModalIsVisible] = useState(false);

  function handleShowImagePreviewModal(image){
    setSelectedImage(image);
    setImagePreviewModalIsVisible(true);
  }

  function handleCloseImagePreviewModal(){
    setSelectedImage(undefined);
    setImagePreviewModalIsVisible(false);
  }

  return (
    <>
      <ImagePreviewModal 
        handleClose={handleCloseImagePreviewModal}
        image={selectedImage}
        isVisible={imagePreviewModalIsVisible}
      />
      <Modal
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflowY: "scroll"
        }}
      >
        <Box
          bgcolor="#FFF"
          border="1px solid #E0E5EC"
          borderRadius={8}
          display='flex'
          flexDirection='column'
          margin='16px auto'
          width='55%'
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
            borderBottom="1px solid #D5DCE6"
            px={2}
            py={2}
          >
            <span
              style={{
                color: "#162233",
                fontSize: 22
              }}
            >
            {merchandising.title}
            </span>

            <CustomIconButton
              onClick={handleClose}
            >
              <CloseIcon />
            </CustomIconButton>
          </Box>

          <Box
            paddingX={2}
          >
            <Box>
              <span
                style={{
                  color: "#666666",
                  fontSize: 19,
                  fontWeight: 600,
                }}
              >
                Descrição
              </span>

              <p style={{width: "99%", textAlign: "left"}}>
                {
                  merchandising.description
                }
              </p>
            </Box>

            <Box mt={4} flexGrow={1}>
              <span
                style={{
                  color: "#666666",
                  fontSize: 19,
                  fontWeight: 600
                }}
              >
                Arquivos
              </span>
              <Grid container mt={2} spacing={2}>
                {
                merchandising.images?.map(image => (
                    <Grid item xs={2} key={image.id_visual_merchandising_images} maxWidth={120} maxHeight={120}>
                      <img
                        src={image.image_url}
                        alt="merchandising-image"
                        onClick={() => handleShowImagePreviewModal(image)}
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer'
                        }}
                      />
                    </Grid>              
                  ))
                }
              </Grid>
            </Box>
            <Box mt={4}>
              <span
                style={{
                  color: "#666666",
                  fontSize: 19,
                  fontWeight: 600
                }}
              >
                Dados do envio
              </span>
              <Box mt={2}>
                <StatusBadge 
                  merchandising={merchandising}
                />
              </Box>
            </Box>
          </Box>
          <Box
            display='flex'
            justifyContent='flex-end'
            marginY={2}
            marginX={2}
          >
            {
              merchandising.id_status === 1 ? (
                <>
                  <ButtonWhite
                    onClick={() => handleOpenDeleteAlert(merchandising.id_visual_merchandising)}
                    style={{ marginRight: 16 }}
                  >
                    Descartar
                  </ButtonWhite>

                  <ButtonDarkBlue
                    onClick={() => handleEdit(merchandising.id_visual_merchandising)}
                  >
                    Editar
                  </ButtonDarkBlue>
                </>
              ) 
              : null
            }
          </Box>
        </Box>
      </Modal>
    </>
  );
}

function VisualMerchandisingTable({isLoading, data, isSearching, updateList}) {
  const [descriptionModalIsVisible, setDescriptionModalIsVisible] = useState(false);
  const [archiveAlertIsVisible, setArchiveAlertIsVisible] = useState(false);
  const [deleteAlertIsVisible, setDeleteAlertIsVisible] = useState(false);
  const [selectedMerchandising, setSelectedMerchandising] = useState({});
  const [selectedVisualMerchandisingIdToDelete, setSelectedVisualMerchandisingIdToDelete] = useState(null);
  const [selectedVisualMerchandisingIdToArchive, setSelectedVisualMerchandisingIdToArchive] = useState(null);

  const history = useHistory();
  const classes = useStyles();

  function handleOpenArchiveAlert(visualMerchandisingId){
    setSelectedVisualMerchandisingIdToArchive(visualMerchandisingId);
    setArchiveAlertIsVisible(true);
  }

  async function handleConfirmArchive(){
    try{
      if(!selectedVisualMerchandisingIdToArchive){
        return;
      }

      const response = await VisualMerchandising.archive(selectedVisualMerchandisingIdToArchive);
      setSelectedVisualMerchandisingIdToArchive(null);
      setArchiveAlertIsVisible(false);
      updateList();
      showToast("success", "Visual Merchandising arquivado com sucesso.");
    }catch(err){
      console.log(err);
      showToast("error", "Erro ao arquivar, por favor tente novamente.");
    }
  }

  async function handleConfirmDelete(){
    try{
      if(!selectedVisualMerchandisingIdToDelete){
        return;
      }

      const response = await VisualMerchandising.delete(selectedVisualMerchandisingIdToDelete);
      setSelectedVisualMerchandisingIdToDelete(null);
      setDeleteAlertIsVisible(false);
      setDescriptionModalIsVisible(false);
      updateList();
      showToast("success", "Visual Merchandising removido com sucesso.");
    }catch(err){
      console.log(err);
      showToast("error", "Erro ao excluir, por favor tente novamente.");
    }
  }

  function handleOpenDeleteAlert(visualMerchandisingId){
    setSelectedVisualMerchandisingIdToDelete(visualMerchandisingId);
    setDeleteAlertIsVisible(true);
  }

  function handleCloseDeleteAlert(){
    setSelectedVisualMerchandisingIdToDelete(null);
    setDeleteAlertIsVisible(false);
  }

  function handleOpenDescriptionModal(merchandising){
    setSelectedMerchandising(merchandising);
    setDescriptionModalIsVisible(true);
  }

  function handleEdit(visualMerchandisingId){
    history.push(`/visual_merchandising/edit/${visualMerchandisingId}`);
  }

  if(data.length === 0 && !isSearching && !isLoading){
    return(
      <EmptyListFeedBack />
    );
  }

  if(isSearching && data.length === 0){
    return(
      <EmptyListFeedBackOnSearch />
    );
  }

  return (
    <>
      <VisualMerchandisingDescriptionModal
        isVisible={descriptionModalIsVisible}
        handleClose={() => setDescriptionModalIsVisible(false)}
        merchandising={selectedMerchandising}
        handleOpenDeleteAlert={handleOpenDeleteAlert}
        handleEdit={handleEdit}
      />

      <AlertDialog 
        title="Arquivar conteúdo"
        description="Ao arquivar, o franqueado deixará de visualizar o conteúdo por meio do aplicativo.
        Você tem certeza que deseja arquivar este conteúdo?"
        confirmTextButton="Arquivar conteúdo"
        handleClose={() => setArchiveAlertIsVisible(false)}
        handleConfirm={() => handleConfirmArchive()}
        isOpen={archiveAlertIsVisible}
      />

      <AlertDialog 
        title="Descartar conteúdo"
        description="Você tem certeza que deseja descartar o conteúdo?"
        confirmTextButton="Descartar conteúdo"
        handleClose={() => handleCloseDeleteAlert()}
        handleConfirm={() => handleConfirmDelete()}
        isOpen={deleteAlertIsVisible}
      />

      <TableContainer>
        <Table size="small" style={{
          borderCollapse: 'separate',
          borderSpacing: '0 8px'
        }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="left">Título</StyledTableCell>
              <StyledTableCell align="left">Enviado para</StyledTableCell>
              <StyledTableCell align="left">Visualizações</StyledTableCell>
              <StyledTableCell align="left">Respostas</StyledTableCell>
              <StyledTableCell align="left">Situação</StyledTableCell>
              <StyledTableCell align="left">Última atualização</StyledTableCell>
              <StyledTableCell align="right">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
             isLoading ? Array.from({length: 10}, (v, k) => k).map(element => (
                <StyledTableRow key={element}>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width={60} height={60}/>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60}/>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60}/>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60}/>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60}/>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60}/>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60}/>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60}/>
                  </StyledTableCell>
                </StyledTableRow>
              ))
              : null
            }

            {!isLoading ? data.map((visualMerchandising) => (
              <StyledTableRow key={visualMerchandising.id_visual_merchandising}>
                <StyledTableCell onClick={() => handleOpenDescriptionModal(visualMerchandising)}>
                  <Avatar 
                    alt="merchandising-image" 
                    src={visualMerchandising.images[0]?.image_url}
                    className={classes.large} 
                    variant="rounded" 
                  >
                    <ImageIcon 
                      sx={{
                        fontSize: 32,
                      }}
                    />
                  </Avatar>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} onClick={() => handleOpenDescriptionModal(visualMerchandising)}>
                  {visualMerchandising.title}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleOpenDescriptionModal(visualMerchandising)}>
                  {visualMerchandising.usersSend.length !== 0 ? visualMerchandising.usersSend[0].sendToCount + " pessoas" : "0 pessoas"}
                </StyledTableCell>
                <StyledTableCell align="center" onClick={() => handleOpenDescriptionModal(visualMerchandising)}>
                  {visualMerchandising.usersSend.length !== 0 ? visualMerchandising.usersSend[0].visualizedCount : 0}
                </StyledTableCell>
                <StyledTableCell align="center" onClick={() => handleOpenDescriptionModal(visualMerchandising)}>
                  {visualMerchandising.submissions.length !== 0 ? visualMerchandising.submissions[0].answersCount : 0}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleOpenDescriptionModal(visualMerchandising)}>
                  <StatusBadge
                    merchandising={visualMerchandising}
                  />
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleOpenDescriptionModal(visualMerchandising)}>
                  {
                    moment(visualMerchandising.updated_date).format("DD/MM/YYYY HH:mm")
                  }
                </StyledTableCell>
                <StyledTableCell align="right">
                  {
                    visualMerchandising.id_status === 1 ?
                    (
                      <CustomTooltip title="Excluir">
                        <CustomIconButton
                          onClick={() => handleOpenDeleteAlert(visualMerchandising.id_visual_merchandising)}
                          sx={{
                            '&:hover': {
                              color: "#B00020",
                            }
                          }}
                        >
                          <DeleteIcon />
                        </CustomIconButton>
                      </CustomTooltip>
                    )
                    : null
                  }
                  {
                    visualMerchandising.id_status === 1 ?
                    (
                      <CustomTooltip title="Editar">
                        <CustomIconButton
                          onClick={() => handleEdit(visualMerchandising.id_visual_merchandising)}
                        >
                          <CreateSharpIcon />
                        </CustomIconButton>
                      </CustomTooltip>
                    )
                    : null
                  }
                  {
                     visualMerchandising.id_status === 2 || visualMerchandising.id_status === 4  ?
                     (
                       <CustomTooltip title="Arquivar">
                        <CustomIconButton
                          onClick={() => handleOpenArchiveAlert(visualMerchandising.id_visual_merchandising)}
                        >
                          <ArchiveIcon />
                        </CustomIconButton>
                       </CustomTooltip>
                     )
                     : null
                  }
                </StyledTableCell>
              </StyledTableRow>
            ))
            : null
          }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default VisualMerchandisingTable;