import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 100,
    maxHeight:200,
  },
  tablecell: {
    fontSize: '10pt'
  }
});



const row = (x, i, header) =>
    <TableRow key={`tr-${i}`}>
        {header.map((y,k) =>
            <TableCell component="th" scope="row" key={`trc-${k}`}>
                {x[y.prop]}
            </TableCell>
        )}
    </TableRow>


export default function DashboardTable(props) {
  const classes = useStyles();

  let h = props.header?props.header:[];
  let d = props.data?props.data:[];
  
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
           {h.map((x,i) =>
                <TableCell key={`thc-${i}`} className={classes.tablecell}> 
                {x.name} 
                </TableCell>
           )}
          </TableRow>
        </TableHead>
        <TableBody>
          {d.map((x,i) => row(x, i, h))}
          
        </TableBody>
      </Table>
    </TableContainer>
  );
}