import axios from 'axios';
import GenericModel from './generic_model/GenericModel';

const API = "/visual_merchandising_answers";

class VisualMerchandisingAnswer {

  static async listAll(page, search, filters){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.get(API + `?page=${page}&search=${search}&filters=${filters.join(",")}`, {
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getById(idVisualMerchandising){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.get(API + `/${idVisualMerchandising}`, {
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async getSubmissionDetailById(idVisualMerchandisingSubmission){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.get(API + `/submission/${idVisualMerchandisingSubmission}`, {
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async sendCommentaryFeedback(idVisualMerchandisingSubmission, imageFeedback){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API + `/submission/${idVisualMerchandisingSubmission}/send_feedback`,
        method: "POST",
        headers: authHeader,
        data: {
          imagesFeedback: [
           imageFeedback
          ]
        }
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async approveImage(idVisualMerchandisingSubmission, imageId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API + `/approve_images/${idVisualMerchandisingSubmission}`,
        method: "PUT",
        headers: authHeader,
        data: {
          images: [
           {
            imageId
           }
          ]
        }
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async readMessage(idVisualMerchandisingSubmission, messageId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API + `/submission/${idVisualMerchandisingSubmission}/read_message/${messageId}`,
        method: "PUT",
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async approveSubmission(idVisualMerchandisingSubmission ){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.request({
        url: API + `/submission/approve/${idVisualMerchandisingSubmission}`,
        method: "PUT",
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }
  static async ChangeSubmissionState(idVisualMerchandisingSubmission , statusId){
    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });
      const response = await axios.request({
        url: API + `/submission/change/${idVisualMerchandisingSubmission}/status/${statusId}`,
        method: "PUT",
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }
}

export default VisualMerchandisingAnswer;