import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { CircularProgress } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileImage,
    faFileVideo,
    faFileAlt,
    faTrash,
  } from '@fortawesome/free-solid-svg-icons'
// Created a CustomDropzone for only the products on ModalTicketFormBudget
export function TicketsCustomDropzone(props) {

    const onDrop = useCallback(acceptedFiles => {

        return props.onDropAction(acceptedFiles);

    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const inactiveLabel = props.inactiveLabel ? props.inactiveLabel : 'Arraste o cupom para cá!';
    const activeLabel = props.activeLabel ? props.activeLabel : 'Solte o cupom!';
    const maxFiles = props.maxFiles ? props.maxFiles : 0;

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'end',
            // margin: '10px',
            // paddingLeft: '5px',
            maxHeight: '100%',
            minHeight: '30px',
            maxWidth: props.maxWidth?props.maxWidth:  "125x",
            minWidth: props.minWidth?props.maxWidth: '100px',
            border:  props.activeLabel ? '0px': '5px dashed #bdbdbd',
            borderRadius: props.activeLabel ? '0px':'5px',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '16px',
        }} {...getRootProps()}>

            {props.pictureLabel&& !props.completed && <FontAwesomeIcon
                    icon={faFileImage}
                    style={{
                    width: '60px',
                    height: '30px',
                    }}
                    color={"#554d4d"}
                    />
                }
            <input {...getInputProps()} />
            <div>
                {/*!props.pictureLabel && (!props.completed && !isDragActive && inactiveLabel)*/}
                {/*!props.completed && isDragActive && activeLabel*/}
                {props.completed > 0 && <CircularProgress variant="determinate" value={props.completed} />}
            </div>
        </div>
    )

    
}