import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "./StyledSideNav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import React, { Component, useState } from "react";
import * as Constants from "../../constants.js";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import logo from "./logo.png";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import App from "../../models/App";
import User from "../../models/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faFileInvoiceDollar,
  faStore,
  faHandshake,
  faPhotoVideo,
  faCog,
  faQuestionCircle,
  faHeadset,
  faPaperPlane,
  faPowerOff,
  faFileAlt,
  faCommentAlt,
  faTasks,
  faShareAlt,
  faRss,
  faBullhorn,
  faInbox,
  faPlay,
  faTicketAlt,
  faChalkboardTeacher,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";

const navWidthCollapsed = 64;
const navWidthExpanded = 280;
const NavHeader = styled.div`
  display: ${(props) => (props.expanded ? "block" : "none")};
  white-space: nowrap;
  background-color: #092023;
  color: #ddd;

  > * {
    color: inherit;
    background-color: inherit;
  }
`;

const NavInfoPane = styled.div`
  float: left;
  width: 100%;
  padding: 10px 20px;
  background-color: #092023;
  color: #ddd;
  text-align: left;
`;

const Separator = styled.div`
  clear: both;
  position: relative;
  margin: 0.8rem 0;
  background-color: #ddd;
  height: 1px;
`;

class Sidebar extends Component {
  state = {
    selected: Constants.HOME_SCREEN,
    expanded: false,
    id_app_selected: null,
    app_list: [],
    numUser: "",
    isInside: false,
    moduleRoles: [],
    vmAdminsUsaflex: [
      32815, 32816, 32817, 32818, 32819, 32820, 32821, 32822, 32823, 32824,
      32825,
    ],
  };

  constructor(props) {
    super(props);
    this.props = props;
    //is_insider
    // this.setState({ numUser: this.props.auth_user.phoneNumber });
    this.state.id_app_selected = props.current_app
      ? props.current_app.id_app
      : null;
    const dataC = User.getModuleRole().then((modules) => {
      this.setState({ moduleRoles: modules });
    });
    App.listApps()
      .then((app_list) => {
        this.setState({ app_list: app_list });
        {
          app_list.length === 1 &&
            this.setState({
              id_app_selected: app_list[0]?.id_app,
            });
          this.props.appChosen(app_list[0]?.id_app);
        }
        if (this.state.id_app_selected === 24)
          this.props.history.replace("/" + Constants.FORM_SCREEN);
      })

      .catch((err) => {
        console.error(err);
      });
  }

  // Ensures that updated state will be re-rendered!
  componentWillReceiveProps(nextProps) {
    this.setState({
      id_app_selected: nextProps.current_app
        ? nextProps.current_app.id_app
        : null,
    });
    User.getModuleRole().then((modules) => {
      this.setState({ moduleRoles: modules });
    });
  }

  onSelect = (selected) => {
    this.setState({ selected: selected });
    if (this.props.onSelect) {
      this.props.onSelect(selected);
    }
  };
  onToggle = (expanded) => {
    this.setState({ expanded: expanded });
    if (this.props.onToggle) {
      this.props.onToggle(expanded);
    }
  };

  handleAppChange = (event) => {
    this.setState({ id_app_selected: event.target.value });
    if (this.props.appChosen) {
      this.props.appChosen(event.target.value);
    }
  };

  checkVmAdminUsaflex = () => {
    if (this.props.db_user) {
      if (this.state.vmAdminsUsaflex.includes(this.props.db_user.id_user)) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { expanded, selected } = this.state;

    return this.state.id_app_selected !== 43 ? (
      <SideNav
        style={{
          minWidth: expanded ? navWidthExpanded : navWidthCollapsed,
          backgroundColor: "#092023",
          //position: "fixed",
        }}
        onSelect={this.onSelect}
        onToggle={this.onToggle}
      >
        <Toggle />
        <NavHeader expanded={expanded} style={{ paddingTop: "10px" }}>
          <img src={logo} alt="Logo" style={{ height: "40px" }} />
        </NavHeader>
        {expanded && (
          <NavInfoPane>
            {this.props.db_user ? (
              <div>
                <div>
                  Olá,{" "}
                  {this.props.db_user ? this.props.db_user.user_name : null}!
                </div>
                {this.props.db_user?.is_insider === 1 && (
                  <div style={{ width: "100%" }}>
                    <div style={{ width: "25%", color: "#ddd", float: "left" }}>
                      App:
                    </div>
                    <div style={{ width: "74%", float: "left" }}>
                      <Select
                        value={this.state.id_app_selected}
                        onChange={this.handleAppChange}
                        inputProps={{
                          name: "app",
                          id: "app-choice",
                        }}
                        style={{ color: "#ddd", width: "100%" }}
                      >
                        {this.state.app_list.map((el) => {
                          return (
                            <MenuItem value={el.id_app}>{el.app_name}</MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>ESCOLHA SEU APP ABAIXO!</div>
                <div style={{ width: "100%" }}>
                  <div style={{ width: "25%", color: "#ddd", float: "left" }}>
                    App:
                  </div>
                  <div style={{ width: "74%", float: "left" }}>
                    <Select
                      value={
                        this.state.id_app_selected === null
                          ? this.state.app_list[0]?.id_app
                          : this.state.id_app_selected
                      }
                      onChange={this.handleAppChange}
                      inputProps={{
                        name: "app",
                        id: "app-choice",
                      }}
                      style={{ color: "#ddd", width: "100%" }}
                    >
                      <MenuItem value={this.state.app_list[0]?.id_app}>
                        {this.state.app_list[0]?.app_name}
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
            )}
          </NavInfoPane>
        )}
        <Nav defaultSelected={selected}>
          {/* 
{this.props.db_user && this.props.db_user.app_admin && this.props.db_user.id_app != 6 ? (
*/}
          {/*STARTING EARLY ACCESS MENU */}
          {/* //this.state.id_app_selected === 4 || */}
          {(this.props.db_user &&
            this.props.db_user.app_admin &&
            this.props.current_app.has_early_access != 1 &&
            this.state.id_app_selected === 29) ||
          this.state.id_app_selected === 4 ||
          this.state.id_app_selected === 11 ||
          this.state.id_app_selected === 22 ||
          this.state.id_app_selected === 26 ||
          this.state.id_app_selected === 12 ||
          this.state.id_app_selected === 31 ||
          this.state.id_app_selected === 32 ||
          this.state.id_app_selected === 33 ||
          this.state.id_app_selected === 35 ||
          this.state.id_app_selected === 36 ||
          this.state.id_app_selected === 37 ||
          this.state.id_app_selected === 38 ||
          this.state.id_app_selected === 39 ||
          this.state.id_app_selected === 40 ||
          this.state.id_app_selected === 42 ||
          this.state.id_app_selected === 43 ||
          this.state.id_app_selected === 41 ||
          (this.state.id_app_selected === 45 && !this.checkVmAdminUsaflex()) ||
          this.state.id_app_selected === 46 ||
          this.state.id_app_selected === 48 ||
          //this.state.id_app_selected === 49 ||
          this.state.id_app_selected === 50 ||
          this.state.id_app_selected === 24 ? (
            <NavItem
              eventKey={Constants.NEWS_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.NEWS_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faRss}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>NEWS</NavText>
            </NavItem>
          ) : null}
          {this.props.db_user &&
            this.props.db_user.app_admin &&
            this.props.current_app.has_early_access != 1 &&
            (this.state.id_app_selected === 29 ||
              //this.state.id_app_selected === 49 ||
              this.state.id_app_selected === 45) && (
              <>
                <NavItem
                  eventKey={Constants.CHALLENGE_PHOTO}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.VISUAL_MERCHANDISING
                    );
                  }}
                >
                  <NavIcon>
                    <FontAwesomeIcon
                      icon={faCamera}
                      style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                    />
                  </NavIcon>
                  <NavText>VISUAL MERCHANDISING </NavText>
                  {/* <NavText>CHALLENGE_PHOTO</NavText> */}
                </NavItem>
              </>
            )}

          {this.props.db_user &&
          this.props.current_app &&
          this.state.id_app_selected !== 1 &&
          this.state.id_app_selected !== 4 &&
          this.state.id_app_selected !== 49 &&
          !this.checkVmAdminUsaflex() &&
          this.state.id_app_selected !== 5 &&
          this.state.id_app_selected !== 34 ? (
            <NavItem
              // eventKey="inbox"
              eventKey={Constants.INBOX_SCREEN}
              // onClick={() => {
              //   this.props.history.replace("/" + Constants.INBOX_SCREEN);
              // }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faInbox}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>CAIXA DE ENTRADA</NavText>
              {this.state.id_app_selected !== 4 && (
                <NavItem
                  eventKey={Constants.FORM_SCREEN /*"forms"*/}
                  onClick={() => {
                    this.props.history.replace("/" + Constants.FORM_SCREEN);
                  }}
                >
                  <NavText>PESQUISA</NavText>
                </NavItem>
              )}

              {this.props.current_app ? (
                <NavItem
                  eventKey={Constants.MESSAGE_SCREEN}
                  onClick={() => {
                    this.props.history.replace("/" + Constants.MESSAGE_SCREEN);
                  }}
                >
                  <NavText>MENSAGEM</NavText>
                </NavItem>
              ) : null}

              {this.state.id_app_selected === 3 ||
              this.state.id_app_selected === 24 ||
              this.state.id_app_selected === 29 ||
              this.state.id_app_selected === 34 ||
              this.state.id_app_selected === 40 ||
              this.state.id_app_selected === 31 ? (
                <NavItem
                  eventKey={Constants.ADMIN_CHALLENGE_PHOTO_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_CHALLENGE_PHOTO_SCREEN
                    );
                  }}
                >
                  <NavText>DASHBOARD DE DESAFIOS</NavText>
                </NavItem>
              ) : null}
              {this.state.id_app_selected === 24 ||
              this.state.id_app_selected === 31 ? (
                <NavItem
                  eventKey={Constants.ADMIN_INVOICE_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_INVOICE_SCREEN
                    );
                  }}
                >
                  <NavText>CORRIDA DE VENDAS</NavText>
                </NavItem>
              ) : null}

              {this.state.id_app_selected === 24 ||
              this.state.id_app_selected === 29 ||
              this.state.id_app_selected === 40 ||
              this.state.id_app_selected === 34 ||
              this.state.id_app_selected === 31 ? (
                <NavItem
                  eventKey={Constants.TASK_SCREEN}
                  onClick={() => {
                    this.props.history.replace("/" + Constants.TASK_SCREEN);
                  }}
                >
                  <NavText>
                    {this.state.id_app_selected === 31 ||
                    this.state.id_app_selected === 34
                      ? "DESAFIO DE FOTOS"
                      : "TAREFAS"}
                  </NavText>
                </NavItem>
              ) : null}
            </NavItem>
          ) : null}
          {this.props.db_user &&
            this.props.db_user.app_admin &&
            this.props.current_app.has_early_access != 1 &&
            this.state.id_app_selected === 4 && (
              <NavItem
                eventKey={Constants.ADMIN_INVOICE_SCREEN}
                onClick={() => {
                  this.props.history.replace(
                    "/" + Constants.ADMIN_INVOICE_SCREEN
                  );
                }}
              >
                <NavIcon>
                  <FontAwesomeIcon
                    icon={faCamera}
                    style={{
                      fontSize: "1.75em",
                      verticalAlign: "middle",
                    }}
                  />
                </NavIcon>
                <NavText>ATIVIDADES </NavText>

                <NavItem
                  eventKey={Constants.ADMIN_INVOICE_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_INVOICE_SCREEN
                    );
                  }}
                >
                  {/* <NavIcon>
                <FontAwesomeIcon
                  icon={faFileInvoiceDollar}
                  style={{
                    fontSize: '1.75em',
                    verticalAlign: 'middle',
                  }}
                />
              </NavIcon> */}
                  <NavText>{"CORRIDA DE VENDAS"}</NavText>
                </NavItem>
                <NavItem
                  eventKey={Constants.CHALLENGE_PHOTO}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.VISUAL_MERCHANDISING
                    );
                  }}
                >
                  {/* <NavIcon>
                    <FontAwesomeIcon
                      icon={faCamera}
                      style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                    />
                  </NavIcon> */}
                  <NavText>VISUAL MERCHANDISING </NavText>
                  {/* <NavText>CHALLENGE_PHOTO</NavText> */}
                </NavItem>
              </NavItem>
            )}

          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access != 1 &&
          this.state.id_app_selected === 5 ? (
            <NavItem
              eventKey={Constants.CONTENT_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.CONTENT_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faPlay}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>CURSOS</NavText>
            </NavItem>
          ) : null}
          {/* {this.state.id_app_selected === 24 ||
          // this.state.id_app_selected === 29 ||
          this.state.id_app_selected === 31 ? (
            <NavItem
              eventKey={Constants.MESSAGE_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.MESSAGE_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faInbox}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>MENSAGEM</NavText>
            </NavItem>
          ) : null} */}
          {/*this.props.db_user &&
          this.props.db_user.app_admin &&
          this.state.id_app_selected === 4 ? (
            <NavItem
              eventKey={Constants.FORM_SCREEN /*"forms"}
              onClick={() => {
                this.props.history.replace("/" + Constants.FORM_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faFileAlt}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>PESQUISA</NavText>

              {/*<NavItem
                                eventKey={Constants.FORM_SCREEN}
                                onClick={
                                    () => {
                                        this.props.history.replace(
                                            "/" + Constants.FORM_SCREEN
                                        );
                                    }
                                }
                            >
                                <NavIcon>
                                    <FontAwesomeIcon
                                        icon={faFileAlt}
                                        style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                                    />
                                </NavIcon>
                            <NavText>ENVIAR PESQUISA</NavText></NavItem>*/}
          {/*this.state.id_app_selected !== 5 && this.state.id_app_selected !== 4 ? (
                                <NavItem
                                    eventKey={Constants.ADMIN_CHALLENGE_PHOTO_SCREEN}
                                    onClick={() => {
                                        this.props.history.replace(
                                            "/" + Constants.ADMIN_CHALLENGE_PHOTO_SCREEN
                                        );
                                    }}
                                >
                                    <NavIcon>
                                        <FontAwesomeIcon
                                            icon={faChalkboardTeacher}
                                            style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                                        />
                                    </NavIcon>
                                    <NavText>AVALIAR DESAFIO DE FOTOS</NavText>
                                </NavItem>
                                ) : null}
            </NavItem>
                                  ) : null*/}
          {/* {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access != 1 &&
          this.state.id_app_selected !== 5 ? (
            <NavItem
              eventKey={Constants.CONTENT_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.CONTENT_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faPlay}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>
                {this.state.id_app_selected !== 4 ? "CURSOS" : "CONTEÚDOS"}
              </NavText>
            </NavItem>
          ) : null} */}
          {/* //parado */}
          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access ? (
            <NavItem
              eventKey={Constants.ADMIN_DATA_PANEL_SCREEN}
              onClick={() => {
                this.props.history.replace(
                  "/" + Constants.ADMIN_DATA_PANEL_SCREEN
                );
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faChartPie}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>DASHBOARD</NavText>
            </NavItem>
          ) : null}
          {/* //dani  aqui client*/}
          {/*this.state.id_app_selected !== 4 ? (
            <NavItem
              eventKey={Constants.ADMIN_CHALLENGE_PHOTO_SCREEN}
              onClick={() => {
                this.props.history.replace(
                  "/" + Constants.ADMIN_CHALLENGE_PHOTO_SCREEN
                );
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>DASHBOARD DESAFIOS</NavText>
            </NavItem>
            ) : null*/}
          {/*this.state.id_app_selected === 24 ||
          this.state.id_app_selected === 29 ||
          this.state.id_app_selected === 31 ? (
            <NavItem
              eventKey={Constants.TASK_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.TASK_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faTasks}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>
                {this.state.id_app_selected === 31
                  ? "DESAFIO DE FOTOS"
                  : "TAREFAS"}
              </NavText>
            </NavItem>
          ) : null}

          {/* oioiioi */}
          {/*this.props.db_user &&
                        this.props.db_user.app_admin &&
                        this.props.current_app.has_early_access ? (
                        <NavItem eventKey="contents">
                            <NavIcon>
                                <FontAwesomeIcon
                                    icon={faPhotoVideo}
                                    style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                                />
                            </NavIcon>
                            <NavText>TM</NavText>

                            {this.props.db_user && this.props.db_user.app_admin && this.state.id_app_selected !== 4 ? (
                                <NavItem
                                    eventKey={Constants.CONTENT_SCREEN}
                                    onClick={() => {
                                        this.props.history.replace("/" + Constants.CONTENT_SCREEN);
                                    }}
                                >
                                    <NavText>Cursos</NavText>
                                </NavItem>
                            ) : null}

                            {/*this.props.db_user && this.props.db_user.app_admin && this.state.id_app_selected === 4 ? (
                                <NavItem
                                    eventKey={Constants.CONTENT_SCREEN}
                                    onClick={() => {
                                        this.props.history.replace("/" + Constants.CONTENT_SCREEN);
                                    }}
                                >
                                    <NavText>Conteúdos</NavText>
                                </NavItem>
                                ) : null}

                            <NavItem
                                eventKey={Constants.ADMIN_CONTENT_ENGAGEMENT_SCREEN}
                                onClick={() => {
                                    this.props.history.replace(
                                        "/" + Constants.ADMIN_CONTENT_ENGAGEMENT_SCREEN
                                    );
                                }}
                            >
                                <NavIcon>
                                    <FontAwesomeIcon
                                        style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                                    />
                                </NavIcon>
                                <NavText>ENGAJAMENTO</NavText>
                            </NavItem>
                        </NavItem>
                    ) : null*/}
          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access ? (
            <NavItem eventKey="faq">
              <NavIcon>
                <FontAwesomeIcon
                  icon={faStore}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>OPS</NavText>

              {/*this.props.db_user && this.props.db_user.app_admin ? (
                                <NavItem
                                    eventKey={Constants.ADMIN_CHALLENGE_PHOTO_SCREEN}
                                    onClick={() => {
                                        this.props.history.replace(
                                            "/" + Constants.ADMIN_CHALLENGE_PHOTO_SCREEN
                                        );
                                    }}
                                >
                                    <NavText>DESAFIOS</NavText>
                                </NavItem>
                                ) : null*/}

              {/*this.props.db_user && this.props.db_user.app_admin ? (
                                <NavItem
                                    eventKey={Constants.ADMIN_CHALLENGE_PHOTO_SCREEN}
                                    onClick={() => {
                                        this.props.history.replace(
                                            "/" + Constants.ADMIN_CHALLENGE_PHOTO_SCREEN
                                        );
                                    }}
                                >
                                    <NavText>AVALIAR DESAFIOS</NavText>
                                </NavItem>
                                ) : null*/}

              {this.props.db_user && this.props.db_user.app_admin ? (
                <NavItem
                  eventKey={Constants.FAQ_SCREEN}
                  onClick={() => {
                    this.props.history.replace("/" + Constants.FAQ_SCREEN);
                  }}
                >
                  <NavText>CHAMADOS</NavText>
                </NavItem>
              ) : null}
            </NavItem>
          ) : null}
          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access ? (
            <NavItem eventKey="data">
              <NavIcon>
                <FontAwesomeIcon
                  icon={faChartPie}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>GROW</NavText>

              {this.props.current_app && this.props.current_app.has_invoices ? (
                <NavItem
                  eventKey={Constants.ADMIN_INVOICE_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_INVOICE_SCREEN
                    );
                  }}
                >
                  <NavIcon>
                    <FontAwesomeIcon
                      style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                    />
                  </NavIcon>
                  <NavText>CUPONS RECEBIDOS</NavText>
                </NavItem>
              ) : null}

              <NavItem
                eventKey={Constants.ADMIN_STORE_DASHBOARD_SCREEN}
                onClick={() => {
                  this.props.history.replace(
                    "/" + Constants.ADMIN_STORE_DASHBOARD_SCREEN
                  );
                }}
              >
                <NavIcon>
                  <FontAwesomeIcon
                    style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                  />
                </NavIcon>
                <NavText>MARKET SHARE</NavText>
              </NavItem>
            </NavItem>
          ) : null}
          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access ? (
            <NavItem eventKey="settings">
              <NavIcon>
                <FontAwesomeIcon
                  icon={faCog}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>CONFIGURAÇÕES</NavText>
              {/*this.props.db_user &&
                this.props.db_user.app_admin &&
                this.props.current_app.has_early_access !== 1 && ( //demo-1
                  // this.state.id_app_selected === 22 &&
                  <NavItem
                    eventKey={Constants.ADMIN_COMMUNICATION_CHANNEL_SCREEN}
                    onClick={() => {
                      this.props.history.replace(
                        "/" + Constants.ADMIN_COMMUNICATION_CHANNEL_SCREEN
                      );
                    }}
                  >
                    <NavText>CENTRAL DE IDEIAS</NavText>
                  </NavItem>
                  )*/}

              <NavItem
                eventKey={Constants.ADMIN_APP_SCREEN}
                onClick={() => {
                  this.props.history.replace("/" + Constants.ADMIN_APP_SCREEN);
                }}
              >
                <NavText>TUTORIAL</NavText>
              </NavItem>
              {/* <NavItem
                eventKey={Constants.ADMIN_TICKET_DEPARTMENT_SCREEN}
                onClick={() => {
                  this.props.history.replace(
                    "/" + Constants.ADMIN_TICKET_DEPARTMENT_SCREEN
                  );
                }}
              >
                <NavText>DEMANDAS3</NavText>
              </NavItem> */}

              <NavItem
                eventKey={Constants.ADMIN_ROLE_SCREEN}
                onClick={() => {
                  this.props.history.replace("/" + Constants.ADMIN_ROLE_SCREEN);
                }}
              >
                <NavText>SEGMENTAÇÕES</NavText>
              </NavItem>

              {this.props.db_user && this.props.db_user.app_admin ? (
                <NavItem
                  eventKey={Constants.ADMIN_PUSH_NOTIFICATIONS_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_PUSH_NOTIFICATIONS_SCREEN
                    );
                  }}
                >
                  {/* //not this exclude  */}
                  <NavText>ENVIAR PUSH</NavText>
                </NavItem>
              ) : null}

              <NavItem
                eventKey={Constants.ADMIN_USER_SCREEN}
                onClick={() => {
                  this.props.history.replace("/" + Constants.ADMIN_USER_SCREEN);
                }}
              >
                {/*não sei oq é esse*/}
                <NavText>USUÁRIOS</NavText>
              </NavItem>

              {this.props.current_app &&
              this.props.current_app.has_invoice &&
              this.state.id_app_selected !== 4 &&
              this.state.id_app_selected !== 11 &&
              this.state.id_app_selected !== 12 &&
              this.state.id_app_selected !== 14 &&
              this.state.id_app_selected !== 22 &&
              this.state.id_app_selected !== 26 &&
              this.state.id_app_selected !== 27 &&
              this.state.id_app_selected !== 29 &&
              this.state.id_app_selected !== 30 &&
              this.state.id_app_selected !== 32 &&
              this.state.id_app_selected !== 33 &&
              this.state.id_app_selected !== 34 &&
              this.state.id_app_selected !== 35 &&
              this.state.id_app_selected !== 36 &&
              this.state.id_app_selected !== 37 &&
              this.state.id_app_selected !== 38 &&
              this.state.id_app_selected !== 39 &&
              this.state.id_app_selected !== 40 &&
              this.state.id_app_selected !== 41 ? (
                <NavItem
                  eventKey={Constants.ADMIN_BRAND_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_BRAND_SCREEN
                    );
                  }}
                >
                  <NavText>MARCAS</NavText>
                </NavItem>
              ) : null}

              {this.props.current_app &&
              this.props.current_app.has_invoices &&
              (this.state.id_app_selected === 4 ||
                this.state.id_app_selected === 3 ||
                this.state.id_app_selected === 5) ? (
                <NavItem
                  eventKey={Constants.ADMIN_STORE_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_STORE_SCREEN
                    );
                  }}
                >
                  <NavText>LOJAS</NavText>
                </NavItem>
              ) : null}
            </NavItem>
          ) : null}
          {/*FINISHING EARLY ACCESS MENU */}
          {
            /////////////////////////////////////////////////////////////////////
            /////////////////////////////////////////////////////////////////////
          }
          {/*STARTING LIVE MENU KENNER FRANCO NO DADOS */}
          {this.state.id_app_selected !== 4 &&
          this.state.id_app_selected !== 11 &&
          this.state.id_app_selected !== 12 &&
          this.state.id_app_selected !== 13 &&
          this.state.id_app_selected !== 14 &&
          this.state.id_app_selected !== 15 &&
          this.state.id_app_selected !== 21 &&
          this.state.id_app_selected !== 22 &&
          this.state.id_app_selected !== 23 &&
          this.state.id_app_selected !== 24 &&
          this.state.id_app_selected !== 25 &&
          this.state.id_app_selected !== 26 &&
          this.state.id_app_selected !== 27 &&
          this.state.id_app_selected !== 29 &&
          this.state.id_app_selected !== 30 &&
          this.state.id_app_selected !== 31 &&
          this.state.id_app_selected !== 32 &&
          this.state.id_app_selected !== 33 &&
          this.state.id_app_selected !== 34 &&
          this.state.id_app_selected !== 35 &&
          this.state.id_app_selected !== 36 &&
          this.state.id_app_selected !== 37 &&
          this.state.id_app_selected !== 38 &&
          this.state.id_app_selected !== 40 &&
          this.state.id_app_selected !== 41 &&
          this.state.id_app_selected !== 42 &&
          this.state.id_app_selected !== 43 &&
          this.state.id_app_selected !== 39 &&
          this.state.id_app_selected !== 45 &&
          this.state.id_app_selected !== 46 &&
          this.state.id_app_selected !== 48 &&
          this.state.id_app_selected !== 49 &&
          this.state.id_app_selected !== 50 &&
          this.state.id_app_selected !== 44 ? (
            <NavItem
              eventKey={Constants.ADMIN_STORE_DASHBOARD_SCREEN}
              onClick={() => {
                this.props.history.replace(
                  "/" + Constants.ADMIN_STORE_DASHBOARD_SCREEN
                );
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faStore}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>DASHBOARD LOJAS</NavText>
            </NavItem>
          ) : null}
          {this.props.current_app &&
          this.props.current_app.has_invoices &&
          this.state.id_app_selected !== 4 &&
          this.state.id_app_selected !== 11 &&
          this.state.id_app_selected !== 12 &&
          this.state.id_app_selected !== 13 &&
          this.state.id_app_selected !== 14 &&
          this.state.id_app_selected !== 15 &&
          this.state.id_app_selected !== 21 &&
          this.state.id_app_selected !== 22 &&
          this.state.id_app_selected !== 23 &&
          this.state.id_app_selected !== 24 &&
          this.state.id_app_selected !== 25 &&
          this.state.id_app_selected !== 26 &&
          this.state.id_app_selected !== 27 &&
          this.state.id_app_selected !== 29 &&
          this.state.id_app_selected !== 30 &&
          this.state.id_app_selected !== 31 &&
          this.state.id_app_selected !== 32 &&
          this.state.id_app_selected !== 33 &&
          this.state.id_app_selected !== 34 &&
          this.state.id_app_selected !== 35 &&
          this.state.id_app_selected !== 36 &&
          this.state.id_app_selected !== 37 &&
          this.state.id_app_selected !== 38 &&
          this.state.id_app_selected !== 40 &&
          this.state.id_app_selected !== 41 &&
          this.state.id_app_selected !== 42 &&
          this.state.id_app_selected !== 43 &&
          this.state.id_app_selected !== 39 &&
          this.state.id_app_selected !== 45 &&
          this.state.id_app_selected !== 46 &&
          this.state.id_app_selected !== 48 &&
          this.state.id_app_selected !== 49 &&
          this.state.id_app_selected !== 50 &&
          this.state.id_app_selected !== 44 ? (
            <NavItem
              eventKey={Constants.ADMIN_INVOICE_SCREEN}
              onClick={() => {
                this.props.history.replace(
                  "/" + Constants.ADMIN_INVOICE_SCREEN
                );
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faFileInvoiceDollar}
                  style={{
                    fontSize: "1.75em",
                    verticalAlign: "middle",
                  }}
                />
              </NavIcon>
              <NavText>COMMIT DE CUPONS</NavText>
            </NavItem>
          ) : null}
          {this.state.id_app_selected !== 4 &&
          this.state.id_app_selected !== 10 &&
          this.state.id_app_selected !== 11 &&
          this.state.id_app_selected !== 14 &&
          this.state.id_app_selected !== 15 &&
          this.state.id_app_selected !== 21 &&
          this.state.id_app_selected !== 22 &&
          this.state.id_app_selected !== 23 &&
          this.state.id_app_selected !== 24 &&
          this.state.id_app_selected !== 25 &&
          this.state.id_app_selected !== 26 &&
          this.state.id_app_selected !== 27 &&
          this.state.id_app_selected !== 29 && //franco
          this.state.id_app_selected !== 30 &&
          this.state.id_app_selected !== 31 &&
          this.state.id_app_selected !== 32 &&
          this.state.id_app_selected !== 33 &&
          this.state.id_app_selected !== 34 &&
          this.state.id_app_selected !== 35 &&
          this.state.id_app_selected !== 36 &&
          this.state.id_app_selected !== 37 &&
          this.state.id_app_selected !== 38 &&
          this.state.id_app_selected !== 40 &&
          this.state.id_app_selected !== 42 &&
          this.state.id_app_selected !== 39 &&
          this.state.id_app_selected !== 45 &&
          this.state.id_app_selected !== 46 &&
          this.state.id_app_selected !== 48 &&
          this.state.id_app_selected !== 49 &&
          this.state.id_app_selected !== 50 &&
          this.state.id_app_selected !== 44 ? (
            <NavItem
              eventKey={Constants.ADMIN_DATA_PANEL_SCREEN}
              onClick={() => {
                this.props.history.replace(
                  "/" + Constants.ADMIN_DATA_PANEL_SCREEN
                );
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faChartPie}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>DASHBOARD</NavText>
            </NavItem>
          ) : null}
          {/*STARTING LIVE MENU FOR OLDER VERSIONS */}
          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access !== 1 &&
          this.state.id_app_selected !== 39 &&
          this.state.id_app_selected !== 4 &&
          this.state.id_app_selected !== 24 &&
          this.state.id_app_selected !== 29 &&
          this.state.id_app_selected !== 12 &&
          this.state.id_app_selected !== 30 &&
          this.state.id_app_selected !== 31 &&
          this.state.id_app_selected !== 32 &&
          this.state.id_app_selected !== 33 &&
          this.state.id_app_selected !== 34 &&
          this.state.id_app_selected !== 35 &&
          this.state.id_app_selected !== 36 &&
          this.state.id_app_selected !== 37 &&
          this.state.id_app_selected !== 38 &&
          this.state.id_app_selected !== 39 &&
          this.state.id_app_selected !== 40 &&
          this.state.id_app_selected !== 42 &&
          this.state.id_app_selected !== 43 &&
          this.state.id_app_selected !== 45 &&
          this.state.id_app_selected !== 46 &&
          this.state.id_app_selected !== 48 &&
          this.state.id_app_selected !== 49 &&
          this.state.id_app_selected !== 41 ? (
            <NavItem
              eventKey={
                this.state.id_app_selected === 11 ||
                this.state.id_app_selected === 12 ||
                this.state.id_app_selected === 13 ||
                this.state.id_app_selected === 14 ||
                this.state.id_app_selected === 15 ||
                this.state.id_app_selected === 21 ||
                this.state.id_app_selected === 22 ||
                this.state.id_app_selected === 23 ||
                this.state.id_app_selected === 24 ||
                this.state.id_app_selected === 25 ||
                this.state.id_app_selected === 26 ||
                this.state.id_app_selected === 27 ||
                this.state.id_app_selected === 29 ||
                this.state.id_app_selected === 30 ||
                this.state.id_app_selected === 31 ||
                this.state.id_app_selected === 32 ||
                this.state.id_app_selected === 33 ||
                this.state.id_app_selected === 34 ||
                this.state.id_app_selected === 35 ||
                this.state.id_app_selected === 36 ||
                this.state.id_app_selected !== 24 ||
                this.state.id_app_selected === 37 ||
                this.state.id_app_selected === 40 ||
                this.state.id_app_selected === 44
                  ? Constants.ADMIN_DATA_PANEL_SCREEN
                  : /*: this.state.id_app_selected === 12
                                    ? Constants.ADMIN_DATA_PANEL_SCREEN_CAFE
                                    : this.state.id_app_selected === 13
                                    ? Constants.ADMIN_DATA_PANEL_SCREEN_MILBIJUS*/
                    "data"
              }
              onClick={
                (this.state.id_app_selected === 11 ||
                  this.state.id_app_selected === 13 ||
                  this.state.id_app_selected === 14 ||
                  this.state.id_app_selected === 15 ||
                  this.state.id_app_selected === 21 ||
                  this.state.id_app_selected === 22 ||
                  this.state.id_app_selected === 23 ||
                  this.state.id_app_selected === 25 ||
                  this.state.id_app_selected === 26 ||
                  this.state.id_app_selected === 27 ||
                  this.state.id_app_selected === 30 ||
                  this.state.id_app_selected === 31 ||
                  this.state.id_app_selected === 32 ||
                  this.state.id_app_selected === 33 ||
                  this.state.id_app_selected === 34 ||
                  this.state.id_app_selected === 35 ||
                  this.state.id_app_selected === 36 ||
                  this.state.id_app_selected === 37 ||
                  this.state.id_app_selected === 44) &&
                this.state.id_app_selected !== 24 &&
                this.state.id_app_selected !== 29 &&
                this.state.id_app_selected !== 46 &&
                this.state.id_app_selected !== 48 &&
                this.state.id_app_selected !== 49 &&
                this.state.id_app_selected !== 50 &&
                this.state.id_app_selected !== 45
                  ? () => {
                      this.props.history.replace(
                        "/" + Constants.ADMIN_DATA_PANEL_SCREEN
                      );
                    }
                  : /*: this.state.id_app_selected === 12
                  ? () => {
                      this.props.history.replace(
                        "/" + Constants.ADMIN_DATA_PANEL_SCREEN_CAFE
                      );
                    }
                  : this.state.id_app_selected === 13
                  ? () => {
                      this.props.history.replace(
                        "/" + Constants.ADMIN_DATA_PANEL_SCREEN_MILBIJUS
                      );
                    }*/ () => {}
              }
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faChartPie}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>DADOS</NavText>

              {this.state.id_app_selected !== 10 &&
              this.state.id_app_selected !== 11 &&
              this.state.id_app_selected !== 14 &&
              this.state.id_app_selected !== 15 &&
              this.state.id_app_selected !== 21 &&
              this.state.id_app_selected !== 22 &&
              this.state.id_app_selected !== 23 &&
              this.state.id_app_selected !== 25 &&
              this.state.id_app_selected !== 26 &&
              this.state.id_app_selected !== 27 &&
              this.state.id_app_selected !== 24 &&
              // this.state.id_app_selected === 29 && //franco
              this.state.id_app_selected !== 30 &&
              this.state.id_app_selected !== 31 &&
              this.state.id_app_selected !== 32 &&
              this.state.id_app_selected !== 33 &&
              this.state.id_app_selected !== 34 &&
              this.state.id_app_selected !== 35 &&
              this.state.id_app_selected !== 36 &&
              this.state.id_app_selected !== 37 &&
              this.state.id_app_selected !== 38 &&
              this.state.id_app_selected !== 42 &&
              this.state.id_app_selected !== 50 &&
              this.state.id_app_selected !== 39 ? (
                <NavItem
                  eventKey={Constants.ADMIN_DATA_PANEL_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_DATA_PANEL_SCREEN
                    );
                  }}
                >
                  <NavIcon>
                    <FontAwesomeIcon
                      icon={faChartPie}
                      style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                    />
                  </NavIcon>
                  <NavText>DASHBOARD</NavText>
                </NavItem>
              ) : null}
              {this.props.current_app &&
              this.props.current_app.has_invoices &&
              this.state.id_app_selected !== 11 &&
              this.state.id_app_selected !== 12 &&
              this.state.id_app_selected !== 13 &&
              this.state.id_app_selected !== 14 &&
              this.state.id_app_selected !== 15 &&
              this.state.id_app_selected !== 21 &&
              this.state.id_app_selected !== 22 &&
              this.state.id_app_selected !== 23 &&
              this.state.id_app_selected !== 24 &&
              this.state.id_app_selected !== 25 &&
              this.state.id_app_selected !== 26 &&
              this.state.id_app_selected !== 27 &&
              this.state.id_app_selected !== 29 &&
              this.state.id_app_selected !== 30 &&
              this.state.id_app_selected !== 31 &&
              this.state.id_app_selected !== 32 &&
              this.state.id_app_selected !== 33 &&
              this.state.id_app_selected !== 34 &&
              this.state.id_app_selected !== 35 &&
              this.state.id_app_selected !== 36 &&
              this.state.id_app_selected !== 37 &&
              this.state.id_app_selected !== 38 &&
              this.state.id_app_selected !== 39 &&
              this.state.id_app_selected !== 42 &&
              this.state.id_app_selected !== 43 &&
              this.state.id_app_selected !== 50 &&
              this.state.id_app_selected !== 40 ? (
                <NavItem
                  eventKey={Constants.ADMIN_INVOICE_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_INVOICE_SCREEN
                    );
                  }}
                >
                  <NavIcon>
                    <FontAwesomeIcon
                      icon={faFileInvoiceDollar}
                      style={{
                        fontSize: "1.75em",
                        verticalAlign: "middle",
                      }}
                    />
                  </NavIcon>
                  <NavText>COMMIT DE CUPONS</NavText>
                </NavItem>
              ) : null}
              {this.props.db_user.app_admin &&
              this.state.id_app_selected !== 11 &&
              this.state.id_app_selected !== 12 &&
              this.state.id_app_selected !== 13 &&
              this.state.id_app_selected !== 14 &&
              this.state.id_app_selected !== 15 &&
              this.state.id_app_selected !== 21 &&
              this.state.id_app_selected !== 22 &&
              this.state.id_app_selected !== 23 &&
              this.state.id_app_selected !== 24 &&
              this.state.id_app_selected !== 25 &&
              this.state.id_app_selected !== 26 &&
              this.state.id_app_selected !== 27 &&
              this.state.id_app_selected !== 29 &&
              this.state.id_app_selected !== 30 &&
              this.state.id_app_selected !== 31 &&
              this.state.id_app_selected !== 32 &&
              this.state.id_app_selected !== 33 &&
              this.state.id_app_selected !== 34 &&
              this.state.id_app_selected !== 35 &&
              this.state.id_app_selected !== 36 &&
              this.state.id_app_selected !== 37 &&
              this.state.id_app_selected !== 38 &&
              this.state.id_app_selected !== 39 &&
              this.state.id_app_selected !== 43 &&
              this.state.id_app_selected !== 40 &&
              this.state.id_app_selected !== 50 &&
              this.state.id_app_selected !== 44 ? (
                <NavItem
                  eventKey={Constants.ADMIN_STORE_DASHBOARD_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_STORE_SCREEN
                    );
                  }}
                >
                  <NavIcon>
                    <FontAwesomeIcon
                      icon={faStore}
                      style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                    />
                  </NavIcon>
                  <NavText>DASHBOARD LOJAS</NavText>
                </NavItem>
              ) : null}
              {this.state.id_app_selected === 4 &&
              this.props.db_user.app_admin ? (
                <NavItem
                  eventKey={Constants.ADMIN_CHALLENGE_PHOTO_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_CHALLENGE_PHOTO_SCREEN
                    );
                  }}
                >
                  <NavIcon>
                    <FontAwesomeIcon
                      icon={faChalkboardTeacher}
                      style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                    />
                  </NavIcon>
                  <NavText>DASHBOARD DESAFIOS</NavText>
                </NavItem>
              ) : null}
              {/*this.state.id_app_selected !== 11 &&
                                this.state.id_app_selected !== 12 &&
                                this.state.id_app_selected !== 13 &&
                                this.state.id_app_selected !== 14 &&
                                this.state.id_app_selected !== 15 &&
                                this.state.id_app_selected !== 21 &&
                                this.state.id_app_selected !== 22 ? (
                                <NavItem
                                    eventKey={Constants.ADMIN_CONTENT_ENGAGEMENT_SCREEN}
                                    onClick={() => {
                                        this.props.history.replace(
                                            "/" + Constants.ADMIN_CONTENT_ENGAGEMENT_SCREEN
                                        );
                                    }}
                                >
                                    <NavIcon>
                                        <FontAwesomeIcon
                                            icon={faHandshake}
                                            style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                                        />
                                    </NavIcon>
                                    <NavText>ENGAJAMENTO</NavText>
                                </NavItem>
                                ) : null*/}
            </NavItem>
          ) : null}
          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access != 1 &&
          this.state.id_app_selected === 4 ? (
            <NavItem
              eventKey={Constants.TASK_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.TASK_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faTasks}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>TAREFAS</NavText>
              {/* dani aqui tarefas */}
              <NavItem
                eventKey={Constants.MESSAGE_SCREEN}
                onClick={() => {
                  this.props.history.replace("/" + Constants.MESSAGE_SCREEN);
                }}
              >
                <NavText>MENSAGEM</NavText>
              </NavItem>
              <NavItem
                eventKey={Constants.ADMIN_CHALLENGE_PHOTO_SCREEN}
                onClick={() => {
                  this.props.history.replace(
                    "/" + Constants.ADMIN_CHALLENGE_PHOTO_SCREEN
                  );
                }}
              >
                <NavText>DASHBOARD DESAFIOS</NavText>
              </NavItem>
            </NavItem>
          ) : null}
          {/*this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access != 1 &&
          this.state.id_app_selected === 4 ? (
            <NavItem
              eventKey={Constants.ADMIN_INVOICE_SCREEN}
              onClick={() => {
                this.props.history.replace(
                  '/' + Constants.ADMIN_INVOICE_SCREEN,
                );
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faFileInvoiceDollar}
                  style={{
                    fontSize: '1.75em',
                    verticalAlign: 'middle',
                  }}
                />
              </NavIcon>
              <NavText>{'CORRIDA DE VENDAS'}</NavText>
            </NavItem>
                ) : null*/}
          {/* {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access != 1 &&
          this.state.id_app_selected === 4 ? (
            <NavItem
              eventKey={Constants.ADMIN_CHALLENGE_PHOTO_SCREEN}
              onClick={() => {
                this.props.history.replace(
                  "/" + Constants.ADMIN_CHALLENGE_PHOTO_SCREEN
                );
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>DASHBOARD DESAFIOS</NavText>
            </NavItem>
          ) : null} */}
          {(this.props.db_user &&
            this.props.db_user.app_admin &&
            this.props.current_app.has_early_access != 1 &&
            this.state.id_app_selected === 4) ||
          this.state.id_app_selected === 5 ||
          this.state.id_app_selected === 24 ||
          //this.state.id_app_selected === 27 ||
          this.state.id_app_selected === 28 ||
          this.state.id_app_selected === 31 ? (
            <NavItem
              eventKey={Constants.MARKETING_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.MARKETING_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faShareAlt}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>
                {this.state.id_app_selected === 4 ||
                this.state.id_app_selected === 24 ||
                this.state.id_app_selected === 28 ||
                this.state.id_app_selected === 31
                  ? "PUBLIQUE"
                  : this.state.id_app_selected === 11 ||
                    this.state.id_app_selected === 22 ||
                    this.state.id_app_selected === 12 ||
                    this.state.id_app_selected === 27 ||
                    this.state.id_app_selected === 29 ||
                    this.state.id_app_selected === 32 ||
                    this.state.id_app_selected === 33 ||
                    this.state.id_app_selected === 34 ||
                    this.state.id_app_selected === 35 ||
                    this.state.id_app_selected === 36 ||
                    this.state.id_app_selected === 37 ||
                    this.state.id_app_selected === 40 ||
                    this.state.id_app_selected === 41 ||
                    this.state.id_app_selected === 46 ||
                    this.state.id_app_selected === 48 ||
                    this.state.id_app_selected === 44
                  ? "REDES SOCIAIS"
                  : "ESPAÇO WE"}
              </NavText>
            </NavItem>
          ) : null}
          {(this.props.db_user &&
            this.props.db_user.app_admin &&
            this.props.current_app.has_early_access != 1 &&
            this.state.id_app_selected === 29) ||
          this.state.id_app_selected === 11 ||
          this.state.id_app_selected === 12 ||
          this.state.id_app_selected === 14 ||
          this.state.id_app_selected === 22 ||
          this.state.id_app_selected === 26 ||
          this.state.id_app_selected === 27 ||
          this.state.id_app_selected === 32 ||
          this.state.id_app_selected === 33 ||
          this.state.id_app_selected === 34 ||
          this.state.id_app_selected === 35 ||
          this.state.id_app_selected === 36 ||
          this.state.id_app_selected === 37 ||
          this.state.id_app_selected === 38 ||
          // this.state.id_app_selected === 39 ||
          this.state.id_app_selected === 40 ||
          this.state.id_app_selected === 41 ||
          this.state.id_app_selected === 43 ||
          this.state.id_app_selected === 42 ||
          this.state.id_app_selected === 46 ||
          this.state.id_app_selected === 48 ||
          this.state.id_app_selected === 50 ||
          this.state.id_app_selected === 44 ? (
            <>
              {this.state.id_app_selected !== 39 &&
                this.state.id_app_selected !== 34 && (
                  <NavItem
                    eventKey={Constants.CAMPAIGN_SCREEN}
                    onClick={() => {
                      this.props.history.replace(
                        "/" + Constants.CAMPAIGN_SCREEN
                      );
                    }}
                  >
                    <NavIcon>
                      <FontAwesomeIcon
                        icon={faBullhorn}
                        style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                      />
                    </NavIcon>

                    <NavText>CAMPANHAS</NavText>
                  </NavItem>
                )}
              {this.state.id_app_selected !== 34 && (
                <NavItem
                  eventKey={Constants.MARKETING_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.MARKETING_SCREEN
                    );
                  }}
                >
                  <NavIcon>
                    <FontAwesomeIcon
                      icon={faShareAlt}
                      style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                    />
                  </NavIcon>
                  <NavText>REDES SOCIAIS</NavText>
                </NavItem>
              )}

              {(this.state.id_app_selected === 14 ||
                this.state.id_app_selected === 22 ||
                this.state.id_app_selected === 29 ||
                this.state.id_app_selected === 32 ||
                this.state.id_app_selected === 33 ||
                this.state.id_app_selected === 34 ||
                this.state.id_app_selected === 36 ||
                this.state.id_app_selected === 37 ||
                this.state.id_app_selected === 38 ||
                this.state.id_app_selected === 40 ||
                this.state.id_app_selected === 41 ||
                this.state.id_app_selected === 45 ||
                this.state.id_app_selected === 48 ||
                this.state.id_app_selected === 42) &&
              this.props.db_user?.app_admin ? (
                (this.state.id_app_selected === 42 ||
                  this.state.id_app_selected === 48) &&
                this.state.moduleRoles &&
                this.state.moduleRoles.length > 0 &&
                this.state.moduleRoles.filter(
                  (module) => module.module_name === "FINANCEIRO"
                ) ? (
                  <NavItem
                    eventKey={Constants.CUPOM_SCREEN}
                    onClick={() => {
                      this.props.history.replace("/" + Constants.CUPOM_SCREEN);
                    }}
                  >
                    <NavIcon>
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                      />
                    </NavIcon>
                    <NavText>FINANCEIRO</NavText>
                  </NavItem>
                ) : this.state.id_app_selected === 42 ? null : (
                  <NavItem
                    eventKey={Constants.CUPOM_SCREEN}
                    onClick={() => {
                      this.props.history.replace("/" + Constants.CUPOM_SCREEN);
                    }}
                  >
                    <NavIcon>
                      <FontAwesomeIcon
                        icon={faFileInvoiceDollar}
                        style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                      />
                    </NavIcon>
                    <NavText>FINANCEIRO</NavText>
                  </NavItem>
                )
              ) : null}
            </>
          ) : null}
          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access != 1 &&
          this.state.id_app_selected !== 39 &&
          this.state.id_app_selected !== 48 &&
          this.state.id_app_selected !== 49 &&
          !this.checkVmAdminUsaflex() &&
          this.state.id_app_selected !== 5 &&
          this.state.id_app_selected !== 34 ? (
            <NavItem
              eventKey={Constants.CONTENT_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.CONTENT_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faPlay}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>
                {this.state.id_app_selected !== 4 ? "CURSOS" : "CONTEÚDOS"}
              </NavText>
            </NavItem>
          ) : null}
          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access !== 1 &&
          this.state.id_app_selected !== 4 &&
          this.state.id_app_selected !== 5 &&
          this.state.id_app_selected !== 39 &&
          this.state.id_app_selected !== 49 &&
          !this.checkVmAdminUsaflex() &&
          this.state.id_app_selected !== 31 &&
          this.state.id_app_selected !== 34 ? (
            <NavItem
              eventKey={Constants.MANUAL_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.MANUAL_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>MANUAIS</NavText>
            </NavItem>
          ) : null}
          {/*this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access != 1 &&
          this.state.id_app_selected === 4 ? (
            <NavItem
              eventKey={Constants.MESSAGE_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.MESSAGE_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faCommentAlt}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>MENSAGEM</NavText>
            </NavItem>
            ) : null*/}
          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.props.current_app.has_early_access != 1 &&
          this.state.id_app_selected === 5 ? (
            <NavItem
              eventKey={Constants.FAQ_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.FAQ_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>MANUAIS</NavText>
            </NavItem>
          ) : null}
          {this.props.current_app &&
          this.props.current_app.has_invoices &&
          (this.state.id_app_selected === 12 ||
            this.state.id_app_selected === 11 ||
            this.state.id_app_selected === 14 ||
            this.state.id_app_selected === 22 ||
            this.state.id_app_selected === 27 ||
            this.state.id_app_selected === 29 ||
            this.state.id_app_selected === 32 ||
            this.state.id_app_selected === 33 ||
            this.state.id_app_selected === 36 ||
            this.state.id_app_selected === 37 ||
            this.state.id_app_selected === 38 ||
            this.state.id_app_selected === 40 ||
            this.state.id_app_selected === 41 ||
            this.state.id_app_selected === 43 ||
            this.state.id_app_selected === 42 ||
            this.state.id_app_selected === 44 ||
            this.state.id_app_selected === 46 ||
            this.state.id_app_selected === 49 ||
            this.state.id_app_selected === 50 ||
            this.state.id_app_selected === 39) ? (
            <NavItem
              eventKey={Constants.ADMIN_TICKET_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.ADMIN_TICKET_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faCommentAlt}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>DEMANDAS</NavText>
              <NavItem
                eventKey={Constants.ADMIN_TICKET_DEPARTMENT_SCREEN}
                onClick={() => {
                  this.props.history.replace(
                    "/" + Constants.ADMIN_TICKET_DEPARTMENT_SCREEN
                  );
                }}
              >
                <NavText>CONTATO</NavText>
              </NavItem>
            </NavItem>
          ) : null}
          {this.props.current_app &&
          this.props.current_app.has_invoices &&
          this.state.id_app_selected !== 4 &&
          this.state.id_app_selected !== 3 &&
          this.state.id_app_selected !== 49 &&
          !this.checkVmAdminUsaflex() &&
          this.state.id_app_selected !== 5 ? (
            <NavItem
              eventKey={Constants.ADMIN_STORE_SCREEN}
              onClick={() => {
                this.props.history.replace("/" + Constants.ADMIN_STORE_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faStore}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>LOJAS</NavText>
            </NavItem>
          ) : null}
          {(this.props.db_user &&
            this.props.db_user.app_admin &&
            this.state.id_app_selected === 22) ||
          this.state.id_app_selected === 29 ||
          this.state.id_app_selected === 11 ||
          this.state.id_app_selected === 33 ||
          this.state.id_app_selected === 36 ||
          this.state.id_app_selected === 37 ||
          this.state.id_app_selected === 38 ||
          this.state.id_app_selected === 40 ||
          this.state.id_app_selected === 46 ||
          this.state.id_app_selected === 50 ||
          this.state.id_app_selected === 41 ? (
            <NavItem
              eventKey={Constants.CHECKLIST_SCREEN /*"forms "*/}
              onClick={() => {
                this.props.history.replace("/" + Constants.CHECKLIST_SCREEN);
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faTasks}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>ATIVIDADES</NavText>
            </NavItem>
          ) : null}
          {this.props.db_user &&
          this.props.db_user.app_admin &&
          this.state.id_app_selected !== 49 &&
          !this.checkVmAdminUsaflex() &&
          this.props.current_app.has_early_access != 1 ? (
            <NavItem
              eventKey={Constants.ADMIN_PUSH_NOTIFICATIONS_SCREEN}
              onClick={() => {
                this.props.history.replace(
                  "/" + Constants.ADMIN_PUSH_NOTIFICATIONS_SCREEN
                );
              }}
            >
              <NavIcon>
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>ENVIAR PUSH</NavText>
            </NavItem>
          ) : null}
          {/* {this.props.db_user &&
            this.props.db_user.app_admin &&
            this.props.current_app.has_early_access !== 1 &&
            this.state.id_app_selected != 22 && ( //demo-1
              <NavItem
                eventKey={Constants.ADMIN_COMMUNICATION_CHANNEL_SCREEN}
                onClick={() => {
                  this.props.history.replace(
                    "/" + Constants.ADMIN_COMMUNICATION_CHANNEL_SCREEN
                  );
                }}
              >
                <NavIcon>
                  <FontAwesomeIcon
                    icon={faHeadset}
                    style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                  />
                </NavIcon>
                <NavText>
                  {this.state.id_app_selected !== 4 ? "AJUDA" : "SUPORTE"}
                </NavText>
              </NavItem>
            )} */}
          {this.props.db_user &&
            this.props.db_user.app_admin &&
            this.props.current_app.has_early_access !== 1 && (
              // this.state.id_app_selected == 22 &&
              <NavItem
              // eventKey={Constants.ZEN_DESK}
              // onClick={() => {
              //   this.props.history.replace("/" + Constants.ZEN_DESK);
              // }}
              >
                <NavIcon>
                  <FontAwesomeIcon
                    icon={faHeadset}
                    style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                  />
                </NavIcon>

                <NavText>
                  <a
                    style={{ textDecoration: "none", color: "#ddd" }}
                    href="https://forms.clickup.com/31116948/f/xnkmm-1643/OMF0110UCX51QFJTMS" //"https://insidetheboxhelp.zendesk.com/hc/pt-br"
                    target="_blank"
                  >
                    CENTRAL DE AJUDA
                  </a>
                </NavText>
              </NavItem>
            )}
          {/* //* dani aqui */}

          {this.props.db_user &&
          this.props.db_user.app_admin &&
          //this.state.id_app_selected !== 49 &&
          !this.checkVmAdminUsaflex() &&
          this.props.current_app.has_early_access != 1 ? (
            <NavItem eventKey="settings">
              <NavIcon>
                <FontAwesomeIcon
                  icon={faCog}
                  style={{ fontSize: "1.75em", verticalAlign: "middle" }}
                />
              </NavIcon>
              <NavText>CONFIGURAÇÕES</NavText>
              {/* //demandas aqui */}
              {/* {(this.props.current_app &&
                this.props.current_app.has_invoices &&
                this.state.id_app_selected === 11) ||
              this.state.id_app_selected === 12 ||
              this.state.id_app_selected === 14 ||
              this.state.id_app_selected === 22 ||
              this.state.id_app_selected === 27 ||
              this.state.id_app_selected === 29 ||
              this.state.id_app_selected === 32 ||
              this.state.id_app_selected === 33 ||
              this.state.id_app_selected === 34 ||
              this.state.id_app_selected === 36 ||
              this.state.id_app_selected === 37 ||
              this.state.id_app_selected === 38 ||
              this.state.id_app_selected === 40 ||
              this.state.id_app_selected === 41 ||
              this.state.id_app_selected === 43 ||
              this.state.id_app_selected === 42 ? (
                <NavItem
                  eventKey={Constants.ADMIN_TICKET_DEPARTMENT_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_TICKET_DEPARTMENT_SCREEN
                    );
                  }}
                >
                  <NavText>DEMANDAS</NavText>
                </NavItem>
              ) : null} */}
              <NavItem
                eventKey={Constants.ADMIN_APP_SCREEN}
                onClick={() => {
                  this.props.history.replace("/" + Constants.ADMIN_APP_SCREEN);
                }}
              >
                <NavText>TUTORIAL</NavText>
              </NavItem>
              {this.props.db_user &&
              this.props.db_user.app_admin &&
              this.props.current_app.has_early_access !== 1 &&
              this.state.id_app_selected === 4 ? (
                <NavItem
                  eventKey={Constants.FAQ_SCREEN}
                  onClick={() => {
                    this.props.history.replace("/" + Constants.FAQ_SCREEN);
                  }}
                >
                  <NavText>AJUDA</NavText>
                </NavItem>
              ) : null}
              {/*this.props.db_user &&
                this.props.db_user.app_admin &&
                this.props.current_app.has_early_access !== 1 && ( //demo-1
                  // this.state.id_app_selected === 22 &&
                  <NavItem
                    eventKey={Constants.ADMIN_COMMUNICATION_CHANNEL_SCREEN}
                    onClick={() => {
                      this.props.history.replace(
                        "/" + Constants.ADMIN_COMMUNICATION_CHANNEL_SCREEN
                      );
                    }}
                  >
                    <NavText>CENTRAL DE IDEIAS</NavText>
                  </NavItem>
                  )*/}
              {this.props.current_app &&
              this.props.current_app.has_invoices &&
              (this.state.id_app_selected === 4 ||
                this.state.id_app_selected === 3 ||
                this.state.id_app_selected === 5) ? (
                <NavItem
                  eventKey={Constants.ADMIN_STORE_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_STORE_SCREEN
                    );
                  }}
                >
                  <NavText>LOJAS</NavText>
                </NavItem>
              ) : null}
              {this.props.current_app &&
              this.props.current_app.has_invoices &&
              this.state.id_app_selected !== 4 &&
              this.state.id_app_selected !== 11 &&
              this.state.id_app_selected !== 12 &&
              this.state.id_app_selected !== 14 &&
              this.state.id_app_selected !== 22 &&
              this.state.id_app_selected !== 26 &&
              this.state.id_app_selected !== 27 &&
              this.state.id_app_selected !== 29 &&
              this.state.id_app_selected !== 30 &&
              this.state.id_app_selected !== 32 &&
              this.state.id_app_selected !== 33 &&
              this.state.id_app_selected !== 34 &&
              this.state.id_app_selected !== 35 &&
              this.state.id_app_selected !== 36 &&
              this.state.id_app_selected !== 37 &&
              this.state.id_app_selected !== 38 &&
              this.state.id_app_selected !== 39 &&
              this.state.id_app_selected !== 40 &&
              this.state.id_app_selected !== 45 &&
              this.state.id_app_selected !== 46 &&
              this.state.id_app_selected !== 41 ? (
                <NavItem
                  eventKey={Constants.ADMIN_BRAND_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_BRAND_SCREEN
                    );
                  }}
                >
                  <NavText>MARCAS</NavText>
                </NavItem>
              ) : null}
              {/*{this.props.current_app && this.props.current_app.has_invoices ? (*/}
              {/*  <NavItem eventKey={Constants.ADMIN_SELLING_REGION_SCREEN} onClick={() => {*/}
              {/*    this.props.history.replace('/' + Constants.ADMIN_SELLING_REGION_SCREEN)*/}
              {/*  }}>*/}
              {/*    <NavText>*/}
              {/*      REGIÕES DE VENDA*/}
              {/*    </NavText>*/}
              {/*  </NavItem>*/}
              {/*) : null}*/}

              <NavItem
                eventKey={Constants.ADMIN_ROLE_SCREEN}
                onClick={() => {
                  this.props.history.replace("/" + Constants.ADMIN_ROLE_SCREEN);
                }}
              >
                <NavText>PAPÉIS</NavText>
              </NavItem>
              {/*this.state.id_app_selected &&
              this.state.id_app_selected === 29 ? (<NavItem
                eventKey={Constants.ADMIN_ROLENEW_SCREEN}
                onClick={() => {
                  this.props.history.replace("/" + Constants.ADMIN_ROLENEW_SCREEN);
                }}
              >
                <NavText>SEGMENTAÇÃO</NavText>
              </NavItem>) : null*/}

              <NavItem
                eventKey={Constants.ADMIN_USER_SCREEN}
                onClick={() => {
                  this.props.history.replace("/" + Constants.ADMIN_USER_SCREEN);
                }}
              >
                <NavText>
                  {this.state.id_app_selected === 3
                    ? "USUÁRIOS"
                    : "ADMINISTRADORES"}
                </NavText>
              </NavItem>
              {this.state.id_app_selected &&
              this.state.id_app_selected === 5 ? (
                <NavItem
                  eventKey={Constants.ADMIN_AUTHOR_SCREEN}
                  onClick={() => {
                    this.props.history.replace(
                      "/" + Constants.ADMIN_AUTHOR_SCREEN
                    );
                  }}
                >
                  <NavText>AUTORES</NavText>
                </NavItem>
              ) : null}

              <NavItem
                eventKey={Constants.ADMIN_SUBJECT_SCREEN}
                onClick={() => {
                  this.props.history.replace(
                    "/" + Constants.ADMIN_SUBJECT_SCREEN
                  );
                }}
              >
                <NavText>TEMAS</NavText>
              </NavItem>

              {/* this.props.db_user &&
              this.props.db_user.app_admin &&
              this.props.current_app.has_early_access != 1 &&
              this.state.id_app_selected !== 4 &&
              this.state.id_app_selected !== 5 ? (
                <NavItem
                  eventKey={Constants.FAQ_SCREEN}
                  onClick={() => {
                    this.props.history.replace("/" + Constants.FAQ_SCREEN);
                  }}
                >
                  <NavText>FAQ</NavText>
                </NavItem>
                ) : null */}
            </NavItem>
          ) : null}
          {/*FINISHING LIVE MENU FOR OLDER VERSIONS */}
          <Separator />
          <NavItem eventKey="logout">
            <NavIcon>
              <FontAwesomeIcon
                icon={faPowerOff}
                style={{ fontSize: "1.75em", verticalAlign: "middle" }}
              />
            </NavIcon>
            <NavText
              style={{ paddingRight: 32, color: expanded ? "#ddd" : "black" }}
              title="SAIR"
            >
              SAIR
            </NavText>
          </NavItem>
        </Nav>
      </SideNav>
    ) : (
      <div>
        <p> App Desativado </p>
      </div>
    );
  }
}

export default withRouter(Sidebar);
