import React, { Component } from 'react';
import ContentTrailAdminScreen from '../components/generic_screens/ContentTrailAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Content from '../../models/Content';
import Role from '../../models/Role';

import ContentTrailCard from '../components/cards/ContentTrailCard';
import ContentTrialDescription from '../components/description_cards/ContentTrialDescription';
import Spinner from '../components/buttons/Spinner';

class AdminContentTrailScreen extends Component {
  editionTimeout = null;

  state = {
    contents: [],
    relations: [],
    filters: [],
    loading: true,
  };

  // TEXTS
  searchText_placeholder = 'Título do Conteúdo';
  addButton_placeholder = 'Conteúdo';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    Content.getContentTrail(this.props.startContent.id_content)
      .then((contents) => {
        this.setState({ contents: contents });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
    Role.query().then((roles) => {
      let relations = this.state.relations;
      relations[0] = roles;
      this.setState({ relations: relations });
    });
    this.reloadContent();
  }

  async reloadContent(parameters) {
    try {
      let contents = await Content.getContentTrail(
        this.props.startContent.id_content,
      );

      if (contents && contents.length > 0) {
        contents = contents.sort((obj1, obj2) => {
          let obj1_order_content = obj1.order_content;
          let obj2_order_content = obj2.order_content;

          if (!obj1_order_content) {
            obj1_order_content = 99999999999999;
          }

          if (!obj2_order_content) {
            obj2_order_content = 99999999999999;
          }

          if (obj1.order_content < obj2.order_content) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      this.setState({ loading: false });
      this.setState({ contents: contents });
    } catch (err) {
     toast.error('Erro ao carregar conteúdos');
    }
  }

  addContent = async (contentType) => {
    let contentList = [];
    if (this.state.contents && this.state.contents.length > 0) {
      contentList = this.state.contents;
    }
    let order_content =
      contentList && contentList.length > 0 ? contentList.length : 0;
    let newContent = new Content();
    newContent.content = {
      id_app: localStorage.getItem('ID_APP'),
      content_trail: this.props.startContent.id_content,
      is_challenge: 1,
      is_fixed: null,
      is_marketing: null,
      roles: this.props.startContent.roles,
      starting_content: this.props.startContent.starting_content,
      order_content: order_content,
      content_type: contentType,
    };
    let contentObj = {};
    try {
      contentObj = await newContent.saveTrail();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }

    contentList.push(contentObj);
    this.setState({ contents: contentList });
    this.state.contents = contentList;
    this.reloadContent();
    return contentObj;
  };

  updateContent = (contentObj, field, value, force) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_content === contentObj.id_content;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          contentToUpdate[prop] = value[prop];
        }
      }
    } else if (field.indexOf('.') > 0) {
      let hierarchy = field.split('.');
      let obj = contentToUpdate;
      for (let i = 0; i < hierarchy.length; i++) {
        if (i === hierarchy.length - 1) {
          obj[hierarchy[i]] = value;
          break;
        }
        obj = obj[hierarchy[i]];
      }
    } else {
      contentToUpdate[field] = value;
    }
    this.setState({ contents: contentList });
    return contentList[position];
  };

  deleteContent = async (contentObj) => {
    let contentList = this.state.contents;

    // Finds Edited content on contents list and change Content_name value
    for (let i = 0; i < contentList.length; i++) {
      if (contentObj.id_content === contentList[i].id_content) {
        contentList[i].remove();
        contentList.splice(i, 1);
        break;
      }
    }

    this.setState({ contents: contentList });
  };

  saveContent = async (contentObj) => {
    let contentList = this.state.contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_content === contentObj.id_content;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    if (contentToUpdate != null) {
      /*let contentObj = contentToUpdate;*/
      let contentObj = contentToUpdate;

      try {
        let order = position;
        //let predecessor = contentList.find(content => content.order_content === order - 1);
        if (order < 0) {
          throw new Error('content_order');
          /*} else if (order > 1 && !predecessor) {
                        throw new Error('content_order_number');*/
        } else if (contentToUpdate.content_type === 3) {
          if (
            contentToUpdate.form.forms_questions &&
            contentToUpdate.form.forms_questions.length
          ) {
            let form_questions = contentToUpdate.form.forms_questions;
            for (let k = 0; k < form_questions.length; k++) {
              if (form_questions[k].form_question_type === 1) {
                if (!form_questions[k].forms_choices) {
                  if (form_questions[k].forms_choices.length === 0) {
                    throw new Error('form_choices');
                  } else if (!form_questions[k].forms_choices[0].form_choice) {
                    throw new Error('form_choices');
                  }
                }
              }
              if (form_questions[k].form_question_type === 2) {
                if (!form_questions[k] || form_questions[k] === '') {
                  throw new Error('form_questions');
                }
              }

              if (form_questions[k].form_question_type === 3) {
                if (!form_questions[k] || form_questions[k] === '') {
                  throw new Error('form_questions');
                }
              }
            }
          } else {
            throw new Error('form_questions');
          }
        }
        contentObj = await contentToUpdate.save();
        // Content upload to server worked
        contentList[position] = contentObj;
        this.setState({ contents: contentList });
        this.state.contents = contentList;

        toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
        switch (err.message) {
          case 'form_questions':
           toast.error(
              'Erro ao salvar conteúdo! Elabore uma pergunta para seu formulário.',
            );
            break;
          case 'form_choices':
           toast.error(
              'Erro ao salvar conteúdo! Pergunta do tipo múltipla deve ter pelo menos uma alternativa.',
            );
            break;
          case 'content_order':
           toast.error('Erro ao salvar conteúdo! Defina uma ordem.');
            break;
          case 'content_order_number':
           toast.error(
              'Erro ao salvar conteúdo! Conteúdo não apresenta ordem correta.',
            );
            break;
          default:
           toast.error('Erro ao salvar conteúdo!');
            break;
        }
      }
    }
  };

  refreshContents = async (parameters) => {
    this.reloadContent(parameters);
  };

  saveContentList = async (contentListObj) => {
    let contentListToUpdate = contentListObj.map((content, index) => {
      content.order_content = index;
      return content;
    });

    if (contentListToUpdate != null) {
      try {
        for (let i = 0; i < contentListToUpdate.length; i++) {
          /*let contentObjtoUpdate = */
          contentListToUpdate[i].save();
          //contentListToUpdate[i] = contentObjtoUpdate[i];
        }

        this.setState({ contents: contentListToUpdate });
        toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
        switch (err.message) {
          default:
           toast.error('Erro ao salvar conteúdo!');
            break;
        }
      }
    }
  };

  updateContentList = (objectList) => {
    this.state.contents = objectList;
    this.saveContentList(this.state.contents);
    this.setState({ loading: false });
  };

  render() {
    return (
      <> 
      {/* //certo */}
      {this.state.contents.length===0 && this.state.loading ? <Spinner
       margin='13px' 
      texto={'carregando...'}
      border={'#9BB3D4'}
      top={'#1D2D44'}/>
      :
      <ContentTrailAdminScreen
      loading={this.state.loading}
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.contents}
        objectCard={ContentTrailCard}
        objectDescription={ContentTrialDescription}
        addObject={this.addContent}
        updateObject={this.updateContent}
        deleteObject={this.deleteContent}
        saveObject={this.saveContent}
        refreshObjects={this.refreshContents}
        relations={this.state.relations}
        objectIdName={'id_content'}
        startingContent={this.props.startContent}
        objectModel={Content}
        onFilterReload={true}
        updateObjectList={this.updateContentList}
      ></ContentTrailAdminScreen>
      }
      </>
    );
  }
}

export default AdminContentTrailScreen;
