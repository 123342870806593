import React from "react";
import {
  Grid,
  Paper,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  Button,
} from "@material-ui/core";
import SaveButton from "../buttons/SaveButton";
import DeleteButton from "../buttons/DeleteButton";
import ArchiveButton from "../buttons/ArchiveButton";
import SearchableSelect from "../selectbox/SearchableSelect.js";
import { DateTimePicker, DatePicker } from "@material-ui/pickers/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ptBR } from "date-fns/locale";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import DateFnsUtils from "@date-io/date-fns";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { CustomDropzone } from "../CustomDropzone";
import FormQuestionCard from "../cards/FormQuestionCard";
import Training from "../../../models/Training";
import Content from "../../../models/Content";
import Select from "react-select";
import cloneDeep from "lodash/cloneDeep";
import AddIcon from "@material-ui/icons/Add";
import AdminContentTrailScreen from "../../screens/AdminContentTrailScreen";
import { toast } from "react-toastify";
import ContentDescriptionClassTypePill from './ContentDescriptionClassTypePill';
import moment from "moment";

const ID_APPS_COURSE_WITH_TOPICS = [11, 12, 45, 46, 48, 23, 24, 4, 27, 29, 43, 50, 44 ];

export default function ContentDescription(props) {
  const userIdApp = localStorage.getItem("ID_APP");
  this.editionTimeout = null;
  // Object to Render
  this.object = props.object;

  this.app = props.app;

  this.roles = props.relations[0];
  this.subjects = props.subjects[0];
  this.contents = props.contents ? props.contents : [];

  this.updateContent = props.updateObject;
  // console.log(this.updateContent, "UPDATE CONTENT");

  if (!this.object.content_type) {
    this.updateContent(this.object, "content_type", 6);
  }

  this.deleteContent = props.deleteObject;
  this.saveContent = props.saveObject;
  this.publishObject = props.publishObject;
  this.nullDescription = props.nullDescription;

  this.showList = props.showList;
  let content_type = "trail";
  this.object.content_type = 6;
  if (!this.object.training) {
    this.object.training = {
      id_app: localStorage.getItem("ID_APP"),
      id_training: this.object.id_training ? this.object.id_training : null,
    };
  }

  this.object.roleOptions = this.roles.map((role) => {
    let roleOption = {
      value: role.id_role,
      label: role.role_name,
    };
    return roleOption;
  });

  if (this.object.roles !== undefined) {
    this.object.defaultRoleValues = this.object.roles.map((role) => {
      return {
        value: role.id_role ? role.id_role : null,
        label: role.role_name ? role.role_name : null,
      };
    });
  } else {
    this.object.defaultRoleValues = [];
  }
  if (this.contents !== undefined) {
    this.object.contentOptions = this.contents.map((content) => {
      let contentOptions = {
        value: content.id,
        label: content.title,
      };
      return contentOptions;
    });
  } else {
    this.object.contentOptions = [];
  }
  //remove my self from pre required
  this.object.contentOptions = this.object.contentOptions.filter(
    (content) => content.value !== this.object.id_content
  );

  if (this.contents) {
    this.object.defaultContentValues = this.contents.map((content) => {
      if (content.id === this.object.id_predecessor_content) {
        let contentOptions = {
          value: content.id,
          label: content.title,
        };
        return contentOptions;
      }
    });
    this.object.defaultContentValues = this.object.defaultContentValues.filter(
      (item) => item
    );
  } else {
    this.object.defaultContentValues = [];
  }

  if (this.object.id_topic) {
    if (!this.object.defaultSubjectValue) {
      this.object.defaultSubjectValue = {
        value: this.object.id_topic ? this.object.id_topic : null,
        label: this.object.topic_name ? this.object.topic_name : null,
      };
    }
  }

  if (this.subjects !== undefined) {
    this.object.subjectOptions = this.subjects.map((subjects) => {
      let subjectOption = {
        value: subjects.id_topic,
        label: subjects.topic_name,
      };
      return subjectOption;
    });
  } else {
    this.object.subjectOptions = [];
  }

  if (this.object.predecessor_content && this.object.id_predecessor_content) {
    this.object.defaultPredecessorContentValue = {
      value: this.object.predecessor_content,
      label: this.object.predecessor_content.form
        ? this.object.predecessor_content.form.form_title
        : this.object.predecessor_content.training
        ? this.object.predecessor_content.training.training_title
        : this.object.predecessor_content.form_title
        ? this.object.predecessor_content.form_title
        : this.object.predecessor_content.training_title
        ? this.object.predecessor_content.training_title
        : "ID do Conteúdo:" + this.object.predecessor_content.id_content,
    };
  }
  //   else {
  //     this.object.defaultPredecessorContentValue = {
  //       value: null,
  //       label: '',
  //     };
  //   }

  let updateDescriptionState = props.updateDescriptionState
    ? props.updateDescriptionState
    : () => {};
  if (!props.descriptionState.content_type) {
    updateDescriptionState({ content_type: content_type });
  }

  this.onClickArchiveContent = () => {
    if (this.object.title === null || this.object.title === undefined) {
      toast.info("Para arquivar adicione um titulo");
      return;
    } else if (this.object.title?.trim().length === 0) {
      toast.info("Para arquivar adicione um titulo");
      return;
    }
    this.onChangeUpdate("is_archived")({
      target: {
        value: this.object.is_archived ? 0 : 1,
      },
    });
    this.saveContent(this.object);
  };

  this.onChangeUpdateDateStart = (field, force) => (event) => {
    event = new Date(event);
    event.setHours(11, 0, 0);

    this.updateContent(this.object, field, event, force);
  };
  this.onChangeUpdateDateEnd = (field, force) => (event) => {
    event = new Date(event);
    event.setHours(11, 0, 0);

    this.updateContent(this.object, field, event, force);
  };

  this.onClickPublishContent = () => {
    if (this.object.title === null || this.object.title === undefined) {
      toast.info("Para publicar adicione um titulo");
      return;
    } else if (this.object.title?.trim().length === 0) {
      toast.info("Para publicar adicione um titulo");
      return;
    }

    this.object.is_published = 1;
    this.publishObject(this.object);
  };

  this.onClickSaveContent = () => {
    // this.preRequiredContentsDelete();
    // console.log("save");

    if (this.object.title === null || this.object.title === undefined) {
      toast.info("Para salvar adicione um titulo");
      return;
    } else if (this.object.title?.trim().length === 0) {
      toast.info("Para salvar adicione um titulo");
      return;
    }
    this.saveContent(this.object);
  };

  this.onClickDeleteContent = () => {
    // click remover
    if (window.confirm("Tem certeza que deseja remover esse item?")) {
      // this.preRequiredContentsDelete();
      this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the content
      this.deleteContent(this.object);
    }
  };

  this.onChangeUpdate =
    (field, force = false) =>
    (event) => {
      let value = event && event.target ? event.target.value : null;

      if (
        field === "starting_content" ||
        field === "is_fixed" ||
        field === "is_marketing" ||
        field === "is_challenge" ||
        field === "is_removed_when_completed" ||
        field === "have_medal"
      ) {
        value = event.target.checked ? 1 : 0;
      } else if (field === "content_title") {
        switch (content_type) {
          case "challenge":
          case "video":
          case "pdf":
            field = "training.training_title";
            break;
          case "form":
            field = "form.form_title";
            break;
          default:
            field = "training.training_title";
        }
      }

      this.updateContent(this.object, field, value, force);
    };

  // essa função emite um alerta sempre, mesmo quando o formato da imagem é suportado

  this.onDropActionCoverImage = async (file) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedCoverImage: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = null;
      switch (this.object.content_type) {
        case 1:
        case 2:
        case 4:
        case 5:
        case 6:
          imageUrl = await Training.sendTrainingCoverImage(
            file,
            null,
            progressFunction
          );
          this.updateContent(
            this.object,
            "training.training_cover_image",
            imageUrl.url,
            true
          );
          break;
        case 3:
          imageUrl = await Training.sendTrainingCoverImage(
            file,
            null,
            progressFunction
          );
          this.updateContent(
            this.object,
            "form.form_image",
            imageUrl.url,
            true
          );
          break;
        default:
          toast.error("Tipo de conteúdo não permite imagem de capa!");
      }
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteCoverImage = () => {
    switch (content_type) {
      case "video":
      case "pdf":
      case "challenge":
      case "image":
      case "trail":
        return this.updateContent(
          this.object,
          "training.training_cover_image",
          null,
          true
        );
      case "form":
        return this.updateContent(this.object, "form.form_image", null, true);
      default:
        toast.error("Tipo de conteúdo não permite imagem de capa!");
    }
  };

  this.onDropActionVideo = async (file) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedVideo: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = await Training.sendTrainingVideo(
        file,
        this.object.training.id_training,
        progressFunction
      );
      this.updateContent(
        this.object,
        "training.training_video",
        imageUrl.url,
        true
      );
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteVideo = () => {
    return this.updateContent(
      this.object,
      "training.training_video",
      null,
      true
    );
  };

  this.onDropActionFile = async (file, field) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedFile: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = await Training.sendTrainingFile(
        file,
        this.object.training.id_training,
        progressFunction
      );
      this.updateContent(this.object, field, imageUrl.url, true);
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.onDropActionImage = async (file, idx) => {
    file = file[0];

    const progressFunction = (completed) => {
      let completedImages = props.completed.completedImages
        ? props.completed.completedImages
        : [];
      completedImages[idx] = completed;
      const completedState = {
        completedImages: completedImages,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = await Training.sendTrainingFile(
        file,
        this.object.training.id_training,
        progressFunction
      );
      let imageToUpdate = { training_image_filename: imageUrl.url };
      let training_images = this.object.training.training_images
        ? this.object.training.training_images
        : [];
      training_images[idx].training_image_filename =
        imageToUpdate.training_image_filename;
      this.updateContent(
        this.object,
        "training.training_images",
        training_images,
        true
      );
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteFile = () => {
    return this.updateContent(
      this.object,
      "training.training_file",
      null,
      true
    );
  };

  this.changeRadioFormType = (event) => {
    let value = event.target.value;

    value = Number(value);

    let form = this.object.form ? this.object.form : {};

    form.form_type = value;

    updateDescriptionState({ form: form });

    if (value === 2) {
      this.updateContent(this.object, "form.forms_questions", [
        { form_question_type: 2 },
      ]);
    } else if (value === 3) {
      this.updateContent(this.object, "form.forms_questions", [
        { form_question_type: 4 },
      ]);
    }

    this.updateContent(this.object, "form", form, true);
  };

  this.changeRadioTypeContent = (event) => {
    if (event.target.value === "form" && !this.object.form) {
      this.updateContent(
        this.object,
        "form",
        {
          form_type: 1,
          id_form: this.object.id_form ? this.object.id_form : null,
        },
        true
      );
    }

    switch (event.target.value) {
      case "video":
        content_type = "video";
        this.object.content_type = 1;
        this.object.screenOptions = this.object.screenOptionsDefault;
        break;
      case "image":
        content_type = "image";
        this.object.content_type = 2;
        this.object.screenOptions = this.object.screenOptionsDefault;
        break;
      case "form":
        content_type = "form";
        this.object.content_type = 3;
        this.object.screenOptions = this.object.screenOptionsDefault;
        break;
      case "challenge":
        content_type = "challenge";
        this.object.content_type = 4;
        delete this.object.form;
        this.object.screenOptions = this.object.screenOptionsChallenge;
        break;
      case "pdf":
        content_type = "pdf";
        this.object.content_type = 5;
        this.object.screenOptions = this.object.screenOptionsDefault;
        break;
      case "trail":
        content_type = "trail";
        this.object.content_type = 6;
        this.object.screenOptions = this.object.screenOptionsTrail;
        break;
    }

    updateDescriptionState({
      content_type: event.target.value,
      user_defined_content_type: event.target.value,
    });
  };

  this.onChangeUpdateSubjects = (value) => {
    if (value) {
      this.updateContent(this.object, "id_topic", value.value, true);
      this.object.defaultSubjectValue = {
        value: value.value,
        label: value.label,
      };
    } else {
      this.updateContent(this.object, "id_topic", null, true);
      this.object.defaultSubjectValue = {
        value: null,
        label: "",
      };
    }
  };

  this.onQuestionChange = (field, idxQuestion) => (event) => {
    let value = event && event.target ? event.target.value : null;
    let formsQuestionsList = cloneDeep(this.object.form.forms_questions);
    let formQuestionObj = formsQuestionsList[idxQuestion];

    if (formQuestionObj) {
      if (!isNaN(value)) {
        value = Number(value);
      }
      formQuestionObj[field] = value;
      this.updateContent(
        this.object,
        "form.forms_questions",
        formsQuestionsList
      );
    }
  };

  this.addQuestion = () => {
    let formsQuestionsList = this.object.form.forms_questions
      ? cloneDeep(this.object.form.forms_questions)
      : [];
    formsQuestionsList.push({ form_question_type: 1 });
    this.updateContent(this.object, "form.forms_questions", formsQuestionsList);
  };

  this.addImage = () => {
    let imagesList = this.object.training.training_images
      ? cloneDeep(this.object.training.training_images)
      : [];
    imagesList.push({ training_image_position: imagesList.length });
    this.updateContent(this.object, "training.training_images", imagesList);
  };

  this.showForm = () => {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            textAlign: "left",
          }}
        >
          {/* //voltar aqui */}
          Atividades :
        </span>
        {this.object.form && this.object.form.forms_questions
          ? this.object.form.forms_questions.map((question, idx) => {
              let deleteQuestion = () => {
                let formsQuestionsList = cloneDeep(
                  this.object.form.forms_questions
                );
                formsQuestionsList.splice(idx, 1);
                this.updateContent(
                  this.object,
                  "form.forms_questions",
                  formsQuestionsList
                );
              };

              return (
                <FormQuestionCard
                  formQuestion={question}
                  onQuestionChange={this.onQuestionChange}
                  deleteQuestion={deleteQuestion}
                  idx={idx}
                  formType={
                    this.object.form ? this.object.form.form_type : null
                  }
                />
              );
            })
          : null}
        {this.object.form &&
        this.object.form.form_type !== 2 &&
        this.object.form.form_type !== 3 ? (
          <Button onClick={this.addQuestion} variant="contained">
            {/* Adicionar Pergunta */}
            Adicionar Pergunta
            <AddIcon />
          </Button>
        ) : null}
      </Grid>
    );
  };

  this.deleteImage = (idx) => {
    let ImagesList = cloneDeep(this.object.training.training_images);
    ImagesList.splice(idx, 1);
    this.updateContent(this.object, "training.training_images", ImagesList);
  };

  this.onChangeUpdateTrainingImage = (event, idx) => {
    let imagesList = cloneDeep(this.object.training.training_images);
    imagesList[idx].training_image_description = event.target.value;
    this.object.training.training_images = imagesList;
    this.updateContent(this.object, "training.training_images", imagesList);
  };

  this.showTrailContent = () => {
    return (
      <Grid xs={12} item style={{ width: "100%" }} contain spacing={2}>
        <AdminContentTrailScreen startContent={this.object} />
      </Grid>
    );
  };

  this.showImage = () => {
    return (
      <Grid xs={12} item style={{ width: "100%" }} spacing={2}>
        {this.object.training &&
        this.object.training.training_images &&
        this.object.training.training_images.length > 0
          ? this.object.training.training_images.map((image, idx) => {
              return (
                <Grid container spacing={2} direction={"row"}>
                  {image.training_image_filename ? (
                    <Grid container xs={6}>
                      <Grid item xs={12}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            lineHeight: 1,
                            letterSpacing: "0.00938em",
                            float: "left",
                            color: "rgba(0, 0, 0, 0.54)",
                            paddingBottom: "10px",
                          }}
                        >
                          Imagem {idx + 1}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <img
                          style={{ maxHeight: "30vh" }}
                          src={image.training_image_filename}
                          id={"image"}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid xs={6}>
                      <CustomDropzone
                        onDropAction={(file) => {
                          this.onDropActionImage(file, idx);
                        }}
                        completed={
                          props.completed.completedImages &&
                          props.completed.completedImages.length > 0
                            ? props.completed.completedImages[idx]
                            : 0
                        }
                        inactiveLabel={"Arraste a imagem para cá"}
                        // inactiveLabel={'Arraste a imagem para cá'}
                        activeLabel={"Solte a imagem"}
                      />
                      <Grid xs={12}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 400,
                            lineHeight: 1,
                            letterSpacing: "0.00938em",
                            float: "bottom",
                            color: "rgba(0, 0, 0, 0.54)",
                            paddingBottom: "10px",
                          }}
                        >
                          Tamanho Máximo da Imagem: 10 Mb
                        </span>
                      </Grid>
                    </Grid>
                  )}
                  <Grid xs={6} item>
                    <Grid item xs={12}>
                      <span
                        style={{
                          float: "left",
                          color: "rgba(0, 0, 0, 0.54)",
                          padding: "0",
                          fontSize: "13px",
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          fontWeight: "400",
                          lineHeight: "1",
                          letterSpacing: "0.00938em",
                          marginTop: "10px",
                          paddingLeft: "20px",
                          paddingBottom: "10px",
                        }}
                      >
                        Descrição da Imagem {idx + 1} -{" "}
                        {image.training_image_description
                          ? image.training_image_description.length
                          : 0}
                        /1000
                      </span>
                    </Grid>
                    <TextareaAutosize
                      aria-label={"Descrição da Imagem"}
                      placeholder={
                        "Descrição da Imagem(Máximo de 1000 caracteres)"
                      }
                      maxLength={1000}
                      rows={4}
                      value={image.training_image_description}
                      onChange={(event) => {
                        this.onChangeUpdateTrainingImage(event, idx);
                      }}
                      style={{
                        width: "90%",
                        height: "70%",
                        padding: "10px 10px",
                      }}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Button
                      onClick={() => {
                        this.deleteImage(idx);
                      }}
                      variant="contained"
                      color={"secondary"}
                      style={{ background: "#CC0B0B" }}
                    >
                      Remover Imagem {idx + 1}
                    </Button>
                  </Grid>
                  <Grid xs={12}>
                    <div style={{ height: "40px" }} />
                  </Grid>
                </Grid>
              );
            })
          : null}

        <Button onClick={this.addImage} variant="contained" color={"primary"}>
          Adicionar Imagem
          <AddIcon />
        </Button>
        <Grid item xs={12}>
          <span
            style={{
              float: "left",
              color: "rgba(0, 0, 0, 0.54)",
              padding: "0",
              fontSize: "13px",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontWeight: "400",
              lineHeight: "1",
              letterSpacing: "0.00938em",
              marginTop: "10px",
            }}
          ></span>
        </Grid>
      </Grid>
    );
  };

  this.onChangeUpdateRoles = (value) => {
    let roles;
    if (value != null) {
      //null cant use map function
      roles = value.map((obj) => {
        return {
          //revert the label to put the id_role and role_name in the Content Object
          id_role: obj.value,
          role_name: obj.label,
        };
      });
    } else {
      roles = [];
    }
    this.updateContent(this.object, "roles", roles, true);
  };
  this.onChangeUpdateContents = (value) => {
    value = value && value.value? value.value : null;
    this.updateContent(
      this.object,
      "id_predecessor_content",
      value,
      true
    );
  };
  this.preRequiredContentsDelete = async () => {
    // console.log(this.object.id_predecessor_content, "id prodecessor");
    // await Content.predecessor_content(this.object.id_predecessor_content);
    // console.log(this.object);
    // this.updateContent(this.object, "id_predecessor_content", null, true);
  };
  this.contentType = props.descriptionState.content_type;

  this.predecessorContentChosen = (value) => {
    this.updateContent(
      this.object,
      "id_predecessor_content",
      value ? (value.id_content ? value.id_content : null) : null,
      true
    );
    this.updateContent(this.object, "predecessor_content", value, true);
    if (value) {
      this.object.defaultPredecessorContentValue = {
        value: value,
        label: value.training_title
          ? value.training_title
          : value.form_title
          ? value.form_title
          : "ID:" + value.id_content,
      };
    } else {
      this.object.defaultPredecessorContentValue = {
        value: null,
        label: "",
      };
    }
  };

  if(this.object?.is_pill === 1){
    return(
      <ContentDescriptionClassTypePill 
        content={this.object}
        onChangeUpdate={this.onChangeUpdate}
        onChangeUpdateRoles={this.onChangeUpdateRoles}
        onClickPublishContent={this.onClickPublishContent}
        onClickSaveContent={this.onClickSaveContent}
        onClickDeleteContent={this.onClickDeleteContent}
        onClickArchiveContent={this.onClickArchiveContent}
        onChangeUpdateSubjects={this.onChangeUpdateSubjects}
        appHasTopicsInCourses={ID_APPS_COURSE_WITH_TOPICS.includes(Number(userIdApp))}
      />
    );
  }

  return (
    <div style={{ height: "auto" }}>
      {/* <Grid xs={6} item> */}
      <Grid container direction={"column"}>
        <Grid item xs={12}>
          {this.object.training && this.object.training.training_cover_image ? (
            <img
              style={{
                width: "30vw",
                height: "15vw",
                objectFit: "cover",
              }}
              src={
                this.object.training
                  ? this.object.training.training_cover_image
                  : null
              }
              id={"image-modal"}
            />
          ) : (
            <div id="picker">
              <CustomDropzone
                onDropAction={this.onDropActionCoverImage}
                completed={props.completed.completedCoverImage}
                inactiveLabel={
                  "Arraste a imagem (.jpeg, .png, .gif) de capa para cá"
                }
                activeLabel={"Solte a imagem de capa"}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <span
            style={{
              fontSize: "12px",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 400,
              lineHeight: 1,
              letterSpacing: "0.00938em",
              // float: 'left',
              color: "rgba(0, 0, 0, 0.54)",
              paddingBottom: "10px",
            }}
          >
            Imagem de Capa (1700px X 864px)
            {this.object.training &&
            this.object.training.training_cover_image ? (
              <a href="#" onClick={this.deleteCoverImage}>
                {" "}
                Remover Imagem
              </a>
            ) : null}
          </span>
        </Grid>
      </Grid>
      {/* </Grid> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          alignItems: "center",
          padding: "30px 20px 10px 20px",
        }}
      >
        <Grid container direction="column" spacing={2}>
          <Grid xs={12} container direction="row" spacing={1}>
            <Grid xs={6} item>
              <TextField
                label="Título Conteúdo"
                value={
                  this.object.training && this.object.training.training_title
                    ? this.object.training.training_title
                    : ""
                }
                style={{ width: "100%" }}
                fontSize="50px"
                onChange={this.onChangeUpdate("content_title", false)}
                onBlur={this.onChangeUpdate("content_title", true)}
                multiline
              />
            </Grid>
            <Grid xs={2} item></Grid>
            <Grid xs={1} item>
              <TextField label="ID" value={this.object.id_content} disabled />
            </Grid>
            <Grid xs={3} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label="Data da ultima alteração"
                  value={this.object.updated_date}
                  style={{ width: "100%" }}
                  format="dd/MM/yyyy HH:mm"
                  disabled
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid xs={2} item></Grid>
          <Grid xs={12} container direction="row" spacing={1}>
            <Grid xs={3} container direction={"column"}>
              <Grid item>
                <span
                  style={{
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    padding: "0",
                    fontSize: "13px",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: "400",
                    lineHeight: "1",
                    letterSpacing: "0.00938em",
                  }}
                >
                  Papéis:
                </span>
              </Grid>
              <Grid item>
                <Select
                  placeholder={"Selecione os Papéis"}
                  options={this.object.roleOptions}
                  value={this.object.defaultRoleValues}
                  onChange={this.onChangeUpdateRoles}
                  isMulti
                ></Select>
              </Grid>
            </Grid>
            {/*this.object.is_campaign === 1 ? <Calendar selectRange={true} /> : null*/}
            {this.object.is_campaign === 1 ? (
              <Grid xs={4} item>
                <MuiPickersUtilsProvider
                  locale={ptBR}
                  utils={DateFnsUtils}
                  style={{
                    border: "0px",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <DatePicker
                    label="Início da campanha"
                    value={this.object.start_date ? moment(this.object.start_date).add(3, 'hours') : this.object.start_date}
                    style={{ width: "100%", textDecoration: "none" }}
                    format="dd/MM/yyyy"
                    onChange={this.onChangeUpdateDateStart("start_date", false)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}
            {this.object.is_campaign === 1 ? (
              <Grid xs={4} item>
                <MuiPickersUtilsProvider
                  locale={ptBR}
                  utils={DateFnsUtils}
                  style={{
                    border: "0px",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <DatePicker
                    label="Fim da campanha"
                    value={this.object.end_date ? moment(this.object.end_date).add(3, 'hours') : this.object.end_date}
                    style={{ width: "100%", textDecoration: "none" }}
                    format="dd/MM/yyyy"
                    onChange={this.onChangeUpdateDateEnd("end_date", false)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            ) : null}
            <Grid xs={0.5} item></Grid>
           {!this.object.is_campaign && ID_APPS_COURSE_WITH_TOPICS.includes(Number(userIdApp)) ? (
              <Grid xs={3} container direction={"column"}>
                <Grid item>
                  <span
                    style={{
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Tema:
                  </span>
                </Grid>
                <Grid item>
                  <Select
                    placeholder={"Selecione o Tema"}
                    options={this.object.subjectOptions}
                    value={this.object.defaultSubjectValue}
                    onChange={this.onChangeUpdateSubjects}
                    isClearable
                  ></Select>
                </Grid>
              </Grid>
            ) : null}
            <Grid xs={0.5} item></Grid>
            {!this.object.is_campaign ? (
              <Grid xs={4} container direction={"column"}>
                <Grid item>
                  <span
                    style={{
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      padding: "0",
                      fontSize: "13px",
                      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                      fontWeight: "400",
                      lineHeight: "1",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Conteúdo pré requisito:
                  </span>
                </Grid>
                <Grid item>
                  {/* <SearchableSelect
                  placeholder={'Selecione o Conteúdo pré requisito'}
                  searchApiUrl={'/content/select-contents'}
                  actionOnSelectedOption={(value) => {
                    this.predecessorContentChosen(value);
                  }}
                  // onChange={this.onChangeUpdateSubjects}
                  defaultValue={this.object.defaultPredecessorContentValue}
                  // options={this.object.subjectOptions}
                ></SearchableSelect> */}
                  {/* aqui error homolog ticket  */}
                  <Select
                    placeholder={"Selecione o Conteúdo pré requisito"}
                    value={this.object.defaultContentValues}
                    onChange={this.onChangeUpdateContents}
                    options={this.object.contentOptions}
                    isSearchable 
                    isClearable
                  ></Select>
                  

                {/* <Select
                  placeholder={"Selecione os Papéis."}
                  options={this.object.roleOptions}
                  value={this.object.defaultRoleValues}
                  onChange={this.onChangeUpdateRoles}
                  isMulti
                ></Select> */}
                </Grid>
              </Grid>
            ) : null}
            <Grid xs={0.5} item></Grid>
          </Grid>
          <Grid container direction="row" xs={12}>
            {/* Clients that it has coins or points and are campaigns
              localStorage.getItem("ID_APP") != 3 &&
              localStorage.getItem("ID_APP") != 4 &&
              localStorage.getItem("ID_APP") != 14 &&
              localStorage.getItem("ID_APP") != 26 &&
              localStorage.getItem("ID_APP") != 27 &&
              localStorage.getItem("ID_APP") != 32 &&
              localStorage.getItem("ID_APP") != 33 &&
              localStorage.getItem("ID_APP") != 34 &&
              this.object.is_campaign ? (
                <Grid item xs={2}>
                  <TextField
                    label={
                      localStorage.getItem("ID_APP") != 3 ? "Moedas" : "Pontos"
                    }
                    value={
                      this.object.content_points &&
                      this.object.content_points >= 0 &&
                      !this.object.is_marketing
                        ? this.object.content_points
                        : ""
                    }
                    disabled={this.object.is_marketing}
                    style={{ width: "100%" }}
                    style={{ marginTop: "10px" }}
                    onChange={this.onChangeUpdate("content_points", false)}
                    onBlur={this.onChangeUpdate("content_points", true)}
                    type={"number"}
                  />
                </Grid>
              ) : null
              */}
            {
              /* Clients that only has coins */
              localStorage.getItem("ID_APP") == 24 ||
              localStorage.getItem("ID_APP") == 31 ? (
                <Grid item xs={2}>
                  <TextField
                    label={"Moedas"}
                    value={
                      this.object.content_points &&
                      this.object.content_points >= 0 &&
                      !this.object.is_marketing
                        ? this.object.content_points
                        : ""
                    }
                    disabled={this.object.is_marketing}
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={this.onChangeUpdate("content_points", false)}
                    onBlur={this.onChangeUpdate("content_points", true)}
                    type={"number"}
                  />
                </Grid>
              ) : null
            }
            {
              /* Clients that only has coupons*/
              localStorage.getItem("ID_APP") == 4 ? (
                <Grid item xs={2}>
                  <TextField
                    label="Cupons"
                    value={
                      this.object.normal_tickets && !this.object.is_marketing
                        ? this.object.normal_tickets
                        : 0
                    }
                    disabled={this.object.is_marketing}
                    style={{ width: "100%", marginTop: "10px" }}
                    onChange={this.onChangeUpdate("normal_tickets", false)}
                    onBlur={this.onChangeUpdate("normal_tickets", true)}
                  />
                </Grid>
              ) : null
            }

            {
              /* Clients that it has medals */
              localStorage.getItem("ID_APP") != 26 &&
              localStorage.getItem("ID_APP") != 27 &&
              localStorage.getItem("ID_APP") != 31 &&
              localStorage.getItem("ID_APP") != 32 &&
              localStorage.getItem("ID_APP") != 33 &&
              localStorage.getItem("ID_APP") != 34 &&
              !this.object.is_campaign ? (
                <Grid item xs={2} style={{ marginTop: "25px" }}>
                  <Checkbox
                    onChange={this.onChangeUpdate("have_medal", true)}
                    checked={this.object.have_medal}
                  />
                  {"Tem medalhas "}
                </Grid>
              ) : null
            }
            {/* //DANIAQUI */}
            {!this.object.is_campaign ? (
              <Grid item xs={2}>
                <TextField
                  label="Ordem Conteúdo"
                  value={this.object.order_content}
                  onChange={this.onChangeUpdate("order_content", false)}
                  style={{ width: "100%", marginTop: "10px" }}
                />
              </Grid>
            ) : null}
            {this.object.content_type &&
            //  !== 6
            localStorage.getItem("ID_APP") !== "5" ? (
              <Grid style={{ marginTop: "25px" }} item xs={6} spacing={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        letterSpacing: "0.00938em",
                        float: "left",
                        color: "rgba(0, 0, 0, 0.54)",
                        paddingBottom: "0px",
                      }}
                    >
                      {content_type === "form" || content_type === "quiz"
                        ? "Responder somente uma vez?"
                        : "Some quando Completado:"}
                    </span>
                  </Grid>
                  <Grid xs={2} item>
                    <Grid style={{ float: "left" }}>
                      <Checkbox
                        onChange={this.onChangeUpdate(
                          "is_removed_when_completed",
                          true
                        )}
                        checked={this.object.is_removed_when_completed === 1}
                      />{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {localStorage.getItem("ID_APP") === "3" && (
              <Grid item xs={3}>
                <Grid container style={{ width: "100%", marginTop: "10px" }}>
                  <Grid item xs={2}>
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        letterSpacing: "0.00938em",
                        float: "left",
                        color: "rgba(0, 0, 0, 0.54)",
                        // color: 'red',
                        paddingBottom: "0px",
                      }}
                    >
                      P/inativos:
                    </span>
                  </Grid>
                  <Grid xs={6} item>
                    <Grid style={{ float: "left", marginTop: "20px" }}>
                      <Checkbox
                        onChange={this.onChangeUpdate("starting_content", true)}
                        checked={this.object.starting_content === 1}
                      />
                      P/ inativos
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid xs={12} container spacing={2}>
            <Grid xs={12} container direction="row" spacing={1}>
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: "18px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: "0.00938em",
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingBottom: "15px",
                    paddingTop: "40px",
                  }}
                >
                  Conteúdo:
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {/* //daniremove <Grid xs={6} item>
              <Grid container direction={"column"}>
                <Grid item xs={12}>
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      lineHeight: 1,
                      letterSpacing: "0.00938em",
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      paddingBottom: "10px",
                    }}
                  >
                    Imagem de Capa (1700px X 864px)
                    {this.object.training &&
                    this.object.training.training_cover_image ? (
                      <a href="#" onClick={this.deleteCoverImage}>
                        {" "}
                        Remover Imagem
                      </a>
                    ) : null}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  {this.object.training &&
                  this.object.training.training_cover_image ? (
                    <img
                      style={{
                        width: "30vw",
                        height: "15vw",
                        objectFit: "cover",
                      }}
                      src={
                        this.object.training
                          ? this.object.training.training_cover_image
                          : null
                      }
                      id={"image-modal"}
                    />
                  ) : (
                    <div id="picker">
                      <CustomDropzone
                        onDropAction={this.onDropActionCoverImage}
                        completed={props.completed.completedCoverImage}
                        inactiveLabel={
                          "Arraste a imagem (.jpeg, .png, .gif) de capa para cá"
                        }
                        activeLabel={"Solte a imagem de capa"}
                      />
                    </div>
                  )}
                </Grid>
              </Grid> 
             </Grid> */}
            {/*<Grid xs={6}>
                            <Grid xs={12} item>
                                <span
                                    style={{
                                        fontSize: '12px',
                                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                        fontWeight: 400,
                                        lineHeight: 1,
                                        letterSpacing: '0.00938em',
                                        float: 'left',
                                        color: 'rgba(0, 0, 0, 0.54)',
                                        paddingBottom: '10px',
                                        paddingLeft: '20px',
                                    }}
                                >
                                    Descrição -{' '}
                                    {this.object.training &&
                                        this.object.training.training_description
                                        ? this.object.training.training_description.length
                                        : 0}
                                    /5000
                                </span>
                            </Grid>
                            <TextareaAutosize
                                aria-label={'Descrição'}
                                placeholder={'Descrição(Máximo de 5000 caracteres)'}
                                maxLength={5000}
                                rows={4}
                                value={
                                    this.object.training &&
                                        this.object.training.training_description
                                        ? this.object.training.training_description
                                        : ''
                                }
                                onChange={this.onChangeUpdate(
                                    'training.training_description',
                                    false,
                                )}
                                onBlur={this.onChangeUpdate(
                                    'training.training_description',
                                    true,
                                )}
                                style={{
                                    width: '90%',
                                    height: '70%',
                                    padding: '10px 10px',
                                }}
                            />
                        </Grid>*/}
          </Grid>

          <Grid xs={12}>
            <div style={{ height: "40px" }}></div>
          </Grid>
          <Grid container direction="row" spacing={2}>
            {this.object ? this.showTrailContent() : null}
          </Grid>
          <Grid xs={12} item>
            <SaveButton
              style={{ float: "right" }}
              onClickSave={this.onClickPublishContent}
              ref={(ref) => {
                this.saveButton = ref;
              }}
              text={"Publicar"}
            />
            <SaveButton
              style={{ float: "right" }}
              onClickSave={this.onClickSaveContent}
              ref={(ref) => {
                this.saveButton = ref;
              }}
              text={"Salvar Rascunho"}
            />
            <DeleteButton
              style={{ float: "right", background: "#CC0B0B" }}
              onClickDelete={this.onClickDeleteContent}
            />
            <ArchiveButton
              style={{ float: "right" }}
              onClickArchive={this.onClickArchiveContent}
              is_archived={this.object.is_archived}
              ref={(ref) => {
                this.archiveButton = ref;
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
