import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
// DB Models
import Role from '../../models/Role';
import User from '../../models/User';
import Brand from '../../models/Brand';
import Selling_Region from '../../models/Selling_Region';
import RoleCard from '../components/cards/RoleCard';
import RoleDescription from '../components/description_cards/RoleDescription';
import Product from '../../models/Product';
import { toast } from 'react-toastify';
import Spinner from '../components/buttons/Spinner';

class AdminRoleScreen extends Component {
  editionTimeout = null;

  state = {
    roles: [],
    relations: [],
    loading: true,
  };

  // TEXTS
  searchText_placeholder = 'Nome do Papel';
  addButton_placeholder = 'Papel';

  // FUNCTIONS
  // Load all data from DB
  componentDidMount() {
    Role.query().then((roles) => {this.setState({ roles: roles });})
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
    this.reloadRoles();
  }

  refreshRoles = async (parameters) => {
    this.reloadRoles();
  };

  reloadRoles = async (parameters) => {
    try {
      const [roles] = await Promise.all([Role.query()]);
      this.setState({ roles: roles });
      this.setState({ loading: false });
    } catch (err) {
      console.warn(err);
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
  };

  addRole = async () => {
    let newRole = new Role();
    let roleObj = {};
    try {
      roleObj = await newRole.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let roleList = this.state.roles;
    roleList.push(roleObj);
    this.setState({ roles: roleList });
    return roleObj;
  };

  updateRole = (roleObj, field, value, force) => {
    let that = this;
    let roleList = this.state.roles;
    let position = roleList.findIndex((obj) => {
      return obj.id_role === roleObj.id_role;
    });
    let roleToUpdate = roleList.find((obj) => {
      return obj.id_role === roleObj.id_role;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          roleToUpdate[prop] = value[prop];
        }
      }
    } else {
      roleToUpdate[field] = value;
    }

    // Timeout: Wait 500 milliseconds before sending API POST call to Server
    if (this.editionTimeout) clearTimeout(this.editionTimeout);
    this.setState({ roles: roleList });
    return roleList[position];
  };

  deleteRole = (roleObj) => {
    let roleList = this.state.roles;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < roleList.length; i++) {
      if (roleObj.id_role === roleList[i].id_role) {
        roleList[i].remove();
        roleList.splice(i, 1);
        break;
      }
    }

    this.setState({ roles: roleList });
  };

  saveRole = async (roleObj) => {
    let roleList = this.state.roles;
    let position = roleList.findIndex((obj) => {
      return obj.id_role === roleObj.id_role;
    });
    let roleToUpdate = roleList.find((obj) => {
      return obj.id_role === roleObj.id_role;
    });

    if (roleToUpdate != null) {
      let roleObj = roleToUpdate;

      try {
        roleObj = await roleToUpdate.save();
        // Role upload to server worked
        roleList[position] = roleObj;
        this.setState({ roles: roleList });
        toast.success('Papel salvo com sucesso!');
      } catch (err) {
       toast.error('Erro ao salvar papel!');
        console.error(err);
      }
    }
  };

  render() {
    return (
      <> 
      {this.state.roles.length===0 
      &&this.state.loading
      ? <Spinner
       margin='13px' 
      texto={'carregando...'}
      border={'#9BB3D4'}
      top={'#1D2D44'}/>
      :
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.roles}
        objectCard={RoleCard}
        objectDescription={RoleDescription}
        addObject={this.addRole}
        saveObject={this.saveRole}
        updateObject={this.updateRole}
        deleteObject={this.deleteRole}
        refreshObjects={this.refreshRoles}
        relations={this.state.relations}
        objectIdName={'id_role'}
        objectModel={Role}
      ></GenericAdminScreen>
  }
  </>
    );
  }
}

export default AdminRoleScreen;
