import { Grid, Paper } from "@material-ui/core";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faStore
} from "@fortawesome/free-solid-svg-icons";

export default function StoreCard(props) {

    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;
    //FUNCTIONS
    this.updateStore = props.updateObject;
    this.deleteStore = props.deleteObject;
    this.callDescription = props.callDescription;

    this.onClickCallDescription = () => {
        this.callDescription(this.object);
    };

    return (
        <Paper style={{ 'height': '100px', 'backgroundColor': props.chosenId === this.object.id_store ? '#4498A7' : '#f2f2f2' }}>
            <Grid container onClick={this.onClickCallDescription} style={{ height: '100px', width: '100%' }}>
                <Grid xs={3}>
                    <div style={{ display: "flex", flex: 1, justifyContent: "center", alignContent: "center", marginTop: "10px" }}>
                        {this.object.store_image_file ? (
                            <img
                                style={{ width: 80, height: 80, borderRadius: 80 / 2 }}
                                src={this.object.store_image_file ? this.object.store_image_file : ""} />)
                            : (
                                /*<div
                                    style={{ width: 80, height: 80, borderRadius: 80 / 2, backgroundColor: '#b0b0b0' }}>
                                </div>*/
                                null
                            )}

                    </div>
                </Grid>
                <Grid xs={8}>
                    <tbody>
                        <tr>
                            <Grid xs={12} style={{ float: 'left', marginTop: '20px' }}>
                                <span style={{
                                    'width': '100%', float: 'left', padding: '0', fontSize: '18px',
                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                                    letterSpacing: '0.00938em'
                                }}>{this.object.store_name ? this.object.store_name : ''}</span>
                            </Grid>
                        </tr>
                        <tr>
                            <Grid xs={12} style={{ float: 'left' }}>
                                <span style={{
                                    'width': '100%', float: 'left', padding: '0', fontSize: '18px',
                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                                    letterSpacing: '0.00938em'
                                }}>{this.object.store_ein_code ? this.object.store_ein_code : ''}</span>
                            </Grid>
                        </tr>
                    </tbody>
                </Grid>
            </Grid>
        </Paper>
    );
}