import React, { Component } from 'react';
import logo from '../../../logo_insidethebox.png';
import { Button, Grid } from '@material-ui/core';
import WhiteTextField from '../text_fields/WhiteTextField.js';
import 'react-phone-number-input/style.css';
import AuthManager from '../../../lib/AuthManager.js';
import Utils from '../../../lib/utils';
import { toast } from 'react-toastify';
import Spinner from '../buttons/Spinner';
class LoginForm extends Component {
  state = {
    askCode: false,
    phone_number: '',
    phone_code: '',
    width: 0,
    height: 0,
    loading: false,
    loading2: false,
  };

  constructor(props) {
    super(props);
    this.onLogin = props.onLogin;
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
  };

  signInWithPhoneNumber = () => {
    this.setState({ loading:true });
    AuthManager.signInWithPhoneNumber(
      Utils.correctPhoneNumber(this.state.phone_number),
      'sign-in-button',
      (err) => {
        if (err) {
          toast.error('Error sending phone number!');
        } else {
          this.setState({ askCode: true });
        }
        this.setState({ loading:false });
      },
    )
  };

  sendPhoneCode = () => {

    this.setState({ loading2:true });
    AuthManager.sendPhoneCode(this.state.phone_code, (err) => {
      if (err) {
        toast.error('Código errado!');
      } else {
        this.onLogin();
      }
      this.setState({ loading2:false });
    });
  };

  _handleTextFieldChange = function (field) {
    let that = this;
    return function (e) {
      let newState = {};
      newState[field] = e.target.value;
      that.setState(newState);
    };
  };

  updateWindowDimensions = function () {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    return (
    
      <Grid
        style={{
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
        }}
      >
        <Grid style={{ flex: 1 }} />
        <Grid
          style={{
            backgroundColor: '#191919',
            padding: '50px',
            width: this.state.width < 600 ? this.state.width - 100 : '50%',
            height: this.state.width < 600 ? '100%' : null,
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            flexDirection: 'column',
          }}
        >
          <Grid style={{ height: this.state.width < 600 ? '15%' : null }} />
          {/*Logo*/}
          <Grid item style={{ marginBottom: '50px' }}>
            <img src={logo} alt='logo' height={80} />
          </Grid>
          <Grid item>
            <WhiteTextField
              style={{ width: '100%' }}
              label='Insira DDD+número'
              value={this.state.phone_number}
              onChange={this._handleTextFieldChange('phone_number')}
              disabled={this.state.askCode}
            />
          </Grid>
          {this.state.loading ===true &&  <Spinner/> }
          {this.state.askCode ? null : (
            <Grid item xs={12}>
              <Button
              style={{ width: '100%', marginTop: '30px' }}
              variant='contained'
              color='primary'
              id='sign-in-button'
              onClick={this.signInWithPhoneNumber}
              >
                {this.state.loading ===false && 'Entrar' }  
              </Button>
              
            </Grid>
          )}
          {this.state.askCode ? (
            <Grid item xs={12}>
              <WhiteTextField
                label='Código'
                value={this.state.phone_code}
                onChange={this._handleTextFieldChange('phone_code')}
                style={{ width: '100%' }}
                />
            </Grid>
          ) : null}
          
          {this.state.loading2 ===true &&  <Spinner/> }
          {this.state.askCode ? (
            <Grid item xs={12}>
              <Button
              style={{ width: '100%', marginTop: '30px' }}
              variant='contained'
              color='primary'
              onClick={this.sendPhoneCode}
              >
                 {this.state.loading2 ===false && 'Confirmar Código' }  
               
              </Button>
            </Grid>
          ) : null}
         
        </Grid>
        <Grid style={{ flex: 1 }} />
      </Grid>
    );
  }
}

export default LoginForm;
