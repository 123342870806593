import GenericModel from "./generic_model/GenericModel";

const API = "/ticket_departments";

class TicketDepartment extends GenericModel {
  API = API;
  static API = API;

  static getDepartmentSelect = async function () {
    //voltar aqui 22

    let authHeader = null;
    try {
      let that = this;
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(this.API + "/select", {
        headers: authHeader,
      });
      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

      let ans = {};

      function mountObjArray(elementsArray) {
        let result = [];

        for (let i = 0; i < elementsArray.length; i++) {
          result.push(new GenericModel(elementsArray[i], that.API));
        }

        return result;
      }

      if (body && body.elements) {
        for (let prop in body) {
          if (body.hasOwnProperty(prop) && prop !== "elements") {
            ans[prop] = body[prop];
          }
        }
        ans.elements = mountObjArray(body.elements);
      } else if (body instanceof Array) {
        ans = mountObjArray(body);
      } else {
        ans = new GenericModel(body, that.API);
      }

      return ans;
    } catch (err) {
      console.log(err);
      throw Error(err);
    }
  };

  //aqui

  //voltar aqui 22  postAllDepartment
  static getAllDepartment = async function () {
    let authHeader = null;
    try {
      let that = this;
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(this.API + "/", {
        headers: authHeader,
      });
      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

      let ans = {};

      function mountObjArray(elementsArray) {
        let result = [];

        for (let i = 0; i < elementsArray.length; i++) {
          result.push(new GenericModel(elementsArray[i], that.API));
        }

        return result;
      }

      if (body && body.elements) {
        for (let prop in body) {
          if (body.hasOwnProperty(prop) && prop !== "elements") {
            ans[prop] = body[prop];
          }
        }
        ans.elements = mountObjArray(body.elements);
      } else if (body instanceof Array) {
        ans = mountObjArray(body);
      } else {
        ans = new GenericModel(body, that.API);
      }

      return ans;
    } catch (err) {
      console.log(err);
      throw Error(err);
    }
  };

  // static postAllDepartment = async function (departments) {
  //   console.log(departments, "all departaments");
  //   // const isso =  departments.map(item=>{
  //   //   return Object.keys(item).reduce((acc,key)=>{
  //   //     return item[key]
  //   //   },{})

  //   let data = departments[0];

  //   let data2 =
  //     (JSON.stringify({ department: departments[0] }),
  //     " JSON.stringify(departments) all departaments");
  //   let authHeader = null;
  //   try {
  //     authHeader = await GenericModel.insertAuthHeader();
  //     let response = await fetch(this.API + "/", {
  //       method: "POST",
  //       headers: authHeader,
  //       body: JSON.stringify({
  //         wins: 1,
  //       }),
  //     });
  //     //   let responsa = await fetch(this.API + "/save-forms-image-file", {
  //     //     method: "POST",
  //     //     headers: headerObj,
  //     //     body: JSON.stringify(formChoicesObj),
  //     // });
  //     return response;
  //   } catch (err) {
  //     console.log(err);
  //     throw Error(err);
  //   }

  //   //aqui
  // };

  static postAllDepartment = async (departments) => {
    // console.log(departments)
    const authHeader = await GenericModel.insertAuthHeader({
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    try {
      let response = await fetch(this.API + "/", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify({
          departments,
        }),
      });
      if (response.status >= 400) {
        throw Error(response.statusText);
      }
      response.success = true;
      return response;
    } catch (err) {
      console.warn(err);
      return { success: false };
    }
  };
}
export default TicketDepartment;
