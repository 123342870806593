import DateFnsUtils from '@date-io/date-fns';
import {
  FormControlLabel, Grid, Radio,
  RadioGroup, TextField
} from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTimePicker } from '@material-ui/pickers/';
import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Message from '../../../models/Message';
import SearchableSelect from '../../components/selectbox/SearchableSelect.js';
import ArchiveButton from '../buttons/ArchiveButton';
import DeleteButton from '../buttons/DeleteButton'; 
import SaveButton from '../buttons/SaveButton';
import { CustomDropzone } from '../CustomDropzone';

export default function MessageDescription(props) {
  this.editionTimeout = null;

  // Functions
  this.updateMessage = props.updateObject;
  this.deleteMessage = props.deleteObject;
  this.archiveMessage = props.archiveObject;
  this.sendMessage = props.sendMessage;
  this.saveMessage = props.saveObject;
  this.nullDescription = props.nullDescription;

  // Object to Render
  this.object = props.object;
  this.roles = props.relations[0];
  this.target_users = this.object.target_user;
  this.is_individual = this.object.users_count === 1;
  this.users = [];

  let updateDescriptionState = props.updateDescriptionState
    ? props.updateDescriptionState
    : () => {};

  this.object.roleOptions = this.roles.map((role) => {
    let roleOption = {
      value: role.id_role,
      label: role.role_name,
    };
    return roleOption;
  });

  if (this.object.content?.roles) {
    if (this.object.content.roles !== undefined) {
      this.object.defaultRoleValues = this.object.content.roles.map((role) => {
        return {
          value: role.id_role ? role.id_role : null,
          label: role.role_name ? role.role_name : null,
        };
      });
    } else {
      this.object.defaultRoleValues = [];
    }
  }

  this.onChangeUpdate = (field, force,dataLimit = null ) => (event) => {
    let value = event && event.target ? event.target.value : null;
   
      if(field === "link" && value &&value.length >10){
        let url = value
        
          if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url;
            value = url ;
            }
      }

     if(
      dataLimit 
    ){
      const verify =this.object[field] ? this.object[field] : null;
      // limit the value/message_title to dataLimit before update is the limit in db
      if(value.length > dataLimit){
      value=  value.substring(0 ,dataLimit)
      }
      if(( verify===null || verify.length < dataLimit) || ( value.length < verify.length ))
        {    
        this.updateMessage(this.object, field, value, force);
        }

    }
else{
  this.updateMessage(this.object, field, value, force);
}
}

  this.onClicksaveMessage = () => {
    this.saveMessage(this.object);
  };

  this.onClickSendMessage = () => {
    this.sendMessage(this.object);
  };

  this.onClickdeleteMessage = () => {
    if (window.confirm('Deseja mesmo deletar esse item?')) {
      this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the user
      this.deleteMessage(this.object);
    }
  };

  this.onClickArchiveContent = () => {
    this.onChangeUpdate('is_archived')({
      target: {
        value: this.object.is_archived ? 0 : 1,
      },
    });
    this.archiveMessage(this.object);
  };

  this.onChangeUpdateRoles = (value) => {
    let roles;
    if (value != null) {
      //null cant use map function
      roles = value.map((obj) => {
        return {
          //revert the label to put the id_role and role_name in the Content Object
          id_role: obj.value,
          role_name: obj.label,
        };
      });
    } else {
      roles = [];
    }
    this.updateMessage(this.object, 'content.roles', roles, true);
  };

  this.onDropActionFile = async (file) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedFile: completed,
      };
      props.progressFunction(completedState);
    };
    let fileType = file.type;
    let messageFileType = fileType.split("/")[0];
    if (messageFileType === "application") {
      messageFileType = fileType.split("/")[1];
    }    


    if (messageFileType === "image") {
      //alert("Arquivo de imagem!");
      try {
        let imageUrl = null;
        if (this.object) {
          imageUrl = await Message.sendImageFile(file, null, progressFunction);
          this.updateMessage(this.object, "message_file", imageUrl.url, true);
          this.updateMessage(this.object, "message_file_type", 1, true);
        }
        // Reset completed variable (progress bar)
        progressFunction(0);
      } catch (err) {
        console.error(err);
      }
    } 
    else if (messageFileType === 'video') {
      //alert("Arquivo de vídeo!");
      try {
        let imageUrl = null;
        if (this.object) {
          imageUrl = await Message.sendVideo(file, null, progressFunction);
          this.updateMessage(this.object, 'message_file', imageUrl.url, true);
          this.updateMessage(this.object, 'message_file_type', 2, true);
        }
        // Reset completed variable (progress bar)
        progressFunction(0);
      } catch (err) {
        console.error(err);
      }
    } 
    else if (messageFileType === "pdf") {
      // alert("Arquivo de pdf!");
      try {
        let imageUrl = null;
        if (this.object) {
          imageUrl = await Message.sendImageFile(file, null, progressFunction);
          this.updateMessage(this.object, "message_file", imageUrl.url, true);
          this.updateMessage(this.object, "message_file_type", 3, true);
        }
        // Reset completed variable (progress bar)
        progressFunction(0);
      } catch (err) {
        console.error(err);
      }
    } else {
      toast.error("Arquivo não suportado!");
    }




    // if (messageFileType === 'image') {
    //   //alert("Arquivo de imagem!");
    //   try {
    //     let imageUrl = null;
    //     if (this.object) {
    //       imageUrl = await Message.sendImageFile(file, null, progressFunction);
    //       this.updateMessage(this.object, 'message_file', imageUrl.url, true);
    //       this.updateMessage(this.object, 'message_file_type', 1, true);
    //     }
    //     // Reset completed variable (progress bar)
    //     progressFunction(0);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // } 
    // else if (messageFileType === 'video') {
    //   //alert("Arquivo de vídeo!");
    //   try {
    //     let imageUrl = null;
    //     if (this.object) {
    //       imageUrl = await Message.sendVideo(file, null, progressFunction);
    //       this.updateMessage(this.object, 'message_file', imageUrl.url, true);
    //       this.updateMessage(this.object, 'message_file_type', 2, true);
    //     }
    //     // Reset completed variable (progress bar)
    //     progressFunction(0);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // }
    // else {
    //   toast.error('Arquivo não suportado!');
    // }
  };

  this.deleteFile = () => {
    if (this.object && this.object.message_file) {
      return (
        this.updateMessage(this.object, 'message_file', null, true),
        this.updateMessage(this.object, 'message_file_type', null, true)
      );
    }
  };

  this.selectHandler = async (user) => {
    let label = user.user_name + '-' + user.user_phone;
    let value = user;
    let message = this.object ? this.object : {};
    if (message.user) {
      if (message.user.length > 0) {
        message.user[0].label = label;
        message.user[0].value = user;
      } else {
        message.user.push({
          label: label,
          value: user,
        });
      }
    } else {
      message.user.label = label;
      message.user.value = user;
    }
    updateDescriptionState({ user: message });
  };

  this.radioGroupHandler = async (event) => {
    let value = event.target.value;
    let message = this.object ? this.object : {};
    message.target_user = value;
    updateDescriptionState({ target_user: message });
    let users;
    try {
      switch (value) {
        case 'all':
          this.is_individual = false;
          this.target_users = value;
          this.is_roles = false;
          break;
        case 'individual':
          this.is_individual = true;
          this.target_users = value;
          this.is_roles = false;
        /*if (this.object.user.value) {
                    this.selectHandler(this.object.user.value);
                }*/
        case 'roles':
          this.is_individual = false;
          this.target_users = value;
          this.is_roles = true;
          break;
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          alignItems: 'center',
          padding: '30px 20px 10px 20px',
        }}
      >
      <Grid container direction='column' spacing={2}>
          <Grid xs={12} container direction='row' spacing={1}>
            <Grid xs={6} item>
            
            <TextField
              label= {this.object.message_title && this.object.message_title.length ? `Titulo ${this.object.message_title.length +'/50'} `: ' Titulo 0/50' } 
              value={this.object.message_title  ? this.object.message_title : ''}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('message_title', true ,50)}
              onBlur={this.onChangeUpdate('message_title', true)}
            />
            
          </Grid>
            <Grid xs={2} item></Grid>
            <Grid xs={1} item>
              <TextField label='ID' value={this.object.id_message} disabled />
            </Grid>
            <Grid xs={3} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label='Data de criação'
                  value={this.object.created_date}
                  style={{ width: '100%' }}
                  format='dd/MM/yyyy HH:mm'
                  disabled
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid xs={2} item></Grid>
          

          <Grid container spacing={3} style={{ marginTop: 10 }}>
            {
              <Grid xs={6} item>
                <Grid container direction={'column'}>
                  <Grid item xs={12}>
                    <span
                      style={{
                        fontSize: '12px',
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        letterSpacing: '0.00938em',
                        float: 'left',
                        color: 'rgba(0, 0, 0, 0.54)',
                        paddingBottom: '10px',
                      }}
                    >
                      Anexo(Arquivos de imagem ou vídeo com tamanho de até 1GB)
                      {this.object && this.object.message_file ? (
                        <a href='#' onClick={this.deleteFile}>
                          {' '}
                          Remover Anexo
                        </a>
                      ) : null}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    {this.object &&
                    this.object.message_file &&
                    this.object.message_file_type === 1 ? (
                      <img
                        style={{
                          width: '30vw',
                          height: '15vw',
                          objectFit: 'cover',
                        }}
                        src={
                          this.object.message_file
                            ? this.object.message_file
                            : null
                        }
                        id={'image-modal'}
                      />
                    ) : this.object &&
                      this.object.message_file &&
                      this.object.message_file_type === 2 ? (
                      <video
                        style={{ maxHeight: '100%', maxWidth: '100%' }}
                        controls
                      >
                        <source
                          src={
                            this.object && this.object.message_file
                              ? this.object.message_file
                              : null
                          }
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) :    
                    this.object.message_file &&
                      this.object.message_file_type === 3 ? (
                      <a
                        target="_blank"
                        href={
                          this.object.message_file
                            ? this.object.message_file
                            : null
                        }
                        rel="noreferrer"
                      >
                        Clique aqui para acessar o pdf.
                      </a>
                    ) : (
                      <div id="picker">
                        <CustomDropzone
                          onDropAction={this.onDropActionFile}
                          maxFiles={1}
                          multiple={false}
                          completed={props.completed.completedFile}
                          inactiveLabel={
                            "Arraste o PDF , video ou a imagem (.jpeg, .png) para cá"
                          }
                          activeLabel={"Solte o material de treinamento"}
                        />
                      </div>
                    

                    // (
                    //   <div id='picker'>
                    //     <CustomDropzone
                    //       onDropAction={this.onDropActionFile}
                    //       completed={props.completed.completedFile}
                    //       inactiveLabel={'Arraste o arquivo para cá'}
                    //       activeLabel={'Solte o arquivo'}
                    //     />
                    //   </div>
                    ) }
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid xs={6}>
              <Grid xs={12} item>
                <span
                  style={{
                    fontSize: '12px',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: '0.00938em',
                    float: 'left',
                    color: 'rgba(0, 0, 0, 0.54)',
                    paddingBottom: '10px',
                    paddingLeft: '20px',
                  }}
                >
                  Mensagem -{' '}
                  {this.object && this.object.message_content
                    ? this.object.message_content.length
                    : 0}
                  /1000
                </span>
              </Grid>
              <TextareaAutosize
              disabled={!this.object.is_news && !this.object.is_cupon && (this.object.is_published || this.object.is_archived === 1)}
                aria-label={'Mensagem'}
                placeholder={'Mensagem(1000 caracteres)'}
                maxLength={1000}
                rows={4}
                value={
                  this.object && this.object.message_content
                    ? this.object.message_content
                    : ''
                }
                onChange={this.onChangeUpdate('message_content', true , 1000)}
                onBlur={this.onChangeUpdate('message_content', true)}
                style={{
                  width: '90%',
                  height: '70%',
                  padding: '10px 10px',
                }}
              />
            </Grid>
            {/* inicio */}
            <Grid xs={12} container direction='row' spacing={1}>
            <Grid xs={6} item>
            
            <TextField
              label= {this.object.link && this.object.link.length ? `Compartilhar link externo ${this.object.link.length +'/300'} `: ' Compartilhar link externo 0/300' } 
              value={this.object.link  ? this.object.link : ''}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('link', true ,300 )}
              onBlur={this.onChangeUpdate('link', true)}
            />
            
            
          </Grid>
          
            <Grid xs={6} item>
            {
                this.object.link && this.object.link.length &&  
                <>
               <span
               style={{
                fontSize: '12px',
                 fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                 fontWeight: 400,
                 lineHeight: 1,
                 letterSpacing: '0.00938em',
                 float: 'left',
                 color: 'rgba(0, 0, 0, 0.54)',
                 paddingTop: this.object && this.object.message_file_type === 3 ? '30px' :'0px',
                 width: '100%',
                //  paddingLeft: '20px',
                }}
                >
                Teste o Link de compartilhamento 
                </span>
              {
                this.object.link && this.object.link.length &&
                <a
                target="_blank"
                href={
                  this.object.link
                  ? this.object.link
                  : null
                }
                rel="noreferrer"
                >
                Clique aqui para acessar o link.
                </a>
                
              }
            
              </>}
            {/* <TextField
              label= {this.object.link_name && this.object.link_name.length ? `Nome do link ${this.object.link_name.length +'/50'} `: ' Nome do link 0/50' } 
              value={this.object.link_name  ? this.object.link_name : ''}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('link_name', true , 50)}
              onBlur={this.onChangeUpdate('link_name', true)}
            /> */}
            
            
          </Grid>
          </Grid>
            {/* fim */}
          </Grid>
          <Grid xs={13} container direction={'column'}>
            <RadioGroup
              row
              style={{ paddingLeft: 9, paddingTop: 20 }}
              onChange={this.radioGroupHandler}
              value={this.target_users}
            >
              <Grid xs={2} item>
                <FormControlLabel
                  control={<Radio color={'primary'} />}
                  label={'Enviar Para Todos'}
                  value={'all'}
                  disabled={!this.object.is_news && !this.object.is_cupon && (this.object.is_published || this.object.is_archived === 1)}
                />
              </Grid>
              <Grid xs={4} item>
                <FormControlLabel
                  control={<Radio color={'primary'} />}
                  label={'Enviar para um usuário específico'}
                  value={'individual'}
                  disabled={!this.object.is_news && !this.object.is_cupon && (this.object.is_published || this.object.is_archived === 1)}
                />
                <SearchableSelect
                  label='Texto da Notificação'
                  style={{ width: '80%' }}
                  placeholder={'Selecione o Usuário'}
                  searchApiUrl={'/user/select_full_user'}
                  actionOnSelectedOption={this.selectHandler}
                  value={{
                    label: this.object.user.label,
                    value: this.object.user.value,
                  }}
                  defaultValue={this.object.user}
                  disabled={!this.object.is_news && !this.object.is_cupon && (this.target_users != 'individual' || this.object.is_published || this.object.is_archived === 1)}
                ></SearchableSelect>
              </Grid>

              <Grid xs={5} style={{ marginLeft: 10 }} item>
                <FormControlLabel
                  control={<Radio color={'primary'} />}
                  label={'Enviar para Usuários de papéis específicos'}
                  value={'roles'}
                  disabled={!this.object.is_news && !this.object.is_cupon && (this.object.is_published || this.object.is_archived === 1)}
                />
                <Select
                  placeholder={'Selecione os Papéis'}
                  options={this.object.roleOptions}
                  value={this.object.defaultRoleValues}
                  onChange={this.onChangeUpdateRoles}
                  isDisabled={!this.object.is_news && !this.object.is_cupon && (this.target_users != 'roles' || this.object.is_published || this.object.is_archived === 1)}
                  isMulti
                ></Select>
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid xs={12} style={{ marginTop: '20px' }}>
            <SaveButton
              style={{ float: 'right' }}
              onClickSave={this.onClickSendMessage}
              ref={(ref) => {
                this.saveButton = ref;
              }}
              text={'Enviar'}
              disabled={!this.object.is_news && !this.object.is_cupon && (this.object.is_published || this.object.is_archived === 1)}
            />
            <SaveButton
              style={{ float: 'right' }}
              onClickSave={this.onClicksaveMessage}
              ref={(ref) => {
                this.saveButton = ref;
              }}
              text={'Salvar Rascunho'}
              disabled={!this.object.is_news && !this.object.is_cupon && (this.object.is_published || this.object.is_archived === 1)}
            />
            <ArchiveButton
              style={{ float: 'right' }}
              onClickArchive={this.onClickArchiveContent}
              is_archived={this.object.is_archived}
              ref={(ref) => {
                this.archiveButton = ref;
              }}
            />
            <DeleteButton
              style={{ float: 'right', background: '#CC0B0B' }}
              onClickDelete={this.onClickdeleteMessage}
            />
          </Grid>
        </Grid>
    </div>
  );
}
