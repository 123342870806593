// const ContentFormAdminScreen = () => {
//   return (
//     <div>
//       <p>aqyu</p>
//     </div>
//   )
// }

// export default ContentFormAdminScreen

// include styles for modal
import DateFnsUtils from "@date-io/date-fns";
import { faCheckSquare, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Checkbox,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddIcon from "@material-ui/icons/Add";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers/";
import React, { useEffect, useRef, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import PerfectScrollbar from "react-perfect-scrollbar";
import Modal from "react-responsive-modal";
import SlidingPane from "react-sliding-pane";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Select from "react-select";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { toast } from "react-toastify";
import Rodal from "rodal";
// Scrollbar Style
import "../../../../node_modules/react-perfect-scrollbar/dist/css/styles.css";
import "../../../../node_modules/rodal/lib/rodal.css";
import ModalChecklistForm from "../modals/ModalChecklistForm";
import FormQuestionCard from "../cards/FormQuestionCard";
import SearchTextFieldWithFilter from "../text_fields/SearchTextFieldWithFilter";

const PAGE_INTERVAL = 20;

// const moment = require('moment');

// class ContentFormAdminScreen extends Component {
function ContentChecklistAdminScreen(props) {
  const [alteredContent, setAlteredContent] = useState(false);
  const [objectList, setObjectList] = useState([]);
  const [theChosenObject, setTheChosenObject] = useState({});
  const [chosenObject, setChosenObject] = useState({});
  const [chosenObjectId, setChosenObjectId] = useState({});
  const [filterdList, setFilteredList] = useState({});
  const [isCalled, setIsCalled] = useState(false);
  const [relations, setRelations] = useState([]);
  const [defaultRoles, setDefaultRoles] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [authors, setAuthors] = useState([]);
  // const [authors, setstate] = useState([])
  const [subjects, setSubjects] = useState([]);
  const [contents, setContents] = useState([]);
  const [open, setOpen] = useState(false);
  const [showList, setShowList] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [fieldsToFilter, setFieldsToFilter] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [maxEl, setMaxEl] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [is_saving, setIsSaving] = useState(false);
  const [is_loading, setIsLoading] = useState(false);
  const [is_editing_item, setIsEditingItem] = useState(false);
  const [descriptionState, setDescriptionState] = useState({});
  const [leftOffset, setLeftOffset] = useState(0);
  const [modalWidth, setModalWidth] = useState(0);
  const [is_selecting_type, setIsSelectingType] = useState(false);
  const [typeSelect, setTypeSelect] = useState(-1);
  const [forms, setForms] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const [activityTitle, setActivityTitle] = useState("");
  const [isRemovedWhenCompleted, setIsRemovedWhenCompleted] = useState(false);
  const [openCofirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  let objectModel = props.objectModel;

  let onFilterReload = props.onFilterReload;
  let addButton_placeholder = props.addButton_placeholder;
  let searchText_placeholder = props.searchText_placeholder;
  let hideAddButton = props.hideAddButton;
  let key = props.key;

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    setStateFromProps(props);
  }, []);
  useEffect(() => {}, [props.typeQuestion]);

  //  exclude UI Classes
  let objectCard = props.objectCard ? props.objectCard : this.defaultObjectCard;
  let objectDescription = props.objectDescription
    ? props.objectDescription
    : this.defaultObjectDescription;

  let ListSize = objectList.length * 100;
  // this.ListSize = chosenObjectList.length * 100;
  let maxPageupdateSelectList = props.updateSelectList;
  let onDropAction = props.onDropAction;

  let objectIdName = props.objectIdName;

  const addObject = props.addObject
    ? async (contentType, questionType) => {
        let modal = !!questionType;
        setTypeSelect(questionType ? questionType : -1);
        // this.setState({ isCalled: false });
        if (modal) {
          setIsCalled(false);
          let obj = await props.addObject(contentType, questionType);
          //exclude this.setChosenObject(obj);
          findChosenObject(obj);
          setIsCalled(true);
          setIsEditingItem(true);
          setIsSelectingType(false);
        } else {
          setIsSelectingType(true);
          setIsSelectingType(-1);
        }
      }
    : () => {};
  const saveObject = props.saveObject
    ? (obj) => {
        setIsSaving(false);
        setAlteredContent(false);
        props
          .saveObject(obj)
          .then(() => {
            setIsSaving(false);
          })
          .catch((e) => {
            // this.setState({ is_saving: false });
            setIsSaving(false);

            console.warn(e);
          });
      }
    : () => {};

  const publishObject = props.saveObject
    ? // this.publishObject = props.saveObject
      (obj, isPublished) => {
        setIsSaving(true);
        setAlteredContent(false);

        props
          .saveObject(obj, isPublished)
          .then(() => {
            setIsSaving(false);
            onModalClose();
          })
          .catch((e) => {
            setIsSaving(false);
            console.warn(e);
          });
      }
    : () => {};

  const updateObject = props.updateObject
    ? (invoiceObj, field, value, force) => {
        if (props.saveObject) {
          // this.setState({ alteredContent: true });
          setAlteredContent(true);
        }
        let obj = props.updateObject(invoiceObj, field, value, force);
        findChosenObject(obj);
      }
    : () => {};

  const deleteObject = props.deleteObject
    ? (obj) => {
        setIsSaving(true);
        setAlteredContent(false);
        // this.setState({ is_saving: true, alteredContent: false });
        props.deleteObject(obj);
        setIsSaving(false);
        setAlteredContent(!!props.dontCloseRodal);
        setIsCalled(false);
        // this.setState({
        //   is_saving: false,
        //   is_editing_item: props.dontCloseRodal ? true : false,
        // });
      }
    : () => {};

  let refreshObjects = props.refreshObjects //&& this.fieldsToFilter.length > 0
    ? () => {
        setIsLoading(true);
        // this.setState({ is_loading: true });
        let fieldsFilterTo = fieldsToFilter;
        let filterObjAPI = {};
        for (let i = 0; i < fieldsFilterTo.length; i++) {
          let filterObj = fieldsFilterTo[i];
          if (filterObj.filter_type === "DATE") {
            if (filterObj.from_date)
              filterObjAPI[filterObj.parameter + "_from"] = filterObj.from_date;
            if (filterObj.to_date)
              filterObjAPI[filterObj.parameter + "_to"] = filterObj.to_date;
          } else if (filterObj.filter_type === "NUMERIC") {
            filterObjAPI[filterObj.parameter + "_operator"] = filterObj.operator
              ? filterObj.operator
              : undefined;
            filterObjAPI[filterObj.parameter + "_number"] =
              filterObj.number != null ? filterObj.number : undefined;
          } else if (
            filterObj.selectedValues &&
            filterObj.selectedValues.length > 0
          ) {
            filterObjAPI[
              filterObj.parameter
            ] = `('${filterObj.selectedValues.join("','")}')`;
          }
        }

        filterObjAPI["offset"] = (curPage - 1) * PAGE_INTERVAL;
        filterObjAPI["interval"] = PAGE_INTERVAL;
        if (textFilter) {
          filterObjAPI["textFilter"] = textFilter;
        }
        if (filterObjAPI) {
          props
            .refreshObjects(filterObjAPI)
            .then(() => {
              let maxEl = props.pagination
                ? props.numberElements
                : objectList.filter(filterList).length;
              let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);
              setMaxEl(maxEl);
              setMaxPage(maxPage);
              setIsLoading(false);
            })
            .catch((err) => {
              toast.error(
                "Erro ao carregar elementos. Verifique internet e tente novamente."
              );
              setIsLoading(false);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    : () => {};

  useEffect(() => {
    window.addEventListener("resize", calculateLeftPosition);
    calculateLeftPosition();
    return () => {
      // window.removeEventListener('scroll', handleScroll)
      window.removeEventListener("resize", calculateLeftPosition);
    };
  }, [window.innerWidth]);

  // componentDidMount() {
  //   window.addEventListener("resize", this.calculateLeftPosition);
  //   this.calculateLeftPosition();
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.calculateLeftPosition);
  // }

  // Ensures that updated state will be re-rendered!
  useEffect(() => {
    setStateFromProps(props);
  }, [props]);
  //error
  // componentWillReceiveProps(nextProps) {
  //   this.setStateFromProps(nextProps);
  // }

  // Set state from props
  const setStateFromProps = function (props) {
    // setStateFromProps(props) {
    let objectList = props.objectList;

    if (!props.objectList || !(props.objectList instanceof Array)) {
      objectList = [];
    }

    let maxEl = props.pagination
      ? props.numberElements
      : objectList.filter(filterList).length;

    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

    let roleOptions = [];
    if (props.relations[0] && props.relations[0].length > 0) {
      roleOptions = props.relations[0].map((role) => {
        let roleOption = {
          value: role.id_role,
          label: role.role_name,
        };
        return roleOption;
      });
    }

    // List of Objects
    setRoleOptions(roleOptions);
    setObjectList(objectList);
    setMaxEl(maxEl);
    setMaxPage(maxPage);
    setAuthors(props.authors ? props.authors : []);
    setSubjects(props.subjects ? props.subjects : []);
    // setContents(objectList)
    setForms(props.forms ? props.forms : contents);
    // this.setState({
    //   objectList: objectList,
    //   maxEl: maxEl,
    //   maxPage: maxPage,
    //   relations: props.relations ? props.relations : [],
    //   authors: props.authors ? props.authors : [],
    //   subjects: props.subjects ? props.subjects : [],
    //   contents: this.state.contents,
    //   forms: props.forms,
    // });

    let chosenObject =
      props.objectList && props.objectList.find
        ? props.objectList.find((el) => {
            return el[objectIdName] === chosenObjectId;
            // return el[chosenObjectIdName] === this.state.chosenObjectId;
          })
        : null;

    if (chosenObject) {
      findChosenObject(chosenObject);

      // this.setChosenObject(chosenObject);
    }
  };

  // UI Default Classes
  const defaultObjectCard = function (props) {
    return (
      <Paper style={{ margin: "20px" }}>{JSON.stringify(props.object)}</Paper>
    );
  };

  const defaultObjectDescription = function (props) {
    return (
      <Paper style={{ margin: "20px", height: "300px", width: "100%" }}>
        {JSON.stringify(props.object)}
      </Paper>
    );
  };

  // Event Functions
  const onPopUpClose = () => {
    setOpen(false);
    // this.state.open = false;
    //  key = key+1;
    ++key;
  };

  const fetchFilters = () => {
    if (objectModel) {
      objectModel.filters().then((filters) => {
        if (fieldsToFilter && fieldsToFilter.length > 0) {
          let fieldsToFilter = fieldsToFilter;
          for (let i = 0; i < fieldsToFilter.length; i++) {
            let filterObj = fieldsToFilter[i];
            let newFilterObj = filters.find((el) => {
              return el.parameter === filterObj.parameter;
            });
            newFilterObj.selectedValues = filterObj.selectedValues;
            newFilterObj.selectedOptions = filterObj.selectedOptions;
            newFilterObj.from_date = filterObj.from_date;
            newFilterObj.to_date = filterObj.to_date;
          }
        }

        setFieldsToFilter([]);
      });
    }
  };

  const searchText_onClickFilter = () => {
    let maxEl = props.pagination
      ? props.numberElements
      : objectList.filter(filterList).length;
    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

    setMaxEl(maxEl);
    setMaxPage(maxPage);
    setShowFilter(true);
  };

  let searchTimeout = null;

  const searchText_onSearch = (event) => {
    setTextFilter(event);
    // setState({ textFilter: event });

    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      if (props.pagination) refreshObjects();
    }, 1000);
  };

  const onModalClose = () => {
    let basic = chosenObject?.training?.training_title
      ? chosenObject?.training?.training_title
      : null;
    basic = basic ? basic : chosenObject?.form.form_title;
    //  console.log(basic)
    if (basic === null || basic === undefined) {
      toast.info("Para fechar adicione um titulo");
      return;
    } else if (basic?.trim().length === 0) {
      toast.info("Para fechar adicione um titulo");
      return;
    }
    if (
      !alteredContent /*||
      /*(alteredContent &&
        window.confirm(
          "O item atual ainda não foi salvo. " +
            "Deseja fechar a janela? (As alterações serão perdidas!)"
        ))*/
    ) {
      setAlteredContent(false);
      setIsCalled(false);
      setIsEditingItem(false);
      setDescriptionState({});
      setFilteredList({});
      // setState({
      //   alteredContent: false,
      //   isCalled: false,
      //   is_editing_item: false,
      //   descriptionState: {},
      //   filteredList: {},
      // });
    }
  };

  const objectCard_CallDescription = (object) => {
    if (
      !alteredContent ||
      (alteredContent &&
        window.confirm(
          "O item atual ainda não foi salvo. " +
            "Tem certeza que deseja abrir outro item antes de salvar o atual? " +
            "(As alterações serão perdidas)"
        ))
    ) {
      object.start_commit_time = new Date();
      // setState({
      //   alteredContent: false,
      //   isCalled: false,
      //   is_editing_item: true,
      //   descriptionState: {},
      //   filteredList: {},
      // });
      setAlteredContent(false);
      setIsCalled(false);
      setIsEditingItem(true);
      setDescriptionState({});
      setFilteredList({});

      if (object !== {}) {
        findChosenObject(object);
        // setChosenObject(object);
        let that = this;
        setTimeout(() => {
          setIsCalled(true);
          // that.setState({ isCalled: true });
        }, 1);
      }
    }
  };

  const nullDescription = (nada) => {
    nada = nada ? nada : null;
    setChosenObject(nada);
    setIsCalled(false);
    findChosenObject({});

    // setState({ isCalled: false });
  };
  // useEffect(() => {
  //   setIsCalled(!isCalled)
  //  console.log(isCalled,'isCalled')
  //  // setState({ isCalled: false });
  // }, [isCalled])
  const updateDescriptionState = (descriptionState) => {
    let oldState = descriptionState;
    if (!oldState) {
      oldState = {};
    }

    for (let prop in descriptionState) {
      if (descriptionState.hasOwnProperty(prop)) {
        oldState[prop] = descriptionState[prop];
      }
    }
    descriptionState = oldState;
    setDescriptionState(descriptionState);

    // setState({ descriptionState });
  };

  const progressFunction = (completed) => {
    // If completed is an Object with props (else just saves a number)
    if (isNaN(completed)) {
      let completedState = completed;
      if (completedState === 0) {
        completedState = {};
      }
      for (let prop in completed) {
        if (completed.hasOwnProperty(prop)) {
          completedState[prop] = completed[prop];
        }
      }
      completed = completedState;
    }
    setCompleted(completed);
    // setState({ completed });
  };

  const Description = (chosenObject) => {
    //olhar isso
    return new objectDescription({
      object: chosenObject,
      saveObject: saveObject,
      publishObject: publishObject,
      updateObject: updateObject,
      deleteObject: deleteObject,
      nullDescription: nullDescription,
      relations: relations,
      authors: authors,
      subjects: subjects,
      contents: contents,
      showList: showList,
      progressFunction: progressFunction,
      completed: completed,
      updateDescriptionState: updateDescriptionState,
      descriptionState: descriptionState,
      objectList: objectList,
      forms: props.forms ? props.forms : contents,
      typeQuestion: props.typeQuestion,
    });
  };

  const findChosenObject = (chosenObject) => {
    setChosenObject(chosenObject);
    setActivityTitle(
      chosenObject.form?.form_title ? chosenObject.form?.form_title : ""
    );
    setIsRemovedWhenCompleted(chosenObject.is_removed_when_completed);
    let defaultRoles = [];
    if (chosenObject.roles && chosenObject.roles?.length > 0) {
      defaultRoles = chosenObject.roles.map((role) => {
        let defaultRole = {
          value: role.id_role,
          label: role.role_name,
        };
        return defaultRole;
      });
    }
    setDefaultRoles(defaultRoles);
    // setState({ chosenObject: chosenObject });
    if (chosenObject[objectIdName]) {
      //parei aqui

      setChosenObjectId(chosenObject[objectIdName]);
      // setState({ chosenObjectId: chosenObject[objectIdName] });
    } else {
      setChosenObjectId(null);

      // setState({ chosenObjectId: null });
    }
  };

  const filterList = (object) => {
    if (props.pagination) {
      return true;
    }
    if (fieldsToFilter && fieldsToFilter.length > 0) {
      for (let i = 0; i < fieldsToFilter.length; i++) {
        let filterObj = fieldsToFilter[i];
        // Check if there are filtered values
        if (
          filterObj.filter_type !== "IGNOREFRONT" &&
          (filterObj.filter_type === "DATE" ||
            (filterObj.selectedValues && filterObj.selectedValues.length > 0))
        ) {
          let objectValue = object[filterObj.parameter];
          let objectValueArray = [];

          // If parameter has dots, dive into object's hierarchy
          if (filterObj.parameter.indexOf(".") > 0) {
            let parameterList = filterObj.parameter.split(".");
            objectValue = object[parameterList[0]];
            if (objectValue instanceof Array) {
              for (let k = 0; k < objectValue.length; k++) {
                for (let j = 1; j < parameterList.length; j++) {
                  if (!objectValue) break;
                  if (objectValue[k])
                    objectValueArray.push(objectValue[k][parameterList[j]]);
                }
              }
            } else {
              for (let j = 1; j < parameterList.length; j++) {
                if (!objectValue) break;
                objectValueArray.push(objectValue[parameterList[j]]);

                if (j === parameterList.length - 1) {
                  break;
                }
              }
            }
          }
          if (objectValueArray.length > 0) {
            objectValue = objectValueArray;
          }
          if (filterObj.filter_type === "DATE") {
            if (
              filterObj.from_date &&
              new Date(objectValue) < filterObj.from_date
            )
              return false;
            if (filterObj.to_date && new Date(objectValue) > filterObj.to_date)
              return false;
          } else {
            let isInFilter;
            // Searches object value in array of filtered values
            if (objectValue instanceof Array) {
              isInFilter = objectValue.filter((value) => {
                return filterObj.selectedValues.find((filterValue) => {
                  if (
                    !filterValue ||
                    filterValue === "null" ||
                    filterValue === null ||
                    filterValue === 0 ||
                    filterValue === "0" ||
                    filterValue === "undefined" ||
                    filterValue === undefined
                  ) {
                    return !objectValue;
                  }
                  return filterValue === value;
                });
              });
            } else {
              isInFilter = filterObj.selectedValues.find((filterValue) => {
                if (
                  !filterValue ||
                  filterValue === "null" ||
                  filterValue === null ||
                  filterValue === 0 ||
                  filterValue === "0" ||
                  filterValue === "undefined" ||
                  filterValue === undefined
                ) {
                  return !objectValue;
                }
                return filterValue === objectValue;
              });
            }
            if (isInFilter instanceof Array) {
              if (isInFilter.length == filterObj.selectedValues.length) {
                for (let i = 0; i < isInFilter.length; i++) {
                  if (
                    !filterObj.selectedValues.some(
                      (value) => value == isInFilter[i]
                    )
                  ) {
                    return false;
                  }
                }
              } else {
                return false;
              }
            }
            // Check if value is in filter array.
            // Sometimes, filter array has 0 number, so we need to check explicitly for that
            else if (!isInFilter && isInFilter !== 0) return false;
          }
        }
      }
    }

    if (textFilter && textFilter !== "") {
      if (
        JSON.stringify(Object.values(object))
          .toUpperCase()
          .indexOf(textFilter.toUpperCase()) < 0
      )
        return false;
    }

    return true;
  };

  const nextPage = () => {
    let maxEl = props.pagination
      ? props.numberElements
      : objectList.filter(filterList).length;
    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

    let curPage = curPage;
    if (curPage < maxPage) {
      curPage++;
      if (props.pagination) {
        // state.curPage = curPage;
        refreshObjects();
      } else {
        setCurPage(curPage);

        // setState({ curPage });
      }
    }
  };

  const previousPage = () => {
    let curPage = curPage;
    curPage--;
    if (curPage >= 1) {
      if (props.pagination) {
        // state.curPage = curPage;
        refreshObjects();
      } else {
        setCurPage(curPage);

        // setState({ curPage });
      }
    }
  };

  const handleChoseContentType = () => {
    setIsSelectingType(true);

    // setState({ is_selecting_type: true });
  };

  const calculateLeftPosition = () => {
    setTimeout(() => {
      let leftOffset = 0;
      let modalWidth = 0;

      // if (
      //   ReactDOM &&
      //   ReactDOM.findDOMNode(refs["GenericScreenContainer"])
      // ) {
      //   leftOffset = ReactDOM.findDOMNode(
      //     refs["GenericScreenContainer"]
      //   ).getBoundingClientRect().left;
      // }
      // let react = GenericScreenContainer.getBoundingClientRect()
      leftOffset = window.innerWidth - window.outerHeight + 450;
      modalWidth = window.innerWidth - leftOffset - 100;
      setLeftOffset(leftOffset);
      setLeftOffset(modalWidth);
      // setState({ leftOffset, modalWidth });
    }, 300);
  };

  // Final UI
  // render() {
  let that = this;

  let maxEle = props.pagination
    ? props.numberElements
    : objectList.filter(filterList).length;
  let minElPage = (curPage - 1) * PAGE_INTERVAL + 1;
  let maxElPage =
    curPage * PAGE_INTERVAL > maxEle ? maxEle : curPage * PAGE_INTERVAL;

  const onChangeTitle = (title) => {
    setActivityTitle(title);
    chosenObject.form.form_title = title;
  };

  const onChangeRemoveWhenCompleted = () => {
    setIsRemovedWhenCompleted(!isRemovedWhenCompleted);
    chosenObject.is_removed_when_completed = !isRemovedWhenCompleted;
  };

  const handleClose = () => {
    if (
      (chosenObject.form?.form_title
        ? chosenObject.form?.form_title
        : activityTitle) &&
      (chosenObject.form?.form_title
        ? chosenObject.form?.form_title
        : activityTitle) !== ""
    ) {
      nullDescription(chosenObject);
      setIsEditingItem(false);
    } else {
      toast.error(
        "Insira um título para sua atividade e salve antes de fechar."
      );
    }
  };

  const onQuestionChange = (field, idxQuestion) => (event) => {
    let value = event && event.target ? event.target.value : null;
    let formsQuestionsList = cloneDeep(chosenObject.form.forms_questions);
    let formQuestionObj = formsQuestionsList[idxQuestion];

    if (formQuestionObj) {
      formQuestionObj[field] = value;
      updateObject(chosenObject, "form.forms_questions", formsQuestionsList);
    }
  };

  const showForm = () => {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "10px 0px 10px 10px",
        }}
      >
        <span
          style={{
            fontSize: "1rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: 1.5,
            letterSpacing: "0.00938em",
            textAlign: "left",
          }}
        >
          {/* {typeQuestion ===9} */}
          Atividade :
        </span>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            float: "left",
          }}
        >
          <Grid xs={12}>
            <Grid xs={3} item>
              <Button
                onClick={() => this.addQuestion(8, false)}
                variant="outlined"
                // color="success"
              >
                Adicionar Atividade <AddIcon />
              </Button>
            </Grid>
            <Grid xs={3} item>
              <Button
                onClick={() => this.addQuestion(9, false)}
                variant="outlined"
                // color="success"
              >
                Adicionar Formulario <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </div> */}

        {/* //deves ter o form question antes de add a quetão  */}
        {chosenObject.form && chosenObject.form.forms_questions
          ? chosenObject.form.forms_questions.map((question, idx) => {
              let deleteQuestion = () => {
                let formsQuestionsList = cloneDeep(
                  chosenObject.form.forms_questions
                );
                formsQuestionsList.splice(idx, 1);
                updateObject(
                  chosenObject,
                  "form.forms_questions",
                  formsQuestionsList
                );
              };

              return (
                //uma olhada aqui
                <>
                  {/* <p>aaaaaaaaaaaaaaaaaaaaaa</p> */}
                  <FormQuestionCard
                    formQuestion={question}
                    onQuestionChange={onQuestionChange}
                    deleteQuestion={deleteQuestion}
                    idx={idx}
                    forms={props.forms}
                    typeQuestion={props.typeQuestion ? props.typeQuestion : -1}
                    // mudar aqui
                    objectList={props.objectList}
                    formType={6}
                  />
                  {/* <FormQuestionCardModular
                    formQuestion={question}
                    onQuestionChange={this.onQuestionChange}
                    deleteQuestion={deleteQuestion}
                    idx={idx}
                    forms={props.forms}
                    typeQuestion={props.typeQuestion ? props.typeQuestion : -1}
                    // mudar aqui
                    objectList={props.objectList}
                    formType={6}
                  /> */}
                </>
              );
            })
          : null}
      </Grid>
    );
  };

  const handleCloseDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleOpenModalDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };
  const handleDelete = () => {
    let formList = props.objectList;
    let position = formList.findIndex((obj) => {
      return obj.id_content === chosenObject.id_content;
    });
    try {
      formList[position].remove();
      formList.splice(position, 1);

      setForms(formList);
      toast.success("Atividade removida com sucesso!");
      nullDescription(chosenObject);
      setIsEditingItem(false);
      handleCloseDeleteDialog();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao remover atividade! Tente mais tarde..");
    }
  };
  const handleSave = () => {
    if (activityTitle && activityTitle !== "") {
      chosenObject.form.form_title = activityTitle;
      chosenObject.is_removed_when_completed = isRemovedWhenCompleted;
      chosenObject.roles = roles;
      saveObject(chosenObject);
    } else {
      toast.error(
        "Insira um título para sua atividade e salve antes de fechar."
      );
    }
  };
  const handlePublish = () => {
    if (activityTitle && activityTitle !== "") {
      chosenObject.form.form_title = activityTitle;
      chosenObject.roles = roles;
      chosenObject.is_removed_when_completed = isRemovedWhenCompleted;
      chosenObject.is_published = 1;
      publishObject(chosenObject, true);
      nullDescription(chosenObject);
      setIsEditingItem(false);
    } else {
      toast.error(
        "Insira um título para sua atividade e salve antes de fechar."
      );
    }
  };

  const onChangeRoles = (value) => {
    let roles;
    if (value != null) {
      //null cant use map function
      roles = value.map((obj) => {
        return {
          //revert the label to put the id_role and role_name in the Content Object
          id_role: obj.value,
          role_name: obj.label,
        };
      });
    } else {
      roles = [];
    }
    setRoles(roles);
    setDefaultRoles(value);
  };

  const addQuestion = (defaultValue = 1, show) => {
    let formValue =
      defaultValue === null || defaultValue === undefined ? 1 : defaultValue;
    let formsQuestionsList = chosenObject.form.forms_questions
      ? cloneDeep(chosenObject.form.forms_questions)
      : [];
    //parei aqui
    formsQuestionsList.push({ form_question_type: formValue });
    show = show === undefined || show === null ? -1 : show;
    updateObject(chosenObject, "form.forms_questions", formsQuestionsList);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      // ref={"GenericScreenContainer"}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px 30px 10px 20px",
        }}
      >
        {addButton_placeholder ? (
          <div style={{ paddingRight: "10px" }}>
            <Button
              variant="contained"
              onClick={() => addObject(3) /*handleChoseContentType*/}
            >
              <AddCircleIcon style={{ marginRight: "8px" }} />{" "}
              {addButton_placeholder}
            </Button>
          </div>
        ) : null}
        <div style={{ paddingRight: "10px" }}>
          <Button variant="contained" onClick={refreshObjects}>
            <i
              className="fa fa-fw fa-refresh"
              style={{ fontSize: "1.75em", verticalAlign: "middle" }}
            />
          </Button>
        </div>
        <div style={{ flex: "1" }}>
          <SearchTextFieldWithFilter
            placeholder={searchText_placeholder}
            onSearch={searchText_onSearch}
            onClickFilter={searchText_onClickFilter}
            filters={fieldsToFilter}
          ></SearchTextFieldWithFilter>
        </div>
      </div>
      <div style={{ flex: "1" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
              display: showList ? "flex" : "none",
            }}
          >
            <Grid container>
              <Grid xs={12} style={{ height: "82vh" }}>
                <PerfectScrollbar
                  style={{
                    width: "100%",
                    height: "82vh",
                    overflow: "hidden",
                    position: "absolute",
                  }}
                  options={{ suppressScrollX: "true" }}
                >
                  {objectList
                    .filter(filterList)
                    .map((object, index) => {
                      return (
                        <div
                          style={{
                            marginBottom: "5px",
                            marginLeft: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <>
                            {
                              new objectCard({
                                object: object,
                                updateObject: updateObject,
                                deleteObject: deleteObject,
                                callDescription: objectCard_CallDescription,
                                relations: relations,
                                authors: authors,
                                subjects: subjects,
                                descriptionState: descriptionState
                                  ? descriptionState
                                  : null,
                                chosenId: chosenObjectId,
                              })
                            }
                            {/* //voltar aqui prara trocar */}
                            <ModalChecklistForm
                              object={object}
                              updateObject={updateObject}
                              deleteObject={deleteObject}
                              callDescription={objectCard_CallDescription}
                              relations={relations}
                              authors={authors}
                              subjects={subjects}
                              descriptionState={
                                descriptionState ? descriptionState : null
                              }
                              chosenId={chosenObjectId}
                            />
                          </>
                        </div>
                        // {
                        //   new objectCard({
                        //     object: object,
                        //     updateObject: updateObject,
                        //     deleteObject: deleteObject,
                        //     callDescription:
                        //       objectCard_CallDescription,
                        //     relations:relations,
                        //     authors:authors,
                        //     subjects:subjects,
                        //     descriptionState:descriptionState
                        //       ?descriptionState
                        //       : null,
                        //     chosenId:chosenObjectId,
                        //   })
                        // }
                      );
                    })
                    .slice(
                      props.pagination ? 0 : (curPage - 1) * PAGE_INTERVAL,
                      props.pagination ? undefined : curPage * PAGE_INTERVAL
                    )}
                </PerfectScrollbar>
              </Grid>
              <Grid xs={12} style={{ marginBottom: "10px" }}>
                <Button
                  variant="contained"
                  style={{ margin: "0px 10px", fontWeight: "bolder" }}
                  onClick={previousPage}
                >
                  &lt;
                </Button>
                Exibindo {minElPage}-{maxElPage} de {maxEl}
                <Button
                  variant="contained"
                  style={{ margin: "0px 10px", fontWeight: "bolder" }}
                  onClick={nextPage}
                >
                  &gt;
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* //not need */}
      <SlidingPane
        isOpen={showFilter}
        title="Filtros"
        from="right"
        width="40vw"
        onRequestClose={
          () => setShowFilter(false)
          // setState({ showFilter: false })
        }
      >
        <PerfectScrollbar
          style={{
            width: "100%",
            height: "87vh",
            overflow: "hidden",
            position: "absolute",
          }}
          options={{ suppressScrollX: "true" }}
          //   ref={(ref) => {
          //  let   _scrollBarRef = ref;
          //   }}
        >
          {fieldsToFilter
            ? fieldsToFilter.map((el) => {
                el.operator = el.operator
                  ? el.operator
                  : el.possibleValues && el.possibleValues[0]
                  ? el.possibleValues[0].value
                  : null;

                const handleChange = (selectedOptions) => {
                  let selectedValues = selectedOptions.map((obj) => {
                    return obj.value;
                  });

                  let fieldsToFilter = fieldsToFilter;
                  let fieldObj = fieldsToFilter.find((filterObj) => {
                    return filterObj.parameter === el.parameter;
                  });

                  fieldObj.selectedValues = selectedValues;
                  fieldObj.selectedOptions = selectedOptions;

                  if (onFilterReload) refreshObjects();

                  let maxEl = props.pagination
                    ? props.numberElements
                    : objectList.filter(filterList).length;
                  let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);
                  setMaxEl(maxEl);
                  setMaxPage(maxPage);
                  setFilteredList(fieldsToFilter);
                  //aqui
                  // that.setState({
                  //   maxEl: maxEl,
                  //   maxPage: maxPage,
                  //   fieldsToFilter: fieldsToFilter,
                  // });
                };

                const handleDateChange = (date_field) => (event) => {
                  event = event ? new Date(event) : undefined;

                  let fieldsToFilter = fieldsToFilter;
                  let fieldObj = fieldsToFilter.find((filterObj) => {
                    return filterObj.parameter === el.parameter;
                  });

                  fieldObj[date_field] = event;

                  if (onFilterReload) refreshObjects();

                  let maxEl = props.pagination
                    ? props.numberElements
                    : objectList.filter(filterList).length;
                  let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);
                  setMaxEl(maxEl);
                  setMaxPage(maxPage);
                  // that.setState({
                  //   maxEl: maxEl,
                  //   maxPage: maxPage,
                  // });

                  //that.setState(fieldsToFilter);
                };

                const handleNumericChange = (numeric_field) => (event) => {
                  let fieldsToFilterTo = fieldsToFilter;
                  let fieldObj = fieldsToFilterTo.find((filterObj) => {
                    return filterObj.parameter === el.parameter;
                  });

                  fieldObj[numeric_field] = event.target.value;

                  if (onFilterReload) refreshObjects();

                  let maxEl = props.pagination
                    ? props.numberElements
                    : objectList.filter(filterList).length;
                  let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);
                  setMaxEl(maxEl);
                  setMaxPage(maxPage);
                  setFilteredList(fieldsToFilterTo);
                  // that.setState({
                  //   maxEl: maxEl,
                  //   maxPage: maxPage,
                  // });

                  // that.setState(fieldsToFilter);
                };

                return (
                  <>
                    <Grid container>
                      <Grid item xs={5}>
                        <p>{el.title}</p>
                      </Grid>

                      {el.filter_type === "DATE" ? (
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid xs={6}>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                style={{
                                  border: "0px",
                                  color: "black",
                                  textDecoration: "none",
                                }}
                              >
                                <DateTimePicker
                                  clearable
                                  label="De"
                                  value={el.from_date}
                                  style={{
                                    width: "100%",
                                    textDecoration: "none",
                                  }}
                                  format="dd/MM/yyyy"
                                  onChange={handleDateChange("from_date")}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid xs={6}>
                              <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                style={{
                                  border: "0px",
                                  color: "black",
                                  textDecoration: "none",
                                }}
                              >
                                <DateTimePicker
                                  clearable
                                  label="Até"
                                  value={el.to_date}
                                  style={{
                                    width: "100%",
                                    textDecoration: "none",
                                  }}
                                  format="dd/MM/yyyy"
                                  onChange={handleDateChange("to_date")}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : el.filter_type === "NUMERIC" ? (
                        <Grid item xs={6}>
                          <Grid container>
                            <Grid item xs={3}>
                              <TextField
                                id="comparison_operator"
                                select
                                value={el.operator}
                                defaultValue={el.operator}
                                onChange={handleNumericChange("operator")}
                              >
                                {el.possibleValues.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "30px" }}>
                              <TextField
                                id="comparison_number"
                                type="number"
                                value={el.number}
                                defaultValue={el.number ? el.number : undefined}
                                onChange={handleNumericChange("number")}
                                inputProps={{ min: "0", max: "100", step: "1" }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={6}>
                          <ReactMultiSelectCheckboxes
                            style={{ dropdownButton: { width: "100%" } }}
                            options={el.possibleValues}
                            defaultValue={el.selectedOptions}
                            onChange={handleChange}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </>
                );
              })
            : null}
        </PerfectScrollbar>
      </SlidingPane>
      <Modal
        open={is_saving}
        showCloseIcon={false}
        focusTrapped={false}
        center={true}
        onClose={() => {}}
      >
        <h2>Salvando alterações...</h2>
      </Modal>
      <Modal
        open={is_loading}
        showCloseIcon={false}
        focusTrapped={false}
        center={true}
        onClose={() => {}}
      >
        <h2>Carregando...</h2>
      </Modal>
      <Dialog
        open={is_selecting_type}
        scroll={"paper"}
        fullWidth={false}
        maxWidth={"md"}
        onClose={() => setIsSelectingType(false)}
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <Typography variant="h5">{"Enviar Rotina"}</Typography>
        </DialogTitle>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <DialogTitle>
            <Typography variant="h6">{"Escolha um tipo de rotina"}</Typography>
          </DialogTitle>
        </div>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "3vw",
              textAlign: "center",
            }}
          >
            <div>
              {" "}
              <Card
                style={{
                  display: "flex",
                  marginRight: "1vw",
                  width: "10vw",
                  height: "10vw",
                  justifyContent: "center",
                  backgroundColor: "#eeee",
                }}
              >
                <Button
                  style={{ width: "10vw", height: "10vw" }}
                  onClick={() => addObject(3, 9)}
                  // onClick={() => addObject(3)}
                >
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    style={{ objectFit: "cover", width: "5vw", height: "5vw" }}
                    id={"form-modal"}
                    color={"#424242"}
                    // title={"Form"}
                    title={"Checklist"}
                  />
                </Button>
              </Card>
              <p>Checklist</p>
            </div>

            {/* <Card
                style={{
                  display: "flex",
                  width: "10vw",
                  height: "10vw",
                  justifyContent: "center",
                  backgroundColor: "#eeee",
                }}
              >
                <Button
                  style={{ width: "10vw", height: "10vw" }}
                  onClick={() => addObject(4)}
                >
                  <FontAwesomeIcon
                    icon={faCamera}
                    style={{ objectFit: "cover", width: "5vw", height: "5vw" }}
                    id={"desFoto-modal"}
                    color={"#424242"}
                    title={"Desafio de foto"}
                  />
                </Button>
              </Card> */}
            <div>
              <Card
                style={{
                  display: "flex",
                  width: "10vw",
                  height: "10vw",
                  justifyContent: "center",
                  backgroundColor: "#eeee",
                }}
              >
                <Button
                  style={{ width: "10vw", height: "10vw" }}
                  onClick={() => addObject(3, 8)}
                  disabled={true}
                >
                  <FontAwesomeIcon
                    // icones
                    icon={faCheckSquare}
                    style={{ objectFit: "cover", width: "5vw", height: "5vw", opacity: 0.5 }}
                    id={"desFoto-modal"}
                    color={"#424242"}
                    title={"Desafio de foto"}
                  />
                </Button>
              </Card>
              <p>Atividade</p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openCofirmDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deseja remover essa atividade?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
          <Button onClick={handleDelete} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={is_editing_item}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
              }}
            >
              <TextField
                label="Título da Atividade"
                value={
                  chosenObject.form?.form_title !== ""
                    ? chosenObject.form?.form_title
                    : activityTitle
                }
                style={{ width: "100%" }}
                onChange={(event) => onChangeTitle(event.target.value)}
                onBlur={(event) => onChangeTitle(event.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "50%",
              }}
            >
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  marginRight: "5px",
                }}
              >
                <TextField
                  label="ID"
                  value={chosenObject.id_content}
                  style={{ width: "100%" }}
                  disabled
                />
              </div>

              <div
                style={{
                  width: "65%",
                  display: "flex",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    label="Data da ultima alteração"
                    value={chosenObject.updated_date}
                    style={{ width: "100%" }}
                    format="dd/MM/yyyy HH:mm"
                    disabled
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: "0.00938em",
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  Papéis:
                </span>
                <div style={{ width: "100%", height: "150px" }}>
                  <Select
                    placeholder={"Selecione os Papéis"}
                    options={roleOptions}
                    value={defaultRoles}
                    onChange={onChangeRoles}
                    isMulti
                  ></Select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "48%",
                  marginLeft: "10px"
                }}
              >
                <div style={{display: "flex", alignItems: "center", justifyContent:"center"}}>
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: "0.00938em",
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  Responder mais de uma vez?
                </span>
                </div>
                
                <div style={{display: "flex", alignItems: "center", justifyContent:"center"}}>
                  <Checkbox
                    onChange={onChangeRemoveWhenCompleted}
                    checked={!isRemovedWhenCompleted}
                    title={"Responder mais de uma vez?"}
                  />
                </div>
              </div>
            </div>

            <div>
              {showForm()}
              {chosenObject.form?.forms_questions[0]?.form_question_type !==
              8 ? (
                <Button
                  style={{ width: "100%", marginLeft: "10px" }}
                  onClick={() => addQuestion(1)}
                  variant="contained"
                  // color="success"
                >
                  Adicionar Pergunta <AddIcon />
                </Button>
              ) : null}
              {}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
          <Button onClick={handleOpenModalDelete}>Remover</Button>
          <Button onClick={handleSave}>Salvar Rascunho</Button>
          <Button onClick={handlePublish}>Publicar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ContentChecklistAdminScreen;
