import GenericModel from './generic_model/GenericModel';
import axios from 'axios';
import * as firebase from 'firebase/app';
import 'firebase/storage';

const API = '/tutorial_image';

class TutorialImage extends GenericModel {
  API = API;
  static API = API;

  static sendTutorialImageFile = async (
    obj,
    id_tutorial_image,
    progressFunction,
    is_register_tutorial,
  ) => {
    try {
      let formData = new FormData();

      formData.append(
        'file',
        obj,
        new Date().getTime() +
          '-' +
          Math.round(Math.random() * 100000) +
          '.' +
          obj.name.split('.').pop(),
      );

      formData.append('id_tutorial_image', JSON.stringify(id_tutorial_image));
      formData.append(
        'is_register_tutorial',
        JSON.stringify(is_register_tutorial),
      );

      let headerObj = await TutorialImage.insertAuthHeader();

      let response = await axios.request({
        url: API + '/send-tutorial-image-file',
        method: 'post',
        data: formData,
        headers: headerObj,
        onUploadProgress: (p) => {
          if (progressFunction) {
            progressFunction(Math.round((p.loaded * 100) / p.total));
          }
        },
      });

      return response.data;
    } catch (err) {
      throw Error(err);
    }
  };
//   static updateOrder = async (
//     tutorial_images,
   
//   ) => {
//     const authHeader = await GenericModel.insertAuthHeader({
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     });
// console.log(tutorial_images,'chegou aqui')
// console.log( API + '/update_order')
//     try {
//       let formData = new FormData();
      
//       let headerObj = await TutorialImage.insertAuthHeader();
//       let response = await axios.request({
//         url: API + '/update_order',
//         method: 'post',
//         // data: formData,
//         headers: authHeader,
//         body: JSON.stringify({
//           tutorial_images,
//         }),
//       });

//       return response.data;
//     } catch (err) {
//       throw Error(err);
//     }
//   };


static updateOrder = async (tutorialsUpdate) => {
  // console.log(tutorialsUpdate)
  const authHeader = await GenericModel.insertAuthHeader({
    Accept: "application/json",
    "Content-Type": "application/json",
  });

  try {
    let response = await fetch(this.API + "/update_order", {
      method: "POST",
      headers: authHeader,
      body: JSON.stringify({
        tutorialsUpdate,
      }),
    });
    if (response.status >= 400) {
      throw Error(response.statusText);
    }
    response.success = true;
    return response;
  } catch (err) {
    console.warn(err);
    return { success: false };
  }
};
}

export default TutorialImage;
