import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Spinner from "../buttons/Spinner";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Content from "../../../models/Content";
import Form from "../../../models/Form";
import ModalTicketFormBudget from "./ModalTicketFormBudget";
import ModalTicketFormBudgetNew from "./ModalTicketFormBudgetNew";

function ModalTicketDepartmentList(props) {
  const [ticketsDepartments, setTicketsDepartments] = useState(
    props.ticketDepartments
  );
  const [chosenDepartment, setChosenDepartment] = useState(null);

  const handleClose = () => {
    props.onClose();
  };
  const handleListItemClick = (value) => {
    props.handleDepartmentChosen(value);
  };

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle id="simple-dialog-title">Escolha um departamento</DialogTitle>
      <List>
        {props.ticketDepartments.map((department) => (
          <ListItem button onClick={() => handleListItemClick(department)} key={department.value}>
            <ListItemText primary={department.label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
    </>
  );
}

export default ModalTicketDepartmentList;
