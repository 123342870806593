import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Checkbox,
  Button,
  MenuItem,
  Menu
} from '@material-ui/core';
import SaveButton from "../buttons/SaveButton";
import DeleteButton from "../buttons/DeleteButton";
import ArchiveButton from "../buttons/ArchiveButton";
import { DateTimePicker } from "@material-ui/pickers/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import {
  faImage,
  faVideo,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Content from '../../../models/Content';
import ContentTrailCard from '../cards/ContentTrailCard';
import AdminContentTrailScreen from "../../screens/AdminContentTrailScreen";

export default function ContentDescriptionClassTypePill({
  content,
  onChangeUpdate,
  onChangeUpdateRoles,
  onClickPublishContent,
  onClickSaveContent,
  onClickDeleteContent,
  onClickArchiveContent,
  onChangeUpdateSubjects,
  appHasTopicsInCourses
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [classContent, setClassContent] = useState([]);

  useEffect(() => {
    getClassContent();
  }, [])


  const getClassContent = async () => {
    const response = await Content.getContentTrail(content.id_content);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addContent = async (contentType) => {
    if (classContent.length > 0) {
      return;
    }

    let newContent = new Content();
    newContent.content = {
      id_app: localStorage.getItem('ID_APP'),
      content_trail: content.id_content,
      is_challenge: 1,
      is_fixed: null,
      is_marketing: null,
      roles: content.roles,
      starting_content: content.starting_content,
      order_content: 0,
      content_type: contentType,
    };
    let contentObj = {};
    try {
      contentObj = await newContent.saveTrail();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }

    setClassContent([contentObj]);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid xs={12} container direction="row" spacing={1}>
        <Grid xs={6} item>
          <TextField
            label="Título Conteúdo"
            value={
              content.training && content.training.training_title
                ? content.training.training_title
                : ""
            }
            style={{ width: "100%" }}
            fontSize="50px"
            onChange={onChangeUpdate("content_title", false)}
            onBlur={onChangeUpdate("content_title", true)}
            multiline
          />
        </Grid>
        <Grid xs={2} item></Grid>
        <Grid xs={1} item>
          <TextField label="ID" value={content.id_content} disabled />
        </Grid>
        <Grid xs={3} item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              label="Data da ultima alteração"
              value={content.updated_date}
              style={{ width: "100%" }}
              format="dd/MM/yyyy HH:mm"
              disabled
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid xs={2} item></Grid>
      <Grid xs={12} container direction="row" spacing={1}>
        <Grid xs={3} container direction={"column"}>
          <Grid item>
            <span
              style={{
                float: "left",
                color: "rgba(0, 0, 0, 0.54)",
                padding: "0",
                fontSize: "13px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "400",
                lineHeight: "1",
                letterSpacing: "0.00938em",
              }}
            >
              Papéis:
            </span>
          </Grid>
          <Grid item>
            <Select
              placeholder={"Selecione os Papéis"}
              options={content.roleOptions}
              value={content.defaultRoleValues}
              onChange={onChangeUpdateRoles}
              isMulti
            ></Select>
          </Grid>
        </Grid>
        <Grid xs={0.5} item></Grid>
        {appHasTopicsInCourses ? (
          <Grid xs={3} container direction={"column"}>
            <Grid item>
              <span
                style={{
                  float: "left",
                  color: "rgba(0, 0, 0, 0.54)",
                  padding: "0",
                  fontSize: "13px",
                  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                  fontWeight: "400",
                  lineHeight: "1",
                  letterSpacing: "0.00938em",
                }}
              >
                Tema:
              </span>
            </Grid>
            <Grid item>
              <Select
                placeholder={"Selecione o Tema"}
                options={content.subjectOptions}
                value={content.defaultSubjectValue}
                onChange={onChangeUpdateSubjects}
                isClearable
              ></Select>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid container direction="row" xs={12}>
        {
          /* Clients that only has coins */
          localStorage.getItem("ID_APP") == 24 ||
            localStorage.getItem("ID_APP") == 31 ? (
            <Grid item xs={2}>
              <TextField
                label={"Moedas"}
                value={
                  content.content_points &&
                    content.content_points >= 0 &&
                    !content.is_marketing
                    ? content.content_points
                    : ""
                }
                disabled={content.is_marketing}
                style={{ width: "100%", marginTop: "10px" }}
                onChange={onChangeUpdate("content_points", false)}
                onBlur={onChangeUpdate("content_points", true)}
                type={"number"}
              />
            </Grid>
          ) : null
        }
        {
          /* Clients that only has coupons*/
          localStorage.getItem("ID_APP") == 4 ? (
            <Grid item xs={2}>
              <TextField
                label="Cupons"
                value={
                  content.normal_tickets && !content.is_marketing
                    ? content.normal_tickets
                    : 0
                }
                disabled={content.is_marketing}
                style={{ width: "100%", marginTop: "10px" }}
                onChange={onChangeUpdate("normal_tickets", false)}
                onBlur={onChangeUpdate("normal_tickets", true)}
              />
            </Grid>
          ) : null
        }

        {
          /* Clients that it has medals */
          localStorage.getItem("ID_APP") != 26 &&
            localStorage.getItem("ID_APP") != 27 &&
            localStorage.getItem("ID_APP") != 31 &&
            localStorage.getItem("ID_APP") != 32 &&
            localStorage.getItem("ID_APP") != 33 &&
            localStorage.getItem("ID_APP") != 34 &&
            !content.is_campaign ? (
            <Grid item xs={2} style={{ marginTop: "25px" }}>
              <Checkbox
                onChange={onChangeUpdate("have_medal", true)}
                checked={content.have_medal}
              />
              {"Tem medalhas "}
            </Grid>
          ) : null
        }
        {!content.is_campaign ? (
          <Grid item xs={2}>
            <TextField
              label="Ordem Conteúdo"
              value={content.order_content}
              onChange={onChangeUpdate("order_content", false)}
              style={{ width: "100%", marginTop: "10px" }}
            />
          </Grid>
        ) : null}
        {localStorage.getItem("ID_APP") === "3" && (
          <Grid item xs={3}>
            <Grid container style={{ width: "100%", marginTop: "10px" }}>
              <Grid item xs={2}>
                <span
                  style={{
                    fontSize: "12px",
                    fontFamily:
                      '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: "0.00938em",
                    float: "left",
                    color: "rgba(0, 0, 0, 0.54)",
                    // color: 'red',
                    paddingBottom: "0px",
                  }}
                >
                  P/inativos:
                </span>
              </Grid>
              <Grid xs={6} item>
                <Grid style={{ float: "left", marginTop: "20px" }}>
                  <Checkbox
                    onChange={onChangeUpdate("starting_content", true)}
                    checked={content.starting_content === 1}
                  />
                  P/ inativos
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid xs={12} container spacing={2}>
        <Grid xs={12} container direction="row" spacing={1}>
          <Grid item xs={12}>
            <span
              style={{
                fontSize: "18px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
                lineHeight: 1,
                letterSpacing: "0.00938em",
                float: "left",
                color: "rgba(0, 0, 0, 0.54)",
                paddingBottom: "15px",
                paddingTop: "40px",
              }}
            >
              Conteúdo:
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <div style={{ height: "40px" }}></div>
      </Grid>
      <Grid xs={12}>
        {
          <AdminContentTrailScreen startContent={content} />
        }
      </Grid>
      <Grid xs={12} item>
        <SaveButton
          style={{ float: "right" }}
          onClickSave={onClickPublishContent}
          ref={(ref) => {
            this.saveButton = ref;
          }}
          text={"Publicar"}
        />
        <SaveButton
          style={{ float: "right" }}
          onClickSave={onClickSaveContent}
          ref={(ref) => {
            this.saveButton = ref;
          }}
          text={"Salvar Rascunho"}
        />
        <DeleteButton
          style={{ float: "right", background: "#CC0B0B" }}
          onClickDelete={onClickDeleteContent}
        />
        <ArchiveButton
          style={{ float: "right" }}
          onClickArchive={onClickArchiveContent}
          is_archived={content.is_archived}
          ref={(ref) => {
            this.archiveButton = ref;
          }}
        />
      </Grid>
    </Grid>
  );
}