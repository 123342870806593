import React, {Component} from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen'
// DB Models
import Selling_Region from '../../models/Selling_Region';
import Brand from '../../models/Brand';
import Store from '../../models/Store';
import Selling_RegionCard from '../components/cards/Selling_RegionCard';
import Selling_RegionDescription from '../components/description_cards/Selling_RegionDescription.js';
import Product from "../../models/Product";

class AdminSelling_RegionScreen extends Component {

  editionTimeout = null;
  
  state = {
    selling_regions:[],
    relations: []
  };

  // TEXTS
  searchText_placeholder = 'Nome da Região';
  addButton_placeholder = 'Região';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    Selling_Region.query().then(selling_regions => {
      this.setState({'selling_regions':selling_regions});
    }).catch(err => {
      if(window.location.pathname !== '/') {
        window.location.href = '/';
      }
    });
    Brand.query().then(brands => {
      let relations = this.state.relations;
      relations[0] = brands;
      this.setState({'relations':relations});
    }).catch(err => {
      if(window.location.pathname !== '/') {
        window.location.href = '/';
      }
    });
    Store.query().then(stores =>{
      let relations = this.state.relations;
      relations[1] = stores;
      this.setState({'relations':relations});
    }).catch(err => {
      if(window.location.pathname !== '/') {
        window.location.href = '/';
      }
    });
    
  };

  addSelling_Region = async () => {
    let newSelling_Region = new Selling_Region();
    let selling_regionObj = {};
    try {
      selling_regionObj = await newSelling_Region.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let selling_regionList = this.state.selling_regions;
    selling_regionList.push(selling_regionObj);
    this.setState({ 'selling_regions': selling_regionList });
    return selling_regionObj;
  };

  updateSelling_Region = (selling_RegionObj, field, value, force) => {
    let that = this;
    let selling_RegionList = this.state.selling_regions;
    let position = selling_RegionList.findIndex((obj) => {
      return obj.id_selling_region === selling_RegionObj.id_selling_region;
    });
    let selling_RegionToUpdate = selling_RegionList.find((obj) => {
      return obj.id_selling_region === selling_RegionObj.id_selling_region;
    });

    if (!field) {
      for (let prop in value) {
        if(value.hasOwnProperty(prop)) {
          selling_RegionToUpdate[prop] = value[prop];
        }
      }
    } else {
      selling_RegionToUpdate[field] = value;
    }

    // Timeout: Wait 500 milliseconds before sending API POST call to Server
    if (this.editionTimeout) clearTimeout(this.editionTimeout);

    function uploadSelling_Region() {
      if (selling_RegionToUpdate != null) {
        selling_RegionToUpdate.save().then(selling_RegionObj => {
          // Selling_Region upload to server worked
          selling_RegionList[position] = selling_RegionObj;
          that.setState({ 'selling_regions': selling_RegionList });
        }).catch(err => {
          console.error(err);
        });
      }
    }

    if (force) uploadSelling_Region();
    else {
      this.editionTimeout = setTimeout(() => {
        uploadSelling_Region()
      }, 500);
    }
    this.setState({'selling_regions': selling_RegionList});
    return selling_RegionList[position];
  };

  deleteSelling_Region = (selling_regionObj) => {

    let selling_regionList = this.state.selling_regions;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < selling_regionList.length; i++) {
      if(selling_regionObj.id_selling_region === selling_regionList[i].id_selling_region) {
        selling_regionList[i].remove();
        selling_regionList.splice(i,1);
        break;
      }
    }

    this.setState({'selling_regions':selling_regionList});
  };


  render() {
    return (
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.selling_regions}
        objectCard={Selling_RegionCard}
        objectDescription={Selling_RegionDescription}
        addObject={this.addSelling_Region}
        updateObject={this.updateSelling_Region}
        deleteObject={this.deleteSelling_Region}
        relations={this.state.relations}
        objectIdName={"id_selling_region"}>
      </GenericAdminScreen>
    )
  }
}

export default AdminSelling_RegionScreen;