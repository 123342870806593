import React from "react";
import { Grid, Paper } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faVideo,
  faCamera,
  faFileAlt,
  faFilePdf,
  faEye,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import DownloadButton from "../buttons/DownloadButton";
import Content from "../../../models/Content";
import * as FileSaver from "file-saver";
import moment from "moment";

export default function ContentFormCardNew(props) {
  const title = props.object.form ? props.object.form.form_title : "";

  const id = props.object.id_content ? props.object.id_content : "SEM ID";

  return (
    <div
      style={{
        height: "6vw",
        backgroundColor: "#f2f2f2",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <FontAwesomeIcon
        icon={faFileAlt}
        style={{
          width: "10vw",
          height: "3vw",
          objectFit: "cover",
          marginRight: "20px",
          marginLeft: "20px",
        }}
        id={"image-modal"}
        color={"#424242"}
      />
      <div style={{ flex: 3 }}>
        <span
          style={{
            float: "left",
            padding: "0",
            fontSize: "18px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1",
            letterSpacing: "0.00938em",
          }}
        >
          {title}
        </span>
      </div>
      <FontAwesomeIcon
        icon={faCommentDots}
        style={{
          width: "5vw",
          height: "2vw",
          objectFit: "cover",
          marginRight: "5px",
        }}
        id={"image-modal"}
        color={"#424242"}
      />
      <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <span
          style={{
            float: "left",
            padding: "0",
            fontSize: "18px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1",
            letterSpacing: "0.00938em",
          }}
        >
          {props.object.answer_number
            ? props.object.answer_number + "%"
            : "0" + "%"}
        </span>
      </div>
      <div style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        {props.object.id_form && props.object.has_answers ? (
          <DownloadButton
            style={{ backgroundColor: "#4caf50" }}
            onClickDownload={props.onClickDownloadAnswers}
          ></DownloadButton>
        ) : null}
      </div>

      <div style={{ flex: 1 }}>
        <span
          style={{
            float: "flex",
            padding: "0",
            fontSize: "18px",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1",
            letterSpacing: "0.00938em",
          }}
        >
          {props.object.is_published == 1 ? "Publicado" : "Salvo como rascunho"}
        </span>
      </div>
    </div>
  );
}
