import { Button, Grid, Paper, TextField } from "@material-ui/core";
import React from "react";
import '../../../../node_modules/react-perfect-scrollbar/dist/css/styles.css';
import DownloadButton from "../buttons/DownloadButton";
import DashboardBarChart from '../charts/DashboardBarChart';
import Content from '../../../models/Content';
import * as FileSaver from 'file-saver';
import moment from 'moment';


export default function ContentEngagementDescription(props) {
  const EXCEL_TYPE = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';

  this.editionTimeout = null;


  // Object to Render
  this.object = props.object;
  //FUNCTIONS
  this.nullDescription = props.nullDescription;
  this.relations = props.relations;
  this.isCalled = props.isCalled;
  this.thiscontent = [];

  this.onClickDownloadAnswers = async () => {


    await Content.getexcel(this.object.id_content.toString()).then(data => {

      let filename = this.object.form_filename;
      let blob = new Blob([data], { type: EXCEL_TYPE });
      FileSaver.saveAs(blob, filename);

    }).catch(err => {
      console.error(err);
    });
  }

  return (
    <div style={{ 'height': '100%', width: '100%' }} key={this.keyReload}>
      <Grid container direction="row" spacing={2} justify="space-evenly" style={{ marginTop: "10px" }}>
        <Grid xs={1} item>
          <TextField label="ID" value={this.object.id} style={{ 'width': '100%' }} disabled />
        </Grid>
        <Grid xs={5} item>
          <TextField label="Título" value={this.object.title} style={{ 'width': '100%' }} disabled />
        </Grid>
        <Grid xs={3} item>
          <TextField label="Tipo de conteúdo" value={this.object.content_type_name} style={{ 'width': '100%' }} disabled />
        </Grid>
        <Grid xs={3} item>
          <TextField label="Data de criação" value={
            (this.object.created_date) ?
              moment(this.object.created_date).format('DD/MM/YYYY') :
              "Não disponível"
          } style={{ 'width': '100%' }} disabled />
        </Grid>
        <Grid xs={6} item>
          <DashboardBarChart data={this.object.views_data}></DashboardBarChart>
        </Grid>
        <Grid xs={6} container direction={"column"} style={{alignItems:"center"}}>
          {(this.object.is_form && this.object.has_answers) ? <span style={{
            fontSize: '14px',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            lineHeight: 1,
            letterSpacing: '0.00938em',
            float: 'center',
            color: 'rgba(0, 0, 0, 0.54)',
          }}>
            Respostas:
          </span> : null}

          <div style={{width:180}}>

            {(this.object.is_form && this.object.has_answers) ? <DownloadButton style={{ float: 'center' }} onClickDownload={this.onClickDownloadAnswers}
              ref={(ref) => { this.downloadButton = ref }}> </DownloadButton> : null}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}