import React from 'react';
import { Grid, Paper, TextField, Button } from '@material-ui/core';
import { red, green, blue, purple } from '@material-ui/core/colors';
import DeleteButton from '../buttons/DeleteButton';

export default function SubjectCard(props) {


    this.editionTimeout = null;

    // Object to Render
    this.object = props.object;

    // Functions
    this.updateSubject = props.updateObject;
    this.deleteSubject = props.deleteObject;
    this.callDescription = props.callDescription;


    this.onClickDeleteSubject = () => {
        this.deleteSubject(this.object);
    };

    this.onChangeUpdate = (field, force) => event => {
        let value = (event && event.target) ? event.target.value : null;
        this.updateSubject(this.object, field, value, force);
    };
    //Function to Call Description in GenericAdminScreen.js
    this.onClickCallDescription = () => {
        this.callDescription(this.object);
    }

    return (
        <Paper style={{ 'height': '100px' }} onClick={this.onClickCallDescription}>
            <Grid container>
                <Grid xs={3}>
                    <div style={{ height: '80px', background: '#f2f2f2', top: '10px', margin: 'auto', position: 'relative', width: '75px' }}></div>
                </Grid>
                <Grid xs={8} style={{ marginTop: '15px' }}>
                    <table>
                        <tr>
                            <Grid xs={12} style={{ float: 'left' }}>
                                <span style={{
                                    'width': '100%', float: 'left', padding: '0', fontSize: '18px',
                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
                                    letterSpacing: '0.00938em'
                                }}>
                                    {this.object.topic_name ? this.object.topic_name : ''}
                                </span>
                            </Grid>
                        </tr>
                    </table>
                </Grid>
            </Grid>
        </Paper>
    );

}