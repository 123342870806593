import React from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import SaveButton from '../buttons/SaveButton';
import DeleteButton from '../buttons/DeleteButton';
import ArchiveButton from '../buttons/ArchiveButton';
import SearchableSelect from '../selectbox/SearchableSelect.js';
import { DateTimePicker } from '@material-ui/pickers/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileImage,
  faFileVideo,
  faFileAlt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DateFnsUtils from '@date-io/date-fns';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { CustomDropzone } from '../CustomDropzone';
import FormQuestionCard from '../cards/FormQuestionCard';
import Training from '../../../models/Training';
import ManualContent from '../../../models/ManualContent';
import Content from '../../../models/Content';
import Select from 'react-select';
import cloneDeep from 'lodash/cloneDeep';
import AddIcon from '@material-ui/icons/Add';
import AdminContentTrailScreen from '../../screens/AdminContentTrailScreen';
import { toast } from 'react-toastify';

export default function ManualContentDescription(props) {
  this.editionTimeout = null;
  // Object to Render
  this.object = props.object;

  this.trainings = props.object.trainings;

  this.roles = props.relations[0];
  // Functions
  this.updateContent = props.updateObject;

  this.deleteContent = props.deleteObject;
  this.saveContent = props.saveObject;
  this.publishObject = props.publishObject;
  this.nullDescription = props.nullDescription;

  this.showList = props.showList;
  this.attachListSize = props.object.trainings ? props.object.trainings.length : 0;

  let updateDescriptionState = props.updateDescriptionState
    ? props.updateDescriptionState
    : () => {};

  this.onClickSaveContent = () => {
    if(this.object.manual_content_title === null|| this.object.manual_content_title === undefined ){
      toast.info('Para salvar adicione um título')
      return;
     }else if(this.object.manual_content_title?.trim().length===0){
       toast.info('Para salvar adicione um título')
       return;
     }
    this.saveContent(this.object);
  };

  this.onClickPublishContent = () => {
    if(this.object.manual_content_title === null|| this.object.manual_content_title === undefined ){
      toast.info('Para publicar adicione um titulo')
      return;
     }else if(this.object.manual_content_title?.trim().length===0){
       toast.info('Para publicar adicione um titulo')
       return;
     }
    this.object.is_published = 1;
    this.publishObject(this.object);
  };

  this.onClickDeleteContent = () => {
    if (window.confirm('Tem certeza que deseja remover esse item?')) {
      this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the content
      this.object.is_published = null;
      this.deleteContent(this.object);
    }
  };

  this.onChangeUpdate =
    (field, force = false , dataLimit=null) =>
    (event) => {
      let value = event && event.target ? event.target.value : null;
      if(field === "link" && value &&value.length >10){
        let url = value
        
          if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url;
            value = url ;
            }
      }

      if(
        dataLimit 
      ){
        const verify =this.object[field] ? this.object[field] : null;
        // limit the value/message_title to dataLimit before update is the limit in db
        if(value.length > dataLimit){
        value=  value.substring(0 ,dataLimit)
        }
        if(( verify===null || verify.length < dataLimit) || ( value.length < verify.length ))
          {    
          this.updateContent(this.object, field, value, force);
          }
  
      }else{
        this.updateContent(this.object, field, value, force);
      }
    };

  this.onDropActionAttachFiles = async (files, allFiles) => {
    files = files;
    let listTam = this.attachListSize + files.length;

    const progressFunction = (completed) => {
      const completedState = {
        completedAttachedFiles: completed,
      };
      props.progressFunction(completedState);
    };

    if (listTam > 10) {
      toast.error('Arquivo muito grande ');
      toast.info('Máximo de 10 conteúdos para envio ');
      return;
    }

    try {
      for (let i = 0; i < files.length; i++) {
        // toast(listTam + 'tam');
        this.attachListSize ++
        let tam = i + 1;
        toast.dark('Carregando item  ' + tam);
        let imageUrl = null;
        let videoUrl = null;
        let file = files[i];
        let filetype = file.type.split('/')[0];
        if (filetype === 'image') {
          imageUrl = await ManualContent.sendAttachFile(file, progressFunction); //enviando
          if (imageUrl) {
            await ManualContent.saveManualImage(
              this.object.id_manual_content,
              imageUrl.url,
              file.name,
              2,
            );
          }
        }
        if (filetype === 'video') {
          videoUrl = await ManualContent.sendAttachFileVideo(
            file,
            progressFunction,
          );
          if (videoUrl) {
            await ManualContent.saveManualVideo(
              this.object.id_manual_content,
              videoUrl.url,
              file.name,
              1,
            );
          }
        }

        // Reset completed variable (progress bar)

        if (i === files.length - 1) {
          this.saveContent(this.object);
          progressFunction(0);
        } else {
          progressFunction(1);
        }
      }
    } catch (err) {
      console.error(err);
      progressFunction(0);
      toast.error('Ocorreu um erro no upload do seu arquivo!');
    }
  };

  this.deleteStoreAttachment = async (attachment) => {
    if (this.object && attachment) {
      await ManualContent.removeAttachFile(attachment);
      this.saveContent(this.object);
    }
  };

  return (
    <div style={{ height: 'auto' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '90%',
          alignItems: 'center',
          padding: '30px 20px 10px 20px',
        }}
      >
        <Grid container spacing={0}>
          <Grid xs={12} container direction='row' spacing={0}>
            <Grid xs={12} container spacing={2}>
              <Grid xs={4} item>
                <TextField
                  label='Título Conteúdo'
                  value={
                    this.object.manual_content_title
                      ? this.object.manual_content_title
                      : ''
                  }
                  style={{ width: '100%' }}
                  onChange={this.onChangeUpdate('manual_content_title', false)}
                  onBlur={this.onChangeUpdate('manual_content_title', true)}
                />
              </Grid>
              <Grid xs={4} item>
                <Grid xs={12} item>
                  <TextField
                    label='Ordem Conteúdo'
                    value={this.object.order_content}
                    onChange={this.onChangeUpdate('order_content', false)}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
              <Grid xs={1} item>
                <TextField
                  label='ID'
                  value={this.object.id_manual_content}
                  disabled
                />
              </Grid>
              <Grid xs={3} item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    label='Data da ultima alteração'
                    value={this.object.updated_date}
                    style={{ width: '100%' }}
                    format='dd/MM/yyyy HH:mm'
                    disabled
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              container
              direction='row'
              spacing={1}
              style={{ marginTop: '20px' }}
            >
              <Grid xs={12} container direction='row' spacing={3}>
                <Grid xs={6} style={{ marginTop: '20px' }}>
                  <Grid xs={12} item>
                    <span
                      style={{
                        fontSize: '12px',
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        letterSpacing: '0.00938em',
                        float: 'left',
                        color: 'rgba(0, 0, 0, 0.54)',
                        paddingBottom: '10px',
                        paddingLeft: '20px',
                      }}
                    >
                      Texto:{' '}
                      {this.object && this.object.manual_content_text
                        ? this.object.manual_content_text.length
                        : 0}
                      /20000
                    </span>
                  </Grid>
                  <TextareaAutosize
                    aria-label={'Texto'}
                    placeholder={'Texto(20000 caracteres)'}
                    maxLength={20000}
                    rows={20}
                    value={this.object.manual_content_text}
                    onChange={this.onChangeUpdate('manual_content_text', false)}
                    onBlur={this.onChangeUpdate('manual_content_text', true)}
                    style={{
                      width: '90%',
                      height: '70%',
                      padding: '10px 10px',
                    }}
                  />
                </Grid>
                <Grid xs={6} container direction='row' spacing={0}>
                  <Grid xs={12}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{
                          position: 'relative',
                          display: 'flex',
                        }}
                      >
                        <Grid container>
                          <Grid xs={11} item>
                            <div id='picker'>
                              {!this.trainings ||
                              this.trainings?.length < 10 ? (
                                <CustomDropzone
                                  onDropAction={this.onDropActionAttachFiles}
                                  completed={
                                    props.completed.completedAttachedFiles
                                  }
                                  inactiveLabel={
                                    'Arraste arquivos (.jpeg, .png, .gif, .mp4, .avi) para cá ou clique para anexar! Máximo de 10 arquivos'
                                  }
                                  activeLabel={'Solte o arquivo'}
                                />
                              ) : (
                                <div>
                                  <p>
                                    Limite de conteúdos atingido
                                    <br />
                                    Máximo de 10 conteúdos
                                  </p>
                                </div>
                              )}
                            </div>
                            <PerfectScrollbar
                              style={{
                                width: '100%',
                                height: '20vh',
                                overflow: 'hidden',
                                position: 'absolute',
                              }}
                              options={{ suppressScrollX: 'true' }}
                            >
                              {this.trainings.map((object, index) => {
                                return (
                                  <div
                                    style={{
                                      marginBottom: '5px',
                                      marginLeft: '20px',
                                      marginRight: '20px',
                                    }}
                                  >
                                    <div
                                      itemID={object.id_manual_content_training}
                                      style={{
                                        backgroundColor: '#eeee',
                                        borderRadius: 30,
                                        padding: '10px',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'flex-start',
                                          alignItems: 'center',
                                          marginLeft: '5px',
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            flex: 1,
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={
                                              object.manual_content_training_type ===
                                              2
                                                ? faFileImage
                                                : faFileVideo
                                            }
                                            style={{
                                              fontSize: '1.75em',
                                              verticalAlign: 'middle',
                                              marginRight: '5px',
                                            }}
                                            color={'#000'}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flex: 5,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <span>
                                            {object.manual_content_training_name
                                              ? object.manual_content_training_name
                                              : 'Arquivo'}
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flex: 1,
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <Button
                                            onClick={() =>
                                              this.deleteStoreAttachment(object)
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrash}
                                              style={{
                                                fontSize: '1.20em',
                                                verticalAlign: 'middle',
                                                marginLeft: '5px',
                                              }}
                                              color={'#CC0B0B'}
                                            />
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </PerfectScrollbar>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} container direction='row' spacing={1}>
              <Grid xs={6} item>

              <TextField
              label= {this.object.link && this.object.link.length ? `Compartilhar link externo ${this.object.link.length +'/300'} `: ' Compartilhar link externo 0/300' } 
              value={this.object.link  ? this.object.link : ''}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('link', true ,300 )}
              onBlur={this.onChangeUpdate('link', true)}
              />


              </Grid>
              <Grid xs={6} item>
              {
                this.object.link && this.object.link.length &&  
                <>
               <span
               style={{
                 fontSize: '12px',
                 fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                 fontWeight: 400,
                 lineHeight: 1,
                 letterSpacing: '0.00938em',
                 float: 'left',
                 color: 'rgba(0, 0, 0, 0.54)',
                 paddingBottom: '15px',
                 width: '100%',
                //  paddingLeft: '20px',
                }}
                >
                Teste o Link de compartilhamento 
                </span>
              {
                this.object.link && this.object.link.length &&
                <a
                target="_blank"
                href={
                  this.object.link
                  ? this.object.link
                  : null
                }
                rel="noreferrer"
                >
                Clique aqui para acessar o link.
                </a>
                
              }
            
              </>}

              {/* <TextField
              label= {this.object.link_name && this.object.link_name.length ? `Nome do link ${this.object.link_name.length +'/50'} `: ' Nome do link 0/50' } 
              value={this.object.link_name  ? this.object.link_name : ''}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('link_name', true , 50)}
              onBlur={this.onChangeUpdate('link_name', true)}
              /> */}


              </Grid>
          </Grid>
          </Grid>
          <Grid xs={12} item>
            <SaveButton
              style={{ float: 'right' }}
              text={'Publicar'}
              onClickSave={this.onClickPublishContent}
              ref={(ref) => {
                this.saveButton = ref;
              }}
            />
            <SaveButton
              style={{ float: 'right' }}
              text={'Salvar Rascunho'}
              onClickSave={this.onClickSaveContent}
              ref={(ref) => {
                this.saveButton = ref;
              }}
            />
            <DeleteButton
              style={{ float: 'right', background: '#CC0B0B' }}
              onClickDelete={this.onClickDeleteContent}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
