import React, { useState, useEffect } from 'react';
import RoleAdminScreen from '../components/generic_screens/RoleAdminScreen';
// DB Models
import Role from '../../models/Role';
import User from '../../models/User';
import Brand from '../../models/Brand';
import Selling_Region from '../../models/Selling_Region';
import RoleCard from '../components/cards/RoleCard';
import RoleDescription from '../components/description_cards/RoleDescription';
import Product from '../../models/Product';
import { toast } from 'react-toastify';
import Spinner from '../components/buttons/Spinner';

function AdminRoleScreenNew(props) {
  const editionTimeout = null;
  const [roles, setRoles] = useState([]);
  const [roleTypes, setRoleTypes] = useState([]);
  const [relations, setRelations] = useState([]);
  const [loading, setLoading] = useState(true);



  // TEXTS
  const searchText_placeholder = 'Nome do Papel';
  const addButton_placeholder = 'Papel';

  // FUNCTIONS
  // Load all data from DB
  useEffect(()=>{
    reloadRoles();
  },[]) 

  const refreshRoles = async (parameters) => {
    reloadRoles();
  };

  const reloadRoles = async (parameters) => {
    try {
      let [rolesDb] = await Promise.all([Role.query()]);
      let roles = [];
      let rolesType = [];
      if (rolesDb && rolesDb.length > 0) {
          roles = rolesDb.filter((role) => {
              return !role.id_role_ref;
          });
          rolesType = rolesDb.filter((role) => {
              return role.id_role_ref;
          });
      }
      setRoles(roles);
      setRoleTypes(rolesType);
      setLoading(false);
    } catch (err) {
      console.warn(err);
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
  };

  const addRole = async (role_name) => {
    let newRole = new Role();
    newRole.role_name = role_name;
    let roleObj = {};
    try {
      if (!newRole.role_name || newRole.role_name === "") {
          throw new Error("role_name");
      }
      roleObj = await newRole.save();
      toast.success('Segmentação criada com sucesso!');
      let roleList = roles;
    roleList.push(roleObj);
    setRoles(roleList);
    return roleObj;
    } catch (err) {
      console.error(err);
      switch (err.message) {
        case "role_name":
          toast.error(
            "Informe um nome para o seu segmento."
          );
          break;
        default:
          toast.error("Erro ao salvar segmento!");
          break;
      }
    }
    
  };

  const addRoleType = async (role_name, id_role_ref, objectListTypes) => {
    let newRole = new Role();
    newRole.role_name = role_name;
    newRole.id_role_ref = id_role_ref ? id_role_ref : null;
    let roleObj = {};
    try {
      if (!newRole.role_name || newRole.role_name === "") {
          throw new Error("role_name");
      }
      roleObj = await newRole.save();
      toast.success('Segmentação criada com sucesso!');
      let roleListType = objectListTypes;
      roleListType.push(roleObj);
    setRoleTypes(roleListType);
    return roleObj;
    } catch (err) {
      console.error(err);
      switch (err.message) {
        case "role_name":
          toast.error(
            "Informe um nome para o seu segmento."
          );
          break;
        default:
          toast.error("Erro ao salvar segmento!");
          break;
      }
    }
    
  };

  const updateRole = (roleObj, field, value, force) => {
    let roleList = roles;
    let position = roleList.findIndex((obj) => {
      return obj.id_role === roleObj.id_role;
    });
    let roleToUpdate = roleList.find((obj) => {
      return obj.id_role === roleObj.id_role;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          roleToUpdate[prop] = value[prop];
        }
      }
    } else {
      roleToUpdate[field] = value;
    }

    // Timeout: Wait 500 milliseconds before sending API POST call to Server
    if (editionTimeout) clearTimeout(editionTimeout);
    setRoles(roleList);
    return roleList[position];
  };

 const deleteRole = (roleObj) => {
    let roleList = roles;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < roleList.length; i++) {
      if (roleObj.id_role === roleList[i].id_role) {
        roleList[i].remove();
        roleList.splice(i, 1);
        break;
      }
    }

    setRoles(roleList);
  };

 const deleteRoleType = (roleObj, roleTypes) => {
    let roleList = roleTypes;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < roleList.length; i++) {
      if (roleObj.id_role === roleList[i].id_role) {
        roleList[i].remove();
        roleList.splice(i, 1);
        break;
      }
    }

    setRoleTypes(roleList);
  };

  const saveRole = async (roleObj) => {
    let roleList = roles;
    let position = roleList.findIndex((obj) => {
      return obj.id_role === roleObj.id_role;
    });
    let roleToUpdate = roleList.find((obj) => {
      return obj.id_role === roleObj.id_role;
    });

    if (roleToUpdate != null) {
      let roleObj = roleToUpdate;

      try {
        roleObj = await roleToUpdate.save();
        // Role upload to server worked
        roleList[position] = roleObj;
        setRoles(roleList);
        toast.success('Papel salvo com sucesso!');
      } catch (err) {
       toast.error('Erro ao salvar papel!');
        console.error(err);
      }
    }
  };

    return (
      <> 
      {roles.length===0 
      &&loading
      ? <Spinner
       margin='13px' 
      texto={'carregando...'}
      border={'#9BB3D4'}
      top={'#1D2D44'}/>
      :
      <RoleAdminScreen
        searchText_placeholder={searchText_placeholder}
        addButton_placeholder={addButton_placeholder}
        objectList={roles}
        objectTypeList={roleTypes}
        objectCard={RoleCard}
        objectDescription={RoleDescription}
        addObject={addRole}
        addObjectType={addRoleType}
        saveObject={saveRole}
        updateObject={updateRole}
        deleteObject={deleteRole}
        deleteObjectType={deleteRoleType}
        refreshObjects={refreshRoles}
        relations={relations}
        objectIdName={'id_role'}
        objectModel={Role}
      ></RoleAdminScreen>
  }
  </>
    );
}

export default AdminRoleScreenNew;
