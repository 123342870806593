import {TextField, withStyles} from "@material-ui/core";
import React, {Component} from 'react';

const WhiteTextField = withStyles({
  root: {
    '& input.MuiInputBase-input': {
      color: 'white'
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& label.MuiInputLabel-animated': {
      color: 'white'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white'
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    }
  }
})(TextField);

export default WhiteTextField;