import "../../../../node_modules/rodal/lib/rodal.css";
import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Radio,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  NativeSelect,
  MenuItem,
  Box,
  Card,
  Tooltip,
} from "@material-ui/core";
import { Select as MaterialSelect } from "@material-ui/core";
import {
  faFileImage,
  faTrash,
  faTimes,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import cloneDeep from "lodash/cloneDeep";
import { TicketsCustomDropzone } from "../TicketsCustomDropzone";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import Spinner from "../buttons/Spinner";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TicketDepartment from "../../../models/TicketDepartment";
import Content from "../../../models/Content";
import Form from "../../../models/Form";
import DeleteButton from "../buttons/DeleteButton";
import DownloadButton from "../buttons/DownloadButton";
import { set } from "date-fns";

function ModalTicketFormBudgetBkp(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [formObject, setFormObject] = useState(props.form);
  const [formTitle, setFormTitle] = useState(
    props.form.form.form_title ? props.form.form.form_title : ""
  );
  const [chosenFormDepartment, setChosenFormDepartment] = useState({});
  const [formDefaultRoles, setFormDefaultRoles] = useState([]);
  const [formRoles, setFormRoles] = useState(props.form.roles);
  const [ticketsDepartments, setTicketsDepartments] = useState(
    props.ticketsDepartments
  );
  const [formQuestionLabels, setFormQuestionLabels] = useState([]);
  const [formToAdd, setFormToAdd] = useState(
    !ticketsDepartments.ticketDepartamentType ||
      !ticketsDepartments.ticketDepartamentType ||
      ticketsDepartments.ticketDepartamentType != 1
      ? 1
      : 3
  ); //remover
  const [formQuestions, setFormQuestions] = useState([]);
  const [someArray, setSomeArray] = useState([]);
  const [formChoices, setFormChoices] = useState([]);
  const [formChoicesQuestion, setFormChoicesQuestion] = useState([]);
  const [openCofirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [indexQuestionShow, setIndexQuestionShow] = useState(0);
  const [idxChoiceToShow, setIdxChoiceToShow] = useState(0);
  const [completed, setCompleted] = useState([{ completed: 0 }]);
  const [department, setDepartment] = useState(null);
  const [formQuestionsSize, setFormQuestionsSize] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    let formDefaultRoles = [];
    if (formRoles && formRoles.length > 0) {
      formDefaultRoles = formRoles.map((role) => {
        let formDefaultRole = {
          value: role.id_role,
          label: role.role_name,
        };
        return formDefaultRole;
      });
    }
    let chosenFormDepartmentDefault = {
      value: Array.isArray(props.form.form.ticket_department)
        ? props.form.form.ticket_department[0]?.id_ticket_department
        : props.form.form.ticket_department?.id_ticket_department,
      label: Array.isArray(props.form.form.ticket_department)
        ? props.form.form.ticket_department[0]?.ticket_department_name
        : props.form.form.ticket_department?.ticket_department_name,
    };
    let formQuestionDb = await Form.getFormQuestion(formObject?.id_form);
    let formQuestionsSizeObj = formQuestionDb ? formQuestionDb.length : 0;
    let formChoicesDb = [];
    if (formQuestionDb && formQuestionDb.length > 0) {
      formChoicesDb = await Form.getFormChoicesForm(props.form.id_form);
    }
    let formChoicesQuestionDb = [];
    if (formChoicesDb && formChoicesDb.length > 0) {
      formChoicesQuestionDb = formChoicesDb.filter((choices) => {
        return choices.id_form_question === formQuestionDb[0].id_form_question;
      });
    }
    let completedLoadingProductImage = [];
    if (formChoicesQuestionDb && formChoicesQuestionDb.length > 0) {
      formChoicesQuestionDb.forEach((choice) => {
        completedLoadingProductImage.push({
          completed: 0,
        });
      });
    }
    let departmentObj = Array.isArray(props.form.form.ticket_department)
      ? props.form.form.ticket_department[0]
      : props.form.form.ticket_department;

    if (departmentObj && departmentObj.id_ticket_dep_type !== 1) {
      setFormQuestionLabels([
        { id: 1, value: 1, label: "Questionário" },
        { id: 2, value: 2, label: "Aberta" },
        { id: 3, value: 4, label: "Orçamento" },
      ]);
    } else {
      setFormQuestionLabels([{ id: 3, value: 4, label: "Orçamento" }]);
    }
    setCompleted(completedLoadingProductImage);
    setFormQuestionsSize(formQuestionsSizeObj);
    setDepartment(departmentObj);
    setIndexQuestionShow(0);
    setFormChoicesQuestion(formChoicesQuestionDb);
    setFormChoices(formChoicesDb);
    setFormQuestions(formQuestionDb);
    setFormDefaultRoles(formDefaultRoles);
    setChosenFormDepartment(chosenFormDepartmentDefault);
    setIsLoading(false);
  };

  const onChangeTitle = (title) => {
    setFormTitle(title);
  };

  const onChangeRoles = (value) => {
    let roles;
    if (value != null) {
      roles = value.map((obj) => {
        return {
          id_role: obj.value,
          role_name: obj.label,
        };
      });
    } else {
      roles = [];
    }
    setFormRoles(roles);
    setFormDefaultRoles(value);
  };

  const handleDepartmentChange = (department) => {
    setChosenFormDepartment(department);
  };

  const handleClose = () => {
    props.onClose();
  };
  const handleSave = async () => {
    await saveForm(null);
  };
  const handlePublish = async () => {
    await saveForm(1);
  };

  const saveForm = async (publish) => {
    try {
      setIsLoading(true);

      if (!formTitle || formTitle.trim() === "") {
        throw new Error("form_title");
      }

      /**Form object to save start*/
      let formObj = formObject;
      formObj.is_published = publish;
      formObj.form.form_title = formTitle;
      formObj.roles = formRoles;
      let contentToUpdate = formObj;
      let contentObj = await contentToUpdate.save();
      /*Form object to save end*/

      /**Form question object to save start*/
      let formQuestionsToUpdate = formQuestions;
      if (formQuestionsToUpdate && formQuestionsToUpdate.length > 0) {
        await Form.saveFormQuestion(formQuestionsToUpdate);
      } else {
        throw new Error("form_question");
      }
      /**Form question object to save end*/

      /**Form question choices object to save start*/
      let formQuestionsChoicesToUpdate = formChoices;
      if (
        formQuestionsChoicesToUpdate &&
        formQuestionsChoicesToUpdate.length > 0
      ) {
        await Form.saveFormQuestionChoice(formQuestionsChoicesToUpdate);
      }
      setIsLoading(false);
      /**Form question choices object to save start*/

      toast.success(
        `Conteúdo ${publish === 1 ? "publicado" : "salvo"} com sucesso!`
      );
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      switch (error.message) {
        case "form_title":
          toast.error(
            `Erro ao ${
              publish === 1 ? "publicar" : "salvar"
            } conteúdo! Insira um título para seu formulário.`
          );
          break;
        case "form_question":
          toast.error(
            `Erro ao ${
              publish === 1 ? "publicar" : "salvar"
            } conteúdo! Insira pelo menos uma pergunta.`
          );
          break;
        case "form_question_blank":
          toast.error(
            `Erro ao ${
              publish === 1 ? "publicar" : "salvar"
            } conteúdo! Pergunta não pode estar em branco.`
          );
          break;
        default:
          toast.error(
            `Erro ao ${
              publish === 1 ? "publicar" : "salvar"
            } conteúdo! Tente novamente.`
          );
          break;
      }
    }
  };
  const addFormQuestion = async () => {
    let idForm = formObject.form.id_form;
    let newFormQuestion = {
      id_form: idForm,
      form_question: null,
      form_question_type: 1,
      deleted_date: null,
    };

    let formQuestionsSizeObj = formQuestionsSize;

    let newQuestionObj = await Form.addFormQuestion(newFormQuestion);
    setFormQuestions((arr) => [...arr, newQuestionObj]);
    setFormQuestionsSize(formQuestionsSizeObj + 1);
  };

  const onChangeQuestionType = (type, idx) => {
    let formQuestionObj = formQuestions.filter((question) => {
      return !question.deleted_date
    });
    let curFormQuestion = formQuestionObj[idx];
    if (curFormQuestion) {
      curFormQuestion.form_question_type = type;
    }

    let idFormQuestion = curFormQuestion.id_form_question;

    setFormQuestions((prevFormQuestions) => {
      return prevFormQuestions.map((question, index) => {
        if (question.id_form_question === idFormQuestion) {
          question = curFormQuestion;
        }
        return question;
      });
    });

    setFormChoicesQuestion([]);

    setFormChoices((existingFormChoices) => {
      return existingFormChoices.map((choice, index) => {
        if (choice.id_form_question === idFormQuestion) {
          choice.deleted_date = moment().format("YYYY-MM-DD hh:mm:ss");
        }
        return choice;
      });
    });
  };

  const onChangeQuestion = (question, idx) => {
    let formQuestionObj = formQuestions.filter((question) => {
      return !question.deleted_date
    });
    let curFormQuestion = formQuestionObj[idx];
    if (curFormQuestion) {
      curFormQuestion.form_question = question;
    }

    let idFormQuestion = curFormQuestion.id_form_question;

    setFormQuestions((prevFormQuestions) => {
      return prevFormQuestions.map((question, index) => {
        if (question.id_form_question === idFormQuestion) {
          question = curFormQuestion;
        }
        return question;
      });
    });
  };

  const setCurrentQuestion = (idx) => {
    if (indexQuestionShow !== parseInt(idx)) {
      let formQuestionObj = formQuestions.filter((question) => {
        return !question.deleted_date
      });
      let id_form_question = formQuestionObj[idx].id_form_question;
      let questionChoices = formChoices.filter((choices) => {
        return choices.id_form_question === id_form_question;
      });
      setIndexQuestionShow(parseInt(idx));
      setFormChoicesQuestion(questionChoices);
    }
  };

  const addFormChoice = async (idx) => {
    let choiceOrder = formChoicesQuestion.length + 1;
    let formQuestionObj = formQuestions.filter((question) => {
      return !question.deleted_date
    });
    let idFormQuestion = formQuestionObj[idx].id_form_question;

    let newChoice = {
      forms_choices_order: choiceOrder,
      id_form_question: idFormQuestion,
      form_choice: null,
      form_choice_correct: null,
      form_product_image: null,
    };

    let newChoiceObj = await Form.addFormQuestionChoice(newChoice);
    setFormChoicesQuestion((arr) => [...arr, newChoiceObj]);
    setFormChoices((arr) => [...arr, newChoiceObj]);
    if (formQuestions[idx].form_question_type === 4) {
      setCompleted((arr) => [...arr, { completed: 0 }]);
    }
  };

  const onChangeChoice = (choiceQuestion, idx, idxChoice, idFormChoice) => {
    let formQuestionObj = formQuestions.filter((question) => {
      return !question.deleted_date
    });
    let idFormQuestion = formQuestionObj[idx].id_form_question;

    setFormChoicesQuestion((existingFormChoicesQuestion) => {
      return existingFormChoicesQuestion.map((choice, index) => {
        if (
          choice.id_form_question === idFormQuestion &&
          choice.id_form_choice === idFormChoice
        ) {
          choice.form_choice = choiceQuestion;
        }
        return choice;
      });
    });

    setFormChoices((existingFormChoices) => {
      return existingFormChoices.map((choice, index) => {
        if (
          choice.id_form_question === idFormQuestion &&
          choice.id_form_choice === idFormChoice
        ) {
          choice.form_choice = choiceQuestion;
        }
        return choice;
      });
    });
  };

  const deleteFormQuestion = (idx) => {
    let formQuestionsSizeObj = formQuestionsSize;
    let formQuestionsObj = formQuestions.filter((question) => {
      return !question.deleted_date;
    });
    let idFormQuestion = formQuestionsObj[idx].id_form_question;
    setFormQuestions((existingFormQuestions) => {
      return existingFormQuestions.map((question, index) => {
        if (question.id_form_question === idFormQuestion) {
          question.deleted_date = moment().format("YYYY-MM-DD hh:mm:ss");
        }
        return question;
      });
    });

    setFormChoices((existingFormChoices) => {
      return existingFormChoices.map((choice, index) => {
        if (choice.id_form_question === idFormQuestion) {
          choice.deleted_date = moment().format("YYYY-MM-DD hh:mm:ss");
        }
        return choice;
      });
    });

    setFormQuestionsSize(formQuestionsSizeObj - 1);
  };

  const deleteFormChoice = (idx, idxChoice) => {
    let idFormChoice = formChoicesQuestion[idxChoice].id_form_choice;

    setFormChoicesQuestion((existingFormChoicesQuestion) => {
      existingFormChoicesQuestion.splice(idxChoice, 1);
      return existingFormChoicesQuestion;
    });

    setFormChoices((existingFormChoices) => {
      return existingFormChoices.map((choice) => {
        if (choice.id_form_choice === idFormChoice) {
          choice.deleted_date = moment().format("YYYY-MM-DD hh:mm:ss");
        }
        return choice;
      });
    });
  };

  const onDropActionAttachFiles = async (files, idxChoice, idxQuestion) => {
    files = files[0];
    let formQuestionsObj = formQuestions.filter((question) => {
      return !question.deleted_date;
    });
    
    let idFormQuestion = formQuestionsObj[idxQuestion].id_form_question;
    let completedObj = 0;
    let choiceObj = formChoicesQuestion[idxChoice];

    const progressFunction = (completedProps) => {
      let completedState = cloneDeep(completed);
      completedObj = completedProps;

      setCompleted((existingCompleted) => {
        return existingCompleted.map((element, index) => {
          if (index === idxChoice) {
            element.completed = completedObj;
          }
          return element;
        });
      });
    };

    try {
      let imageUrl = await Form.sendAttachImage(files, progressFunction);

      if (imageUrl) {
        await Form.saveFormImage(
          choiceObj.id_form_choice,
          imageUrl.url,
          choiceObj.form_choice
        );

        choiceObj.form_product_image = imageUrl.url;
      }
      progressFunction(0);
      setFormChoicesQuestion((existingFormChoicesQuestion) => {
        return existingFormChoicesQuestion.map((choice, index) => {
          if (
            index === idxChoice &&
            choice.id_form_question === idFormQuestion
          ) {
            choice = choiceObj;
          }
          return choice;
        });
      });

      setFormChoices((existingFormChoices) => {
        return existingFormChoices.map((choice, index) => {
          if (
            index === idxChoice &&
            choice.id_form_question === idFormQuestion
          ) {
            choice = choiceObj;
          }
          return choice;
        });
      });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteChoicesAttachment = async (idxChoice, idx) => {
    let formQuestionsObj = formQuestions.filter((question) => {
      return !question.deleted_date;
    });
    let idFormQuestion = formQuestionsObj[idx].id_form_question;

    setFormChoicesQuestion((existingFormChoices) => {
      return existingFormChoices.map((choice, index) => {
        if (index === idxChoice && choice.id_form_question === idFormQuestion) {
          choice.form_product_image = null;
        }
        return choice;
      });
    });

    setFormChoices((existingFormChoices) => {
      return existingFormChoices.map((choice, index) => {
        if (index === idxChoice && choice.id_form_question === idFormQuestion) {
          choice.form_product_image = null;
        }
        return choice;
      });
    });
  };

  const handleOpenModalDelete = () => {
    setOpenConfirmDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleDelete = () => {
    props.deleteForm(formObject);
  };

  return (
    <>
      <Dialog
        open={openCofirmDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deseja remover esse formulário?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
          <Button onClick={handleDelete} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={props.open}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          {isLoading ? (
            <Spinner
              margin="13px"
              texto={""}
              border={"#9BB3D4"}
              top={"#1D2D44"}
            />
          ) : (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                  }}
                >
                  <TextField
                    label="Título Conteúdo"
                    value={formTitle ? formTitle : ""}
                    style={{ width: "100%" }}
                    onChange={(event) => onChangeTitle(event.target.value)}
                    onBlur={(event) => onChangeTitle(event.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      width: "10%",
                      display: "flex",
                      marginRight: "5px",
                    }}
                  >
                    <TextField
                      label="ID"
                      value={formObject.id_content}
                      style={{ width: "100%" }}
                      disabled
                    />
                  </div>

                  <div
                    style={{
                      width: "40%",
                      display: "flex",
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        label="Data da ultima alteração"
                        value={formObject.updated_date}
                        style={{ width: "100%" }}
                        format="dd/MM/yyyy HH:mm"
                        disabled
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  flexDirection: "row",

                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "48%",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      lineHeight: 1,
                      letterSpacing: "0.00938em",
                      float: "left",
                      color: "rgba(0, 0, 0, 0.54)",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    Papéis:
                  </span>
                  <div style={{ width: "100%" }}>
                    <Select
                      placeholder={"Selecione os Papéis"}
                      options={props.roleOptions}
                      value={formDefaultRoles}
                      onChange={onChangeRoles}
                      isMulti
                    ></Select>
                  </div>
                </div>
                {
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                      marginLeft: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 400,
                        lineHeight: 1,
                        letterSpacing: "0.00938em",
                        float: "left",
                        color: "rgba(0, 0, 0, 0.54)",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      Departamento:
                    </span>
                    <Select
                      options={ticketsDepartments}
                      value={chosenFormDepartment}
                      placeholder={"Selecione um departamento..."}
                      onChange={handleDepartmentChange}
                      isMulti={false}
                      isDisabled={true}
                    ></Select>
                  </div>
                }
              </div>
              {/* inicio do box de adicionar question */}
              {/*department && department.id_ticket_dep_type !== 1 ? (
                <Box
                  sx={{
                    borderRadius: "15px",
                    flexDirection: "column",
                    width: "4%",
                    height: 60,
                    position: "absolute",
                    right: "30px",
                    top: "160px",
                    padding: "10px",
                    justifyItems: "center",
                    display: "flex",
                    "* > div": {
                      marginTop: "10px",
                    },
                    alignItems: "center",
                    backgroundColor: "#969B9F",
                    "&:hover": {
                      backgroundColor: "#BEC1C4",
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                >
                  <div
                    onClick={() => addFormQuestion()}
                    style={{
                      cursor: "pointer",

                      "&:hover": {
                        backgroundColor: "#FFF",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  >
                    <FontAwesomeIcon
                      onClick={() => {}}
                      icon={faPlusCircle}
                      style={{
                        fontSize: "30px",
                        marginTop: "10px",
                        "&:hover": {
                          backgroundColor: "#FFF",
                          opacity: [0.9, 0.8, 0.7],
                        },
                      }}
                      color={"#fdfdfd"}
                    />
                  </div>
                </Box>
              ) : null}

              {/* fim do box */}
              <div>
                {formQuestions
                  .filter((question) => {
                    return !question.deleted_date;
                  })
                  .map((question, idx) =>
                    question.form_question_type === 4 ? (
                      <div
                        key={question.id_question}
                        onClick={() => setCurrentQuestion(idx)}
                        style={{
                          borderRadius: "15px",
                          paddingBottom: "10px",
                          marginTop: "10px",
                          border:
                            indexQuestionShow === idx
                              ? "2px solid #162233"
                              : "transparent",
                          backgroundColor: "#F1F3F4",
                          cursor: "pointer",
                          width: "92%",
                        }}
                      >
                        {indexQuestionShow === idx && (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                              marginBottom: "-15px",
                            }}
                          >
                            <p style={{ marginLeft: "10px" }}>
                              {" "}
                              {`Tipo de pergunta :  ${
                                question.form_question_type === 4
                                  ? "Orçamento "
                                  : question.form_question_type == 1
                                  ? "Questionário"
                                  : question.form_question_type === 2
                                  ? "Aberta"
                                  : " Pergunta"
                              }`}
                            </p>
                            <MaterialSelect
                              style={{ marginRight: "30px", height: "40px" }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              variant={"outlined"}
                              defaultValue={question.form_question_type}
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              onChange={(event) => {
                                onChangeQuestionType(event.target.value, idx);
                              }}
                            >
                              {formQuestionLabels.map((labels) => (
                                <MenuItem key={labels.id} value={labels.value}>
                                  {labels.label}
                                </MenuItem>
                              ))}
                            </MaterialSelect>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            flexDirection: "row",
                            backgroundColor: "#F1F3F4",
                            borderRadius: "50px",
                            padding: "10px",
                            width: "95%",
                          }}
                        >
                          {indexQuestionShow !== idx ? (
                            <Box
                              sx={{
                                width: "90%",
                                display: "flex",
                                alignItems: "center",

                                cursor: "text",
                                borderBottom: "1px solid transparent",

                                "&:hover": {
                                  borderBottom: "1px solid #BEC1C4",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                            >
                              <p>
                                {question.form_question
                                  ? question.form_question
                                  : "Escreva a pergunta"}
                              </p>
                            </Box>
                          ) : (
                            <TextField
                              label={` ${
                                question.form_question
                                  ? question.form_question.length
                                  : 0
                              }/500`}
                              aria-label="(Máximo de 500 caracteres)"
                              placeholder="(Máximo de 500 caracteres)"
                              minRows={3}
                              value={
                                question.form_question
                                  ? question.form_question
                                  : ""
                              }
                              onChange={(event) =>
                                onChangeQuestion(event.target.value, idx)
                              }
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                        <div>
                          {indexQuestionShow === idx &&
                          question.form_question_type === 4 &&
                          formChoicesQuestion.length > 0 ? (
                            <div>
                              {formChoicesQuestion.map((choice, idxChoice) => (
                                <div>
                                  <div
                                    key={choice.id_form_choice}
                                    style={{
                                      display: "flex",
                                      marginTop: "5px",
                                      flexDirection: "row",
                                      margin: "10px 4%",
                                      borderRadius: "10px",
                                    }}
                                    onClick={() =>
                                      setIdxChoiceToShow(parseInt(idxChoice))
                                    }
                                  >
                                    <TextField
                                      key={choice.id_form_choice}
                                      label={` Produto ${idxChoice + 1} - ${
                                        choice.form_choice
                                          ? choice.form_choice.length
                                          : 0
                                      }/500`}
                                      aria-label={`Produto ${
                                        idxChoice + 1
                                      } (Máximo de 500 caracteres)`}
                                      placeholder={`Produto ${
                                        idxChoice + 1
                                      } (Máximo de 500 caracteres)`}
                                      minRows={3}
                                      value={
                                        choice.form_choice
                                          ? choice.form_choice
                                          : ""
                                      }
                                      onChange={(event) =>
                                        onChangeChoice(
                                          event.target.value,
                                          idx,
                                          idxChoice,
                                          choice.id_form_choice
                                        )
                                      }
                                      style={{
                                        width: "83%",
                                      }}
                                    />

                                    {/* has no images  */}
                                    {!choice.form_product_image ? (
                                      <Tooltip
                                        title={
                                          "Arraste arquivos (.jpeg, .png) para cá ou clique para anexar!"
                                        }
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "10%",
                                            minWidth: "40px",
                                            justifyContent: "center",
                                            marginTop: "5px",
                                            alignItems: "center",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <TicketsCustomDropzone
                                            onDropAction={(files) => {
                                              onDropActionAttachFiles(
                                                files,
                                                idxChoice,
                                                idx
                                              );
                                            }}
                                            maxWidth={"10%"}
                                            minWidth={"20px"}
                                            pictureLabel={true}
                                            completed={
                                              completed[idxChoice]
                                                ? completed[idxChoice].completed
                                                : 0
                                            }
                                            multiple={false}
                                            inactiveLabel={
                                              "Arraste arquivos (.jpeg, .png) para cá ou clique para anexar!"
                                            }
                                            activeLabel={"Solte o arquivo"}
                                          />
                                        </div>
                                      </Tooltip>
                                    ) : null}
                                    {/* finish */}

                                    <div
                                      style={{
                                        display: "flex",
                                        width: "4%",
                                        justifyContent: "center",
                                        alignItems: "end",
                                        background: "transparent",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        deleteFormChoice(idx, idxChoice)
                                      }
                                    >
                                      <p style={{}}>X</p>
                                    </div>
                                  </div>
                                  {choice.form_product_image ? (
                                    <div
                                      style={{
                                        width: "148px",
                                        height: "144px",
                                        margin: "10px",
                                        display: "flex",
                                        position: "relative",
                                        justifyContent: "flex-start",
                                        marginLeft: "50px",
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: "95%",
                                          height: "95%",
                                          objectFit: "contain",
                                          borderRadius: "10px",
                                        }}
                                        src={
                                          choice.form_product_image
                                            ? choice.form_product_image
                                            : null
                                        }
                                        id={"image-modal"}
                                      />
                                      <div
                                        style={{
                                          position: "absolute",
                                          left: "125px",
                                          top: "-5px",
                                          background: "gray",
                                          height: "25px",
                                          width: "25px",
                                          borderRadius: "50%",
                                        }}
                                      >
                                        <Button
                                          style={{
                                            justifyContent: "flex-start",
                                            position: "absolute",
                                            top: "7px",
                                            left: "-1px",
                                          }}
                                          onClick={() =>
                                            deleteChoicesAttachment(
                                              idxChoice,
                                              idx
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faTimes}
                                            style={{
                                              fontSize: "1.20em",
                                              position: "absolute",
                                            }}
                                            color={"#FFF"}
                                          />
                                        </Button>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                            </div>
                          ) : null}
                          {question.form_question_type === 4 &&
                          indexQuestionShow === idx ? (
                            <Button
                              onClick={() => addFormChoice(idx)}
                              color={"#F1F3F4"}
                              variant="contained"
                              style={{ marginLeft: "10px" }}
                            >
                              Adicionar produto
                            </Button>
                          ) : null}
                          {indexQuestionShow === idx &&
                          formQuestionsSize > 1 ? (
                            <div
                              onClick={() => {
                                deleteFormQuestion(idx);
                              }}
                              style={{
                                cursor: "pointer",
                                marginLeft: "91.5%",
                                marginTop: "-25px",
                                paddingBottom: "4px",
                                "&:hover": {
                                  backgroundColor: "#FFF",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  fontSize: "25px",
                                  //marginTop: "20px",
                                  "&:hover": {
                                    backgroundColor: "#FFF",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                                color={"#CC0B0B"}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <div
                        key={question.id_question}
                        onClick={() => setCurrentQuestion(idx)}
                        style={{
                          borderRadius: "15px",
                          paddingBottom: "10px",
                          marginTop: "10px",
                          border:
                            indexQuestionShow === idx
                              ? "2px solid #162233"
                              : "transparent",
                          backgroundColor: "#F1F3F4",
                          cursor: "pointer",
                          width: "92%",
                        }}
                      >
                        {indexQuestionShow === idx && (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "baseline",
                              marginBottom: "-15px",
                            }}
                          >
                            <p style={{ marginLeft: "10px" }}>
                              {" "}
                              {`Tipo de pergunta :  ${
                                question.form_question_type === 3
                                  ? "Orçamento "
                                  : question.form_question_type == 1
                                  ? "Questionário"
                                  : question.form_question_type === 2
                                  ? "Aberta"
                                  : " Pergunta"
                              }`}
                            </p>
                            <MaterialSelect
                              style={{ marginRight: "30px", height: "40px" }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              variant={"outlined"}
                              defaultValue={question.form_question_type}
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              onChange={(event) => {
                                onChangeQuestionType(event.target.value, idx);
                              }}
                            >
                              {formQuestionLabels.map((labels) => (
                                <MenuItem key={labels.id} value={labels.value}>
                                  {labels.label}
                                </MenuItem>
                              ))}
                            </MaterialSelect>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            flexDirection: "row",
                            backgroundColor: "#F1F3F4",
                            borderRadius: "50px",
                            padding: "10px",
                            width: "95%",
                          }}
                        >
                          {indexQuestionShow !== idx ? (
                            <Box
                              sx={{
                                width: "90%",
                                display: "flex",
                                alignItems: "center",

                                cursor: "text",
                                borderBottom: "1px solid transparent",

                                "&:hover": {
                                  borderBottom: "1px solid #BEC1C4",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                            >
                              <p>
                                {question.form_question
                                  ? question.form_question
                                  : "Escreva a pergunta"}
                              </p>
                            </Box>
                          ) : (
                            <TextField
                              label={` ${
                                question.form_question
                                  ? question.form_question.length
                                  : 0
                              }/500`}
                              aria-label="(Máximo de 500 caracteres)"
                              placeholder="(Máximo de 500 caracteres)"
                              minRows={3}
                              value={
                                question.form_question
                                  ? question.form_question
                                  : ""
                              }
                              onChange={(event) =>
                                onChangeQuestion(event.target.value, idx)
                              }
                              onBlur={(event) =>
                                onChangeQuestion(event.target.value, idx)
                              }
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                        <div>
                          {indexQuestionShow === idx &&
                          question.form_question_type === 1 &&
                          formChoicesQuestion.length > 0 ? (
                            <div>
                              {formChoicesQuestion.map((choice, idxChoice) => (
                                <div
                                  key={choice.id_form_choice}
                                  style={{
                                    display: "flex",
                                    marginTop: "5px",
                                    flexDirection: "row",
                                    margin: "10px 4%",
                                    borderRadius: "10px",
                                  }}
                                  onClick={() =>
                                    setIdxChoiceToShow(parseInt(idxChoice))
                                  }
                                >
                                  <TextField
                                    key={choice.id_form_choice}
                                    label={` Alternativa ${idxChoice + 1} - ${
                                      choice.form_choice
                                        ? choice.form_choice.length
                                        : 0
                                    }/500`}
                                    aria-label={`Alternativa ${
                                      idxChoice + 1
                                    } (Máximo de 500 caracteres)`}
                                    placeholder={`Alternativa ${
                                      idxChoice + 1
                                    } (Máximo de 500 caracteres)`}
                                    minRows={3}
                                    value={
                                      choice.form_choice
                                        ? choice.form_choice
                                        : ""
                                    }
                                    onChange={(event) =>
                                      onChangeChoice(
                                        event.target.value,
                                        idx,
                                        idxChoice,
                                        choice.id_form_choice
                                      )
                                    }
                                    style={{
                                      width: "83%",
                                    }}
                                  />

                                  <div
                                    style={{
                                      display: "flex",
                                      width: "4%",
                                      justifyContent: "center",
                                      alignItems: "end",
                                      background: "transparent",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      deleteFormChoice(idx, idxChoice)
                                    }
                                  >
                                    <p style={{}}>X</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                          {question.form_question_type === 1 &&
                          indexQuestionShow === idx ? (
                            <Button
                              onClick={() => addFormChoice(idx)}
                              color={"#F1F3F4"}
                              variant="contained"
                              style={{ marginLeft: "10px" }}
                            >
                              Adicionar alternativa
                            </Button>
                          ) : null}
                          {indexQuestionShow === idx &&
                          formQuestionsSize > 1 ? (
                            <div
                              onClick={() => {
                                deleteFormQuestion(idx);
                              }}
                              style={{
                                cursor: "pointer",
                                marginLeft: "91.5%",
                                marginTop:
                                  question.form_question_type === 1
                                    ? "-25px"
                                    : "5px",
                                paddingBottom: "4px",
                                "&:hover": {
                                  backgroundColor: "#FFF",
                                  opacity: [0.9, 0.8, 0.7],
                                },
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{
                                  fontSize: "25px",
                                  //marginTop: "20px",
                                  "&:hover": {
                                    backgroundColor: "#FFF",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                                color={"#CC0B0B"}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )
                  )}
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
          <Button onClick={handleOpenModalDelete}>Remover</Button>
          <Button onClick={handleSave}>Salvar Rascunho</Button>
          <Button onClick={handlePublish}>Publicar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ModalTicketFormBudgetBkp;
