import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Spinner from "../buttons/Spinner";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Content from "../../../models/Content";
import Form from "../../../models/Form";
import ModalTicketFormBudget from "./ModalTicketFormBudget";
//import ModalTicketFormBudgetNew from "./ModalTicketFormBudgetNew";
import ModalTicketFormBudgetNew from "./ModalTicketFormBudgetBkp";
import DownloadButton from "../buttons/DownloadButton";

function ModalTicketFormList(props) {
  const [ticketsDepartments, setTicketsDepartments] = useState(
    props.ticketDepartments
  );
  const [forms, setForms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chosenForm, setChosenForm] = useState({});
  const [openModalForm, setOpenModalForm] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let forms = await Content.getTicketForms(props.ticketDepartments.value);
      setForms(forms);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    props.onClose();
  };

  const addForm = async () => {
    if (ticketsDepartments) {
    // if (ticketsDepartments.ticketDepartamentType === 1) {
      let contentObj = {};
      try {
      let newContent = new Content(); 
      newContent.content = {
        id_app: localStorage.getItem("ID_APP"),
        is_challenge: 1,
        content_type: 3,
      };
      /*let departmentAux = ticketsDepartments.filter((department) => {
        return department.ticketDepartamentType === 1;
      });*/
      let department = {
        id_ticket_department: ticketsDepartments.value,
        ticket_department_name: ticketsDepartments.label,
      };
      let newForm = new Form();
      newForm = {
        form_title: null,
        form_type: 3,
        id_app: localStorage.getItem("ID_APP"),
        ticket_department: department,
        forms_questions: [{
          form_question: null,
          form_question_type: ticketsDepartments.ticketDepartamentType === 1 ? 4 : 1
        }]
      };
      newContent.content.form = newForm;

        contentObj = await newContent.saveFormTicket();
      } catch (err) {
        console.error(err);
        throw Error(err);
      }
  
      let contentList = forms;
      contentList.push(new Content(contentObj[0]));
      setForms(contentList);
      setChosenForm(new Content(contentObj[0]));
      setOpenModalForm(true);
    } else {
      toast.warning("O departamento selecionado não permite a criação de formulários");
      setIsLoading(false);
    }
    
  };

  const openChosenForm = (form) => {
    setChosenForm(form);
    setOpenModalForm(true);
  };
  const closeChosenForm = (form) => {
    setIsLoading(true);
    fetchData();
    setChosenForm(null);
    setOpenModalForm(false);
  };

  const saveFormBudget = async (formObj) => {
    let formList = forms;
    let position = formList.findIndex((obj) => {
      return obj.id_content === formObj.id_content;
    });
    let contentToUpdate = formList.find((obj) => {
      return obj.id_content === formObj.id_content;
    });
    contentToUpdate = formObj;
    if (contentToUpdate != null) {
      let contentObj = contentToUpdate;
      try {
        if (
          !contentToUpdate.form.form_title || contentToUpdate.form.form_title === ""
        ) {
          throw new Error("form_title");
        } else if (
          contentToUpdate.form.forms_questions &&
          contentToUpdate.form.forms_questions.length
        ) {
          let form_questions = contentToUpdate.form.forms_questions[0];
          if (
            !form_questions.form_question ||
            form_questions.form_question === ""
          ) {
            throw new Error("form_questions");
          } else if (!form_questions.forms_choices) {
            throw new Error("form_choices");
          } else if (
            !form_questions.forms_choices.length ||
            form_questions.forms_choices.length === 0
          ) {
            throw new Error("form_choices");
          } 

          let isFormChoiceEmpty = false;
          let formChoicesSize = form_questions.forms_choices.length;
          for (let i = 0; i < formChoicesSize; i++) {
            if (!form_questions.forms_choices[i].form_choice || form_questions.forms_choices[i].form_choice === "") {
              isFormChoiceEmpty = true;
            } 
          }

          if (isFormChoiceEmpty) {
            throw new Error("form_choices_name");
          }

          contentObj = await contentToUpdate.save();
          formList[position] = contentObj;
          setForms(formList);
          toast.success("Conteúdo salvo com sucesso!");
        } else {
          throw new Error("form_questions");
        }
      } catch (err) {
        console.log(err);
        switch (err.message) {
          case "form_questions":
            toast.error(
              "Erro ao salvar conteúdo! Elabore uma pergunta na descrição para seu formulário."
            );
            break;
          case "has_form":
            toast.error(
              "Erro ao salvar conteúdo! Pergunta do tipo formulário deve ter um formulário vinculado."
            );
            break;
          case "form_choices":
            toast.error(
              "Erro ao salvar conteúdo! Adicione pelo menos um produto."
            );
            break;
          case "form_title":
            toast.error(
              "Erro ao salvar conteúdo! Adicione um título para seu formulário."
            );
            break;
          case "form_choices_name":
            toast.error(
              "Erro ao salvar conteúdo! Adicione um título para seus produtos."
            );
            break;
          default:
            toast.error("Erro ao salvar conteúdo!");
            break;
        }
      }
    }
  };

  const saveFormBudgetProduct = (formObj) => {
    let formList = forms;
    let position = formList.findIndex((obj) => {
      return obj.id_content === formObj.id_content;
    });
    let contentToUpdate = formList.find((obj) => {
      return obj.id_content === formObj.id_content;
    });
    contentToUpdate = formObj;
    if (contentToUpdate != null) {
      try {
         if (
          contentToUpdate.form.forms_questions &&
          contentToUpdate.form.forms_questions.length
        ) {
          let form_questions = contentToUpdate.form.forms_questions[0];
          // let form_questions = contentToUpdate.form.forms_questions[0];
          let isFormChoiceEmpty = false;
          let formChoicesSize = form_questions.forms_choices.length;
          for (let i = 0; i < formChoicesSize; i++) {
            if (!form_questions.forms_choices[i].form_choice || form_questions.forms_choices[i].form_choice === "") {
              isFormChoiceEmpty = true;
            } 
          }

          formList[position] = formObj;

          setForms(formList);
        } else {
          throw new Error("form_questions");
        }
      } catch (err) {
        console.log(err);
        switch (err.message) {
          case "form_questions":
            toast.error(
              "Erro ao salvar conteúdo! Elabore uma pergunta na descrição para seu formulário."
            );
            break;
          case "has_form":
            toast.error(
              "Erro ao salvar conteúdo! Pergunta do tipo formulário deve ter um formulário vinculado."
            );
            break;
          default:
            toast.error("Erro ao salvar conteúdo!");
            break;
        }
      }
    }
  };

  const deleteForm = (formObj) => {
    let formList = forms;
    let position = formList.findIndex((obj) => {
      return obj.id_content === formObj.id_content;
    });
    try {
      formList[position].remove();
      formList.splice(position, 1);

      setForms(formList);
      toast.success("Formulário removido com sucesso!");
      closeChosenForm();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao remover formulário! Tente mais tarde..");
    }
  };

  return (
    <>
      {openModalForm && chosenForm ? (
        <ModalTicketFormBudgetNew
          // department={ticketsDepartments.department.ticketDepartamentType}
          open={openModalForm}
          saveObject={saveFormBudget}
          saveObjectProduct={saveFormBudgetProduct}
          onClose={closeChosenForm}
          roleOptions={props.roleOptions}
          form={chosenForm}
          ticketsDepartments={ticketsDepartments}
          deleteForm={deleteForm}
        />
      ) : null}

      <Dialog
        open={props.open}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent
          style={{ overflow: "hidden", display: "flex", alignItems: "center" }}
        >
          <div style={{ marginBottom: "10px" }}>
            {/* <p>aqui oq e sprint 20</p> */}

            <Button variant="contained" onClick={addForm}>
              {/* //adciona o form */}
              <AddCircleIcon style={{ marginRight: "8px" }} /> {"Formulário"}
            </Button>
          </div>
        </DialogContent>

        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          {isLoading ? <Spinner
                margin="13px"
                texto={"carregando..."}
                border={"#9BB3D4"}
                top={"#1D2D44"}
              /> : ( forms.length > 0
                ? forms.map((form, index) => {
                    return (
                      <div
                        style={{
                          backgroundColor: "#eee",
                          width: "80%",
                          borderRadius: 20,
                          height: "40px",
                          display: "flex",
                          alignItems:"center",
                          justifyContent:"space-around",
                          marginTop: index > 0 ? "10px" : 0,
                          flexDirection: "row",
                        }}
                        key={form.form.id_form}
                        onClick={() => openChosenForm(form)}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "10px",
                            width: "33%",

                          }}
                        >
                          <p>
                            {form.form.form_title && form.form.form_title !== ""
                              ? form.form.form_title
                              : "Formulário"}
                          </p>
                        </div>

                        {/* <div style={{ flex: 1, alignItems: "center", justifyContent: "center" }}> */}
                     

                        {
                          <div style={{ display: "flex", width: "50%" }}>
                            <p>
                              {Array.isArray(form.form.ticket_department)
                                ? form.form.ticket_department[0]
                                    ?.ticket_department_name
                                : form.form.ticket_department
                                    ?.ticket_department_name}
                            </p>
                          </div>
                        }
                        <div style={{ display: "flex", width: "9%"  }}>
                             {/* {this.object.is_form && this.object.has_answers ? ( */}
                        {/*<DownloadButton
                        style={{ backgroundColor: "#4caf50" , height:'37px' , width:'37px' }}
                        // onClickDownload={this.onClickDownloadAnswers}
                        // ref={(ref) => {
                        //   this.downloadButton = ref;
                        // }}
                      ></DownloadButton>*/}
                        {/* ) : null} */}
                      </div>
                      </div>
                    );
                  })
                : null)}
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ModalTicketFormList;