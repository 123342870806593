import React, {useEffect, useState} from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

import {ButtonBlueBorder, ButtonDarkBlue, ButtonWhite} from '../../../components/buttons/ThemeButtons';
import {TextInput} from '../../../components/text_fields/ThemeTextInputs';
import AlertDialog from '../../../components/alert/ThemeAlertDialog';
import VisualMerchandising from '../../../../models/VisualMerchandising';
import { showToast } from '../../../components/toast/ThemeToast';
import { handleChangeInputTextValue } from '../../../utils';

function MerchandisingContentForm({nextStep, isActive, visualMerchandising, setVisualMerchandising}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deleteAlertIsVisible, setDeleteAlertIsVisible] = useState(false);

  useEffect(() => {
    if(visualMerchandising){
      setTitle(visualMerchandising.title);
      setDescription(visualMerchandising.description);
    }
  }, [visualMerchandising]);

  const history = useHistory();

  if(!isActive){
    return null;
  }

  async function handleNext(){
    try {
      const isValid = validateInputs();

      if(!isValid){
        return;
      }

      if(visualMerchandising){
        const response = await VisualMerchandising.update(title, description, visualMerchandising.id_visual_merchandising);
  
        setVisualMerchandising({...response, ...visualMerchandising});
  
        nextStep();
        return;
      }
  
      const response = await VisualMerchandising.create(title, description);
  
      setVisualMerchandising(response);
  
      nextStep();
      showToast("success", "Dados salvos com sucesso.");
    } catch(err){
      console.log(err);
      showToast("error", "Erro ao salvar, por favor tente novamente.");
    }
  }

  async function handleSaveDraft(){
    try {
      const isValid = validateInputs();
      
      if(!isValid){
        return;
      }

      if(visualMerchandising){
        const response = await VisualMerchandising.update(title, description, visualMerchandising.id_visual_merchandising);
      }else {
        const response = await VisualMerchandising.create(title, description);
      }

      showToast("success", "Rascunho salvo com sucesso.");
      history.push("/visual_merchandising");
    } catch(err){
      console.log(err);
      showToast("error", "Erro ao salvar rascunho, por favor tente novamente.");
    }
  }

  async function handleConfirmDelete(){
    try {
      if(visualMerchandising){
        const response = await VisualMerchandising.delete(visualMerchandising.id_visual_merchandising);
        history.push("/visual_merchandising");
        return;
      }
  
      history.push("/visual_merchandising");
      showToast("success", "Visual Merchandising excluído com sucesso.");
    } catch(err){
      console.log(err);
      showToast("error", "Erro ao excluir Visual Merchandising, por favor tente novamente.");
    }
  }

  function validateInputs(){
    if(title === "" || description === ""){
      showToast("error", "Não é possível salvar o rascunho em branco.");
      return false;
    }

    return true;
  }

  return (
    <Box
      bgcolor='#FFF'
      border='1px solid #E0E5EC'
      borderRadius={8}
      display='flex'
      alignItems='flex-start'
      flexDirection='column'
      padding={4}
    >
      <AlertDialog 
        title="Descartar conteúdo"
        description="Você tem certeza que deseja descartar o conteúdo?"
        confirmTextButton="Descartar conteúdo"
        handleClose={() => setDeleteAlertIsVisible(false)}
        handleConfirm={() => handleConfirmDelete()}
        isOpen={deleteAlertIsVisible}
      />
      <h1 style={{
        color: '#666666',
        fontWeight: 700,
        fontSize: 19,
        marginBottom: 24
      }}>
        Cadastro de conteúdo
      </h1>

      <TextInput
        label="Título"
        placeholder="Título"
        variant="outlined"
        style={{ width: '50%', marginBottom: 24 }}
        value={title}
        onChange={(e) => handleChangeInputTextValue(e, title, 200, setTitle)}
        helperText={`${title.length}/ 200 caracteres`}
      />
      <TextInput
        label="Descrição"
        placeholder="Descrição"
        variant="outlined"
        multiline={true}
        style={{ width: '100%' }}
        rows={4}
        value={description}
        onChange={(e) => handleChangeInputTextValue(e, description, 500, setDescription)}
        helperText={`${description.length}/ 500 caracteres`}
      />

      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        marginTop={4}
        marginBottom={4}
      >
        {
          visualMerchandising && (
            <span
              style={{
                fontWeight: 400,
                fontSize: 12,
                color: '#666666',
                letterSpacing: 0.4
              }}
            >
              ID {visualMerchandising.id_visual_merchandising} | Última atualização: {moment(visualMerchandising.updated_date).format("DD/MM/YYYY - HH:mm")}
            </span>
          )
        }
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <ButtonWhite
          onClick={() => setDeleteAlertIsVisible(true)}
        >
          Descartar
        </ButtonWhite>
        <Box>
          <ButtonBlueBorder
            onClick={() => handleSaveDraft()}
            style={{
              marginRight: 16
            }}
          >
            Salvar rascunho
          </ButtonBlueBorder>

          <ButtonDarkBlue
            onClick={() => handleNext()}
          >
            Próximo
          </ButtonDarkBlue>
        </Box>
      </Box>
    </Box>
  );
}

export default MerchandisingContentForm;