import React from 'react';
import {
    Grid,
    Paper,
    TextField,
    Radio,
    FormControlLabel,
    RadioGroup,
    Checkbox, Button
} from '@material-ui/core';
import SaveButton from "../buttons/SaveButton";
import DeleteButton from '../buttons/DeleteButton';
import ArchiveButton from "../buttons/ArchiveButton";
import { DateTimePicker } from '@material-ui/pickers/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { CustomDropzone } from "../CustomDropzone";
import FormQuestionCard from "../cards/FormQuestionCard"
import Training from "../../../models/Training";
import Select from 'react-select';
import cloneDeep from 'lodash/cloneDeep';
import AddIcon from "@material-ui/icons/Add";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

export default function ContentDescription(props) {

    this.editionTimeout = null;
    // Object to Render
    this.object = props.object;

    this.callDescription = props.callDescription;


    this.onClickCallDescription = () => {
        this.callDescription(this.object);
    };

    return (
      <div style={{
        'height': '6vw',
        'backgroundColor': props.chosenId === this.object.id_training ? '#4498A7' : '#f2f2f2',
        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
      }} onClick={this.onClickCallDescription}>
        <img
          style={{ width: '10vw', height: '5vw', objectFit: 'cover', marginRight: '20px', marginLeft: '20px' }}
          src={this.object.training_cover_image}
          id={'image-modal'}
        />
        <div style={{ flex: 3 }}>
          <span
            style={{
              float: 'left',
              padding: '0',
              fontSize: '18px',
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontWeight: '400',
              lineHeight: '1',
              letterSpacing: '0.00938em'
            }}>
            {this.object.training_title}
          </span>
        </div>
      </div>
    );
}