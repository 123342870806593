import DateFnsUtils from "@date-io/date-fns";
import { Grid, TextField } from "@material-ui/core";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers/";
import React from "react";
import Img from "react-fix-image-orientation";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GOOGLE_CHALLENGE_PHOTOS_URL } from "../../../constants";
import DeleteButton from "../buttons/DeleteButton";
import SaveButton from "../buttons/SaveButton";

export default function ContentDescription(props) {
  this.editionTimeout = null;
  // Object to Render
  this.object = props.object;
  //Functions
  this.updateChallengePhoto = props.updateObject;
  this.saveChallengePhoto = props.saveObject;
  this.deleteChallengePhoto = props.deleteObject;

  this.onChange = (value, field, challenge_photo) => {
    challenge_photo[field] = value;
    this.updateChallengePhoto(challenge_photo, true);
  };

  this.onChangeTextField = (field, challenge_photo) => (event) => {
    let value = event && event.target ? event.target.value : null;
    challenge_photo[field] = value;
    this.updateChallengePhoto(challenge_photo, true);
  };

  this.onClickSaveChallengePhoto = (challenge_photo) => {
    this.saveChallengePhoto(challenge_photo);
  };

  this.onClickDeleteChallengePhoto = (challenge_photo) => {
    if (window.confirm("Tem certeza que deseja remover esse item?")) {
      this.deleteChallengePhoto(challenge_photo);
    }
  };

  this.returnChallengePhotos = () => {
    let challenge_photos = this.object.challenge_photos;
    //console.log("challenge_photos",challenge_photos)

    return challenge_photos.map((challenge_photo) => {
      return (
        <div style={{height: "80%", width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "100%",
              alignItems: "center",
              padding: "0 20px 0 20px",
            }}
          >
            <Grid container spacing={1} style={{ flex: 1 }}>
              <Grid item xs={5}>
                <div style={{ height: "13vw", alignItems: "center" }}>
                  <Img
                    style={{
                      height: "12vw",
                      width: "80%",
                      objectFit: "contain",
                    }}
                    src={
                      GOOGLE_CHALLENGE_PHOTOS_URL +
                      challenge_photo.challenge_photo_filename
                    }
                  />
                </div>
              </Grid>
              <Grid xs={7} item>
                <Grid spacing={2} container>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "80%" }}
                      label={"Id da Imagem"}
                      value={challenge_photo?.id_challenge_photo}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      style={{
                        border: "0px",
                        color: "black",
                        textDecoration: "none",
                      }}
                    >
                      <DatePicker
                        label="Data de Envio"
                        value={challenge_photo?.created_date}
                        style={{ textDecoration: "none", width: "80%" }}
                        format="dd/MM/yyyy"
                        disabled
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "80%" }}
                      label={"Nome do Usuário"}
                      value={challenge_photo?.user?.user_name}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "80%" }}
                      label={"Id do Usuário"}
                      value={challenge_photo?.user?.id_user}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "80%" }}
                      label={"Nome da loja"}
                      value={ (challenge_photo.user &&  challenge_photo.user.store && challenge_photo.user.store.store_name) ? challenge_photo.user?.store.store_name :'Não informado'} 
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "80%" }}
                      label={"Estado da loja"}
                      value={ (challenge_photo.user && challenge_photo.user.store && challenge_photo.user.store.state) ? challenge_photo.user?.store.state :'Não informado'}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <ThumbUpIcon
                          onClick={() => {
                            this.onChange(
                              1,
                              "challenge_photo_review",
                              challenge_photo
                            );
                          }}
                          style={{
                            color:
                              challenge_photo.challenge_photo_review === 1
                                ? "green"
                                : "black",
                          }}
                        ></ThumbUpIcon>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <ThumbDownIcon
                          onClick={() => {
                            this.onChange(
                              0,
                              "challenge_photo_review",
                              challenge_photo
                            );
                          }}
                          style={{
                            color:
                              challenge_photo.challenge_photo_review === 0
                                ? "red"
                                : "black",
                          }}
                        ></ThumbDownIcon>
                      </div>
                      <div style={{ flex: 1 }} />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: "90%" }}
                      multiline
                      rowsMax={2}
                      inputProps={{ maxLength: 150 }}
                      label={"Observações(Máximo de Caracteres:150)"}
                      value={challenge_photo.challenge_photo_note}
                      onChange={this.onChangeTextField(
                        "challenge_photo_note",
                        challenge_photo
                      )}
                    />
                  </Grid>
                  <Grid xs={12} item justifyContent="center">
                    <DeleteButton
                        style={{background: "#CC0B0B" }}
                        onClickDelete={() => {
                          this.onClickDeleteChallengePhoto(challenge_photo);
                        }}
                      />
                      <SaveButton
                        onClickSave={() => {
                          this.onClickSaveChallengePhoto(challenge_photo);
                        }}
                        ref={(ref) => {
                          this.saveButton = ref;
                        }}
                      />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    });
  };

  return (
    <PerfectScrollbar style={{ height: 700 }}>
      {this.returnChallengePhotos()}
    </PerfectScrollbar>
  );
}
