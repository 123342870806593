import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import GenericAdminScreen from "../components/generic_screens/GenericAdminScreen";
import ContentFormAdminScreenOLD from "../components/generic_screens/ContentFormAdminScreenOLD";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PerfectScrollbar from "react-perfect-scrollbar";
import SlidingPane from "react-sliding-pane";
import {
  Button,
  Card,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateTimePicker } from "@material-ui/pickers/";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

// import ContentFormAdminScreen from "../components/generic_screens/ContentFormAdminScreen";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";

// DB Models
import Content from "../../models/Content";
import Form from "../../models/Form";
import Role from "../../models/Role";
import ModalFormResearch from "../components/modals/ModalFormResearch";

import ContentFormCard from "../components/cards/ContentFormCardNew";
import ContentDescriptionFormResearch from "../components/description_cards/ContentDescriptionFormResearch";
import Spinner from "../components/buttons/Spinner";
import SearchTextFieldWithFilter from "../components/text_fields/SearchTextFieldWithFilter";

function AdminContentFormScreen(props) {
  const [forms, setForms] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chosenForm, setChosenForm] = useState({});
  const [openModalForm, setOpenModalForm] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [maxEl, setMaxEl] = useState(0);
  const [fieldsToFilter, setFieldsToFilter] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const PAGE_INTERVAL = 20;
  const [minElPage, setMinElPage] = useState(1);
  const [maxElPage, setMaxElPage] = useState(1);
  const EXCEL_TYPE =
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setMinElPage((curPage - 1) * PAGE_INTERVAL + 1);
    setMaxElPage(
      curPage * PAGE_INTERVAL > maxEl ? maxEl : curPage * PAGE_INTERVAL
    );
  }, [curPage]);

  const fetchData = async () => {
    try {
      let forms = await Content.getResearchesNew();
      let roles = await Role.query();
      let roleOptionsDb = [];
      if (roles && roles.length > 0) {
        roleOptionsDb = roles.map((role) => {
          let roleOption = {
            value: role.id_role,
            label: role.role_name,
          };
          return roleOption;
        });
      }
      let maxElObj = forms.length;
      setMaxEl(forms.length);
      setMinElPage((curPage - 1) * PAGE_INTERVAL + 1);
      setMaxElPage(
        curPage * PAGE_INTERVAL > maxElObj ? maxElObj : curPage * PAGE_INTERVAL
      );
      setForms(forms);
      setRoleOptions(roleOptionsDb);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const addForm = async () => {
    let contentObj = {};
    try {
      let newContent = new Content();
      newContent.content = {
        id_app: localStorage.getItem("ID_APP"),
        is_challenge: 1,
        content_type: 3,
      };

      let newForm = new Form();
      newForm = {
        form_title: null,
        form_type: 3,
        id_app: localStorage.getItem("ID_APP"),
        forms_questions: [
          {
            form_question: null,
            form_question_type: 1,
          },
        ],
      };
      newContent.content.form = newForm;

      contentObj = await newContent.saveFormResearchNew();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }

    let contentList = forms;
    contentList.push(new Content(contentObj[0]));
    setForms(contentList);
    setChosenForm(new Content(contentObj[0]));
    setOpenModalForm(true);
  };

  const previousPage = () => {
    let curPageObj = curPage;
    curPageObj--;
    if (curPageObj >= 1) {
      setCurPage(curPageObj);
    }
  };

  const nextPage = () => {
    let maxElObj = maxEl;
    let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);
    let curPageObj = curPage;
    if (curPageObj < maxPage) {
      curPageObj++;
      setCurPage(curPageObj);
    }
  };

  const openFormDialog = (form) => {
    setOpenModalForm(true);
    setChosenForm(form);
  };

  const closeChosenForm = () => {
    setOpenModalForm(false);
    setChosenForm(null);
  };

  const onClickDownloadAnswers = async (event, object) => {
    event.stopPropagation();
    await Content.getexcel(object.id_content.toString())
      .then((data) => {
        let filename = object.form_filename;
        let blob = new Blob([data], { type: EXCEL_TYPE });
        FileSaver.saveAs(blob, filename);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteForm = (formObj) => {
    let formList = forms;
    let position = formList.findIndex((obj) => {
      return obj.id_content === formObj.id_content;
    });
    try {
      formList[position].remove();
      formList.splice(position, 1);
      let maxElObj = formList.length;
      setMaxEl(formList.length);
      setMaxElPage(
        curPage * PAGE_INTERVAL > maxElObj ? maxElObj : curPage * PAGE_INTERVAL
      );

      setForms(formList);
      toast.success("Formulário removido com sucesso!");
      closeChosenForm();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao remover formulário! Tente mais tarde..");
    }
  };

  const filterList = (object) => {
    if (fieldsToFilter && fieldsToFilter.length > 0) {
      for (let i = 0; i < fieldsToFilter.length; i++) {
        let filterObj = fieldsToFilter[i];
        // Check if there are filtered values
        if (
          filterObj.filter_type !== "IGNOREFRONT" &&
          (filterObj.filter_type === "DATE" ||
            (filterObj.selectedValues && filterObj.selectedValues.length > 0))
        ) {
          let objectValue = object[filterObj.parameter];
          let objectValueArray = [];

          // If parameter has dots, dive into object's hierarchy
          if (filterObj.parameter.indexOf(".") > 0) {
            let parameterList = filterObj.parameter.split(".");
            objectValue = object[parameterList[0]];
            if (objectValue instanceof Array) {
              for (let k = 0; k < objectValue.length; k++) {
                for (let j = 1; j < parameterList.length; j++) {
                  if (!objectValue) break;
                  if (objectValue[k])
                    objectValueArray.push(objectValue[k][parameterList[j]]);
                }
              }
            } else {
              for (let j = 1; j < parameterList.length; j++) {
                if (!objectValue) break;
                objectValueArray.push(objectValue[parameterList[j]]);

                if (j === parameterList.length - 1) {
                  break;
                }
              }
            }
          }
          if (objectValueArray.length > 0) {
            objectValue = objectValueArray;
          }
          if (filterObj.filter_type === "DATE") {
            if (
              filterObj.from_date &&
              new Date(objectValue) < filterObj.from_date
            )
              return false;
            if (filterObj.to_date && new Date(objectValue) > filterObj.to_date)
              return false;
          } else {
            let isInFilter;
            // Searches object value in array of filtered values
            if (objectValue instanceof Array) {
              isInFilter = objectValue.filter((value) => {
                return filterObj.selectedValues.find((filterValue) => {
                  if (
                    !filterValue ||
                    filterValue === "null" ||
                    filterValue === null ||
                    filterValue === 0 ||
                    filterValue === "0" ||
                    filterValue === "undefined" ||
                    filterValue === undefined
                  ) {
                    return !objectValue;
                  }
                  return filterValue === value;
                });
              });
            } else {
              isInFilter = filterObj.selectedValues.find((filterValue) => {
                if (
                  !filterValue ||
                  filterValue === "null" ||
                  filterValue === null ||
                  filterValue === 0 ||
                  filterValue === "0" ||
                  filterValue === "undefined" ||
                  filterValue === undefined
                ) {
                  return !objectValue;
                }
                return filterValue === objectValue;
              });
            }
            if (isInFilter instanceof Array) {
              if (isInFilter.length == filterObj.selectedValues.length) {
                for (let i = 0; i < isInFilter.length; i++) {
                  if (
                    !filterObj.selectedValues.some(
                      (value) => value == isInFilter[i]
                    )
                  ) {
                    return false;
                  }
                }
              } else {
                return false;
              }
            }
            // Check if value is in filter array.
            // Sometimes, filter array has 0 number, so we need to check explicitly for that
            else if (!isInFilter && isInFilter !== 0) return false;
          }
        }
      }
    }

    if (textFilter && textFilter !== "") {
      if (
        JSON.stringify(Object.values(object))
          .toUpperCase()
          .indexOf(textFilter.toUpperCase()) < 0
      )
        return false;
    }

    return true;
  };

  const searchText_onClickFilter = () => {
    Content.filters().then((filters) => {
      if (fieldsToFilter && fieldsToFilter.length > 0) {
        let fieldsToFilterObj = fieldsToFilter;
        for (let i = 0; i < fieldsToFilterObj.length; i++) {
          let filterObj = fieldsToFilterObj[i];
          let newFilterObj = filters.find((el) => {
            return el.parameter === filterObj.parameter;
          });
          newFilterObj.selectedValues = filterObj.selectedValues;
          newFilterObj.selectedOptions = filterObj.selectedOptions;
          newFilterObj.from_date = filterObj.from_date;
          newFilterObj.to_date = filterObj.to_date;
        }
      }

      let maxEl = forms.filter(filterList).length;
      let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

      setFieldsToFilter(filters);
      setMaxEl(maxEl);
      setMaxElPage(maxPage);
      setShowFilter(true);
    });
  };

  const refreshObjects = async (text) => {
    //setIsLoading(true);
    let fieldsToFilterObj = fieldsToFilter;
    let filterObjAPI = {};
    for (let i = 0; i < fieldsToFilterObj.length; i++) {
      let filterObj = fieldsToFilterObj[i];
      if (filterObj.filter_type === "DATE") {
        if (filterObj.from_date)
          filterObjAPI[filterObj.parameter + "_from"] = filterObj.from_date;
        if (filterObj.to_date)
          filterObjAPI[filterObj.parameter + "_to"] = filterObj.to_date;
      } else if (filterObj.filter_type === "NUMERIC") {
        filterObjAPI[filterObj.parameter + "_operator"] = filterObj.operator
          ? filterObj.operator
          : undefined;
        filterObjAPI[filterObj.parameter + "_number"] =
          filterObj.number != null ? filterObj.number : undefined;
      } else if (
        filterObj.selectedValues &&
        filterObj.selectedValues.length > 0
      ) {
        filterObjAPI[filterObj.parameter] = `('${filterObj.selectedValues.join(
          "','"
        )}')`;
      }
    }

    filterObjAPI["offset"] = (curPage - 1) * PAGE_INTERVAL;
    filterObjAPI["interval"] = PAGE_INTERVAL;
    if (text) {
      filterObjAPI["textFilter"] = text;
    }
    if (filterObjAPI) {
      try {
        /*let formsObj = forms.filter(filterList);
        let maxElObj = formsObj.length;
        setMaxEl(formsObj.length);
        setMinElPage((curPage - 1) * PAGE_INTERVAL + 1);
        setMaxElPage(
          curPage * PAGE_INTERVAL > maxElObj
            ? maxElObj
            : curPage * PAGE_INTERVAL
        );
        //setForms(forms);*/
        setTextFilter(text);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const searchText_onSearch = (event) => {
    setTextFilter(event);

    setTimeout(() => {
      refreshObjects(event);
    }, 1000);
  };

  return isLoading ? (
    <Spinner
      margin="13px"
      texto={"Carregando..."}
      border={"#9BB3D4"}
      top={"#1D2D44"}
    />
  ) : (
    <>
      {openModalForm && chosenForm ? (
        <ModalFormResearch
          // department={ticketsDepartments.department.ticketDepartamentType}
          open={openModalForm}
          onClose={closeChosenForm}
          roleOptions={roleOptions}
          form={chosenForm}
          deleteForm={deleteForm}
        />
      ) : null}

      <div
        style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "10px 30px 10px 20px",
          }}
        >
          <div style={{ paddingRight: "10px" }}>
            <Button variant="contained" onClick={() => addForm()}>
              <AddCircleIcon style={{ marginRight: "8px" }} /> {"Pesquisa"}
            </Button>
          </div>

          <div style={{ paddingRight: "10px" }}>
            <Button
              variant="contained"
              onClick={() => {
                setIsLoading(true);
                fetchData();
              }}
            >
              <i
                className="fa fa-fw fa-refresh"
                style={{ fontSize: "1.75em", verticalAlign: "middle" }}
              />
            </Button>
          </div>
          <div style={{ flex: "1" }}>
            <SearchTextFieldWithFilter
              placeholder={"Título da pesquisa"}
              onSearch={(event) => searchText_onSearch(event)}
              onClickFilter={() => searchText_onClickFilter()} //searchText_onClickFilter}
            ></SearchTextFieldWithFilter>
          </div>
        </div>
        <div style={{ flex: "1" }}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                position: "relative",
                display: forms ? "flex" : "none",
              }}
            >
              <Grid container>
                <Grid xs={12} style={{ height: "82vh" }}>
                  <PerfectScrollbar
                    style={{
                      width: "100%",
                      height: "82vh",
                      overflow: "hidden",
                      position: "absolute",
                    }}
                    options={{ suppressScrollX: "true" }}
                  >
                    {forms
                      .filter(filterList)
                      .map((object, index) => {
                        return (
                          <div
                            key={object.id_content}
                            style={{
                              marginBottom: "5px",
                              marginLeft: "20px",
                              marginRight: "20px",
                            }}
                          >
                            <ContentFormCard
                              object={object}
                              onClick={() => openFormDialog(object)}
                              onClickDownloadAnswers={(event) =>
                                onClickDownloadAnswers(event, object)
                              }
                            />
                          </div>
                        );
                      })
                      .slice(
                        (curPage - 1) * PAGE_INTERVAL,
                        curPage * PAGE_INTERVAL
                      )}
                  </PerfectScrollbar>
                </Grid>
                <Grid xs={12} style={{ marginBottom: "10px" }}>
                  <Button
                    variant="contained"
                    style={{ margin: "0px 10px", fontWeight: "bolder" }}
                    onClick={() => previousPage()}
                  >
                    &lt;
                  </Button>
                  Exibindo {minElPage}-{maxElPage} de {maxEl}
                  <Button
                    variant="contained"
                    style={{ margin: "0px 10px", fontWeight: "bolder" }}
                    onClick={() => nextPage()}
                  >
                    &gt;
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <SlidingPane
        isOpen={showFilter}
        title="Filtros"
        from="right"
        width="40vw"
        onRequestClose={() => setShowFilter(false)}
      >
        <PerfectScrollbar
          style={{
            width: "100%",
            height: "87vh",
            overflow: "hidden",
            position: "absolute",
          }}
          options={{ suppressScrollX: "true" }}
          /*ref={(ref) => {
              _scrollBarRef = ref;
            }}*/
        >
          {fieldsToFilter
            ? fieldsToFilter.map((el) => {
                el.operator = el.operator
                  ? el.operator
                  : el.possibleValues && el.possibleValues[0]
                  ? el.possibleValues[0].value
                  : null;

                const handleChange = (selectedOptions) => {
                  let selectedValues = selectedOptions.map((obj) => {
                    return obj.value;
                  });

                  let fieldsToFilterObj = fieldsToFilter;
                  let fieldObj = fieldsToFilterObj.find((filterObj) => {
                    return filterObj.parameter === el.parameter;
                  });

                  fieldObj.selectedValues = selectedValues;
                  fieldObj.selectedOptions = selectedOptions;

                  //if (onFilterReload) refreshObjects();
                  refreshObjects();

                  let maxEl = forms.filter(filterList).length;
                  let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

                  setMaxEl(maxEl);
                  setMaxElPage(maxPage);
                  setFieldsToFilter(fieldsToFilterObj);
                };

                const handleDateChange = (date_field) => (event) => {
                  event = event ? new Date(event) : undefined;

                  let fieldsToFilterObj = fieldsToFilter;
                  let fieldObj = fieldsToFilterObj.find((filterObj) => {
                    return filterObj.parameter === el.parameter;
                  });

                  fieldObj[date_field] = event;

                  //if (onFilterReload) refreshObjects();
                  refreshObjects();

                  let maxEl = forms.filter(filterList).length;
                  let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);
                  setMaxEl(maxEl);
                  setMaxElPage(maxPage);
                };

                const handleNumericChange = (numeric_field) => (event) => {
                  let fieldsToFilterObj = fieldsToFilter;
                  let fieldObj = fieldsToFilterObj.find((filterObj) => {
                    return filterObj.parameter === el.parameter;
                  });

                  fieldObj[numeric_field] = event.target.value;

                  //if (onFilterReload) refreshObjects();
                  refreshObjects();

                  let maxEl = forms.filter(filterList).length;
                  let maxPage = Math.ceil(maxEl / PAGE_INTERVAL);

                  setMaxEl(maxEl);
                  setMaxElPage(maxPage);
                  setFieldsToFilter(fieldsToFilterObj);
                };

                return (
                  <Grid container>
                    <Grid item xs={5}>
                      <p>{el.title}</p>
                    </Grid>

                    {el.filter_type === "DATE" ? (
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid xs={6}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              style={{
                                border: "0px",
                                color: "black",
                                textDecoration: "none",
                              }}
                            >
                              <DateTimePicker
                                clearable
                                label="De"
                                value={el.from_date}
                                style={{
                                  width: "100%",
                                  textDecoration: "none",
                                }}
                                format="dd/MM/yyyy"
                                onChange={handleDateChange("from_date")}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid xs={6}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              style={{
                                border: "0px",
                                color: "black",
                                textDecoration: "none",
                              }}
                            >
                              <DateTimePicker
                                clearable
                                label="Até"
                                value={el.to_date}
                                style={{
                                  width: "100%",
                                  textDecoration: "none",
                                }}
                                format="dd/MM/yyyy"
                                onChange={handleDateChange("to_date")}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : el.filter_type === "NUMERIC" ? (
                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={3}>
                            <TextField
                              id="comparison_operator"
                              select
                              value={el.operator}
                              defaultValue={el.operator}
                              onChange={handleNumericChange("operator")}
                            >
                              {el.possibleValues.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={6} style={{ paddingLeft: "30px" }}>
                            <TextField
                              id="comparison_number"
                              type="number"
                              value={el.number}
                              defaultValue={el.number ? el.number : undefined}
                              onChange={handleNumericChange("number")}
                              inputProps={{ min: "0", max: "100", step: "1" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        <ReactMultiSelectCheckboxes
                          style={{ dropdownButton: { width: "100%" } }}
                          options={el.possibleValues}
                          defaultValue={el.selectedOptions}
                          onChange={handleChange}
                        />
                      </Grid>
                    )}
                  </Grid>
                );
              })
            : null}
        </PerfectScrollbar>
      </SlidingPane>
    </>
  );
}

export default AdminContentFormScreen;
