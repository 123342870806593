export const HOME_SCREEN = "home";
export const ADMIN_APP_SCREEN = "settings/app";
export const ADMIN_BRAND_SCREEN = "settings/brands";
export const ADMIN_CHALLENGE_PHOTO_SCREEN = "data/challenge_photo";
export const ADMIN_INVOICE_SCREEN = "data/invoices";
export const ADMIN_INVOICE_MASK_SCREEN = "data/invoice_mask";
export const ADMIN_CONTENT_ENGAGEMENT_SCREEN = "data/engagement_dashboard";
export const ADMIN_PRODUCT_SCREEN = "settings/products";
export const ADMIN_PUSH_NOTIFICATIONS_SCREEN = "push_notification";
export const ADMIN_ROLE_SCREEN = "settings/role";
export const ADMIN_ROLENEW_SCREEN = "settings/rolenew";
export const ADMIN_SUBJECT_SCREEN = "settings/subject";
export const ADMIN_SELLING_REGION_SCREEN = "settings/selling_region";
export const ADMIN_STORE_DASHBOARD_SCREEN = "data/store_dashboard";
export const ADMIN_DATA_PANEL_SCREEN = "data/panel";
export const ADMIN_DATA_PANEL_SCREEN_CELLAIRIS = "data/panel_cellairis";
export const ADMIN_DATA_PANEL_SCREEN_CAFE = "data/panel_cafe_cultura";
export const ADMIN_DATA_PANEL_SCREEN_MILBIJUS = "data/panel_mil_bijus";
export const ADMIN_STORE_SCREEN = "settings/stores";
export const ADMIN_USER_SCREEN = "settings/users";
export const ADMIN_AUTHOR_SCREEN = "settings/authors";
export const CONTENT_SCREEN = "contents";
export const FORM_SCREEN = "forms";
export const TASK_SCREEN = "tasks";
export const MARKETING_SCREEN = "marketing";
export const MESSAGE_SCREEN = "messages";
export const NEWS_SCREEN = "news";
export const CAMPAIGN_SCREEN = "newMarketing/campaigns";
export const CUPOM_SCREEN = "newMarketing/cupons";
export const FAQ_SCREEN = "faq";
export const MANUAL_SCREEN = "manual";
export const ADMIN_COMMUNICATION_CHANNEL_SCREEN = "communication_channel";
export const ZEN_DESK = "zen_desk";
export const ADMIN_TICKET_SCREEN = "tickets";
export const ADMIN_TICKET_DEPARTMENT_SCREEN = "tickets_departments";
export const CHECKLIST_SCREEN = "checklists";
export const INBOX_SCREEN = "inbox";
export const CHALLENGE_PHOTO = "challenge_photo";
export const VISUAL_MERCHANDISING = "visual_merchandising";

export const GOOGLE_IMAGES_URL =
  "https://storage.googleapis.com/insidethebox-invoice/";
export const GOOGLE_CHALLENGE_PHOTOS_URL =
  "https://storage.googleapis.com/insidethebox-challenge-image/";
