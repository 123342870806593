import GenericModel from './generic_model/GenericModel'
import { serialize } from '../utils'
const API = '/classes_we';

class ClassWe extends GenericModel {
    API = API;
    static API = API;

    static addOrUpdateProduct = async function (requestBody) {
  
        let authHeader = null;
        try {
          authHeader = await GenericModel.insertAuthHeader();
    
          const response = await fetch(API + '/product', {
            headers: {
                ...authHeader,
                'Content-Type': 'application/json'
            },
            method: "post",
            body: JSON.stringify(requestBody)
          });
    
          const body = await response.json();
    
          if (response.status !== 200) throw new Error(body.message);
    
          return body;
        } catch (err) {
            console.log({err})
          throw Error(err);
        }
    };

    static getInappproducts = async function (query) {
      
        // let queryString = serialize(query);
  
        // let authHeader = null;
        // try {
        //   authHeader = await GenericModel.insertAuthHeader();

        //   console.log(API + '/all-products?')
    
        //   const response = await fetch(API + '/all-products?' + queryString, {
        //     headers: authHeader
        //   });

        //   console.log({response});
    
        //   const body = await response.json();

        //   console.log({body})
    
        //   if (response.status !== 200) throw new Error(body.message);
    
        //   return body;
        // } catch (err) {
        //     console.log({err})
        //   throw Error(err);
        // }
    };

    static statistics = async function (query) {

        let queryString = serialize(query);

        let authHeader = null;
        try {
            authHeader = await GenericModel.insertAuthHeader();

            const response = await fetch(API + '/statistics?' + queryString, {
                headers: authHeader
            });

            const body = await response.json();

            if (response.status !== 200) throw new Error(body.message);

            return body;
        } catch (err) {
            throw Error(err);
        }
    };

    static getexcel = async function (id) {

        let authHeader = null;
        try {
            authHeader = await GenericModel.insertAuthHeader();

            const response = await fetch(API + '/getexcel/' + id, {
                headers: authHeader
            });

            const body = await response.blob();

            if (response.status !== 200) throw new Error(body.message);

            return body;
        } catch (err) {
            throw Error(err);
        }
    };

    static filters = async function (query) {
        let queryString = serialize(query);

        let authHeader = null;

        try {
            authHeader = await GenericModel.insertAuthHeader();
            const response = await fetch(this.API + "/filters?" + queryString, {
                headers: authHeader,
            });

            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body;
        } catch (err) {
            throw Error(err);
        }

    };

}

export default ClassWe;