import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning'; 
import ReportIcon from '@mui/icons-material/Report';

export const showToast = (type, message) => {
  const options = {
    position: "top-right",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  }

  if(type === "success"){
    toast.success(message, {
      ...options,
      icon: <CheckCircleIcon />,
      style: {
        backgroundColor: "#4CAF50",
        color: "#FAFAFA"
      }
    })
  }
  
  if(type === "warn"){
    toast.warn(message, {
      ...options,
      icon: <WarningIcon />,
      style: {
        backgroundColor: "#F9A825",
        color: "#FAFAFA"
      }
    })
  }
  
  if(type === "error"){
    toast.error(message, {
      ...options,
      icon: <ReportIcon />,
      style: {
        backgroundColor: "#B00020",
        color: "#FAFAFA"
      }
    });
  } 
}