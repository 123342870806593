import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
// DB Models
import ClassWeAuthor from '../../models/ClassWeAuthor';
import Role from '../../models/Role';
import AuthorCard from '../components/cards/AuthorCard';
import AuthorDescription from '../components/description_cards/AuthorDescription';
import Utils from '../../lib/utils';
import { toast } from 'react-toastify';
class AdminAuthorScreen extends Component {
  editionTimeout = null;

  state = {
    authors: [],
    relations: [],
  };

  // TEXTS
  searchText_placeholder = 'Nome do autor';
  addButton_placeholder = 'Autor';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    this.reloadAuthors();
  }

  refreshAuthors = async (parameters) => {
    this.reloadAuthors(parameters);
  };

  reloadAuthors = async (parameters) => {
    try {
      const [authors, roles] = await Promise.all([
        ClassWeAuthor.query(parameters),
        Role.query(),
      ]);
      this.setState({ authors: authors });
      let relations = this.state.relations;
      relations[0] = roles;
      this.setState({ roles: relations });
    } catch (err) {
      console.warn(err);
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    }
  };

  addAuthor = async () => {
    let newAuthor = new ClassWeAuthor();
    let authorObj = {};
    try {
      authorObj = await newAuthor.saveAuthor();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let authorList = this.state.authors;
    authorList.push(authorObj);
    this.setState({ authors: authorList });
    return authorObj;
  };

  updateAuthor = (authorObj, field, value, force) => {
    let authorList = this.state.authors;
    let position = authorList.findIndex((obj) => {
      return obj.id_author === authorObj.id_author;
    });
    let authorToUpdate = authorList.find((obj) => {
      return obj.id_author === authorObj.id_author;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          authorToUpdate[prop] = value[prop];
        }
      }
    } else if (field.indexOf('.') > 0) {
      let hierarchy = field.split('.');
      let obj = authorToUpdate;
      for (let i = 0; i < hierarchy.length; i++) {
        if (i === hierarchy.length - 1) {
          obj[hierarchy[i]] = value;
          break;
        }
        obj = obj[hierarchy[i]];
      }
    } else {
      authorToUpdate[field] = value;
    }
    this.setState({ authors: authorList });
    return authorList[position];
  };

  saveAuthor = async (authorObj) => {
    let authorList = this.state.authors;
    let position = authorList.findIndex((obj) => {
      return obj.id_author === authorObj.id_author;
    });
    let authorToUpdate = authorList.find((obj) => {
      return obj.id_author === authorObj.id_author;
    });

    if (authorToUpdate != null) {
      authorToUpdate
        .save()
        .then((authorObj) => {
          // Author upload to server worked
          authorList[position] = authorObj;
          this.setState({ authors: authorList });
          toast.success('Autor salvo com sucesso!');
        })
        .catch((err) => {
          console.error(err);
          toast.error('Erro ao salvar usuário!');
        });
    }
  };

  deleteAuthor = (authorObj) => {
    let authorList = this.state.authors;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < authorList.length; i++) {
      if (authorObj.id_author === authorList[i].id_author) {
        authorList[i].remove();
        authorList.splice(i, 1);
        break;
      }
    }

    this.setState({ authors: authorList });
  };

  render() {
    return (
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.authors}
        objectCard={AuthorCard}
        objectDescription={AuthorDescription}
        addObject={this.addAuthor}
        updateObject={this.updateAuthor}
        saveObject={this.saveAuthor}
        deleteObject={this.deleteAuthor}
        refreshObjects={this.refreshAuthors}
        relations={this.state.relations}
        objectIdName={'id_author'}
        objectModel={ClassWeAuthor}
      />
    );
  }
}

export default AdminAuthorScreen;
