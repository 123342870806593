import axios from 'axios';

import GenericModel from './generic_model/GenericModel'
const API = '/user';

class User extends GenericModel {
  API = API;
  static API = API;

  static me = async function () {

      let authHeader = null;
      try {
          authHeader = await GenericModel.insertAuthHeader();

          const response = await fetch(API + '/me', {
              headers: authHeader
          });

          const body = await response.json();

          if (response.status !== 200) throw new Error(body.message);

          return new GenericModel(body, API);
      } catch (err) {
          throw Error(err);
      }
  };

  static searchPhoneNumber = async function (phoneNumber) {
      let queryString = "";
      if (phoneNumber) {
        queryString = phoneNumber;
      }
  
      let authHeader = null;
      try {
        let that = this;
        authHeader = await GenericModel.insertAuthHeader();
        const response = await fetch(this.API + "/search_number/" + phoneNumber, {
          headers: authHeader,
        });
        const body = await response.json();
  
        if (response.status !== 200) throw Error(body.message);
  
        let ans = {};
  
        function mountObjArray(elementsArray) {
          let result = [];
  
          for (let i = 0; i < elementsArray.length; i++) {
            result.push(new GenericModel(elementsArray[i], that.API));
          }
  
          return result;
        }
  
        if (body && body.elements) {
          for (let prop in body) {
            if (body.hasOwnProperty(prop) && prop !== "elements") {
              ans[prop] = body[prop];
            }
          }
          ans.elements = mountObjArray(body.elements);
        } else if (body instanceof Array) {
          ans = mountObjArray(body);
        } else {
          ans = new GenericModel(body, that.API);
        }
  
        return ans;
      } catch (err) {
        throw Error(err);
      }
    };
    
    static async listAll(search){
      try {
        const authHeader = await GenericModel.insertAuthHeader({
          Accept: "application/json",
          "Content-Type": "application/json",
        });
  
        const response = await axios.get(API + `/select_full_user?search=${search}`, {
          headers: authHeader
        });

        return response.data;
      } catch (err) {
        throw Error(err);
      }
    }
    


  static getModuleRole = async ( module_name) => {

    try {
      const authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      const response = await axios.get( API+`/placeholder_invoice_roles_web`, {
      // const response = await axios.get( API+`/placeholder_invoice_roles_web/${module_name}`, {
        headers: authHeader
      });

      return response.data;
    } catch (err) {
      throw Error(err);
    }
  }


  

}

export default User;