import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import Subject from '../../models/TopicOfInterest';
import User from '../../models/User';
import Brand from '../../models/Brand';
import Selling_Region from '../../models/Selling_Region';
import SubjectCard from '../components/cards/SubjectCard';
import SubjectDescription from '../components/description_cards/SubjectDescription';
import Product from '../../models/Product';
import Spinner from '../components/buttons/Spinner';

class AdminSubjectScreen extends Component {
  editionTimeout = null;

  state = {
    subjects: [],
    relations: [],
    loading:true
  };

  // TEXTS
  searchText_placeholder = 'Nome do Tema';
  addButton_placeholder = 'Tema';

  // FUNCTIONS
  // Load all data from DB
  componentDidMount() {
    Subject.query()
      .then((subjects) => {
        this.setState({ subjects: subjects });
        this.setState({ loading: false });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
  }

  addSubject = async () => {
    let newSubject = new Subject();
    let subjectObj = {};
    try {
      subjectObj = await newSubject.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let subjectList = this.state.subjects;
    subjectList.push(subjectObj);
    this.setState({ subjects: subjectList });
    return subjectObj;
  };

  updateSubject = (subjectObj, field, value, force) => {
    this.setState({ loading: true });
    let that = this;
    let subjectList = this.state.subjects;
    let position = subjectList.findIndex((obj) => {
      return obj.id_topic === subjectObj.id_topic;
    });
    let subjectToUpdate = subjectList.find((obj) => {
      return obj.id_topic === subjectObj.id_topic;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          subjectToUpdate[prop] = value[prop];
        }
      }
    } else {
      subjectToUpdate[field] = value;
    }

    // Timeout: Wait 500 milliseconds before sending API POST call to Server
    if (this.editionTimeout) clearTimeout(this.editionTimeout);
    this.setState({ subjects: subjectList });
    this.setState({ loading: false });
    return subjectList[position];
  };

  deleteSubject = (subjectObj) => {
    let subjectList = this.state.subjects;

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < subjectList.length; i++) {
      if (subjectObj.id_topic === subjectList[i].id_topic) {
        subjectList[i].remove();
        subjectList.splice(i, 1);
        break;
      }
    }

    this.setState({ subjects: subjectList });
  };

  saveSubject = async (subjectObj) => {
    let subjectList = this.state.subjects;
    let position = subjectList.findIndex((obj) => {
      return obj.id_topic === subjectObj.id_topic;
    });
    let subjectToUpdate = subjectList.find((obj) => {
      return obj.id_topic === subjectObj.id_topic;
    });

    if (subjectToUpdate != null) {
      let subjectObj = subjectToUpdate;

      try {
        subjectObj = await subjectToUpdate.save();
        // Subject upload to server worked
        subjectList[position] = subjectObj;
        this.setState({ subjects: subjectList });
        toast.success('Tema salvo com sucesso!');
      } catch (err) {
       toast.error('Error ao salvar tema!');
        console.error(err);
      }
    }
  };

  render() {
    return (
      <> 
      
      {this.state.subjects.length===0 
      &&this.state.loading ===true
      ? <Spinner
       margin='13px' 
      texto={'carregando... '}
      border={'#9BB3D4'}
      top={'#1D2D44'}/>
      :
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.subjects}
        objectCard={SubjectCard}
        objectDescription={SubjectDescription}
        addObject={this.addSubject}
        saveObject={this.saveSubject}
        updateObject={this.updateSubject}
        deleteObject={this.deleteSubject}
        objectIdName={'id_topic'}
        objectModel={Subject}
      ></GenericAdminScreen>
  }</>
    );
  }
}

export default AdminSubjectScreen;
