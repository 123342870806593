import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen'
import { toast } from 'react-toastify';
// DB Models
import Invoice from '../../models/Invoice';
import InvoiceCard from '../components/cards/InvoiceCard';
import InvoiceDescription from '../components/description_cards/InvoiceDescription';
import Brand from '../../models/Brand';
import moment from "moment";
import Spinner from '../components/buttons/Spinner';


class AdminInvoiceScreen extends Component {
  editionTimeout = null;

  state = {
    invoices: [],
    relations: [],
    filters: [],
    loading:true,
    numberInvoices: 0,
  };

  // TEXTS
  searchText_placeholder = 'Código do Cupom Fiscal';
  addButton_placeholder = 'Cupom';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    Promise.all([
      Invoice.query({
        invoice_status: "('1. PENDENTE')",
        offset: 0,
        interval: 20,
      }),
      Brand.query(),
    ])
      .then((results) => {
        this.setState({
          invoices: results[0].elements,
          numberInvoices: results[0].countElements,
          relations: [results[1]],
          loading:false
        });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
      this.refreshInvoices({
        invoice_status: "('1. PENDENTE')",
        offset: 0,
        interval: 20,
      });
  }


  refreshInvoices = async (
    parameters 
  ) => {
    try {
      let invoiceResponse = await Invoice.query(parameters);

      this.setState({
        invoices: invoiceResponse.elements,
        numberInvoices: invoiceResponse.countElements,
      });
    } catch (err) {
      console.error(err);
    }
  };

  addInvoice = async () => {
    let newInvoice = new Invoice();
    let invoiceObj = {};
    try {
      invoiceObj = await newInvoice.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let invoiceList = this.state.invoices;
    invoiceList.push(invoiceObj);
    this.setState({ invoices: invoiceList });
    return invoiceObj;
  };

  saveInvoice = async (invoiceObj) => {
    let commit_time_inc = new Date() - invoiceObj.start_commit_time;
    invoiceObj.commit_time += commit_time_inc;
    let invoiceList = this.state.invoices;
    let position = invoiceList.findIndex((obj) => {
      return obj.id_invoice === invoiceObj.id_invoice;
    });
    let invoiceToUpdate = invoiceList.find((obj) => {
      return obj.id_invoice === invoiceObj.id_invoice;
    });

    if (invoiceToUpdate != null) {
      if (invoiceToUpdate.user.user_birthdate) {
        invoiceToUpdate.user.user_birthdate = moment(invoiceToUpdate.user.user_birthdate).format('YYYYMMDD');
      }
      let invoiceObj = invoiceToUpdate;

      try {
        invoiceObj.start_commit_time = new Date() / 1;
        invoiceObj = await invoiceToUpdate.save();
        // Invoice upload to server worked
        invoiceList[position] = invoiceObj;
        this.setState({ invoices: invoiceList });
       toast.success('Cupom salvo com sucesso!');
      } catch (err) {
       toast.error('Erro ao salvar cupom!');
        console.error(err);
      }
    }
  };

  updateInvoice = (invoiceObj, field, value, force) => {
    let that = this;
    let invoiceList = this.state.invoices;
    let position = invoiceList.findIndex((obj) => {
      return obj.id_invoice === invoiceObj.id_invoice;
    });
    let invoiceToUpdate = invoiceList.find((obj) => {
      return obj.id_invoice === invoiceObj.id_invoice;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          invoiceToUpdate[prop] = value[prop];
        }
      }
    } else {
      invoiceToUpdate[field] = value;
    }

    this.setState({ invoices: invoiceList });
    return invoiceList[position];
  };

  deleteInvoice = (invoiceObj) => {
    invoiceObj.start_commit_time = invoiceObj.start_commit_time / 1;

    let invoiceList = this.state.invoices;
    invoiceObj.start_commit_time = invoiceObj.start_commit_time / 1;


    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < invoiceList.length; i++) {
      if (invoiceObj.id_invoice === invoiceList[i].id_invoice) {
        invoiceList[i].remove();
        invoiceList.splice(i, 1);
        break;
      }
    }

    this.setState({ invoices: invoiceList });
  };

  render() {
    return (
      // dani
      <>
       {this.state.invoices.length===0 &&this.state.loading? <Spinner
        margin='13px' 
       texto={'carregando...'}
       border={'#9BB3D4'}
       top={'#1D2D44'}/>
       :
      <GenericAdminScreen
      searchText_placeholder={this.searchText_placeholder}
      addButton_placeholder={this.addButton_placeholder}
      objectList={this.state.invoices}
      objectCard={InvoiceCard}
      objectDescription={InvoiceDescription}
      addObject={this.addInvoice}
      saveObject={this.saveInvoice}
      updateObject={this.updateInvoice}
      deleteObject={this.deleteInvoice}
      refreshObjects={this.refreshInvoices}
      relations={this.state.relations}
      objectIdName={'id_invoice'}
      objectModel={Invoice}
      onFilterReload={true}
      numberElements={this.state.numberInvoices}
        />
     } 
      
      </>
    );
  }
}

export default AdminInvoiceScreen;
