import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import Skeleton from '@mui/material/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import MailIcon from '@mui/icons-material/Mail';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import ArchiveIcon from '@mui/icons-material/Archive';
import Badge from '@mui/material/Badge';
import { useHistory } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';

import AlertDialog from '../../../components/alert/ThemeAlertDialog';
import { CustomIconButton } from '../../../components/buttons/ThemeButtons';
import { CustomTooltip } from '../../../components/tooltip/ThemeTooltips';
import VisualMerchandising from '../../../../models/VisualMerchandising';
import { EmptyListFeedBack, EmptyListFeedBackOnSearch } from './EmptyListFeedBacks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFA",
    borderRadius: 8,
    cursor: 'pointer',
  },
}))(TableRow);


const StyledTableCell = withStyles((theme) => ({
  root: {
    border: 0,
    marginBottom: 24
  }
}))(TableCell);


function StatusBadge({ merchandising }) {
  const isFinished = merchandising.id_status === 4;

  return (
    <Chip
      label={isFinished ? "Concluído" : "Pendente"}
      style={{
        backgroundColor: isFinished ? "#DBFFDD" : "#FFE5A2",
        color: "#162233"
      }}
    />
  );
}

function VisualMerchandisingAnswersTable({ isLoading, data, isSearching, updateList}) {
  const [archiveAlertIsVisible, setArchiveAlertIsVisible] = useState(false);
  const [selectedVisualMerchandisingIdToArchive, setSelectedVisualMerchandisingIdToArchive] = useState(null);

  const classes = useStyles();
  const history = useHistory();

  function handleOpenArchiveAlert(visualMerchandisingId) {
    setSelectedVisualMerchandisingIdToArchive(visualMerchandisingId);
    setArchiveAlertIsVisible(true);
  }

  async function handleConfirmArchive() {
    try{
      const response = await VisualMerchandising.archive(selectedVisualMerchandisingIdToArchive);
      setArchiveAlertIsVisible(false);
      updateList();
    } catch(err){
      console.log(err); 
    }
  }

  function handleClickTableRow(idVisualMerchandising) {
    history.push(`visual_merchandising/answers/${idVisualMerchandising}`);
  }

  function haveNewMessages(visualMerchandising){
    if(visualMerchandising.waitingFeedback > 0 || visualMerchandising.newMessages > 0){
      return false;
    }

    return true;
  }

  if(data.length === 0 && !isSearching && !isLoading){
    return(
      <EmptyListFeedBack />
    );
  }

  if(isSearching && data.length === 0){
    return(
      <EmptyListFeedBackOnSearch />
    );
  }

  return (
    <>
      <AlertDialog
        title="Arquivar conteúdo"
        description="Ao arquivar, o franqueado deixará de visualizar o conteúdo por meio do aplicativo.
        Você tem certeza que deseja arquivar este conteúdo?"
        confirmTextButton="Arquivar conteúdo"
        handleClose={() => setArchiveAlertIsVisible(false)}
        handleConfirm={() => handleConfirmArchive()}
        isOpen={archiveAlertIsVisible}
      />

      <TableContainer>
        <Table size="small" style={{
          borderCollapse: 'separate',
          borderSpacing: '0 8px'
        }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="left">Título</StyledTableCell>
              <StyledTableCell align="left">Enviado para</StyledTableCell>
              <StyledTableCell align="left">Respostas</StyledTableCell>
              <StyledTableCell align="left">Aprovações</StyledTableCell>
              <StyledTableCell align="left">Situação</StyledTableCell>
              <StyledTableCell align="right">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              isLoading ? Array.from({ length: 10 }, (v, k) => k).map(element => (
                <StyledTableRow key={element}>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width={60} height={60} />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60} />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60} />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60} />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60} />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60} />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={60} />
                  </StyledTableCell>
                </StyledTableRow>
              ))
                : null
            }

            {!isLoading ? data.map((visualMerchandising) => (
              <StyledTableRow key={visualMerchandising.id_visual_merchandising}>
                <StyledTableCell onClick={() => handleClickTableRow(visualMerchandising.id_visual_merchandising)}>
                <Avatar 
                    alt="merchandising-image" 
                    src={visualMerchandising?.imageUrl}
                    className={classes.large} 
                    variant="rounded" 
                  >
                    <ImageIcon 
                      sx={{
                        fontSize: 32,
                      }}
                    />
                  </Avatar>
                </StyledTableCell>
                <StyledTableCell 
                align="left" 
                style={{ maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} 
                onClick={() => handleClickTableRow(visualMerchandising.id_visual_merchandising)}>
                  {visualMerchandising.title}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClickTableRow(visualMerchandising.id_visual_merchandising)}>
                  {visualMerchandising.storesSendCount !== null ? visualMerchandising.storesSendCount + " lojas" : "0 lojas"}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClickTableRow(visualMerchandising.id_visual_merchandising)}>
                  {visualMerchandising.answersCount !== null ? visualMerchandising.answersCount : 0}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClickTableRow(visualMerchandising.id_visual_merchandising)}>
                  {visualMerchandising.approvedCount !== null ? visualMerchandising.approvedCount : 0}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClickTableRow(visualMerchandising.id_visual_merchandising)}>
                  <StatusBadge
                    merchandising={visualMerchandising}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <CustomIconButton
                    onClick={() => handleClickTableRow(visualMerchandising.id_visual_merchandising)}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#FFE0BF',
                      }
                    }}
                  >
                    <Badge
                      invisible={haveNewMessages(visualMerchandising)}
                      variant="dot"
                      overlap='circular'
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: "#F28C1F",
                          border: '1px solid #FFF'
                        }
                      }}>
                      <MailIcon sx={{ color: haveNewMessages(visualMerchandising) ? "#979899" : "#F28C1F" }} />
                    </Badge>
                  </CustomIconButton>
                  <CustomTooltip title="Arquivar">
                    <CustomIconButton
                      onClick={() => handleOpenArchiveAlert(visualMerchandising.id_visual_merchandising)}
                    >
                      <ArchiveIcon />
                    </CustomIconButton>
                  </CustomTooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))
              : null
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default VisualMerchandisingAnswersTable;