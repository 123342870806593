import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import LoginForm from "../components/forms/LoginForm.js";
import AdminStore_DashboardScreen from "./AdminStore_DashboardScreen.js";
import AdminContentScreen from "./AdminContentScreen.js";
import AdminDataPanelScreen from "./AdminDataPanelScreen";
import AdminDataPanelScreenCellaris from "./AdminDataPanelScreenCellairis";
import AdminDataPanelScreenCafeCultura from "./AdminDataPanelScreenCafeCultura";
import AdminDataPanelScreenMilBijus from "./AdminDataPanelScreenMilBijus";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import * as Constants from "../../constants.js";
import { Link, withRouter } from "react-router-dom";
import App from "../../models/App";

class HomeScreen extends Component {
    state = {
        user: {},
        selected: Constants.HOME_SCREEN,
        expanded: false,
        id_app_selected: null,
        app_list: [],
    };

    constructor(props) {
        super(props);
        this.state.user = props.user;
        this.state.id_app_selected = props.current_app
            ? props.current_app.id_app
            : null;
        App.listApps()
            .then((app_list) => {
                this.setState({ app_list: app_list });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    // Ensures that updated state will be re-rendered!
    componentWillReceiveProps(nextProps) {
        this.setState({ user: nextProps.user });
    }

    render() {
        let id_app = localStorage.getItem("ID_APP");
        if (id_app == 1) {
            return this.state.user && this.state.user.phoneNumber ? (
                <AdminContentScreen />
            ) : (
                <LoginForm onLogin={this.props.onLogin} />
            );
        } else if (id_app == 11 || 12 || 13 || 14 || 15 || 21 || 22) {
            return this.state.user && this.state.user.phoneNumber ? (
                <AdminDataPanelScreen />
            ) : (
                <LoginForm onLogin={this.props.onLogin} />
            );
        } /*else if (id_app == 12) {
            return this.state.user && this.state.user.phoneNumber ? (
                <AdminDataPanelScreenCafeCultura />
            ) : (
                <LoginForm onLogin={this.props.onLogin} />
            );
        } else if (id_app == 13) {
            return this.state.user && this.state.user.phoneNumber ? (
                <AdminDataPanelScreenMilBijus />
            ) : (
                <LoginForm onLogin={this.props.onLogin} />
            );
        } else if (id_app == 14) {
            return this.state.user && this.state.user.phoneNumber ? (
                <AdminDataPanelScreenMilBijus />
            ) : (
                <LoginForm onLogin={this.props.onLogin} />
            );
        }*/
        else {
            return this.state.user && this.state.user.phoneNumber ? (
                <AdminStore_DashboardScreen />
            ) : (
                <LoginForm onLogin={this.props.onLogin} />
            );
        }
    }
}

export default HomeScreen;
