import React from 'react';
import {
  Grid,
  Paper,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import SaveButton from '../buttons/SaveButton';
import DeleteButton from '../buttons/DeleteButton';
import ArchiveButton from '../buttons/ArchiveButton';
import SearchableSelect from '../selectbox/SearchableSelect.js';
import { DateTimePicker } from '@material-ui/pickers/';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import DateFnsUtils from '@date-io/date-fns';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { CustomDropzone } from '../CustomDropzone';
import FormQuestionCard from '../cards/FormQuestionCard';
import Training from '../../../models/Training';
import Content from '../../../models/Content';
import ClassWeAuthor from '../../../models/ClassWeAuthor';
import Select from 'react-select';
import cloneDeep from 'lodash/cloneDeep';
import AddIcon from '@material-ui/icons/Add';
import AdminContentTrailScreen from '../../screens/AdminContentTrailScreen';
import AdminContentClassWeScreen from '../../screens/AdminContentClassWeScreen';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
export default function ContentClassWeDescription(props) {
  this.editionTimeout = null;
  // Object to Render
  this.object = props.object;
  this.roles = props.relations[0];
  this.authors = props.authors[0];
  this.subjects = props.subjects[0];
  // Functions
  this.updateContent = props.updateObject;

  this.deleteContent = props.deleteObject;
  this.saveContent = props.saveObject;
  this.publishObject = props.publishObject;
  this.nullDescription = props.nullDescription;

  this.showList = props.showList;

  this.object.roleOptions = this.roles.map((role) => {
    let roleOption = {
      value: role.id_role,
      label: role.role_name,
    };
    return roleOption;
  });

  if (this.object.roles !== undefined) {
    this.object.defaultRoleValues = this.object.roles.map((role) => {
      return {
        value: role.id_role ? role.id_role : null,
        label: role.role_name ? role.role_name : null,
      };
    });
  } else {
    this.object.defaultRoleValues = [];
  }

  if (this.authors !== undefined) {
    this.object.authorOptions = this.authors.map((author) => {
      let authorOption = {
        value: author.id_author,
        label: author.author_name,
      };
      return authorOption;
    });
  } else {
    this.object.authorOptions = [];
  }

  if (this.subjects !== undefined) {
    this.object.subjectOptions = this.subjects.map((subjects) => {
      let subjectOption = {
        value: subjects.id_topic,
        label: subjects.topic_name,
      };
      return subjectOption;
    });
  } else {
    this.object.subjectOptions = [];
  }

  if (this.object.id_author) {
    if (!this.object.defaultAuthorValue) {
      this.object.defaultAuthorValue = {
        value: this.object.id_author ? this.object.id_author : null,
        label: this.object.author_name ? this.object.author_name : null,
      };
    }
  }

  if (this.object.class_subject) {
    if (!this.object.defaultSubjectValue) {
      this.object.defaultSubjectValue = {
        value: this.object.class_subject ? this.object.class_subject : null,
        label: this.object.topic_name ? this.object.topic_name : null,
      };
    }
  }

  this.onClickArchiveContent = () => {
    this.onChangeUpdate('is_archived')({
      target: {
        value: this.object.is_archived ? 0 : 1,
      },
    });
    this.saveContent(this.object);
  };

  this.onClickSaveContent = () => {
    this.saveContent(this.object);
  };

  this.onClickPublishContent = () => {
    this.object.is_published = 1;
    this.publishObject(this.object);
  };

  this.onClickDeleteContent = () => {
    if (window.confirm('Tem certeza que deseja remover esse item?')) {
      this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the content
      this.object.is_published = null;
      this.deleteContent(this.object);
    }
  };

  this.onChangeUpdate =
    (field, force = false) =>
    (event) => {
      let value = event && event.target ? event.target.value : null;
      this.updateContent(this.object, field, value, force);
    };

  this.onChangeUpdateCertificate =
    (field, force = false) =>
    (event) => {
      let value = event && event.target ? event.target.checked : null;
      this.updateContent(this.object, field, value, force);
    };

  this.onDropActionCoverImage = async (file) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedCoverImage: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = null;
      if (this.object) {
        imageUrl = await Training.sendTrainingCoverImage(
          file,
          null,
          progressFunction,
        );
        this.updateContent(this.object, 'class_banner', imageUrl.url, true);
        toast.error('Tipo de conteúdo não permite imagem de capa!');
      }
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteCoverImage = () => {
    if (this.object && this.object.class_banner) {
      return this.updateContent(this.object, 'class_banner', null, true);
    }
  };

  this.onDropActionFile = async (file, field) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedFile: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = await Training.sendTrainingFile(
        file,
        this.object.id_class,
        progressFunction,
      );
      this.updateContent(this.object, field, imageUrl.url, true);
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.onDropActionImage = async (file, idx) => {
    file = file[0];

    const progressFunction = (completed) => {
      let completedImages = props.completed.completedImages
        ? props.completed.completedImages
        : [];
      completedImages[idx] = completed;
      const completedState = {
        completedImages: completedImages,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = await Training.sendTrainingFile(
        file,
        this.object.id_class,
        progressFunction,
      );
      let imageToUpdate = { class_banner: imageUrl.url };
      let training_images = this.object.class_banner
        ? this.object.class_banner
        : [];
      training_images[idx].training_image_filename =
        imageToUpdate.training_image_filename;
      this.updateContent(
        this.object,
        'training.training_images',
        training_images,
        true,
      );
      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteFile = () => {
    return this.updateContent(
      this.object,
      'training.training_file',
      null,
      true,
    );
  };

  this.onChangeUpdateTrainingImage = (event, idx) => {
    let imagesList = cloneDeep(this.object.training.training_images);
    imagesList[idx].training_image_description = event.target.value;
    this.object.training.training_images = imagesList;
    this.updateContent(this.object, 'training.training_images', imagesList);
  };

  this.showTrailContent = () => {
    return (
      <Grid xs={12} item style={{ width: '100%' }} contain spacing={2}>
        <AdminContentClassWeScreen startContent={this.object} />
      </Grid>
    );
  };

  this.onChangeUpdateRoles = (value) => {
    let roles;
    if (value != null) {
      //null cant use map function
      roles = value.map((obj) => {
        return {
          //revert the label to put the id_role and role_name in the Content Object
          id_role: obj.value,
          role_name: obj.label,
        };
      });
    } else {
      roles = [];
    }
    this.updateContent(this.object, 'roles', roles, true);
  };

  this.onChangeUpdateAuthor = (value) => {
    this.updateContent(this.object, 'id_author', value.value, true);

    this.object.defaultAuthorValue = {
      value: value.value,
      label: value.label,
    };
  };

  this.onChangeUpdateSubjects = (value) => {
    this.updateContent(this.object, 'class_subject', value.value, true);
    this.object.defaultSubjectValue = {
      value: value.value,
      label: value.label,
    };
  };

  this.onChangeUpdatePrice = (value) => {
    this.updateContent(this.object, 'class_price', value.value, true);
  };

  return (
    <div style={{ height: 'auto' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          alignItems: 'center',
          padding: '30px 20px 10px 20px',
        }}
      >
        <Grid container direction='column' spacing={2}>
          <Grid xs={12} container direction='row' spacing={1}>
            <Grid xs={4} item>
              <TextField
                label='Título Curso'
                value={
                  this.object && this.object.class_title
                    ? this.object.class_title
                    : ''
                }
                style={{ width: '100%' }}
                onChange={this.onChangeUpdate('class_title', false)}
                onBlur={this.onChangeUpdate('class_title', true)}
                multiline
              />
            </Grid>
            <Grid xs={4} item></Grid>
            <Grid xs={1} item>
              <TextField label='ID' value={this.object.id_class} disabled />
            </Grid>
            <Grid xs={3} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label='Data da ultima alteração'
                  value={this.object.updated_date}
                  style={{ width: '100%' }}
                  format='dd/MM/yyyy HH:mm'
                  disabled
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid xs={2} item></Grid>
          <Grid xs={12} container direction='row' spacing={1}>
            <Grid xs={3} container direction={'column'}>
              <Grid item>
                <span
                  style={{
                    float: 'left',
                    color: 'rgba(0, 0, 0, 0.54)',
                    padding: '0',
                    fontSize: '13px',
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    fontWeight: '400',
                    lineHeight: '1',
                    letterSpacing: '0.00938em',
                  }}
                >
                  Autor:
                </span>
              </Grid>
              <Grid item>
                <Select
                  placeholder={'Selecione o Autor'}
                  options={this.object.authorOptions}
                  value={this.object.defaultAuthorValue}
                  onChange={this.onChangeUpdateAuthor}
                ></Select>
              </Grid>
            </Grid>
            <Grid xs={0.5} item></Grid>
            <Grid xs={3} container direction={'column'}>
              <Grid item>
                <span
                  style={{
                    float: 'left',
                    color: 'rgba(0, 0, 0, 0.54)',
                    padding: '0',
                    fontSize: '13px',
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    fontWeight: '400',
                    lineHeight: '1',
                    letterSpacing: '0.00938em',
                  }}
                >
                  Tema:
                </span>
              </Grid>
              <Grid item>
                <Select
                  placeholder={'Selecione o Tema'}
                  options={this.object.subjectOptions}
                  value={this.object.defaultSubjectValue}
                  onChange={this.onChangeUpdateSubjects}
                ></Select>
              </Grid>
            </Grid>
            {/*<Grid xs={0.5} item></Grid>
                        <Grid xs={4} container direction={"column"}>
                            <Grid item>
                                <span
                                    style={{
                                        float: "left",
                                        color: "rgba(0, 0, 0, 0.54)",
                                        padding: "0",
                                        fontSize: "13px",
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                        fontWeight: "400",
                                        lineHeight: "1",
                                        letterSpacing: "0.00938em",
                                    }}
                                >
                                    Conteúdo pré requisito:
                                </span>
                            </Grid>
                            
                            <Grid item>
                                <Select
                                options={this.object.subjectOptions}
                                    placeholder={"Selecione o Conteúdo pré requisito"}
                                    searchApiUrl={"/content/select-contents"}
                                    actionOnSelectedOption={(value) => {
                                        this.predecessorContentChosen(value);
                                    }}
                                    value={this.object.defaultPredecessorContentValue}
                                ></Select>
                            </Grid>
                        </Grid>*/}

            <Grid xs={0.5} item></Grid>
            <Grid xs={1} container direction={'column'}>
              <Grid item>
                <span
                  style={{
                    float: 'left',
                    color: 'rgba(0, 0, 0, 0.54)',
                    padding: '0',
                    fontSize: '13px',
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    fontWeight: '400',
                    lineHeight: '1',
                    letterSpacing: '0.00938em',
                  }}
                >
                  Preço:
                </span>
              </Grid>
              <Grid item style={{ marginTop: 6 }}>
                <NumberFormat
                  customInput={TextField}
                  prefix={'R$'}
                  type='text'
                  placeholder='Preço RS'
                  decimalSeparator={','}
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  defaultValue={
                    this.object.class_price ? this.object.class_price : '0,00'
                  }
                  onValueChange={this.onChangeUpdatePrice}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container>
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: '12px',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: '0.00938em',
                    float: 'left',
                    color: 'rgba(0, 0, 0, 0.54)',
                    paddingBottom: '0px',
                  }}
                >
                  Possui certificado?
                </span>
              </Grid>
              <Grid xs={12} item>
                <Grid style={{ float: 'left' }}>
                  <Checkbox
                    onChange={this.onChangeUpdateCertificate(
                      'has_certificate',
                      true,
                    )}
                    checked={this.object.has_certificate}
                    title={'Possui certificado?'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} container spacing={2}>
            <Grid xs={12} container direction='row' spacing={1}>
              <Grid item xs={12}>
                <span
                  style={{
                    fontSize: '18px',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: '0.00938em',
                    float: 'left',
                    color: 'rgba(0, 0, 0, 0.54)',
                    paddingBottom: '15px',
                    paddingTop: '40px',
                  }}
                >
                  Conteúdo:
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid xs={6} item>
              <Grid container direction={'column'}>
                <Grid item xs={12}>
                  <span
                    style={{
                      fontSize: '12px',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 400,
                      lineHeight: 1,
                      letterSpacing: '0.00938em',
                      float: 'left',
                      color: 'rgba(0, 0, 0, 0.54)',
                      paddingBottom: '10px',
                    }}
                  >
                    Imagem de Capa (1700px X 864px)
                    {this.object && this.object.class_banner ? (
                      <a href='#' onClick={this.deleteCoverImage}>
                        {' '}
                        Remover Imagem
                      </a>
                    ) : null}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  {this.object && this.object.class_banner ? (
                    <img
                      style={{
                        width: '30vw',
                        height: '15vw',
                        objectFit: 'cover',
                      }}
                      src={
                        this.object.class_banner
                          ? this.object.class_banner
                          : null
                      }
                      id={'image-modal'}
                    />
                  ) : (
                    <div id='picker'>
                      <CustomDropzone
                        onDropAction={this.onDropActionCoverImage}
                        completed={props.completed.completedCoverImage}
                        inactiveLabel={
                          'Arraste a imagem(.jpeg, .png, .gif) de capa para cá'
                        }
                        activeLabel={'Solte a imagem de capa'}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6}>
              <Grid xs={12} item>
                <span
                  style={{
                    fontSize: '12px',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontWeight: 400,
                    lineHeight: 1,
                    letterSpacing: '0.00938em',
                    float: 'left',
                    color: 'rgba(0, 0, 0, 0.54)',
                    paddingBottom: '10px',
                    paddingLeft: '20px',
                  }}
                >
                  Descrição -{' '}
                  {this.object && this.object.class_description
                    ? this.object.class_description.length
                    : 0}
                  /5000
                </span>
              </Grid>
              <TextareaAutosize
                aria-label={'Descrição'}
                placeholder={'Descrição(Máximo de 5000 caracteres)'}
                maxLength={5000}
                rows={4}
                value={
                  this.object && this.object.class_description
                    ? this.object.class_description
                    : ''
                }
                onChange={this.onChangeUpdate('class_description', false)}
                onBlur={this.onChangeUpdate('class_description', true)}
                style={{
                  width: '90%',
                  height: '70%',
                  padding: '10px 10px',
                }}
              />
            </Grid>
          </Grid>

          <Grid xs={12}>
            <div style={{ height: '40px' }}></div>
          </Grid>
          <Grid container direction='row' spacing={2}>
            {this.object ? this.showTrailContent() : null}
          </Grid>
          <Grid xs={12} item>
            <SaveButton
              style={{ float: 'right' }}
              onClickSave={this.onClickPublishContent}
              ref={(ref) => {
                this.saveButton = ref;
              }}
              text={'Publicar'}
            />
            <SaveButton
              style={{ float: 'right' }}
              onClickSave={this.onClickSaveContent}
              ref={(ref) => {
                this.saveButton = ref;
              }}
              text={'Salvar Rascunho'}
            />
            <DeleteButton
              style={{ float: 'right', background: '#CC0B0B' }}
              onClickDelete={this.onClickDeleteContent}
            />
            <ArchiveButton
              style={{ float: 'right' }}
              onClickArchive={this.onClickArchiveContent}
              is_archived={this.object.is_archived}
              ref={(ref) => {
                this.archiveButton = ref;
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
