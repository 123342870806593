import GenericModel from './generic_model/GenericModel'
import axios from "axios";
const API = '/classes_we_author';

class ClassWeAuthor extends GenericModel {
    API = API;
    static API = API;

    static sendAuthorProfileImage = async (obj, progressFunction) => {

        try {
            let formData = new FormData();
            formData.append('cover_image', obj, (new Date()).getTime() + '-' + Math.round(Math.random() * 100000) + '.png');

            let headerObj = await ClassWeAuthor.insertAuthHeader();

            let response = await axios.request({
                url: API + '/send-author-profile-image',
                method: "post",
                data: formData,
                headers: headerObj,
                onUploadProgress: (p) => {
                    if (progressFunction) {
                        progressFunction(Math.round((p.loaded * 100) / p.total));
                    }
                }
            });

            return response.data;
        } catch (err) {
            throw Error(err);
        }
    };

    saveAuthor = async function () {
        let that = this;
        let authHeader = {};

        try {
            authHeader = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            let response = await fetch(that.API + '/', {
                method: "POST",
                headers: authHeader,
                body: JSON.stringify(this),
            });

            if (response.status >= 400) {
                throw Error(response.statusText);
            }

            let object = await response.json();

            return new GenericModel(object, that.API);
        } catch (err) {
            throw Error(err);
        }
    };
}

export default ClassWeAuthor;