import React, { Component } from "react";

import "./ui/css/App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import Constants
import * as Constants from "./constants";
// Import Sidebar Menu
import Sidebar from "./ui/navbar/Sidebar";
// Import Screens
import HomeScreen from "./ui/screens/HomeScreen";
import AdminAppScreen from "./ui/screens/AdminAppScreen";
import AdminBrandScreen from "./ui/screens/AdminBrandScreen";
import AdminContentScreen from "./ui/screens/AdminContentScreen";
import AdminContentTaskScreen from "./ui/screens/AdminContentTaskScreen";
import AdminContentMarketingScreen from "./ui/screens/AdminContentMarketingScreen";
import AdminContentFormScreen from "./ui/screens/AdminContentFormScreen";
import AdminContentInboxScreen from "./ui/screens/AdminContentInboxScreen";
import AdminContentTrailScreen from "./ui/screens/AdminContentTrailScreen";
import AdminContentScreenWe from "./ui/screens/AdminContentScreenWe";
import AdminFaqTopicScreen from "./ui/screens/AdminFaqTopicScreen";
import AdminManualScreen from "./ui/screens/AdminManualScreen";
import AdminInvoiceScreen from "./ui/screens/AdminInvoiceScreen";
import AdminInvoiceMaskScreen from "./ui/screens/AdminInvoiceMaskScreen";
import AdminStoreScreen from "./ui/screens/AdminStoreScreen";
import AdminUserScreen from "./ui/screens/AdminUserScreen";
import AdminAuthorScreen from "./ui/screens/AdminAuthorScreen";
import AdminMessageScreen from "./ui/screens/AdminMessageScreen";
import AdminChallengePhotoScreen from "./ui/screens/AdminChallengePhotoScreen";
import AdminVisualMerchandising from "./ui/screens/AdminVisualMerchandising";
import CreateVisualMerchandising from "./ui/screens/AdminVisualMerchandising/components/CreateVisualMerchandising";
import VisualMerchandisingAnswers from "./ui/screens/AdminVisualMerchandising/components/VisualMerchandisingAnswers";
import VisualMerchandisingAnswerChat from "./ui/screens/AdminVisualMerchandising/components/VisualMerchandisingAnswerChat";
import AdminProductScreen from "./ui/screens/AdminProductScreen";
import AdminSellingRegionScreen from "./ui/screens/AdminSellingRegionScreen";
import AdminDataPanelScreen from "./ui/screens/AdminDataPanelScreen";
import AdminDataPanelScreenCellairis from "./ui/screens/AdminDataPanelScreenCellairis";
import AdminDataPanelScreenCafeCultura from "./ui/screens/AdminDataPanelScreenCafeCultura";
import AdminDataPanelScreenMilBijus from "./ui/screens/AdminDataPanelScreenMilBijus";
import AdminPushNotificationScreen from "./ui/screens/AdminPushNotificationScreen";
import AdminRoleScreen from "./ui/screens/AdminRoleScreen";
import AdminRoleNewScreen from "./ui/screens/AdminRoleScreenNew";
import AdminSubjectScreen from "./ui/screens/AdminSubjectScreen";
import AdminContentEngagementScreen from "./ui/screens/AdminContentEngagementScreen";
import AdminCommunicationChannelScreen from "./ui/screens/AdminCommunicationChannelScreen";
import AdminNewsScreen from "./ui/screens/AdminNewsScreen";
import AdminCupomScreen from "./ui/screens/AdminCupomScreen";
import AdminCampaignScreen from "./ui/screens/AdminCampaignScreen";
import AdminTicketScreen from "./ui/screens/AdminTicketScreen";
import AdminChecklistScreen from "./ui/screens/AdminChecklistScreen";
import AdminChecklistScreenAlmost from "./ui/screens/AdminChecklistScreenAlmost";
import InboxTaskAdminScreen from "./ui/components/generic_screens/InboxTaskAdminScreen";
import AdminTicketDepartmentScreen from "./ui/screens/AdminTicketDepartmentScreen";
// import AdminTicketDepartmentScreen from "./ui/screens/generic_screens/AdminTicketDepartmentScreen";
import ZenDesk from "./ui/screens/ZenDesk";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
// Redirect Libraries
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import AuthManager from "./lib/AuthManager";
import AppModel from "./models/App";
import User from "./models/User";
import Chatboot from "./Chatboot";

class App extends Component {
  state = {
    menuExpanded: false,
    user: null,
    db_user: null,
    current_app: null,
  };

  componentDidMount() {
    let that = this;
    AuthManager.getUser()
      .then((user) => {
        that.setState({ user: user });
        if (localStorage.getItem("ID_APP")) {
          let promiseList = [
            User.me(),
            AppModel.get(localStorage.getItem("ID_APP")),
          ];
          Promise.all(promiseList)
            .then((answers) => {
              let stateObj = {
                db_user: answers[0],
                current_app: answers[1],
              };
              that.setState(stateObj);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
      });
  }

  appChosen = (id_app) => {
    let that = this;
    AppModel.get(id_app).then((app) => {
      localStorage.setItem("ID_APP", id_app);
      localStorage.setItem("CLIENT_ID", app.id_client);
      this.setState({ current_app: app });
      User.me()
        .then((db_user) => {
          that.setState({ db_user });
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  async onLogin() {
    try {
      let user = await AuthManager.getUser();
      this.setState({ user: user });
      if (localStorage.getItem("ID_APP")) {
        let db_user = await User.me();
        this.setState({ db_user });
      } else {
        this.setState({ current_app: null });
      }
    } catch (err) {
      console.error(err);
      toast.error("Não foi possível realizar o seu login. Tente novamente.");
    }
  }

  onMenuSelect = (selected) => {
    if (selected === "logout") {
      AuthManager.signOut().then(() => {
        this.setState({ user: null });
        window.location.href = "/";
      });
    }
  };

  onMenuToggle = (toggle) => {
    this.setState({ menuExpanded: toggle });
  };

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <ToastContainer 
            theme="colored"
          />
          {/*this.state.db_user &&
            // this.state.db_user.id_app === 22 &&
            // // console.log(this.state.db_user, "AQUIII") &&
            // console.log(this.state.db_user.id_app == 22, "FUNCIONA") &&

            Chatboot(
              "https://static.zdassets.com/ekr/snippet.js?key=1c7080ea-4632-4917-b2f5-6833e5d9fbfe",
              this.state.db_user.id_app ? this.state.db_user.id_app : null
            )*/}

          {this.state.user ? (
            <Sidebar
              style={{ height: "100%" }}
              onSelect={this.onMenuSelect}
              onToggle={this.onMenuToggle}
              auth_user={this.state.user}
              db_user={this.state.db_user}
              appChosen={this.appChosen}
              current_app={this.state.current_app}
            />
          ) : null}
          <div
            style={{
              marginLeft: this.state.user
                ? this.state.menuExpanded
                  ? "280px"
                  : "64px"
                : "0px",
              WebkitTransition: "all 0.15s",
              MozTransition: "all 0.15s",
              msTransition: "all 0.15s",
              transition: "all 0.15s",
              height: "100%",
            }}
          >
            <Switch>
              <Route
                path="/"
                exact={true}
                render={(props) => (
                  <HomeScreen
                    {...props}
                    onLogin={this.onLogin.bind(this)}
                    user={this.state.user}
                  />
                )}
              />
              <Route
                path={"/" + Constants.HOME_SCREEN}
                render={(props) => (
                  <HomeScreen
                    {...props}
                    onLogin={this.onLogin.bind(this)}
                    user={this.state.user}
                  />
                )}
              />
              <Route
                path={"/" + Constants.ADMIN_APP_SCREEN}
                component={AdminAppScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_BRAND_SCREEN}
                component={AdminBrandScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_CHALLENGE_PHOTO_SCREEN}
                component={AdminChallengePhotoScreen}
              />
              <Route
                path={"/" + Constants.CHALLENGE_PHOTO}
                component={AdminChallengePhotoScreen}
              />
              <Route
                path={"/" + Constants.VISUAL_MERCHANDISING + '/create'}
                component={CreateVisualMerchandising}
              />
              <Route
                path={"/" + Constants.VISUAL_MERCHANDISING + '/edit/:id'}
                component={CreateVisualMerchandising}
              />
              <Route
                path={"/" + Constants.VISUAL_MERCHANDISING + '/answers/:id'}
                component={VisualMerchandisingAnswers}
              />
              <Route
                exact
                path={"/" + Constants.VISUAL_MERCHANDISING + '/answer/chat/:id'}
                component={VisualMerchandisingAnswerChat}
              />
              <Route
                path={"/" + Constants.ADMIN_CONTENT_ENGAGEMENT_SCREEN}
                component={AdminContentEngagementScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_INVOICE_SCREEN}
                component={AdminInvoiceScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_INVOICE_MASK_SCREEN}
                component={AdminInvoiceMaskScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_PRODUCT_SCREEN}
                component={AdminProductScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_PUSH_NOTIFICATIONS_SCREEN}
                component={AdminPushNotificationScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_ROLE_SCREEN}
                component={AdminRoleScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_ROLENEW_SCREEN}
                component={AdminRoleNewScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_SUBJECT_SCREEN}
                component={AdminSubjectScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_SELLING_REGION_SCREEN}
                component={AdminSellingRegionScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_DATA_PANEL_SCREEN}
                component={AdminDataPanelScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_DATA_PANEL_SCREEN_CELLAIRIS}
                component={AdminDataPanelScreenCellairis}
              />
              <Route
                path={"/" + Constants.ADMIN_DATA_PANEL_SCREEN_CAFE}
                component={AdminDataPanelScreenCafeCultura}
              />
              <Route
                path={"/" + Constants.ADMIN_DATA_PANEL_SCREEN_MILBIJUS}
                component={AdminDataPanelScreenMilBijus}
              />
              <Route
                path={"/" + Constants.ADMIN_STORE_SCREEN}
                component={AdminStoreScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_USER_SCREEN}
                component={AdminUserScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_AUTHOR_SCREEN}
                component={AdminAuthorScreen}
              />
              <Route
                path={"/" + Constants.CONTENT_SCREEN}
                component={
                  localStorage.getItem("ID_APP") === "5"
                    ? AdminContentScreenWe
                    : AdminContentScreen
                }
              />
              <Route
                path={"/" + Constants.TASK_SCREEN}
                component={AdminContentTaskScreen}
              />
                <Route
                path={"/" + Constants.VISUAL_MERCHANDISING}
                component={AdminVisualMerchandising}
              />
              <Route
                path={"/" + Constants.MARKETING_SCREEN}
                component={AdminContentMarketingScreen}
              />
              <Route
                path={"/" + Constants.CONTENT_SCREEN}
                component={AdminContentTrailScreen}
              />
              <Route
                path={"/" + Constants.FORM_SCREEN}
                component={AdminContentFormScreen}
              />
              <Route
                path={"/" + Constants.MESSAGE_SCREEN}
                component={AdminMessageScreen}
              />
              <Route
                path={"/" + Constants.NEWS_SCREEN}
                component={AdminNewsScreen}
              />
              <Route
                path={"/" + Constants.CAMPAIGN_SCREEN}
                component={AdminCampaignScreen}
              />
              <Route
                path={"/" + Constants.CUPOM_SCREEN}
                component={AdminCupomScreen}
              />
              <Route
                path={"/" + Constants.FAQ_SCREEN}
                component={AdminFaqTopicScreen}
              />
              <Route
                path={"/" + Constants.MANUAL_SCREEN}
                component={AdminManualScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_COMMUNICATION_CHANNEL_SCREEN}
                component={AdminCommunicationChannelScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_TICKET_SCREEN}
                component={AdminTicketScreen}
              />
              <Route
                path={"/" + Constants.CHECKLIST_SCREEN}
                component={AdminChecklistScreenAlmost}
              />

              <Route
                path={"/" + Constants.INBOX_SCREEN}
                component={AdminContentInboxScreen}
              />
              <Route
                path={"/" + Constants.ADMIN_TICKET_DEPARTMENT_SCREEN}
                component={AdminTicketDepartmentScreen}
              />
              <Route path={"/" + Constants.ZEN_DESK} component={ZenDesk} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
