import React, { useLayoutEffect, useState } from "react";
import { toast } from "react-toastify";
// DB Models
import Content from "../../models/Content";
import Role from "../../models/Role";
import Spinner from "../components/buttons/Spinner";
import ContentTrailCard from "../components/cards/ContentFormCard";
import ChecklistDescription from "../components/description_cards/ChecklistDescription";
import ContentChecklistAdminScreen from "../components/generic_screens/ContentChecklistAdminScreen";
import ContentFormAdminScreen from "../components/generic_screens/ContentFormAdminScreen";


// class AdminChecklistScreen extends Component {
function AdminChecklistScreen() {
  const [contents, setContents] = useState([]);
  // const [state, setstate] = useState([])
  const [relations, setRelations] = useState([]);
  const [forms, setForms] = useState([]);
  // const [filters, setstate] = useState([])
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  // const [is_selecting_type, setstate] = useState(false)

  // editionTimeout = null;
  // //remove in upload dani
  // state = {
  //   contents: [],
  //   forms: [],
  //   relations: [],
  //   filters: [],
  //   loading: true,
  //   is_selecting_type: false,
  // };

  // TEXTS
  const searchText_placeholder = "Título da Atividade";
  const addButton_placeholder = "Atividades";
  // searchText_placeholder = 'Título do checklist';
  // addButton_placeholder = 'Checklist';

  // FUNCTIONS

  // Load all data from DB
  // componentDidMount() {
  //   Content.getResearches()
  //     .then((contents) => {
  //       this.setState({ contents: contents });

  //       // setContents( contents)
  //     })
  //     .catch((err) => {
  //       if (window.location.pathname !== "/") {
  //         window.location.href = "/";
  //       }
  //     });
  //   Role.query().then((roles) => {
  //     let relations = this.state.relations;
  //     relations[0] = roles;
  //     this.setState({ relations: relations });
  //   });
  //   this.reloadContent();
  // }
  useLayoutEffect(() => {
    Content.getResearches()
      .then((contents) => {
        // this.setState({ contents: contents });

        setContents(contents);
      })
      .catch((err) => {
        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
      });
    Role.query().then((roles) => {
      let relation = relations;
      relation[0] = roles;
      setRelations(relation);

      // this.setState({ relations: relations });
    });
    reloadContent();
  }, []);

  const reloadContent = async (parameters) => {
    // async reloadContent(parameters) {
    try {
      let contents = await Content.getResearches(parameters);
      // this.setState({ forms: contents });
      setForms(contents);
      //forms aqui
      contents = contents.filter((content) => {
        return content.form?.form_type === 6;
      });

      contents = contents.sort((obj1, obj2) => {
        if (obj1.id_content < obj2.id_content) {
          return -1;
        } else {
          return 1;
        }
      });

      // this.setState({ contents: contents });
      setContents(contents);

      setLoading(false);
      // this.setState({ loading: false });
    } catch (err) {
      toast.error("Erro ao carregar conteúdos");
    }
  };
  // async reloadContent(parameters) {
  //   try {
  //     let contents = await Content.getResearches(parameters);
  //     this.setState({ forms: contents });
  //     //forms aqui
  //     console.log("forms no adm checklist", this.state.forms);
  //     contents = contents.filter((content) => {
  //       return content.form?.form_type === 6;
  //     });

  //     contents = contents.sort((obj1, obj2) => {
  //       if (obj1.id_content < obj2.id_content) {
  //         return -1;
  //       } else {
  //         return 1;
  //       }
  //     });

  //     this.setState({ contents: contents });
  //     this.setState({ loading: false });
  //   } catch (err) {
  //     toast.error("Erro ao carregar conteúdos");
  //   }
  // }

  const addContent = async (contentType, questionType) => {
    let newContent = new Content();
    newContent.content = {
      id_app: localStorage.getItem("ID_APP"),
      is_challenge: 1,
      content_type: contentType,
      form: {form_type : 6, form_title : "", },
      form_question_type: questionType,
    };
    let contentObj = {};
    try {
      contentObj = await newContent.saveRoutine();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let contentList = contents;
    // let contentList = this.state.contents;
    contentList.push(contentObj);
    setContents(contentList);
    // this.setState({ contents: contentList });
    return contentObj;
  };
  //olhar isso
  const updateContent = (contentObj, field, value, force) => {
    // let contentList = this.state.contents;
    let contentList = contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_content === contentObj.id_content;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          contentToUpdate[prop] = value[prop];
        }
      }
    } else if (field.indexOf(".") > 0) {
      let hierarchy = field.split(".");
      let obj = contentToUpdate;
      for (let i = 0; i < hierarchy.length; i++) {
        if (i === hierarchy.length - 1) {
          obj[hierarchy[i]] = value;
          break;
        }
        obj = obj[hierarchy[i]];
      }
    } else {
      contentToUpdate[field] = value;
    }

    setContents(contentList);
    // this.setState({ contents: contentList });
    return contentList[position];
  };

  const deleteContent = async (contentObj) => {
    let contentList = contents;
    // let contentList = this.state.contents;
    // Finds Edited content on contents list and change Content_name value
    for (let i = 0; i < contentList.length; i++) {
      if (contentObj.id_content === contentList[i].id_content) {
        if (contentObj.content_type === 6) {
          Content.getContentTrail(contentObj.id_content).then(
            async (contentTrail) => {
              if (contentTrail && contentTrail.length) {
                for (let j = 0; j < contentTrail.length; j++) {
                  contentTrail[j].remove();
                }
              }
            }
          );
        }
        contentList[i].remove();
        contentList.splice(i, 1);
        break;
      }
    }
    setContents(contentList);
    // this.setState({ contents: contentList });
  };

  const saveContent = async (contentObj, isPublished = null) => {
    // let contentList = this.state.contents;
    let contentList = contents;
    let position = contentList.findIndex((obj) => {
      return obj.id_content === contentObj.id_content;
    });
    let contentToUpdate = contentList.find((obj) => {
      return obj.id_content === contentObj.id_content;
    });

    if (contentToUpdate != null) {
      let contentObj = contentToUpdate;

      try {
        if (contentToUpdate.content_type === 3) {
          if (
            contentToUpdate.form.forms_questions &&
            contentToUpdate.form.forms_questions.length
          ) {
            let form_questions = contentToUpdate.form.forms_questions;
            for (let k = 0; k < form_questions.length; k++) {
              if (
                form_questions[k].form_question_type === 9 &&
                !form_questions[k].id_form
              ) {
                throw new Error("has_form");
              } else if (form_questions[k].form_question_type === 1) {
                if (!form_questions[k].forms_choices) {
                  throw new Error("form_choices");
                } else if (
                  (!form_questions[k].forms_choices.length ||
                  form_questions[k].forms_choices.length === 0)
                

                ) {
                  throw new Error("form_choices");
                } else if (!form_questions[k].forms_choices[0].form_choice  && form_questions[k].form_question_type !== 8) {
                  throw new Error("form_choices");
                }
              }
            }
          } else {
            throw new Error("form_questions");
          }
        }
        contentObj = await contentToUpdate.save();
        if (contentObj.content_type === 6) {
          Content.getContentTrail(contentObj.id_content).then(
            async (contentTrail) => {
              if (contentTrail && contentTrail.length) {
                for (let j = 0; j < contentTrail.length; j++) {
                  contentTrail[j].is_challenge = contentObj.is_challenge;
                  contentTrail[j].is_fixed = contentObj.is_fixed;
                  contentTrail[j].is_marketing = contentObj.is_marketing;
                  contentTrail[j].roles = contentObj.roles;
                  contentTrail[j].starting_content =
                    contentObj.starting_content;
                  await contentTrail[j].save();
                }
              }
            }
          );
        }
        // Content upload to server worked
        contentList[position] = contentObj;
        setContents(contentList);
        // this.setState({ contents: contentList });
        toast.success(isPublished ? "Conteúdo pulicado com sucesso!\nAgora você pode ver o conteúdo no seu aplicativo Franco." : "Conteúdo salvo com sucesso!");
      } catch (err) {
        console.error(err);
        switch (err.message) {
          case "form_questions":
            toast.error(
              "Erro ao salvar conteúdo! Elabore uma pergunta para seu formulário."
            );
            break;
          case "has_form":
            toast.error(
              "Erro ao salvar conteúdo! Pergunta do tipo formulário deve ter um formulário vinculado."
            );
            break;
          case "form_choices":
            toast.error(
              "Erro ao salvar conteúdo! Pergunta do tipo múltipla deve ter pelo menos uma alternativa."
            );
            break;
          default:
            toast.error("Erro ao salvar conteúdo!");
            break;
        }
      }
    }
  };

  const refreshContents = async (parameters) => {
    reloadContent(parameters);
  };
  const openModalChecklist = () => {
    // this.setState({ is_selecting_type: true });
    setModalVisible(true);
  };

  const closeModalChecklist = () => {
    setModalVisible(true);

    // this.setState({ is_selecting_type: false });
  };

  return (
    <>
      {contents.length === 0 && loading ? (
        <Spinner
          margin="13px"
          texto={"carregando..."}
          border={"#9BB3D4"}
          top={"#1D2D44"}
        />
      ) : (
        <ContentChecklistAdminScreen
          searchText_placeholder={searchText_placeholder}
          addButton_placeholder={addButton_placeholder}
          objectList={contents}
          //aqui mesmo
          forms={forms}
          objectCard={ContentTrailCard}
          objectDescription={ChecklistDescription}
          addObject={addContent}
          updateObject={updateContent}
          // updateObject={this.updateContent}
          deleteObject={deleteContent}
          saveObject={saveContent}
          refreshObjects={refreshContents}
          relations={relations}
          contents={contents}
          objectIdName={"id_content"}
          objectModel={Content}
          onFilterReload={true}
          modalChecklistVisible={modalVisible}
          openModalChecklist={openModalChecklist}
          closeModalChecklist={closeModalChecklist}
        ></ContentChecklistAdminScreen>
      )}
    </>
  );
}

export default AdminChecklistScreen;
