import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import Grid from '@mui/material/Grid';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory, useParams } from 'react-router-dom';

import SolicitationAnswersTable from './SolicitationAnswersTable';
import { CustomIconButton } from '../../../components/buttons/ThemeButtons';
import VisualMerchandisingAnswer from '../../../../models/VisualMerchandisingAnswer';
import ImagePreviewModal from './ImagePreviewModal';

function VisualMerchandisingAnswers() {
  const [visualMerchandising, setVisualMerchandising] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState();
  const [imagePreviewModalIsVisible, setImagePreviewModalIsVisible] = useState(false);

  const history = useHistory();
  const {id} = useParams();
  
  useEffect(() => {
    fecthVisualMerchandisingAnswers();
  }, []);

  async function fecthVisualMerchandisingAnswers(){
    try {
      const response = await VisualMerchandisingAnswer.getById(id);
      
      setVisualMerchandising(response);
      setIsLoading(false);
    } catch(err){
      console.log(err);
    }
  }

  function handleShowImagePreviewModal(image){
    setSelectedImage(image);
    setImagePreviewModalIsVisible(true);
  }

  function handleCloseImagePreviewModal(){
    setSelectedImage(undefined);
    setImagePreviewModalIsVisible(false);
  }

  if(isLoading){
    return null;
  }

  return (
    <>
      <ImagePreviewModal 
        handleClose={handleCloseImagePreviewModal}
        image={selectedImage}
        isVisible={imagePreviewModalIsVisible}
      />
      <Box
        padding='16px 48px'
        bgcolor='#FAFAFA'
        height='99vh'
        overflow='auto'
      >
        <Container maxWidth="lg" style={{ marginBottom: 16 }}>
          <Box
            mb={4}
          >
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link onClick={() => history.push('/visual_merchandising')} style={{ cursor: 'pointer', color: "#666666" }}>
                Merchandising
              </Link>
              <span
                style={{
                  fontWeight: 400,
                  color: "#666666"
                }}
              >
                Respostas
              </span>
              <span
                style={{
                  fontWeight: 700,
                  color: "#162233"
                }}
              >
                Visualizar respostas
              </span>
            </Breadcrumbs>
          </Box>

          <Box
            bgcolor='#FFF'
            border='1px solid #E0E5EC'
            borderRadius={8}
            display='flex'
            alignItems='flex-start'
            flexDirection='column'
            padding={4}
          >
            <Box
              display='flex'
              alignItems='center'
              flexDirection='row'
            >
              <CustomIconButton
                onClick={() => history.push("/visual_merchandising")}
              >
                <ArrowBackIosNewIcon
                  color='##979899'
                />
              </CustomIconButton>
              <h1 style={{
                color: '#666666',
                fontWeight: 700,
                fontSize: 19,
                marginLeft: 16
              }}>
                {
                  visualMerchandising.title
                }
              </h1>
            </Box>

            <h1 style={{
              color: '#666666',
              fontWeight: 700,
              fontSize: 19,
              marginBottom: 8
            }}>
              Detalhes da solicitação
            </h1>

            <p
              style={{
                textAlign: 'left',
                color: '#333333',
                fontSize: 15
              }}
            >
              {visualMerchandising.description}
            </p>

            <Grid container mt={2} spacing={2}>
                {
                visualMerchandising.images.map(image => (
                    <Grid item xs={2} key={image.id_visual_merchandising_images} maxWidth={120} maxHeight={120}>
                      <img
                        src={image.image_url}
                        alt="merchandising-image"
                        onClick={() => handleShowImagePreviewModal(image)}
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer'
                        }}
                      />
                    </Grid>              
                  ))
                }
              </Grid>

            <h1 style={{
              color: '#666666',
              fontWeight: 700,
              fontSize: 19,
              marginBottom: 24
            }}>
              Respostas
            </h1>

            <SolicitationAnswersTable 
              data={visualMerchandising}
              isLoading={false}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default VisualMerchandisingAnswers;