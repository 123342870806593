
export default function mask(input){
  return parseFloat(Math.round(input*100)/100).toFixed(2);
}

export function handleChangeInputTextValue(event, actualValue, maxLength, setValue){
  if(event.target.value.length > maxLength){
    if(actualValue.length < maxLength){
      setValue(event.target.value.slice(0, maxLength));
    }
    return;
  }

  setValue(event.target.value);
}