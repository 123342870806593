import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';

import { CustomIconButton } from '../../../components/buttons/ThemeButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    cursor: 'pointer',
  },
}))(TableRow);


const StyledTableCell = withStyles((theme) => ({
  root: {
    border: 0,
  }
}))(TableCell);


function StatusBadge({ status }) {
  let badgeBackgroundColor;
  let label;

  switch (status) {
    case 0:
      label = "Não lida";
      badgeBackgroundColor = "#FCB76E";
      break;

    case 2:
      label = "Aguardando resposta";
      badgeBackgroundColor = "#FFE5A2";
      break;
    
    case 3:
      label = "Aprovado";
      badgeBackgroundColor = "#DBFFDD";
      break;

    case 4:
      label = "Aguardando feedback";
      badgeBackgroundColor = "#FFE5A2";
      break;
    case 5:
      label = "Reprovado";
      badgeBackgroundColor = "#FFE5A2";
      break;
    case 6:
      label = "Aguardando feedback";
      badgeBackgroundColor = "#FFE5A2";
      break;
  
    default:
      label = "";
      badgeBackgroundColor = "";
      break;
  }

  return (
    <Chip
      label={label}
      style={{
        backgroundColor: badgeBackgroundColor,
        color: "#162233"
      }}
    />
  );
}

function SolicitationAnswersTable({ isLoading, data }) {
   const history = useHistory();

  function handleClickTableRow(idSubmission) {
    if(!idSubmission){
      return;
    }

    history.push(`/visual_merchandising/answer/chat/${idSubmission}`);
  }

  return (
    <>
      <TableContainer>
        <Table size="small" style={{
          borderCollapse: 'separate',
          borderSpacing: '0 0px'
        }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Quem respondeu</StyledTableCell>
              <StyledTableCell align="left">Loja</StyledTableCell>
              <StyledTableCell align="left">CNPJ</StyledTableCell>
              <StyledTableCell align="left">Respostas</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              isLoading ? Array.from({ length: 10 }, (v, k) => k).map(element => (
                <StyledTableRow key={element}>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width="100%" height={40} />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={40} />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={40} />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Skeleton variant="rectangular" width='100%' height={40} />
                  </StyledTableCell>
                </StyledTableRow>
              ))
                : null
            }

            {!isLoading ? data.usersSend.map((solicitationAnswer) => (
              <StyledTableRow key={solicitationAnswer.id_visual_merchandising_users}>
                <StyledTableCell 
                  align="left" 
                  onClick={() => handleClickTableRow(solicitationAnswer.submission?.id_visual_merchandising_submissions)}
                >
                  {solicitationAnswer.user.user_name}
                  </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClickTableRow(solicitationAnswer.submission?.id_visual_merchandising_submissions)}>
                  {solicitationAnswer.user.store !== null ? solicitationAnswer.user.store.store_name : "Sem loja"}
                  </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClickTableRow(solicitationAnswer.submission?.id_visual_merchandising_submissions)}>
                  {solicitationAnswer.user.store !== null ? solicitationAnswer.user.store.store_ein_code : "Sem loja"}
                </StyledTableCell>
                <StyledTableCell align="left" onClick={() => handleClickTableRow(solicitationAnswer.submission?.id_visual_merchandising_submissions)}>
                  <StatusBadge
                    status={solicitationAnswer.submission !== null ? solicitationAnswer.submission.id_status : 0}
                  />
                </StyledTableCell>
                <StyledTableCell align="right" onClick={() => handleClickTableRow(solicitationAnswer.submission?.id_visual_merchandising_submissions)}>
                  {
                    solicitationAnswer.submission !== null ? (
                      <CustomIconButton>
                        <ChevronRightIcon />
                      </CustomIconButton>
                    ) : null
                  }
                </StyledTableCell>

              </StyledTableRow>
            ))
              : null
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default SolicitationAnswersTable;