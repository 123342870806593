import GenericModel from "../generic_model/GenericModel";

export const urlModify = (oldUrl)=>{
    // console.log(oldUrl,'a url entra ')

    let newUrl=  oldUrl.replace(/firebasestorage./i, 'storage.');
    newUrl=  newUrl.replace('.com/v0/b/', '.com/');
    newUrl=  newUrl.replace('insidethebox-video/o/', 'insidethebox-video/');
    newUrl=  newUrl.split("?alt=media")
    newUrl=  newUrl[0]
    // console.log(newUrl,'a url sai ')
    return newUrl ;
  }
export const sendDataImgVideo = async (   idManualContent,  videoUrlSend, fileName, fileType )=>{
    
    const  videoUrl = urlModify(videoUrlSend)

        try {
            let manualContentTrainingObj = {
            id_manual_content: idManualContent,
            manual_content_training_type: fileType,
            manual_content_training_video: videoUrl,
            manual_content_training_name: fileName,
            };
            let headerObj = await GenericModel.insertAuthHeader({
            Accept: "application/json",
            "Content-Type": "application/json",
            });

            let response = await fetch(this.API + "/save-manual-video-file", {
            method: "POST",
            headers: headerObj,
            body: JSON.stringify(manualContentTrainingObj),
            });

            return response.data;
        } catch (err) {
            throw Error(err);
        }
    
      };
