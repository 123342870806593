import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
// DB Models
import User from '../../models/User';
import Role from '../../models/Role';
import UserCard from '../components/cards/UserCard';
import UserDescription from '../components/description_cards/UserDescription';
import Utils from '../../lib/utils';
import { toast } from 'react-toastify';
import Spinner from '../components/buttons/Spinner';

class AdminUserScreen extends Component {
  editionTimeout = null;

  state = {
    users: [],
    relations: [],
    loading:true
  };

  // TEXTS
  searchText_placeholder = 'Nome, Telefone ou E-mail';
  addButton_placeholder = 'Administrador';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    this.reloadUsers();
  }

  refreshUsers = async (parameters) => {
   await this.reloadUsers(parameters);
  };

  reloadUsers = async (parameters) => {
    try {
      let [users, roles] = await Promise.all([
        User.query(parameters), 
        Role.query()
      ]);
      if(localStorage.getItem('ID_APP') != 3) {
        users = users.filter((user) => {
          return user.app_admin === 1;
        });
      } 
      
      this.setState({ users: users });
      let relations = this.state.relations;
      relations[0] = roles;
      this.setState({ roles: relations });
      this.setState({ loading: false });
    } catch (err) {
      console.log(err);
      console.warn(err);
      /*if (window.location.pathname !== '/') {
        window.location.href = '/';
      }*/
    }
  };

  addUser = async () => {
    let newUser = new User();
    let userObj = {};
    try {
      userObj = await newUser.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let userList = this.state.users;
    userList.push(userObj);
    this.setState({ users: userList });
    return userObj;
  };

  updateUser = (userObj, field, value, force) => {
    let userList = this.state.users;
    let position = userList.findIndex((obj) => {
      return obj.id_user === userObj.id_user;
    });
    let userToUpdate = userList.find((obj) => {
      return obj.id_user === userObj.id_user;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          userToUpdate[prop] = value[prop];
        }
      }
    } else {
      // Format phone number
      if (field === 'user_phone' && value && force) {
        value = Utils.correctPhoneNumber(value);
      }

      userToUpdate[field] = value;

    }
    this.setState({ users: userList });
    return userList[position];
  };


  saveUser = async (userObj) => {
    let userList = this.state.users;
    let position = userList.findIndex((obj) => {
      return obj.id_user === userObj.id_user;
    });
    let userToUpdate = userList.find((obj) => {
      return obj.id_user === userObj.id_user;
    });

    if (userToUpdate != null) {
      let checkUserPhoneToUpdate = await User.searchPhoneNumber(userToUpdate.user_phone);
      if (!checkUserPhoneToUpdate || checkUserPhoneToUpdate === null || checkUserPhoneToUpdate.length === 0) {
        if(localStorage.getItem('ID_APP') != 3) {
          userToUpdate.app_admin = 1;
        }
        if (localStorage.getItem('ID_APP') == 4) {
          userToUpdate
            .saveUserWeb()
            .then((userObj) => {
              // User upload to server worked
              userList[position] = userObj;
              this.setState({ users: userList });
              toast.success('Usuário salvo com sucesso!');
            })
            .catch((err) => {
              console.error(err);
              toast.error('Error ao salvar usuário!');
            });
        } else {
          userToUpdate
            .save()
            .then((userObj) => {
              // User upload to server worked
              userList[position] = userObj;
              this.setState({ users: userList });
              toast.success('Usuário salvo com sucesso!');
            })
            .catch((err) => {
              console.log(err);
              toast.error('Error ao salvar usuário!');
            });
        }
      } else  if (checkUserPhoneToUpdate.length > 0 && userToUpdate.id_user === checkUserPhoneToUpdate[0].id_user)  {
        if(localStorage.getItem('ID_APP') != 3) {
          userToUpdate.app_admin = 1;
        }
        if (localStorage.getItem('ID_APP') == 4) {
          userToUpdate
            .saveUserWeb()
            .then((userObj) => {
              // User upload to server worked
              userList[position] = userObj;
              this.setState({ users: userList });
              toast.success('Usuário salvo com sucesso!');
            })
            .catch((err) => {
              console.error(err);
              toast.error('Error ao salvar usuário!');
            });
        } else {
          userToUpdate
            .save()
            .then((userObj) => {
              // User upload to server worked
              userList[position] = userObj;
              this.setState({ users: userList });
              toast.success('Usuário salvo com sucesso!');
            })
            .catch((err) => {
              console.log(err);
              toast.error('Error ao salvar usuário!');
            });
        }

        
      } else {
        toast.error('Número já cadastrado!');
        return;
      }
     
    }
  };

  deleteUser = async (userObj) => {
    let userList = this.state.users;
    let checkUserPhoneToUpdate = await User.searchPhoneNumber(userObj.user_phone);
    if (checkUserPhoneToUpdate && checkUserPhoneToUpdate.length > 0 && userObj.id_user !== checkUserPhoneToUpdate[0].id_user) {
      userObj.user_phone = null;
      userObj.user_name = null;
      userObj.user_email = null;
      userObj.user_cpf = null;
    }

    userObj.remove();

    // Finds Edited brand on brands list and change Brand_name value
    for (let i = 0; i < userList.length; i++) {
      if (userObj.id_user === userList[i].id_user) {
        //userList[i].remove();
        userList.splice(i, 1);
        break;
      }
    }

    this.setState({ users: userList });
  };

  render() {
    return (
      <> 
      {this.state.users.length===0 
      &&this.state.loading
      ? <Spinner
       margin='13px' 
      texto={'carregando...'}
      border={'#9BB3D4'}
      top={'#1D2D44'}/>
      :
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.users}
        objectCard={UserCard}
        objectDescription={UserDescription}
        addObject={this.addUser}
        updateObject={this.updateUser}
        saveObject={this.saveUser}
        deleteObject={this.deleteUser}
        refreshObjects={this.reloadUsers}
        relations={this.state.relations}
        objectIdName={'id_user'}
        objectModel={User}
        onFilterReload={true}
      />
  }</>
    );
  }
}

export default AdminUserScreen;
