import React, { Component } from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen';
import { toast } from 'react-toastify';
// DB Models
import FaqTopic from '../../models/FaqTopic';

// UI Cards
import FaqTopicCard from '../components/cards/FaqTopicCard';
import FaqTopicDescription from '../components/description_cards/FaqTopicDescription';
import Spinner from '../components/buttons/Spinner';

class AdminFaqTopicScreen extends Component {
  editionTimeout = null;

  state = {
    faqTopics: [],
    relations: [],
    loading:true
  };

  // TEXTS
  searchText_placeholder = 'Tópico ou Pergunta';
  addButton_placeholder = 'Tópico';

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    FaqTopic.query()
      .then((faq_topics) => {
        this.setState({ loading: false });
        this.setState({ faqTopics: faq_topics });
      })
      .catch((err) => {
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });
  }

  addFaqTopic = async () => {
    let newFaqTopic = new FaqTopic();
    let faqTopicObj = {};
    try {
      faqTopicObj = await newFaqTopic.save();
    } catch (err) {
      console.error(err);
      throw Error(err);
    }
    let faqTopicList = this.state.faqTopics;
    faqTopicList.push(faqTopicObj);
    this.setState({ faqTopics: faqTopicList });
    return faqTopicObj;
  };

  updateFaqTopic = (faqTopicObj, field, value, force) => {
    this.setState({ loading: true });

    let that = this;
    let faqTopicList = this.state.faqTopics;
    let position = faqTopicList.findIndex((obj) => {
      return obj.id_faq_topic === faqTopicObj.id_faq_topic;
    });
    let faqTopicToUpdate = faqTopicList.find((obj) => {
      return obj.id_faq_topic === faqTopicObj.id_faq_topic;
    });

    if (!field) {
      for (let prop in value) {
        if (value.hasOwnProperty(prop)) {
          faqTopicToUpdate[prop] = value[prop];
        }
      }
    } else {
      faqTopicToUpdate[field] = value;
    }

    // Timeout: Wait 500 milliseconds before sending API POST call to Server
    if (this.editionTimeout) clearTimeout(this.editionTimeout);

    this.setState({ faqTopics: faqTopicList });
    this.setState({ loading: false });
    return faqTopicList[position];
  };

  deleteFaqTopic = async (faqTopicObj) => {
    let faqTopicList = this.state.faqTopics;

    // Finds Edited faqTopic on faqTopics list and change FaqTopic_name value
    for (let i = 0; i < faqTopicList.length; i++) {
      if (faqTopicObj.id_faq_topic === faqTopicList[i].id_faq_topic) {
        await faqTopicList[i].remove();
        faqTopicList.splice(i, 1);
        break;
      }
    }

    this.setState({ faqTopics: faqTopicList });
  };

  saveFaqTopic = async (faqTopicObj) => {
    let faqTopicList = this.state.faqTopics;
    let position = faqTopicList.findIndex((obj) => {
      return obj.id_faq_topic === faqTopicObj.id_faq_topic;
    });
    let faqTopicToUpdate = faqTopicList.find((obj) => {
      return obj.id_faq_topic === faqTopicObj.id_faq_topic;
    });

    if (faqTopicToUpdate != null) {
      let faqTopicObj = faqTopicToUpdate;

      try {
        faqTopicObj = await faqTopicToUpdate.save();
        // FaqTopic upload to server worked
        faqTopicList[position] = faqTopicObj;
        this.setState({ faqTopics: faqTopicList });
       toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
       toast.error('Erro ao salvar conteúdo!');
      }
    }
  };

  render() {
    return (
      <> 
      {this.state.faqTopics.length===0 
      &&this.state.loading
      ? <Spinner
       margin='13px' 
      texto={'carregando...'}
      border={'#9BB3D4'}
      top={'#1D2D44'}/>
      :
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        addButton_placeholder={this.addButton_placeholder}
        objectList={this.state.faqTopics}
        objectCard={FaqTopicCard}
        objectDescription={FaqTopicDescription}
        addObject={this.addFaqTopic}
        updateObject={this.updateFaqTopic}
        saveObject={this.saveFaqTopic}
        deleteObject={this.deleteFaqTopic}
        relations={this.state.relations}
        objectIdName={'id_faq_topic'}
      ></GenericAdminScreen>}</>
    );
  }
}

export default AdminFaqTopicScreen;
