import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import Grid from '@material-ui/core/Grid';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

import {TextInput} from '../../../components/text_fields/ThemeTextInputs';
import {ButtonBlueBorder, ButtonDarkBlue, ButtonWhite, CustomIconButton} from '../../../components/buttons/ThemeButtons';
import AlertDialog from '../../../components/alert/ThemeAlertDialog';
import { CustomTooltip } from '../../../components/tooltip/ThemeTooltips';
import VisualMerchandising from '../../../../models/VisualMerchandising';
import { showToast } from '../../../components/toast/ThemeToast';
import { handleChangeInputTextValue } from '../../../utils';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

function DropZone({ addFile }) {
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');

      reader.onload = () => {
        const binaryStr = reader.result
        
        addFile(binaryStr, file);
      }
      reader.readAsDataURL(file)
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/jpeg, image/png' });

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: '2px dashed #C9CACC',
        marginTop: 24,
        padding: '32px 0',
        cursor: 'pointer',
        hover: {
          background: "#B3F6FF"
        },
        transition: "all 0.2s",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          (
            <div>
              <FileUploadIcon
                sx={{
                  fontSize: 72,
                  color: '#C9CACC'
                }}
              />
              <p style={{
                fontSize: 14,
                color: "#333",
                fontWeight: 400
              }}>Arraste e solte ou <b>clique aqui</b> para adicionar até 10 arquivos.</p>
              <div style={{
                fontSize: 12,
                color: "#666666",
                fontWeight: 400
              }}>
                <p>Formatos aceitos: .jpeg, .png, .gif</p>
                <p>Tamanho máximo por aquivo: 10mb</p>
              </div>
            </div>
          )
      }
    </div>
  );
}

function ImageCard({ 
  showModal, 
  image, 
  index, 
  handleDelete, 
  visualMerchandisingId,
  updateImage
}) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if(image.image_preview_url){
      handleUpload();
    }

    if(image.image_url){
      setUploadProgress(1);
    }
  }, []);

  async function handleUpload(){
    try{
      if(isUploading){
        return;
      }

      setIsUploading(true);
      
      const response = await VisualMerchandising.uploadFileImage(image.file, visualMerchandisingId);
  
      setIsUploading(false);
      setUploadProgress(1);
  
      updateImage(index, {
        image_preview_url: null,
        image_file: null,
        ...response
      });

    }catch(err){
      console.log(err);
      
      showToast("error", "Erro ao fazer o upload da imagem. por favor tente novamente.");
    }
  }

  return (
    <Box
      bgcolor="#FAFAFA"
      display="flex"
      flexDirection="row"
      alignItems="center"
      width='100%'
      borderRadius={8}
      pl={1}
    >
      <img
        src={image.image_preview_url || image.image_url}
        alt="merchandising-image"
        style={{
          width: 123,
          height: 48,
          borderRadius: 4,
          opacity: uploadProgress < 1 ? 0.3 : 1
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        width='100%'
        paddingX={1}
        paddingY={1}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width='100%'
        >
          {
            uploadProgress === 1 ? (
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                width='100%'
              >
                <span
                  style={{
                    color: "#666666",
                    fontSize: 12
                  }}
                >
                  Título e legenda
                </span>
                <CustomTooltip title="Editar">
                  <CustomIconButton
                    onClick={() => showModal(image)}
                  >
                    <EditIcon />
                  </CustomIconButton>
                </CustomTooltip>
              </Box>
            )
              : null
          }
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            width='100%'
          >
            <span
              style={{
                fontSize: 10,
                fontWeight: 400,
                color: "#666666"
              }}
            >
              {uploadProgress * 100} %
            </span>
            <CustomTooltip title="Excluir">   
              <CustomIconButton
                onClick={() => handleDelete(index)}
                sx={{
                  '&:hover': {
                    color: "#B00020",
                  }
                }}
                disabled={isUploading}
              >
                <DeleteIcon />
              </CustomIconButton>
            </CustomTooltip>
          </Box>
        </Box>
        {
          uploadProgress < 1 ? (
            <div style={{ width: '100%', color: uploadProgress === 1 ? "#4CAF50" : "#F28C1F" }}>
              <BorderLinearProgress color='inherit' variant='determinate' value={uploadProgress * 100} />
            </div>
          )
            : (
              <span
                style={{
                  textAlign: 'left',
                  color: "#333333",
                  fontSize: 14
                }}
              >
                {image?.title}
              </span>
            )
        }
      </Box>
    </Box>
  );
}

function ImageDescriptionModal({ handleClose, isVisible, image, visualMerchandisingId, updateImageById}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setTitle(image.title || "");
    setDescription(image.description|| "");
  }, [image]);

  async function handleUpdate(){
    try{
      const response = await VisualMerchandising.updateImage(
        title, 
        description, 
        image.id_visual_merchandising_images, 
        visualMerchandisingId);
      
      updateImageById(image.id_visual_merchandising_images, response);

      showToast("success", "Dados salvos com sucesso.");
    } catch(err){
      console.log(err);
      showToast("error", "Erro ao atualizar os dados da imagem. por favor tente novamente.");
    }
  }

  return (
    <Modal
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{overflowY:'scroll'}}

      >
      <Box
        bgcolor="#FFF"
        border="1px solid #E0E5EC"
        borderRadius={8}
        display='flex'
        flexDirection='column'
        margin='16px auto'
        width='50%'
        p={2}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <span
            style={{
              color: "#162233",
              fontSize: 22
            }}
          >
            Título e legenda
          </span>

          <CustomIconButton
            onClick={handleClose}
          >
            <CloseIcon />
          </CustomIconButton>
        </Box>

        <img
          src={image.image_url}
          alt="merchandising-image"
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: 400,
            borderRadius: 4
          }}
        />
        <Box
          mt={3}
        >
          <TextInput
            label="Título"
            placeholder="Título"
            variant="outlined"
            style={{ width: '50%', marginBottom: 24 }}
            value={title}
            onChange={(e) => handleChangeInputTextValue(e, title, 200, setTitle)}
            helperText={`${title.length}/ 200 caracteres`}
          />
          <TextInput
            label="Legenda"
            placeholder="Legenda"
            variant="outlined"
            multiline={true}
            style={{ width: '100%' }}
            rows={4}
            value={description}
            onChange={(e) =>  handleChangeInputTextValue(e, description, 500, setDescription)}
            helperText={`${description.length}/ 500 caracteres`}
          />
        </Box>

        <Box
          display='flex'
          justifyContent='flex-end'
          mt={2}
        >
          <ButtonDarkBlue
            onClick={() => handleUpdate()}
          >
            Salvar
          </ButtonDarkBlue>
        </Box>
      </Box>
    </Modal>
  );
}

function ImagesGrid({ showModal, images, handleDelete, visualMerchandisingId, updateImage }) {
  return (
    <Box
      marginTop={2}
      width='100%'
    >
      <Grid container spacing={3}>
        {
          images.map((image, index) => (
            <Grid item xs={6} key={index}>
              <Box
                display='flex'
                justifyContent='flex-start'
                mb={1}
              >
                <span
                  style={{
                    alignSelf: 'flex-start',
                    fontSize: 12,
                    color: "#333333"
                  }}
                >
                  {index + 1}ª
                </span>
              </Box>
              <ImageCard
                image={image}
                showModal={showModal}
                handleDelete={handleDelete}
                index={index}
                visualMerchandisingId={visualMerchandisingId}
                updateImage={updateImage}
              />
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
}

function MerchandisingUploadForm({ nextStep, goBack, isActive, visualMerchandising }) {
  const [files, setFiles] = useState([]);
  const [descriptionModalIsVisible, setDescriptionModalIsVisible] = useState(false);
  const [deleteAlertIsVisible, setDeleteAlertIsVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if(!visualMerchandising){
      return;
    }

    if(!visualMerchandising.images){
      return;
    }

    if(visualMerchandising.images.length > 0){
      setFiles(visualMerchandising.images);
    }
  }, [visualMerchandising]);

  function handleAddFile(fileUrl, file) {
    setFiles(old => [...old, 
      {
        image_preview_url: fileUrl, 
        file: file
      }]);
  }

  async function handleDeleteFile(indexFile){
    try {
      await VisualMerchandising.deleteImage(files[indexFile].id_visual_merchandising_images, visualMerchandising.id_visual_merchandising);
  
      setFiles(old => old.filter((file, index) => {
        if(index !== indexFile){
          return file;
        }
      }));
    } catch(err){
      console.log(err);
    }
  }

  function handleUpdateFile(indexFile, imageUpdated){
    setFiles(old => old.map((file, index) => {
      if(index === indexFile){
        file = imageUpdated;

        return file;
      }

      return file;
    }));
  }

  function handleUpdateImageById(imageId, imageUpdated){
    setFiles(old => old.map(image => {
      if(image.id_visual_merchandising_images === imageId){
        image = imageUpdated;

        return image;
      }

      return image;
    }));
  }

  function handleSaveDraft(){
    showToast("success", "Rascunho salvo com sucesso.");
    history.push("/visual_merchandising");
  }

  async function handleConfirmDelete(){
    try{
      const response = await VisualMerchandising.delete(visualMerchandising.id_visual_merchandising);
      showToast("success", "Visual Merchandising excluído com sucesso.");
      history.push("/visual_merchandising");
    } catch(err){
      showToast("error", "Erro ao excluir Visual Merchandising, por favor tente novamente.");
      console.log(err);
    }
  }

  function handleShowModal(image){
    setSelectedImage(image);
    setDescriptionModalIsVisible(true);
  }

  function handleNext(){
    nextStep();
  }

  if (!isActive) {
    return null;
  }

  return (
    <Box
      bgcolor='#FFF'
      border='1px solid #E0E5EC'
      borderRadius={8}
      display='flex'
      alignItems='flex-start'
      flexDirection='column'
      padding={4}
    >
      {
        selectedImage && (
          <ImageDescriptionModal
            isVisible={descriptionModalIsVisible}
            handleClose={() => setDescriptionModalIsVisible(false)}
            image={selectedImage}
            visualMerchandisingId={visualMerchandising.id_visual_merchandising}
            updateImageById={handleUpdateImageById}
          />
        )
      }
      <AlertDialog 
        title="Descartar conteúdo"
        description="Você tem certeza que deseja descartar o conteúdo?"
        confirmTextButton="Descartar conteúdo"
        handleClose={() => setDeleteAlertIsVisible(false)}
        handleConfirm={() => handleConfirmDelete()}
        isOpen={deleteAlertIsVisible}
      />
      <Box
        display='flex'
        alignItems='center'
        flexDirection='row'
      >
        <CustomIconButton
          onClick={goBack}
        >
          <ArrowBackIosNewIcon
            color='##979899'
          />
        </CustomIconButton>
        <h1 style={{
          color: '#666666',
          fontWeight: 700,
          fontSize: 19,
          marginLeft: 16
        }}>
          Upload de arquivos
        </h1>
      </Box>

      {
        files.length > 9 ?
          null
          : (
            <DropZone
              addFile={handleAddFile}
            />
          )
      }
      {
        files.length > 0 ?
          (
            <ImagesGrid
              images={files}
              showModal={handleShowModal}
              handleDelete={handleDeleteFile}
              visualMerchandisingId={visualMerchandising.id_visual_merchandising}
              updateImage={(indexFile, updatedImage) => handleUpdateFile(indexFile, updatedImage)}
            />
          )
          : null
      }
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        marginTop={4}
        marginBottom={4}
      >
        {
          visualMerchandising && (
            <span
              style={{
                fontWeight: 400,
                fontSize: 12,
                color: '#666666',
                letterSpacing: 0.4
              }}
            >
              ID {visualMerchandising.id_visual_merchandising} | Última atualização: {moment(visualMerchandising.updated_date).format("DD/MM/YYYY - HH:mm")}
            </span>
          )
        }
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <ButtonWhite
          onClick={() => setDeleteAlertIsVisible(true)}
        >
          Descartar
        </ButtonWhite>
        <Box>
          <ButtonBlueBorder
            onClick={() => handleSaveDraft()}
            style={{
              marginRight: 16
            }}
          >
            Salvar rascunho
          </ButtonBlueBorder>

          <ButtonDarkBlue
            onClick={() => handleNext()}
          >
            Próximo
          </ButtonDarkBlue>
        </Box>
      </Box>
    </Box>
  );
}

export default MerchandisingUploadForm;