import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { ButtonBlueBorder, ButtonDarkBlue, ButtonWhite, CustomIconButton } from '../../../components/buttons/ThemeButtons';
import { AutoCompleteInput, MultipleSelectInput } from '../../../components/select_inputs/ThemeSelectInputs';
import AlertDialog from '../../../components/alert/ThemeAlertDialog';
import Role from '../../../../models/Role';
import User from '../../../../models/User';
import VisualMerchandising from '../../../../models/VisualMerchandising';
import { showToast } from '../../../components/toast/ThemeToast';

function CustomRadio(props){
  return(
    <Radio
    {...props}
      sx={{
        color: "#F28C1F",
        '&.Mui-checked': {
          color: "#F28C1F",
        }
      }}
    />
  );
}

function MerchandisingSendConfigForm({ nextStep, isActive, goBack, visualMerchandising }) {
  const [selectSendType, setSelectedSendType] = useState("1");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchUserValue, setSearchUserValue] = useState("");
  const [usersOptions, setUsersOptions] = useState([]);
  const [deleteAlertIsVisible, setDeleteAlertIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchAppRoles();
  }, []);

  useEffect(() => {
    if(searchUserValue.length > 2){
      fetchUsersOptions();
    }
  }, [searchUserValue]);

  async function fetchAppRoles(){
    const response = await Role.query();
    let rolesOptions = [];

    response.map(role => {
      rolesOptions.push(role.role_name + ` (${role.id_role})`);
    });

    setRoles(rolesOptions);
  }

  async function fetchUsersOptions(){
    const response = await User.listAll(searchUserValue);
    let newUserOptions = [];

    response.map(user => {
      newUserOptions.push({
        id: user.value.id_user,
        label: user.value.user_name + " - " + user.value.user_phone
      })
    });

    setUsersOptions(newUserOptions);
  }

  function handleChange(e) {
    const {
      target: { value },
    } = e;

    setSelectedRoles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );  
  }

  async function handleSend(){
    try{
      if(isLoading){
        return;
      }

      let rolesId = [];

      if(selectSendType === "2"){
        const isValid = validateSelectedUser();
        if(!isValid){
          return;
        }
      }

      if(selectSendType === "3"){
        selectedRoles.map(role => {
          const found = role.match(/ \((\d+)\)/);
    
          if(found !== null){
            rolesId.push(Number(found[1]));
          }
        })

        const isValid = validateSelectedRoles(rolesId);

        if(!isValid){
          return;
        }
      }

      setIsLoading(true);
      const response = await VisualMerchandising.sendVisualMerchandising(
        Number(selectSendType),
        selectedUserId,
        selectedRoles.length === 0 ? null : rolesId,
        visualMerchandising.id_visual_merchandising
      );

      showToast("success", "Solicitação enviada com sucesso.");
      setIsLoading(false);
      history.push("/visual_merchandising");
    } catch(err){
      console.log(err);
      showToast("error", "A solicitação não pôde ser enviada, mas foi salva como rascunho. Tente novamente mais tarde.");
      history.push("/visual_merchandising");
    }
  }
    
  async function handleConfirmDelete(){
    try{
      if(isLoading){
        return;
      }
      
      setIsLoading(true);
      const response = await VisualMerchandising.delete(visualMerchandising.id_visual_merchandising);
      setIsLoading(false);
      history.push("/visual_merchandising");
      showToast("success", "Visual Merchandising excluído com sucesso.");
    } catch(err){
      console.log(err);
      showToast("error", "Erro ao excluir Visual Merchandising, por favor tente novamente.");
    }
  }
    
  function handleChangeSelectedType(type){
    setSelectedUserId(null);
    setSelectedRoles([]);
    setSelectedSendType(type);
  }

  function handleSaveDraft(){
    showToast("success", "Rascunho salvo com sucesso.");
    history.push("/visual_merchandising");
  }

  function validateSelectedRoles(rolesId){
    if(rolesId.length === 0){
      showToast("error", "Selecione pelo menos 1 papel.");
      return false;
    }

    if(rolesId.length !== selectedRoles.length){
      showToast("error", "Erro ao selecionar os papéis.");
      return false;
    }

    return true;
  }

  function validateSelectedUser(){
    if(!selectedUserId){
      showToast("error", "É necessário selecionar um usuário.");
      return false;
    }

    return true;
  }

  if (!isActive) {
    return null;
  }

  return (
    <Box
      bgcolor='#FFF'
      border='1px solid #E0E5EC'
      borderRadius={8}
      display='flex'
      alignItems='flex-start'
      flexDirection='column'
      padding={4}
    >
       <AlertDialog 
        title="Descartar conteúdo"
        description="Você tem certeza que deseja descartar o conteúdo?"
        confirmTextButton="Descartar conteúdo"
        handleClose={() => setDeleteAlertIsVisible(false)}
        handleConfirm={() => handleConfirmDelete()}
        isOpen={deleteAlertIsVisible}
      />
       <Backdrop
        sx={{ color: '#0EA8BC', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" size={56} />
      </Backdrop>
      <Box
        display='flex'
        alignItems='center'
        flexDirection='row'
      >
        <CustomIconButton
          onClick={goBack}
        >
          <ArrowBackIosNewIcon
            color='##979899'
          />
        </CustomIconButton>
        <h1 style={{
          color: '#666666',
          fontWeight: 700,
          fontSize: 19,
          marginLeft: 16
        }}>
          Configuração de envio
        </h1>
      </Box>
      <Stack spacing={2} mt={2}>
        <span
          style={{
            color: '#333333',
            fontWeight: 600,
            fontSize: 19,
            textAlign: 'left'
          }}
        >
          Para quem deseja enviar o conteúdo?
        </span>
        <FormControl sx={{paddingLeft: 4}}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="1"
            name="radio-buttons-group"
            onChange={(e) => handleChangeSelectedType(e.target.value)}
          >
            <FormControlLabel 
              value="1" 
              control={<CustomRadio />} 
              label="Enviar para todos"
              sx={{color: "#666666", fontSize: 15}} 
             />
            <FormControlLabel 
              value="2" 
              control={<CustomRadio />} 
              label="Enviar para um usuário específico" 
              sx={{color: "#666666", fontSize: 15}} 
            />
            {
              selectSendType === "2" ? 
              (
                <Box
                  marginY={2}
                >
                  <AutoCompleteInput 
                    options={usersOptions}
                    onChange={(newValue) => setSelectedUserId(newValue)}
                    inputValue={searchUserValue}
                    onInputChange={(newValue) => setSearchUserValue(newValue)}
                  />
                </Box>
              ) : null
            }
            <FormControlLabel 
              value="3" 
              control={<CustomRadio />} 
              label="Enviar para Usuários de papéis específicos" 
              sx={{color: "#666666", fontSize: 15}}
            />
            {
              selectSendType === "3" ? 
              (
                <Box
                  marginY={2}
                >
                  <MultipleSelectInput 
                    options={roles}
                    onChange={handleChange}
                    value={selectedRoles}
                  />
                </Box>
              ) : null
            }
          </RadioGroup>
        </FormControl>
      </Stack>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        marginTop={4}
        marginBottom={4}
      >
         {
          visualMerchandising && (
            <span
              style={{
                fontWeight: 400,
                fontSize: 12,
                color: '#666666',
                letterSpacing: 0.4
              }}
            >
              ID {visualMerchandising.id_visual_merchandising} | Última atualização: {moment(visualMerchandising.updated_date).format("DD/MM/YYYY - HH:mm")}
            </span>
          )
        }
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <ButtonWhite
          onClick={() => setDeleteAlertIsVisible(true)}
        >
          Descartar
        </ButtonWhite>
        <Box>
          <ButtonBlueBorder
            onClick={() => handleSaveDraft()}
            style={{
              marginRight: 16
            }}
          >
            Salvar rascunho
          </ButtonBlueBorder>

          <ButtonDarkBlue
            onClick={() => handleSend()}
          >
            Enviar
          </ButtonDarkBlue>
        </Box>
      </Box>
    </Box>
  );
}

export default MerchandisingSendConfigForm;