import DateFnsUtils from "@date-io/date-fns";
import {
  faBuilding, 
  faCommentAlt,
  faListAlt,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers/";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";
import Role from "../../models/Role";
import Ticket from "../../models/Ticket";
import TicketAnswer from "../../models/TicketAnswer";
import TicketDepartment from "../../models/TicketDepartment";
import TicketStatus from "../../models/TicketStatus";
import User from "../../models/User";
import Spinner from "../components/buttons/Spinner";
import ModalTicketDepartmentList from "../components/modals/ModalTicketDepartmentList";
import ModalTicketFormList from "../components/modals/ModalTicketFormList";
import {AttachFile, Send, Close, MoreVert} from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AlertDialog from "../components/alert/AlertDialog";

function AnswerMessageMenu({handleDeleteMessage}){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return(
    <Box
      display='flex'
      flexDirection='row-reverse'
      width='100%'
    >   
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
      <MoreVert />
        </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
          maxHeight: 38 * 4.5,
          width: '20ch',
          },
        }}
      >
        <MenuItem key={'Remover'} onClick={handleDeleteMessage}>
          Remover mensagem
        </MenuItem>
      </Menu>
    </Box>
  );
}

function TicketAnswerMessage({answer, chosenTicket, messagesEndRef, handleDeleteMessage, loggedUserId}){
  return(
    <div
      ref={messagesEndRef}
      style={{
        display: "flex",
        alignItems:
          answer.id_user === chosenTicket.id_user
            ? "flex-end"
            : "flex-start",
        flexDirection: "column",
      }}
    >
      <div
        key={answer.id_ticket_answer}
        style={{
          display: "flex",
          width: "55%",
          borderRadius: 20,
          backgroundColor: "#eee",
          alignItems: "flex-start",
          padding: '10px 20px',
          flexDirection: "column",
          marginBottom: 5,
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
        >
          <span style={{ color: "#000", marginBottom: 2, width: '100%' }}>{`${
            answer.user_name
          } - ${moment(answer.created_date).format(
            "DD/MM/YYYY HH:mm"
          )}`}</span>
          {
          answer.id_user === loggedUserId && answer.deleted_date === null
          ? <AnswerMessageMenu handleDeleteMessage={handleDeleteMessage}/>
          : null
          }
        </Box>
        <span style={{ color: "#000" }}>
          {answer.ticket_answer}
        </span>
      </div>
    </div>
  );
}

function TicketAnswerAttachment({answer, chosenTicket, messagesEndRef, handleDeleteMessage, loggedUserId}){
  const [showFullScreen, setShowFullScreen] = useState(false);

  return(
    <>
      <Modal
        open={showFullScreen}
        onClose={() => setShowFullScreen(false)}
      >
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              padding: '10px 10px 0 0'
            }}
          >
            <IconButton
              onClick={() => setShowFullScreen(false)}
            >
              <Close style={{color: 'white'}}/>
            </IconButton>
          </div>
          <div
            style={{
              width: '100%',
              height: 'calc(100% - 120px)',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}>
              <img 
                src={answer.ticket_attachment_url}
                alt="Anexo"
                style={{
                  width: '100%',
                  maxHeight: '100%'
                }}
              />
            </div>
          </div>
        </>
      </Modal>
      <div
        ref={messagesEndRef}
        style={{
          display: "flex",
          alignItems:
          answer.id_user === chosenTicket.id_user
            ? "flex-end"
            : "flex-start",
          flexDirection: "column",
        }}
      >
      <div
        key={answer.id_ticket_answer}
        style={{
          display: "flex",
          width: "55%",
          borderRadius: 20,
          backgroundColor: "#eee",
          alignItems: "flex-start",
          padding: '10px 20px',
          flexDirection: "column",
          marginBottom: 5,
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          width='100%'
        >
          <span style={{ color: "#000", marginBottom: 2, width: '100%'}}>{`${
            answer.user_name
            } - ${moment(answer.created_date).format(
            "DD/MM/YYYY HH:mm"
            )}`}
          </span>
          {
            answer.id_user == loggedUserId 
            ? <AnswerMessageMenu handleDeleteMessage={handleDeleteMessage}/>
            : null
          }
        </Box>
        {
          answer.ticket_attachment_file_name.includes(".pdf") ? 
          (
            <a 
              href={answer.ticket_attachment_url}
              target='_blank'
              style={{ color: "#162233", padding: '16px 0'}}
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                style={{ marginRight: "8px", fontSize: "20px" }}
              />
              <span>{answer.ticket_attachment_file_name}</span>
            </a>
          )
          :
          (
            <div 
              onClick={() => setShowFullScreen(true)}
              style={{
                cursor: 'pointer',
                marginBottom: 8
              }}
            >
            <img 
              src={answer.ticket_attachment_url}
              alt="Anexo"
              style={{
                width: '100%',
                height: 'auto',
                margin: '0 auto',
                display: 'block',
                maxHeight: 350
              }}
            />
              <span style={{marginTop: 16, display: 'block'}}>{answer.ticket_attachment_file_name}</span>
            </div>
          )
        }
        <Divider style={{width: '100%', marginBottom: 16}}/>
        <span style={{ color: "#000" }}>
          {answer.ticket_answer}
        </span>
      </div>
      </div>
    </>
  );
}

function AdminTicketScreen() {
  const [tickets, setTickets] = useState([]);
  const [ticketsDepartments, setTicketsDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [alteredTicket, setAlteredTicket] = useState(false);
  const [columns, setColumns] = useState([]);
  const [open, setOpen] = useState(false);
  const [openFormList, setOpenFormList] = useState(false);
  const [openDepartmentList, setOpenDepartmentList] = useState(false);
  const [chosenTicket, setChosenTicket] = useState({});
  const [chosenTicketDepartment, setChosenTicketDepartment] = useState({});
  const [deadlineDate, setDeadLineDate] = useState({});
  const [message, setMessage] = useState("");
  const [answers, setAnswers] = useState([]);
  const [userDepartment, setUserDepartment] = useState(null);
  const [user, setUser] = useState({});
  const [ticketUserResponsible, setTicketUserResponsible] = useState(null);
  const [ticketUsersResponsible, setTicketUsersResponsible] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const [attachment, setAttachment] = useState();
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [alertDialogIsOpen, setAlertDialogIsOpen] = useState(false);

  const permittedAttachmentTypes = ['image/png', 'image/jpeg', 'application/pdf'];
  const MAX_ATTACHMENT_SIZE = 104857600; // 100MB

  const DarkerDisabledTextField = withStyles({
    root: {
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
      },
    },
  })(TextField);

  const handleClickOpen = async (ticket) => {
    try {
      let anwersDb = await Ticket.getAnswers(ticket.id_ticket);
      let ticketStatusName = await Ticket.getStatusName(ticket.ticket_status);
      await Ticket.saveTicketAnswerFeedBack(ticket.id_ticket);
      let usersResponsibleDb = [];
      if (ticket.department?.id_ticket_dep_type === 2) {
        usersResponsibleDb = await Ticket.getUserInCharge(
          ticket.department?.id_ticket_dep_type
        );
      }
      let usersResponsibleDbOptions = usersResponsibleDb.map((user) => {
        let userResponsibleDbOption = {
          value: user.value,
          label: user.label,
        };
        return userResponsibleDbOption;
      });

      ticket.status_name = ticketStatusName[0].ticket_status_name;
      //console.log(ticket.department.id_ticket_department);
      setAnswers(anwersDb);
      setTicketUsersResponsible(usersResponsibleDbOptions);
      setDeadLineDate(ticket.deadline_date);
      setChosenTicket(ticket);
      setChosenTicketDepartment({
        value: ticket.department.id_ticket_department,
        label: ticket.department.ticket_department_name,
      });
      if (ticket.responsible) {
        setTicketUserResponsible({
          value: ticket.responsible?.id_user,
          label: ticket.responsible?.user_name,
        });
      }

      setOpen(true);
      scrollToBottom();
      onRefresh(false);
    } catch (error) {
      console.log(error);
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  const handleClose = () => {
    clearSelectedAttachment();
    setOpen(false);
  };

  const saveListTickets = async (ticketListObj) => {
    let ticketListToUpdate = ticketListObj.map((ticket, index) => {
      ticket.ticket_order = index;
      return ticket;
    });

    if (ticketListToUpdate != null) {
      try {
        for (let i = 0; i < ticketListToUpdate.length; i++) {
          /*let ticketObjtoUpdate = */
          ticketListToUpdate[i].save();
          //ticketListToUpdate[i] = ticketObjtoUpdate[i];
        }

        //toast.success('Conteúdo salvo com sucesso!');
      } catch (err) {
        console.error(err);
        switch (err.message) {
          default:
            //toast.error('Erro ao salvar tickets!');
            break;
        }
      }
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    /*console.log(source, "SOURCE");
    console.log(destination, "DESTINATION");
    console.log(columns, "COLUNAS");*/

    if (source.droppableId !== destination.droppableId) {
      let columnsBkp = columns;
      const sourceColumn = columnsBkp.filter((column, index) => {
        return column.id_column === source.droppableId;
      });
      const destColumn = columnsBkp.filter((column, index) => {
        return column.id_column === destination.droppableId;
      });
      const sourceItems = sourceColumn[0].items;
      const destItems = destColumn[0].items;
      const [removed] = sourceItems.splice(source.index, 1);
      //console.log(removed, "REMOVED");
      removed.ticket_status = destination.droppableId;
      if (removed.deadline_date) {
        if (destColumn[0].type < 2) {
          if (destColumn[0].type === "1") {
            removed.deadline_status = 4;
          } else {
            removed.deadline_status = 0;
          }
          
        } else {
          let dateNow = moment().startOf("day");
          let deadlineDate = moment(removed.deadline_date).startOf("day");
  
          if (dateNow.diff(deadlineDate, "days") === -1) {
            removed.deadline_status = 1; //24h antes de vencer o prazo
          } else if (dateNow.diff(deadlineDate, "days") === 0) {
            removed.deadline_status = 2; //no dia do prazo
          } else if (dateNow.diff(deadlineDate, "days") > 0) {
            removed.deadline_status = 3; // passou do dia do prazo
          } else {
            removed.deadline_status = 0;
          }
        }

      }
      
      //removed.ticket_order = destination.index;
      //removed.save();
      //
      destItems.splice(destination.index, 0, removed);
      //console.log(destItems, "LISTA DE ITENS DESTINO");
      columnsBkp = columnsBkp.map((columnBkp) => {
        if (columnBkp.id_column === sourceColumn[0].id_column) {
          columnBkp.items = sourceItems;
        }
        if (columnBkp.id_column === destColumn[0].id_column) {
          columnBkp.items = destItems;
        }
        return columnBkp;
      });
      setColumns(columnsBkp);
      saveListTickets(destItems);
    } else {
      let columnsBkp = columns;
      let column = columnsBkp.filter((column, index) => {
        return column.id_column === source.droppableId;
      });
      const copiedItems = column[0].items;
      const [removed] = copiedItems.splice(source.index, 1);
      removed.ticket_status = destination.droppableId;
      copiedItems.splice(destination.index, 0, removed);
      //removed.save();
      columnsBkp = columnsBkp.map((columnBkp) => {
        if (columnBkp.id_column === column[0].id_column) {
          columnBkp.items = copiedItems;
        }
        return columnBkp;
      });
      setColumns(columnsBkp);

      saveListTickets(copiedItems);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  const fetchData = async (chosenDepartment) => {
    try {
      let ticketsDb = await Ticket.query();
      let userDb = await User.me();
      let departmentsDb = await TicketDepartment.getDepartmentSelect();
      let departmentOptions = departmentsDb.map((department) => {
        let departmentOption = {
          value: department.value,
          label: department.label,
          ticketDepartamentType: department.ticketDepartamentType,
        };
        return departmentOption;
      });
      let rolesDb = await Role.query();
      let roleOptions = [];
      if (rolesDb && rolesDb.length > 0) {
        roleOptions = rolesDb.map((role) => {
          let roleOption = {
            value: role.id_role,
            label: role.role_name,
          };
          return roleOption;
        });
      }
      if (ticketsDb && ticketsDb.length > 0) {
        ticketsDb = ticketsDb.filter((ticket) => {
          if (chosenDepartment) {
            return ticket.ticket_area === chosenDepartment.value;
          } else {
            return ticket.ticket_area === departmentOptions[0].value;
          }
        });
      }
      let columnsFromBackendDb = [];
      if (chosenDepartment) {
        columnsFromBackendDb = await TicketStatus.getTicketsDepartmentStatus(
          chosenDepartment.value
        );
      } else {
        columnsFromBackendDb = await TicketStatus.getTicketsDepartmentStatus(
          departmentOptions[0].value
        );
      }
      let columnsFromBackend = columnsFromBackendDb.map((column) => {
        return {
          id_column: String(column.id_ticket_status),
          name: String(column.ticket_status_name),
          type: String(column.ticket_status_type),
          items: ticketsDb.filter((ticket) => {
            if (
              (column.ticket_status_name === "Aberto" ||
              column.ticket_status_name === "Recebido" ||
                column.ticket_status_name === "Pedido Solicitado") &&
              (!ticket.ticket_status || ticket.ticket_status === 1)
            ) {
              return true;
            } else {
              return ticket.ticket_status === column.id_ticket_status;
            }
          }),
        };
      });
      /*let columnsFromBackend = {
        ["1"]: {
          name: "Aberto",
          items: ticketsDb.filter((ticket) => {
            return (
              ticket.ticket_status === 1 ||
              !ticket.ticket_status ||
              ticket.ticket_status === "1"
            );
          }),
        },
        ["2"]: {
          name: "Executando",
          items: ticketsDb.filter((ticket) => {
            return ticket.ticket_status === 2 || ticket.ticket_status === "2";
          }),
        },
        ["3"]: {
          name: "Resolvido",
          items: ticketsDb.filter((ticket) => {
            return ticket.ticket_status === 3 || ticket.ticket_status === "3";
          }),
        },
        ["4"]: {
          name: "Cancelado",
          items: ticketsDb.filter((ticket) => {
            return ticket.ticket_status === 4 || ticket.ticket_status === "4";
          }),
        },
      };*/
      setColumns(columnsFromBackend);
      setTickets(ticketsDb);
      setTicketsDepartments(departmentOptions);
      setRoleOptions(roleOptions);
      setUser(userDb);
      if (chosenDepartment) {
        setUserDepartment(chosenDepartment);
      } else {
        setUserDepartment(departmentOptions[0]);
      }

      setIsLoading(false);
      scrollToBottom();
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onRefresh = (hasToLoad) => {
    setIsLoading(hasToLoad);
    fetchData(userDepartment);
  };

  const handleMessageChange = (message) => {
    setMessage(message);
  };

  const handleDepartmentChange = (event, newValue) => {
    setChosenTicketDepartment(newValue);
    setAlteredTicket(true);
  };
  const handleDeadlineDate = (deadlineDate) => {
    setDeadLineDate(deadlineDate);
    setAlteredTicket(true);
  };
  const handleUserResponsableChange = (event, newValue) => {
    setTicketUserResponsible(newValue);
    setAlteredTicket(true);
  };

  const saveTicket = async () => {
    if (alteredTicket) {
      setIsSaving(true);
      let ticketToSave = new Ticket(chosenTicket);
      ticketToSave.ticket_area = chosenTicketDepartment.value;
      ticketToSave.id_user_in_charge = ticketUserResponsible?.value;
      ticketToSave.deadline_date = moment(deadlineDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      try {
        await ticketToSave.save();
        setIsSaving(false);
        setAlteredTicket(false);
        toast.success("Demanda salva com sucesso!");
        onRefresh(false);
      } catch (error) {
        console.log(error);
        setIsSaving(false);
        toast.error("Não foi possível salvar sua demanda. Tente novamente.");
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleSendAnswer = async () => {
    if(attachment){
      await sendAttachment();
      return;
    }

    if (message && message !== "") {
      try {
        let answerApi = new TicketAnswer();
        answerApi.id_ticket = chosenTicket.id_ticket;
        answerApi.id_user = user.id_user;
        answerApi.ticket_answer = message;
        setIsSendingMessage(true);
       const newTicketAnswer = await answerApi.save();
        answerApi.user_name = user.user_name
          ? user.user_name
          : user.phone_number;
        setMessage("");
        let anwersDb = answers;
        anwersDb.push({...answerApi, 
          id_ticket_answer: newTicketAnswer.id_ticket_answer,
          ticket_attachment_url: null,
          deleted_date: null
          });
        setAnswers(anwersDb);
        setIsSendingMessage(false);
        onRefresh(false);
      } catch (error) {
        toast.error("Erro ao enviar mensagem");
        console.log(error);
      }
    }
  };

  const handleSendAttachment = () => {
    if(fileInputRef){
      fileInputRef.current.click();
    }
  }

  const onChangeFile = (e) => {
    if(e.target.files && e.target.files.length > 0){

      setAttachment(e.target.files[0]);

      if(e.target.files[0].type === 'application/pdf'){
        setAttachmentPreview(null);
        return;
      }

      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);
    
      reader.addEventListener('load', () => {
        setAttachmentPreview(reader.result);
      });
    }
  }

  const clearSelectedAttachment = () => {
    setAttachment(null);
    setAttachmentPreview(null);
    fileInputRef.current.file = '';
  }

  const sendAttachment = async () => {
    try {
      // Validar o tipo do arquivo
      if(!permittedAttachmentTypes.includes(attachment.type)){
        toast.warn("Só são permitidos anexos com extensão do tipo .pdf, .jpeg e .png.");
        return;
      } 

      // Validar o tamanho
       if(attachment.size > MAX_ATTACHMENT_SIZE){
        toast.warn("Arquivo com tamanho maior que 100MB.");
        return;
      }
     
      setIsSendingMessage(true);
      const newTicketAnswer = await TicketAnswer.sendAttachment(attachment, message, user.id_user, chosenTicket.id_ticket);
      setAnswers(old => [...old, 
        {
        ...newTicketAnswer, 
        user_name: user.user_name ? user.user_name : user.phone_number
      }]);
      clearSelectedAttachment();
      setMessage("");
      setIsSendingMessage(false);
    } catch (err) {
      clearSelectedAttachment();
      setMessage("");
      setIsSendingMessage(false);
      toast.error(err.message);
      console.log(err);
    }
  }

  const handleDeleteMessage = async (ticketAnswer) => {
    try {
      await TicketAnswer.deleteTicketAnswer(user.id_user, [ticketAnswer]);
      
      setAnswers(old => old.filter(answer => {
        if(answer.id_ticket_answer === ticketAnswer.id_ticket_answer){
          answer.ticket_answer = "Mensagem deletada pelo usuário.";
          answer.ticket_attachment_url = null;
          answer.ticket_attachment_file_name = null;
          answer.deleted_date = new Date();

          return answer; 
        }
        return answer;
      }));
    } catch(err){
      console.log(err);
    }
  }

  const handleDeleteTicket = async () => {
    try{
      const response = await Ticket.removeTicket(chosenTicket.id_ticket, user.id_user);
  
      clearSelectedAttachment();
      setOpen(false);
      toast.success("Demanda excluída com sucesso.");
      setAlertDialogIsOpen(false);
      setIsLoading(true);
      await fetchData(chosenTicketDepartment);
    }catch(err){  
      toast.err("Erro ao excluir demanda.");
      console.log(err);
    }
  }

  const handleOpenFormList = () => {
    setOpenFormList(true);
  };
  const handleOpenDepartmentList = () => {
    setOpenDepartmentList(true);
  };

  const onCloseFormList = () => {
    setOpenFormList(false);
  };
  const onCloseDepartmentList = () => {
    setOpenDepartmentList(false);
  };
  const handleDepartmentChosen = (chosenDepartment) => {
    if (
      chosenDepartment &&
      chosenDepartment !== undefined &&
      chosenDepartment.value !== userDepartment.value
    ) {
      setIsLoading(true);
      fetchData(chosenDepartment);
    }
    setOpenDepartmentList(false);
  };

  return isLoading ? (
    <Spinner
    margin='13px' 
    texto={'carregando...'}
    border={'#9BB3D4'}
    top={'#1D2D44'}/>
  ) : (
    <PerfectScrollbar
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "95%",
        overflow: "hidden",
        position: "absolute",
      }}
      options={{ suppressScrollX: "true" }}
    >
      {openFormList ? (
        <ModalTicketFormList
          open={openFormList}
          roleOptions={roleOptions}
          onClose={onCloseFormList}
          ticketDepartments={userDepartment}
        />
      ) : null}
      {openDepartmentList ? (
        <ModalTicketDepartmentList
          open={openDepartmentList}
          roleOptions={roleOptions}
          onClose={onCloseDepartmentList}
          ticketDepartments={ticketsDepartments}
          handleDepartmentChosen={handleDepartmentChosen}
        />
      ) : null}

      <Dialog
        open={open}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-describedby="scroll-dialog-description"
      >
        <AlertDialog 
          isOpen={alertDialogIsOpen}
          title="Tem certeza que deseja excluir essa demanda?"
          description="A demanda será excluída permanentemente."
          handleClose={() => setAlertDialogIsOpen(false)}
          handleConfirm={() => handleDeleteTicket()}
        />
        <DialogTitle>
          <Typography variant="h5">
            {`ID: ${chosenTicket.id_ticket} - ${chosenTicket.ticket_title} - ${
              chosenTicket.status_name ? chosenTicket.status_name : ""
            }`}
          </Typography>
        </DialogTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "25px",
            marginBottom: 16
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
          >
            <span>{`Usuário: ${chosenTicket.user_name}`}</span>
            <span>{`Aberto em: ${moment(chosenTicket.created_date).format(
              "DD/MM/YYYY HH:mm"
            )}`}</span>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            marginLeft={20}
          >
            <span>{`Loja: ${
              chosenTicket.store_name ? chosenTicket.store_name : ""
            }`}</span>
            <span>{`CNPJ: ${
              chosenTicket.store_ein_code ? chosenTicket.store_ein_code : ""
            }`}</span>
          </Box>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "25px",
            marginTop: "5px",
          }}
        >
          {chosenTicket ? (
            <div style={{ width: "30%", marginTop: "10px" }}>
              {/*<Select
                options={ticketsDepartments}
                value={chosenTicketDepartment}
                onChange={handleDepartmentChange}
                isMulti={false}
              ></Select>*/}
              <Autocomplete
                id="combo-box-department"
                options={ticketsDepartments}
                onChange={(event, newValue) =>
                  handleDepartmentChange(event, newValue)
                }
                defaultValue={chosenTicketDepartment}
                size={"small"}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Departamento"
                    variant="outlined"
                  />
                )}
              />
            </div>
          ) : null}
          {chosenTicket?.department?.id_ticket_dep_type === 2 ? (
            <div
              style={{ width: "30%", marginTop: "10px", marginLeft: "15px" }}
            >
              <Autocomplete
                id="combo-box-user-responsible"
                options={ticketUsersResponsible}
                onChange={(event, newValue) =>
                  handleUserResponsableChange(event, newValue)
                }
                defaultValue={ticketUserResponsible}
                size={"small"}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Responsável"
                    variant="outlined"
                  />
                )}
              />
            </div>
          ) : null}
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            style={{
              border: "0px",
              color: "black",
              textDecoration: "none",
            }}
          >
            <DatePicker
              label="Prazo"
              value={deadlineDate}
              style={{ width: "25%", marginLeft: "15px" }}
              format="dd/MM/yyyy"
              onChange={handleDeadlineDate}
            />
          </MuiPickersUtilsProvider>
        </div>

        <DialogContent
          style={{overflowX: 'clip', maxHeight: '8vh'}}
        >
          <DialogContentText
            style={{
              height: "100%",
              width: "100%",
              marginTop: "10px",
              marginLeft: "5px",
            }}
          >
            <div style={{ color: "#000", whiteSpace: "pre-wrap" }}>
              {chosenTicket.ticket_text}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertDialogIsOpen(true)} disabled={isSendingMessage}>Deletar demanda</Button>
          <Button onClick={handleClose} disabled={isSendingMessage}>Fechar</Button>
          <Button onClick={saveTicket} disabled={isSendingMessage}>Salvar</Button>
        </DialogActions>
        <DialogContent style={{ marginTop: 2, height: '50vh' }} dividers={true}>
          {answers.length > 0
            ? answers.map((answer, index) => (
              answer.ticket_attachment_url === null ?
              <TicketAnswerMessage 
                answer={answer}
                chosenTicket={chosenTicket}
                messagesEndRef={messagesEndRef}
                handleDeleteMessage={() => handleDeleteMessage(answer)}
                loggedUserId={user.id_user}
              />
              :
              <TicketAnswerAttachment 
                answer={answer}
                chosenTicket={chosenTicket}
                messagesEndRef={messagesEndRef}
                handleDeleteMessage={() => handleDeleteMessage(answer)}
                loggedUserId={user.id_user}
              />
            ))
            : null}
        </DialogContent>
        <DialogContent>
          {
            attachment && (
              <>
              {
                attachmentPreview !== null ? (
                  <>
                    <img 
                      src={attachmentPreview} 
                      alt="anexo"
                      style={{
                        width: '30%',
                        margin: '0 auto',
                        display: 'block',
                      }} 
                    />
                  </>
                )
                : null
              }
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  justifyContent='center'
                  marginTop={4}
                >
                  {
                    attachment && (
                      <>
                      {
                        attachmentPreview === null && (
                          <>
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{ marginRight: "8px", fontSize: "20px" }}
                              />
                          </>
                        )
                      }
                      </>
                    )
                  }
                  <span>
                    {attachment.name}
                  </span>
                  <Button 
                    onClick={clearSelectedAttachment}
                    style={{
                      marginLeft: 16
                    }}
                  >
                    Remover
                  </Button>
                </Box>
              </>
            )
          }
          {
            isSendingMessage && (
              <LinearProgress />
            )
          }
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            alignItems='flex-start'
            minHeight='12vh'
            maxHeight='15vh'
          >
            <IconButton 
              onClick={handleSendAttachment}
              disabled={isSendingMessage}
            >
              <AttachFile />
            </IconButton>
            <TextField
              autoFocus
              margin="dense"
              id="answer"
              label="Responder"
              fullWidth
              variant="standard"
              multiline
              onChange={(event) => handleMessageChange(event.target.value)}
              value={message}
              style={{margin: '0 10px'}}
              disabled={isSendingMessage}
            />
            <IconButton 
              onClick={handleSendAnswer}
              disabled={isSendingMessage}
            >
              <Send />
            </IconButton>
          </Box>
        </DialogContent>
        <input
          type="file"
          accept="image/png,image/jpeg,.pdf"
          onChange={onChangeFile}
          onClick={(event) => event.target.value = null}
          style={{display: "none"}}
          ref={fileInputRef}
        />
      </Dialog>

      <div
        style={{ display: "flex", marginTop: "50px", justifyContent: "center" }}
      >
        <div style={{ display: "flex", marginRight: "10px" }}>
          <Button
            variant="contained"
            onClick={() => handleOpenDepartmentList()}
          >
            <FontAwesomeIcon
              icon={faBuilding}
              style={{ marginRight: "8px", fontSize: "20px" }}
            />
            {"Selecionar Departamento"}
          </Button>
        </div>
        <div style={{ display: "flex" }}>
          <Button variant="contained" onClick={() => handleOpenFormList()}>
            <FontAwesomeIcon
              icon={faListAlt}
              style={{ marginRight: "8px", fontSize: "20px" }}
            />
            {"Ver Formulários"}
          </Button>
        </div>
      </div>
      <div
        style={{ display: "flex", marginTop: "15px", justifyContent: "center" }}
      >
        <h1>{`${userDepartment.label}`}</h1>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "20px",
          height: "100%",
          width: "100%",
          flex: 1,
          scrollBehavior: "smooth",
          overflow: "auto",
        }}
      >
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          {columns.map((column, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                key={column.id_column}
              >
                <h2>{column.name}</h2>
                <div style={{ margin: 5 }}>
                  <Droppable
                    droppableId={column.id_column}
                    key={column.id_column}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightblue"
                              : "lightgrey",
                            padding: 4,
                            width: 225,
                            minHeight: 500,
                            borderRadius: 5,
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={String(item.id_ticket)}
                                draggableId={String(item.id_ticket)}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      onClick={() => handleClickOpen(item)}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        padding: 10,
                                        margin: "2px 0 8px 3px",
                                        minHeight: "50px",
                                        width: "88%",
                                        borderRadius: 10,
                                        backgroundColor: snapshot.isDragging
                                          ? "#263B4A"
                                          : "#456C86",
                                        color: "white",
                                        ...provided.draggableProps.style,
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flex: 3,
                                          flexDirection: "column",
                                        }}
                                      >
                                        <a
                                          style={{
                                            display: "flex",
                                            flex: 1,
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >{`${item.ticket_title}`}</a>
                                        <a
                                          style={{
                                            display: "flex",
                                            flex: 1,
                                            fontSize: 12,
                                            padding: "5px",
                                          }}
                                        >{`${item.department.ticket_department_name}`}</a>
                                        <a
                                          style={{
                                            display: "flex",
                                            flex: 1,
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            background:
                                              item.deadline_status === 1
                                                ? "#f2d401"
                                                : item.deadline_status === 2
                                                ? "#fa290f"
                                                : item.deadline_status === 3
                                                ? "#ed9388"
                                                : item.deadline_status === 4
                                                ? "#53ca40"
                                                : "gray",
                                            borderRadius: 20,
                                            width: "150px",
                                            padding: "5px",
                                          }}
                                        >{`Prazo: ${
                                          item.deadline_date
                                            ? moment(item.deadline_date).format(
                                                "DD/MM/YYYY"
                                              )
                                            : "não definido"
                                        }`}</a>
                                      </div>
                                      {item.answers &&
                                      item.answers.length > 0 ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            flex: 0.5,
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <div
                                            style={{
                                              flex: 0,
                                              borderRadius: 20,
                                              background: "#F02848",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              display: "flex",
                                              fontSize: 12,
                                              padding: "5px",
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faCommentAlt}
                                              style={{
                                                fontSize: "1.0em",
                                                verticalAlign: "middle",
                                              }}
                                            />
                                            <a
                                              style={{
                                                marginLeft: 5,
                                                fontSize: 12,
                                              }}
                                            >
                                              {item.answers.length > 99
                                                ? "99+"
                                                : item.answers.length}
                                            </a>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </PerfectScrollbar>
  );
}

export default AdminTicketScreen;
