import React, { Component } from "react";
import Selling_Region from '../../../models/Selling_Region';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Grid } from '@material-ui/core';

class SRFilteredSelect extends Component {

  selling_optionReload = 1;

  state = {
    brand_value: {},
    selling_region: {},
    selling_regionsList: [],
    brands: [],
    selling_region_value: {},
    index: {}
  };

  constructor(props) {
    super(props);
    this.setStateFromProps(props);
  }

  setStateFromProps = function(props) {
    this.object = props.object;
    this.state.brands = props.brands;
    this.SRUpdateStore = props.SRUpdateStore;
    this.SRRemove = props.SRRemove;
    this.state.selling_region = props.selling_region;
    this.state.index = props.index;
    if (this.state.selling_region != null) {
      this.state.selling_region_value = {
        value: this.state.selling_region.id_selling_region,
        label: this.state.selling_region.selling_region_name
      };
      for (let i = 0; i < this.state.brands.length; i++) {
        if (this.state.brands[i].id_brand == this.state.selling_region.id_brand) {
          this.state.brand_value = {
            value: this.state.brands[i].id_brand,
            label: this.state.brands[i].brand_name
          }
        }
      }
    } else{
      this.state.selling_region_value = {
        value: {},
        label: {}
      };

      this.state.brand_value = {
        value: {},
        label: {}
      };
    }

    this.brand_options = this.state.brands.map((brand) => {
      return {
        value: brand.id_brand,
        label: brand.brand_name
      }
    });
  };

  // Ensures that updated state will be re-rendered!
  componentWillReceiveProps(nextProps) {
    this.setStateFromProps(nextProps);
  }

  // function for AsyncSelect(need a bugfix, constantly making request)
  SRMakeSelling_RegionsOptions = function (object, callback) {
    Selling_Region.query({ id_brand: object.state.brand_value.value }).then((selling_regions) => {
      let selling_regionOptions = selling_regions;
      selling_regionOptions = selling_regionOptions.map((selling_region) => {
        return {
          value: selling_region.id_selling_region,
          label: selling_region.selling_region_name
        }
      });
      callback(selling_regionOptions);
    })
  };

  Selling_RegionOnChangeHandler = (value) => {
    this.state.selling_region = {id_selling_region: value.value, selling_region_name: value.label};
    this.setState({ selling_regionsList: [] });
    this.setState({ selling_region_value: value });
    this.SRUpdateStore(this.state.selling_region, this.state.index);
  };
  

  BrandOnChangeHandler = (value) => {
    this.setState({ brand_value: value });
    this.selling_optionReload++;
  };

  render() {
    return (
      <Grid xs={12} container style={{ marginTop: '10px' }}>
        <Grid item xs={5} style={{ marginTop: '5px' }}>
          <Select placeholder={'Selecione a Marca'} options={this.brand_options}
            value={this.state.brand_value?this.state.brand_value:'b'}
            onChange={this.BrandOnChangeHandler} />
        </Grid>
        <Grid item xs={6} style={{ marginTop: '5px' }}>
          <AsyncSelect id='regionSelect' placeholder={'Selecione a Região'} 
            defaultOptions
            key={this.selling_optionReload}
            loadOptions={(input_value, callback) => {
              this.SRMakeSelling_RegionsOptions(this, callback);
            }}
            value={this.state.selling_region_value?this.state.selling_region_value:'b'}
            onChange={this.Selling_RegionOnChangeHandler} />
        </Grid>
        <Grid item xs={1}>
          <i className="fa fa-fw fa-trash" style={{ fontSize: '1.75em', marginTop: '10px', height:'auto', width: 'auto' }} onClick={(index) => this.SRRemove(this.state.index)}/>
        </Grid>

      </Grid>

    )
  }
}
export default SRFilteredSelect;