import { Button, Grid, Paper, TextField } from '@material-ui/core';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteButton from '../buttons/DeleteButton';
import AttachFileButton from '../buttons/AttachFileButton';
import ListUserButton from '../buttons/ListUserButton';
import { DateTimePicker } from '@material-ui/pickers/';
import { CustomDropzone } from '../CustomDropzone';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';
import SRFilteredSelect from '../selectbox/SRFilteredSelect';
import SearchableSelect from '../../components/selectbox/SearchableSelect.js';
import SaveButton from '../buttons/SaveButton';
import Store from '../../../models/Store';
import '../../../../node_modules/rodal/lib/rodal.css';
import Rodal from 'rodal';
import { toast } from 'react-toastify';

export default function StoreDescription(props) {
  const appsHasStoreRoleDefined = [45];
  const idAppSelected = localStorage.getItem("ID_APP");

  this.editionTimeout = null;

  // Object to Render
  this.object = props.object;

  //FUNCTIONS
  this.saveStore = props.saveObject;
  this.updateStore = props.updateObject;
  this.deleteStore = props.deleteObject;
  this.nullDescription = props.nullDescription;
  this.relations = props.relations;
  this.isCalled = props.isCalled;
  this.openAttachModal = props.openAttachModal;
  this.openUserListModal = props.openUserListModal;

  this.roles = props.relations[0];
  this.users = props.users;
  this.attachModalVisible = props.attachModalVisible;

  this.object.roleOptions = this.roles.map((role) => {
    let roleOption = {
      value: role.id_role,
      label: role.role_name,
    };
    return roleOption;
  });

  let objectRole = this.roles.filter((value) => {
    return this.object.id_role ? value.id_role === this.object.id_role : false;
  })[0];

  if (objectRole) {
    this.object.defaultRoleValue = {
      value: objectRole.id_role,
      label: objectRole.role_name,
    };
  } else {
    this.object.defaultRoleValue = [];
  }

  this.object.userOptions = this.users.map((user) => {
    let userOption = {
      value: user.id_user,
      label:
        user.user_name && user.user_name !== ''
          ? user.user_name
          : user.user_phone,
    };
    return userOption;
  });

  let objectUser = this.users.filter((value) => {
    return this.object.id_user ? value.id_user === this.object.id_user : false;
  })[0];

  if (objectUser) {
    this.object.defaultUserValue = {
      value: objectUser.id_user,
      label:
        objectUser.user_name && objectUser.user_name !== ''
          ? objectUser.user_name
          : objectUser.user_phone,
    };
  } else {
    this.object.defaultUserValue = [];
  }

  this.onChangeUpdateRole = (value) => {
    if(this.object.defaultRoleValue.value === value.value){
      this.object.defaultRoleValue = null;
    }else{
      this.object.defaultRoleValue = { value: value.value, label: value.label };
    }

    this.object.id_role = this.object.defaultRoleValue ? this.object.defaultRoleValue.value : null;
    this.object.role = null;
    this.updateStore(this.object, 'id_role', this.object.id_role, true);
  };

  this.onChangeUpdateUser = (value) => {
    this.object.id_user = value.value;
    this.object.user = null;
    this.object.defaultUserValue = { value: value.value, label: value.label };
    this.updateStore(this.object, 'id_user', this.object.id_user, true);
  };

  this.onClickDeleteStore = () => {
    if (window.confirm('Deseja mesmo deletar esse item?')) {
      this.nullDescription(); //make the genericadminscreen change the descriptionCard specs to null before delete the store
      this.deleteStore(this.object);
    }
  };

  this.onClickSaveStore = () => {
    if (
      this.object.store_name === null ||
      this.object.store_name === undefined
    ) {
      toast.info('Para salvar adicione um nome');
      return;
    } else if (this.object.store_name?.trim().length === 0) {
      toast.info('Para salvar adicione um nome');
      return;
    }
    this.saveStore(this.object);
  };

  this.onChangeUpdate = (field, force) => (event) => {
    let value = event && event.target ? event.target.value : null;
    this.updateStore(this.object, field, value, force);
  };

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        isNumericString
        allowNegative={false}
        decimalScale={0}
      />
    );
  }

  this.onDropActionCoverImage = async (file) => {
    file = file[0];

    const progressFunction = (completed) => {
      const completedState = {
        completedCoverImage: completed,
      };
      props.progressFunction(completedState);
    };

    try {
      let imageUrl = null;

      imageUrl = await Store.sendCoverImage(file, progressFunction);
      this.updateStore(this.object, 'store_image_file', imageUrl.url, true);

      // Reset completed variable (progress bar)
      progressFunction(0);
    } catch (err) {
      console.error(err);
    }
  };

  this.deleteFile = () => {
    if (this.object && this.object.store_image_file) {
      return this.updateStore(this.object, 'store_image_file', null, true);
    }
  };

  this.onClickOpenAttachModal = () => {
    this.openAttachModal();
  };

  this.onClickOpenUserListModal = () => {
    this.openUserListModal();
  };

  return (
    <div style={{ height: 'auto' }} key={this.keyReload}>
      <Grid xs={12} container spacing={2}>
        <Grid xs={12}>
          {this.object && this.object.store_image_file ? (
            <img
              style={{
                width: '30vw',
                height: '15vw',
                objectFit: 'cover',
              }}
              src={
                this.object.store_image_file
                  ? this.object.store_image_file
                  : null
              }
              id={'image-modal'}
            />
          ) : (
            <CustomDropzone
              onDropAction={this.onDropActionCoverImage}
              completed={props.completed.completedCoverImage}
              //daniremove
              inactiveLabel={
                'Arraste a imagem(.jpeg, .png, .gif) da loja para cá '
              }
              activeLabel={'Solte a imagem da loja'}
            />
          )}

          <Grid xs={12}>
            <span
              style={{
                fontSize: '12px',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
                lineHeight: 1,
                letterSpacing: '0.00938em',
                float: 'bottom',
                color: 'rgba(0, 0, 0, 0.54)',
                paddingBottom: '10px',
              }}
              //   daniremove
            >
              Tamanho Máximo da Imagem: 10 Mb{' '}
              {this.object && this.object.store_image_file ? (
                <a href='#' onClick={this.deleteFile}>
                  Remover Capa da Loja
                </a>
              ) : null}
            </span>
          </Grid>
        </Grid>
        <Grid xs={1}></Grid>
        <Grid
          xs={12}
          container
          direction='row'
          spacing={1}
          style={{ marginTop: '10px' }}
        >
          <Grid xs={6} item>
            <TextField
              label='Nome'
              value={this.object.store_name ? this.object.store_name : ''}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('store_name', false)}
              onBlur={this.onChangeUpdate('store_name', true)}
            />
          </Grid>
          <Grid xs={5} item>
            <TextField
              label='CNPJ'
              value={
                this.object.store_ein_code ? this.object.store_ein_code : ''
              }
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('store_ein_code', false)}
              onBlur={this.onChangeUpdate('store_ein_code', true)}
            />
          </Grid>
          <Grid xs={1} item>
            <TextField
              label='ID'
              value={this.object.id_store}
              style={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid xs={6} item spacing={2} style={{ marginTop: '5px' }}>
            <Grid xs={12}>
              <Select
                placeholder={'Selecione o Usuário Responsável'}
                options={this.object.userOptions}
                value={this.object.defaultUserValue}
                onChange={this.onChangeUpdateUser}
              />
            </Grid>
          </Grid>
          <Grid xs={6} item spacing={2} style={{ marginTop: '5px' }}>
            <Grid xs={12}>
              {
                appsHasStoreRoleDefined.includes(Number(idAppSelected)) && (
                  <Select
                    placeholder={'Selecione o Papel'} 
                    options={this.object.roleOptions}
                    value={this.object.defaultRoleValue} 
                    onChange={this.onChangeUpdateRole}
                  />
                )
              }
            </Grid>
          </Grid>
          <Grid xs={6} item>
            <TextField
              label='Endereço'
              value={this.object.store_adress ? this.object.store_adress : ''}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('store_adress', false)}
              onBlur={this.onChangeUpdate('store_adress', true)}
            />
          </Grid>
          <Grid xs={3} item>
            <TextField
              label='Cidade '
              value={this.object.city}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('city', false)}
              onBlur={this.onChangeUpdate('city', true)}
            />
          </Grid>
          <Grid xs={1} item>
            <TextField
              label='UF'
              value={this.object.state}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('state', false)}
              onBlur={this.onChangeUpdate('state', true)}
              inputProps={{ maxLength: 2 }}
            />
          </Grid>
          <Grid xs={2} item>
            <TextField
              label='CEP'
              value={this.object.store_zip_code}
              style={{ width: '100%' }}
              onChange={this.onChangeUpdate('store_zip_code', false)}
              onBlur={this.onChangeUpdate('store_zip_code', true)}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>

          <Grid xs={12} />
        </Grid>

        <Grid
          xs={12}
          style={{ marginTop: '20px' }}
          spacing={1}
          container
          direction='row'
        >
          <Grid xs={2} item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                label='Data de Criação'
                value={this.object.created_date}
                style={{ width: '100%' }}
                format='dd/MM/yyyy HH:mm'
                disabled
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid xs={2} item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                label='Data da ultima alteração'
                value={this.object.updated_date}
                style={{ width: '100%' }}
                format='dd/MM/yyyy HH:mm'
                disabled
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid xs={8} style={{ marginTop: '5px' }} item>
            <SaveButton
              style={{ float: 'right' }}
              onClickSave={this.onClickSaveStore}
              ref={(ref) => {
                this.saveButton = ref;
              }}
            />
            <DeleteButton
              style={{ float: 'right', background: '#CC0B0B' }}
              onClickDelete={this.onClickDeleteStore}
            />
            <AttachFileButton
              disabled={
                !this.object.store_name || this.object.store_name === ''
              }
              style={{ float: 'right', background: 'grey' }}
              onClickOpenAttachModal={this.onClickOpenAttachModal}
            />
            <ListUserButton
              disabled={
                !this.object.store_name || this.object.store_name === ''
              }
              style={{ float: 'right', background: '#092023' }}
              onClickOpenListUserModal={this.onClickOpenUserListModal}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
