import React, { Component } from "react";
import GenericAdminScreen from "../components/generic_screens/GenericAdminScreen";
// DB Models
import Selling_Region from "../../models/Selling_Region";
import Brand from "../../models/Brand";
import Store from "../../models/Store";
import Selling_RegionCard from "../components/cards/Selling_RegionCard";
import Selling_RegionDescription from "../components/description_cards/Selling_RegionDescription.js";
import Product from "../../models/Product";

class AdminCommunicationChannelScreen extends Component {
  state = {
    id_app: null,
  };

  componentDidMount() {
    this.state.id_app = localStorage.getItem("ID_APP");
  }

  render() {
    return (
      <div style={{ height: "100%", padding: "0px" }} key={this.keyReload}>
        <iframe
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          src={
            localStorage.getItem("ID_APP") == 1
              ? "https://airtable.com/embed/shr4OSVbuJwQ2IZu4?prefill_Cliente=Inside%20The%20Box"
              : localStorage.getItem("ID_APP") == 3
              ? "https://airtable.com/embed/shr6PyTl4aPPHbHwk?prefill_Cliente=Consultora%20dos%20Cachos"
              : localStorage.getItem("ID_APP") == 4
              ? "https://airtable.com/embed/shregAjsDOSHsmc08?prefill_Cliente=Kenner"
              : localStorage.getItem("ID_APP") == 5
              ? "https://airtable.com/embed/shrno2IzxicuoKFCO?prefill_Cliente=We%20Representações"
              : localStorage.getItem("ID_APP") == 6
              ? "https://airtable.com/embed/shrgvWPQBtFoK5Nlh?prefill_Cliente=Alberts"
              : localStorage.getItem("ID_APP") == 7
              ? "https://airtable.com/embed/shrUAKsXqPG7A1S51?prefill_Cliente=Beagle"
              : localStorage.getItem("ID_APP") == 8
              ? "https://airtable.com/embed/shrjKaTITSZ7k73f0?prefill_Cliente=PSVarejo"
              : localStorage.getItem("ID_APP") == 9
              ? "https://airtable.com/embed/shr5X8tfYjCHaQhf0?prefill_Cliente=Ferrero"
              : localStorage.getItem("ID_APP") == 10
              ? "https://airtable.com/embed/shr8tz26Ib3iN4yAi?prefill_Cliente=JAB"
              : localStorage.getItem("ID_APP") == 11
              ? "https://airtable.com/embed/shrOgYiH8Wqygew4J?prefill_Cliente=Cellairis"
              : localStorage.getItem("ID_APP") == 12
              ? "https://airtable.com/embed/shro95g03W3UX8vx9?prefill_Cliente=Café%20Cultura"
              : localStorage.getItem("ID_APP") == 13
              ? "https://airtable.com/embed/shr3oKrfpxlZvNKAA?prefill_Cliente=Mil%20Bijus"
              : localStorage.getItem("ID_APP") == 14
              ? "https://airtable.com/embed/shrNxRyt95iojNI2g?prefill_Cliente=Famiglia%20Altieri"
              : localStorage.getItem("ID_APP") == 15
              ? "https://airtable.com/embed/shrSCqXbY7CD6vtjU?prefill_Cliente=Demo%20-%20Moda"
              : localStorage.getItem("ID_APP") == 21
              ? "https://airtable.com/embed/shry2Zb9zDuBTmXIX?prefill_Cliente=Demo%20-%20Cosmético"
              : localStorage.getItem("ID_APP") == 22
              ? "https://airtable.com/embed/shrxqkIc8udWyevUH?prefill_Cliente=Demo%20-%201"
              : localStorage.getItem("ID_APP") == 23
              ? "https://airtable.com/embed/shr2Ynf7YZGMijz3u?prefill_Cliente=PopTech"
              : localStorage.getItem("ID_APP") == 24
              ? "https://airtable.com/embed/shrkGrINJisck9vNX?prefill_Cliente=La%20Moda"
              : localStorage.getItem("ID_APP") == 25
              ? "https://airtable.com/embed/shrBGbsPuO9sKIY0R?prefill_Cliente=Forneria%20Catarina"
              : localStorage.getItem("ID_APP") == 26
              ? "https://airtable.com/embed/shrmkCxZpmPUXius9?prefill_Cliente=À%20Lenha"
              : localStorage.getItem("ID_APP") == 27
              ? "https://airtable.com/embed/shrSSdKWfxxE5XrCg?prefill_Cliente=Flow"
              : localStorage.getItem("ID_APP") == 31
              ? "https://airtable.com/embed/shrNERO7gZg9ZCg9O?prefill_Cliente=Di%20Valentini"
              : localStorage.getItem("ID_APP") == 32
              ? "https://airtable.com/embed/shr4witmUaujt9BWc?prefill_Cliente=Ojo"
              : localStorage.getItem("ID_APP") == 33
              ? "https://airtable.com/embed/shrekrcuTlYVsnF7I?prefill_Cliente=Sense%20Spa"
              : localStorage.getItem("ID_APP") == 34
              ? "https://airtable.com/embed/shr2EjTTvv6Fv5h5n?prefill_Cliente=Day%20Off%20Café"
              : localStorage.getItem("ID_APP") == 35
              ? "https://airtable.com/embed/shrNzCvtjX7CvS52t?prefill_Cliente=The%20Fifties"
              : localStorage.getItem("ID_APP") == 36
              ? "https://airtable.com/embed/shreRx8w6huhsAObE?prefill_Cliente=Demo%20-%202"
              : localStorage.getItem("ID_APP") == 37
              ? "https://airtable.com/embed/shr5xu4m7tCQPjHuX?prefill_Cliente=Demo-Café"
              : localStorage.getItem("ID_APP") == 38
              ? "https://airtable.com/embed/shrNES30ImA1BXzy2?prefill_Cliente=Demo%203"
              : localStorage.getItem("ID_APP") == 39
              ? "https://airtable.com/embed/shrbfA00O4YwbwqC4?prefill_Cliente=Demo%20-%20RI"
              : localStorage.getItem("ID_APP") == 40
              ? "https://airtable.com/embed/shrdBzpybIUNSWOsA?prefill_Cliente=Demo%20-%205"
              : localStorage.getItem("ID_APP") == 41
              ? "https://airtable.com/embed/shrVS6BYKlaYlNc9n?prefill_Cliente=Demo%20-%20Halipar"
              : localStorage.getItem("ID_APP") == 42
              ? "https://airtable.com/embed/shrLR6r4gx1YL4QOH?prefill_Cliente=Box%20Mineiro"
              : localStorage.getItem("ID_APP") == 43
              ? "https://airtable.com/embed/shrfl4bmhrE2Sw3su?prefill_Cliente=Dullius"
              : localStorage.getItem("ID_APP") == 44
              ? "https://airtable.com/embed/shrO0EgQq4sypFVua?prefill_Cliente=Flow%20Coffe"
              : localStorage.getItem("ID_APP") == 45
              ? "https://airtable.com/embed/shrGVFaWXBpsCLlay?prefill_Cliente=Usaflex"
              : localStorage.getItem("ID_APP") == 46
              ? "https://airtable.com/embed/shrFbYcfqLL8GDl1u?prefill_Cliente=Mana%20Poke"
              : localStorage.getItem("ID_APP") == 48
              ? "https://airtable.com/embed/shrIAJp6X1kDjhoMB?prefill_Cliente=Bio%20Mundo"
              : null
          }
          frameborder="0"
          onmousewheel=""
          width="100%"
          height="100%"
          style="background: transparent; border: 1px solid #ccc;"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      </div>
    );
  }
}

export default AdminCommunicationChannelScreen;
