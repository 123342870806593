import React from 'react';
import { Grid, Paper } from '@material-ui/core';

export default function FaqTopicCard(props) {

	this.editionTimeout = null;

	// Object to Render
	this.object = props.object;

	// Functions
	this.updateFaqTopic = props.updateObject;
	this.deleteFaqTopic = props.deleteObject;
	this.callDescription = props.callDescription;

	this.onChangeUpdate = (field, force) => event => {
		let value = (event && event.target) ? event.target.value : null;
		this.updateFaqTopic(this.object, field, value, force);
	};

	this.onClickCallDescription = () => {
	  this.callDescription(this.object);
	};

	return (
		<Paper
			style={{
				'height':'100px',
				'backgroundColor': (props.chosenId === this.object.id_faq_topic ? '#4498A7' : '#f2f2f2')
			}}
			onClick={this.onClickCallDescription}
		>
			<Grid container>
				<Grid xs={3}>
					<div style={{height:'80px', background:'#f2f2f2', top:'10px', margin:'auto', position: 'relative', width:'75px'}} />
				</Grid>
				<Grid xs={4} style={{margin:'auto'}}>
					<span style={{float: 'left', padding: '0', fontSize: '18px',
						fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
						letterSpacing: '0.00938em'}}>{this.object.faq_topic ? this.object.faq_topic : ''}</span>
				</Grid>
				<Grid xs={5} style={{margin:'auto'}}>
					<span style={{float: 'left', padding: '0', fontSize: '18px',
						fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: '400', lineHeight: '1',
						letterSpacing: '0.00938em'}}>{this.object.faq_topic_email ? this.object.faq_topic_email : ''}</span>
				</Grid>
			</Grid>
		</Paper>
	);
}