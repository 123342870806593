import GenericModel from "./generic_model/GenericModel";
import { serialize } from "../utils";
const API = "/content";

class Content extends GenericModel {
  API = API;
  static API = API;

  static statistics = async function (query) {
    let queryString = serialize(query);

    let authHeader = null;
    try {
      authHeader = await GenericModel.insertAuthHeader();

      const response = await fetch(API + "/statistics?" + queryString, {
        headers: authHeader,
      });

      const body = await response.json();

      if (response.status !== 200) throw new Error(body.message);

      return body;
    } catch (err) {
      throw Error(err);
    }
  };

  static getexcel = async function (id) {
    let authHeader = null;
    try {
      authHeader = await GenericModel.insertAuthHeader();

      const response = await fetch(API + "/getexcel/" + id, {
        headers: authHeader,
      });

      const body = await response.blob();

      if (response.status !== 200) throw new Error(body.message);

      return body;
    } catch (err) {
      throw Error(err);
    }
  };

  static getInbox = async function () {
    let authHeader = null;
    try {
      authHeader = await GenericModel.insertAuthHeader();

      const response = await fetch(API + "/inbox/", {
        headers: authHeader,
      });

      const body = await response.json();

      if (response.status !== 200) throw new Error(body.message);

      return body;
    } catch (err) {
      throw Error(err);
    }
  };

  static getContentTrail = async function (id) {
    let queryString = "";
    if (id) {
      queryString = id;
    }

    let authHeader = null;
    try {
      let that = this;
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(
        this.API + "/getContentTrail/" + queryString,
        {
          headers: authHeader,
        }
      );
      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

      let ans = {};

      function mountObjArray(elementsArray) {
        let result = [];

        for (let i = 0; i < elementsArray.length; i++) {
          result.push(new GenericModel(elementsArray[i], that.API));
        }

        return result;
      }

      if (body && body.elements) {
        for (let prop in body) {
          if (body.hasOwnProperty(prop) && prop !== "elements") {
            ans[prop] = body[prop];
          }
        }
        ans.elements = mountObjArray(body.elements);
      } else if (body instanceof Array) {
        ans = mountObjArray(body);
      } else {
        ans = new GenericModel(body, that.API);
      }

      return ans;
    } catch (err) {
      throw Error(err);
    }
  };

  static getTicketForms = async function (id_department) {

    let queryString = "";
    if (id_department) {
        queryString = "id_department=" + id_department;
    }


    let authHeader = null;
    try {
        let that = this;
        authHeader = await GenericModel.insertAuthHeader();
        const response = await fetch(this.API + '/getTicketForms/' + "?" + queryString, {
            headers: authHeader,
        });
        const body = await response.json();

       //console.log({body});

        if (response.status !== 200) throw Error(body.message);

        let ans = {};

        function mountObjArray(elementsArray) {
            let result = [];

            for (let i = 0; i < elementsArray.length; i++) {
                result.push(new GenericModel(elementsArray[i], that.API));
            }

            return result;
        }

        if (body && body.elements) {
            for (let prop in body) {
                if (body.hasOwnProperty(prop) && prop !== "elements") {
                    ans[prop] = body[prop];
                }
            }
            ans.elements = mountObjArray(body.elements);
        } else if (body instanceof Array) {
            ans = mountObjArray(body);
        } else {
            ans = new GenericModel(body, that.API);
        }

        return ans;
    } catch (err) {
        throw Error(err);
    }
};

static getMessages = async function (id_message) {

    let authHeader = null;
    try {
        let that = this;
        authHeader = await GenericModel.insertAuthHeader();
        const response = await fetch(this.API + '/getMessage/' + id_message, {
            headers: authHeader,
        });
        const body = await response.json();

        if (response.status !== 200) throw Error(body.message);

        let ans = {};

        function mountObjArray(elementsArray) {
            let result = [];

            for (let i = 0; i < elementsArray.length; i++) {
                result.push(new GenericModel(elementsArray[i], that.API));
            }

            return result;
        }

        if (body && body.elements) {
            for (let prop in body) {
                if (body.hasOwnProperty(prop) && prop !== "elements") {
                    ans[prop] = body[prop];
                }
            }
            ans.elements = mountObjArray(body.elements);
        } else if (body instanceof Array) {
            ans = mountObjArray(body);
        } else {
            ans = new GenericModel(body, that.API);
        }

        return ans;
    } catch (err) {
        throw Error(err);
    }
};

  static getResearches = async function (query) {
    let queryString = "";
    if (query) {
      queryString = serialize(query);
    }

    let authHeader = null;
    try {
      let that = this;
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(
        this.API + "/getResearches/" + "?" + queryString,
        {
          headers: authHeader,
        }
      );
      const body = await response.json();

      //console.log({body});

      if (response.status !== 200) throw Error(body.message);

      let ans = {};

      function mountObjArray(elementsArray) {
        let result = [];

        for (let i = 0; i < elementsArray.length; i++) {
          result.push(new GenericModel(elementsArray[i], that.API));
        }

        return result;
      }

      if (body && body.elements) {
        for (let prop in body) {
          if (body.hasOwnProperty(prop) && prop !== "elements") {
            ans[prop] = body[prop];
          }
        }
        ans.elements = mountObjArray(body.elements);
      } else if (body instanceof Array) {
        ans = mountObjArray(body);
      } else {
        ans = new GenericModel(body, that.API);
      }

      return ans;
    } catch (err) {
      throw Error(err);
    }
  };

  static getResearchesNew = async function (query) {
    let queryString = "";
    if (query) {
      queryString = serialize(query);
    }

    let authHeader = null;
    try {
      let that = this;
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(
        this.API + "/getResearches-new/" + "?" + queryString,
        {
          headers: authHeader,
        }
      );
      const body = await response.json();

      //console.log({body});

      if (response.status !== 200) throw Error(body.message);

      let ans = {};

      function mountObjArray(elementsArray) {
        let result = [];

        for (let i = 0; i < elementsArray.length; i++) {
          result.push(new GenericModel(elementsArray[i], that.API));
        }

        return result;
      }

      if (body && body.elements) {
        for (let prop in body) {
          if (body.hasOwnProperty(prop) && prop !== "elements") {
            ans[prop] = body[prop];
          }
        }
        ans.elements = mountObjArray(body.elements);
      } else if (body instanceof Array) {
        ans = mountObjArray(body);
      } else {
        ans = new GenericModel(body, that.API);
      }

      return ans;
    } catch (err) {
      throw Error(err);
    }
  };

  static getMessages = async function (id_message) {
    let authHeader = null;
    try {
      let that = this;
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(this.API + "/getMessage/" + id_message, {
        headers: authHeader,
      });
      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

      let ans = {};

      function mountObjArray(elementsArray) {
        let result = [];

        for (let i = 0; i < elementsArray.length; i++) {
          result.push(new GenericModel(elementsArray[i], that.API));
        }

        return result;
      }

      if (body && body.elements) {
        for (let prop in body) {
          if (body.hasOwnProperty(prop) && prop !== "elements") {
            ans[prop] = body[prop];
          }
        }
        ans.elements = mountObjArray(body.elements);
      } else if (body instanceof Array) {
        ans = mountObjArray(body);
      } else {
        ans = new GenericModel(body, that.API);
      }

      return ans;
    } catch (err) {
      throw Error(err);
    }
  };

  saveFormTicket = async function () {
    let that = this;

    let authHeader = {};

    try {
        authHeader = await GenericModel.insertAuthHeader({
            Accept: "application/json",
            "Content-Type": "application/json",
        });

        let response = await fetch(that.API + '/content_form_ticket', {
            method: "POST",
            headers: authHeader,
            body: JSON.stringify(this),
        });

        if (response.status >= 400) {
            throw Error(response.statusText);
        }

        let object = await response.json();

        return new GenericModel(object, that.API);
    } catch (err) {
        throw Error(err);
    }
};
  saveFormResearchNew = async function () {
    let that = this;

    let authHeader = {};

    try {
        authHeader = await GenericModel.insertAuthHeader({
            Accept: "application/json",
            "Content-Type": "application/json",
        });

        let response = await fetch(that.API + '/content_form_research_new', {
            method: "POST",
            headers: authHeader,
            body: JSON.stringify(this),
        });

        if (response.status >= 400) {
            throw Error(response.statusText);
        }

        let object = await response.json();

        return new GenericModel(object, that.API);
    } catch (err) {
        throw Error(err);
    }
};

  static filters = async function (query) {
    let queryString = serialize(query);

    let authHeader = null;

    try {
      authHeader = await GenericModel.insertAuthHeader();
      const response = await fetch(this.API + "/filters?" + queryString, {
        headers: authHeader,
      });

      const body = await response.json();

      if (response.status !== 200) throw Error(body.message);

      return body;
    } catch (err) {
      throw Error(err);
    }
  };

  saveTrailClassWe = async function () {
    let that = this;

    let authHeader = {};

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API + "/content_trail_class_we", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  };

  saveTrail = async function () {
    let that = this;

    let authHeader = {};

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API + "/content_trail", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  };

  saveMessage = async function () {
    let that = this;
    let authHeader = {};

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API + "/content_message", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  };

  saveFormResearch = async function () {
    let that = this;

    let authHeader = {};

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API + "/content_form_research", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  };

  saveRoutine = async function () {
    let that = this;

    let authHeader = {};

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API + "/content_routines", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  };

  saveMainTrail = async function () {
    let that = this;

    let authHeader = {};

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API + "/content_main_trail", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify(this),
      });

      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  };
  /**
  static predecessor_content = async function (id) {
    console.log(id);
    console.log("aqui");
    let that = this;
    let authHeader = {};
    //stop hi chamado dani

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      });

      let response = await fetch(that.API + "/predecessor_content", {
        method: "POST",
        headers: authHeader,
        body: JSON.stringify({ id_predecessor_content: id }),
      });
      if (response.status >= 400) {
        throw Error(response.statusText);
      }

      let object = await response.json();

      return new GenericModel(object, that.API);
    } catch (err) {
      throw Error(err);
    }
  }; 

  */
}

export default Content;
