import React, { useEffect, useState, useRef } from 'react';
import { Box, Container, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grow from '@mui/material/Grow';
import Popover from '@mui/material/Popover';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Stack } from '@mui/material';

import VisualMerchandisingTable from './components/VisualMerchandisingTable';
import { SearchInput } from '../../components/text_fields/ThemeTextInputs';
import VisualMerchandisingAnswersTable from './components/VisualMerchandisingAnswersTable';
import { ButtonLightBlue, CustomIconButton } from '../../components/buttons/ThemeButtons';
import { CustomTooltip } from '../../components/tooltip/ThemeTooltips';
import VisualMerchandising from '../../../models/VisualMerchandising';
import VisualMerchandisingAnswer from '../../../models/VisualMerchandisingAnswer';

const CustomTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    width: '100%'
  },
  indicator: {
    backgroundColor: '#F28C1F',
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontSize: 19,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#333',
      opacity: 1,
    },
    '&$selected': {
      color: '#333',
      fontWeight: 'bold'
    },
    '&:focus': {
      color: '#333',
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

function Pagination({ page, totalCount, isLoading, handleNextPage, handlePreviousPage }) {
  const maxPage = Math.floor(totalCount / 20);
  const restContent = page === maxPage ? totalCount % 20 : 0;
  const finalContentCount = page !== maxPage  ? (20 * (page + 1)) : (20 * (page))

  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      paddingBottom={2}
    >
      {
        page !== 0 ? (
          <CustomIconButton
            disabled={isLoading}
            onClick={handlePreviousPage}
          >
            <ArrowBackIcon />
          </CustomIconButton>
        )
          : null
      }

      <span
        style={{
          color: "#666666",
          fontSize: 15
        }}
      >
        Exibindo {page * 20 + 1} - {finalContentCount + restContent} de {totalCount}
      </span>
      {
        page !== maxPage ? (
          <CustomIconButton
            disabled={isLoading}
            onClick={handleNextPage}
          >
            <ArrowForwardIcon />
          </CustomIconButton>
        ) : null
      }
    </Box>
  );
}

function FilterOptions({
  anchorEl, 
  handleClose, 
  onChangeValue, 
  activeFilters, 
  clearFilters,
  activeTab
}){
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return(
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      sx={{
        borderRadius: 8
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
    <Box p={2}>
      <Stack direction="row" justifyContent='space-between' alignItems="center">
        <span
          style={{
            marginRight: 24,
            fontWeight: 600,
            color: "#333333",
            fontSize: 19
          }}
        >
          Selecionar filtros
        </span>
        <CustomTooltip title="Limpar filtro">
          <CustomIconButton
            onClick={clearFilters}
            sx={{
              '&:hover': {
               color: "#B00020",
              }
            }}
          >
            <DeleteIcon />
          </CustomIconButton>
        </CustomTooltip>
      </Stack>
      <Box marginY={1}>
        <span
          style={{
            fontWeight: 700,
            color: "#333333",
            fontSize: 15,
          }}
        >
          Situação
        </span>
      </Box>
      <Stack spacing={2}>
        <FormControl 
          component="fieldset" 
          onChange={onChangeValue}
        >
          {
            activeTab === 0 ? (
                <>
                  <FormControlLabel
                    value={2}
                    label="Enviado"
                    checked={activeFilters.includes(2)}
                    sx={{ color: "#4F4F4F", fontSize: 15 }}
                    control={
                      <Checkbox
                        sx={{
                          color: "#979899",
                          '&.Mui-checked': {
                            color: "#F28C1F",
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value={1}
                    label="Rascunho"
                    checked={activeFilters.includes(1)}
                    sx={{ color: "#4F4F4F", fontSize: 15 }}
                    control={
                      <Checkbox
                        sx={{
                          color: "#979899",
                          '&.Mui-checked': {
                            color: "#F28C1F",
                          }
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value={3}
                    label="Arquivado"
                    checked={activeFilters.includes(3)}
                    sx={{ color: "#4F4F4F", fontSize: 15 }}
                    control={
                      <Checkbox
                        sx={{
                          color: "#979899",
                          '&.Mui-checked': {
                            color: "#F28C1F",
                          }
                        }}
                      />
                    }
                  />
                </>
              )
                : (
                  <>
                    <FormControlLabel
                      value={4}
                      label="Concluídos"
                      checked={activeFilters.includes(4)}
                      sx={{ color: "#4F4F4F", fontSize: 15 }}
                      control={
                        <Checkbox
                          sx={{
                            color: "#979899",
                            '&.Mui-checked': {
                              color: "#F28C1F",
                            },
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      value={2}
                      label="Pendentes"
                      checked={activeFilters.includes(2)}
                      sx={{ color: "#4F4F4F", fontSize: 15 }}
                      control={
                        <Checkbox
                          sx={{
                            color: "#979899",
                            '&.Mui-checked': {
                              color: "#F28C1F",
                            },
                          }}
                        />
                      }
                    />
              </>
            )
          }        
        </FormControl>
      </Stack>
    </Box>
  </Popover>
  );
}

function AdminChallengePhotoScreen() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [activeFilters, setActiveFilters] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [updateList, setUpdateList] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const history = useHistory();
  const topPageRef = useRef(null);

  useEffect(() => {
    if(selectedTab === 0){
      listAllVisualMerchandising();
    }

    if(selectedTab === 1){
      listAllVisualMerchandisingAnswers();
    }
    setIsFirstLoading(false);
  }, [selectedTab, page, updateList, activeFilters]);

  useEffect(() => {
    if(isFirstLoading){
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      if(selectedTab === 0){
        setPage(0);
        listAllVisualMerchandising();
      }
  
      if(selectedTab === 1){
        setPage(0);
        listAllVisualMerchandisingAnswers();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn)
  }, [search]);

  async function listAllVisualMerchandising(){
    setIsLoading(true);
    const response = await VisualMerchandising.listAll(page, search, activeFilters);
    scrollTotop();

    setData(response.result);
    setTotalCount(response.totalCount);
    setIsLoading(false);
  }

  async function listAllVisualMerchandisingAnswers(){
    setIsLoading(true);
    const response = await VisualMerchandisingAnswer.listAll(page, search, activeFilters);
    scrollTotop();

    setData(response.result);
    setTotalCount(response.totalCount)
    setIsLoading(false);
  }

  const handleChange = (event, newValue) => {
    if(isLoading){
      return;
    }

    if(newValue === selectedTab){
      return;
    }

    setPage(0);
    setData([]);
    setActiveFilters([]);
    setSelectedTab(newValue);
  };

  const handleAddButtonClick = () => {
    history.push("/visual_merchandising/create");
  }

  const handleFilterButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterOptions = () => {
    setAnchorEl(null);
  };

  const onChangeFilterValue = (e) => {
    const statusId = Number(e.target.value);
    const isSelected = activeFilters.includes(statusId);
    
    if(!isSelected){
      setActiveFilters(old => [...old, statusId]);
      setPage(0);
      return;
    }

    setActiveFilters(old => old.filter(selectedStatusId => {
      if(selectedStatusId !== statusId){
        return selectedStatusId;
      }
    }));
    setPage(0);
  }

  const handleClearFilters = () => {
    setPage(0);
    setActiveFilters([]);
  }

  const scrollTotop = () => {
    topPageRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Box
      padding='0px 48px'
      height='100vh'
      overflow='auto'
    >
      <div ref={topPageRef} />
      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <h1>Merchandising</h1>

        <ButtonLightBlue
          onClick={handleAddButtonClick}
          startIcon={<AddIcon />}
        >
          Visual merchandising
        </ButtonLightBlue>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        width='100%'
      >
        <CustomTabs value={selectedTab} onChange={handleChange}>
          <CustomTab label="Solicitações" />
          <CustomTab label="Respostas" />
        </CustomTabs>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          borderBottom='1px solid #e8e8e8'
        >
          <Stack mb={1} direction="row" alignItems="center">
            <Grow in={showSearchInput}>
              {
                <Box width={350}>
                  <SearchInput
                    clear={() => setShowSearchInput(false)}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                </Box>
              }
            </Grow>
            <Grow
              in={!showSearchInput}
              style={{ transformOrigin: '0 0 0', display: !showSearchInput ? "flex" : 'none' }}
              {...(!showSearchInput ? { timeout: 500 } : {})}
            >
              <CustomIconButton
                onClick={() => setShowSearchInput(true)}
              >
                <SearchIcon />
              </CustomIconButton>
            </Grow>

            <CustomIconButton
              onClick={handleFilterButtonClick}
              sx={{ml: 1}}
            >
              <FilterAltIcon />
            </CustomIconButton>
            <FilterOptions
              anchorEl={anchorEl}
              handleClose={handleCloseFilterOptions}
              onChangeValue={onChangeFilterValue}
              activeFilters={activeFilters}
              clearFilters={handleClearFilters}
              activeTab={selectedTab}
            />
          </Stack>
        </Box>
      </Box>
      {
        selectedTab === 0 ? (
          <VisualMerchandisingTable
            isLoading={isLoading}
            data={data}
            isSearching={search.length > 1 || activeFilters.length > 0 ? true : false}
            updateList={() => setUpdateList(!updateList)}
          />
        )
          : null
      }
      {
        selectedTab === 1 ? (
          <VisualMerchandisingAnswersTable
            isLoading={isLoading}
            data={data}
            isSearching={search.length > 1 || activeFilters.length > 0 ? true : false}
            updateList={() => setUpdateList(!updateList)}
          />
        )
          : null
      }

      {
        data.length > 0 && (
          <>
            <Divider variant="fullWidth" style={{ margin: '10px' }} />
            <Container maxWidth="xs">
              <Pagination
                page={page}
                totalCount={totalCount}
                isLoading={isLoading}
                handleNextPage={() => setPage(old => old + 1)}
                handlePreviousPage={() => setPage(old => old - 1)}
              />
            </Container>
          </>
        )
      }
    </Box>
  );
}

export default AdminChallengePhotoScreen;