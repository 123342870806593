import axios from 'axios'
import GenericModel from './generic_model/GenericModel'
const API = '/invoice';

class Invoice extends GenericModel {
  API = API;
  static API = API;

  static sendInvoiceFile = async (obj, id_invoice, progressFunction) => {

    try {

      let formData = new FormData();
      formData.append('file',obj,(new Date()).getTime() + '-' + Math.round(Math.random()*100000) + '.png');

      if(id_invoice) {
        formData.append('id_invoice',id_invoice);
      }

      let headerObj = await Invoice.insertAuthHeader();

      let response = await axios.request({
        url: API + '/send-invoice-file',
        method: "post",
        data: formData,
        headers: headerObj,
        onUploadProgress: (p) => {
          if(progressFunction) {
            progressFunction(Math.round( (p.loaded * 100) / p.total));
          }
        }
      });
      return response.data;
    } catch(err) {
      throw Error(err);
    }


  };
}

export default Invoice;