import React, {Component} from 'react';
import GenericAdminScreen from '../components/generic_screens/GenericAdminScreen'
// DB Models
import Store from '../../models/Store';
import User from '../../models/User';
import Brand from '../../models/Brand';
import Selling_Region from '../../models/Selling_Region';
import Store_DashboardCard from '../components/cards/Store_DashboardCard';
import Store_DashboardDescription from '../components/description_cards/Store_DashboardDescription';
import Product from "../../models/Product";

class AdminStore_DashboardScreen extends Component {

  editionTimeout = null;

  state = {
    stores: [],
    relations: []
  };

  // TEXTS
  searchText_placeholder = 'Nome da Loja';

  // FUNCTIONS
  // Load all data from DB
  componentDidMount() {
    let id_app = localStorage.getItem("ID_APP");
    if (id_app){
        Store.statistics().then(stores => {
            this.setState({'stores': stores});
          }).catch(err => {
            if(window.location.pathname !== '/') {
              window.location.href = '/';
            }
          });
          Promise.all([Brand.query()]).then(relations => {
            this.setState({'relations': relations});
          }).catch(err => {
            if(window.location.pathname !== '/') {
              window.location.href = '/';
            }
          })
    }
  }

  async reloadStatistics(parameters) {
    let id_app = localStorage.getItem("ID_APP");
    if (id_app){
        Store.statistics(parameters).then(stores => {
        this.setState({'stores': stores});
      }).catch(err => {
        if(window.location.pathname !== '/') {
          window.location.href = '/';
        }
      });}
    
    
  }
  
  refreshStatistics = async (parameters) => {
    this.reloadStatistics(parameters);
  };

  render() {
    return (
      <GenericAdminScreen
        searchText_placeholder={this.searchText_placeholder}
        objectList={this.state.stores}
        objectCard={Store_DashboardCard}
        objectDescription={Store_DashboardDescription}
        refreshObjects={this.refreshStatistics}
        objectModel={Store}
        relations={this.state.relations}
        objectIdName={"id_store"}
        onFilterReload={true}>
      </GenericAdminScreen>

    )
  }
}

export default AdminStore_DashboardScreen;