import React, { useRef, useState } from "react";

import { toast } from "react-toastify";
// DB Models
// import Content from "../../models/Content";
import TicketDepartment from "../../models/TicketDepartment";

// import ContentCard from "../components/cards/ContentCard";
// import ContentTrailCard from "../components/cards/ContentFormCard";
// import ContentDescriptionFormResearch from "../components/description_cards/ContentDescriptionFormResearch";
import Spinner from "../components/buttons/Spinner";
import { useEffect } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { set } from "lodash";

const AdminTicketDepartmentScreen = () => {
  const [loading, setLoading] = useState(true);
  const [save, setSave] = useState(false);
  const [departments, setDepartments] = useState([]);
  const departmentsRef = useRef(departments);
  useEffect(() => {
    fetchData();
  }, [save]);
  const fetchData = async () => {
    try {
      let result = await TicketDepartment.getAllDepartment();
      setDepartments(result);
      // console.log("ALLL is", result);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const changeDepartmentName = (str, idx) => {
    // console.log("dado digitado :", str);

    const almost = departments.map((department, index) => {
      if (index !== idx) {
        return department;
      } else {
        let data = department;
        data.email_departament = str;
        return data;
      }
    });
    setDepartments(almost);
  };
  const updateTicketDepartaments = async () => {
    setSave(false);
    try {
      let result = await TicketDepartment.postAllDepartment(departments);
      // departmentsRef(departments);
      // console.log("result is", result);

      if (result && result.success === false) {
        throw Error();
      }
      toast.success("Salvo com sucesso");
    } catch (error) {
      toast.error("Erro ao Salvar");
      console.log(error);
    } finally {
      setSave(true);
    }
  };
  return (
    <>
      {loading ? (
        <Spinner texto="Carregando" />
      ) : (
        <Grid
          xs={12}
          container
          spacing={2}
          padding={2}
          style={{ padding: "5%", borderRadius: "13px", border: 2 }}
        >
          <span
            style={{
              float: "left",
              color: "rgba(0, 0, 0, 0.54)",
              padding: "0",
              fontSize: "18px",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontWeight: "400",
              lineHeight: "1",
              letterSpacing: "0.00938em",
              paddingTop: "5px",
              paddingLeft: "-40px",
            }}
          >
            E-Mails para envio de demandas:
          </span>
          <Grid
            xs={12}
            container
            style={{ padding: "20px" }}
            direction={"row"}
            spacing={2}
            padding={2}
          >
            {!loading &&
              departments.length > 0 &&
              departments.map((department, idx) => (
                <Grid
                  xs={5}
                  key={department.id_ticket_department}
                  style={{ paddingTop: "20px", paddingRight: "20px" }}
                >
                  <TextField
                    label={`para o departamento ${
                      department.ticket_department_name
                        ? department.ticket_department_name
                        : "nome do departamento não cadastrado"
                    }`}
                    onChange={(event) => {
                      changeDepartmentName(
                        event.target.value,
                        idx // ,department.id_ticket_department
                      );
                    }}
                    style={{ width: "100%" }}
                    value={department ? department.email_departament : ""}
                  />
                </Grid>
              ))}
          </Grid>
          {!loading && departments.length > 0 && (
            <Grid xs={12}>
              <Button
                variant="contained"
                color="success"
                style={{
                  height: 45,
                  width: "40%",
                  backgroundColor: "green",
                  color: "white",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  updateTicketDepartaments();
                }}
              >
                Salvar alterações
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default AdminTicketDepartmentScreen;
