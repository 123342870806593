import React, {useState, useEffect} from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';  
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Container from '@material-ui/core/Container';
import {useHistory, useParams} from 'react-router-dom';

import MerchandisingContentForm from './MerchandisingContentForm';
import MerchandisingUploadForm from './MerchandisingUploadForm';
import MerchandisingSendConfigForm from './MerchandisingSendConfigForm';
import VisualMerchandising from '../../../../models/VisualMerchandising';

const steps = ['Cadastro de conteúdo', 'Upload de arquivos', 'Configuração de envio'];

const CustomStepper = withStyles({
  root:{
    backgroundColor: '#FAFAFA',
  }
})(Stepper);

function CreateVisualMerchandisingStepper({activeStep}){
  return(
    <CustomStepper alternativeLabel activeStep={activeStep}>
    {steps.map((label) => (
      <Step key={label}>
        <StepLabel>{label}</StepLabel>
      </Step>
    ))}
  </CustomStepper>
  );
}

function CreateVisualMerchandising(){
  const [visualMerchandising, setVisualMerchandising] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const history = useHistory();
  const {id} = useParams();

  useEffect(() => {
    if(id){
      getVisualMerchandising();
    }
  }, []);

  async function getVisualMerchandising(){
    const response = await VisualMerchandising.getById(id);

    setVisualMerchandising(response);
  }

  return(
    <Box
      padding='16px 48px'
      bgcolor='#FAFAFA'
      height='99vh'
      overflow='auto'
    >
      <Container maxWidth="lg" style={{marginBottom: 16}}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link color="#162233" onClick={() => history.push('/visual_merchandising')} style={{cursor:'pointer'}}>
            Merchandising
          </Link>
          <span
            style={{
              fontWeight: 700,
              color: "#162233"
            }}
          >
            Criar visual merchandising
          </span>
        </Breadcrumbs>
        <CreateVisualMerchandisingStepper 
          activeStep={activeStep}
        />
        { 
          <>
            <MerchandisingContentForm 
              nextStep={() => setActiveStep(old => old + 1)}
              isActive={activeStep === 0}
              visualMerchandising={visualMerchandising}
              setVisualMerchandising={(visualMerchandising) => setVisualMerchandising(visualMerchandising)}
            />
            <MerchandisingUploadForm
              goBack={() => setActiveStep(old => old - 1)} 
              nextStep={() => setActiveStep(old => old + 1)}
              isActive={activeStep === 1}
              visualMerchandising={visualMerchandising}
            />
            <MerchandisingSendConfigForm 
              goBack={() => setActiveStep(old => old - 1)} 
              nextStep={() => setActiveStep(old => old + 1)}
              isActive={activeStep === 2}
              visualMerchandising={visualMerchandising}
            />
          </>
        }
      </Container>
    </Box>
  );
}

export default CreateVisualMerchandising;