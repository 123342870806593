import React, { Component } from "react";
import GenericAdminScreen from "../components/generic_screens/GenericAdminScreen";
// DB Models
import Training from "../../models/Training";
import ChallengePhoto from "../../models/ChallengePhoto";

import ChallengePhotoCard from "../components/cards/ChallengePhotoCard";
import ChallengePhotoDescription from "../components/description_cards/ChallengePhotoDescription";
import Invoice from "../../models/Invoice";
import User from "../../models/User";
import { toast } from "react-toastify";
import Spinner from "../components/buttons/Spinner";
class AdminChallengePhotoScreen extends Component {
  editionTimeout = null;

  state = {
    challenges: [],
    relations: [],
    filters: [],
    challenge_photos: [],
    loading: true,
  };

  // TEXTS
  searchText_placeholder = "Título do Desafio";

  // FUNCTIONS

  // Load all data from DB
  componentDidMount() {
    Training.query()
      .then((trainings) => {
        let challenges = trainings.filter((training) => {
          return training.is_challenge_photo && training.content;
        });
        this.setState({ challenges: challenges, loading: false });
      })
      .catch((err) => {
        if (window.location.pathname !== "/") {
          window.location.href = "/";
        }
        this.setState({ loading: false });
      });
    this.reloadChallenge();
  }

  async reloadChallenge(parameters) {
    this.setState({ loading: true });
    try {
      let challenges = await Training.query(parameters).then((trainings) => {
        let challenges = trainings.filter((training) => {
          return training.is_challenge_photo ? true : false;
        });
        return challenges;
      });
      let challenge_photos = [];
      challenges.map((challenge) => {
        if (challenge.challenge_photos)
          challenge.challenge_photos.map((challenge_photo) => {
            challenge_photos.push(new ChallengePhoto(challenge_photo));
          });
        challenge.challenge_photos.sort((el1, el2) => {
          return el2.id_user - el1.id_user;
        });
        challenge.challenge_photos.sort((el1, el2) => {
          if (el2.challenge_photo_review === null) {
            return 1;
          } else if (el2.challenge_photo_review) {
            if (el1.challenge_photo_review === null) return -1;
            return 0;
          } else {
            return -1;
          }
        });
      });
      this.setState({ loading: false });
      this.setState({ challenges: challenges });
      this.setState({ challenge_photos: challenge_photos });
    } catch (err) {
      toast.error("Erro ao carregar desafios");
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  }

  refreshChallenges = async (parameters) => {
    this.reloadChallenge(parameters);
  };

  updateChallengePhoto = (newChallengePhotoObj) => {
    let challenge_photos = this.state.challenge_photos;
    let position = challenge_photos.findIndex((obj) => {
      return obj.id_challenge_photo === newChallengePhotoObj.id_challenge_photo;
    });
    let challengePhotoToUpdate = challenge_photos.find((obj) => {
      return obj.id_challenge_photo === newChallengePhotoObj.id_challenge_photo;
    });
    for (let prop in newChallengePhotoObj) {
      if (newChallengePhotoObj.hasOwnProperty(prop)) {
        challengePhotoToUpdate[prop] = newChallengePhotoObj[prop];
      }
    }
    this.setState({ challenge_photos: challenge_photos });
    return challenge_photos[position];
  };

  deleteChallengePhoto = (challengePhotoObj) => {
    let challenge_photos = this.state.challenge_photos;
    // Finds Edited content on contents list and change Content_name value
    for (let i = 0; i < challenge_photos.length; i++) {
      if (
        challengePhotoObj.id_challenge_photo ===
        challenge_photos[i].id_challenge_photo
      ) {
        let challenges = this.state.challenges;
        let challenge = challenges.filter(
          (challenge) =>
            challenge.id_training === challenge_photos[i].id_training
        )[0];
        let contentChallengePhotos = challenge.challenge_photos.filter(
          (challenge_photo) =>
            challenge_photo.id_challenge_photo !=
            challengePhotoObj.id_challenge_photo
        );
        challenge.challenge_photos = contentChallengePhotos;
        challenges = challenges.map((challengeObject) => {
          if (challengeObject.id_training == challenge.id_training) {
            challengeObject = challenge;
          }
          return challengeObject;
        });
        this.setState({ challenges });
        challenge_photos[i].remove();

        challenge_photos.splice(i, 1);
        break;
      }
    }

    this.setState({ challenge_photos: challenge_photos });
  };

  saveChallengePhoto = async (challengePhotoObj) => {
    let challenge_photos = this.state.challenge_photos;
    let position = challenge_photos.findIndex((obj) => {
      return obj.id_challenge_photo === challengePhotoObj.id_challenge_photo;
    });
    let challengePhotoToUpdate = challenge_photos.find((obj) => {
      return obj.id_challenge_photo === challengePhotoObj.id_challenge_photo;
    });

    if (challengePhotoToUpdate != null) {
      let challengePhotoObj = challengePhotoToUpdate;

      try {
        challengePhotoObj = await challengePhotoToUpdate.save();
        // Invoice upload to server worked
        challenge_photos[position] = challengePhotoObj;
        this.setState({ challenge_photos: challenge_photos });
        toast.success("Foto avaliada com sucesso!");
      } catch (err) {
        toast.error("Erro ao avaliar foto");
        console.error(err);
      }
    }
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <Spinner
            margin="13px"
            texto={"carregando..."}
            border={"#9BB3D4"}
            top={"#1D2D44"}
          />
        ) : (
          <GenericAdminScreen
            searchText_placeholder={this.searchText_placeholder}
            objectList={this.state.challenges}
            objectCard={ChallengePhotoCard}
            updateObject={this.updateChallengePhoto}
            saveObject={this.saveChallengePhoto}
            deleteObject={this.deleteChallengePhoto}
            dontCloseRodal={true}
            objectDescription={ChallengePhotoDescription}
            refreshObjects={this.refreshChallenges}
            relations={this.state.relations}
            objectIdName={"id_training"}
            objectModel={Training}
            onFilterReload={true}
          ></GenericAdminScreen>
        )}
      </>
    );
  }
}

export default AdminChallengePhotoScreen;
