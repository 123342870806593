import GenericModel from './generic_model/GenericModel'
import { serialize } from '../utils'
const API = '/content_we';

class ContentWe extends GenericModel {
    API = API;
    static API = API;

    static statistics = async function (query) {

        let queryString = serialize(query);

        let authHeader = null;
        try {
            authHeader = await GenericModel.insertAuthHeader();

            const response = await fetch(API + '/statistics?' + queryString, {
                headers: authHeader
            });

            const body = await response.json();

            if (response.status !== 200) throw new Error(body.message);

            return body;
        } catch (err) {
            throw Error(err);
        }
    };

    static getexcel = async function (id) {

        let authHeader = null;
        try {
            authHeader = await GenericModel.insertAuthHeader();

            const response = await fetch(API + '/getexcel/' + id, {
                headers: authHeader
            });

            const body = await response.blob();

            if (response.status !== 200) throw new Error(body.message);

            return body;
        } catch (err) {
            throw Error(err);
        }
    };

    static getContentClass = async function (id) {
        let queryString = "";
        if (id) {
            queryString = id;
        }
        let authHeader = null;
        try {
            let that = this;
            authHeader = await GenericModel.insertAuthHeader();
            const response = await fetch(this.API + '/' + queryString + "?", {
                headers: authHeader,
            });
            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            let ans = {};

            function mountObjArray(elementsArray) {
                let result = [];

                for (let i = 0; i < elementsArray.length; i++) {
                    result.push(new GenericModel(elementsArray[i], that.API));
                }

                return result;
            }

            if (body && body.elements) {
                for (let prop in body) {
                    if (body.hasOwnProperty(prop) && prop !== "elements") {
                        ans[prop] = body[prop];
                    }
                }
                ans.elements = mountObjArray(body.elements);
            } else if (body instanceof Array) {
                ans = mountObjArray(body);
            } else {
                ans = new GenericModel(body, that.API);
            }

            return ans;
        } catch (err) {
            throw Error(err);
        }
    };

    static filters = async function (query) {
        let queryString = serialize(query);

        let authHeader = null;

        try {
            authHeader = await GenericModel.insertAuthHeader();
            const response = await fetch(this.API + "/filters?" + queryString, {
                headers: authHeader,
            });

            const body = await response.json();

            if (response.status !== 200) throw Error(body.message);

            return body;
        } catch (err) {
            throw Error(err);
        }

    };

    saveTrail = async function () {
        let that = this;

        let authHeader = {};

        try {
            authHeader = await GenericModel.insertAuthHeader({
                Accept: "application/json",
                "Content-Type": "application/json",
            });

            let response = await fetch(that.API + '/content_trail', {
                method: "POST",
                headers: authHeader,
                body: JSON.stringify(this),
            });

            if (response.status >= 400) {
                throw Error(response.statusText);
            }

            let object = await response.json();

            return new GenericModel(object, that.API);
        } catch (err) {
            throw Error(err);
        }
    };

}

export default ContentWe;